<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_api">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title">NOX API</div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_api_reference"><p><b>Base URL:</b><span class="blue"> {{ $store.state.noxApiBaseURL }}</span><br><b>Base TOKEN:</b><span class="token blue"> {{ $store.state.noxOAuthTokenForNOXAPI }}</span></p><p><b>Используемые коды ответа:</b></p><p><b class="green">200</b> − {{ noxAPIResponseDescriptions[200] }} (OK).<br><b class="green">201</b> − {{ noxAPIResponseDescriptions[201] }} (Created).<br><b class="red">400</b> − {{ noxAPIResponseDescriptions[400] }} (Bad Request).<br><b class="red">401</b> − {{ noxAPIResponseDescriptions[401] }} (Unauthorized).<br><b class="red">403</b> − {{ noxAPIResponseDescriptions[403] }} (Forbidden).<br><b class="red">404</b> − {{ noxAPIResponseDescriptions[404] }} (Not Found).<br><b class="red">405</b> − {{ noxAPIResponseDescriptions[405] }} (Method Not Allowed).<br><b class="red">409</b> − {{ noxAPIResponseDescriptions[409] }} (Conflict).<br><b class="red">429</b> − {{ noxAPIResponseDescriptions[429] }} (Too Many Requests).<br><b class="red">500</b> − {{ noxAPIResponseDescriptions[500] }} (Internal Server Error).</p></div>
					</div>
				</div>
				<div class="nox_account_desktop_api_data" v-if="Object.keys(noxAPIData).length">
					<div class="nox_account_desktop_api_data_categories" v-if="Object.keys(noxAPIData.categories).length">
						<div class="nox_account_desktop_portlet" v-for="(value, key, index) in noxAPIData.categories" :key="index">
							<div class="nox_account_desktop_portlet_title">{{ (index + 1) }}. {{ value.name }}</div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_api_data_subcategories" v-if="Object.keys(value.subcategories).length">
									<div class="nox_account_desktop_api_data_subcategories_block" v-for="(value2, key2, index2) in value.subcategories" :key="index2">
										<div class="nox_account_desktop_api_data_endpoints" v-if="Object.keys(value2.endpoints).length">
											<div class="nox_account_desktop_api_data_endpoints_title"><span>{{ (index2 + 1) }}. {{ value2.name }}</span> Requests</div>
											<div class="nox_account_desktop_api_data_endpoints_block" :class="{ new_group: value3.is_new_group }" :id="'nox_account_desktop_api_data_endpoints_block_' + value3.id" v-for="(value3, key3, index3) in value2.endpoints" :key="index3">
												<div class="nox_account_desktop_api_data_endpoints_block_item" :class="{ hidden: !value3.is_show }">
													<div class="nox_account_desktop_api_data_endpoints_block_item_head" @click="toggleEndpoint(value3.id)">
														<div class="nox_account_desktop_api_data_endpoints_block_item_head_content">
															<span class="nox_account_desktop_api_data_endpoints_block_item_head_content_method" :class="[(value3.method == 'POST' ? 'green' : (value3.method == 'PATCH' ? 'orange' : (value3.method == 'DELETE' ? 'red' : 'blue')))]">{{ value3.method }}</span><span class="nox_account_desktop_api_data_endpoints_block_item_head_content_path">{{ value3.path }}</span>
														</div>
														<div class="nox_account_desktop_api_data_endpoints_block_item_head_toggle">
															<font-awesome-icon :icon="['fas', 'angle-up']" v-if="noxIsToggleEndpoint && noxToggleEndpointId == value3.id" />
															<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
														</div>
													</div>
													<div class="nox_account_desktop_api_data_endpoints_block_item_body" :class="{ hide: (!noxIsToggleEndpoint || (noxIsToggleEndpoint && noxToggleEndpointId !== value3.id)) }">
														<div class="nox_account_desktop_api_data_endpoints_block_item_body_content">
															<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_description" v-if="value3.description">{{ value3.description }}</div>
															<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block">
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_title">Headers:</div>
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_headers">
																	<table class="nox_table">
																		<thead>
																			<tr>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 55)"></th>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 1)"></th>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 55) + ':'">Authorization</td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">Bearer &#60;token&#62;</td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'">где <span>&#60;token&#62;</span> = <span v-if="value3.is_nox_token"><span class="blue">{{ $store.state.noxOAuthTokenForNOXAPI }}</span> (базовый постоянный токен, который не будет меняться без особой необходимости)</span><span v-else>токену доступа к аккаунту, который можно получить путем авторизации в системе NOX</span></td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
															<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block">
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_title">Parameters:</div>
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters" v-if="value3.parameters.length">
																	<table class="nox_table">
																		<thead>
																			<tr>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 55)"></th>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 41)"></th>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 58)"></th>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
																				<th v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
																				<th v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'times']" /></th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr v-for="(value4, index4) in value3.parameters" :key="index4">
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 55) + ':'">{{ value4.name }}</td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 41) + ':'"><span>{{ value4.type }}</span><br><span :class="[(value4.is_required ? 'red' : 'green')]">[ {{ (value4.is_required ? 'required' : 'optional') }} ]</span></td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 58) + ':'">{{ value4.example }}</td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'">{{ value4.description }} <span class="blue">RegExp: {{ noxAPIParameterRegExps[value4.regexp].regexp }}</span></td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 28) + ':'" v-if="$store.state.noxAccountData.group == 1"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalAPI', { type: 'editAPIParameter', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id, parameter_id: value4.id, parameter_index: index4 })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 29) + ':'" v-if="$store.state.noxAccountData.group == 1"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalAPI', { type: 'deleteAPIParameter', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id, parameter_id: value4.id, parameter_index: index4 })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
																			</tr>
																		</tbody>
																	</table>
																</div>
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_buttons" v-if="$store.state.noxAccountData.group == 1">
																	<button type="button" class="nox_button big green" @click="$modal.show('NoxModalAPI', { type: 'addAPIParameter', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить параметр</button>
																</div>
															</div>
															<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block">
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_title">Responses:</div>
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses" v-if="value3.responses.length">
																	<table class="nox_table">
																		<thead>
																			<tr>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 56)"></th>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 57)"></th>
																				<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
																				<th v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
																				<th v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'times']" /></th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr v-for="(value4, index4) in value3.responses" :key="index4">
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 56) + ':'" :class="[([200, 201].includes(value4.code) ? 'green' : 'red')]">{{ value4.code }}</td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 57) + ':'" :class="{ data: Object.keys(value4.data).length }">{{ (Object.keys(value4.data).length ? value4.data : '') }}</td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'">{{ (value4.description ? value4.description : noxAPIResponseDescriptions[value4.code]) }}</td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 28) + ':'" v-if="$store.state.noxAccountData.group == 1"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalAPI', { type: 'editAPIResponse', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id, response_id: value4.id, response_index: index4 })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
																				<td :data-label="$store.getters.getLanguageText('1.7.1', 29) + ':'" v-if="$store.state.noxAccountData.group == 1"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalAPI', { type: 'deleteAPIResponse', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id, response_id: value4.id, response_index: index4 })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
																			</tr>
																		</tbody>
																	</table>
																</div>
																<div class="nox_account_desktop_api_data_endpoints_block_item_body_content_block_buttons" v-if="$store.state.noxAccountData.group == 1">
																	<button type="button" class="nox_button big green" @click="$modal.show('NoxModalAPI', { type: 'addAPIResponse', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить ответ</button>
																</div>
															</div>
														</div>
														<div class="nox_account_desktop_api_data_endpoints_block_item_body_buttons" v-if="$store.state.noxAccountData.group == 1">
															<button type="button" class="nox_button big red" @click="$modal.show('NoxModalAPI', { type: 'deleteAPIEndpoint', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id })"><font-awesome-icon :icon="['fas', 'trash']" />Удалить эндпоинт</button>
															<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalAPI', { type: 'editAPIEndpoint', category_id: value.id, subcategory_id: value2.id, endpoint_id: value3.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" />Изменить эндпоинт</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_buttons" v-if="$store.state.noxAccountData.group == 1">
								<button type="button" class="nox_button big green" @click="$modal.show('NoxModalAPI', { type: 'addAPIEndpoint', category_id: value.id })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить эндпоинт</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalAPI"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxAPIData: {},
			noxAPICategories: [],
			noxAPISubcategories: [],
			noxAPIEndpointMethods: ['GET', 'POST', 'PATCH', 'DELETE'],
			noxAPIParameterTypes: ['string', 'integer', 'decimal', 'boolean', 'array[string]', 'array[integer]', 'array[decimal]', 'array[boolean]'],
			noxAPIParameterRegExps: [
				{ code: 0, label: 'Не задан', regexp: 'Не задан' },
				{ code: 1, label: 'noxRegex_d', regexp: '/^\\d+$/' },
				{ code: 2, label: 'noxRegex_w', regexp: '/^\\w+$/' },
				{ code: 3, label: 'noxRegexEmail', regexp: '/^\\w+([.-]?\\w+)*@\\w+([.-]?\\w+)*(\\.\\w{2,4})+$/' },
				{ code: 4, label: 'noxRegexPhone', regexp: '/^\\+[1-9]{1,3}[0-9]{7,10}$/' },
				{ code: 5, label: 'noxRegexCountry', regexp: '/^[A-Z]{2}$/' },
				{ code: 6, label: 'noxRegexLanguage', regexp: '/^[a-z]{2}$/' },
				{ code: 7, label: 'noxRegexUsername', regexp: '/^[a-zа-яё0-9\\-_. ]{4,30}$/ui' },
				{ code: 8, label: 'noxRegexPassword', regexp: '/^[a-zа-яё0-9]{6,30}$/ui' },
				{ code: 9, label: 'noxRegexInvitation', regexp: '/^[a-z0-9=\\-_]+$/i' },
				{ code: 10, label: 'noxRegexERC20', regexp: '/^(0x){1}[a-f0-9]{40}$/i' },
				{ code: 11, label: 'noxRegexTRC20', regexp: '/^(T){1}[a-z0-9]{33}$/i' },
				{ code: 12, label: 'noxRegexCoupon', regexp: '/^[1-9A-HK-NP-Z]{20}$/' },
				{ code: 13, label: 'noxRegexFloatNumbers', regexp: '/^\\d+\\.\\d+$/' },
				{ code: 14, label: 'noxRegexNumbersAndComma', regexp: '/^[0-9,]+$/i' },
				{ code: 15, label: 'noxRegexIntOrFloatNumbers', regexp: '/^\\d+(\\.\\d+)?$/' },
				{ code: 16, label: 'noxRegexENLetters', regexp: '/^[a-z]+$/i' },
				{ code: 17, label: 'noxRegexENLettersAndSpace', regexp: '/^[a-z ]+$/i' },
				{ code: 18, label: 'noxRegexENLettersAndNumbers', regexp: '/^[a-z0-9]+$/i' },
				{ code: 19, label: 'noxRegexENLettersAndNumbersAndMarks', regexp: '/^[a-z0-9№%!?.,:;=*+−\\-/@_«»()\\n ]+$/i' },
				{ code: 20, label: 'noxRegexENRULetters', regexp: '/^[a-zа-яё]+$/ui' },
				{ code: 21, label: 'noxRegexENRULettersAndNumbers', regexp: '/^[a-zа-яё0-9]+$/ui' },
				{ code: 22, label: 'noxRegexENRULettersAndNumbersAndSpace', regexp: '/^[a-zа-яё0-9 ]+$/ui' },
				{ code: 23, label: 'noxRegexENRULettersAndNumbersAndMarks', regexp: '/^[a-zа-яё0-9№%!?.,:;=*+−\\-/@_«»()\\n ]+$/ui' }
			],
			noxAPIResponseCodes: [200,201,400,401,403,404,405,409,429,500],
			noxAPIResponseTypes: ['code', 'array', 'object', 'string'],
			noxAPIResponseDescriptions: {
				200: 'Запрос успешно выполнен.',
				201: 'Запрос успешно выполнен.',
				400: 'Ошибка! Неверный запрос.',
				401: 'Ошибка! Необходимо авторизоваться в системе.',
				403: 'Ошибка! Доступ к ресурсу запрещен.',
				404: 'Ошибка! Данные не найдены.',
				405: 'Ошибка! Метод не разрешен.',
				409: 'Ошибка! Конфликт данных.',
				429: 'Ошибка! Слишком частые запросы.',
				500: 'Внутренняя ошибка сервера!'
			},
			noxToggleEndpointId: -1,
			noxIsToggleEndpoint: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || [40,42].includes(this.$store.state.noxAccountData.id)) {
					this.axios('getAPIData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initAPIData: function(data) {
				this.noxAPIData = {};
				this.noxAPICategories = [];
				this.noxAPISubcategories = [];
				if (data.api_categories !== undefined) {
					if (data.api_categories.length) {
						this.noxAPIData = { categories: {} };
						for (var i in data.api_categories) {
							this.noxAPICategories.push({ code: data.api_categories[i].id, label: data.api_categories[i].name });
							this.$set(this.noxAPIData.categories, data.api_categories[i].id, Object.assign(data.api_categories[i], { subcategories: {} }));
						}
					}
				}
				if (data.api_subcategories !== undefined) {
					if (data.api_subcategories.length) {
						for (var j in data.api_subcategories) {
							this.noxAPISubcategories.push({ code: data.api_subcategories[j].id, label: data.api_subcategories[j].name });
							if (this.noxAPIData.categories !== undefined && this.noxAPIData.categories[data.api_subcategories[j].category_id] !== undefined) { this.$set(this.noxAPIData.categories[data.api_subcategories[j].category_id].subcategories, data.api_subcategories[j].id, Object.assign(data.api_subcategories[j], { endpoints: {} })); }
						}
					}
				}
				if (data.api_endpoints !== undefined) {
					if (data.api_endpoints.length) {
						for (var k in data.api_endpoints) {
							if (this.noxAPIData.categories !== undefined && this.noxAPIData.categories[data.api_endpoints[k].category_id] !== undefined && this.noxAPIData.categories[data.api_endpoints[k].category_id].subcategories[data.api_endpoints[k].subcategory_id] !== undefined) { this.$set(this.noxAPIData.categories[data.api_endpoints[k].category_id].subcategories[data.api_endpoints[k].subcategory_id].endpoints, ('id' + data.api_endpoints[k].id), Object.assign(data.api_endpoints[k], { parameters: [], responses: [] })); }
						}
					}
				}
				if (data.api_parameters !== undefined) {
					if (data.api_parameters.length) {
						for (var l in data.api_parameters) {
							if (this.noxAPIData.categories !== undefined && this.noxAPIData.categories[data.api_parameters[l].category_id] !== undefined && this.noxAPIData.categories[data.api_parameters[l].category_id].subcategories[data.api_parameters[l].subcategory_id] !== undefined && this.noxAPIData.categories[data.api_parameters[l].category_id].subcategories[data.api_parameters[l].subcategory_id].endpoints['id' + data.api_parameters[l].endpoint_id] !== undefined) { this.noxAPIData.categories[data.api_parameters[l].category_id].subcategories[data.api_parameters[l].subcategory_id].endpoints['id' + data.api_parameters[l].endpoint_id].parameters.push(data.api_parameters[l]); }
						}
					}
				}
				if (data.api_responses !== undefined) {
					if (data.api_responses.length) {
						for (var m in data.api_responses) {
							if (this.noxAPIData.categories !== undefined && this.noxAPIData.categories[data.api_responses[m].category_id] !== undefined && this.noxAPIData.categories[data.api_responses[m].category_id].subcategories[data.api_responses[m].subcategory_id] !== undefined && this.noxAPIData.categories[data.api_responses[m].category_id].subcategories[data.api_responses[m].subcategory_id].endpoints['id' + data.api_responses[m].endpoint_id] !== undefined) { this.noxAPIData.categories[data.api_responses[m].category_id].subcategories[data.api_responses[m].subcategory_id].endpoints['id' + data.api_responses[m].endpoint_id].responses.push(data.api_responses[m]); }
						}
					}
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getAPIData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/api';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getAPIData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								_this.initAPIData(data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleEndpoint: function(id) {
				var _this = this;
				_this.noxIsToggleEndpoint = _this.noxToggleEndpointId == id ? !_this.noxIsToggleEndpoint : true;
				_this.noxToggleEndpointId = id;
				setTimeout(function() {
					if (_this.noxIsToggleEndpoint && _this.noxToggleEndpointId) {
						_this.$el.querySelector('#nox_account_desktop_api_data_endpoints_block_' + _this.noxToggleEndpointId).scrollIntoView({
							behavior: 'smooth',
							block: 'start'
						});
					}
				}, 0);
			}
		},
		components: {
			NoxModalAPI: () => import('@/views/modal_components/NoxModalAPI.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_api_reference .blue {
		color: #A5E5FF;
	}
	.nox_account_desktop_api_reference .green {
		color: #ABFFAB;
	}
	.nox_account_desktop_api_reference .red {
		color: #FFBBBB;
	}
	.nox_account_desktop_api_reference .token {
		word-break: break-all;
	}
	.nox_account_desktop_api_data_subcategories_block {
		margin: 25px 0 0 0;
	}
	.nox_account_desktop_api_data_subcategories_block:first-child {
		margin: 0;
	}
	.nox_account_desktop_api_data_endpoints_title {
		color: #DDDDDD;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-style: italic;
		font-size: 10px;
	}
	.nox_account_desktop_api_data_endpoints_title span {
		text-transform: uppercase;
	}
	.nox_account_desktop_api_data_endpoints_block {
		padding: 3px 0 0 0;
	}
	.nox_account_desktop_api_data_endpoints_block.new_group {
		margin: 15px 0 0 0;
	}
	.nox_account_desktop_api_data_endpoints_block:first-child {
		padding: 0;
	}
	.nox_account_desktop_api_data_endpoints_block_item {
		border: 1px solid #999999;
		border-radius: 10px;
		background: #505050B3;
		overflow: hidden;
	}
	.nox_account_desktop_api_data_endpoints_block_item.hidden {
		background: #606060B3;
		opacity: 0.5;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head {
		position: relative;
		padding: 10px 45px 10px 10px;
		cursor: pointer;
		font-size: 13px;
		line-height: 20px;
		min-height: 46px;
		z-index: 1;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head_content_method {
		margin: 0 10px 0 0;
		padding: 3px 0;
		border-radius: 3px;
		background: #5093CB;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		display: inline-block;
		min-width: 65px;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head_content_method.blue {
		background: #5093CB;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head_content_method.green {
		background: #48B849;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head_content_method.orange {
		background: #E1B673;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head_content_method.red {
		background: #E26868;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head_content_path {
		word-break: break-all;
	}
	.nox_account_desktop_api_data_endpoints_block_item_head_toggle {
		position: absolute;
		top: 20px;
		right: 20px;
		margin: -7px 0 0 0;
		font-size: 20px;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body {
		position: relative;
		margin: 0 10px 10px 10px;
		padding: 10px 0 0 0;
		border-top: 1px solid #999999;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_description {
		margin: 0 0 10px 0;
		font-style: italic;
		line-height: 20px;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block {
		margin: 0 0 10px 0;
		padding: 10px;
		border: 1px solid #999999;
		border-radius: 5px;
		background: #77777780;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block table {
		background: #50505080;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block tr td {
		word-break: break-all;
		word-break: break-word;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_title {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_headers,
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters,
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses {
		margin: 10px 0 0 0;
		padding: 10px 0 0 0;
		border-top: 1px solid #999999;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_headers tr th:nth-child(1),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_headers tr td:nth-child(1),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_headers tr th:nth-child(2),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_headers tr td:nth-child(2),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr th:nth-child(1),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr td:nth-child(1),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr th:nth-child(2),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr td:nth-child(2),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr th:nth-child(3),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr td:nth-child(3) {
		min-width: 145px;
		width: 1%;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr th:nth-child(5),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr td:nth-child(5),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr th:nth-child(6),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_parameters tr td:nth-child(6),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr th:nth-child(1),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr td:nth-child(1),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr th:nth-child(4),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr td:nth-child(4),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr th:nth-child(5),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr td:nth-child(5) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr td:nth-child(1) {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr th:nth-child(2),
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr td:nth-child(2) {
		text-align: left;
		width: 43%;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_responses tr td.data:nth-child(2) {
		background: #40404080;
		font-size: 10px;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_content_block_buttons {
		margin: 10px 0 0 0;
		padding: 5px 0 0 0;
		border-top: 1px solid #999999;
		text-align: right;
	}
	.nox_account_desktop_api_data_endpoints_block_item_body_buttons {
		margin: -5px 0 0 0;
		text-align: right;
	}
</style>
