<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_analysis" :class="{ full: !noxIsWidget2 && !noxIsWidget7 }">
				<div class="nox_account_desktop_widgets">
					<div class="nox_account_desktop_widget nox_account_desktop_widget_2" v-if="noxIsWidget2">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="noxWidget2['name_' + $store.state.noxLanguage]"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_sdata">
									<div class="nox_account_desktop_widget_2_text">
										<div class="theme"><b v-html="$store.getters.getLanguageText('4.2', 1)"></b>:<br><span v-html="noxWidget2.data['theme_' + $store.state.noxLanguage]"></span></div>
										<div class="datetime"><b v-html="$store.getters.getLanguageText('4.2', 2)"></b>:<br>{{ new Date(noxWidget2.data.datetime).toLocaleString($store.state.noxLanguage) }}</div>
									</div>
									<div class="nox_account_desktop_widget_2_countdown">
										<div class="nox_countdown">
											<div class="nox_countdown_content" v-if="noxIsWidget2Countdown">
												<div class="title" v-html="$store.getters.getLanguageText('4.2', 0)"></div>
												<div class="values" v-html="noxWidget2CountdownValues"></div>
												<div class="labels" v-html="noxWidget2CountdownLabels"></div>
											</div>
											<div class="nox_countdown_content" v-else>
												<div class="title blue" v-if="noxWidget2CountdownStatus == 2" v-html="$store.getters.getLanguageText('4.2', 3)"></div>
												<div class="title green" v-else-if="noxWidget2CountdownStatus == 1" v-html="$store.getters.getLanguageText('4.2', 4)"></div>
												<div class="title red" v-else v-html="$store.getters.getLanguageText('4.2', 5)"></div>
											</div>
											<div class="nox_countdown_button">
												<a :href="$store.state.noxBaseURL + '/webinar'" target="_blank"><button type="button" class="nox_button common" v-html="$store.getters.getLanguageText('1.1', 25)"></button></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_widget nox_account_desktop_widget_7" v-if="noxIsWidget7">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="noxWidget7['name_' + $store.state.noxLanguage]"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_sdata" v-if="noxWidget7.data && noxWidget7.data.is_hidden_in_weekends && noxWidget7.is_weekends && noxIsWidget7Countdown">
									<div class="nox_countdown">
										<div class="nox_countdown_content">
											<div class="title" v-html="$store.getters.getLanguageText('4.5', 1)"></div>
											<div class="values" v-html="noxWidget7CountdownValues"></div>
											<div class="labels" v-html="noxWidget7CountdownLabels"></div>
										</div>
									</div>
								</div>
								<div class="nox_account_desktop_portlet_content_sdata" v-else>
									<div class="nox_account_desktop_widget_7_item" v-if="noxWidget7.data && noxWidget7.data.clv_currency_pairs && !noxWidget7.data.hidden_symbols.includes(name)" v-for="(value, name, index) in noxWidget7.data.clv_currency_pairs" :key="index">
										<div class="nox_account_desktop_widget_7_item_symbol" :class="$store.state.noxWidget7CLVDataByMultipliers[value[0].toFixed(1)].color" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 209, { options: [$store.state.noxWidget7CLVDataByMultipliers[value[0].toFixed(1)].index], symbol: name.toUpperCase() }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">{{ name }}</div>
										<div class="nox_account_desktop_widget_7_item_icon" :class="$store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].color"><span class="nox_account_desktop_widget_7_item_icon_block" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 210, { options: [$store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].index] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }"><font-awesome-icon :icon="['fas', $store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].icon]" /></span></div>
										<div class="nox_account_desktop_widget_7_item_bar" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 211, { options: [((((value[3] - 0.5) * 100) >= 60) ? 0 : (((value[2] - 0.5) * 100) >= 60 ? 1 : 2))] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">
											<div class="nox_account_desktop_widget_7_item_bar_label sell" v-if="!index" v-html="$store.getters.getLanguageText('4.5', 0, { options: [0] })"></div>
											<div class="nox_account_desktop_widget_7_item_bar_value sell">{{ (100 - ((value[2] - 0.5) * 100)).toFixed(0) }}%</div>
											<div class="nox_account_desktop_widget_7_item_bar_label buy" v-if="!index" v-html="$store.getters.getLanguageText('4.5', 0, { options: [1] })"></div>
											<div class="nox_account_desktop_widget_7_item_bar_value buy">{{ ((value[2] - 0.5) * 100).toFixed(0) }}%</div>
											<div class="nox_account_desktop_widget_7_item_bar_line" :style="'width:' + ((value[2] - 0.5) * 100) + '%;'"></div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_buttons">
								<div class="nox_account_desktop_widget_7_text" v-html="noxWidget7['text_' + $store.state.noxLanguage]"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_analysis_data" v-if="noxAnalysisData.length">
					<div class="nox_account_desktop_portlet" v-for="(value, index) in noxAnalysisData" :key="index">
						<div class="nox_account_desktop_portlet_title" v-if="!index && !noxIsAnalysisItemData" v-html="$store.getters.getLanguageText('3.15', 0)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_analysis_data_item">
								<div class="nox_account_desktop_analysis_data_item_title">
									<div class="nox_account_desktop_analysis_data_item_title_text">
										<span><span v-html="$store.getters.getLanguageText('3.15', 1)"></span>:&#160;</span>
										<span v-if="value.message_data.username"><a :href="'https://t.me/' + value.message_data.username + '/' + value.message_id" rel="dofollow" target="_blank">{{ value.title }}</a></span>
										<span v-else>{{ value.title }}</span>
									</div>
									<div class="nox_account_desktop_analysis_data_item_title_created_at">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
								</div>
								<div class="nox_account_desktop_analysis_data_item_content" v-html="value.text"></div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_buttons" v-if="!noxIsAnalysisItemData && value.url">
							<a :href="value.url" class="nox_button big transparent_white" target="_blank"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 159)"></span></a>
						</div>
					</div>
					<div v-if="noxAnalysisDataLimitOffset">
						<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
							<button type="button" class="nox_button big" @click="axios('getAnalysisData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_analysis_data" v-else>
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-if="!noxIsAnalysisItemData" v-html="$store.getters.getLanguageText('3.15', 0)"></div>
						<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.15', (noxIsAnalysisItemData ? 3 : 2))"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxAnalysisData: [],
			noxAnalysisDataLimitOffset: 0,
			noxAnalysisDataLimitCount: 9,
			noxWidget2: {},
			noxWidget2CountdownTime: 0,
			noxWidget2CountdownStatus: 0,
			noxWidget2CountdownValues: '',
			noxWidget2CountdownLabels: '',
			noxWidget2CountdownTimerId: 0,
			noxWidget7: {},
			noxWidget7CountdownTime: 0,
			noxWidget7CountdownValues: '',
			noxWidget7CountdownLabels: '',
			noxWidget7CountdownTimerId: 0,
			noxIsAnalysisItemData: false,
			noxIsWidget2: false,
			noxIsWidget2Countdown: false,
			noxIsWidget7: false,
			noxIsWidget7Countdown: false,
			noxIsWidget7CountdownEnd: false,
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route() {
				this.resetAnalysisData();
				this.axios('getAnalysisData', this.$route.params.id);
			}
		},
		methods: {
			isReady: function() {
				this.axios('getAnalysisData', this.$route.params.id);
			},
			initWidget: function(type, data) {
				var _this = this;
				if (type == 2) {
					_this.noxWidget2 = data;
					_this.noxIsWidget2 = ((data.status && data.data.link) ? true : false);
					if (_this.noxIsWidget2) {
						_this.noxWidget2CountdownTime = new Date(data.data.datetime).getTime();
						_this.noxWidget2CountdownStatus = 0;
						_this.noxIsWidget2Countdown = false;
						if (_this.noxWidget2CountdownTimerId) { clearInterval(_this.noxWidget2CountdownTimerId); }
						if (_this.noxWidget2CountdownTime + (2 * 60 * 60 * 1000) > new Date().getTime()) {
							_this.noxWidget2CountdownStatus = 1;
							if (_this.noxWidget2CountdownTime > new Date().getTime()) {
								_this.noxWidget2CountdownStatus = 2;
								_this.noxIsWidget2Countdown = true;
							}
							_this.getWidgetsCountdown(2);
							_this.noxWidget2CountdownTimerId = setInterval(function() { _this.getWidgetsCountdown(2); }, 1000);
						}
					}
				}
				else if (type == 7) {
					_this.noxWidget7 = data;
					_this.noxIsWidget7 = (data.status ? true : false);
					if (_this.noxIsWidget7) {
						_this.noxWidget7CountdownTime = data.weekends_at * 1000;
						if (_this.noxWidget7CountdownTime > data.time * 1000) {
							_this.noxIsWidget7Countdown = true;
							_this.noxIsWidget7CountdownEnd = false;
							_this.getWidgetsCountdown(7);
							_this.noxWidget7CountdownTimerId = setInterval(function() { _this.getWidgetsCountdown(7); }, 1000);
						}
					}
				}
			},
			getWidgetsCountdown: function(type) {

				var time = (type == 2 ? this.noxWidget2CountdownTime : 0);
						time = (type == 7 ? this.noxWidget7CountdownTime : time);
				var seconds = (time - (new Date().getTime())) / 1000;
				var days = parseInt(seconds / 86400); seconds = seconds % 86400;
				var hours = parseInt(seconds / 3600); seconds = seconds % 3600;
				var minutes = parseInt(seconds / 60);
				var values = '<span>' + this.$parent.$parent.getNumberPad(days) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(hours) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(minutes) + '</span>';
				var labels = '<span>' + this.$store.getters.getLanguageText('1.4', 0) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 1) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 2) + '</span>';

				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
					values = '<span>' + Math.floor(seconds) + '</span>';
					labels = '<span>' + this.$store.getters.getLanguageText('1.4', 3) + '</span>';
				}

				if (type == 2) {
					this.noxWidget2CountdownStatus = 2;
					this.noxWidget2CountdownValues = values;
					this.noxWidget2CountdownLabels = labels;
					if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
						this.noxWidget2CountdownStatus = 1;
						this.noxIsWidget2Countdown = false;
						if (Number(hours) <= -2) {
							this.noxWidget2CountdownStatus = 0; clearInterval(this.noxWidget2CountdownTimerId);
						}
					}
				}
				else if (type == 7) {
					this.noxWidget7CountdownValues = values;
					this.noxWidget7CountdownLabels = labels;
					if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
						if (!this.noxIsWidget7CountdownEnd) { this.noxIsWidget7CountdownEnd = true; this.axios('refreshWidget7'); }
						if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
							this.noxIsWidget7Countdown = false; clearInterval(this.noxWidget7CountdownTimerId);
						}
					}
				}
			},
			resetAnalysisData: function() {
				this.noxAnalysisData = [];
				this.noxAnalysisDataLimitOffset = 0;
				this.noxIsAnalysisItemData = false;
			},
			axios: function(type, var1) {

				var _this = this;
				var config = {
					method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
					headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxInfoTokenForAnalysis }
				};

				if (type == 'getAnalysisData') {
					if (!_this.noxAnalysisDataLimitOffset) {
						_this.noxIsLoading = true;
					}
					if (var1 !== undefined && _this.$store.state.noxRegex_d.test(var1) && var1 > 0) {
						_this.noxIsAnalysisItemData = true;
						config.url = '/v2/info/analysis/item';
						config.params = { id: var1 };
					}
					else {
						_this.noxIsPageContentLoading = true;
						config.url = '/v2/info/analysis';
						config.params = { limitOffset: _this.noxAnalysisDataLimitOffset, limitCount: _this.noxAnalysisDataLimitCount };
					}
				}
				else if (type == 'refreshWidget7') {
					config.url = '/v2/info/analysis/widget';
					config.params = { id: 3 };
				}

				axios(config).then(function(data) {
					if (data.status == 200) {
						if (type == 'getAnalysisData') {
							_this.$parent.$parent.goToTop(!_this.noxAnalysisDataLimitOffset ? true : false);
							_this.$parent.$parent.checkAppVersion(data.headers);
							if (!_this.noxAnalysisDataLimitOffset) {
								if (data.data.widgets_data !== undefined) {
									if (data.data.widgets_data.length) {
										for (var i in data.data.widgets_data) {
											if (data.data.widgets_data[i].id == 1) {
												_this.initWidget(2, data.data.widgets_data[i]);
											}
											else if (data.data.widgets_data[i].id == 3) {
												_this.initWidget(7, data.data.widgets_data[i]);
											}
										}
									}
								}
							}
							if (data.data.analysis_data !== undefined) {
								if (data.data.analysis_data.data.length) {
									for (var j in data.data.analysis_data.data) {
										_this.noxAnalysisData.push(data.data.analysis_data.data[j]);
									}
								}
								_this.noxAnalysisDataLimitOffset = data.data.analysis_data.count > _this.noxAnalysisDataLimitOffset + _this.noxAnalysisDataLimitCount ? _this.noxAnalysisDataLimitOffset + _this.noxAnalysisDataLimitCount : 0;
							}
							else if (data.data.analysis_item_data !== undefined) {
								_this.noxAnalysisData.push(data.data.analysis_item_data);
								if (data.data.analysis_item_data.meta_title) { document.title = data.data.analysis_item_data.meta_title; }
								if (data.data.analysis_item_data.meta_description) { document.querySelector('meta[name="description"]').setAttribute('content', data.data.analysis_item_data.meta_description); }
							}
						}
						else if (type == 'refreshWidget7') {
							_this.initWidget(7, data.data);
						}
					}
				}).catch(function() {
					// ...
				}).finally(function() {
					_this.noxIsLoading = false;
					_this.noxIsPageContentLoading = false;
					_this.$store.state.noxIsLoading = false;
				});
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>
