<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_auth registration_activate">
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.2', 0)"></div>
					<div class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_text"><b>{{ $store.getters.getLanguageText('1.3', 1) }}</b> {{ $store.getters.getLanguageText('1.3.1', 33) }}</div>
						<div class="nox_auth_block_content_form_footer">
							<router-link to="/">[<span v-html="$store.getters.getLanguageText('1.1', 31)"></span>]</router-link>
						</div>
					</div>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	export default {
		components: {
			NoxAuthHeader,
			NoxAuthFooter
		}
	}
</script>
