<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_zp">
				<div class="nox_account_desktop_zp_cron">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title">Планировщик NOX ZP</div>
						<div class="nox_account_desktop_portlet_content">
							<table class="nox_table" v-if="noxZPData.length">
								<thead>
									<tr>
										<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 13)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 40)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 39)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 37)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 38)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
										<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
										<th><font-awesome-icon :icon="['fas', 'times']" /></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(value, index) in noxZPData" :key="index">
										<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ (index + 1) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 13) + ':'">{{ value.user_id }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 40) + ':'">{{ (!value.type ? ('$' + parseFloat(value.amount).toFixed(2)) : '−') }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 39) + ':'">${{ parseFloat(value.balance).toFixed(2) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 37) + ':'" :class="{ blue: !value.payday }">{{ (value.payday ? ('каждое ' + value.payday + ' число') : 'каждый день') }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 38) + ':'"><span v-if="value.comment" v-html="String(value.comment).replace(/\n/g, '<br>')"></span></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'"><toggle-button class="nox_toggle_button" :value="toggleValue(value.status)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStatus(value.id, index, $event)"></toggle-button></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 28) + ':'"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalZP', { type: 'editZP', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 29) + ':'"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalZP', { type: 'deleteZP', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
									</tr>
								</tbody>
							</table>
							<div v-else>Данных нет!</div>
						</div>
						<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading1 }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading1 }">
							<button type="button" class="nox_button big" @click="axios('getZPData')" v-if="noxZPLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							<button type="button" class="nox_button big green" @click="$modal.show('NoxModalZP', { type: 'addZP' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить NOX ZP</button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_zp_payout">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.8', 1)"></div>
						<div class="nox_account_desktop_portlet_content">
							<table class="nox_table" v-if="noxZPPayoutData.length">
								<thead>
									<tr>
										<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 13)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 20)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 21)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(value, index) in noxZPPayoutData" :key="index">
										<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 13) + ':'">{{ value.user_id }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 20) + ':'" v-if="[6,10].includes(value.payment_method_id)">${{ (Math.ceil((value.payment_amount + value.payment_description.fee_amount) * 10000 / (100 - value.payment_description.fee_percent)) / 100).toFixed(2) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 20) + ':'" v-else>${{ (Math.ceil(value.payment_amount * 10000 / (100 - value.payment_description.commission)) / 100).toFixed(2) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 21) + ':'" v-if="[6,10].includes(value.payment_method_id)">{{ value.payment_amount.toFixed(2) }} USDT + ${{ (Math.ceil((value.payment_amount + value.payment_description.fee_amount) * 100 / (100 - value.payment_description.fee_percent) * value.payment_description.fee_percent) / 100).toFixed(2) }} ({{ value.payment_description.fee_percent }}%) + ${{ value.payment_description.fee_amount.toFixed(2) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 21) + ':'" v-else>${{ value.payment_amount.toFixed(2) }} + ${{ (Math.ceil(value.payment_amount * 100 / (100 - value.payment_description.commission) * value.payment_description.commission) / 100).toFixed(2) }} ({{ value.payment_description.commission }}%)</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.9', value.payment_description)"></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-if="value.status == 1"><span class="green" v-html="$store.getters.getLanguageText('1.7.2', 14)"></span></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 2"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 3)"></span></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 3"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 4)"></span></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 4"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 9)"></span></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else><span v-html="$store.getters.getLanguageText('1.7.2', 5)"></span></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
									</tr>
								</tbody>
							</table>
							<div v-else>Данных нет!</div>
						</div>
						<div v-if="noxZPPayoutLimitOffset">
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading2 }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading2 }">
								<button type="button" class="nox_button big" @click="axios('getZPPayoutData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_zp_operations">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title">История операций NOX ZP</div>
						<div class="nox_account_desktop_portlet_content">
							<table class="nox_table" v-if="noxZPOperationsData.length">
								<thead>
									<tr>
										<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 13)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 40)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(value, index) in noxZPOperationsData" :key="index">
										<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 13) + ':'">{{ value.user_id }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 40) + ':'">
											<div :class="{ red: [33].includes(value.type) }">
												<span v-if="[33].includes(value.type)">−</span>
												<span>&#160;${{ value.amount.toFixed(2) }}</span>
											</div>
										</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.3', value.details)"></td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
									</tr>
								</tbody>
							</table>
							<div v-else>Данных нет!</div>
						</div>
						<div v-if="noxZPOperationsLimitOffset">
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading3 }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading3 }">
								<button type="button" class="nox_button big" @click="axios('getZPOperationsData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalZP"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxZPData: [],
			noxZPLimitOffset: 0,
			noxZPLimitCount: 50,
			noxZPPayoutData: [],
			noxZPPayoutLimitOffset: 0,
			noxZPPayoutLimitCount: 50,
			noxZPOperationsData: [],
			noxZPOperationsLimitOffset: 0,
			noxZPOperationsLimitCount: 50,
			noxIsPortletLoading1: false,
			noxIsPortletLoading2: false,
			noxIsPortletLoading3: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getZPData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getZPData') {
						if (!_this.noxZPLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPortletLoading1 = true;
						config.url = '/v2/admin/account/zp';
						config.params = { limitOffset: _this.noxZPLimitOffset, limitCount: _this.noxZPLimitCount };
					}
					else if (type == 'getZPPayoutData') {
						_this.noxIsPortletLoading2 = true;
						config.url = '/v2/admin/account/zp/payout';
						config.params = { limitOffset: _this.noxZPPayoutLimitOffset, limitCount: _this.noxZPPayoutLimitCount };
					}
					else if (type == 'getZPOperationsData') {
						_this.noxIsPortletLoading3 = true;
						config.url = '/v2/admin/account/zp/operations';
						config.params = { limitOffset: _this.noxZPOperationsLimitOffset, limitCount: _this.noxZPOperationsLimitCount };
					}
					else if (type == 'editZPStatus') {
						_this.noxZPData[var2].status = var3;
						config.url = '/v2/admin/account/zp/status';
						config.data = { id: var1, status: var3 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getZPData') {
								_this.$parent.$parent.goToTop(!_this.noxZPLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.zp_data !== undefined) {
									if (data.data.zp_data.data.length) {
										for (var i in data.data.zp_data.data) {
											_this.noxZPData.push(data.data.zp_data.data[i]);
										}
									}
									_this.noxZPLimitOffset = data.data.zp_data.count > _this.noxZPLimitOffset + _this.noxZPLimitCount ? _this.noxZPLimitOffset + _this.noxZPLimitCount : 0;
								}
								if (data.data.zp_payout_data !== undefined) {
									if (data.data.zp_payout_data.data.length) {
										for (var j in data.data.zp_payout_data.data) {
											_this.noxZPPayoutData.push(data.data.zp_payout_data.data[j]);
										}
									}
									_this.noxZPPayoutLimitOffset = data.data.zp_payout_data.count > _this.noxZPPayoutLimitOffset + _this.noxZPPayoutLimitCount ? _this.noxZPPayoutLimitOffset + _this.noxZPPayoutLimitCount : 0;
								}
								if (data.data.zp_operations_data !== undefined) {
									if (data.data.zp_operations_data.data.length) {
										for (var k in data.data.zp_operations_data.data) {
											_this.noxZPOperationsData.push(data.data.zp_operations_data.data[k]);
										}
									}
									_this.noxZPOperationsLimitOffset = data.data.zp_operations_data.count > _this.noxZPOperationsLimitOffset + _this.noxZPOperationsLimitCount ? _this.noxZPOperationsLimitOffset + _this.noxZPOperationsLimitCount : 0;
								}
							}
							else if (type == 'getZPPayoutData') {
								if (data.data.data.length) {
									for (var jj in data.data.data) {
										_this.noxZPPayoutData.push(data.data.data[jj]);
									}
								}
								_this.noxZPPayoutLimitOffset = data.data.count > _this.noxZPPayoutLimitOffset + _this.noxZPPayoutLimitCount ? _this.noxZPPayoutLimitOffset + _this.noxZPPayoutLimitCount : 0;
							}
							else if (type == 'getZPOperationsData') {
								if (data.data.data.length) {
									for (var kk in data.data.data) {
										_this.noxZPOperationsData.push(data.data.data[kk]);
									}
								}
								_this.noxZPOperationsLimitOffset = data.data.count > _this.noxZPOperationsLimitOffset + _this.noxZPOperationsLimitCount ? _this.noxZPOperationsLimitOffset + _this.noxZPOperationsLimitCount : 0;
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading1 = false;
						_this.noxIsPortletLoading2 = false;
						_this.noxIsPortletLoading3 = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForStatus: function(id, index, event) {
				this.axios('editZPStatus', id, index, Number(event.value));
			}
		},
		components: {
			NoxModalZP: () => import('@/views/modal_components/NoxModalZP.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_zp_cron tr th:nth-child(1),
	.nox_account_desktop_zp_cron tr td:nth-child(1),
	.nox_account_desktop_zp_cron tr th:nth-child(7),
	.nox_account_desktop_zp_cron tr td:nth-child(7),
	.nox_account_desktop_zp_cron tr th:nth-child(8),
	.nox_account_desktop_zp_cron tr td:nth-child(8),
	.nox_account_desktop_zp_cron tr th:nth-child(9),
	.nox_account_desktop_zp_cron tr td:nth-child(9),
	.nox_account_desktop_zp_payout tr th:nth-child(1),
	.nox_account_desktop_zp_payout tr td:nth-child(1),
	.nox_account_desktop_zp_operations tr th:nth-child(1),
	.nox_account_desktop_zp_operations tr td:nth-child(1) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_zp_cron tr th:nth-child(2),
	.nox_account_desktop_zp_cron tr td:nth-child(2),
	.nox_account_desktop_zp_payout tr th:nth-child(2),
	.nox_account_desktop_zp_payout tr td:nth-child(2),
	.nox_account_desktop_zp_operations tr th:nth-child(2),
	.nox_account_desktop_zp_operations tr td:nth-child(2) {
		min-width: 71px;
		width: 1%;
	}
	.nox_account_desktop_zp_payout tr th:nth-child(3),
	.nox_account_desktop_zp_payout tr td:nth-child(3),
	.nox_account_desktop_zp_payout tr th:nth-child(4),
	.nox_account_desktop_zp_payout tr td:nth-child(4),
	.nox_account_desktop_zp_payout tr th:nth-child(6),
	.nox_account_desktop_zp_payout tr td:nth-child(6) {
		width: 12%;
	}
	.nox_account_desktop_zp_payout tr th:nth-child(7),
	.nox_account_desktop_zp_payout tr td:nth-child(7) {
		width: 17%;
	}
	.nox_account_desktop_zp_payout tr th:nth-child(5),
	.nox_account_desktop_zp_payout tr td:nth-child(5) {
		word-break: break-all;
		word-break: break-word;
	}
	.nox_account_desktop_zp_payout tr td:nth-child(5) a {
		color: #A5E5FF;
	}
	.nox_account_desktop_zp_payout tr td:nth-child(5) a,
	.nox_account_desktop_zp_payout tr td:nth-child(5) span.address {
		word-break: break-all;
		font-size: 11px;
	}
	.nox_account_desktop_zp_operations tr th:nth-child(3),
	.nox_account_desktop_zp_operations tr td:nth-child(3),
	.nox_account_desktop_zp_operations tr th:nth-child(5),
	.nox_account_desktop_zp_operations tr td:nth-child(5) {
		width: 25%;
	}
</style>
