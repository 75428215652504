<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_auth registration">
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.2', 0)"></div>
					<form class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_row">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.2', 1)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="text" class="nox_input" id="email" name="email" v-model.trim="noxEmail" autocomplete="off">
								<div v-html="noxAlertEmail"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.2', 2)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="password" class="nox_input" id="password" name="password" v-model.trim="noxPassword" autocomplete="new-password">
								<div v-html="noxAlertPassword"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.2', 3)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="password" class="nox_input" id="repassword" name="repassword" v-model.trim="noxRePassword" autocomplete="new-password">
								<div v-html="noxAlertRePassword"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row" v-if="!$cookies.get('noxIsRouteQueryOptNogoo')">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.2', 4)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="text" class="nox_input" id="invitation" name="invitation" v-model.trim="noxInvitation" autocomplete="off">
								<div v-html="noxAlertInvitation"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row agreement last">
							<div class="nox_auth_block_content_form_row_label"></div>
							<div class="nox_auth_block_content_form_row_input">
								<div class="nox_auth_block_content_form_row_checkbox">
									<label class="nox_checkbox" for="agreement1">
										<input type="checkbox" id="agreement1" v-model.trim="noxIsAgreement1">
										<span class="nox_checkbox_mark"></span>
										<span class="nox_checkbox_text" v-html="$store.getters.getLanguageText('2.2', 6)"></span>
									</label>
								</div>
								<div class="between" v-html="noxAlertIsAgreement1"></div>
								<div class="nox_auth_block_content_form_row_checkbox">
									<label class="nox_checkbox" for="agreement2">
										<input type="checkbox" id="agreement2" v-model.trim="noxIsAgreement2">
										<span class="nox_checkbox_mark"></span>
										<span class="nox_checkbox_text" v-html="$store.getters.getLanguageText('2.2', 7)"></span>
									</label>
								</div>
								<div class="between" v-html="noxAlertIsAgreement2"></div>
								<div class="nox_auth_block_content_form_row_checkbox">
									<label class="nox_checkbox" for="agreement3">
										<input type="checkbox" id="agreement3" v-model.trim="noxIsAgreement3">
										<span class="nox_checkbox_mark"></span>
										<span class="nox_checkbox_text" v-html="$store.getters.getLanguageText('2.2', 8)"></span>
									</label>
								</div>
								<div v-html="noxAlertIsAgreement3"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row last">
							<div class="nox_auth_block_content_form_row_recaptcha">
								<vue-recaptcha class="nox_recaptcha" ref="recaptcha" size="normal" theme="light" :sitekey="$store.state.noxRecaptchaSiteKey" @verify="verifyRecaptcha" @expired="resetRecaptcha"></vue-recaptcha>
								<div v-html="noxAlertRecaptcha"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_alert" v-html="noxAlert"></div>
						<div class="nox_auth_block_content_form_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_auth_block_content_form_buttons" :class="{ hide: noxIsLoading }">
							<router-link to="/"><button type="button" class="nox_button common" v-html="$store.getters.getLanguageText('1.1', 10)"></button></router-link>
							<button type="submit" class="nox_button common green" @click="submit($event, 'registration')" v-html="$store.getters.getLanguageText('1.1', 30)"></button>
						</div>
						<div class="nox_auth_block_content_form_footer">
							<router-link to="/password/recovery">[<span v-html="$store.getters.getLanguageText('1.1', 33)"></span>]</router-link>
							<router-link to="/">[<span v-html="$store.getters.getLanguageText('1.1', 31)"></span>]</router-link>
						</div>
					</form>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	import VueRecaptcha from 'vue-recaptcha'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertEmail: '',
			noxAlertPassword: '',
			noxAlertRePassword: '',
			noxAlertInvitation: '',
			noxAlertIsAgreement1: '',
			noxAlertIsAgreement2: '',
			noxAlertIsAgreement3: '',
			noxAlertRecaptcha: '',
			noxEmail: '',
			noxPassword: '',
			noxRePassword: '',
			noxInvitation: '',
			noxRecaptcha: '',
			noxIsAgreement1: false,
			noxIsAgreement2: false,
			noxIsAgreement3: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertEmail = '';
				this.noxAlertPassword = '';
				this.noxAlertRePassword = '';
				this.noxAlertInvitation = '';
				this.noxAlertIsAgreement1 = '';
				this.noxAlertIsAgreement2 = '';
				this.noxAlertIsAgreement3 = '';
				this.noxAlertRecaptcha = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 4); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 5); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 16); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 18); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 19); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 20); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 23); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 24); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 25); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 26); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 52); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 27); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 36); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 37); }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 7); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			resetRecaptcha: function() {
				this.noxRecaptcha = '';
				this.$refs.recaptcha.reset();
			},
			verifyRecaptcha: function(token) {
				this.noxRecaptcha = token;
			},
			submit: function(event, type) {
				event.preventDefault(); this.axios(type);
			},
			axios: function(type) {

				var _this = this;
				if (_this.$store.state.noxOAuthTokenForRegistration) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxOAuthTokenForRegistration }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'registration') {
						if (!_this.noxEmail) {
							_this.noxAlertEmail = _this.getError(1);
						}
						else if (!_this.$store.state.noxRegexEmail.test(_this.noxEmail)) {
							_this.noxAlertEmail = _this.getError(2);
						}
						if (!_this.noxPassword) {
							_this.noxAlertPassword = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexPassword.test(_this.noxPassword) || _this.noxPassword.search(_this.$store.state.noxRegexSearchENRULetters) < 0) {
							_this.noxAlertPassword = _this.getError(4);
						}
						if (!_this.noxRePassword) {
							_this.noxAlertRePassword = _this.getError(5);
						}
						if (_this.noxPassword && _this.noxRePassword && _this.noxPassword != _this.noxRePassword) {
							_this.noxAlertRePassword = _this.getError(6);
						}
						if (!_this.noxIsAgreement1) {
							_this.noxAlertIsAgreement1 = _this.getError(9);
						}
						if (!_this.noxIsAgreement2) {
							_this.noxAlertIsAgreement2 = _this.getError(10);
						}
						if (!_this.noxIsAgreement3) {
							_this.noxAlertIsAgreement3 = _this.getError(11);
						}
						if (!_this.noxRecaptcha) {
							_this.noxAlertRecaptcha = _this.getError(12);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/oauth/registration';
							config.data = { email: _this.noxEmail, password: _this.noxPassword, invitation: _this.noxInvitation.replace(/(.+)\?r=/gi, ''), recaptcha: _this.noxRecaptcha, language: _this.$store.state.noxLanguage };
							config.method = 'post';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 201) {
								if (type == 'registration') {
									_this.$router.push({ path: '/registration/success', query: { email: _this.noxEmail }});
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'registration') {
										if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlertEmail = _this.getError(1); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlertEmail = _this.getError(2); }
										else if (data.response.data.error == 'PASSWORD_IS_EMPTY') { _this.noxAlertPassword = _this.getError(3); }
										else if (data.response.data.error == 'PASSWORD_NOT_VALID') { _this.noxAlertPassword = _this.getError(4); }
										else if (data.response.data.error == 'INVITATION_IS_EMPTY') { _this.noxAlertInvitation = _this.getError(7); }
										else if (data.response.data.error == 'INVITATION_NOT_VALID') { _this.noxAlertInvitation = _this.getError(8); }
										else if (data.response.data.error == 'RECAPTCHA_IS_EMPTY') { _this.noxAlertRecaptcha = _this.getError(12); }
										else if (data.response.data.error == 'RECAPTCHA_NOT_VALID') { _this.noxAlertRecaptcha = _this.getError(12); }
										else if (data.response.data.error == 'LANGUAGE_IS_EMPTY') { _this.noxAlert = _this.getError(13); }
										else if (data.response.data.error == 'LANGUAGE_NOT_VALID') { _this.noxAlert = _this.getError(14); }
									}
								}
								else if (data.response.status == 409) {
									if (type == 'registration') {
										if (data.response.data.error == 'EMAIL_DUPLICATE') { _this.noxAlertEmail = _this.getError(15); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
							if (type == 'registration') {
								_this.resetRecaptcha();
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
			}
		},
		components: {
			NoxAuthHeader,
			NoxAuthFooter,
			VueRecaptcha
		},
		created: function() {
			this.$parent.initRecaptcha();
			if (this.$cookies.get('noxInvitation')) {
				this.noxInvitation = this.$cookies.get('noxInvitation').trim().replace(/\s/g, '+');
			}
		}
	}
</script>
