<template>
	<div class="nox_editor_landing">
		<div class="nox_editor_landing_loading" :class="{ hide: !noxIsLoading }"><div class="nox_loading progress"><span>{{ $parent.noxEditorLoadingProgress }}%</span></div></div>
		<div class="nox_editor_landing_content" :class="{ hide: noxIsLoading }">
			<div class="nox_editor_landing_content_header">
				<div class="nox_editor_landing_content_header_logo"><img src="@/assets/images/mobile_logo.png"></div>
				<div class="nox_editor_landing_content_header_name" v-if="Object.keys(noxEditorLandingData).length">{{ noxEditorLandingData.name }}</div>
				<div class="nox_editor_landing_content_header_items">
					<notifications class="nox_notification" group="saveEditorLandingData" position="top right" :duration="300"></notifications>
					<a class="green" @click="axios('saveEditorLandingData')"><font-awesome-icon :icon="['fas', 'save']" /><span v-html="$store.getters.getLanguageText('1.1', 7)"></span></a>
					<a :href="getEditorLandingPageLink()" class="blue" target="_blank"><font-awesome-icon :icon="['fas', 'globe']" /><span v-html="$store.getters.getLanguageText('1.1', 13)"></span></a>
					<a class="red" @click="exitEditorLanding"><font-awesome-icon :icon="['fas', 'sign-out-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 176)"></span></a>
				</div>
			</div>
			<div class="nox_editor_landing_content_sidebar">
				<div class="nox_editor_landing_content_sidebar_logo">
					<img src="@/assets/images/mobile_logo.png"><span v-html="$store.getters.getLanguageText('3.19.1', 0)"></span>
				</div>
				<div class="nox_editor_landing_content_sidebar_tools" v-if="noxEditorLandingElementsData.length">
					<vue-easy-lightbox class="nox_lightbox" :index="noxEditorLandingLightboxIndex" :imgs="noxEditorLandingLightboxImages" :visible="noxIsEditorLandingLightboxVisible" @hide="hideEditorLandingLightbox"></vue-easy-lightbox>
					<draggable class="nox_editor_landing_content_sidebar_tools_rows" :list="noxEditorLandingElementsData" :group="{ name: 'landing', pull: 'clone', put: false }" :sort="false" :forceFallback="true">
						<div class="nox_editor_landing_content_sidebar_tools_row" v-for="(value, index) in noxEditorLandingElementsData" :key="index">
							<div class="nox_editor_landing_content_sidebar_tools_data">
								<font-awesome-icon :icon="['fas', 'search-plus']" @click="showEditorLandingLightbox(0, [value.image_1])" />
								<img :src="value.image_2" @load="draggableLoadEditorLandingElements">
							</div>
						</div>
					</draggable>
				</div>
			</div>
			<div class="nox_editor_landing_content_desktop">
				<div class="nox_editor_landing_content_desktop_page">
					<draggable class="nox_editor_landing_content_desktop_page_elements" :class="{ empty: !noxEditorLandingPageElements.length }" :list="noxEditorLandingPageElements" group="landing" handle=".nox_editor_landing_content_desktop_page_element_data_image" @change="draggableChangeEditorLandingElement">
						<div class="nox_editor_landing_content_desktop_page_element" v-for="(value, index) in noxEditorLandingPageElements" :key="index">
							<div class="nox_editor_landing_content_desktop_page_element_data">
								<div class="nox_editor_landing_content_desktop_page_element_data_buttons">
									<div @click="draggableSwapEditorLandingElements(index, (index - 1))" v-if="index"><font-awesome-icon :icon="['fas', 'arrow-up']" /></div>
									<div @click="draggableSwapEditorLandingElements(index, (index + 1))" v-if="noxEditorLandingPageElements.length > (index + 1)"><font-awesome-icon :icon="['fas', 'arrow-down']" /></div>
									<div @click="noxEditorLandingPageElements.splice(index, 1)"><font-awesome-icon :icon="['fas', 'times']" /></div>
								</div>
								<div class="nox_editor_landing_content_desktop_page_element_data_image">
									<img :src="value.image_1" @load="draggableLoadEditorLandingElements">
								</div>
							</div>
						</div>
					</draggable>
					<div class="nox_editor_landing_content_desktop_page_element_add" @click="$modal.show('NoxModalLandings', { type: 'addEditorLandingElement', adata: noxEditorLandingElementsData })">
						<font-awesome-icon :icon="['fas', 'plus']" />
						<span v-html="$store.getters.getLanguageText('3.19.1', 2)"></span>
					</div>
				</div>
			</div>
			<div class="nox_editor_landing_content_preload">
				<div v-if="noxEditorLandingElementsData.length">
					<img :src="value.image_1" v-for="(value, index) in noxEditorLandingElementsData" :key="index">
				</div>
			</div>
			<div class="nox_editor_landing_content_modals">
				<div is="NoxModalLandings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import draggable from 'vuedraggable'
	export default {
		data: () => ({
			noxEditorLandingData: {},
			noxEditorLandingDataId: 0,
			noxEditorLandingElementsIds: [],
			noxEditorLandingElementsData: [],
			noxEditorLandingElementsLoadCount: 0,
			noxEditorLandingElementsLoadMaxCount: 0,
			noxEditorLandingSPageElements: '',
			noxEditorLandingPageElements: [],
			noxEditorLandingLightboxIndex: 0,
			noxEditorLandingLightboxImages: [],
			noxIsEditorLandingLightboxVisible: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsEditor) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getEditorLandingData', this.$route.params.id);
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getEditorLandingData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsEditor = true;
						config.url = '/v2/editor/landing';
						config.params = { id: ((var1 !== undefined && _this.$store.state.noxRegex_d.test(var1) && var1 > 0) ? var1 : 0) };
					}
					else if (type == 'saveEditorLandingData') {
						var elementIds = [], elementCodes = [];
						if (_this.noxEditorLandingPageElements.length) {
							for (var i in _this.noxEditorLandingPageElements) {
								elementIds.push(_this.noxEditorLandingPageElements[i].id);
								elementCodes.push(_this.noxEditorLandingPageElements[i].code);
							}
						}
						config.url = '/v2/editor/landing';
						config.data = { id: _this.noxEditorLandingDataId, element_ids: elementIds, element_codes: elementCodes };
						config.method = 'patch';
						_this.noxEditorLandingSPageElements = JSON.stringify(_this.noxEditorLandingPageElements);
						_this.$notify({ group: 'saveEditorLandingData', text: _this.$store.getters.getLanguageText('3.19.1', 1) });
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getEditorLandingData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.landing_elements_data !== undefined) {
									if (data.data.landing_elements_data.length) {
										for (var i in data.data.landing_elements_data) {
											_this.noxEditorLandingElementsIds.push(data.data.landing_elements_data[i].id);
											_this.noxEditorLandingElementsData.push(data.data.landing_elements_data[i]);
										}
										_this.noxEditorLandingElementsLoadCount += data.data.landing_elements_data.length;
										_this.noxEditorLandingElementsLoadMaxCount += data.data.landing_elements_data.length;
									}
									if (data.data.landing_data !== undefined) {
										_this.noxEditorLandingData = data.data.landing_data;
										_this.noxEditorLandingDataId = (Object.keys(_this.noxEditorLandingData).length ? _this.noxEditorLandingData.id : 0);
										if (_this.noxEditorLandingElementsIds.length) {
											if (Object.keys(_this.noxEditorLandingData).length) {
												if (_this.noxEditorLandingData.element_ids.length) {
													for (var j in _this.noxEditorLandingData.element_ids) {
														if (_this.noxEditorLandingElementsIds.includes(_this.noxEditorLandingData.element_ids[j])) {
															_this.noxEditorLandingPageElements.push(_this.noxEditorLandingElementsData[_this.noxEditorLandingElementsData.findIndex(i => i.id == _this.noxEditorLandingData.element_ids[j])]);
														}
													}
													_this.noxEditorLandingElementsLoadCount += _this.noxEditorLandingPageElements.length;
													_this.noxEditorLandingElementsLoadMaxCount += _this.noxEditorLandingPageElements.length;
												}
											}
										}
										_this.noxEditorLandingSPageElements = JSON.stringify(_this.noxEditorLandingPageElements);
									}
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						if (type == 'getEditorLandingData') {
							if (_this.noxEditorLandingElementsLoadCount) { return false; }
						}
						_this.axiosEndIsLoading(type);
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			axiosEndIsLoading: function(type) {
				var _this = this;
				_this.noxIsLoading = false;
				_this.$store.state.noxIsEditor = false;
				_this.$store.state.noxIsLoading = false;
				if (type == 'getEditorLandingData') {
					if (!_this.noxEditorLandingDataId) {
						setTimeout(() => _this.$modal.show('NoxModalLandings', { type: 'createEditorLanding' }), 0);
					}
				}
			},
			getEditorLandingPageLink: function() {
				var elementCodes = [];
				if (this.noxEditorLandingPageElements.length) {
					for (var i in this.noxEditorLandingPageElements) {
						elementCodes.push(this.noxEditorLandingPageElements[i].code);
					}
				}
				return this.$parent.$parent.getLandingLink('', elementCodes);
			},
			draggableLoadEditorLandingElements: function() {
				var _this = this;
				_this.noxEditorLandingElementsLoadCount--;
				_this.$parent.noxEditorLoadingProgress = (100 - (_this.noxEditorLandingElementsLoadMaxCount > 0 ? (_this.noxEditorLandingElementsLoadCount * 100 / _this.noxEditorLandingElementsLoadMaxCount) : 0)).toFixed(0);
				_this.$parent.noxEditorLoadingProgress = (_this.$parent.noxEditorLoadingProgress > 100 ? 100 : _this.$parent.noxEditorLoadingProgress);
				if (_this.noxEditorLandingElementsLoadCount < 1) { setTimeout(function() { _this.axiosEndIsLoading('getEditorLandingData'); }, 100); }
			},
			draggableSwapEditorLandingElements: function(index1, index2) {
				var _this = this;
				setTimeout(function() {
					var temp = _this.noxEditorLandingPageElements[index1];
					_this.noxEditorLandingPageElements.splice(index1, 1, _this.noxEditorLandingPageElements[index2]);
					_this.noxEditorLandingPageElements.splice(index2, 1, temp);
				}, 0);
			},
			draggableChangeEditorLandingElement: function() {
				// ...
			},
			showEditorLandingLightbox: function(index, images) {
				this.noxEditorLandingLightboxIndex = index;
				this.noxEditorLandingLightboxImages = images;
				this.noxIsEditorLandingLightboxVisible = true;
			},
			hideEditorLandingLightbox: function() {
				this.noxIsEditorLandingLightboxVisible = false;
			},
			exitEditorLanding: function() {
				var _this = this;
				setTimeout(function() {
					if (_this.noxEditorLandingSPageElements !== JSON.stringify(_this.noxEditorLandingPageElements)) {
						_this.$modal.show('NoxModalLandings', { type: 'exitEditorLanding' });
					}
					else {
						_this.$router.push({ path: '/account/landings' });
					}
				}, 0);
			}
		},
		components: {
			draggable,
			NoxModalLandings: () => import('@/views/modal_components/NoxModalLandings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_editor_landing,
	.nox_editor_landing_content {
		height: 100%;
	}
	.nox_editor_landing_content_header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: #333333B3;
		min-height: 50px;
		z-index: 2;
	}
	.nox_editor_landing_content_header_logo {
		float: left;
		margin: 0 0 0 15px;
		padding: 14px 0 16px 0;
		line-height: 20px;
		display: none;
	}
	.nox_editor_landing_content_header_logo img {
		margin: -2px 0 0 0;
		height: 13px;
	}
	.nox_editor_landing_content_header_name {
		float: left;
		margin: 0 0 0 320px;
		padding: 14px 0 16px 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 20px;
		width: 250px;
	}
	.nox_editor_landing_content_header_items {
		position: absolute;
		top: 0;
		right: 0;
		padding: 10px 20px;
	}
	.nox_editor_landing_content_header_items a {
		float: left;
		margin: 0 5px 0 0;
		padding: 0 12px;
		border: 1px solid #FFFFFF;
		border-radius: 5px;
		box-shadow: 0 0 1px #333333B3;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		color: #FFFFFF;
		cursor: pointer;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 27px;
		height: 30px;
	}
	.nox_editor_landing_content_header_items a:last-child {
		margin: 0;
	}
	.nox_editor_landing_content_header_items a:hover {
		opacity: 0.8;
	}
	.nox_editor_landing_content_header_items a.blue {
		border-color: #5093CB;
		background: #5093CB;
	}
	.nox_editor_landing_content_header_items a.green {
		border-color: #48B849;
		background: #48B849;
		min-width: 125px;
	}
	.nox_editor_landing_content_header_items a.red {
		border-color: #E26868;
		background: #E26868;
	}
	.nox_editor_landing_content_header_items a span {
		margin: 0 0 0 8px;
	}
	.nox_editor_landing_content_header_items .nox_notification {
		position: absolute;
		top: 10px !important;
		left: 20px !important;
		width: 125px !important;
	}
	.nox_editor_landing_content_header_items .nox_notification .vue-notification {
		padding: 4px 10px 6px 10px;
		border: 1px solid #FFFFFF;
		border-radius: 5px;
		background: #48B849;
		color: #FFFFFF;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 18px;
	}
	.nox_editor_landing_content_sidebar {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #333333B3;
		width: 300px;
		height: 100%;
		z-index: 2;
	}
	.nox_editor_landing_content_sidebar_logo {
		position: fixed;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 16px;
		line-height: 48px;
		width: 300px;
		height: 50px;
	}
	.nox_editor_landing_content_sidebar_logo img {
		margin: -2px 2px 0 0;
		height: 13px;
	}
	.nox_editor_landing_content_sidebar_tools {
		margin: 70px 0 0 0;
		padding: 0 20px;
		overflow-y: scroll;
		overflow-anchor: auto;
		width: 300px;
		height: calc(100% - 90px);
	}
	.nox_editor_landing_content_sidebar_tools_row,
	.nox_editor_landing_content_desktop_page_element {
		margin: 5px 0 0 0;
	}
	.nox_editor_landing_content_sidebar_tools_row:first-child,
	.nox_editor_landing_content_desktop_page_element:first-child {
		margin: 0;
	}
	.nox_editor_landing_content_sidebar_tools_data,
	.nox_editor_landing_content_desktop_page_element_data {
		position: relative;
		border: 5px solid #F1F1F1;
		border-radius: 5px;
		background: #FFFFFF;
		text-align: center;
		cursor: pointer;
		width: 260px;
		min-height: 30px;
	}
	.nox_editor_landing_content_sidebar_tools_data svg {
		position: absolute;
		top: 5px;
		left: 5px;
		padding: 7px;
		border: 1px solid #555555;
		border-radius: 50%;
		box-shadow: 0 0 5px #555555;
		background: #FFFFFF;
		color: #555555;
		font-size: 30px;
		display: none;
	}
	.nox_editor_landing_content_sidebar_tools_data svg:hover {
		background: #555555;
		color: #FFFFFF;
	}
	.nox_editor_landing_content_sidebar_tools_data:hover svg {
		display: block;
	}
	.nox_editor_landing_content_desktop {
		position: relative;
		padding: 70px 20px 20px 320px;
		background: #FAFAFA;
		color: #333333;
		display: table;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.nox_editor_landing_content_desktop_page,
	.nox_editor_landing_content_desktop_page_elements {
		position: relative;
		display: table;
		width: 100%;
		height: 100%;
	}
	.nox_editor_landing_content_desktop_page_elements {
		padding: 5px;
		border: 1px dashed #BBBBBB;
		border-radius: 5px;
	}
	.nox_editor_landing_content_desktop_page_elements img {
		max-width: 645px;
	}
	.nox_editor_landing_content_desktop_page_elements .nox_editor_landing_content_sidebar_tools_data,
	.nox_editor_landing_content_desktop_page_elements .nox_editor_landing_content_desktop_page_element_data {
		border: 1px dashed #BBBBBB;
		width: auto;
		min-height: 42px;
	}
	.nox_editor_landing_content_desktop_page_elements .nox_editor_landing_content_sidebar_tools_data:hover svg {
		display: none;
	}
	.nox_editor_landing_content_desktop_page_element_add {
		padding: 10px 0;
		border: 1px dotted #48B849;
		border-radius: 21px;
		background: #48B8490D;
		color: #48B849;
		text-align: center;
		font-size: 13px;
		line-height: 20px;
		display: none;
	}
	.nox_editor_landing_content_desktop_page_element_add svg {
		font-size: 12px;
	}
	.nox_editor_landing_content_desktop_page_element_add span {
		margin: 0 0 0 5px;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_editor_landing_content_desktop_page_element_data_buttons {
		position: absolute;
		top: 10px;
		right: 10px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		z-index: 1;
	}
	.nox_editor_landing_content_desktop_page_element_data_buttons div {
		float: left;
		margin: 0 0 0 3px;
		border: 1px solid #5093CB;
		border-radius: 50%;
		background: #FFFFFF;
		color: #5093CB;
		text-align: center;
		cursor: pointer;
		font-size: 9px;
		line-height: 18px;
		display: inline-block;
		width: 20px;
		height: 20px;
	}
	.nox_editor_landing_content_desktop_page_element_data_buttons div:first-child {
		margin: 0;
	}
	.nox_editor_landing_content_desktop_page_element_data_buttons div:last-child {
		border-color: #E26868;
		color: #E26868;
	}
	.nox_editor_landing_content_preload {
		position: absolute;
		display: none;
		width: 0;
		height: 0;
	}

	@media (min-width: 1001px) and (hover: hover) {
		.nox_editor_landing_content_desktop_page_element_data_buttons div:hover {
			background: #5093CB;
			color: #FFFFFF;
		}
		.nox_editor_landing_content_desktop_page_element_data_buttons div:last-child:hover {
			background: #E26868;
			color: #FFFFFF;
		}
	}
	@media (max-width: 1000px) {
		.nox_editor_landing_content_header_logo {
			display: block;
		}
		.nox_editor_landing_content_header_name {
			margin: 0 0 0 4px;
		}
		.nox_editor_landing_content_header_items {
			padding: 10px 15px 10px 5px;
		}
		.nox_editor_landing_content_header_items a,
		.nox_editor_landing_content_header_items a.blue,
		.nox_editor_landing_content_header_items a.green,
		.nox_editor_landing_content_header_items a.red {
			padding: 0;
			border-radius: 50%;
			font-size: 12px;
			min-width: 30px;
			min-height: 30px;
			width: 30px;
			height: 30px;
		}
		.nox_editor_landing_content_header_items a span {
			display: none;
		}
		.nox_editor_landing_content_header_items .nox_notification {
			left: 5px !important;
			width: 100px !important;
		}
		.nox_editor_landing_content_header_items .nox_notification .vue-notification {
			border-radius: 15px;
		}
		.nox_editor_landing_content_sidebar {
			display: none;
		}
		.nox_editor_landing_content_desktop {
			padding: 65px 15px 15px 15px;
		}
		.nox_editor_landing_content_desktop_page_element {
			margin: 3px 0 0 0;
		}
		.nox_editor_landing_content_desktop_page_elements {
			margin: 0 0 15px 0;
			padding: 3px;
			border-style: dotted;
			border-radius: 3px;
			height: auto;
		}
		.nox_editor_landing_content_desktop_page_elements.empty {
			display: none;
		}
		.nox_editor_landing_content_desktop_page_elements .nox_editor_landing_content_desktop_page_element_data {
			border-style: dotted;
			border-radius: 2px;
		}
		.nox_editor_landing_content_desktop_page_element_add {
			display: block;
		}
	}
	@media (max-width: 700px) {
		.nox_editor_landing_content_desktop_page_elements img {
			max-width: 100%;
		}
	}
	@media (max-width: 424px) {
		.nox_editor_landing_content_header_name {
			width: 210px;
		}
	}
	@media (max-width: 374px) {
		.nox_editor_landing_content_header_name {
			width: 155px;
		}
	}
	@media (max-width: 319px) {
		.nox_editor_landing_content_header_name {
			width: 100px;
		}
	}
</style>
