<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_analysis" :class="{ full: !noxIsWidget7 }">
				<div class="nox_account_desktop_widgets">
					<div class="nox_account_desktop_widget nox_account_desktop_widget_7" v-if="noxIsWidget7">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="noxWidget7['name_' + $store.state.noxLanguage]"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_sdata" v-if="noxWidget7.data && noxWidget7.data.is_hidden_in_weekends && noxWidget7.is_weekends && noxIsWidget7Countdown">
									<div class="nox_countdown">
										<div class="nox_countdown_content">
											<div class="title" v-html="$store.getters.getLanguageText('4.5', 1)"></div>
											<div class="values" v-html="noxWidget7CountdownValues"></div>
											<div class="labels" v-html="noxWidget7CountdownLabels"></div>
										</div>
									</div>
								</div>
								<div class="nox_account_desktop_portlet_content_sdata" v-else>
									<div class="nox_account_desktop_widget_7_item" v-if="noxWidget7.data && noxWidget7.data.clv_currency_pairs && !noxWidget7.data.hidden_symbols.includes(name)" v-for="(value, name, index) in noxWidget7.data.clv_currency_pairs" :key="index">
										<div class="nox_account_desktop_widget_7_item_symbol" :class="$store.state.noxWidget7CLVDataByMultipliers[value[0].toFixed(1)].color" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 209, { options: [$store.state.noxWidget7CLVDataByMultipliers[value[0].toFixed(1)].index], symbol: name.toUpperCase() }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">{{ name }}</div>
										<div class="nox_account_desktop_widget_7_item_icon" :class="$store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].color"><span class="nox_account_desktop_widget_7_item_icon_block" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 210, { options: [$store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].index] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }"><font-awesome-icon :icon="['fas', $store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].icon]" /></span></div>
										<div class="nox_account_desktop_widget_7_item_bar" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 211, { options: [((((value[3] - 0.5) * 100) >= 60) ? 0 : (((value[2] - 0.5) * 100) >= 60 ? 1 : 2))] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">
											<div class="nox_account_desktop_widget_7_item_bar_label sell" v-if="!index" v-html="$store.getters.getLanguageText('4.5', 0, { options: [0] })"></div>
											<div class="nox_account_desktop_widget_7_item_bar_value sell">{{ (100 - ((value[2] - 0.5) * 100)).toFixed(0) }}%</div>
											<div class="nox_account_desktop_widget_7_item_bar_label buy" v-if="!index" v-html="$store.getters.getLanguageText('4.5', 0, { options: [1] })"></div>
											<div class="nox_account_desktop_widget_7_item_bar_value buy">{{ ((value[2] - 0.5) * 100).toFixed(0) }}%</div>
											<div class="nox_account_desktop_widget_7_item_bar_line" :style="'width:' + ((value[2] - 0.5) * 100) + '%;'"></div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_buttons">
								<div class="nox_account_desktop_widget_7_text" v-html="noxWidget7['text_' + $store.state.noxLanguage]"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_analysis_data" v-if="noxAnalysisData.length">
					<div class="nox_account_desktop_portlet" v-for="(value, index) in noxAnalysisData" :key="index">
						<div class="nox_account_desktop_portlet_title" v-if="!index" v-html="$store.getters.getLanguageText('3.15', 0)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_analysis_data_item">
								<div class="nox_account_desktop_analysis_data_item_edit" @click="$modal.show('NoxModalAnalysis', { type: 'deleteAnalysis', index: index, id: value.id })" v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'times']" /></div>
								<div class="nox_account_desktop_analysis_data_item_title" :class="{ fix: $store.state.noxAccountData.group == 1 }">
									<div class="nox_account_desktop_analysis_data_item_title_text">
										<span><span v-html="$store.getters.getLanguageText('3.15', 1)"></span>:&#160;</span>
										<span v-if="value.message_data.username"><a :href="'https://t.me/' + value.message_data.username + '/' + value.message_id" rel="dofollow" target="_blank">{{ value.title }}</a></span>
										<span v-else>{{ value.title }}</span>
									</div>
									<div class="nox_account_desktop_analysis_data_item_title_created_at">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
								</div>
								<div class="nox_account_desktop_analysis_data_item_content" v-html="value.text"></div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_buttons" v-if="value.url">
							<a :href="value.url" class="nox_button big transparent_white" target="_blank"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 159)"></span></a>
						</div>
					</div>
					<div v-if="noxAnalysisDataLimitOffset">
						<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
							<button type="button" class="nox_button big" @click="axios('getAnalysisData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_analysis_data" v-else>
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.15', 0)"></div>
						<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.15', 2)"></div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalAnalysis"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxAnalysisData: [],
			noxAnalysisDataLimitOffset: 0,
			noxAnalysisDataLimitCount: 9,
			noxWidget7: {},
			noxWidget7CountdownTime: 0,
			noxWidget7CountdownValues: '',
			noxWidget7CountdownLabels: '',
			noxWidget7CountdownTimerId: 0,
			noxIsWidget7: false,
			noxIsWidget7Countdown: false,
			noxIsWidget7CountdownEnd: false,
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getAnalysisData');
			},
			initWidget: function(type, data) {
				var _this = this;
				if (type == 7) {
					_this.noxWidget7 = data;
					_this.noxIsWidget7 = (data.status ? true : false);
					if (_this.noxIsWidget7) {
						_this.noxWidget7CountdownTime = data.weekends_at * 1000;
						if (_this.noxWidget7CountdownTime > data.time * 1000) {
							_this.noxIsWidget7Countdown = true;
							_this.noxIsWidget7CountdownEnd = false;
							_this.getWidgetsCountdown(7);
							_this.noxWidget7CountdownTimerId = setInterval(function() { _this.getWidgetsCountdown(7); }, 1000);
						}
					}
				}
			},
			getWidgetsCountdown: function(type) {

				var time = (type == 7 ? this.noxWidget7CountdownTime : 0);
				var seconds = (time - (new Date().getTime())) / 1000;
				var days = parseInt(seconds / 86400); seconds = seconds % 86400;
				var hours = parseInt(seconds / 3600); seconds = seconds % 3600;
				var minutes = parseInt(seconds / 60);
				var values = '<span>' + this.$parent.$parent.getNumberPad(days) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(hours) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(minutes) + '</span>';
				var labels = '<span>' + this.$store.getters.getLanguageText('1.4', 0) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 1) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 2) + '</span>';

				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
					values = '<span>' + Math.floor(seconds) + '</span>';
					labels = '<span>' + this.$store.getters.getLanguageText('1.4', 3) + '</span>';
				}

				if (type == 7) {
					this.noxWidget7CountdownValues = values;
					this.noxWidget7CountdownLabels = labels;
					if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
						if (!this.noxIsWidget7CountdownEnd) { this.noxIsWidget7CountdownEnd = true; this.axios('refreshWidget7'); }
						if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
							this.noxIsWidget7Countdown = false; clearInterval(this.noxWidget7CountdownTimerId);
						}
					}
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getAnalysisData') {
						if (!_this.noxAnalysisDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPageContentLoading = true;
						config.url = '/v2/account/analysis';
						config.params = { limitOffset: _this.noxAnalysisDataLimitOffset, limitCount: _this.noxAnalysisDataLimitCount };
					}
					else if (type == 'refreshWidget7') {
						config.url = '/v2/account/analysis/widget';
						config.params = { id: 3 };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getAnalysisData') {
								_this.$parent.$parent.goToTop(!_this.noxAnalysisDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (!_this.noxAnalysisDataLimitOffset) {
									if (data.data.widgets_data !== undefined) {
										if (data.data.widgets_data.length) {
											for (var i in data.data.widgets_data) {
												if (data.data.widgets_data[i].id == 3) {
													_this.initWidget(7, data.data.widgets_data[i]);
												}
											}
										}
									}
								}
								if (data.data.analysis_data !== undefined) {
									if (data.data.analysis_data.data.length) {
										for (var j in data.data.analysis_data.data) {
											_this.noxAnalysisData.push(data.data.analysis_data.data[j]);
										}
									}
									_this.noxAnalysisDataLimitOffset = data.data.analysis_data.count > _this.noxAnalysisDataLimitOffset + _this.noxAnalysisDataLimitCount ? _this.noxAnalysisDataLimitOffset + _this.noxAnalysisDataLimitCount : 0;
								}
							}
							else if (type == 'refreshWidget7') {
								_this.initWidget(7, data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPageContentLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$router.push({ path: String(_this.$route.path).replace(/account/gi, 'info') });
				}
			}
		},
		components: {
			NoxModalAnalysis: () => import('@/views/modal_components/NoxModalAnalysis.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_analysis {
		position: relative;
		padding: 0 315px 0 0;
	}
	.nox_account_desktop_analysis.full {
		padding: 0;
	}
	.nox_account_desktop_analysis_data_item {
		position: relative;
	}
	.nox_account_desktop_analysis_data_item_edit {
		position: absolute;
		top: 0;
		right: 0;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		font-size: 10px;
		line-height: 18px;
		width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_account_desktop_analysis_data_item_edit:hover {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_account_desktop_analysis_data_item_title {
		position: relative;
		margin: 0 0 25px 0;
		padding: 0 120px 5px 0;
		border-bottom: 1px dashed #999999;
	}
	.nox_account_desktop_analysis_data_item_title.fix {
		padding: 0 155px 5px 0;
	}
	.nox_account_desktop_analysis_data_item_title.fix .nox_account_desktop_analysis_data_item_title_created_at {
		right: 35px;
	}
	.nox_account_desktop_analysis_data_item_title_text {
		padding: 2px 0 6px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 16px;
	}
	.nox_account_desktop_analysis_data_item_title_created_at {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 10px;
	}
	.nox_account_desktop_analysis_data_item_content .photo,
	.nox_account_desktop_analysis_data_item_content .iframe {
		text-align: center;
		display: inline-block;
		width: 100%;
	}
	.nox_account_desktop_analysis_data_item_content .photo {
		margin: 20px 0;
	}
	.nox_account_desktop_analysis_data_item_content .photo:first-child {
		margin: 0 0 20px 0;
	}
	.nox_account_desktop_analysis_data_item_content .photo img,
	.nox_account_desktop_analysis_data_item_content .iframe iframe {
		border: 2px solid #777777;
		border-radius: 10px;
		box-shadow: 0 0 5px #555555;
		background: #000000;
		max-width: 260px;
	}
	.nox_account_desktop_analysis_data_item_content .iframe iframe {
		margin: 0 0 -7px 0;
		max-width: 500px;
	}

	@media (max-width: 1150px) {
		.nox_account_desktop_analysis_data_item_title {
			padding: 0 0 5px 0;
		}
		.nox_account_desktop_analysis_data_item_title.fix {
			padding: 0 25px 5px 0;
		}
		.nox_account_desktop_analysis_data_item_title_created_at {
			position: relative;
			top: -2px !important;
			right: 0 !important;
			display: table;
			width: 100%;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_desktop_analysis {
			padding: 0 310px 0 0;
		}
	}
	@media (max-width: 644px) {
		.nox_account_desktop_analysis {
			padding: 0;
		}
		.nox_account_desktop_analysis_data_item_content .iframe iframe {
			width: 100%;
		}
	}
	@media (max-width: 500px) {
		.nox_account_desktop_analysis_data_item_content .photo img {
			width: 100%;
		}
	}
</style>
