<template>
  <div class="nox_account_desktop_page">
    <div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
    <div class="nox_account_desktop_page_content" v-else>
      <div class="nox_account_desktop_verification">
        <div class="nox_account_desktop_portlet red" v-if="![1,2].includes($store.state.noxAccountData.group)">
          <div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.9', 0)"></div>
        </div>
        <div class="nox_account_desktop_portlet" v-if="![1,2].includes($store.state.noxAccountData.group) && noxIsVerificationAdd">
          <div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.9', 1)"></div>
          <div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.9', 2)"></div>
          <div class="nox_account_desktop_portlet_buttons">
            <div ref="cropImageForVerification" @click="$parent.$parent.chooseCropperImage('cropImageForVerification')"></div>
            <button type="button" class="nox_button big green" @click="$modal.show('NoxModalVerification', { type: 'editCountryByUser' })"><font-awesome-icon :icon="['fas', 'user-check']" /><span v-html="$store.getters.getLanguageText('1.1', 90)"></span></button>
          </div>
        </div>
        <div class="nox_account_desktop_verification_navigation" v-if="[1,2].includes($store.state.noxAccountData.group)">
          <div class="nox_account_desktop_verification_navigation_filters">
            <div class="nox_account_desktop_verification_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
            <div class="nox_account_desktop_verification_navigation_filters_value" :class="{ active: noxVerificationsDataFilterType == 0 }"><div @click="filterVerificationsData(0, 0)">На рассмотрении</div></div>
            <div class="nox_account_desktop_verification_navigation_filters_value" :class="{ active: noxVerificationsDataFilterType == 1 }"><div @click="filterVerificationsData(1, 0)">Одобренные</div></div>
            <div class="nox_account_desktop_verification_navigation_filters_value" :class="{ active: noxVerificationsDataFilterType == 2 }"><div @click="filterVerificationsData(2, 0)">Отклоненные</div></div>
            <div class="nox_account_desktop_verification_navigation_filters_value" :class="{ active: noxVerificationsDataFilterType == 3 }"><div @click="filterVerificationsData(3, 0)">Отмененные</div></div>
            <div class="nox_account_desktop_verification_navigation_filters_value" :class="{ active: noxVerificationsDataFilterType == 4 }"><div @click="filterVerificationsData(4, 0)">Забаненные</div></div>
          </div>
        </div>
        <div v-if="noxVerificationsData.length">
          <vue-easy-lightbox class="nox_lightbox" :index="noxLightboxIndex" :imgs="noxLightboxImages" :visible="noxIsLightboxVisible" @hide="hideLightbox"></vue-easy-lightbox>
          <div class="nox_account_desktop_portlet" v-for="(value, index) in noxVerificationsData" :key="index">
            <div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.9', 3, { number: (index + 1), id: value.id })"></div>
            <div class="nox_account_desktop_portlet_content">
              <div class="nox_account_desktop_verification_item">
                <div class="nox_account_desktop_verification_item_image image" @click="showLightbox(0, [value.image_1])" v-if="value.image_1 && value.image_2">
                  <font-awesome-icon :icon="['fas', 'search-plus']" />
                  <img :src="value.image_2">
                </div>
                <div class="nox_account_desktop_verification_item_image empty" v-else>
                  <font-awesome-icon :icon="['fas', 'address-card']" />
                  <span v-html="$store.getters.getLanguageText('3.9', 4)"></span>
                </div>
                <div class="nox_account_desktop_verification_item_data">
                  <div class="nox_account_desktop_verification_item_data_row">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span v-html="$store.getters.getLanguageText('3.9', 5)"></span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 38), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row" v-if="value.updated_at">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span v-html="$store.getters.getLanguageText('3.9', 6)"></span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 39), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">{{ new Date(value.updated_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row" v-if="[1,2].includes($store.state.noxAccountData.group)">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span>Id пользователя</span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Id пользователя</b> - внутренний идентификатор клиента в системе NOX.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">{{ value.user_id }}</div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span v-html="$store.getters.getLanguageText('3.9', 7)"></span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 40), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">
                      <div class="nox_account_desktop_verification_item_data_value_content">{{ value.document }}</div>
                    </div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span v-html="$store.getters.getLanguageText('3.9', 16)"></span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 69), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">
                      <div class="nox_account_desktop_verification_item_data_value_content">
                        <span class="margin_right_10">{{ value.country }} ({{ noxCountries[value.country] }})</span>
                        <button type="button" class="nox_button nox_is_desktop blue" @click="$modal.show('NoxModalVerification', { type: 'editCountryByAdmin', index: index, id: value.id })" v-if="[1,2].includes($store.state.noxAccountData.group)" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
                        <button type="button" class="nox_button nox_is_mobile blue" @click="$modal.show('NoxModalVerification', { type: 'editCountryByAdmin', index: index, id: value.id })" v-if="[1,2].includes($store.state.noxAccountData.group)"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
                      </div>
                    </div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row" v-if="[1,2].includes($store.state.noxAccountData.group) && value.username">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span>Псевдоним</span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Псевдоним</b> - псевдоним клиента системы NOX.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">{{ value.username }}</div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row" v-if="[1,2].includes($store.state.noxAccountData.group)">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span>E-mail</span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>E-mail</b> - действующий адрес электронной почты, который принадлежит владельцу аккаунта в системе NOX.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">{{ value.email }}</div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row" v-if="[1,2].includes($store.state.noxAccountData.group)">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span>Номер телефона</span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Номер телефона</b> - действующий номер телефона, который принадлежит владельцу аккаунта в системе NOX.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">{{ value.phone }}</div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span v-html="$store.getters.getLanguageText('3.9', 8)"></span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 41), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">
                      <span class="status green" v-if="value.status == 1" v-html="$store.getters.getLanguageText('3.9', 10)"></span>
                      <span class="status red" v-else-if="value.status == 2" v-html="$store.getters.getLanguageText('3.9', 11)"></span>
                      <span class="status red" v-else-if="value.status == 3" v-html="$store.getters.getLanguageText('3.9', 12)"></span>
                      <span class="status red" v-else-if="value.status == 4" v-html="$store.getters.getLanguageText('3.9', 13)"></span>
                      <span class="status orange" v-else v-html="$store.getters.getLanguageText('3.9', 14)"></span>
                    </div>
                  </div>
                  <div class="nox_account_desktop_verification_item_data_row" v-if="(value.status == 1 && ![1,2].includes($store.state.noxAccountData.group)) || value.comment">
                    <div class="nox_account_desktop_verification_item_data_label">
                      <span v-html="$store.getters.getLanguageText('3.9', 9)"></span>
                      <font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 42), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
                    </div>
                    <div class="nox_account_desktop_verification_item_data_value">
                      <div class="nox_account_desktop_verification_item_data_value_content">
                        <span v-if="value.status == 1" v-html="$store.getters.getLanguageText('3.9', 15)"></span>
                        <span v-else>{{ value.comment }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!value.status">
              <div class="nox_account_desktop_portlet_buttons" v-if="[1,2].includes($store.state.noxAccountData.group)">
                <button type="button" class="nox_button big red" @click="$modal.show('NoxModalVerification', { type: 'banVerification', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'ban']" /><span v-html="$store.getters.getLanguageText('1.1', 40)"></span></button>
                <button type="button" class="nox_button big red" @click="$modal.show('NoxModalVerification', { type: 'rejectVerification', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /><span v-html="$store.getters.getLanguageText('1.1', 41)"></span></button>
                <button type="button" class="nox_button big green" @click="$modal.show('NoxModalVerification', { type: 'approveVerification', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'check']" /><span v-html="$store.getters.getLanguageText('1.1', 42)"></span></button>
              </div>
              <div class="nox_account_desktop_portlet_buttons" v-else>
                <button type="button" class="nox_button big red" @click="$modal.show('NoxModalVerification', { type: 'cancelVerification', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /><span v-html="$store.getters.getLanguageText('1.1', 9)"></span></button>
              </div>
            </div>
          </div>
          <div v-if="noxVerificationsDataLimitOffset">
            <div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
            <div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
              <button type="button" class="nox_button big" @click="axios('getVerificationsData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="nox_account_desktop_portlet" v-if="[1,2].includes($store.state.noxAccountData.group)">
            <div class="nox_account_desktop_portlet_content"><p>Не найдено ни одного документа.</p></div>
          </div>
        </div>
      </div>
      <div class="nox_account_desktop_modals">
        <div is="NoxModalCropper"></div>
        <div is="NoxModalVerification"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data: () => ({
      noxVerificationsData: [],
      noxVerificationsDataFilterType: 0,
      noxVerificationsDataFilterSort: 0,
      noxVerificationsDataLimitOffset: 0,
      noxVerificationsDataLimitCount: 5,
      noxVerificationsDataLimitAdded: 0,
      noxCountries: {},
      noxLightboxIndex: 0,
      noxLightboxImages: [],
      noxIsVerificationAdd: true,
      noxIsLightboxVisible: false,
      noxIsPageContentLoading: false,
      noxIsLoading: true
    }),
    methods: {
      isReady: function() {
        if (this.$store.state.noxIsAccount) {
          setTimeout(this.isReady, 0); return false;
        }
        this.noxCountries = this.$parent.$parent.getCountries();
        this.axios('getVerificationsData');
      },
      filterVerificationsData: function(type, sort) {
        this.$parent.$parent.goToTop(true);
        this.noxVerificationsData = [];
        this.noxVerificationsDataFilterType = type;
        this.noxVerificationsDataFilterSort = sort;
        this.noxVerificationsDataLimitOffset = 0;
        this.axios('getVerificationsData');
      },
      axios: function(type) {

        var _this = this;
        if (localStorage.getItem('noxToken')) {

          var config = {
            method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
          };

          if (type == 'getVerificationsData') {
            if (!_this.noxVerificationsDataLimitOffset) {
              _this.noxIsLoading = true;
              _this.$store.state.noxIsAccount = true;
            }
            _this.noxIsPageContentLoading = true;
            config.url = '/v2' + ([1,2].includes(_this.$store.state.noxAccountData.group) ? '/admin' : '') + '/account/verification';
            config.params = { limitOffset: _this.noxVerificationsDataLimitOffset + _this.noxVerificationsDataLimitAdded, limitCount: _this.noxVerificationsDataLimitCount, filterType: _this.noxVerificationsDataFilterType, filterSort: _this.noxVerificationsDataFilterSort };
          }

          axios(config).then(function(data) {
            if (data.status == 200) {
              if (type == 'getVerificationsData') {
                _this.$parent.$parent.goToTop(!_this.noxVerificationsDataLimitOffset ? true : false);
                _this.$parent.$parent.checkAppVersion(data.headers);
                if (data.data.data.length) {
                  for (var i in data.data.data) {
                    if (data.data.data[i].phone !== undefined) {
                      data.data.data[i].phone = ((data.data.data[i].phone ? '+' : '') + data.data.data[i].phone);
                    }
                    if ([0,1].includes(data.data.data[i].status)) { _this.noxIsVerificationAdd = false; }
                    _this.noxVerificationsData.push(data.data.data[i]);
                  }
                }
                _this.noxVerificationsDataLimitOffset = data.data.count - _this.noxVerificationsDataLimitAdded > _this.noxVerificationsDataLimitOffset + _this.noxVerificationsDataLimitCount ? _this.noxVerificationsDataLimitOffset + _this.noxVerificationsDataLimitCount : 0;
              }
            }
          }).catch(function() {
            _this.$parent.$parent.exit();
          }).finally(function() {
            _this.noxIsLoading = false;
            _this.noxIsPageContentLoading = false;
            _this.$store.state.noxIsAccount = false;
            _this.$store.state.noxIsLoading = false;
          });
        }
        else {
          _this.$parent.$parent.exit();
        }
      },
      showLightbox: function(index, images) {
        this.noxLightboxIndex = index;
        this.noxLightboxImages = images;
        this.noxIsLightboxVisible = true;
      },
      hideLightbox: function() {
        this.noxIsLightboxVisible = false;
      }
    },
    components: {
      NoxModalCropper: () => import('@/views/modal_components/NoxModalCropper.vue'),
      NoxModalVerification: () => import('@/views/modal_components/NoxModalVerification.vue')
    },
    created: function() {
      this.isReady();
    }
  }
</script>

<style>
  .nox_account_desktop_verification_navigation {
    margin: 0 0 15px 0;
    display: table;
    width: 100%;
  }
  .nox_account_desktop_verification_navigation_filters {
    position: relative;
    margin: 2px 0 0 0;
    padding: 0 0 0 105px;
  }
  .nox_account_desktop_verification_navigation_filters:first-child {
    margin: 0;
  }
  .nox_account_desktop_verification_navigation_filters_label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px;
    border-radius: 15px 2px 2px 15px;
    background: #333333B3;
    text-align: center;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    line-height: 28px;
    width: 105px;
    height: 30px;
  }
  .nox_account_desktop_verification_navigation_filters_value {
    float: left;
    padding: 0 0 0 2px;
    width: 20%;
  }
  .nox_account_desktop_verification_navigation_filters_value div {
    border-radius: 2px;
    background: #111111B3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    text-transform: lowercase;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    line-height: 28px;
    width: 100%;
    height: 30px;
  }
  .nox_account_desktop_verification_navigation_filters_value.active div,
  .nox_account_desktop_verification_navigation_filters_value:hover div {
    background: #111111CC;
  }
  .nox_account_desktop_verification_navigation_filters_value:last-child div {
    border-radius: 2px 15px 15px 2px;
  }
  .nox_account_desktop_verification_item {
    position: relative;
    padding: 0 0 0 200px;
  }
  .nox_account_desktop_verification_item_image {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 170px;
  }
  .nox_account_desktop_verification_item_image.image {
    cursor: pointer;
  }
  .nox_account_desktop_verification_item_image.image:hover svg {
    display: block;
  }
  .nox_account_desktop_verification_item_image.image img {
    border: 5px solid #F1F1F1;
    border-radius: 5px;
    box-shadow: 0 0 5px #333333;
    width: 170px;
    max-height: 170px;
  }
  .nox_account_desktop_verification_item_image.image svg {
    position: absolute;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 0 10px #333333;
    background: #FFFFFF;
    color: #000000;
    font-size: 40px;
    display: none;
    opacity: 0.6;
  }
  .nox_account_desktop_verification_item_image.empty svg {
    margin: -8px 0 0 0;
    font-size: 150px;
    opacity: 0.6;
  }
  .nox_account_desktop_verification_item_image.empty span {
    position: absolute;
    color: #FFFFFF;
    text-transform: uppercase;
    text-shadow: 0 0 5px #000000;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    font-size: 16px;
  }
  .nox_account_desktop_verification_item_data_row {
    position: relative;
    margin: 0 0 5px 0;
    line-height: 30px;
    display: table;
    width: 100%;
    min-height: 30px;
  }
  .nox_account_desktop_verification_item_data_row:last-child {
    margin: 0;
  }
  .nox_account_desktop_verification_item_data_label {
    position: absolute;
    text-align: right;
    text-transform: uppercase;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    width: 200px;
    z-index: 1;
  }
  .nox_account_desktop_verification_item_data_value {
    position: relative;
    padding: 0 0 0 230px;
  }
  .nox_account_desktop_verification_item_data_value .status {
    text-transform: uppercase;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
  }
  .nox_account_desktop_verification_item_data_value .status.green {
    color: #00FF00;
  }
  .nox_account_desktop_verification_item_data_value .status.orange {
    color: #FFC660;
  }
  .nox_account_desktop_verification_item_data_value .status.red {
    color: #FFBBBB;
  }
  .nox_account_desktop_verification_item_data_value_content {
    padding: 5px 0;
    line-height: 20px;
  }

  @media (max-width: 1000px) {
    .nox_account_desktop_verification_navigation {
      margin: 0 0 10px 0;
    }
  }
  @media (max-width: 800px) {
    .nox_account_desktop_verification_navigation_filters,
    .nox_account_desktop_verification_navigation_filters:first-child {
      margin: 0 0 10px 0;
      padding: 0;
    }
    .nox_account_desktop_verification_navigation_filters:last-child {
      margin: 0;
    }
    .nox_account_desktop_verification_navigation_filters_label {
      position: relative;
      border-radius: 15px 15px 2px 2px;
      width: 100%;
    }
    .nox_account_desktop_verification_navigation_filters_value {
      padding: 2px 0 0 0;
      width: 100%;
    }
    .nox_account_desktop_verification_navigation_filters_value:last-child div {
      border-radius: 2px 2px 15px 15px;
    }
    .nox_account_desktop_verification_item {
      padding: 0;
    }
    .nox_account_desktop_verification_item_image {
      position: relative;
      margin: 0 0 15px 0;
      width: 100%;
    }
    .nox_account_desktop_verification_item_image.empty svg {
      margin: -8px 0;
    }
    .nox_account_desktop_verification_item_data_row {
      margin: 0;
    }
    .nox_account_desktop_verification_item_data_label {
      float: left;
      position: relative;
      padding: 0 10px 0 0;
      width: 50%;
    }
    .nox_account_desktop_verification_item_data_value {
      float: right;
      padding: 0 0 0 10px;
      width: 50%;
    }
  }
  @media (max-width: 550px) {
    .nox_account_desktop_verification_item_image.image img {
      width: 117px;
      max-height: 117px;
    }
    .nox_account_desktop_verification_item_image.empty svg {
      margin: -6px 0;
      font-size: 104px;
    }
    .nox_account_desktop_verification_item_image.empty span {
      font-size: 14px;
    }
    .nox_account_desktop_verification_item_data_row {
      font-size: 11px;
      line-height: 28px;
      min-height: 28px;
    }
    .nox_account_desktop_verification_item_data_label {
      width: auto;
    }
    .nox_account_desktop_verification_item_data_value {
      float: none;
      padding: 0;
      display: flex;
      width: auto;
    }
    .nox_account_desktop_verification_item_data_value_content {
      line-height: 18px;
    }
  }
</style>
