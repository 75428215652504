<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_auth mailings_unsubscribe">
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.6', 0)"></div>
					<div class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_text" :class="{ mailings_unsubscribe_success: noxIsOk }" v-html="$store.getters.getLanguageText('2.6', (noxIsOk ? 2 : 1))"></div>
						<div class="nox_auth_block_content_form_alert" v-html="noxAlert"></div>
						<div class="nox_auth_block_content_form_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_auth_block_content_form_buttons" :class="{ hide: noxIsLoading }" v-if="!noxIsOk">
							<button type="button" class="nox_button common green" @click="axios('unsubscribe')" v-html="$store.getters.getLanguageText('1.1', 135)"></button>
						</div>
						<div class="nox_auth_block_content_form_footer">
							<router-link to="/">[<span v-html="$store.getters.getLanguageText('1.1', 31)"></span>]</router-link>
						</div>
					</div>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxIsLoading: false,
			noxIsError: false,
			noxIsOk: false
		}),
		methods: {
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.22', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.22', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.22', 2); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (_this.$store.state.noxOAuthTokenForMailings && _this.$route.params.key !== undefined) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxOAuthTokenForMailings }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'unsubscribe') {
						config.url = '/v2/oauth/mailings/unsubscribe';
						config.data = { key: _this.$route.params.key };
						config.method = 'post';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'unsubscribe') { _this.noxIsOk = true; }
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'unsubscribe') {
										if      (data.response.data.error == 'KEY_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'KEY_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									}
								}
								else if (data.response.status == 409) {
									if (type == 'unsubscribe') {
										if (data.response.data.error == 'KEY_ALREADY_ACTIVATED') { _this.noxAlert = _this.getError(3); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.noxAlert = _this.getError(1);
				}
			}
		},
		components: {
			NoxAuthHeader,
			NoxAuthFooter
		}
	}
</script>
