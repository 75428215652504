<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_calculators">
				<div class="nox_tabs">
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab('compound_interest')" v-html="$store.getters.getLanguageText('3.12', 0)"></label>
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab('reverse_calculation')" v-html="$store.getters.getLanguageText('3.12', 1)"></label>
					<div class="nox_tab_block" id="nox_tab_block_1">
						<div class="nox_account_desktop_calculators_compound_interest">
							<div class="nox_account_desktop_portlet_wrapper">
								<div class="nox_account_desktop_portlet">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.12', 2)"></div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_portlet_content_intro" v-html="$store.getters.getLanguageText('3.12', 3)"></div>
										<div class="nox_account_desktop_portlet_content_sdata">
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 4)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 70), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxCIDeposit" :placeholder="$store.getters.getLanguageText('3.12', 4).toLowerCase()" min="1" max="10000000000" step="1" autocomplete="off" @input="calculateCompoundInterest">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 5)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 71), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxCIPeriods" :placeholder="$store.getters.getLanguageText('3.12', 5).toLowerCase()" min="1" max="1000" step="1" autocomplete="off" @input="calculateCompoundInterest">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 6)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 72), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxCIPercent" :placeholder="$store.getters.getLanguageText('3.12', 6).toLowerCase()" min="0.001" max="10000" step="0.001" autocomplete="off" @input="calculateCompoundInterest">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 7)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 73), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxCIContribution" :placeholder="$store.getters.getLanguageText('3.12', 7).toLowerCase()" min="1" max="1000000000" step="1" autocomplete="off" @input="calculateCompoundInterest">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 8)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 74), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(noxCIDistribution)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="compoundInterestDistributionToggleChange"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row" v-if="noxCIDistribution">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 9)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 75), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(noxCIExcludeWeekend)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="compoundInterestExcludeWeekendToggleChange"></toggle-button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="nox_account_desktop_portlet_accordion">
									<div class="nox_account_desktop_portlet_accordion_block">
										<div class="nox_account_desktop_portlet_accordion_block_content">
											<highcharts class="nox_chart" :options="noxCIChart"></highcharts>
											<table class="nox_table">
												<thead>
													<tr>
														<th v-html="$store.getters.getLanguageText('1.7.1', 31)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 32)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 33)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 34)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 35)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 36)"></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(value, index) in noxCICalculations" :key="index">
														<td :data-label="$store.getters.getLanguageText('1.7.1', 31) + ':'">{{ index }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 32) + ':'">{{ parseFloat(index > 1 ? value[4] : value[2]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 33) + ':'">{{ parseFloat(value[2]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 34) + ':'">{{ parseFloat(value[0]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 35) + ':'">{{ parseFloat(value[3]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 36) + ':'">{{ parseFloat(value[1]).toFixed(2) }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_tab_block" id="nox_tab_block_2">
						<div class="nox_account_desktop_calculators_reverse_calculation">
							<div class="nox_account_desktop_portlet_wrapper">
								<div class="nox_account_desktop_portlet">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.12', 10)"></div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_portlet_content_intro" v-html="$store.getters.getLanguageText('3.12', 11)"></div>
										<div class="nox_account_desktop_portlet_content_sdata">
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 12)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 76), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxRCTAmount" :placeholder="$store.getters.getLanguageText('3.12', 12).toLowerCase()" min="1" max="5000000" step="1" autocomplete="off" @input="calculateReverseCalculation">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 4)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 70), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxRCDeposit" :placeholder="$store.getters.getLanguageText('3.12', 4).toLowerCase()" min="1" max="5000000" step="1" autocomplete="off" @input="calculateReverseCalculation">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 6)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 72), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxRCPercent" :placeholder="$store.getters.getLanguageText('3.12', 6).toLowerCase()" min="0.1" max="10000" step="0.1" autocomplete="off" @input="calculateReverseCalculation">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 7)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 73), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<input type="text" class="nox_input" v-model.number="noxRCContribution" :placeholder="$store.getters.getLanguageText('3.12', 7).toLowerCase()" min="1" max="5000000" step="1" autocomplete="off" @input="calculateReverseCalculation">
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 8)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 74), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(noxRCDistribution)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="reverseCalculationDistributionToggleChange"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_portlet_content_row" v-if="noxRCDistribution">
												<div class="nox_account_desktop_portlet_content_label">
													<span v-html="$store.getters.getLanguageText('3.12', 9)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 75), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_portlet_content_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(noxRCExcludeWeekend)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="reverseCalculationExcludeWeekendToggleChange"></toggle-button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="nox_account_desktop_portlet_accordion">
									<div class="nox_account_desktop_portlet_accordion_block">
										<div class="nox_account_desktop_portlet_accordion_block_content">
											<highcharts class="nox_chart" :options="noxRCChart"></highcharts>
											<table class="nox_table">
												<thead>
													<tr>
														<th v-html="$store.getters.getLanguageText('1.7.1', 31)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 32)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 33)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 34)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 35)"></th>
														<th v-html="$store.getters.getLanguageText('1.7.1', 36)"></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(value, index) in noxRCCalculations" :key="index">
														<td :data-label="$store.getters.getLanguageText('1.7.1', 31) + ':'">{{ index }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 32) + ':'">{{ parseFloat(index > 1 ? value[4] : value[2]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 33) + ':'">{{ parseFloat(value[2]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 34) + ':'">{{ parseFloat(value[0]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 35) + ':'">{{ parseFloat(value[3]).toFixed(2) }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 36) + ':'">{{ parseFloat(value[1]).toFixed(2) }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			noxCIDeposit: 5000,
			noxCIPeriods: 12,
			noxCIPercent: 5,
			noxCIContribution: 0,
			noxCIDistribution: 0,
			noxCIDistributionPeriods: 30,
			noxCIExcludeWeekend: 0,
			noxCIExcludeWeekendValue: (1 - (2 / 7)),
			noxCIChart: {},
			noxCIChartCategories: [],
			noxCICalculations: {},
			noxRCTAmount: 5000,
			noxRCDeposit: 1000,
			noxRCPercent: 5,
			noxRCContribution: 0,
			noxRCDistribution: 0,
			noxRCDistributionPeriods: 30,
			noxRCExcludeWeekend: 0,
			noxRCExcludeWeekendValue: (1 - (2 / 7)),
			noxRCChart: {},
			noxRCChartCategories: [],
			noxRCCalculations: {},
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
			}
		},
		methods: {
			isReady: function() {
				this.initCalculators();
			},
			initTabs: function(tab) {
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				if (tab == 'reverse_calculation') { this.noxIsTabMenu2Checked = true; }
				else                              { this.noxIsTabMenu1Checked = true; }
			},
			selectTab: function(tab) {
				if (this.$route.query.tab !== tab) {
					this.$router.push({ path: '/info/calculators', query: { tab: tab }});
				}
			},
			initCalculators: function() {
				var _this = this;
				setTimeout(function() {
					_this.noxIsLoading = false;
					_this.$store.state.noxIsLoading = false;
					_this.noxCIChart = {
						title: { text: _this.$store.getters.getLanguageText('1.6.2', 0) },
						chart: { type: 'column', height: 300 },
						xAxis: { type: 'category', categories: _this.noxCIChartCategories },
						legend: { enabled: true },
						series: [
							{ name: _this.$store.getters.getLanguageText('1.6.2', 2), color: '#1EC455', data: [] },
							{ name: _this.$store.getters.getLanguageText('1.6.2', 3), color: '#F45B5B', data: [] },
							{ name: _this.$store.getters.getLanguageText('1.6.2', 4), color: '#888888', data: [] }
						],
						tooltip: {
							shared: true,
							formatter: function() {
								return _this.$parent.$parent.getChartFormatForCompoundInterest(this, 2);
							}
						},
						plotOptions: {
							column: { stacking: 'normal' },
							series: { pointPadding: 0.1, groupPadding: 0, borderWidth: 0 }
						}
					};
					_this.calculateCompoundInterest();
					_this.noxRCChart = {
						title: { text: _this.$store.getters.getLanguageText('1.6.2', 1) },
						chart: { type: 'column', height: 300 },
						xAxis: { type: 'category', categories: _this.noxRCChartCategories },
						legend: { enabled: true },
						series: [
							{ name: _this.$store.getters.getLanguageText('1.6.2', 2), color: '#1EC455', data: [] },
							{ name: _this.$store.getters.getLanguageText('1.6.2', 3), color: '#F45B5B', data: [] },
							{ name: _this.$store.getters.getLanguageText('1.6.2', 4), color: '#888888', data: [] }
						],
						tooltip: {
							shared: true,
							formatter: function() {
								return _this.$parent.$parent.getChartFormatForCompoundInterest(this, 2);
							}
						},
						plotOptions: {
							column: { stacking: 'normal' },
							series: { pointPadding: 0.1, groupPadding: 0, borderWidth: 0 }
						}
					};
					_this.calculateReverseCalculation();
				}, 100);
			},
			calculateCompoundInterest: function() {

				if (!this.noxCIDeposit || !this.noxCIPeriods || !this.noxCIPercent) { return false; }

				var ciDeposit = Number(this.noxCIDeposit);
						ciDeposit = ciDeposit ? ciDeposit : 0;
						ciDeposit = ciDeposit > 10000000000 ? 10000000000 : ciDeposit;
						this.noxCIDeposit = ciDeposit == 10000000000 ? 10000000000 : this.noxCIDeposit;
				var ciPeriods = Number(this.noxCIPeriods);
						ciPeriods = ciPeriods ? ciPeriods : 1;
						ciPeriods = ciPeriods > 1000 ? 1000 : ciPeriods;
						this.noxCIPeriods = ciPeriods == 1000 ? 1000 : this.noxCIPeriods;
				var ciPercent = Number(this.noxCIPercent);
						ciPercent = ciPercent ? ciPercent : 0;
						ciPercent = ciPercent > 10000 ? 10000 : ciPercent;
						this.noxCIPercent = ciPercent == 10000 ? 10000 : this.noxCIPercent;
				var ciContribution = Number(this.noxCIContribution);
						ciContribution = ciContribution ? ciContribution : 0;
						ciContribution = ciContribution > 1000000000 ? 1000000000 : ciContribution;
						this.noxCIContribution = ciContribution == 1000000000 ? 1000000000 : this.noxCIContribution;
				var ciDistribution = Number(this.noxCIDistribution);
				var ciDistributionPeriods = ciDistribution ? Number(this.noxCIDistributionPeriods) : 1;
				var ciDistributionPercent = ciPercent / ciDistributionPeriods / 100;
				var ciExcludeWeekend = Number(this.noxCIExcludeWeekend);
				var ciExcludeWeekendValue = ciExcludeWeekend ? Number(this.noxCIExcludeWeekendValue) : 1;
				var ciProfitForPeriod = 0;
				var ciProfitForIPeriod = 0;
				var ciProfitForJPeriod = 0;
				var ciProfitForTPeriod = 0;
				var ciProfitForPeriods = 0;
				var ciProfitForIPeriods = 0;
				var ciProfitForJPeriods = 0;
				var ciDepositForPeriod = 0;
				var ciDepositForIPeriod = ciDeposit;
				var ciDepositForJPeriod = ciDeposit;
				var ciDepositForTPeriod = 0;
				var ciDepositForPeriods = 0;
				var ciDepositForIPeriods = 0;
				var ciDepositForJPeriods = 0;
				var ciContributionForPeriod = 0;
				var ciContributionForPeriods = 0;
				var ciChartSeries0Data = [];
				var ciChartSeries1Data = [];
				var ciChartSeries2Data = [];

				this.noxCIChart.series[0].data = [];
				this.noxCIChart.series[1].data = [];
				this.noxCIChart.series[2].data = [];
				this.noxCIChartCategories = [];
				this.noxCICalculations = {};

				for (var i = 1; i <= ciPeriods; i++) {

					ciContributionForPeriod = i > 1 ? ciContribution : 0;
					ciContributionForPeriods += ciContributionForPeriod;
					ciDepositForIPeriod += ciContributionForPeriod;
					ciDepositForJPeriod += ciContributionForPeriod;
					ciDepositForTPeriod = ciDepositForJPeriod;
					ciProfitForTPeriod = 0;

					for (var j = 1; j <= ciDistributionPeriods; j++) {
						ciProfitForTPeriod += ciDepositForTPeriod * ciDistributionPercent;
						ciDepositForTPeriod += ciDepositForTPeriod * ciDistributionPercent;
					}

					ciProfitForIPeriod = ciDepositForIPeriod * ciPercent * ciExcludeWeekendValue / 100;
					ciProfitForJPeriod = ciProfitForTPeriod * ciExcludeWeekendValue;
					ciProfitForIPeriods += ciProfitForIPeriod;
					ciProfitForJPeriods += ciProfitForJPeriod;
					ciDepositForIPeriods = ciDepositForIPeriod + ciProfitForIPeriod;
					ciDepositForJPeriods = ciDepositForJPeriod + ciProfitForJPeriod;

					ciProfitForPeriod = ciDistribution ? ciProfitForJPeriod : ciProfitForIPeriod;
					ciProfitForPeriods = ciDistribution ? ciProfitForJPeriods : ciProfitForIPeriods;
					ciDepositForPeriod = ciDistribution ? ciDepositForJPeriod : ciDepositForIPeriod;
					ciDepositForPeriods = ciDistribution ? ciDepositForJPeriods : ciDepositForIPeriods;

					ciChartSeries0Data.push(parseFloat(parseFloat(ciProfitForPeriods).toFixed(2)));
					ciChartSeries1Data.push(parseFloat(parseFloat(ciContributionForPeriods).toFixed(2)));
					ciChartSeries2Data.push(parseFloat(parseFloat(ciDeposit).toFixed(2)));

					this.noxCIChartCategories.push(i);
					this.noxCICalculations[i] = [ ciProfitForPeriod, ciProfitForPeriods, ciDepositForPeriod, ciDepositForPeriods, ciContributionForPeriod, ciContributionForPeriods ];

					ciDepositForIPeriod += ciProfitForIPeriod;
					ciDepositForJPeriod += ciProfitForJPeriod;
				}

				var _this = this;
				setTimeout(function() {
					_this.noxCIChart.series[0].data = ciChartSeries0Data;
					_this.noxCIChart.series[1].data = ciChartSeries1Data;
					_this.noxCIChart.series[2].data = ciChartSeries2Data;
				}, 0);

				this.noxCIChart.xAxis.categories = this.noxCIChartCategories;
			},
			calculateReverseCalculation: function() {

				if (!this.noxRCTAmount || !this.noxRCDeposit || !this.noxRCPercent) { return false; }

				var rcTAmount = Number(this.noxRCTAmount);
						rcTAmount = rcTAmount < 1 ? 1 : rcTAmount;
						rcTAmount = rcTAmount > 5000000 ? 5000000 : rcTAmount;
						this.noxRCTAmount = rcTAmount == 5000000 ? 5000000 : this.noxRCTAmount;
				var rcDeposit = Number(this.noxRCDeposit);
						rcDeposit = rcDeposit < 1 ? 1 : rcDeposit;
						rcDeposit = rcDeposit > 5000000 ? 5000000 : rcDeposit;
						this.noxRCDeposit = rcDeposit == 5000000 ? 5000000 : this.noxRCDeposit;
				var rcPercent = Number(this.noxRCPercent);
						rcPercent = rcPercent < 0.1 ? 0.1 : rcPercent;
						rcPercent = rcPercent > 10000 ? 10000 : rcPercent;
						this.noxRCPercent = rcPercent == 10000 ? 10000 : this.noxRCPercent;
				var rcContribution = Number(this.noxRCContribution);
						rcContribution = rcContribution ? rcContribution : 0;
						rcContribution = rcContribution > 5000000 ? 5000000 : rcContribution;
						this.noxRCContribution = rcContribution == 5000000 ? 5000000 : this.noxRCContribution;
				var rcDistribution = Number(this.noxRCDistribution);
				var rcDistributionPeriods = rcDistribution ? Number(this.noxRCDistributionPeriods) : 1;
				var rcDistributionPercent = rcPercent / rcDistributionPeriods / 100;
				var rcExcludeWeekend = Number(this.noxRCExcludeWeekend);
				var rcExcludeWeekendValue = rcExcludeWeekend ? Number(this.noxRCExcludeWeekendValue) : 1;
				var rcPeriods = 0;
				var rcProfitForPeriod = 0;
				var rcProfitForIPeriod = 0;
				var rcProfitForJPeriod = 0;
				var rcProfitForTPeriod = 0;
				var rcProfitForPeriods = 0;
				var rcProfitForIPeriods = 0;
				var rcProfitForJPeriods = 0;
				var rcDepositForPeriod = 0;
				var rcDepositForIPeriod = rcDeposit;
				var rcDepositForJPeriod = rcDeposit;
				var rcDepositForTPeriod = 0;
				var rcDepositForPeriods = 0;
				var rcDepositForIPeriods = 0;
				var rcDepositForJPeriods = 0;
				var rcContributionForPeriod = 0;
				var rcContributionForPeriods = 0;

				this.noxRCChart.series[0].data = [];
				this.noxRCChart.series[1].data = [];
				this.noxRCChart.series[2].data = [];
				this.noxRCChartCategories = [];
				this.noxRCCalculations = {};

				while (rcDepositForPeriods < rcTAmount) {

					rcPeriods++;
					rcContributionForPeriod = rcPeriods > 1 ? rcContribution : 0;
					rcContributionForPeriods += rcContributionForPeriod;
					rcDepositForIPeriod += rcContributionForPeriod;
					rcDepositForJPeriod += rcContributionForPeriod;
					rcDepositForTPeriod = rcDepositForJPeriod;
					rcProfitForTPeriod = 0;

					for (var j = 1; j <= rcDistributionPeriods; j++) {
						rcProfitForTPeriod += rcDepositForTPeriod * rcDistributionPercent;
						rcDepositForTPeriod += rcDepositForTPeriod * rcDistributionPercent;
					}

					rcProfitForIPeriod = rcDepositForIPeriod * rcPercent * rcExcludeWeekendValue / 100;
					rcProfitForJPeriod = rcProfitForTPeriod * rcExcludeWeekendValue;
					rcProfitForIPeriods += rcProfitForIPeriod;
					rcProfitForJPeriods += rcProfitForJPeriod;
					rcDepositForIPeriods = rcDepositForIPeriod + rcProfitForIPeriod;
					rcDepositForJPeriods = rcDepositForJPeriod + rcProfitForJPeriod;

					rcProfitForPeriod = rcDistribution ? rcProfitForJPeriod : rcProfitForIPeriod;
					rcProfitForPeriods = rcDistribution ? rcProfitForJPeriods : rcProfitForIPeriods;
					rcDepositForPeriod = rcDistribution ? rcDepositForJPeriod : rcDepositForIPeriod;
					rcDepositForPeriods = rcDistribution ? rcDepositForJPeriods : rcDepositForIPeriods;

					this.noxRCChart.series[0].data.push(parseFloat(parseFloat(rcProfitForPeriods).toFixed(2)));
					this.noxRCChart.series[1].data.push(parseFloat(parseFloat(rcContributionForPeriods).toFixed(2)));
					this.noxRCChart.series[2].data.push(parseFloat(parseFloat(rcDeposit).toFixed(2)));
					this.noxRCChartCategories.push(rcPeriods);
					this.noxRCCalculations[rcPeriods] = [ rcProfitForPeriod, rcProfitForPeriods, rcDepositForPeriod, rcDepositForPeriods, rcContributionForPeriod, rcContributionForPeriods ];

					rcDepositForIPeriod += rcProfitForIPeriod;
					rcDepositForJPeriod += rcProfitForJPeriod;
				}

				this.noxRCChart.xAxis.categories = this.noxRCChartCategories;
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			compoundInterestDistributionToggleChange: function(event) {
				this.noxCIDistribution = Number(event.value);
				this.noxCIExcludeWeekend = 0;
				this.calculateCompoundInterest();
			},
			compoundInterestExcludeWeekendToggleChange: function(event) {
				this.noxCIExcludeWeekend = Number(event.value);
				this.calculateCompoundInterest();
			},
			reverseCalculationDistributionToggleChange: function(event) {
				this.noxRCDistribution = Number(event.value);
				this.noxRCExcludeWeekend = 0;
				this.calculateReverseCalculation();
			},
			reverseCalculationExcludeWeekendToggleChange: function(event) {
				this.noxRCExcludeWeekend = Number(event.value);
				this.calculateReverseCalculation();
			}
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>
