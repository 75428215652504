<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Настройки торговых стратегий</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_strategy_settings">
						<table class="nox_table" v-if="$store.state.noxStrategySettings.length">
							<thead>
								<tr>
									<th>Id</th>
									<th>Тип</th>
									<th>Название</th>
									<th>Множитель</th>
									<th>Список мастеров</th>
									<th>Список пакетов</th>
									<th>Тип счета</th>
									<th>Описание</th>
									<th>Insomnia</th>
									<th><font-awesome-icon :icon="['fas', 'link']" /></th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxStrategySettings" :key="index">
									<td data-label="Id:">{{ value.id }}</td>
									<td data-label="Тип:">{{ (value.type == 2 ? 'phoenix' : 'standard') }}</td>
									<td data-label="Название:">{{ value.name }}</td>
									<td data-label="Множитель:"><span v-if="value.type == 2">−</span><span v-else><span v-if="value.min_multiplier !== value.max_multiplier">от {{ value.min_multiplier.toFixed(1) }} до {{ value.max_multiplier.toFixed(1) }}</span><span v-else>{{ value.max_multiplier.toFixed(1) }}</span></span></td>
									<td data-label="Список мастеров:"><span v-if="value.type == 2">−</span><span v-else><span v-for="(value2, index2) in value.masters" :key="index2"><span v-if="noxMasterSettings[value2]">{{ noxMasterSettings[value2] }}</span><span v-else>null</span><span v-if="value.masters.length > index2 + 1">, </span></span></span></td>
									<td data-label="Список пакетов:"><span v-for="(value3, index3) in value.packages" :key="index3">{{ $store.state.noxSystemSettings['package_name_x' + value3] }}<span v-if="value.packages.length > index3 + 1">, </span></span></td>
									<td data-label="Тип счета:">{{ value.type_account }}</td>
									<td data-label="Описание:">{{ value.description }}</td>
									<td data-label="Insomnia:"><span v-if="value.type == 2">−</span><span v-else><toggle-button class="nox_toggle_button" :value="toggleValue(value.is_insomnia)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(value.id, index, $event)"></toggle-button></span></td>
									<td data-label="Ссылка на описание:"><a :href="value.description_link" class="nox_link blue icon" target="_blank" v-if="value.description_link"><font-awesome-icon :icon="['fas', 'link']" /></a></td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalStrategySettings', { type: 'editStrategy', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalStrategySettings', { type: 'addStrategy' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить стратегию</button>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalStrategySettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxMasterSettings: [],
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getMasters');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getMasters') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/bots/masters/only_masters';
					}
					else if (type == 'getStrategies') {
						config.url = '/v2/admin/account/bots/strategies';
					}
					else if (type == 'editBotsStrategyIsInsomnia') {
						_this.$store.state.noxStrategySettings[var2].is_insomnia = var3;
						config.url = '/v2/admin/account/bots/strategies/is_insomnia';
						config.data = { id: var1, is_insomnia: var3 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getMasters') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								for (var i in data.data) {
									_this.noxMasterSettings[data.data[i].id] = data.data[i].shortname;
								}
							}
							else if (type == 'getStrategies') {
								_this.$store.state.noxStrategySettings = data.data;
								for (var j in data.data) {
									_this.$store.state.noxStrategySettings[j].packages = String(data.data[j].packages).split(',');
									_this.$store.state.noxStrategySettings[j].masters = String(data.data[j].masters).split(',');
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						if (type == 'getMasters') {
							_this.axios('getStrategies');
						}
						else if (type == 'getStrategies') {
							_this.noxIsLoading = false;
							_this.$store.state.noxIsAccount = false;
							_this.$store.state.noxIsLoading = false;
						}
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(id, index, event) {
				this.axios('editBotsStrategyIsInsomnia', id, index, Number(event.value));
			}
		},
		components: {
			NoxModalStrategySettings: () => import('@/views/modal_components/NoxModalStrategySettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_strategy_settings tr th:nth-child(1),
	.nox_account_desktop_strategy_settings tr td:nth-child(1),
	.nox_account_desktop_strategy_settings tr th:nth-child(2),
	.nox_account_desktop_strategy_settings tr td:nth-child(2),
	.nox_account_desktop_strategy_settings tr th:nth-child(10),
	.nox_account_desktop_strategy_settings tr td:nth-child(10),
	.nox_account_desktop_strategy_settings tr th:nth-child(11),
	.nox_account_desktop_strategy_settings tr td:nth-child(11) {
		min-width: 45px;
		width: 1%;
	}
</style>
