import Vue from 'vue'
import VModal from 'vue-js-modal'
import VSelect from 'vue-select'
import VTooltip from 'v-tooltip'
import VClipboard from 'v-clipboard'
import VueCropper from 'vue-cropperjs'
import VueQRCode from 'vue-qr-generator'
import VueSlider from 'vue-slider-component'
import Lightbox from 'vue-easy-lightbox'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import Notifications from 'vue-notification'
import ToggleButton from 'vue-js-toggle-button'
import { Datetime } from 'vue-datetime'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faAddressCard, faAlignLeft, faAngleDoubleDown, faAngleDoubleLeft, faAngleDoubleRight, faAngleDoubleUp, faAngleDown, faAngleLeft, faAngleRight,
	faAngleUp, faArrowDown, faArrowLeft, faArrowRight, faArrowsAltH, faArrowsAltV, faArrowUp, faAsterisk, faAt, faBan, faBars, faBell, faBullhorn,
	faCalculator, faCheck, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faCog, faCoins, faComment, faCompass, faCopy,
	faDollarSign, faDownload, faEdit, faEnvelope, faExclamationCircle, faExclamationTriangle, faExternalLinkAlt, faEye, faEyeSlash,
	faFileAlt, faFilePdf, faGift, faGlobe, faHome, faHourglassHalf, faImage, faInfoCircle, faKeyboard, faLevelUpAlt, faLink,
	faLock, faLockOpen, faPause, faPencilAlt, faPlay, faPlayCircle, faPlus, faQuestion, faQuestionCircle, faRandom,
	faRecycle, faRedoAlt, faReply, faSave, faSearch, faSearchMinus, faSearchPlus, faSignOutAlt, faSkullCrossbones,
	faStarHalfAlt, faSyncAlt, faTicketAlt, faTimes, faTools, faTrash, faUndoAlt, faUpload, faUserCheck,
	faUserPlus, faUsers, faUserSecret
} from '@fortawesome/free-solid-svg-icons'
import {
	faFacebook, faFirstOrder, faInstagram, faSkype, faTelegram, faTelegramPlane, faVk, faWhatsapp, faWindows, faYoutube
} from '@fortawesome/free-brands-svg-icons'
import 'cropperjs/dist/cropper.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue-datetime/dist/vue-datetime.css'
import velocity from 'velocity-animate'

Vue.use(VModal, { dynamic: true })
Vue.use(Lightbox)
Vue.use(VTooltip)
Vue.use(VClipboard)
Vue.use(ToggleButton)
Vue.use(HighchartsVue)
Vue.use(Notifications, { velocity })
HighchartsMore(Highcharts)
HighchartsSolidGauge(Highcharts)
Vue.component('v-select', VSelect)
Vue.component('vue-qrcode', VueQRCode)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-cropper', VueCropper)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('datetime', Datetime)

Highcharts.setOptions({
	title: { text: '', useHTML: true },
	chart: { type: 'line', height: 200, zoomType: 'x' },
	xAxis: { type: 'datetime', gridLineWidth: 1 },
	yAxis: { title: false, tickPixelInterval: 100 },
	global: { useUTC: false },
	legend: { enabled: false },
	tooltip: { padding: 1, useHTML: true, backgroundColor: 'rgba(255,255,255,1)' }
})
library.add(
	faAddressCard, faAlignLeft, faAngleDoubleDown, faAngleDoubleLeft, faAngleDoubleRight, faAngleDoubleUp, faAngleDown, faAngleLeft, faAngleRight,
	faAngleUp, faArrowDown, faArrowLeft, faArrowRight, faArrowsAltH, faArrowsAltV, faArrowUp, faAsterisk, faAt, faBan, faBars, faBell, faBullhorn,
	faCalculator, faCheck, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faCog, faCoins, faComment, faCompass, faCopy,
	faDollarSign, faDownload, faEdit, faEnvelope, faExclamationCircle, faExclamationTriangle, faExternalLinkAlt, faEye, faEyeSlash,
	faFacebook, faFileAlt, faFilePdf, faFirstOrder, faGift, faGlobe, faHome, faHourglassHalf, faImage, faInfoCircle, faInstagram,
	faKeyboard, faLevelUpAlt, faLink, faLock, faLockOpen, faPause, faPencilAlt, faPlay, faPlayCircle, faPlus, faQuestion,
	faQuestionCircle, faRandom, faRecycle, faRedoAlt, faReply, faSave, faSearch, faSearchMinus, faSearchPlus,
	faSignOutAlt, faSkullCrossbones, faSkype, faStarHalfAlt, faSyncAlt, faTelegram, faTelegramPlane,
	faTicketAlt, faTimes, faTools, faTrash, faUndoAlt, faUpload, faUserCheck, faUserPlus, faUsers,
	faUserSecret, faVk, faWhatsapp, faWindows, faYoutube
)

export default { }
