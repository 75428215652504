<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_transliteration">
				<div class="nox_account_desktop_transliteration_navigation">
					<div class="nox_account_desktop_transliteration_navigation_filters">
						<div class="nox_account_desktop_transliteration_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 9)"></div>
						<div class="nox_account_desktop_transliteration_navigation_filters_select"><v-select class="nox_select style-chooser" v-model.trim="noxTransliterationFilter" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxTransliterationFilterOptions" @input="getTransliterationDataByFilter"></v-select></div>
						<div class="nox_account_desktop_transliteration_navigation_filters_reset_cache">
							<div :class="{ hide: !noxIsTransliterationFilterLoading }"><img src="@/assets/images/loading.gif"></div>
							<div :class="{ hide: noxIsTransliterationFilterLoading }"><button type="button" class="nox_button" @click="axios('resetTransliteration')" v-html="$store.getters.getLanguageText('1.1', 125)"></button></div>
						</div>
					</div>
					<div class="nox_account_desktop_page_content_navigation_search">
						<input type="text" class="nox_input" v-model.trim="noxTransliterationSearch" @keyup.13="getTransliterationDataBySearch" :placeholder="$store.getters.getLanguageText('1.8.3', 0)" autocomplete="off">
						<div class="nox_account_desktop_page_content_navigation_search_button" @click="getTransliterationDataBySearch"><font-awesome-icon :icon="['fas', 'search']" /></div>
						<div class="nox_account_desktop_page_content_navigation_search_result" v-show="noxTransliterationSearchResult.length || noxIsTransliterationSearchLoading">
							<div class="nox_account_desktop_page_content_navigation_search_result_block">
								<div class="nox_account_desktop_page_content_navigation_search_result_loading" :class="{ hide: !noxIsTransliterationSearchLoading }"><span v-html="$store.getters.getLanguageText('1.8', 0)"></span>&#160;<img src="@/assets/images/loading.gif"></div>
								<div class="nox_account_desktop_page_content_navigation_search_result_content" :class="{ hide: noxIsTransliterationSearchLoading }">
									<span v-if="noxTransliterationSearchResult[0] && noxTransliterationSearchResult[0].length">
										<span class="nox_account_desktop_page_content_navigation_search_result_content_link" v-if="noxTransliterationSearchResult[0].length == 1" @click="getTransliterationData(noxTransliterationSearchResult[0][0].key)" v-html="$store.getters.getLanguageText('1.8.3', 2, { type: noxTransliterationSearchResult[0][0].type })"></span>
										<span class="nox_account_desktop_page_content_navigation_search_result_content_links" v-else>
											<span v-html="$store.getters.getLanguageText('1.8.3', 3)"></span>
											<span v-for="(value, index) in noxTransliterationSearchResult[0]" :key="index">
												<span>{{ (index ? ',' : '') }} </span>
												<span class="nox_account_desktop_page_content_navigation_search_result_content_link" @click="getTransliterationData(value.key)">{{ value.type }}</span>
											</span>
										</span>
									</span>
									<span v-else v-html="$store.getters.getLanguageText('1.8.3', 1)"></span>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_page_content_navigation_search_background" :class="{ hide: !noxTransliterationSearchResult.length && !noxIsTransliterationSearchLoading }" @click="closeTransliterationDataSearch"></div>
					</div>
				</div>
				<div class="nox_account_desktop_transliteration_statistics">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title">Статистика</div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_transliteration_statistics_row"><b>Русских символов в файле</b>:<span>{{ noxTransliterationStatisticsForLanguage.count_ru_characters }}</span></div>
							<div class="nox_account_desktop_transliteration_statistics_row"><b>Русских символов в базе данных</b>:<span>{{ noxTransliterationStatisticsForDatabase.count_ru_characters }}</span></div>
							<div class="nox_account_desktop_transliteration_statistics_row"><b>Русских символов всего</b>:<span>{{ (noxTransliterationStatisticsForLanguage.count_ru_characters + noxTransliterationStatisticsForDatabase.count_ru_characters) }}</span></div>
							<div class="nox_account_desktop_transliteration_statistics_row"><b>Английских символов в файле</b>:<span>{{ noxTransliterationStatisticsForLanguage.count_en_characters }}</span></div>
							<div class="nox_account_desktop_transliteration_statistics_row"><b>Английских символов в базе данных</b>:<span>{{ noxTransliterationStatisticsForDatabase.count_en_characters }}</span></div>
							<div class="nox_account_desktop_transliteration_statistics_row"><b>Английских символов всего</b>:<span>{{ (noxTransliterationStatisticsForLanguage.count_en_characters + noxTransliterationStatisticsForDatabase.count_en_characters) }}</span></div>
							<div class="nox_account_desktop_transliteration_statistics_row"><b>Символов переведено</b>:<span>{{ ((noxTransliterationStatisticsForLanguage.count_en_characters - noxTransliterationStatisticsForLanguage.count_ru_in_en_characters) + (noxTransliterationStatisticsForDatabase.count_en_characters - noxTransliterationStatisticsForDatabase.count_ru_in_en_characters)) }} из {{ (noxTransliterationStatisticsForLanguage.count_en_characters + noxTransliterationStatisticsForDatabase.count_en_characters) }} ({{ (100 - ((noxTransliterationStatisticsForLanguage.count_en_characters + noxTransliterationStatisticsForDatabase.count_en_characters) > 0 ? ((noxTransliterationStatisticsForLanguage.count_ru_in_en_characters + noxTransliterationStatisticsForDatabase.count_ru_in_en_characters) * 100 / (noxTransliterationStatisticsForLanguage.count_en_characters + noxTransliterationStatisticsForDatabase.count_en_characters)) : 0)).toFixed(2) }}%)</span></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title">Транслитерация (из файла)</div>
					<div class="nox_account_desktop_portlet_content">
						<table class="nox_table" v-if="noxTransliterationDataFromLanguage.length">
							<thead>
								<tr>
									<th>Индекс</th>
									<th>RU</th>
									<th>{{ getCountAllCharacters(noxTransliterationDataFromLanguage, 'ru') }}</th>
									<th>EN</th>
									<th>{{ getCountAllCharacters(noxTransliterationDataFromLanguage, 'en') }}</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxTransliterationDataFromLanguage" :key="index">
									<td data-label="Индекс:">{{ index }}</td>
									<td data-label="RU:" v-html="value.ru"></td>
									<td data-label="Количество символов RU:">{{ getCountCharacters(value.ru) }}</td>
									<td data-label="EN:" v-html="value.en" :class="{ bg_red: isRUCharacters(value.en) }"></td>
									<td data-label="Количество символов EN:">{{ getCountCharacters(value.en) }}</td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalTransliteration', { type: 'editTransliteration', uid: 1, index: index })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
						<div v-else>Данных нет!</div>
					</div>
					<div class="nox_account_desktop_portlet_buttons">
						<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTransliteration', { type: 'addTransliteration', uid: 1 })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить переменную</button>
					</div>
				</div>
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title">Транслитерация (из базы данных)</div>
					<div class="nox_account_desktop_portlet_content">
						<table class="nox_table" v-if="noxTransliterationDataFromDatabase.length">
							<thead>
								<tr>
									<th>Индекс</th>
									<th>RU</th>
									<th>{{ getCountAllCharacters(noxTransliterationDataFromDatabase, 'ru') }}</th>
									<th>EN</th>
									<th>{{ getCountAllCharacters(noxTransliterationDataFromDatabase, 'en') }}</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxTransliterationDataFromDatabase" :key="index">
									<td data-label="Индекс:">{{ index }}</td>
									<td data-label="RU:" v-html="value.ru"></td>
									<td data-label="Количество символов RU:">{{ getCountCharacters(value.ru) }}</td>
									<td data-label="EN:" v-html="value.en" :class="{ bg_red: isRUCharacters(value.en) }"></td>
									<td data-label="Количество символов EN:">{{ getCountCharacters(value.en) }}</td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalTransliteration', { type: 'editTransliteration', uid: 2, index: index })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
						<div v-else>Данных нет!</div>
					</div>
					<div class="nox_account_desktop_portlet_buttons">
						<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTransliteration', { type: 'addTransliteration', uid: 2 })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить переменную</button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalTransliteration"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTransliterationSearch: '',
			noxTransliterationSearchResult: [],
			noxTransliterationFilter: '',
			noxTransliterationFilterOptions: [
				{ code: '1', label: '1. Сайт' },
				{ code: '1.1', label: '..... 1.1. Кнопки' },
				{ code: '1.2', label: '..... 1.2. Подсказки' },
				{ code: '1.3', label: '..... 1.3. Оповещения' },
				{ code: '1.3.1', label: '.......... 1.3.1. ... для аккаунта' },
				{ code: '1.3.2', label: '.......... 1.3.2. ... для новостей' },
				{ code: '1.3.3', label: '.......... 1.3.3. ... для тарифного плана' },
				{ code: '1.3.4', label: '.......... 1.3.4. ... для примеров работы' },
				{ code: '1.3.5', label: '.......... 1.3.5. ... для настроек ботов' },
				{ code: '1.3.6', label: '.......... 1.3.6. ... для клиентской программы' },
				{ code: '1.3.7', label: '.......... 1.3.7. ... для моих средств (баланс)' },
				{ code: '1.3.8', label: '.......... 1.3.8. ... для настроек аккаунта' },
				{ code: '1.3.9', label: '.......... 1.3.9. ... для уведомлений' },
				{ code: '1.3.10', label: '.......... 1.3.10. ... для верификации' },
				{ code: '1.3.11', label: '.......... 1.3.11. ... для мероприятий' },
				{ code: '1.3.12', label: '.......... 1.3.12. ... для оплаты счетов' },
				{ code: '1.3.13', label: '.......... 1.3.13. ... для вывода средств' },
				{ code: '1.3.14', label: '.......... 1.3.14. ... для использования перков' },
				{ code: '1.3.15', label: '.......... 1.3.15. ... для получения подарков' },
				{ code: '1.3.16', label: '.......... 1.3.16. ... для прохождения тестов' },
				{ code: '1.3.17', label: '.......... 1.3.17. ... для серверов NOX Box' },
				{ code: '1.3.18', label: '.......... 1.3.18. ... для конструктора сайтов' },
				{ code: '1.3.19', label: '.......... 1.3.19. ... для сервиса NOX VPN' },
				{ code: '1.3.20', label: '.......... 1.3.20. ... для NOX Splash уведомлений' },
				{ code: '1.3.21', label: '.......... 1.3.21. ... для карточки пользователя' },
				{ code: '1.3.22', label: '.......... 1.3.22. ... для отписки от уведомлений' },
				{ code: '1.3.23', label: '.......... 1.3.23. ... для моей команды' },
				{ code: '1.3.24', label: '.......... 1.3.24. ... для конструктора страниц' },
				{ code: '1.4', label: '..... 1.4. Сокращения' },
				{ code: '1.5', label: '..... 1.5. Списки данных' },
				{ code: '1.5.1', label: '.......... 1.5.1. ... для стран мира' },
				{ code: '1.5.2', label: '.......... 1.5.2. ... для примеров работы' },
				{ code: '1.5.3', label: '.......... 1.5.3. ... для моей команды' },
				{ code: '1.5.4', label: '.......... 1.5.4. ... для доставки подарков' },
				{ code: '1.5.5', label: '.......... 1.5.5. ... для серверов NOX Box' },
				{ code: '1.5.6', label: '.......... 1.5.6. ... для агрессивности бота' },
				{ code: '1.5.7', label: '.......... 1.5.7. ... для конструктора сайтов' },
				{ code: '1.5.8', label: '.......... 1.5.8. ... для топ партнеров' },
				{ code: '1.5.9', label: '.......... 1.5.9. ... для типа потрендовой торговли' },
				{ code: '1.5.10', label: '.......... 1.5.10. ... для критерия выбора символов' },
				{ code: '1.5.11', label: '.......... 1.5.11. ... для пользователей системы NOX' },
				{ code: '1.5.12', label: '.......... 1.5.12. ... для метода снятия локирования' },
				{ code: '1.5.13', label: '.......... 1.5.13. ... для конструктора страниц' },
				{ code: '1.6', label: '..... 1.6. Графики' },
				{ code: '1.6.1', label: '.......... 1.6.1. ... для примеров работы' },
				{ code: '1.6.2', label: '.......... 1.6.2. ... для онлайн калькуляторов' },
				{ code: '1.6.3', label: '.......... 1.6.3. ... для серверов NOX Box' },
				{ code: '1.7', label: '..... 1.7. Таблицы' },
				{ code: '1.7.1', label: '.......... 1.7.1. Заголовки таблиц' },
				{ code: '1.7.2', label: '.......... 1.7.2. Статусы и типы таблиц' },
				{ code: '1.8', label: '..... 1.8. Фильтры, сортировка и поиск' },
				{ code: '1.8.1', label: '.......... 1.8.1. ... для топ партнеров' },
				{ code: '1.8.2', label: '.......... 1.8.2. ... для серверов NOX Box' },
				{ code: '1.8.3', label: '.......... 1.8.3. ... для транслитерации' },
				{ code: '1.8.4', label: '.......... 1.8.4. ... для пользователей' },
				{ code: '1.8.5', label: '.......... 1.8.5. ... для примеров работы' },
				{ code: '1.9', label: '..... 1.9. Склонения слов' },
				{ code: '1.10', label: '..... 1.10. Метатеги сайта' },
				{ code: '2', label: '2. Аккаунт' },
				{ code: '2.1', label: '..... 2.1. Авторизация' },
				{ code: '2.2', label: '..... 2.2. Регистрация' },
				{ code: '2.3', label: '..... 2.3. Восстановление пароля' },
				{ code: '2.4', label: '..... 2.4. Подтверждение e-mail' },
				{ code: '2.5', label: '..... 2.5. Меню сайта' },
				{ code: '2.5.1', label: '.......... 2.5.1. ... для аккаунта' },
				{ code: '2.5.2', label: '.......... 2.5.2. ... для уведомлений' },
				{ code: '2.5.3', label: '.......... 2.5.3. ... для моих средств' },
				{ code: '2.6', label: '..... 2.6. Отписка от уведомлений' },
				{ code: '3', label: '3. Сервисы' },
				{ code: '3.1', label: '..... 3.1. Новости' },
				{ code: '3.2', label: '..... 3.2. Тарифный план' },
				{ code: '3.3', label: '..... 3.3. Примеры работы' },
				{ code: '3.4', label: '..... 3.4. Настройки ботов' },
				{ code: '3.4.1', label: '.......... 3.4.1. Тест инвестиционного профиля' },
				{ code: '3.4.2', label: '.......... 3.4.2. Инструкция установки бота' },
				{ code: '3.4.3', label: '.......... 3.4.3. Карточка бота' },
				{ code: '3.4.4', label: '.......... 3.4.4. Журнал бота' },
				{ code: '3.4.5', label: '.......... 3.4.5. Статистические данные' },
				{ code: '3.5', label: '..... 3.5. Клиентская программа' },
				{ code: '3.5.1', label: '.......... 3.5.1. Дерево' },
				{ code: '3.5.2', label: '.......... 3.5.2. Топ кураторов' },
				{ code: '3.5.3', label: '.......... 3.5.3. Ваши кураторы' },
				{ code: '3.5.4', label: '.......... 3.5.4. Ваши достижения' },
				{ code: '3.5.5', label: '.......... 3.5.5. Подарки (список)' },
				{ code: '3.5.6', label: '.......... 3.5.6. Подарки (статусы)' },
				{ code: '3.5.7', label: '.......... 3.5.7. Подарки (контент)' },
				{ code: '3.5.8', label: '.......... 3.5.8. Бустер бонус' },
				{ code: '3.6', label: '..... 3.6. Мои средства (баланс)' },
				{ code: '3.6.1', label: '.......... 3.6.1. Статистика клиентской программы' },
				{ code: '3.6.2', label: '.......... 3.6.2. История операций' },
				{ code: '3.6.3', label: '.......... 3.6.3. История операций (описание)' },
				{ code: '3.6.4', label: '.......... 3.6.4. Выставленные счета' },
				{ code: '3.6.5', label: '.......... 3.6.5. История платежей' },
				{ code: '3.6.6', label: '.......... 3.6.6. История платежей (описание)' },
				{ code: '3.6.7', label: '.......... 3.6.7. Платежная квитанция (PDF)' },
				{ code: '3.6.8', label: '.......... 3.6.8. История выплат' },
				{ code: '3.6.9', label: '.......... 3.6.9. История выплат (описание)' },
				{ code: '3.6.10', label: '.......... 3.6.10. История использования перков' },
				{ code: '3.6.11', label: '.......... 3.6.11. История использования перков (описание)' },
				{ code: '3.6.12', label: '.......... 3.6.12. История переводов' },
				{ code: '3.6.13', label: '.......... 3.6.13. История переводов (описание)' },
				{ code: '3.6.14', label: '.......... 3.6.14. История использования купонов' },
				{ code: '3.6.15', label: '.......... 3.6.15. История использования купонов (описание)' },
				{ code: '3.7', label: '..... 3.7. Настройки аккаунта' },
				{ code: '3.8', label: '..... 3.8. Уведомления' },
				{ code: '3.9', label: '..... 3.9. Верификация' },
				{ code: '3.10', label: '..... 3.10. Моя команда' },
				{ code: '3.11', label: '..... 3.11. Серверы NOX Box' },
				{ code: '3.12', label: '..... 3.12. Онлайн калькуляторы' },
				{ code: '3.13', label: '..... 3.13. Конструктор сайтов' },
				{ code: '3.14', label: '..... 3.14. Топ партнеров' },
				{ code: '3.15', label: '..... 3.15. Анализ рынка' },
				{ code: '3.16', label: '..... 3.16. Промоматериалы' },
				{ code: '3.17', label: '..... 3.17. NOX VPN' },
				{ code: '3.18', label: '..... 3.18. NOX Quest' },
				{ code: '3.19', label: '..... 3.19. NOX Editor' },
				{ code: '3.19.1', label: '.......... 3.19.1. NOX Landing Editor' },
				{ code: '3.19.2', label: '.......... 3.19.2. NOX Website Editor' },
				{ code: '3.20', label: '..... 3.20. Конструктор страниц' },
				{ code: '4', label: '4. Виджеты' },
				{ code: '4.1', label: '..... 4.1. Виджет 1 (Абонентская плата)' },
				{ code: '4.2', label: '..... 4.2. Виджет 2 (Ближайший вебинар)' },
				{ code: '4.3', label: '..... 4.3. Виджет 3 (Профиль пользователя)' },
				{ code: '4.3.1', label: '.......... 4.3.1. Статус пользователя' },
				{ code: '4.3.2', label: '.......... 4.3.2. Группа пользователя' },
				{ code: '4.4', label: '..... 4.4. Виджет 4 (Продажа билетов)' },
				{ code: '4.4.1', label: '.......... 4.4.1. Билет мероприятия (PDF)' },
				{ code: '4.5', label: '..... 4.5. Виджет 5 (Состояние рынка)' },
				{ code: '5', label: '5. Модальные окна' },
				{ code: '5.1', label: '..... 5.1. NoxModalCropper' },
				{ code: '5.2', label: '..... 5.2. NoxModalWelcome' },
				{ code: '5.3', label: '..... 5.3. NoxModalPackageBuy' },
				{ code: '5.4', label: '..... 5.4. NoxModalPackageDetails' },
				{ code: '5.5', label: '..... 5.5. NoxModalPackageExtend' },
				{ code: '5.6', label: '..... 5.6. NoxModalBotSettings' },
				{ code: '5.7', label: '..... 5.7. NoxModalTreeNode' },
				{ code: '5.8', label: '..... 5.8. NoxModalKYCTests' },
				{ code: '5.9', label: '..... 5.9. NoxModalSettings' },
				{ code: '5.10', label: '..... 5.10. NoxModalVerification' },
				{ code: '5.11', label: '..... 5.11. NoxModalEvents' },
				{ code: '5.12', label: '..... 5.12. NoxModalPerks' },
				{ code: '5.13', label: '..... 5.13. NoxModalTreeGifts' },
				{ code: '5.14', label: '..... 5.14. NoxModalPortraitTests' },
				{ code: '5.15', label: '..... 5.15. NoxModalServers' },
				{ code: '5.16', label: '..... 5.16. NoxModalBalance' },
				{ code: '5.17', label: '..... 5.17. NoxModalWebsites' },
				{ code: '5.18', label: '..... 5.18. NoxModalSplash' },
				{ code: '5.19', label: '..... 5.19. NoxModalUserCard' },
				{ code: '5.20', label: '..... 5.20. NoxModalErrors' },
				{ code: '5.21', label: '..... 5.21. NoxModalVPN' },
				{ code: '5.22', label: '..... 5.22. NoxModalBotOrders' },
				{ code: '5.23', label: '..... 5.23. NoxModalRecaptcha' },
				{ code: '5.24', label: '..... 5.24. NoxModalTeam' },
				{ code: '5.25', label: '..... 5.25. NoxModalLandings' },
				{ code: '6', label: '6. E-mail сообщения' },
				{ code: '7', label: '7. Telegram сообщения' }
			],
			noxTransliterationDataFromLanguage: [],
			noxTransliterationDataFromDatabase: [],
			noxTransliterationStatisticsForLanguage: {},
			noxTransliterationStatisticsForDatabase: {},
			noxIsTransliterationSearchLoading: false,
			noxIsTransliterationFilterLoading: false,
			noxIsTransliterationFilterReInit: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				if (to.path == '/account/transliteration' && !Object.keys(to.query).length) {
					if (this.noxIsTransliterationFilterReInit) {
						this.noxTransliterationFilter = this.noxTransliterationFilterOptions[0];
					}
					this.getTransliterationData();
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || [355,463].includes(this.$store.state.noxAccountData.id)) {
					this.noxTransliterationFilter = this.noxTransliterationFilterOptions[0];
					this.getTransliterationData(this.$route.params.key);
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			isRUCharacters: function(text) {
				return this.$store.state.noxRegexSearchRULetters.test(text) ? true : false;
			},
			getCountCharacters: function(text) {
				return text.replace(/({{ \w+ }})|(<[^>]+>)/gi, '').length;
			},
			getCountAllCharacters: function(data, language) {
				var count = 0;
				for (var i in data) {
					count += this.getCountCharacters(data[i][language]);
				}
				return count;
			},
			getTransliterationData: function(key) {
				this.$parent.$parent.goToTop(true);
				this.noxTransliterationDataFromLanguage = [];
				this.noxTransliterationDataFromDatabase = [];
				this.noxIsTransliterationFilterReInit = false;
				this.closeTransliterationDataSearch();
				this.axios('getTransliterationData', key);
			},
			getTransliterationDataByFilter: function() {
				this.getTransliterationData();
			},
			getTransliterationDataBySearch: function() {
				this.noxTransliterationSearchResult.splice(0, 1);
				if (this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(this.noxTransliterationSearch)) {
					this.axios('getTransliterationDataBySearch');
				}
			},
			closeTransliterationDataSearch: function() {
				this.noxTransliterationSearch = '';
				this.noxTransliterationSearchResult.splice(0, 1);
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getTransliterationData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						if (var1 !== undefined) {
							config.url = '/v2/admin/account/transliteration/' + var1;
						}
						else {
							config.url = '/v2/admin/account/transliteration';
							config.params = { type: _this.noxTransliterationFilter.code };
						}
					}
					else if (type == 'getTransliterationDataBySearch') {
						_this.noxIsTransliterationSearchLoading = true;
						config.url = '/v2/admin/account/transliteration/search';
						config.params = { search_value: _this.noxTransliterationSearch };
					}
					else if (type == 'resetTransliteration') {
						_this.noxIsTransliterationFilterLoading = true;
						config.url = '/v2/admin/account/transliteration/reset';
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getTransliterationData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.transliteration_data_from_language !== undefined) {
									for (var i in data.data.transliteration_data_from_language.ru) {
										_this.noxTransliterationDataFromLanguage.push({ ru: data.data.transliteration_data_from_language.ru[i], en: data.data.transliteration_data_from_language.en[i] });
									}
								}
								if (data.data.transliteration_data_from_database !== undefined) {
									for (var j in data.data.transliteration_data_from_database.ru) {
										_this.noxTransliterationDataFromDatabase.push({ ru: data.data.transliteration_data_from_database.ru[j], en: data.data.transliteration_data_from_database.en[j] });
									}
								}
								if (data.data.transliteration_statistics_for_language !== undefined) {
									_this.noxTransliterationStatisticsForLanguage = data.data.transliteration_statistics_for_language;
								}
								if (data.data.transliteration_statistics_for_database !== undefined) {
									_this.noxTransliterationStatisticsForDatabase = data.data.transliteration_statistics_for_database;
								}
								if (var1 !== undefined) {
									_this.noxIsTransliterationFilterReInit = true;
									if (_this.$route.path !== '/account/transliteration/' + var1) {
										_this.$router.push({ path: '/account/transliteration/' + var1 });
									}
									if (data.data.transliteration_type !== undefined) {
										var codes = _this.noxTransliterationFilterOptions.map(a => a.code);
										var index = codes.indexOf(String(data.data.transliteration_type));
										_this.noxTransliterationFilter = _this.noxTransliterationFilterOptions[(index >= 0 ? index : 0)];
									}
								}
								else {
									if (_this.$route.path !== '/account/transliteration') {
										_this.$router.push({ path: '/account/transliteration' });
									}
								}
							}
							else if (type == 'getTransliterationDataBySearch') {
								_this.noxTransliterationSearchResult.splice(0, 1, data.data);
							}
						}
					}).catch(function() {
						if (type == 'getTransliterationDataBySearch') {
							_this.noxTransliterationSearchResult.splice(0, 1);
						}
						else {
							_this.$parent.$parent.exit();
						}
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsTransliterationSearchLoading = false;
						_this.noxIsTransliterationFilterLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalTransliteration: () => import('@/views/modal_components/NoxModalTransliteration.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_transliteration_navigation {
		margin: 0 0 15px 0;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_transliteration_navigation_filters {
		position: relative;
		margin: 2px 0 0 0;
		padding: 0 127px 0 92px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_transliteration_navigation_filters:first-child {
		margin: 0;
	}
	.nox_account_desktop_transliteration_navigation_filters_label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px;
		border-radius: 15px 2px 2px 15px;
		background: #333333B3;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 90px;
		height: 30px;
	}
	.nox_account_desktop_transliteration_navigation_filters_select {
		padding: 0 15px;
		border-radius: 2px;
		background: #111111B3;
		width: 100%;
		height: 30px;
	}
	.nox_account_desktop_transliteration_navigation_filters_select .nox_select {
		margin: 0;
	}
	.nox_account_desktop_transliteration_navigation_filters_select .nox_select .vs__dropdown-toggle {
		padding: 0 0 2px 0;
		border-radius: 0;
		background: transparent;
		line-height: 30px;
		height: 30px;
	}
	.nox_account_desktop_transliteration_navigation_filters_select .nox_select .vs__dropdown-menu {
		margin: 1px 0 0 0;
	}
	.nox_account_desktop_transliteration_navigation_filters_reset_cache {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 2px 15px 15px 2px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #008000B3;
		text-align: center;
		line-height: 30px;
		width: 125px;
		height: 30px;
	}
	.nox_account_desktop_transliteration_navigation_filters_reset_cache img {
		margin: -3px 0 0 0;
	}
	.nox_account_desktop_transliteration_navigation_filters_reset_cache button {
		font-size: 12px;
	}
	.nox_account_desktop_transliteration_statistics_row span {
		margin: 0 0 0 8px;
	}
	.nox_account_desktop_transliteration tr th:nth-child(2),
	.nox_account_desktop_transliteration tr td:nth-child(2),
	.nox_account_desktop_transliteration tr th:nth-child(4),
	.nox_account_desktop_transliteration tr td:nth-child(4) {
		word-break: break-all;
		word-break: break-word;
		width: 50%;
	}

	@media (max-width: 1000px) {
		.nox_account_desktop_transliteration_navigation {
			margin: 0 0 10px 0;
		}
	}
	@media (max-width: 650px) {
		.nox_account_desktop_transliteration_navigation_filters,
		.nox_account_desktop_transliteration_navigation_filters:first-child {
			margin: 0 0 10px 0;
			padding: 0;
		}
		.nox_account_desktop_transliteration_navigation_filters:last-child {
			margin: 0;
		}
		.nox_account_desktop_transliteration_navigation_filters_label {
			position: relative;
			border-radius: 15px 15px 2px 2px;
			width: 100%;
		}
		.nox_account_desktop_transliteration_navigation_filters_select {
			margin: 2px 0;
		}
		.nox_account_desktop_transliteration_navigation_filters_reset_cache {
			position: relative;
			border-radius: 2px 2px 15px 15px;
			width: 100%;
		}
	}
</style>
