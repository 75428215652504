<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_vpn">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.17', 0)"></div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_portlet_content_intro" v-html="$store.getters.getLanguageText('3.17', 1)"></div>
						<div class="nox_account_desktop_portlet_content_sdata">
							<div class="nox_account_desktop_portlet_content_row">
								<div class="nox_account_desktop_portlet_content_label">
									<span v-html="$store.getters.getLanguageText('3.17', 2)"></span>
									<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 207), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
								</div>
								<div class="nox_account_desktop_portlet_content_value">
									<div class="nox_account_desktop_portlet_content_value_content">
										<span class="nox_value" :class="{ red: (($store.state.noxAccountData.vpn_subscription_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)) < 7 }" v-if="$store.state.noxAccountData.vpn_subscription_at > $store.state.noxSystemSettings.time">
											<span v-if="(($store.state.noxAccountData.vpn_subscription_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)) > 1" v-html="$store.getters.getLanguageText('3.17', 5, { count: Math.floor(($store.state.noxAccountData.vpn_subscription_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)), options: [$parent.$parent.getDeclension(Math.floor(($store.state.noxAccountData.vpn_subscription_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)))] })"></span>
											<span v-else-if="(($store.state.noxAccountData.vpn_subscription_at - $store.state.noxSystemSettings.time) / (60 * 60)) > 1" v-html="$store.getters.getLanguageText('3.17', 6, { count: Math.floor(($store.state.noxAccountData.vpn_subscription_at - $store.state.noxSystemSettings.time) / (60 * 60)), options: [$parent.$parent.getDeclension(Math.floor(($store.state.noxAccountData.vpn_subscription_at - $store.state.noxSystemSettings.time) / (60 * 60)))] })"></span>
											<span v-else v-html="$store.getters.getLanguageText('3.17', 7)"></span>
										</span>
										<b class="nox_value red uppercase" v-else v-html="$store.getters.getLanguageText('3.17', 4)"></b>
										<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalVPN' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'extendVPNSubscription' : 'addMainBalance') })" v-html="$store.getters.getLanguageText('1.1', 36)"></button>
										<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalVPN' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'extendVPNSubscription' : 'addMainBalance') })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_content_row">
								<div class="nox_account_desktop_portlet_content_label">
									<span v-html="$store.getters.getLanguageText('3.17', 3)"></span>
									<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 208), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
								</div>
								<div class="nox_account_desktop_portlet_content_value">
									<toggle-button class="nox_toggle_button" :value="toggleValue($store.state.noxAccountData.vpn_is_subscription_auto)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForVPNIsSubscriptionAuto"></toggle-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalVPN"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getVPNData');
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getVPNData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/account/vpn';
					}
					else if (type == 'editVPNIsSubscriptionAuto') {
						config.url = '/v2/account/vpn/is_subscription_auto';
						config.data = { is_subscription_auto: var1 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getVPNData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.account_data !== undefined) {
									_this.$parent.$parent.initAccountData(data.data.account_data);
								}
							}
							else if (type == 'editVPNIsSubscriptionAuto') {
								_this.$store.state.noxAccountData.vpn_is_subscription_auto = var1;
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForVPNIsSubscriptionAuto: function(event) {
				this.axios('editVPNIsSubscriptionAuto', Number(event.value));
			}
		},
		components: {
			NoxModalVPN: () => import('@/views/modal_components/NoxModalVPN.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>
