<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_tariffs">
				<div class="nox_account_desktop_portlet" v-if="!$store.state.noxAccountData.package">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.2', 0)"></div>
					<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.2', 1)"></div>
				</div>
				<div class="nox_account_desktop_portlet" v-for="(value, index) in [3,4,5,6]" :key="index">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.2', 2, { name: $store.state.noxSystemSettings['package_name_x' + value] })"></div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_tariffs_description">
							<div class="nox_account_desktop_tariffs_description_img"><img :src="require(`@/assets/images/package_${value}.png`)"></div>
							<div class="nox_account_desktop_tariffs_description_content" v-html="$store.getters.getLanguageText('3.2', (3 + value), { name: $store.state.noxSystemSettings['package_name_x' + value], limit: $store.state.noxSystemSettings['founds_limit_to_use_x' + value] })"></div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_buttons">
						<button type="button" class="nox_button big" @click="$modal.show('NoxModalPackageDetails', { package: value })"><font-awesome-icon :icon="['fas', 'question']" /><span v-html="$store.getters.getLanguageText('1.1', 21)"></span></button>
						<button type="button" class="nox_button big green transparent" v-if="$store.state.noxAccountData.package >= value"><font-awesome-icon :icon="['fas', 'check']" /><span v-html="$store.getters.getLanguageText('1.1', 23)"></span></button>
						<button type="button" class="nox_button big green" @click="$modal.show(($store.state.noxAccountData.is_verification ? (($store.state.noxAccountData.is_grey && value < 5) ? 'NoxModalVerification' : ($store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalPackageBuy' : 'NoxModalBalance')) : 'NoxModalVerification'), { type: ($store.state.noxAccountData.is_verification ? (($store.state.noxAccountData.is_grey && value < 5 ? 'isGreyVerification' : ($store.state.noxAccountData.balance_nox >= 0 ? value : 'addMainBalance'))) : 'welcomeVerification'), package: value })" v-else><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('1.1', 24, { cost: ($store.state.noxSystemSettings['package_cost_x' + value] - $store.state.noxSystemSettings['package_cost_x' + $store.state.noxAccountData.package]), vat: $store.state.noxSystemSettings.vat_value })"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalPackageBuy"></div>
				<div is="NoxModalPackageExtend"></div>
				<div is="NoxModalPackageDetails"></div>
				<div is="NoxModalVerification"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsAccount: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					this.noxIsAccount = true; setTimeout(this.isReady, 0); return false;
				}
				if (this.noxIsAccount) {
					this.noxIsLoading = false;
					this.$store.state.noxIsAccount = false;
					this.$store.state.noxIsLoading = false;
				}
				else {
					this.axios();
				}
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/account',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.noxIsLoading = true;
					_this.$store.state.noxIsAccount = true;

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.$parent.goToTop(true);
							_this.$parent.$parent.checkAppVersion(data.headers);
							if (data.data.account_data !== undefined) {
								_this.$parent.$parent.initAccountData(data.data.account_data);
							}
							if (data.data.system_settings !== undefined) {
								_this.$parent.$parent.initSystemSettings(data.data.system_settings);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalPackageBuy: () => import('@/views/modal_components/NoxModalPackageBuy.vue'),
			NoxModalPackageExtend: () => import('@/views/modal_components/NoxModalPackageExtend.vue'),
			NoxModalPackageDetails: () => import('@/views/modal_components/NoxModalPackageDetails.vue'),
			NoxModalVerification: () => import('@/views/modal_components/NoxModalVerification.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_tariffs_description {
		position: relative;
		padding: 0 0 0 265px;
	}
	.nox_account_desktop_tariffs_description img {
		position: absolute;
		top: 50%;
		left: 0;
		margin: -125px 0 0 0;
		width: 250px;
		height: 250px;
	}

	@media (max-width: 650px) {
		.nox_account_desktop_tariffs_description {
			padding: 0;
		}
		.nox_account_desktop_tariffs_description img {
			position: relative;
			top: 0;
			left: 50%;
			margin: 0 0 20px -100px;
			width: 200px;
			height: 200px;
		}
	}
</style>
