import Vue from 'vue'
import Router from 'vue-router'
import NoxLogin from '@/views/oauth_components/NoxLogin'
import NoxRegistration from '@/views/oauth_components/NoxRegistration'
import NoxRegistrationSuccess from '@/views/oauth_components/NoxRegistrationSuccess'
import NoxRegistrationConfirm from '@/views/oauth_components/NoxRegistrationConfirm'
import NoxRegistrationActivate from '@/views/oauth_components/NoxRegistrationActivate'
import NoxPasswordRecovery from '@/views/oauth_components/NoxPasswordRecovery'
import NoxPasswordRecoverySuccess from '@/views/oauth_components/NoxPasswordRecoverySuccess'
import NoxPasswordRecoveryReset from '@/views/oauth_components/NoxPasswordRecoveryReset'
import NoxPasswordRecoveryResetSuccess from '@/views/oauth_components/NoxPasswordRecoveryResetSuccess'
import NoxMailingsUnsubscribe from '@/views/oauth_components/NoxMailingsUnsubscribe'
import NoxEmailConfirm from '@/views/oauth_components/NoxEmailConfirm'
import NoxInfo from '@/views/info_components/NoxInfo'
import NoxInfoNews from '@/views/info_components/NoxInfoNews'
import NoxInfoStats from '@/views/info_components/NoxInfoStats'
import NoxInfoAnalysis from '@/views/info_components/NoxInfoAnalysis'
import NoxInfoCalculators from '@/views/info_components/NoxInfoCalculators'
import NoxEditor from '@/views/editor_components/NoxEditor'
import NoxEditorDesktop from '@/views/editor_components/NoxEditorDesktop'
import NoxEditorLanding from '@/views/editor_components/NoxEditorLanding'
import NoxEditorWebsite from '@/views/editor_components/NoxEditorWebsite'
import NoxAccount from '@/views/account_components/NoxAccount'
import NoxAccountNews from '@/views/account_components/NoxAccountNews'
import NoxAccountTeam from '@/views/account_components/NoxAccountTeam'
import NoxAccountBots from '@/views/account_components/NoxAccountBots'
import NoxAccountStats from '@/views/account_components/NoxAccountStats'
import NoxAccountAlerts from '@/views/account_components/NoxAccountAlerts'
import NoxAccountAlertsSettings from '@/views/account_components/NoxAccountAlertsSettings'
import NoxAccountAnalysis from '@/views/account_components/NoxAccountAnalysis'
import NoxAccountTariffs from '@/views/account_components/NoxAccountTariffs'
import NoxAccountBalance from '@/views/account_components/NoxAccountBalance'
import NoxAccountSettings from '@/views/account_components/NoxAccountSettings'
import NoxAccountCalculators from '@/views/account_components/NoxAccountCalculators'
import NoxAccountClientProgram from '@/views/account_components/NoxAccountClientProgram'
import NoxAccountPromoMaterials from '@/views/account_components/NoxAccountPromoMaterials'
import NoxAccountVerification from '@/views/account_components/NoxAccountVerification'
import NoxAccountTopPartners from '@/views/account_components/NoxAccountTopPartners'
import NoxAccountLandings from '@/views/account_components/NoxAccountLandings'
import NoxAccountWebsites from '@/views/account_components/NoxAccountWebsites'
import NoxAccountServers from '@/views/account_components/NoxAccountServers'
import NoxAccountQuests from '@/views/account_components/NoxAccountQuests'
import NoxAccountVPN from '@/views/account_components/NoxAccountVPN'
import NoxAccountZP from '@/views/admin_components/NoxAccountZP'
import NoxAccountAPI from '@/views/admin_components/NoxAccountAPI'
import NoxAccountUsers from '@/views/admin_components/NoxAccountUsers'
import NoxAccountSplash from '@/views/admin_components/NoxAccountSplash'
import NoxAccountDatabase from '@/views/admin_components/NoxAccountDatabase'
import NoxAccountDelivery from '@/views/admin_components/NoxAccountDelivery'
import NoxAccountMailings from '@/views/admin_components/NoxAccountMailings'
import NoxAccountStatistics from '@/views/admin_components/NoxAccountStatistics'
import NoxAccountAllServers from '@/views/admin_components/NoxAccountAllServers'
import NoxAccountNewsTrading from '@/views/admin_components/NoxAccountNewsTrading'
import NoxAccountZigzagTrading from '@/views/admin_components/NoxAccountZigzagTrading'
import NoxAccountPhoenixTrading from '@/views/admin_components/NoxAccountPhoenixTrading'
import NoxAccountPhoenixSettings from '@/views/admin_components/NoxAccountPhoenixSettings'
import NoxAccountPortraitSettings from '@/views/admin_components/NoxAccountPortraitSettings'
import NoxAccountStrategySettings from '@/views/admin_components/NoxAccountStrategySettings'
import NoxAccountTransliteration from '@/views/admin_components/NoxAccountTransliteration'
import NoxAccountSystemSettings from '@/views/admin_components/NoxAccountSystemSettings'
import NoxAccountServerSettings from '@/views/admin_components/NoxAccountServerSettings'
import NoxAccountMasterSettings from '@/views/admin_components/NoxAccountMasterSettings'
import NoxAccountBonusSettings from '@/views/admin_components/NoxAccountBonusSettings'
import NoxAccountBotsSettings from '@/views/admin_components/NoxAccountBotsSettings'
import NoxAccountCronSettings from '@/views/admin_components/NoxAccountCronSettings'
import NoxAccountKYCSettings from '@/views/admin_components/NoxAccountKYCSettings'
import NoxAccountTelegramBot from '@/views/admin_components/NoxAccountTelegramBot'
import NoxAccountAdminLandings from '@/views/admin_components/NoxAccountAdminLandings'
import NoxAccountAdminQuests from '@/views/admin_components/NoxAccountAdminQuests'
import NoxAccountAdminVPN from '@/views/admin_components/NoxAccountAdminVPN'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      name: 'Nox404',
      component: NoxLogin,
      meta: { transliteration: [2,3] }
    },
    {
      path: '/',
      name: 'NoxLogin',
      component: NoxLogin,
      meta: { transliteration: [2,3] }
    },
    {
      path: '/registration',
      name: 'NoxRegistration',
      component: NoxRegistration,
      meta: { transliteration: [4,5] }
    },
    {
      path: '/registration/success',
      name: 'NoxRegistrationSuccess',
      component: NoxRegistrationSuccess,
      meta: { transliteration: [4,5] }
    },
    {
      path: '/registration/confirm',
      name: 'NoxRegistrationConfirm',
      component: NoxRegistrationConfirm,
      meta: { transliteration: [4,5] }
    },
    {
      path: '/registration/activate',
      name: 'NoxRegistrationActivate',
      component: NoxRegistrationActivate,
      meta: { transliteration: [4,5] }
    },
    {
      path: '/password/recovery',
      name: 'NoxPasswordRecovery',
      component: NoxPasswordRecovery,
      meta: { transliteration: [6,7] }
    },
    {
      path: '/password/recovery/success',
      name: 'NoxPasswordRecoverySuccess',
      component: NoxPasswordRecoverySuccess,
      meta: { transliteration: [6,7] }
    },
    {
      path: '/password/recovery/reset',
      name: 'NoxPasswordRecoveryReset',
      component: NoxPasswordRecoveryReset,
      meta: { transliteration: [6,7] }
    },
    {
      path: '/password/recovery/reset/success',
      name: 'NoxPasswordRecoveryResetSuccess',
      component: NoxPasswordRecoveryResetSuccess,
      meta: { transliteration: [6,7] }
    },
    {
      path: '/mailings/unsubscribe/:key',
      name: 'NoxMailingsUnsubscribe',
      component: NoxMailingsUnsubscribe
    },
    {
      path: '/email/confirm',
      name: 'NoxEmailConfirm',
      component: NoxEmailConfirm
    },
    {
      path: '/info',
      component: NoxInfo,
      children: [
        { path: '', component: NoxInfoNews, meta: { transliteration: [8,9] }},
        { path: 'news', component: NoxInfoNews, meta: { transliteration: [8,9] }},
        { path: 'news/:id/', component: NoxInfoNews, meta: { transliteration: [8,9] }},
        { path: 'news/:id/:key', component: NoxInfoNews, meta: { transliteration: [8,9] }},
        { path: 'stats', component: NoxInfoStats, meta: { transliteration: [10,11] }},
        { path: 'stats/:key', component: NoxInfoStats, meta: { transliteration: [10,11] }},
        { path: 'analysis', component: NoxInfoAnalysis, meta: { transliteration: [12,13] }},
        { path: 'analysis/:id/', component: NoxInfoAnalysis, meta: { transliteration: [12,13] }},
        { path: 'analysis/:id/:key', component: NoxInfoAnalysis, meta: { transliteration: [12,13] }},
        { path: 'calculators', component: NoxInfoCalculators, meta: { transliteration: [14,15] }},
        { path: ':pathMatch(.*)*', component: NoxInfoNews, meta: { transliteration: [8,9] }}
      ]
    },
    {
      path: '/editor',
      component: NoxEditor,
      children: [
        { path: '', component: NoxEditorDesktop },
        { path: 'desktop', component: NoxEditorDesktop },
        { path: 'landing', component: NoxEditorLanding },
        { path: 'landing/:id', component: NoxEditorLanding },
        { path: 'website', component: NoxEditorWebsite },
        { path: 'website/:uid', component: NoxEditorWebsite },
        { path: ':pathMatch(.*)*', component: NoxEditorDesktop }
      ]
    },
    {
      path: '/account',
      component: NoxAccount,
      children: [
        { path: '', component: NoxAccountNews },
        { path: 'news', component: NoxAccountNews },
        { path: 'team', component: NoxAccountTeam },
        { path: 'bots', component: NoxAccountBots },
        { path: 'bots/:type', component: NoxAccountBots },
        { path: 'bots/:type/:key', component: NoxAccountBots },
        { path: 'bot_settings', component: NoxAccountBots },
        { path: 'stats', component: NoxAccountStats },
        { path: 'stats/:key', component: NoxAccountStats },
        { path: 'alerts', component: NoxAccountAlerts },
        { path: 'alert_settings', component: NoxAccountAlertsSettings },
        { path: 'analysis', component: NoxAccountAnalysis },
        { path: 'tariffs', component: NoxAccountTariffs },
        { path: 'balance', component: NoxAccountBalance },
        { path: 'settings', component: NoxAccountSettings },
        { path: 'calculators', component: NoxAccountCalculators },
        { path: 'client_program', component: NoxAccountClientProgram },
        { path: 'client_program/:key', component: NoxAccountClientProgram },
        { path: 'promo_materials', component: NoxAccountPromoMaterials },
        { path: 'verification', component: NoxAccountVerification },
        { path: 'top_partners', component: NoxAccountTopPartners },
        { path: 'landings', component: NoxAccountLandings },
        { path: 'websites', component: NoxAccountWebsites },
        { path: 'servers', component: NoxAccountServers },
        { path: 'quests', component: NoxAccountQuests },
        { path: 'vpn', component: NoxAccountVPN },
        { path: 'zp', component: NoxAccountZP },
        { path: 'api', component: NoxAccountAPI },
        { path: 'users', component: NoxAccountUsers },
        { path: 'users/:key', component: NoxAccountUsers },
        { path: 'splash', component: NoxAccountSplash },
        { path: 'database', component: NoxAccountDatabase },
        { path: 'delivery', component: NoxAccountDelivery },
        { path: 'mailings', component: NoxAccountMailings },
        { path: 'statistics', component: NoxAccountStatistics },
        { path: 'all_servers', component: NoxAccountAllServers },
        { path: 'all_servers/:key', component: NoxAccountAllServers },
        { path: 'news_trading', component: NoxAccountNewsTrading },
        { path: 'zigzag_trading', component: NoxAccountZigzagTrading },
        { path: 'phoenix_trading', component: NoxAccountPhoenixTrading },
        { path: 'phoenix_settings', component: NoxAccountPhoenixSettings },
        { path: 'portrait_settings', component: NoxAccountPortraitSettings },
        { path: 'strategy_settings', component: NoxAccountStrategySettings },
        { path: 'transliteration', component: NoxAccountTransliteration },
        { path: 'transliteration/:key', component: NoxAccountTransliteration },
        { path: 'system_settings', component: NoxAccountSystemSettings },
        { path: 'server_settings', component: NoxAccountServerSettings },
        { path: 'master_settings', component: NoxAccountMasterSettings },
        { path: 'bonus_settings', component: NoxAccountBonusSettings },
        { path: 'bots_settings', component: NoxAccountBotsSettings },
        { path: 'cron_settings', component: NoxAccountCronSettings },
        { path: 'kyc_settings', component: NoxAccountKYCSettings },
        { path: 'telegram_bot', component: NoxAccountTelegramBot },
        { path: 'admin/landings', component: NoxAccountAdminLandings },
        { path: 'admin/quests', component: NoxAccountAdminQuests },
        { path: 'admin/vpn', component: NoxAccountAdminVPN },
        { path: ':pathMatch(.*)*', component: NoxAccountNews }
      ]
    }
  ],
  scrollBehavior: function(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  }
})
