<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_auth registration_success">
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.2', 0)"></div>
					<form class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_text" v-html="$store.getters.getLanguageText('2.2', 9, { email: $route.query.email })"></div>
						<div class="nox_auth_block_content_form_row last">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.2', 5)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="text" class="nox_input" id="code" name="code" v-model.trim="noxCode" autocomplete="off">
								<div v-html="noxAlertCode"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_alert" v-html="noxAlert"></div>
						<div class="nox_auth_block_content_form_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_auth_block_content_form_buttons" :class="{ hide: noxIsLoading }">
							<button type="button" class="nox_button common blue" @click="axios('registrationResend')" v-if="noxIsResendEmail" v-html="$store.getters.getLanguageText('1.1', 117)"></button>
							<button type="submit" class="nox_button common green" @click="submit($event, 'registrationActivate')" v-html="$store.getters.getLanguageText('1.1', 160)"></button>
						</div>
						<div class="nox_auth_block_content_form_footer">
							<div class="nox_auth_block_content_form_footer_mcountdown" v-if="noxIsMCountdown">
								<span v-html="$store.getters.getLanguageText('2.2', 10)"></span>
								<span class="nox_mcountdown" v-html="noxMCountdownValue"></span>
							</div>
							<router-link to="/">[<span v-html="$store.getters.getLanguageText('1.1', 31)"></span>]</router-link>
						</div>
					</form>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertCode: '',
			noxEmail: '',
			noxCode: '',
			noxMCountdownTime: 0,
			noxMCountdownValue: '',
			noxMCountdownTimerId: 0,
			noxIsResendEmail: false,
			noxIsMCountdown: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			isReady: function() {

				var _this = this;
				if (_this.$route.query.email !== undefined && _this.$store.state.noxRegexEmail.test(_this.$route.query.email)) {
					_this.noxEmail = _this.$route.query.email;
					_this.noxMCountdownTime = (new Date().getTime()) + (2 * 60 * 1000);
					_this.noxIsMCountdown = true;
					_this.getMCountdown();
					_this.noxMCountdownTimerId = setInterval(function() { _this.getMCountdown(); }, 1000);
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertCode = '';
			},
			getError: function(i, data) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 4); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 5); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 36); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 37); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 6); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 56); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 57); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 58); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 59, data); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 60, data); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getMCountdown: function() {

				var time = (this.noxMCountdownTime - (new Date().getTime())) / 1000;
				var minutes = parseInt(time / 60);
				var seconds = parseInt(time % 60);
				this.noxMCountdownValue = this.$parent.getNumberPad(minutes) + ':' + this.$parent.getNumberPad(seconds);
				if (Number(minutes) <= 0 && Number(seconds) < 1) {
					this.noxIsResendEmail = true; this.noxIsMCountdown = false; clearInterval(this.noxMCountdownTimerId);
				}
			},
			submit: function(event, type) {
				event.preventDefault(); this.axios(type);
			},
			axios: function(type) {

				var _this = this;
				if (_this.$store.state.noxOAuthTokenForRegistration) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxOAuthTokenForRegistration }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'registrationResend') {
						config.url = '/v2/oauth/registration/resend';
						config.data = { email: _this.noxEmail, language: _this.$store.state.noxLanguage };
						config.method = 'patch';
					}
					else if (type == 'registrationActivate') {
						if (!_this.noxCode) {
							_this.noxAlertCode = _this.getError(6);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxCode) || _this.noxCode < 100000 || _this.noxCode > 999999) {
							_this.noxAlertCode = _this.getError(7);
						}
						else if (!_this.noxEmail) {
							_this.noxAlert = _this.getError(1);
						}
						else if (!_this.$store.state.noxRegexEmail.test(_this.noxEmail)) {
							_this.noxAlert = _this.getError(2);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/oauth/registration/activate';
							config.data = { email: _this.noxEmail, code: _this.noxCode };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'registrationResend') {
									_this.noxIsResendEmail = false;
								}
								else if (type == 'registrationActivate') {
									_this.$router.push({ path: '/registration/activate' });
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'registrationResend') {
										if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'LANGUAGE_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'LANGUAGE_NOT_VALID') { _this.noxAlert = _this.getError(4); }
									}
									else if (type == 'registrationActivate') {
										if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'CODE_IS_EMPTY') { _this.noxAlertCode = _this.getError(6); }
										else if (data.response.data.error == 'CODE_NOT_VALID') { _this.noxAlertCode = _this.getError(7); }
									}
								}
								else if (data.response.status == 404) {
									if (type == 'registrationResend') {
										if (data.response.data.error == 'EMAIL_NOT_FOUND') { _this.noxAlert = _this.getError(5); }
									}
									else if (type == 'registrationActivate') {
										if      (data.response.data.error == 'EMAIL_NOT_FOUND') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'CODE_NOT_FOUND') { _this.noxAlertCode = _this.getError(8); }
									}
								}
								else if (data.response.status == 429) {
									if (data.response.headers['retry-after'] !== undefined) {
										if (type == 'registrationResend') {
											_this.noxAlert = _this.getError(9, { options: [_this.$parent.getDeclension(Number(data.response.headers['retry-after']))], seconds: Number(data.response.headers['retry-after']) });
										}
										else if (type == 'registrationActivate') {
											_this.noxAlert = _this.getError(10, { options: [_this.$parent.getDeclension(Number(data.response.headers['retry-after']))], seconds: Number(data.response.headers['retry-after']) });
										}
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
			}
		},
		components: {
			NoxAuthHeader,
			NoxAuthFooter
		},
		created: function() {
			this.isReady();
		}
	}
</script>
