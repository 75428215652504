<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_kyc_settings">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_portlet_content_row single">
							<div class="nox_account_desktop_portlet_content_label">Отображать тест:</div>
							<div class="nox_account_desktop_portlet_content_value"><toggle-button class="nox_toggle_button" :value="toggleValue(noxIsActiveKYCQuestions)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActiveKYCQuestions"></toggle-button></div>
						</div>
						<div class="nox_account_desktop_portlet_content_row single">
							<div class="nox_account_desktop_portlet_content_label">Перемешивать вопросы:</div>
							<div class="nox_account_desktop_portlet_content_value"><toggle-button class="nox_toggle_button" :value="toggleValue(noxIsRandomKYCQuestions)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsRandomKYCQuestions"></toggle-button></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_kyc_settings_questions" v-if="noxKYCQuestions.length">
					<div class="nox_account_desktop_portlet" v-for="(value, index) in noxKYCQuestions" :key="index">
						<div class="nox_account_desktop_portlet_title">Вопрос #{{ value.sorting }}</div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_kyc_settings_question">
								<table class="nox_table">
									<thead>
										<tr>
											<th>Вопрос (RU)</th>
											<th>Вопрос (EN)</th>
											<th>Статус</th>
											<th><font-awesome-icon :icon="['fas', 'random']" /></th>
											<th><font-awesome-icon :icon="['fas', 'arrow-up']" /></th>
											<th><font-awesome-icon :icon="['fas', 'arrow-down']" /></th>
											<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
											<th><font-awesome-icon :icon="['fas', 'times']" /></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td data-label="Вопрос (RU):"><span v-html="value.question_ru"></span><br><br><b class="blue">СООБЩЕНИЕ НА НЕВЕРНЫЙ ОТВЕТ:</b><br><span v-html="value.answer_ru"></span></td>
											<td data-label="Вопрос (EN):"><span v-html="value.question_en"></span><br><br><b class="blue">СООБЩЕНИЕ НА НЕВЕРНЫЙ ОТВЕТ:</b><br><span v-html="value.answer_en"></span></td>
											<td data-label="Статус вопроса:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.status)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStatus(value.id, index, $event)"></toggle-button></td>
											<td data-label="Случайные ответы:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.is_random)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsRandom(value.id, index, $event)"></toggle-button></td>
											<td data-label="Переместить вверх:"><span :class="{ hide: !(noxKYCQuestionsSwap == 'up' + value.id + '.' + index) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxKYCQuestionsSwap == 'up' + value.id + '.' + index) }" @click="axios('swapUpKYCQuestion', value.id, index, value.sorting, noxKYCQuestions[index - 1].sorting)" v-if="index"><font-awesome-icon :icon="['fas', 'arrow-up']" /></button></td>
											<td data-label="Переместить вниз:"><span :class="{ hide: !(noxKYCQuestionsSwap == 'down' + value.id + '.' + index) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxKYCQuestionsSwap == 'down' + value.id + '.' + index) }" @click="axios('swapDownKYCQuestion', value.id, index, value.sorting, noxKYCQuestions[index + 1].sorting)" v-if="noxKYCQuestions.length > index + 1"><font-awesome-icon :icon="['fas', 'arrow-down']" /></button></td>
											<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalKYCSettings', { type: 'editKYCQuestion', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
											<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalKYCSettings', { type: 'deleteKYCQuestion', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="nox_account_desktop_kyc_settings_answers" v-if="Object.keys(noxKYCAnswers).length && noxKYCAnswers[value.id] && noxKYCAnswers[value.id].length">
								<div class="nox_account_desktop_kyc_settings_answers_title">Список ответов на вопрос:</div>
								<table class="nox_table">
									<thead>
										<tr>
											<th>№</th>
											<th>Ответ (RU)</th>
											<th>Ответ (EN)</th>
											<th><font-awesome-icon :icon="['fas', 'check']" /></th>
											<th><font-awesome-icon :icon="['fas', 'arrow-up']" /></th>
											<th><font-awesome-icon :icon="['fas', 'arrow-down']" /></th>
											<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
											<th><font-awesome-icon :icon="['fas', 'times']" /></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value2, index2) in noxKYCAnswers[value.id]" :key="index2">
											<td data-label="№:">{{ value2.sorting }}</td>
											<td data-label="Ответ (RU):" v-html="value2.answer_ru"></td>
											<td data-label="Ответ (EN):" v-html="value2.answer_en"></td>
											<td data-label="Верный ответ:"><span :class="[value2.is_correct ? 'green' : 'red']">{{ value2.is_correct ? 'Да' : 'Нет' }}</span></td>
											<td data-label="Переместить вверх:"><span :class="{ hide: !(noxKYCAnswersSwap == 'up' + value2.qid + '.' + index2) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxKYCAnswersSwap == 'up' + value2.qid + '.' + index2) }" @click="axios('swapUpKYCAnswer', value2.qid, index2, value2.sorting, noxKYCAnswers[value.id][index2 - 1].sorting)" v-if="index2"><font-awesome-icon :icon="['fas', 'arrow-up']" /></button></td>
											<td data-label="Переместить вниз:"><span :class="{ hide: !(noxKYCAnswersSwap == 'down' + value2.qid + '.' + index2) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxKYCAnswersSwap == 'down' + value2.qid + '.' + index2) }" @click="axios('swapDownKYCAnswer', value2.qid, index2, value2.sorting, noxKYCAnswers[value.id][index2 + 1].sorting)" v-if="noxKYCAnswers[value.id].length > (index2 + 1)"><font-awesome-icon :icon="['fas', 'arrow-down']" /></button></td>
											<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalKYCSettings', { type: 'editKYCAnswer', index: index2, id: value2.id, qid: value2.qid })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
											<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalKYCSettings', { type: 'deleteKYCAnswer', index: index2, id: value2.id, qid: value2.qid })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_buttons">
							<button type="button" class="nox_button big green" @click="$modal.show('NoxModalKYCSettings', { type: 'addKYCAnswer', qid: value.id })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить ответ</button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-else>
					<div class="nox_account_desktop_portlet_content">Список вопросов пуст.</div>
				</div>
				<div class="nox_account_desktop_page_content_buttons">
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalKYCSettings', { type: 'addKYCQuestion' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить вопрос</button>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalKYCSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxKYCQuestions: [],
			noxKYCQuestionsSwap: '',
			noxKYCAnswers: {},
			noxKYCAnswersSwap: '',
			noxKYCLimitOffset: 0,
			noxKYCLimitCount: 50,
			noxIsActiveKYCQuestions: 0,
			noxIsRandomKYCQuestions: 0,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || [355,463].includes(this.$store.state.noxAccountData.id)) {
					this.noxIsActiveKYCQuestions = this.$store.state.noxSystemSettings.is_active_kyc_questions;
					this.noxIsRandomKYCQuestions = this.$store.state.noxSystemSettings.is_random_kyc_questions;
					this.axios('getKYCSettings');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type, var1, var2, var3, var4) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getKYCSettings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/kyc/settings';
						config.params = { limitOffset: _this.noxKYCLimitOffset, limitCount: _this.noxKYCLimitCount };
					}
					else if (type == 'editKYCQuestionStatus') {
						_this.noxKYCQuestions[var2].status = var3;
						config.url = '/v2/admin/account/kyc/questions/status';
						config.data = { id: var1, status: var3 };
						config.method = 'patch';
					}
					else if (type == 'editKYCQuestionIsRandom') {
						_this.noxKYCQuestions[var2].is_random = var3;
						config.url = '/v2/admin/account/kyc/questions/is_random';
						config.data = { id: var1, is_random: var3 };
						config.method = 'patch';
					}
					else if (type == 'editKYCQuestionsIsRandom') {
						_this.noxIsRandomKYCQuestions = var1;
						_this.$set(_this.$store.state.noxSystemSettings, 'is_random_kyc_questions', var1);
						config.url = '/v2/admin/account/kyc/questions/is_random_kyc_questions';
						config.data = { is_random_kyc_questions: var1 };
						config.method = 'patch';
					}
					else if (type == 'editKYCQuestionsIsActive') {
						_this.noxIsActiveKYCQuestions = var1;
						_this.$set(_this.$store.state.noxSystemSettings, 'is_active_kyc_questions', var1);
						config.url = '/v2/admin/account/kyc/questions/is_active_kyc_questions';
						config.data = { is_active_kyc_questions: var1 };
						config.method = 'patch';
					}
					else if (['swapUpKYCQuestion', 'swapDownKYCQuestion'].includes(type)) {
						_this.noxKYCQuestionsSwap = (type == 'swapUpKYCQuestion' ? 'up' : 'down') + var1 + '.' + var2;
						config.url = '/v2/admin/account/kyc/questions/swap';
						config.data = { sorting_1: var3, sorting_2: var4 };
						config.method = 'patch';
					}
					else if (['swapUpKYCAnswer', 'swapDownKYCAnswer'].includes(type)) {
						_this.noxKYCAnswersSwap = (type == 'swapUpKYCAnswer' ? 'up' : 'down') + var1 + '.' + var2;
						config.url = '/v2/admin/account/kyc/answers/swap';
						config.data = { qid: var1, sorting_1: var3, sorting_2: var4 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getKYCSettings') {
								_this.$parent.$parent.goToTop(!_this.noxKYCLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.kyc_questions !== undefined) {
									_this.noxKYCQuestions = data.data.kyc_questions;
								}
								if (data.data.kyc_answers !== undefined) {
									_this.noxKYCAnswers = data.data.kyc_answers;
								}
							}
							else if (['swapUpKYCQuestion', 'swapDownKYCQuestion'].includes(type)) {
								_this.noxKYCQuestions = data.data;
							}
							else if (['swapUpKYCAnswer', 'swapDownKYCAnswer'].includes(type)) {
								_this.$set(_this.noxKYCAnswers, var1, data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
						_this.noxKYCQuestionsSwap = '';
						_this.noxKYCAnswersSwap = '';
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForStatus: function(id, index, event) {
				this.axios('editKYCQuestionStatus', id, index, Number(event.value));
			},
			toggleChangeForIsRandom: function(id, index, event) {
				this.axios('editKYCQuestionIsRandom', id, index, Number(event.value));
			},
			toggleChangeForIsRandomKYCQuestions: function(event) {
				this.axios('editKYCQuestionsIsRandom', Number(event.value));
			},
			toggleChangeForIsActiveKYCQuestions: function(event) {
				this.axios('editKYCQuestionsIsActive', Number(event.value));
			}
		},
		components: {
			NoxModalKYCSettings: () => import('@/views/modal_components/NoxModalKYCSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_kyc_settings .nox_account_desktop_portlet_content_row.single {
		margin: 0;
	}
	.nox_account_desktop_kyc_settings_question tr th:nth-child(1),
	.nox_account_desktop_kyc_settings_question tr td:nth-child(1),
	.nox_account_desktop_kyc_settings_question tr th:nth-child(2),
	.nox_account_desktop_kyc_settings_question tr td:nth-child(2) {
		width: 47%;
	}
	.nox_account_desktop_kyc_settings_question tr td:nth-child(1),
	.nox_account_desktop_kyc_settings_question tr td:nth-child(2) {
		text-align: left;
	}
	.nox_account_desktop_kyc_settings_question tr th:nth-child(n + 3),
	.nox_account_desktop_kyc_settings_question tr td:nth-child(n + 3) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_kyc_settings_answers {
		margin: 20px 0 0 0;
	}
	.nox_account_desktop_kyc_settings_answers_title {
		margin: 0 0 5px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_kyc_settings_answers tr th:nth-child(1),
	.nox_account_desktop_kyc_settings_answers tr td:nth-child(1),
	.nox_account_desktop_kyc_settings_answers tr th:nth-child(n + 4),
	.nox_account_desktop_kyc_settings_answers tr td:nth-child(n + 4) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_kyc_settings_answers tr th:nth-child(2),
	.nox_account_desktop_kyc_settings_answers tr td:nth-child(2),
	.nox_account_desktop_kyc_settings_answers tr th:nth-child(3),
	.nox_account_desktop_kyc_settings_answers tr td:nth-child(3) {
		width: 47%;
	}
	.nox_account_desktop_kyc_settings_answers tr td:nth-child(2),
	.nox_account_desktop_kyc_settings_answers tr td:nth-child(3) {
		text-align: left;
	}
</style>
