<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_websites">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.13', 0)"></div>
					<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.13', 1)"></div>
				</div>
				<div class="nox_account_desktop_websites_data" v-if="noxWebsitesDataActiveCount || noxWebsitesDataStoppedCount || noxWebsitesDataDeletedCount">
					<div class="nox_account_desktop_page_content_title" v-html="$store.getters.getLanguageText('3.13', 2)"></div>
					<div class="nox_account_desktop_page_content_navigation">
						<div class="nox_account_desktop_page_content_navigation_filters">
							<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
							<div class="nox_account_desktop_page_content_navigation_filters_block col2">
								<div class="nox_account_desktop_page_content_navigation_filters_select"><v-select class="nox_select style-chooser" v-model.trim="noxWebsitesDataFilter1" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxWebsitesDataFilter1Options" @input="getWebsitesDataByFilter($event, 1)"></v-select></div>
							</div>
							<div class="nox_account_desktop_page_content_navigation_filters_block col2">
								<div class="nox_account_desktop_page_content_navigation_filters_select last"><v-select class="nox_select style-chooser" v-model.trim="noxWebsitesDataFilter2" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxWebsitesDataFilter2Options" @input="getWebsitesDataByFilter($event, 2)"></v-select></div>
							</div>
						</div>
					</div>
					<div class="nox_tabs">
						<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
						<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)" v-html="$store.getters.getLanguageText('3.13', 6)"></label>
						<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
						<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)" v-html="$store.getters.getLanguageText('3.13', 7)"></label>
						<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_3" name="nox_tab_menu" :checked="noxIsTabMenu3Checked">
						<label class="nox_tab_menu_label" for="nox_tab_menu_3" @click="selectTab(3)" v-html="$store.getters.getLanguageText('3.13', 8)"></label>
						<div class="nox_tab_block" id="nox_tab_block_1">
							<div class="nox_account_desktop_websites_active" v-if="noxWebsitesData.length">
								<div class="nox_account_desktop_portlet" v-for="(value, index) in noxWebsitesData" :key="index">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.13', 9, { id: value.id })"></div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_websites_data_row">
											<div class="nox_account_desktop_websites_data_col">
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 10)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 91), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">{{ value.id }}</div>
												</div>
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 11)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 92), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">{{ value.website_name }}</div>
												</div>
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 12)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 93), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">
														<span v-if="value.website_domain">{{ value.website_domain }}</span>
														<span v-else v-html="$store.getters.getLanguageText('3.13', 23)"></span>
													</div>
												</div>
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 13)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 94), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">
														<a :href="$store.state.noxMeBaseURL + '/websites/' + value.website_uid + '/'" target="_blank">{{ $store.state.noxMeBaseURL }}/websites/{{ value.website_uid }}/</a>
													</div>
												</div>
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 14)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 95), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">
														<a :href="'/editor/website/' + value.website_uid + '/'">{{ $store.state.noxMeBaseURL }}/editor/website/{{ value.website_uid }}/</a>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_websites_data_col">
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 15)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 96), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">{{ new Date(value.website_start * 1000).toLocaleString($store.state.noxLanguage) }}</div>
												</div>
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 16)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 97), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">{{ new Date(value.website_finish * 1000).toLocaleString($store.state.noxLanguage) }}</div>
												</div>
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 17)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 98), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">{{ new Date(value.updated_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
												</div>
												<div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 20)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 101), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value">
														<span class="margin_right_10" :class="{ red: ((value.website_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)) < 14 }" v-if="value.website_finish > $parent.$parent.getTimeSeconds()">
															<span v-if="((value.website_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)) > 1" v-html="$store.getters.getLanguageText('1.7.2', 15, { count: Math.floor((value.website_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)), options: [$parent.$parent.getDeclension(Math.floor((value.website_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)))] })"></span>
															<span v-else-if="((value.website_finish - $parent.$parent.getTimeSeconds()) / (60 * 60)) > 1" v-html="$store.getters.getLanguageText('1.7.2', 16, { count: Math.floor((value.website_finish - $parent.$parent.getTimeSeconds()) / (60 * 60)), options: [$parent.$parent.getDeclension(Math.floor((value.website_finish - $parent.$parent.getTimeSeconds()) / (60 * 60)))] })"></span>
															<span v-else v-html="$store.getters.getLanguageText('1.7.2', 17)"></span>
														</span>
														<span class="margin_right_10 red" v-else v-html="$store.getters.getLanguageText('1.7.2', 15, { count: Math.floor((value.website_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)), options: [$parent.$parent.getDeclension(Math.floor((value.website_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)))] })"></span>
														<button type="button" class="nox_button nox_is_desktop blue" @click="$modal.show('NoxModalWebsites', { type: 'extendWebsite', index: index, id: value.id })" v-html="$store.getters.getLanguageText('1.1', 36)"></button>
														<button type="button" class="nox_button nox_is_mobile blue" @click="$modal.show('NoxModalWebsites', { type: 'extendWebsite', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
													</div>
												</div>
												<!--div class="nox_account_desktop_websites_data_block">
													<div class="nox_account_desktop_websites_data_label">
														<span v-html="$store.getters.getLanguageText('3.13', 21)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 102), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_websites_data_value"></div>
												</div-->
											</div>
										</div>
									</div>
									<div class="nox_account_desktop_portlet_buttons">
										<button type="button" class="nox_button big red" @click="$modal.show('NoxModalWebsites', { type: 'deleteWebsite', index: index, id: value.id, status: value.status })"><font-awesome-icon :icon="['fas', 'trash']" /><span v-html="$store.getters.getLanguageText('1.1', 103)"></span></button>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet" v-else>
								<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.13', 3)"></div>
							</div>
						</div>
						<div class="nox_tab_block" id="nox_tab_block_2">
							<div class="nox_account_desktop_websites_stopped" v-if="noxWebsitesData.length">
								<div class="nox_account_desktop_portlet" v-for="(value, index) in noxWebsitesData" :key="index">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.13', 9, { id: value.id })"></div>
									<div class="nox_account_desktop_portlet_content"></div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet" v-else>
								<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.13', 4)"></div>
							</div>
						</div>
						<div class="nox_tab_block" id="nox_tab_block_3">
							<div class="nox_account_desktop_websites_deleted" v-if="noxWebsitesData.length">
								<div class="nox_account_desktop_portlet" v-for="(value, index) in noxWebsitesData" :key="index">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.13', 9, { id: value.id })"></div>
									<div class="nox_account_desktop_portlet_content"></div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet" v-else>
								<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.13', 5)"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
			<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }" v-if="noxWebsitesDataType == 1 || noxWebsitesDataLimitOffset">
				<button type="button" class="nox_button big" @click="axios('getWebsitesData')" v-if="noxWebsitesDataLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show('NoxModalWebsites', { type: 'addWebsite' })" v-if="noxWebsitesDataType == 1 && (noxWebsitesDataActiveCount + noxWebsitesDataStoppedCount) < Number($store.state.noxSystemSettings.website_max_count)"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 102)"></span></button>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalWebsites"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxWebsitesData: [],
			noxWebsitesDataType: 1,
			noxWebsitesDataFilter1: '',
			noxWebsitesDataFilter2: '',
			noxWebsitesDataFilter1Options: [],
			noxWebsitesDataFilter2Options: [],
			noxWebsitesDataLimitOffset: 0,
			noxWebsitesDataLimitCount: 20,
			noxWebsitesDataActiveCount: 0,
			noxWebsitesDataStoppedCount: 0,
			noxWebsitesDataDeletedCount: 0,
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsTabMenu3Checked: false,
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
				if (to.path == '/account/websites' && !Object.keys(to.query).length) {
					this.getWebsitesData();
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.noxWebsitesDataFilter1 = this.$parent.$parent.getSelectOptions(11)[0];
				this.noxWebsitesDataFilter2 = this.$parent.$parent.getSelectOptions(10)[1];
				this.noxWebsitesDataFilter1Options = this.$parent.$parent.getSelectOptions(11);
				this.noxWebsitesDataFilter2Options = this.$parent.$parent.getSelectOptions(10);
				this.getWebsitesData();
			},
			getWebsitesData: function() {
				this.$parent.$parent.goToTop(true);
				this.noxWebsitesData = [];
				this.noxWebsitesDataLimitOffset = 0;
				this.axios('getWebsitesData');
			},
			getWebsitesDataByFilter: function(event, type) {
				if (event !== null && event.code !== null) {
					if (type == 1) {
						this.noxWebsitesDataFilter2 = this.$parent.$parent.getSelectOptions(10)[1];
					}
					this.getWebsitesData();
				}
			},
			initTabs: function(tab) {
				this.noxWebsitesDataType = 1;
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				this.noxIsTabMenu3Checked = false;
				if      (tab == 2) { this.noxIsTabMenu2Checked = true; this.noxWebsitesDataType = 2; }
				else if (tab == 3) { this.noxIsTabMenu3Checked = true; this.noxWebsitesDataType = 3; }
				else               { this.noxIsTabMenu1Checked = true; }
			},
			selectTab: function(tab) {
				if (this.noxWebsitesDataType !== tab) {
					this.noxWebsitesDataType = tab;
					this.$router.push({ path: '/account/websites', query: { tab: tab }});
					this.noxWebsitesDataFilter1 = this.$parent.$parent.getSelectOptions(11)[0];
					this.noxWebsitesDataFilter2 = this.$parent.$parent.getSelectOptions(10)[1];
					this.noxWebsitesDataFilter1Options = this.$parent.$parent.getSelectOptions(11);
					this.noxWebsitesDataFilter2Options = this.$parent.$parent.getSelectOptions(10);
					this.getWebsitesData();
				}
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getWebsitesData') {
						if (!_this.noxWebsitesDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPageContentLoading = true;
						config.url = '/v2/account/websites';
						config.params = { type: _this.noxWebsitesDataType, limit_offset: _this.noxWebsitesDataLimitOffset, limit_count: _this.noxWebsitesDataLimitCount, filter_type: _this.noxWebsitesDataFilter1.code, filter_sort: _this.noxWebsitesDataFilter2.code };
					}
					else if (type == 'editWebsiteIsExtend') {
						_this.noxWebsitesData[var1].is_extend = var3;
						config.url = '/v2/account/websites/is_extend';
						config.data = { id: var2, is_extend: var3 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getWebsitesData') {
								_this.$parent.$parent.goToTop(!_this.noxWebsitesDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.count_of_active !== undefined) {
									_this.noxWebsitesDataActiveCount = data.data.count_of_active;
								}
								if (data.data.count_of_stopped !== undefined) {
									_this.noxWebsitesDataStoppedCount = data.data.count_of_stopped;
								}
								if (data.data.count_of_deleted !== undefined) {
									_this.noxWebsitesDataDeletedCount = data.data.count_of_deleted;
								}
								if (data.data.websites_data !== undefined) {
									if (data.data.websites_data.data.length) {
										for (var i in data.data.websites_data.data) {
											_this.noxWebsitesData.push(data.data.websites_data.data[i]);
										}
									}
									_this.noxWebsitesDataLimitOffset = data.data.websites_data.count > _this.noxWebsitesDataLimitOffset + _this.noxWebsitesDataLimitCount ? _this.noxWebsitesDataLimitOffset + _this.noxWebsitesDataLimitCount : 0;
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPageContentLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(event, index, id) {
				this.axios('editWebsiteIsExtend', index, id, Number(event.value));
			}
		},
		components: {
			NoxModalWebsites: () => import('@/views/modal_components/NoxModalWebsites.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_websites_data_row {
		margin: 0 -7.5px;
		display: flex;
	}
	.nox_account_desktop_websites_data_col {
		float: left;
		padding: 0 7.5px;
		width: 100%;
	}
	.nox_account_desktop_websites_data_block {
		line-height: 26px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_websites_data_label {
		float: left;
		margin: 0 10px 0 0;
		text-align: right;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		min-width: 180px;
	}
	.nox_account_desktop_websites_data_value {
		padding: 4px 0;
		word-break: break-all;
		word-break: break-word;
		font-size: 12px;
		line-height: 18px;
		display: table;
		width: calc(100% - 190px);
	}
	.nox_account_desktop_websites_data_value a {
		word-break: break-all;
	}
	.nox_account_desktop_websites_data_value .green {
		color: #00FF00;
	}
	.nox_account_desktop_websites_data_value .red {
		color: #FFBBBB;
	}
	.nox_account_desktop_websites_data_value .nox_button {
		margin: -1px 5px 0 0;
	}
	.nox_account_desktop_websites_data_value .nox_button:last-child {
		margin: -1px 0 0 0;
	}
	.nox_account_desktop_websites_data_value .nox_button.nox_is_desktop {
		font-size: 12px;
	}
	.nox_account_desktop_websites_data_value .vue-js-switch {
		margin: -5px 0;
	}

	@media (max-width: 1200px) {
		.nox_account_desktop_websites_data_row {
			margin: 0;
			display: table;
			width: 100%;
		}
		.nox_account_desktop_websites_data_col {
			float: none;
			padding: 0;
		}
		.nox_account_desktop_websites_data_label {
			margin: 0 20px 0 0;
			min-width: auto;
			width: calc(50% - 10px);
		}
		.nox_account_desktop_websites_data_value {
			width: auto;
		}
	}
	@media (max-width: 600px) {
		.nox_account_desktop_websites_data_label {
			margin: 0 10px 0 0;
			text-align: left;
			width: auto;
		}
		.nox_account_desktop_websites_data_value {
			float: left;
		}
	}
</style>
