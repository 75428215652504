<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_auth password_recovery_reset">
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.3', 2)"></div>
					<form class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_row">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.3', 3)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="password" class="nox_input" id="password" name="password" v-model.trim="noxPassword" autocomplete="new-password">
								<div v-html="noxAlertPassword"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row last">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.3', 4)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="password" class="nox_input" id="repassword" name="repassword" v-model.trim="noxRePassword" autocomplete="new-password">
								<div v-html="noxAlertRePassword"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_alert" v-html="noxAlert"></div>
						<div class="nox_auth_block_content_form_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_auth_block_content_form_buttons" :class="{ hide: noxIsLoading }">
							<button type="submit" class="nox_button common green" @click="submit($event, 'passwordRecoveryReset')" v-html="$store.getters.getLanguageText('1.1', 34)"></button>
						</div>
						<div class="nox_auth_block_content_form_footer">
							<router-link to="/">[<span v-html="$store.getters.getLanguageText('1.1', 31)"></span>]</router-link>
						</div>
					</form>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertPassword: '',
			noxAlertRePassword: '',
			noxPassword: '',
			noxRePassword: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertPassword = '';
				this.noxAlertRePassword = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 30); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 31); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 17); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 18); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 19); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 20); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event, type) {
				event.preventDefault(); this.axios(type);
			},
			axios: function(type) {

				var _this = this;
				if (_this.$store.state.noxOAuthTokenForPassword) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxOAuthTokenForPassword }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'passwordRecoveryReset') {
						if (!_this.noxPassword) {
							_this.noxAlertPassword = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexPassword.test(_this.noxPassword) || _this.noxPassword.search(_this.$store.state.noxRegexSearchENRULetters) < 0) {
							_this.noxAlertPassword = _this.getError(4);
						}
						if (!_this.noxRePassword) {
							_this.noxAlertRePassword = _this.getError(5);
						}
						if (_this.noxPassword && _this.noxRePassword && _this.noxPassword != _this.noxRePassword) {
							_this.noxAlertRePassword = _this.getError(6);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/oauth/password/recovery/reset';
							config.data = { key: _this.$route.query.key, password: _this.noxPassword };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'passwordRecoveryReset') {
									_this.$router.push({ path: '/password/recovery/reset/success' });
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'passwordRecoveryReset') {
										if      (data.response.data.error == 'KEY_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'PASSWORD_IS_EMPTY') { _this.noxAlertPassword = _this.getError(3); }
										else if (data.response.data.error == 'PASSWORD_NOT_VALID') { _this.noxAlertPassword = _this.getError(4); }
									}
								}
								else if (data.response.status == 404) {
									if (type == 'passwordRecoveryReset') {
										if (data.response.data.error == 'KEY_NOT_FOUND') { _this.noxAlert = _this.getError(2); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
			}
		},
		components: {
			NoxAuthHeader,
			NoxAuthFooter
		}
	}
</script>
