<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_delivery">
				<div class="nox_account_desktop_delivery_navigation">
					<div class="nox_account_desktop_delivery_navigation_filters">
						<div class="nox_account_desktop_delivery_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
						<div class="nox_account_desktop_delivery_navigation_filters_value" :class="{ active: noxDeliveryFilterType == 0 }"><div @click="filterDeliveryData(1, 0, 0)">Обработка</div></div>
						<div class="nox_account_desktop_delivery_navigation_filters_value" :class="{ active: noxDeliveryFilterType == 1 }"><div @click="filterDeliveryData(2, 1, 0)">Отправка</div></div>
						<div class="nox_account_desktop_delivery_navigation_filters_value" :class="{ active: noxDeliveryFilterType == 2 }"><div @click="filterDeliveryData(3, 2, 0)">Доставлено</div></div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_content">
						<div v-if="noxDeliveryData.length">
							<table class="nox_table">
								<thead>
									<tr>
										<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 13)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 24)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 25)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 26)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
										<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
										<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(value, index) in noxDeliveryData" :key="index">
										<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 13) + ':'">{{ value.user_id }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 24) + ':'">{{ value.email }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 25) + ':'">{{ ((value.phone ? '+' : '') + value.phone) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 26) + ':'">{{ $store.getters.getLanguageText('3.5.5', (value.gift_id - 1)) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'">{{ (value.status == 1 ? 'Отправка' : (value.status == 2 ? 'Доставлено' : 'Обработка')) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
										<td :data-label="$store.getters.getLanguageText('1.7.1', 28) + ':'"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalTreeGifts', { type: 'editGift', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-else>Не найдено ни одной заявки на получение подарка.</div>
					</div>
					<div v-if="noxDeliveryLimitOffset">
						<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
							<button type="button" class="nox_button big" @click="axios('getDeliveryData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalTreeGifts"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxDeliveryData: [],
			noxDeliveryFilterType: 0,
			noxDeliveryFilterSort: 0,
			noxDeliveryLimitOffset: 0,
			noxDeliveryLimitCount: 10,
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				if (to.path == '/account/delivery' && !Object.keys(to.query).length) {
					this.filterDeliveryData(0, 0, 0);
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || [100,479].includes(this.$store.state.noxAccountData.id)) {
					this.axios('getDeliveryData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initTabs: function(tab) {
				if (tab !== undefined && [1,2,3].includes(Number(tab))) {
					this.noxDeliveryFilterType = Number(tab) - 1;
				}
			},
			filterDeliveryData: function(tab, type, sort) {
				if (tab && tab !== Number(this.$route.query.tab)) {
					this.$router.push({ path: '/account/delivery', query: { tab: tab }});
				}
				this.$parent.$parent.goToTop(true);
				this.noxDeliveryData = [];
				this.noxDeliveryFilterType = type;
				this.noxDeliveryFilterSort = sort;
				this.noxDeliveryLimitOffset = 0;
				this.axios('getDeliveryData');
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getDeliveryData') {
						if (!_this.noxDeliveryLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPortletLoading = true;
						config.url = '/v2/admin/account/gifts';
						config.params = { limitOffset: _this.noxDeliveryLimitOffset, limitCount: _this.noxDeliveryLimitCount, filterType: _this.noxDeliveryFilterType, filterSort: _this.noxDeliveryFilterSort };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getDeliveryData') {
								_this.$parent.$parent.goToTop(!_this.noxDeliveryLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.data.length) {
									for (var i in data.data.data) {
										_this.noxDeliveryData.push(data.data.data[i]);
									}
								}
								_this.noxDeliveryLimitOffset = data.data.count > _this.noxDeliveryLimitOffset + _this.noxDeliveryLimitCount ? _this.noxDeliveryLimitOffset + _this.noxDeliveryLimitCount : 0;
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalTreeGifts: () => import('@/views/modal_components/NoxModalTreeGifts.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_delivery_navigation {
		margin: 0 0 15px 0;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_delivery_navigation_filters {
		position: relative;
		margin: 2px 0 0 0;
		padding: 0 0 0 105px;
	}
	.nox_account_desktop_delivery_navigation_filters:first-child {
		margin: 0;
	}
	.nox_account_desktop_delivery_navigation_filters_label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px;
		border-radius: 15px 2px 2px 15px;
		background: #333333B3;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 105px;
		height: 30px;
	}
	.nox_account_desktop_delivery_navigation_filters_value {
		float: left;
		padding: 0 0 0 2px;
		width: 33.33333%;
	}
	.nox_account_desktop_delivery_navigation_filters_value div {
		border-radius: 2px;
		background: #111111B3;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		overflow: hidden;
		text-align: center;
		text-transform: lowercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 100%;
		height: 30px;
	}
	.nox_account_desktop_delivery_navigation_filters_value.active div,
	.nox_account_desktop_delivery_navigation_filters_value:hover div {
		background: #111111CC;
	}
	.nox_account_desktop_delivery_navigation_filters_value:last-child div {
		border-radius: 2px 15px 15px 2px;
	}
	.nox_account_desktop_delivery tr th:nth-child(1),
	.nox_account_desktop_delivery tr td:nth-child(1),
	.nox_account_desktop_delivery tr th:nth-child(2),
	.nox_account_desktop_delivery tr td:nth-child(2),
	.nox_account_desktop_delivery tr th:nth-child(8),
	.nox_account_desktop_delivery tr td:nth-child(8) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_delivery tr th:nth-child(2),
	.nox_account_desktop_delivery tr td:nth-child(2) {
		min-width: 71px;
	}
	.nox_account_desktop_delivery tr th:nth-child(n + 3),
	.nox_account_desktop_delivery tr td:nth-child(n + 3) {
		width: 19%;
	}

	@media (max-width: 1000px) {
		.nox_account_desktop_delivery_navigation {
			margin: 0 0 10px 0;
		}
	}
	@media (max-width: 450px) {
		.nox_account_desktop_delivery_navigation_filters,
		.nox_account_desktop_delivery_navigation_filters:first-child {
			margin: 0 0 10px 0;
			padding: 0;
		}
		.nox_account_desktop_delivery_navigation_filters:last-child {
			margin: 0;
		}
		.nox_account_desktop_delivery_navigation_filters_label {
			position: relative;
			border-radius: 15px 15px 2px 2px;
			width: 100%;
		}
		.nox_account_desktop_delivery_navigation_filters_value {
			padding: 2px 0 0 0;
			width: 100%;
		}
		.nox_account_desktop_delivery_navigation_filters_value:last-child div {
			border-radius: 2px 2px 15px 15px;
		}
	}
</style>
