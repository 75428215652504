<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Настройки ботов</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_bots_settings">
						<table class="nox_table" v-if="$store.state.noxBotsSettings.length">
							<thead>
								<tr>
									<th>№</th>
									<th>Имя</th>
									<th>Ключ</th>
									<th>Значение</th>
									<th>Тип</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxBotsSettings" :key="index">
									<td data-label="№:">{{ value.sort }}</td>
									<td data-label="Имя:">{{ value.name }}</td>
									<td data-label="Ключ:">{{ value.key }}</td>
									<td data-label="Значение:">{{ [17,26,33,46].includes(value.id) && $parent.$parent.isInteger(value.value) ? value.value.toFixed(1) : value.value }}</td>
									<td data-label="Тип:"><span class="green" v-if="value.type">custom</span><span v-else>global</span></td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalBotsSettings', { type: 'editSettings', index: index, id: value.id, key: value.key })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalBotsSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios();
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/admin/account/bots/settings',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.noxIsLoading = true;
					_this.$store.state.noxIsAccount = true;

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.$parent.goToTop(true);
							_this.$parent.$parent.checkAppVersion(data.headers);
							_this.$store.state.noxBotsSettings = data.data;
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalBotsSettings: () => import('@/views/modal_components/NoxModalBotsSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_bots_settings tr th:nth-child(1),
	.nox_account_desktop_bots_settings tr td:nth-child(1),
	.nox_account_desktop_bots_settings tr th:nth-child(5),
	.nox_account_desktop_bots_settings tr td:nth-child(5),
	.nox_account_desktop_bots_settings tr th:nth-child(6),
	.nox_account_desktop_bots_settings tr td:nth-child(6) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_bots_settings tr th:nth-child(n + 2),
	.nox_account_desktop_bots_settings tr td:nth-child(n + 2) {
		width: 32.33333%;
	}
	.nox_account_desktop_bots_settings tr th:nth-child(2),
	.nox_account_desktop_bots_settings tr td:nth-child(2),
	.nox_account_desktop_bots_settings tr th:nth-child(3),
	.nox_account_desktop_bots_settings tr td:nth-child(3),
	.nox_account_desktop_bots_settings tr th:nth-child(4),
	.nox_account_desktop_bots_settings tr td:nth-child(4) {
		word-break: break-all;
		word-break: break-word;
	}
</style>
