<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Zigzag trading</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_zigzag_trading">
						<div class="nox_account_desktop_portlet_content_accordion" v-if="noxZigzagTradingData.length">
							<div class="nox_account_desktop_portlet_content_accordion_block" :class="{ active: (noxToggleAccordionIndex == index) }" :id="'nox_account_desktop_portlet_content_accordion_block_' + index" v-for="(value, index) in noxZigzagTradingData" :key="index">
								<div class="nox_account_desktop_portlet_content_accordion_block_item">
									<div class="nox_account_desktop_portlet_content_accordion_block_item_head" @click="toggleAccordion(index)">
										<div class="nox_account_desktop_portlet_content_accordion_block_item_head_title"><span>{{ (index + 1) }}. Символ: {{ value.zz_symbol.toUpperCase() }} ( updated_at = {{ (new Date((value.updated_at ? value.updated_at : value.created_at) * 1000).toLocaleString($store.state.noxLanguage)) }} )</span><font-awesome-icon :icon="['fas', ((Number($store.state.noxSystemSettings.time) < (Number(value.updated_at ? value.updated_at : value.created_at) + (7 * 24 * 60 * 60))) ? 'check' : 'times')]" :class="{ active: (Number($store.state.noxSystemSettings.time) < (Number(value.updated_at ? value.updated_at : value.created_at) + (7 * 24 * 60 * 60))) }" /></div>
										<div class="nox_account_desktop_portlet_content_accordion_block_item_head_toggle">
											<font-awesome-icon :icon="['fas', 'angle-up']" v-if="noxIsToggleAccordion && noxToggleAccordionIndex == index" />
											<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
										</div>
									</div>
									<div class="nox_account_desktop_portlet_content_accordion_block_item_body" :class="{ hide: (!noxIsToggleAccordion || (noxIsToggleAccordion && noxToggleAccordionIndex !== index)) }">
										<div class="nox_account_desktop_portlet_content_accordion_block_item_body_value"><pre>{{ value }}</pre></div>
										<div class="nox_account_desktop_portlet_content_accordion_block_item_body_buttons">
											<button type="button" class="nox_button common red" @click="$modal.show('NoxModalZigzagTrading', { type: 'deleteZZSymbol', index: index, zz_symbol: value.zz_symbol })" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="noxZigzagTradingDataLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
						<button type="button" class="nox_button big" @click="axios('getZigzagTradingData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalZigzagTrading"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxZigzagTradingData: [],
			noxZigzagTradingDataLimitOffset: 0,
			noxZigzagTradingDataLimitCount: 100,
			noxToggleAccordionIndex: -1,
			noxIsToggleAccordion: false,
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getZigzagTradingData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getZigzagTradingData') {
						if (!_this.noxZigzagTradingDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPortletLoading = true;
						config.url = '/v2/admin/account/zigzag/trading';
						config.params = { limit_offset: _this.noxZigzagTradingDataLimitOffset, limit_count: _this.noxZigzagTradingDataLimitCount };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getZigzagTradingData') {
								_this.$parent.$parent.goToTop(!_this.noxZigzagTradingDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.data.length) {
									for (var i in data.data.data) {
										_this.noxZigzagTradingData.push(data.data.data[i]);
									}
								}
								_this.noxZigzagTradingDataLimitOffset = data.data.count > _this.noxZigzagTradingDataLimitOffset + _this.noxZigzagTradingDataLimitCount ? _this.noxZigzagTradingDataLimitOffset + _this.noxZigzagTradingDataLimitCount : 0;
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleAccordion: function(index) {
				this.noxIsToggleAccordion = (this.noxToggleAccordionIndex == index ? !this.noxIsToggleAccordion : true);
				this.noxToggleAccordionIndex = (this.noxIsToggleAccordion ? index : -1);
				this.$parent.$parent.goToAccordionBlock(index);
			}
		},
		components: {
			NoxModalZigzagTrading: () => import('@/views/modal_components/NoxModalZigzagTrading.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>
