<template>
	<div class="nox_auth_block_footer">
		<div class="nox_auth_block_footer_menu">
			<a :href="$store.state.noxBaseURL" v-html="$store.getters.getLanguageText('2.5.1', 23)"></a>
			<a href="https://forum.nox.bz/topic/21-faq-otvety-na-chasto-zadavaemye-voprosy-pro-nox" target="_blank" v-html="$store.getters.getLanguageText('2.5.1', 12)"></a>
			<router-link to="/info" v-html="$store.getters.getLanguageText('2.5.1', 1)"></router-link>
			<router-link to="/info/stats" v-html="$store.getters.getLanguageText('2.5.1', 3)"></router-link>
		</div>
		<div class="nox_auth_block_footer_language">
			<div class="nox_auth_block_footer_language_name" :class="{ hide: !$store.state.noxIsLanguageRU }"><img src="@/assets/images/loading.gif"></div>
			<div class="nox_auth_block_footer_language_name text" :class="{ hide: $store.state.noxIsLanguageRU, active: $store.state.noxLanguage == 'ru' }" @click="$parent.$parent.selectLanguage('ru')" v-html="$store.getters.getLanguageText('2', 1, { options: [0] })"></div>
			<div class="nox_auth_block_footer_language_name" :class="{ hide: !$store.state.noxIsLanguageEN }"><img src="@/assets/images/loading.gif"></div>
			<div class="nox_auth_block_footer_language_name text" :class="{ hide: $store.state.noxIsLanguageEN, active: $store.state.noxLanguage == 'en' }" @click="$parent.$parent.selectLanguage('en')" v-html="$store.getters.getLanguageText('2', 1, { options: [1] })"></div>
		</div>
		<div class="nox_auth_block_footer_copyright" is="NoxCommonCopyright"></div>
		<div class="nox_auth_block_footer_social_networks" is="NoxCommonSocialNetworks"></div>
		<div class="nox_auth_block_footer_mobile_applications" is="NoxCommonMobileApplications"></div>
	</div>
</template>

<script>
	export default {
		components: {
			NoxCommonCopyright: () => import('@/views/common_components/NoxCommonCopyright.vue'),
			NoxCommonSocialNetworks: () => import('@/views/common_components/NoxCommonSocialNetworks.vue'),
			NoxCommonMobileApplications: () => import('@/views/common_components/NoxCommonMobileApplications.vue')
		}
	}
</script>
