<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_landings">
				<div class="nox_account_desktop_page_content_intro">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.20', 0)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_portlet_content_img_200">
								<img src="@/assets/images/landing.png">
								<div v-html="$store.getters.getLanguageText('3.20', 1)"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_title" v-html="$store.getters.getLanguageText('3.20', 2)"></div>
				<div class="nox_account_desktop_page_content_navigation">
					<div class="nox_account_desktop_page_content_navigation_filters">
						<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
						<div class="nox_account_desktop_page_content_navigation_filters_block col2">
							<div class="nox_account_desktop_page_content_navigation_filters_select"><v-select class="nox_select style-chooser" v-model.trim="noxLandingsDataFilter1" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxLandingsDataFilter1Options" @input="getLandingsDataByFilter($event, 1)"></v-select></div>
						</div>
						<div class="nox_account_desktop_page_content_navigation_filters_block col2">
							<div class="nox_account_desktop_page_content_navigation_filters_select last"><v-select class="nox_select style-chooser" v-model.trim="noxLandingsDataFilter2" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxLandingsDataFilter2Options" @input="getLandingsDataByFilter($event, 2)"></v-select></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_data" v-if="noxLandingsData.length">
					<div class="nox_account_desktop_landings_data">
						<div class="nox_account_desktop_portlet" v-for="(value, index) in noxLandingsData" :key="index">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.20', 4, { id: value.id })"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_landings_data_row">
									<div class="nox_account_desktop_landings_data_col">
										<div class="nox_account_desktop_landings_data_block">
											<div class="nox_account_desktop_landings_data_label">
												<span v-html="$store.getters.getLanguageText('3.20', 5)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 232), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_landings_data_value">{{ value.id }}</div>
										</div>
										<div class="nox_account_desktop_landings_data_block">
											<div class="nox_account_desktop_landings_data_label">
												<span v-html="$store.getters.getLanguageText('3.20', 6)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 233), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_landings_data_value">
												<span class="margin_right_10">{{ value.name }}</span>
												<button type="button" class="nox_button nox_is_icon blue" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [2] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$modal.show('NoxModalLandings', { type: 'editLanding', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
											</div>
										</div>
									</div>
									<div class="nox_account_desktop_landings_data_col">
										<div class="nox_account_desktop_landings_data_block">
											<div class="nox_account_desktop_landings_data_label">
												<span v-html="$store.getters.getLanguageText('3.20', 7)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 234), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_landings_data_value">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
										</div>
										<div class="nox_account_desktop_landings_data_block">
											<div class="nox_account_desktop_landings_data_label">
												<span v-html="$store.getters.getLanguageText('3.20', 8)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 235), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_landings_data_value">{{ new Date((value.updated_at ? value.updated_at : value.created_at) * 1000).toLocaleString($store.state.noxLanguage) }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_buttons">
								<button type="button" class="nox_button big red" @click="$modal.show('NoxModalLandings', { type: 'deleteLanding', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'trash']" /><span v-html="$store.getters.getLanguageText('1.1', 175)"></span></button>
								<router-link :to="'/editor/landing/' + value.id" class="nox_button big orange"><font-awesome-icon :icon="['fas', 'pencil-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 174)"></span></router-link>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_landings_preload">
						<div v-if="noxLandingsElementsImages.length">
							<div v-for="(value, index) in noxLandingsElementsImages" :key="index">
								<img :src="value.image_1">
								<img :src="value.image_2">
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-else>
					<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.20', 3)"></div>
				</div>
			</div>
			<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
			<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
				<button type="button" class="nox_button big" @click="axios('getLandingsData')" v-if="noxLandingsDataLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show('NoxModalLandings', { type: 'addLanding' })"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 173)"></span></button>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalLandings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxLandingsData: [],
			noxLandingsDataFilter1: '',
			noxLandingsDataFilter2: '',
			noxLandingsDataFilter1Options: [],
			noxLandingsDataFilter2Options: [],
			noxLandingsDataLimitOffset: 0,
			noxLandingsDataLimitCount: 20,
			noxLandingsElementsImages: [],
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.noxLandingsDataFilter1 = this.$parent.$parent.getSelectOptions(17)[0];
				this.noxLandingsDataFilter2 = this.$parent.$parent.getSelectOptions(16)[1];
				this.noxLandingsDataFilter1Options = this.$parent.$parent.getSelectOptions(17);
				this.noxLandingsDataFilter2Options = this.$parent.$parent.getSelectOptions(16);
				this.getLandingsData();
			},
			getLandingsData: function() {
				this.$parent.$parent.goToTop(true);
				this.noxLandingsData = [];
				this.noxLandingsDataLimitOffset = 0;
				this.axios('getLandingsData');
			},
			getLandingsDataByFilter: function(event, type) {
				if (event !== null && event.code !== null) {
					if (type == 1) {
						this.noxLandingsDataFilter2 = this.$parent.$parent.getSelectOptions(16)[1];
					}
					this.getLandingsData();
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getLandingsData') {
						if (!_this.noxLandingsDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPageContentLoading = true;
						config.url = '/v2/account/landings';
						config.params = { limit_offset: _this.noxLandingsDataLimitOffset, limit_count: _this.noxLandingsDataLimitCount, filter_type: _this.noxLandingsDataFilter1.code, filter_sort: _this.noxLandingsDataFilter2.code };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getLandingsData') {
								_this.$parent.$parent.goToTop(!_this.noxLandingsDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.landings_data !== undefined) {
									if (data.data.landings_data.data.length) {
										for (var i in data.data.landings_data.data) {
											_this.noxLandingsData.push(data.data.landings_data.data[i]);
										}
									}
									_this.noxLandingsDataLimitOffset = data.data.landings_data.count > _this.noxLandingsDataLimitOffset + _this.noxLandingsDataLimitCount ? _this.noxLandingsDataLimitOffset + _this.noxLandingsDataLimitCount : 0;
								}
								if (data.data.landings_elements_images !== undefined) {
									if (data.data.landings_elements_images.length) {
										for (var j in data.data.landings_elements_images) {
											_this.noxLandingsElementsImages.push(data.data.landings_elements_images[j]);
										}
									}
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPageContentLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalLandings: () => import('@/views/modal_components/NoxModalLandings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_landings_data_row {
		margin: 0 -7.5px;
		display: flex;
	}
	.nox_account_desktop_landings_data_col {
		float: left;
		padding: 0 7.5px;
		width: 100%;
	}
	.nox_account_desktop_landings_data_block {
		line-height: 26px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_landings_data_label {
		float: left;
		margin: 0 10px 0 0;
		text-align: right;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		min-width: 180px;
	}
	.nox_account_desktop_landings_data_value {
		padding: 4px 0;
		word-break: break-all;
		word-break: break-word;
		font-size: 12px;
		line-height: 18px;
		display: table;
		width: calc(100% - 190px);
	}
	.nox_account_desktop_landings_data_value a {
		word-break: break-all;
	}
	.nox_account_desktop_landings_preload {
		position: absolute;
		display: none;
		width: 0;
		height: 0;
	}

	@media (max-width: 1200px) {
		.nox_account_desktop_landings_data_row {
			margin: 0;
			display: table;
			width: 100%;
		}
		.nox_account_desktop_landings_data_col {
			float: none;
			padding: 0;
		}
		.nox_account_desktop_landings_data_label {
			margin: 0 20px 0 0;
			min-width: auto;
			width: calc(50% - 10px);
		}
		.nox_account_desktop_landings_data_value {
			width: auto;
		}
	}
	@media (max-width: 600px) {
		.nox_account_desktop_landings_data_label {
			margin: 0 10px 0 0;
			text-align: left;
			width: auto;
		}
		.nox_account_desktop_landings_data_value {
			float: left;
		}
	}
</style>
