<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.7', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_portlet_content_sdata">
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 1)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 20), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_content">{{ $store.state.noxAccountData.id }}</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 2)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 21), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings1.email_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings1.email_new" :placeholder="$store.getters.getLanguageText('3.7', 2).toLowerCase()" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings1.email_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings1.email_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings1('cancel', 'email')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings1('cancel', 'email')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings1', 'email')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings1', 'email')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings1.email_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value">{{ ($store.state.noxAccountData.email_new ? $store.state.noxAccountData.email : noxSettings1.email_old) }}<font-awesome-icon class="nox_tooltip_icon orange" :icon="['fas', 'exclamation-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 68, { email: $store.state.noxAccountData.email_new }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="$store.state.noxAccountData.email_new" /></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings1('edit', 'email')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings1('edit', 'email')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 3)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 22), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings1.username_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings1.username_new" :placeholder="$store.getters.getLanguageText('3.7', 3).toLowerCase()" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings1.username_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings1.username_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings1('cancel', 'username')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings1('cancel', 'username')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings1', 'username')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings1', 'username')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings1.username_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings1.username_old">{{ noxSettings1.username_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 24)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings1('edit', 'username')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings1('edit', 'username')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 4)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 23), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings1.phone_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings1.phone_new" :placeholder="$store.getters.getLanguageText('3.7', 4).toLowerCase()" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings1.phone_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings1.phone_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings1('cancel', 'phone')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings1('cancel', 'phone')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings1', 'phone')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings1', 'phone')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings1.phone_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings1.phone_old">{{ noxSettings1.phone_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 24)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings1('edit', 'phone')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings1('edit', 'phone')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 5)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 24), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings1.password_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="password" class="nox_input" v-model.trim="noxSettings1.password_old" :placeholder="$store.getters.getLanguageText('3.7', 30).toLowerCase()" autocomplete="new-password"><br>
											<input type="password" class="nox_input" v-model.trim="noxSettings1.password_new" :placeholder="$store.getters.getLanguageText('3.7', 31).toLowerCase()" autocomplete="new-password"><br>
											<input type="password" class="nox_input" v-model.trim="noxSettings1.password_new_repeat" :placeholder="$store.getters.getLanguageText('3.7', 32).toLowerCase()" autocomplete="new-password">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings1.password_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings1.password_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings1('cancel', 'password')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings1('cancel', 'password')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings1', 'password')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings1', 'password')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings1.password_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value">&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings1('edit', 'password')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings1('edit', 'password')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 7)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 25), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_content">
									<span class="nox_value" v-if="$store.state.noxAccountData.country">{{ $store.state.noxAccountData.country }} ({{ noxCountries[$store.state.noxAccountData.country] }})</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 25)"></span>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 8)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 26), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_content">
									<span class="nox_value" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }">{{ $parent.$parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox, 2) }}</span>
									<button type="button" class="nox_button nox_is_desktop last blue" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }" @click="$modal.show('NoxModalBalance', { type: 'addMainBalance' })" v-html="$store.getters.getLanguageText('1.1', 101)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }" @click="$modal.show('NoxModalBalance', { type: 'addMainBalance' })"><font-awesome-icon :icon="['fas', ($store.state.noxAccountData.balance_nox < 0 ? 'exclamation-triangle' : 'pencil-alt')]" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 33)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 105), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_content">
									<span class="nox_value">{{ $parent.$parent.getNumberFormatForDollar($store.state.noxAccountData.balance_of_bonuses, 2) }}</span>
									<span class="nox_account_desktop_portlet_content_value_buttons">
										<button type="button" class="nox_button nox_is_desktop blue" @click="$modal.show('NoxModalBalance', { type: 'transferBonusBalance' })" v-html="$store.getters.getLanguageText('1.1', 106)"></button>
										<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show('NoxModalBalance', { type: 'payoutBonusBalance' })" v-html="$store.getters.getLanguageText('1.1', 65)"></button>
										<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show('NoxModalBalance', { type: 'openBonusBalance', operation: 0 })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
									</span>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row" v-if="$store.state.noxAccountData.balance_nox_zp !== 0">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 34)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 116), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_content">
									<span class="nox_value">{{ $parent.$parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox_zp, 2) }}</span>
									<span class="nox_account_desktop_portlet_content_value_buttons">
										<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show('NoxModalBalance', { type: 'payoutNOXZPBalance' })" v-html="$store.getters.getLanguageText('1.1', 65)"></button>
										<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show('NoxModalBalance', { type: 'payoutNOXZPBalance' })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
									</span>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 9)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 27), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_content">
									<span class="nox_value">{{ $store.state.noxSystemSettings['package_name_x' + $store.state.noxAccountData.package] }}</span>
									<router-link to="/account/tariffs">
										<button type="button" class="nox_button nox_is_desktop last blue" v-if="$store.state.noxAccountData.package < 6" v-html="$store.getters.getLanguageText('1.1', 35)"></button>
										<button type="button" class="nox_button nox_is_mobile last blue" v-if="$store.state.noxAccountData.package < 6"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
									</router-link>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row" v-if="$store.state.noxAccountData.package">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 10)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 28), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_content">
									<span class="nox_value" :class="{ red: (($store.state.noxAccountData.package_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)) < 7 }" v-if="$store.state.noxAccountData.package_at > $store.state.noxSystemSettings.time">
										<span v-if="(($store.state.noxAccountData.package_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)) > 1" v-html="$store.getters.getLanguageText('3.7', 27, { count: Math.floor(($store.state.noxAccountData.package_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)), options: [$parent.$parent.getDeclension(Math.floor(($store.state.noxAccountData.package_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)))] })"></span>
										<span v-else-if="(($store.state.noxAccountData.package_at - $store.state.noxSystemSettings.time) / (60 * 60)) > 1" v-html="$store.getters.getLanguageText('3.7', 28, { count: Math.floor(($store.state.noxAccountData.package_at - $store.state.noxSystemSettings.time) / (60 * 60)), options: [$parent.$parent.getDeclension(Math.floor(($store.state.noxAccountData.package_at - $store.state.noxSystemSettings.time) / (60 * 60)))] })"></span>
										<span v-else v-html="$store.getters.getLanguageText('3.7', 29)"></span>
									</span>
									<b class="nox_value red uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 26)"></b>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalPackageExtend' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? '' : 'addMainBalance') })" v-html="$store.getters.getLanguageText('1.1', 36)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalPackageExtend' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? '' : 'addMainBalance') })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row" v-if="$store.state.noxAccountData.package">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 35)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 118, { count: $store.state.noxSystemSettings.subscription_period_m1, cost: (Math.round(($store.state.noxSystemSettings['subscription_cost_x' + $store.state.noxAccountData.package + '_m1'] * (1 + $store.state.noxSystemSettings.vat_value / 100)) * 100) / 100).toFixed(2) }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxSettings1.is_subscription_auto)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsSubscriptionAuto($event)"></toggle-button>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_content_alert" v-html="noxAlert1"></div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.7', 11)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_portlet_content_intro" v-html="$store.getters.getLanguageText('3.7', 12)"></div>
					<div class="nox_account_desktop_portlet_content_sdata">
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 13)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 29), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings2.forex4you_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings2.forex4you_new" :placeholder="$store.getters.getLanguageText('3.7', 13).toLowerCase()" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings2.forex4you_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings2.forex4you_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings2('cancel', 'forex4you')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings2('cancel', 'forex4you')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings2', 'forex4you')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings2', 'forex4you')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings2.forex4you_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings2.forex4you_old">{{ noxSettings2.forex4you_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 25)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings2('edit', 'forex4you')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings2('edit', 'forex4you')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<!--div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 14)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 30), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings2.forex_box_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings2.forex_box_new" :placeholder="$store.getters.getLanguageText('3.7', 14).toLowerCase()" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings2.forex_box_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings2.forex_box_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings2('cancel', 'forex_box')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings2('cancel', 'forex_box')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings2', 'forex_box')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings2', 'forex_box')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings2.forex_box_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings2.forex_box_old">{{ noxSettings2.forex_box_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 25)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings2('edit', 'forex_box')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings2('edit', 'forex_box')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div-->
					</div>
					<div class="nox_account_desktop_portlet_content_alert" v-html="noxAlert2"></div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.7', 15)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_portlet_content_intro" v-html="$store.getters.getLanguageText('3.7', 16)"></div>
					<div class="nox_account_desktop_portlet_content_sdata">
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 17)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 31), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings3.vk_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings3.vk_new" placeholder="https://vk.com/your_name" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings3.vk_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings3.vk_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings3('cancel', 'vk')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings3('cancel', 'vk')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings3', 'vk')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings3', 'vk')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings3.vk_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings3.vk_old">{{ noxSettings3.vk_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 25)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings3('edit', 'vk')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings3('edit', 'vk')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 18)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 32), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings3.instagram_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings3.instagram_new" placeholder="https://instagram.com/your_name" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings3.instagram_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings3.instagram_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings3('cancel', 'instagram')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings3('cancel', 'instagram')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings3', 'instagram')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings3', 'instagram')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings3.instagram_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings3.instagram_old">{{ noxSettings3.instagram_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 25)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings3('edit', 'instagram')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings3('edit', 'instagram')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 19)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 33), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings3.facebook_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings3.facebook_new" placeholder="https://facebook.com/your_name" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings3.facebook_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings3.facebook_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings3('cancel', 'facebook')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings3('cancel', 'facebook')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings3', 'facebook')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings3', 'facebook')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings3.facebook_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings3.facebook_old">{{ noxSettings3.facebook_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 25)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings3('edit', 'facebook')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings3('edit', 'facebook')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 20)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 34), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings3.whatsapp_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings3.whatsapp_new" placeholder="+Ххххххххххх" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings3.whatsapp_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings3.whatsapp_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings3('cancel', 'whatsapp')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings3('cancel', 'whatsapp')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings3', 'whatsapp')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings3', 'whatsapp')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings3.whatsapp_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings3.whatsapp_old">{{ noxSettings3.whatsapp_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 24)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings3('edit', 'whatsapp')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings3('edit', 'whatsapp')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 21)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 35), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings3.telegram_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings3.telegram_new" placeholder="@your_name" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings3.telegram_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings3.telegram_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings3('cancel', 'telegram')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings3('cancel', 'telegram')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings3', 'telegram')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings3', 'telegram')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings3.telegram_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings3.telegram_old">{{ noxSettings3.telegram_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 24)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings3('edit', 'telegram')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings3('edit', 'telegram')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 22)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 36), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div class="nox_account_desktop_portlet_content_value_editing" v-if="noxSettings3.skype_is_editing">
									<div class="nox_account_desktop_portlet_content_value_editing_block">
										<form>
											<input type="text" class="nox_input" v-model.trim="noxSettings3.skype_new" placeholder="your_name" autocomplete="off">
											<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxSettings3.skype_is_loading }"><img src="@/assets/images/loading.gif"></span>
											<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxSettings3.skype_is_loading }">
												<button type="button" class="nox_button nox_is_desktop red" @click="editSettings3('cancel', 'skype')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
												<button type="button" class="nox_button nox_is_mobile red" @click="editSettings3('cancel', 'skype')"><font-awesome-icon :icon="['fas', 'times']" /></button>
												<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editSettings3', 'skype')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
												<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editSettings3', 'skype')"><font-awesome-icon :icon="['fas', 'check']" /></button>
											</span>
										</form>
									</div>
									<div class="nox_account_desktop_portlet_content_value_editing_alert" v-html="noxSettings3.skype_alert"></div>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxSettings3.skype_old">{{ noxSettings3.skype_old }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.7', 24)"></span>
									<button type="button" class="nox_button nox_is_desktop last blue" @click="editSettings3('edit', 'skype')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile last blue" @click="editSettings3('edit', 'skype')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_content_row">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.7', 23)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 37), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxSettings3.is_incognito)" :width="42" :height="26" :disabled="toggleDisabled(noxSettings3.is_agreement)" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsIncognito($event)"></toggle-button>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_content_alert" v-html="noxAlert3"></div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalSettings"></div>
				<div is="NoxModalVerification"></div>
				<div is="NoxModalPackageExtend"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert1: '',
			noxAlert2: '',
			noxAlert3: '',
			noxUserData: {},
			noxSettings1: {},
			noxSettings2: {},
			noxSettings3: {},
			noxCountries: {},
			noxIsAccount: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					this.noxIsAccount = true; setTimeout(this.isReady, 0); return false;
				}
				this.noxCountries = this.$parent.$parent.getCountries();
				this.axios('getAccountSettings', !this.noxIsAccount ? 1 : 0);
			},
			initData: function() {

				this.$set(this.noxSettings1, 'username_old', this.$store.state.noxAccountData.username);
				this.$set(this.noxSettings1, 'username_new', this.$store.state.noxAccountData.username);
				this.$set(this.noxSettings1, 'username_is_editing', false);
				this.$set(this.noxSettings1, 'username_is_loading', false);
				this.$set(this.noxSettings1, 'username_alert', '');
				this.$set(this.noxSettings1, 'password_old', '');
				this.$set(this.noxSettings1, 'password_new', '');
				this.$set(this.noxSettings1, 'password_new_repeat', '');
				this.$set(this.noxSettings1, 'password_is_editing', false);
				this.$set(this.noxSettings1, 'password_is_loading', false);
				this.$set(this.noxSettings1, 'password_alert', '');
				this.$set(this.noxSettings1, 'email_old', this.$store.state.noxAccountData.email);
				this.$set(this.noxSettings1, 'email_new', this.$store.state.noxAccountData.email);
				this.$set(this.noxSettings1, 'email_is_editing', false);
				this.$set(this.noxSettings1, 'email_is_loading', false);
				this.$set(this.noxSettings1, 'email_is_new', false);
				this.$set(this.noxSettings1, 'email_alert', '');
				this.$set(this.noxSettings1, 'phone_old', this.$store.state.noxAccountData.phone);
				this.$set(this.noxSettings1, 'phone_new', this.$store.state.noxAccountData.phone);
				this.$set(this.noxSettings1, 'phone_is_editing', false);
				this.$set(this.noxSettings1, 'phone_is_loading', false);
				this.$set(this.noxSettings1, 'phone_alert', '');
				this.$set(this.noxSettings1, 'is_subscription_auto', this.$store.state.noxAccountData.is_subscription_auto);

				this.$set(this.noxSettings2, 'forex4you_old', this.$store.state.noxAccountData.forex4you);
				this.$set(this.noxSettings2, 'forex4you_new', this.$store.state.noxAccountData.forex4you);
				this.$set(this.noxSettings2, 'forex4you_is_editing', false);
				this.$set(this.noxSettings2, 'forex4you_is_loading', false);
				this.$set(this.noxSettings2, 'forex4you_alert', '');
				this.$set(this.noxSettings2, 'forex_box_old', this.$store.state.noxAccountData.forex_box);
				this.$set(this.noxSettings2, 'forex_box_new', this.$store.state.noxAccountData.forex_box);
				this.$set(this.noxSettings2, 'forex_box_is_editing', false);
				this.$set(this.noxSettings2, 'forex_box_is_loading', false);
				this.$set(this.noxSettings2, 'forex_box_alert', '');

				this.$set(this.noxSettings3, 'vk_old', (this.noxUserData !== null ? this.noxUserData.vk : ''));
				this.$set(this.noxSettings3, 'vk_new', (this.noxUserData !== null ? this.noxUserData.vk : ''));
				this.$set(this.noxSettings3, 'vk_is_editing', false);
				this.$set(this.noxSettings3, 'vk_is_loading', false);
				this.$set(this.noxSettings3, 'vk_alert', '');
				this.$set(this.noxSettings3, 'instagram_old', (this.noxUserData !== null ? this.noxUserData.instagram : ''));
				this.$set(this.noxSettings3, 'instagram_new', (this.noxUserData !== null ? this.noxUserData.instagram : ''));
				this.$set(this.noxSettings3, 'instagram_is_editing', false);
				this.$set(this.noxSettings3, 'instagram_is_loading', false);
				this.$set(this.noxSettings3, 'instagram_alert', '');
				this.$set(this.noxSettings3, 'facebook_old', (this.noxUserData !== null ? this.noxUserData.facebook : ''));
				this.$set(this.noxSettings3, 'facebook_new', (this.noxUserData !== null ? this.noxUserData.facebook : ''));
				this.$set(this.noxSettings3, 'facebook_is_editing', false);
				this.$set(this.noxSettings3, 'facebook_is_loading', false);
				this.$set(this.noxSettings3, 'facebook_alert', '');
				this.$set(this.noxSettings3, 'whatsapp_old', (this.noxUserData !== null ? this.noxUserData.whatsapp : ''));
				this.$set(this.noxSettings3, 'whatsapp_new', (this.noxUserData !== null ? this.noxUserData.whatsapp : ''));
				this.$set(this.noxSettings3, 'whatsapp_is_editing', false);
				this.$set(this.noxSettings3, 'whatsapp_is_loading', false);
				this.$set(this.noxSettings3, 'whatsapp_alert', '');
				this.$set(this.noxSettings3, 'telegram_old', (this.noxUserData !== null ? this.noxUserData.telegram : ''));
				this.$set(this.noxSettings3, 'telegram_new', (this.noxUserData !== null ? this.noxUserData.telegram : ''));
				this.$set(this.noxSettings3, 'telegram_is_editing', false);
				this.$set(this.noxSettings3, 'telegram_is_loading', false);
				this.$set(this.noxSettings3, 'telegram_alert', '');
				this.$set(this.noxSettings3, 'skype_old', (this.noxUserData !== null ? this.noxUserData.skype : ''));
				this.$set(this.noxSettings3, 'skype_new', (this.noxUserData !== null ? this.noxUserData.skype : ''));
				this.$set(this.noxSettings3, 'skype_is_editing', false);
				this.$set(this.noxSettings3, 'skype_is_loading', false);
				this.$set(this.noxSettings3, 'skype_alert', '');
				this.$set(this.noxSettings3, 'is_incognito', this.$store.state.noxAccountData.is_incognito);
				this.$set(this.noxSettings3, 'is_agreement', (this.noxUserData !== null ? this.noxUserData.is_agreement : 0));
			},
			resetAlerts: function() {

				this.noxAlert1 = '';
				this.noxAlert2 = '';
				this.noxAlert3 = '';
			},
			getError1: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 12); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 13); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 4); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 5); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 8); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 9); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 17); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 47); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 49); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 50); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 7); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 38); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 39); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 45); }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 46); }
				else if (i == 16) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 48); }
				else if (i == 17) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 51); }
				else if (i == 18) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 21); }
				else if (i == 19) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 22); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError2: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 3); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError3: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 4); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 5); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 6); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 7); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 8); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 9); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 10); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 11); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 12); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 13); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 14); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 15); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 16); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 17); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event, type, var1, var2) {
				event.preventDefault(); this.axios(type, var1, var2);
			},
			axios: function(type, var1, var2) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'getAccountSettings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/account/settings';
						config.params = { isAllData: var1 };
					}
					else if (type == 'editSettings1') {
						if (['username', 'password', 'email', 'phone'].includes(var1)) {
							_this.noxSettings1[var1 + '_alert'] = '';
							if (var1 == 'username') {
								if (!_this.noxSettings1.username_new) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(1);
								}
								else if (!_this.$store.state.noxRegexUsername.test(_this.noxSettings1.username_new)) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(2);
								}
								else if (_this.noxSettings1.username_new == _this.noxSettings1.username_old) {
									_this.editSettings1('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									if (_this.$store.state.noxAccountData.is_grey == 2) {
										_this.$modal.show('NoxModalVerification', { type: 'isGreyEditUsername' }); return false;
									}
									_this.noxSettings1[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/username';
									config.data = { username: _this.noxSettings1.username_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'password') {
								if (!_this.noxSettings1.password_old) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(14);
								}
								else if (!_this.$store.state.noxRegexPassword.test(_this.noxSettings1.password_old)) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(15);
								}
								else if (!_this.noxSettings1.password_new) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(7);
								}
								else if (!_this.$store.state.noxRegexPassword.test(_this.noxSettings1.password_new) || _this.noxSettings1.password_new.search(_this.$store.state.noxRegexSearchENRULetters) < 0) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(8);
								}
								else if (!_this.noxSettings1.password_new_repeat) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(9);
								}
								else if (_this.noxSettings1.password_new && _this.noxSettings1.password_new_repeat && _this.noxSettings1.password_new != _this.noxSettings1.password_new_repeat) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(10);
								}
								else if (_this.noxSettings1.password_new && _this.noxSettings1.password_old && _this.noxSettings1.password_new == _this.noxSettings1.password_old) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(17);
								}
								if (!_this.noxIsError) {
									_this.noxSettings1[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/password';
									config.data = { old_password: _this.noxSettings1.password_old, new_password: _this.noxSettings1.password_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'email') {
								if (!_this.noxSettings1.email_new) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(3);
								}
								else if (!_this.$store.state.noxRegexEmail.test(_this.noxSettings1.email_new)) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(4);
								}
								else if (_this.noxSettings1.email_new == _this.noxSettings1.email_old) {
									_this.editSettings1('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									if (!_this.noxSettings1.email_is_new) {
										_this.$modal.show('NoxModalSettings', { type: 'editEmail', stype: type, value: var1 }); return false;
									}
									_this.noxSettings1[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/email';
									config.data = { email: _this.noxSettings1.email_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'phone') {
								if (!_this.noxSettings1.phone_new) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(5);
								}
								else if (!_this.$store.state.noxRegexPhone.test(_this.noxSettings1.phone_new)) {
									_this.noxSettings1[var1 + '_alert'] = _this.getError1(6);
								}
								else if (_this.noxSettings1.phone_new == _this.noxSettings1.phone_old) {
									_this.editSettings1('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings1[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/phone';
									config.data = { phone: _this.noxSettings1.phone_new };
									config.method = 'patch';
								}
							}
						}
						else if (var1 == 'is_subscription_auto') {
							config.url = '/v2/account/users/is_subscription_auto';
							config.data = { is_subscription_auto: var2 };
							config.method = 'patch';
						}
					}
					else if (type == 'editSettings2') {
						if (['forex4you', 'forex_box'].includes(var1)) {
							_this.noxSettings2[var1 + '_alert'] = '';
							if (var1 == 'forex4you') {
								if (_this.noxSettings2.forex4you_new && !_this.$store.state.noxRegexForex4you.test(_this.noxSettings2.forex4you_new)) {
									_this.noxSettings2[var1 + '_alert'] = _this.getError2(2);
								}
								else if (_this.noxSettings2.forex4you_new == _this.noxSettings2.forex4you_old) {
									_this.editSettings2('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings2[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/forex4you';
									config.data = { forex4you: _this.noxSettings2.forex4you_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'forex_box') {
								if (_this.noxSettings2.forex_box_new && !_this.$store.state.noxRegexForex_Box.test(_this.noxSettings2.forex_box_new)) {
									_this.noxSettings2[var1 + '_alert'] = _this.getError2(4);
								}
								else if (_this.noxSettings2.forex_box_new == _this.noxSettings2.forex_box_old) {
									_this.editSettings2('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings2[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/forex_box';
									config.data = { forex_box: _this.noxSettings2.forex_box_new };
									config.method = 'patch';
								}
							}
						}
					}
					else if (type == 'editSettings3') {
						if (['vk', 'instagram', 'facebook', 'whatsapp', 'telegram', 'skype'].includes(var1)) {
							_this.noxSettings3[var1 + '_alert'] = '';
							if (var1 == 'vk') {
								if (_this.noxSettings3.vk_new && !_this.$store.state.noxRegexVkontakte.test(_this.noxSettings3.vk_new)) {
									_this.noxSettings3[var1 + '_alert'] = _this.getError3(2);
								}
								else if (_this.noxSettings3.vk_new == _this.noxSettings3.vk_old) {
									_this.editSettings3('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings3[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/data/vk';
									config.data = { vk: _this.noxSettings3.vk_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'instagram') {
								if (_this.noxSettings3.instagram_new && !_this.$store.state.noxRegexInstagram.test(_this.noxSettings3.instagram_new)) {
									_this.noxSettings3[var1 + '_alert'] = _this.getError3(4);
								}
								else if (_this.noxSettings3.instagram_new == _this.noxSettings3.instagram_old) {
									_this.editSettings3('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings3[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/data/instagram';
									config.data = { instagram: _this.noxSettings3.instagram_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'facebook') {
								if (_this.noxSettings3.facebook_new && !_this.$store.state.noxRegexFacebook.test(_this.noxSettings3.facebook_new)) {
									_this.noxSettings3[var1 + '_alert'] = _this.getError3(6);
								}
								else if (_this.noxSettings3.facebook_new == _this.noxSettings3.facebook_old) {
									_this.editSettings3('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings3[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/data/facebook';
									config.data = { facebook: _this.noxSettings3.facebook_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'whatsapp') {
								if (_this.noxSettings3.whatsapp_new && !_this.$store.state.noxRegexWhatsApp.test(_this.noxSettings3.whatsapp_new)) {
									_this.noxSettings3[var1 + '_alert'] = _this.getError3(8);
								}
								else if (_this.noxSettings3.whatsapp_new == _this.noxSettings3.whatsapp_old) {
									_this.editSettings3('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings3[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/data/whatsapp';
									config.data = { whatsapp: _this.noxSettings3.whatsapp_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'telegram') {
								if (_this.noxSettings3.telegram_new && !_this.$store.state.noxRegexTelegram.test(_this.noxSettings3.telegram_new)) {
									_this.noxSettings3[var1 + '_alert'] = _this.getError3(10);
								}
								else if (_this.noxSettings3.telegram_new == _this.noxSettings3.telegram_old) {
									_this.editSettings3('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings3[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/data/telegram';
									config.data = { telegram: _this.noxSettings3.telegram_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'skype') {
								if (_this.noxSettings3.skype_new && !_this.$store.state.noxRegexSkype.test(_this.noxSettings3.skype_new)) {
									_this.noxSettings3[var1 + '_alert'] = _this.getError3(12);
								}
								else if (_this.noxSettings3.skype_new == _this.noxSettings3.skype_old) {
									_this.editSettings3('cancel', var1); return false;
								}
								if (!_this.noxIsError) {
									_this.noxSettings3[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/data/skype';
									config.data = { skype: _this.noxSettings3.skype_new };
									config.method = 'patch';
								}
							}
							if (!_this.noxIsError) {
								if ((_this.noxSettings3.vk_new || _this.noxSettings3.instagram_new || _this.noxSettings3.facebook_new || _this.noxSettings3.whatsapp_new || _this.noxSettings3.telegram_new || _this.noxSettings3.skype_new) && !_this.noxSettings3.is_agreement) {
									_this.noxSettings3[var1 + '_is_loading'] = false;
									_this.$modal.show('NoxModalSettings', { type: 'isAgreement', stype: type, value: var1 }); return false;
								}
							}
						}
						else if (var1 == 'is_incognito') {
							config.url = '/v2/account/users/is_incognito';
							config.data = { is_incognito: var2 };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getAccountSettings') {
									_this.$parent.$parent.goToTop(true);
									_this.$parent.$parent.checkAppVersion(data.headers);
									if (data.data.account_data !== undefined) {
										_this.$parent.$parent.initAccountData(data.data.account_data);
									}
									if (data.data.system_settings !== undefined) {
										_this.$parent.$parent.initSystemSettings(data.data.system_settings);
									}
									if (data.data.user_data !== undefined) {
										if (data.data.user_data !== null && data.data.user_data.whatsapp !== '') {
											data.data.user_data.whatsapp = '+' + data.data.user_data.whatsapp;
										}
										_this.noxUserData = data.data.user_data;
										_this.initData();
									}
								}
								else if (type == 'editSettings1') {
									if (['username', 'password', 'email', 'phone'].includes(var1)) {
										if (var1 == 'password') {
											_this.noxSettings1[var1 + '_old'] = '';
											_this.noxSettings1[var1 + '_new'] = '';
											_this.noxSettings1[var1 + '_new_repeat'] = '';
										}
										else if (var1 == 'email') {
											_this.$store.state.noxAccountData[var1 + '_new'] = _this.noxSettings1[var1 + '_new'];
											_this.noxSettings1[var1 + '_is_new'] = false;
										}
										else {
											_this.$store.state.noxAccountData[var1] = _this.noxSettings1[var1 + '_new'];
											_this.noxSettings1[var1 + '_old'] = _this.noxSettings1[var1 + '_new'];
										}
										_this.editSettings1('save', var1);
									}
									else if (var1 == 'is_subscription_auto') {
										_this.$store.state.noxAccountData[var1] = var2;
										_this.noxSettings1[var1] = var2;
									}
								}
								else if (type == 'editSettings2') {
									if (['forex4you', 'forex_box'].includes(var1)) {
										_this.$store.state.noxAccountData[var1] = _this.noxSettings2[var1 + '_new'];
										_this.noxSettings2[var1 + '_old'] = _this.noxSettings2[var1 + '_new'];
										_this.editSettings2('save', var1);
									}
								}
								else if (type == 'editSettings3') {
									if (_this.noxUserData == null) { _this.noxUserData = {}; }
									if (['vk', 'instagram', 'facebook', 'whatsapp', 'telegram', 'skype'].includes(var1)) {
										_this.noxSettings3[var1 + '_old'] = _this.noxSettings3[var1 + '_new'];
										_this.noxUserData[var1] = _this.noxSettings3[var1 + '_new'];
										_this.editSettings3('save', var1);
									}
									else if (var1 == 'is_incognito') {
										_this.$store.state.noxAccountData[var1] = var2;
										_this.noxSettings3[var1] = var2;
									}
								}
							}
						}).catch(function(data) {
							if (type == 'editSettings1') {
								if (data.response !== undefined) {
									if (data.response.status == 400) {
										if      (data.response.data.error == 'USERNAME_IS_EMPTY') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(1); }
										else if (data.response.data.error == 'USERNAME_NOT_VALID') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(2); }
										else if (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(3); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(4); }
										else if (data.response.data.error == 'PHONE_IS_EMPTY') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(5); }
										else if (data.response.data.error == 'PHONE_NOT_VALID') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(6); }
										else if (data.response.data.error == 'OLD_PASSWORD_IS_EMPTY') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(14); }
										else if (data.response.data.error == 'OLD_PASSWORD_NOT_VALID') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(15); }
										else if (data.response.data.error == 'NEW_PASSWORD_IS_EMPTY') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(7); }
										else if (data.response.data.error == 'NEW_PASSWORD_NOT_VALID') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(8); }
										else if (data.response.data.error == 'IS_SUBSCRIPTION_AUTO_IS_EMPTY') { _this.noxAlert1 = _this.getError1(18); }
										else if (data.response.data.error == 'IS_SUBSCRIPTION_AUTO_NOT_VALID') { _this.noxAlert1 = _this.getError1(19); }
									}
									else if (data.response.status == 404) {
										if (data.response.data.error == 'OLD_PASSWORD_NOT_FOUND') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(16); }
									}
									else if (data.response.status == 409) {
										if      (data.response.data.error == 'EMAIL_DUPLICATE') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(11); }
										else if (data.response.data.error == 'EMAIL_HAS_ALREADY') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(13); }
										else if (data.response.data.error == 'PASSWORDS_MATCH') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(17); }
									}
									else if (data.response.status == 429) {
										if (data.response.data.error == 'EMAIL_TOO_MANY_REQUESTS') { _this.noxSettings1[var1 + '_alert'] = _this.getError1(12); }
									}
								}
								if (!_this.noxIsError) {
									_this.noxAlert1 = _this.getError1(0);
								}
							}
							else if (type == 'editSettings2') {
								if (data.response !== undefined) {
									if (data.response.status == 400) {
										if      (data.response.data.error == 'FOREX4YOU_IS_EMPTY') { _this.noxSettings2[var1 + '_alert'] = _this.getError2(1); }
										else if (data.response.data.error == 'FOREX4YOU_NOT_VALID') { _this.noxSettings2[var1 + '_alert'] = _this.getError2(2); }
										else if (data.response.data.error == 'FOREX_BOX_IS_EMPTY') { _this.noxSettings2[var1 + '_alert'] = _this.getError2(3); }
										else if (data.response.data.error == 'FOREX_BOX_NOT_VALID') { _this.noxSettings2[var1 + '_alert'] = _this.getError2(4); }
									}
								}
								if (!_this.noxIsError) {
									_this.noxAlert2 = _this.getError2(0);
								}
							}
							else if (type == 'editSettings3') {
								if (data.response !== undefined) {
									if (data.response.status == 400) {
										if      (data.response.data.error == 'VK_IS_EMPTY') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(1); }
										else if (data.response.data.error == 'VK_NOT_VALID') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(2); }
										else if (data.response.data.error == 'INSTAGRAM_IS_EMPTY') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(3); }
										else if (data.response.data.error == 'INSTAGRAM_NOT_VALID') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(4); }
										else if (data.response.data.error == 'FACEBOOK_IS_EMPTY') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(5); }
										else if (data.response.data.error == 'FACEBOOK_NOT_VALID') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(6); }
										else if (data.response.data.error == 'WHATSAPP_IS_EMPTY') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(7); }
										else if (data.response.data.error == 'WHATSAPP_NOT_VALID') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(8); }
										else if (data.response.data.error == 'TELEGRAM_IS_EMPTY') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(9); }
										else if (data.response.data.error == 'TELEGRAM_NOT_VALID') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(10); }
										else if (data.response.data.error == 'SKYPE_IS_EMPTY') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(11); }
										else if (data.response.data.error == 'SKYPE_NOT_VALID') { _this.noxSettings3[var1 + '_alert'] = _this.getError3(12); }
										else if (data.response.data.error == 'IS_INCOGNITO_IS_EMPTY') { _this.noxAlert3 = _this.getError3(13); }
										else if (data.response.data.error == 'IS_INCOGNITO_NOT_VALID') { _this.noxAlert3 = _this.getError3(14); }
									}
								}
								if (!_this.noxIsError) {
									_this.noxAlert3 = _this.getError3(0);
								}
							}
							else {
								_this.$parent.$parent.exit();
							}
						}).finally(function() {
							_this.noxIsLoading = false;
							_this.$store.state.noxIsAccount = false;
							_this.$store.state.noxIsLoading = false;
							if      (type == 'editSettings1') { _this.noxSettings1[var1 + '_is_loading'] = false; }
							else if (type == 'editSettings2') { _this.noxSettings2[var1 + '_is_loading'] = false; }
							else if (type == 'editSettings3') { _this.noxSettings3[var1 + '_is_loading'] = false; }
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleDisabled: function(value) {
				return value < 1 ? true : false;
			},
			toggleChangeForIsSubscriptionAuto: function(event) {
				this.axios('editSettings1', 'is_subscription_auto', Number(event.value));
			},
			toggleChangeForIsIncognito: function(event) {
				this.axios('editSettings3', 'is_incognito', Number(event.value));
			},
			editSettings1: function(type, stype) {
				this.noxSettings1[stype + '_new'] = this.noxSettings1[stype + '_old'];
				this.noxSettings1[stype + '_is_editing'] = (type == 'edit' ? true : false);
				this.noxSettings1[stype + '_is_loading'] = false;
				this.noxSettings1[stype + '_alert'] = '';
				this.noxAlert1 = '';
			},
			editSettings2: function(type, stype) {
				this.noxSettings2[stype + '_new'] = this.noxSettings2[stype + '_old'];
				this.noxSettings2[stype + '_is_editing'] = (type == 'edit' ? true : false);
				this.noxSettings2[stype + '_is_loading'] = false;
				this.noxSettings2[stype + '_alert'] = '';
				this.noxAlert2 = '';
			},
			editSettings3: function(type, stype) {
				this.noxSettings3[stype + '_new'] = this.noxSettings3[stype + '_old'];
				this.noxSettings3[stype + '_is_editing'] = (type == 'edit' ? true : false);
				this.noxSettings3[stype + '_is_loading'] = false;
				this.noxSettings3[stype + '_alert'] = '';
				this.noxAlert3 = '';
			}
		},
		components: {
			NoxModalSettings: () => import('@/views/modal_components/NoxModalSettings.vue'),
			NoxModalVerification: () => import('@/views/modal_components/NoxModalVerification.vue'),
			NoxModalPackageExtend: () => import('@/views/modal_components/NoxModalPackageExtend.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>
