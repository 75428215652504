<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Список индексов</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_master_settings">
						<table class="nox_table" v-if="$store.state.noxMasterSettings.length">
							<thead>
								<tr>
									<th>Номер</th>
									<th>Токен</th>
									<th>Название</th>
									<th>Множитель</th>
									<th>Алиасы</th>
									<th>Статус</th>
									<th>Описание</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxMasterSettings" :key="index" v-show="!value.is_alias">
									<td data-label="Номер:">{{ value.id }}</td>
									<td data-label="Токен:">{{ value.token }}</td>
									<td data-label="Название:" :class="[value.is_available ? 'available' : 'unavailable']">{{ value.shortname }}</td>
									<td data-label="Множитель:">{{ value.multiplier.toFixed(1) }}</td>
									<td data-label="Алиасы:">{{ String(value.aliases).replace(/,/g, ', ') }}</td>
									<td data-label="Статус:">{{ value.status }}</td>
									<td data-label="Описание:">{{ value.description }}</td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalMasterSettings', { type: 'editMaster', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalMasterSettings', { type: 'addMaster' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить индекс</button>
				</div>
			</div>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Список алиасов</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_master_alias_settings">
						<table class="nox_table" v-if="$store.state.noxMasterSettings.length">
							<thead>
								<tr>
									<th>Номер</th>
									<th>Токен</th>
									<th>Название</th>
									<th>Описание</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxMasterSettings" :key="index" v-show="value.is_alias">
									<td data-label="Номер:">{{ value.id }}</td>
									<td data-label="Токен:">{{ value.token }}</td>
									<td data-label="Название:" :class="[value.is_available ? 'available' : 'unavailable']">{{ value.shortname }}</td>
									<td data-label="Описание:">{{ value.description }}</td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalMasterSettings', { type: 'editAlias', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalMasterSettings', { type: 'addAlias' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить алиас</button>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalMasterSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios();
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/admin/account/bots/masters',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.noxIsLoading = true;
					_this.$store.state.noxIsAccount = true;

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.$parent.goToTop(true);
							_this.$parent.$parent.checkAppVersion(data.headers);
							_this.$store.state.noxMasterSettings = data.data;
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalMasterSettings: () => import('@/views/modal_components/NoxModalMasterSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_master_settings tr th,
	.nox_account_desktop_master_settings tr td {
		width: 14.28571%;
	}
	.nox_account_desktop_master_alias_settings tr th,
	.nox_account_desktop_master_alias_settings tr td {
		width: 25%;
	}
</style>
