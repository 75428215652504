<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Настройки системы</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_system_settings">
						<table class="nox_table">
							<thead>
								<tr>
									<th>№</th>
									<th>Имя</th>
									<th>Ключ</th>
									<th>Значение</th>
									<th>Тип</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, name, index) in noxSystemSettings" :key="index">
									<td data-label="№:">{{ (index + 1) }}</td>
									<td data-label="Имя:">{{ value.name }}</td>
									<td data-label="Ключ:">{{ value.key }}</td>
									<td data-label="Значение:"><input type="text" class="nox_input" v-model.trim="value.value" placeholder="value.key" autocomplete="off" @keyup="change(value.key, value.value)"></td>
									<td data-label="Тип:">{{ value.type }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
					<button type="button" class="nox_button big green" @click="axios('saveSystemSettings')"><font-awesome-icon :icon="['fas', 'save']" />Сохранить</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxSystemSettings: {},
			noxSystemSettingsChangeData: {},
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getSystemSettings');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getSystemSettings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/settings';
					}
					else if (type == 'saveSystemSettings') {
						_this.noxIsPortletLoading = true;
						config.url = '/v2/admin/account/settings';
						config.data = { data: _this.noxSystemSettingsChangeData };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getSystemSettings') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								for (var i in data.data) {
									if (!['bonus', 'cron'].includes(data.data[i].type)) {
										_this.noxSystemSettings[data.data[i].key] = data.data[i];
									}
								}
							}
							else if (type == 'saveSystemSettings') {
								_this.noxSystemSettingsChangeData = {};
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			change: function(key, value) {
				this.noxSystemSettingsChangeData[key] = value;
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_system_settings tr th:nth-child(2),
	.nox_account_desktop_system_settings tr td:nth-child(2),
	.nox_account_desktop_system_settings tr th:nth-child(3),
	.nox_account_desktop_system_settings tr td:nth-child(3),
	.nox_account_desktop_system_settings tr th:nth-child(4),
	.nox_account_desktop_system_settings tr td:nth-child(4) {
		width: 33.33333%;
		word-break: break-all;
		word-break: break-word;
	}
</style>
