<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 13)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_portlet_content_intro">
						<div class="nox_account_desktop_portlet_content_img_200">
							<img src="@/assets/images/bot_img_9.png">
							<div v-html="$store.getters.getLanguageText('3.8', 14)"></div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_content_sdata">
						<div class="nox_account_desktop_portlet_content_row single">
							<div class="nox_account_desktop_portlet_content_label">
								<span v-html="$store.getters.getLanguageText('3.8', 15)"></span>
								<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 43), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
							</div>
							<div class="nox_account_desktop_portlet_content_value">
								<div v-if="noxIsEditAlertsSettings[0]">
									<form>
										<input type="text" class="nox_input" v-model.trim="noxAlertsSettings[0]" :placeholder="$store.getters.getLanguageText('3.8', 16)" autocomplete="off">
										<span class="nox_account_desktop_portlet_content_value_loading" :class="{ hide: !noxIsAlertsSettingsLoadings[0] }"><img src="@/assets/images/loading.gif"></span>
										<span class="nox_account_desktop_portlet_content_value_buttons" :class="{ hide: noxIsAlertsSettingsLoadings[0] }">
											<button type="button" class="nox_button nox_is_desktop red" @click="editAlertsSettings('cancel', 0)" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
											<button type="button" class="nox_button nox_is_mobile red" @click="editAlertsSettings('cancel', 0)"><font-awesome-icon :icon="['fas', 'times']" /></button>
											<button type="submit" class="nox_button nox_is_desktop green" @click="submit($event, 'editAlertsSettings', 0)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
											<button type="submit" class="nox_button nox_is_mobile green" @click="submit($event, 'editAlertsSettings', 0)"><font-awesome-icon :icon="['fas', 'check']" /></button>
										</span>
									</form>
								</div>
								<div class="nox_account_desktop_portlet_content_value_content" v-else>
									<span class="nox_value" v-if="noxAlertsSettings[0]">{{ noxAlertsSettings[0] }}</span>
									<span class="nox_value uppercase" v-else v-html="$store.getters.getLanguageText('3.8', 17)"></span>
									<button type="button" class="nox_button nox_is_desktop blue" @click="editAlertsSettings('edit', 0)" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
									<button type="button" class="nox_button nox_is_mobile blue" @click="editAlertsSettings('edit', 0)"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_content_alert" v-html="noxAlert"></div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big" @click="$router.push({ path: '/account/alerts' })"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 43)"></span></button>
				</div>
			</div>
			<div class="nox_tabs">
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)">
					<span class="nox_tab_menu_label_icon"><font-awesome-icon :icon="['fas', 'users']" /></span>
					<span class="nox_tab_menu_label_text" v-html="$store.getters.getLanguageText('3.8', 18)"></span>
				</label>
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)">
					<span class="nox_tab_menu_label_icon"><font-awesome-icon :icon="['fas', 'coins']" /></span>
					<span class="nox_tab_menu_label_text" v-html="$store.getters.getLanguageText('3.8', 19)"></span>
				</label>
				<div class="nox_tab_block" id="nox_tab_block_1">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 20)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_alert_settings">
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(53)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(53, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 42)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(15)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(15, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 23)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(115)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(115, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 70)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(12)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(12, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 22)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(82)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(82, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 54)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(83)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(83, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 55)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(84)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(84, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 56)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(85)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(85, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 57)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(116)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(116, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 71)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(144)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(144, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 73)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(17)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(17, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 25)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(18)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(18, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 26)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(48)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(48, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 41)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(19)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(19, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 27)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(20)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(20, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 28)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(105)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(105, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 66)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(16)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(16, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 24)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(21)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(21, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 29)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(24)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(24, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 31)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(14)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(14, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 58)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(13)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(13, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 59)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(23)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(23, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 30)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(25)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(25, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 32)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(45)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(45, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 34)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(44)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(44, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 33)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(49)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(49, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 52)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(75)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(75, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 48)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(76)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(76, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 49)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(77)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(77, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 50)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(78)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(78, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 51)"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_tab_block" id="nox_tab_block_2">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 21)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_alert_settings">
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(95)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(95, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 65)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(32)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(32, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 60)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(26)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(26, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 35)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(27)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(27, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 36)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(29)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(29, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 38)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(28)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(28, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 37)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(65)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(65, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 43)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(66)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(66, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 44)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(70)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(70, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 45)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(67)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(67, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 46)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(68)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(68, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 47)"></span>
								</div>
								<!--div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(31)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(31, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 40)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(30)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(30, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 39)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(81)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(81, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 53)"></span>
								</div-->
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(96)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(96, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 61)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(97)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(97, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 62)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(99)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(99, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 63)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(98)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(98, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 64)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(111)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(111, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 67)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(112)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(112, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 68)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(113)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(113, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 69)"></span>
								</div>
								<div class="nox_account_desktop_alert_settings_row">
									<span class="nox_account_desktop_alert_settings_label"><toggle-button class="nox_toggle_button" :value="toggleValue(94)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(94, $event)"></toggle-button></span>
									<span class="nox_account_desktop_alert_settings_value" v-html="$store.getters.getLanguageText('3.8', 72)"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertsSettings: [],
			noxAlertsSettingsData: {},
			noxIsEditAlertsSettings: [],
			noxIsAlertsSettingsLoadings: [],
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsLoading: true,
			noxIsError: false
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getAlertsSettings');
			},
			initData: function() {
				if (this.noxAlertsSettings[0] == undefined) { this.noxAlertsSettings[0] = this.noxAlertsSettingsData.telegram_id; }
				if (this.noxIsEditAlertsSettings[0] == undefined) { this.noxIsEditAlertsSettings.push(false); }
				if (this.noxIsAlertsSettingsLoadings[0] == undefined) { this.noxIsAlertsSettingsLoadings.push(false); }
			},
			initTabs: function(tab) {
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				if (tab == 2) { this.noxIsTabMenu2Checked = true; }
				else          { this.noxIsTabMenu1Checked = true; }
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.9', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.9', 1); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event, type, var1) {
				event.preventDefault(); this.axios(type, var1);
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.noxAlert = '';
					_this.noxIsError = false;

					if (type == 'getAlertsSettings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/account/alerts/settings';
					}
					else if (type == 'editAlertsSettings') {
						if (var1 == 0) {
							if (_this.noxAlertsSettings[var1] && !Number(_this.noxAlertsSettings[var1])) {
								_this.noxAlert = _this.getError(2);
							}
							if (!_this.noxIsError) {
								_this.noxIsAlertsSettingsLoadings.splice(var1, 1, true);
								config.url = '/v2/account/alerts/settings/telegram_id';
								config.data = { telegram_id: Number(_this.noxAlertsSettings[var1]) };
								config.method = 'patch';
							}
						}
					}
					else if (type == 'editAlertsSettingsIsDisabled') {
						config.url = '/v2/account/alerts/settings/is_disabled';
						config.data = { is_disabled: _this.noxAlertsSettingsData.is_disabled.join(',') };
						config.method = 'patch';
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getAlertsSettings') {
									_this.$parent.$parent.goToTop(true);
									_this.$parent.$parent.checkAppVersion(data.headers);
									_this.noxAlertsSettingsData.telegram_id = '';
									_this.noxAlertsSettingsData.is_disabled = [];
									if (data.data !== null) {
										_this.noxAlertsSettingsData = data.data;
										_this.noxAlertsSettingsData.telegram_id = Number(data.data.telegram_id) ? data.data.telegram_id : '';
										_this.noxAlertsSettingsData.is_disabled = data.data.is_disabled !== '' ? String(data.data.is_disabled).split(',').map(temp => parseInt(temp, 10)) : [];
										_this.initData();
									}
								}
								else if (type == 'editAlertsSettings') {
									_this.noxAlertsSettingsData.telegram_id = _this.noxAlertsSettings[0];
									_this.editAlertsSettings('save', var1);
								}
							}
						}).catch(function(data) {
							if (type == 'editAlertsSettings') {
								if (data.response !== undefined) {
									if (data.response.status == 400) {
										if      (data.response.data.error == 'TELEGRAM_ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'TELEGRAM_ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									}
								}
								if (!_this.noxIsError) {
									_this.noxAlert = _this.getError(0);
								}
							}
							else {
								_this.$parent.$parent.exit();
							}
						}).finally(function() {
							if (type == 'editAlertsSettings') {
								_this.noxIsAlertsSettingsLoadings.splice(var1, 1, false);
							}
							_this.noxIsLoading = false;
							_this.$store.state.noxIsAccount = false;
							_this.$store.state.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			selectTab: function(tab) {
				if (Number(this.$route.query.tab) !== tab) {
					this.$router.push({ path: '/account/alert_settings', query: { tab: tab }});
				}
			},
			toggleValue: function(value) {
				return this.noxAlertsSettingsData.is_disabled.indexOf(Number(value)) < 0 ? true : false;
			},
			toggleChange: function(value, event) {
				var index = this.noxAlertsSettingsData.is_disabled.indexOf(Number(value));
				if (index >= 0) { this.noxAlertsSettingsData.is_disabled.splice(index, 1); }
				if (!Number(event.value)) { this.noxAlertsSettingsData.is_disabled.push(Number(value)); }
				if (Number(value) == 32) { this.toggleChange(100, event); return false; }
				if (Number(value) == 144) { this.toggleChange(145, event); return false; }
				this.noxAlertsSettingsData.is_disabled.sort((a, b) => a - b);
				this.axios('editAlertsSettingsIsDisabled');
			},
			editAlertsSettings: function(type, index) {
				this.noxAlert = '';
				this.noxAlertsSettings.splice(index, 1, ['edit', 'save'].includes(type) ? this.noxAlertsSettings[index] : this.noxAlertsSettingsData.telegram_id);
				this.noxIsEditAlertsSettings.splice(index, 1, (type == 'edit' ? true : false));
			}
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_alert_settings_row {
		position: relative;
		padding: 4px 0 4px 50px;
	}
	.nox_account_desktop_alert_settings_label {
		position: absolute;
		top: 0;
		left: 0;
	}
</style>
