<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_mailings">
				<div class="nox_tabs">
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)">Рассылки</label>
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)">Черный список</label>
					<div class="nox_tab_block" id="nox_tab_block_1">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title">Редакторы рассылок</div>
							<div class="nox_account_desktop_portlet_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
							<div class="nox_account_desktop_portlet_buttons">
								<button type="button" class="nox_button big green" @click="$modal.show('NoxModalMailings', { type: 'addMailing', stype: 'email' })"><font-awesome-icon :icon="['fas', 'plus']" />Создать email рассылку</button>
								<button type="button" class="nox_button big green" @click="$modal.show('NoxModalMailings', { type: 'addMailing', stype: 'telegram' })"><font-awesome-icon :icon="['fas', 'plus']" />Создать telegram рассылку</button>
							</div>
						</div>
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title">История рассылок</div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_mailings_data">
									<table class="nox_table">
										<thead>
											<tr>
												<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 41)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 50)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 52)"></th>
												<th><font-awesome-icon :icon="['fas', 'sync-alt']" /></th>
												<th><font-awesome-icon :icon="['fas', 'users']" /></th>
												<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxMailingsData" :key="index">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 41) + ':'"><font-awesome-icon :icon="[(value.type == 1 ? 'fas' : 'fab'), (value.type == 1 ? 'at' : 'telegram')]" :class="[ (value.type == 1 ? 'green' : 'blue') ]" /></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 50) + ':'">
													<div class="nox_account_desktop_mailings_data_subject blue" v-if="value.type == 1"><span>{{ value['subject_' + $store.state.noxLanguage] }}</span><br></div>
													<div class="nox_account_desktop_mailings_data_message"><span v-if="value['message_' + $store.state.noxLanguage].length > 350 && !noxIsMailingsContentShow[value.id]"><span v-html="(value['message_' + $store.state.noxLanguage].slice(0, 350) + '...')"></span><span class="nox_account_desktop_mailings_data_message_button blue" @click="$set(noxIsMailingsContentShow, value.id, 1)">[показать все]</span></span><span v-else v-html="value['message_' + $store.state.noxLanguage]"></span></div>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" class="button">
													<span :class="[ (value.status == 1 ? 'orange' : (value.status == 2 ? 'blue' : (value.status == 3 ? 'green' : ''))) ]" v-html="$store.getters.getLanguageText('1.7.2', [5,24,25,14][value.status])"></span>
													<div><button type="button" class="nox_button icon" :class="[ (value.status == 1 ? 'orange' : (value.status == 2 ? 'blue' : (value.status == 3 ? 'green' : ''))) ]" @click="$modal.show('NoxModalMailings', { type: (value.status == 1 ? 'pauseMailing' : (value.status == 2 ? 'continueMailing' : (value.status == 3 ? 'repeatMailing' : 'startMailing'))), stype: (value.type == 1 ? 'email' : 'telegram'), index: index, id: value.id })"><font-awesome-icon :icon="['fas', (value.status == 1 ? 'pause' : (value.status == 3 ? 'redo-alt' : 'play'))]" /></button></div>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 52) + ':'">{{ value.count_of_sent + ' / ' + value.count_of_recipients }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 53) + ':'">
													<div :class="{ hide: !noxIsMailingsRefresh[value.id] }"><img src="@/assets/images/loading.gif"></div>
													<div :class="{ hide: noxIsMailingsRefresh[value.id] }"><button type="button" class="nox_button icon blue" @click="axios('refreshMailing', value.id, index)"><font-awesome-icon :icon="['fas', 'sync-alt']" /></button></div>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 51) + ':'"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalMailings', { type: 'getMRecipients', stype: (value.type == 1 ? 'email' : 'telegram'), index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'users']" /></button></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 28) + ':'"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalMailings', { type: 'editMailing', stype: (value.type == 1 ? 'email' : 'telegram'), index: index, id: value.id })" v-if="value.status !== 3"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 29) + ':'"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalMailings', { type: 'deleteMailing', stype: (value.type == 1 ? 'email' : 'telegram'), index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div v-if="noxMailingsDataLimitOffset">
								<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading1 }"><img src="@/assets/images/loading.gif"></div>
								<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading1 }">
									<button type="button" class="nox_button big" @click="axios('getMailingsData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_tab_block" id="nox_tab_block_2">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title">Черный список рассылок</div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_mailings_blacklist">
									<table class="nox_table">
										<thead>
											<tr>
												<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 24)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxMailingsBlacklist" :key="index">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 24) + ':'">{{ value.email }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 29) + ':'"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalMailings', { type: 'deleteMBlacklist', index: index, id: value.id, email: value.email })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading2 }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading2 }">
								<button type="button" class="nox_button big" @click="axios('getMailingsBlacklist')" v-if="noxMailingsBlacklistLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
								<button type="button" class="nox_button big green" @click="$modal.show('NoxModalMailings', { type: 'addMBlacklist' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить e-mail в черный список</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalMailings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxMailingsLimitOffset: 0,
			noxMailingsLimitCount: 20,
			noxMailingsData: [],
			noxMailingsDataLimitOffset: 0,
			noxMailingsDataLimitCount: 20,
			noxMailingsBlacklist: [],
			noxMailingsBlacklistEmails: [],
			noxMailingsBlacklistLimitOffset: 0,
			noxMailingsBlacklistLimitCount: 20,
			noxIsMailingsContentShow: {},
			noxIsMailingsRefresh: {},
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsPortletLoading1: false,
			noxIsPortletLoading2: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getMailings');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initTabs: function(tab) {
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				if (tab == 2) { this.noxIsTabMenu2Checked = true; }
				else          { this.noxIsTabMenu1Checked = true; }
			},
			selectTab: function(tab) {
				if (Number(this.$route.query.tab) !== tab) {
					this.$router.push({ path: '/account/mailings', query: { tab: tab }});
				}
			},
			axios: function(type, var1, var2) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getMailings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/mailings';
						config.params = { limitOffset: _this.noxMailingsLimitOffset, limitCount: _this.noxMailingsLimitCount };
					}
					else if (type == 'getMailingsData') {
						_this.noxIsPortletLoading1 = true;
						config.url = '/v2/admin/account/mailings/history';
						config.params = { limitOffset: _this.noxMailingsDataLimitOffset, limitCount: _this.noxMailingsDataLimitCount };
					}
					else if (type == 'getMailingsBlacklist') {
						_this.noxIsPortletLoading2 = true;
						config.url = '/v2/admin/account/mailings/blacklist';
						config.params = { limitOffset: _this.noxMailingsBlacklistLimitOffset, limitCount: _this.noxMailingsBlacklistLimitCount };
					}
					else if (type == 'refreshMailing') {
						_this.$set(_this.noxIsMailingsRefresh, var1, true);
						config.url = '/v2/admin/account/mailings/refresh';
						config.data = { id: var1 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getMailings') {
								if (data.data.mailings_data !== undefined) {
									if (data.data.mailings_data.data.length) {
										for (var i in data.data.mailings_data.data) {
											_this.noxMailingsData.push(data.data.mailings_data.data[i]);
										}
									}
									_this.noxMailingsDataLimitOffset = data.data.mailings_data.count > _this.noxMailingsDataLimitOffset + _this.noxMailingsDataLimitCount ? _this.noxMailingsDataLimitOffset + _this.noxMailingsDataLimitCount : 0;
								}
								if (data.data.mailings_blacklist !== undefined) {
									if (data.data.mailings_blacklist.data.length) {
										for (var j in data.data.mailings_blacklist.data) {
											_this.noxMailingsBlacklist.push(data.data.mailings_blacklist.data[j]);
											_this.noxMailingsBlacklistEmails.push(data.data.mailings_blacklist.data[j].email);
										}
									}
									_this.noxMailingsBlacklistLimitOffset = data.data.mailings_blacklist.count > _this.noxMailingsBlacklistLimitOffset + _this.noxMailingsBlacklistLimitCount ? _this.noxMailingsBlacklistLimitOffset + _this.noxMailingsBlacklistLimitCount : 0;
								}
							}
							else if (type == 'getMailingsData') {
								if (data.data.data.length) {
									for (var ii in data.data.data) {
										_this.noxMailingsData.push(data.data.data[ii]);
									}
								}
								_this.noxMailingsDataLimitOffset = data.data.count > _this.noxMailingsDataLimitOffset + _this.noxMailingsDataLimitCount ? _this.noxMailingsDataLimitOffset + _this.noxMailingsDataLimitCount : 0;
							}
							else if (type == 'getMailingsBlacklist') {
								if (data.data.data.length) {
									for (var jj in data.data.data) {
										_this.noxMailingsBlacklist.push(data.data.data[jj]);
									}
								}
								_this.noxMailingsBlacklistLimitOffset = data.data.count > _this.noxMailingsBlacklistLimitOffset + _this.noxMailingsBlacklistLimitCount ? _this.noxMailingsBlacklistLimitOffset + _this.noxMailingsBlacklistLimitCount : 0;
							}
							else if (type == 'refreshMailing') {
								_this.$set(_this.noxIsMailingsRefresh, var1, false);
								_this.noxMailingsData.splice(var2, 1, data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading1 = false;
						_this.noxIsPortletLoading2 = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalMailings: () => import('@/views/modal_components/NoxModalMailings.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_mailings_data tr th:nth-child(1),
	.nox_account_desktop_mailings_data tr td:nth-child(1),
	.nox_account_desktop_mailings_data tr th:nth-child(2),
	.nox_account_desktop_mailings_data tr td:nth-child(2),
	.nox_account_desktop_mailings_data tr th:nth-child(6),
	.nox_account_desktop_mailings_data tr td:nth-child(6),
	.nox_account_desktop_mailings_data tr th:nth-child(7),
	.nox_account_desktop_mailings_data tr td:nth-child(7),
	.nox_account_desktop_mailings_data tr th:nth-child(8),
	.nox_account_desktop_mailings_data tr td:nth-child(8),
	.nox_account_desktop_mailings_data tr th:nth-child(9),
	.nox_account_desktop_mailings_data tr td:nth-child(9),
	.nox_account_desktop_mailings_blacklist tr th:nth-child(1),
	.nox_account_desktop_mailings_blacklist tr td:nth-child(1),
	.nox_account_desktop_mailings_blacklist tr th:nth-child(4),
	.nox_account_desktop_mailings_blacklist tr td:nth-child(4) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_mailings_data tr th:nth-child(3),
	.nox_account_desktop_mailings_data tr td:nth-child(3),
	.nox_account_desktop_mailings_blacklist tr th:nth-child(2),
	.nox_account_desktop_mailings_blacklist tr td:nth-child(2) {
		width: 50%;
	}
	.nox_account_desktop_mailings_data tr td:nth-child(3) {
		text-align: left;
	}
	.nox_account_desktop_mailings_data_subject {
		margin: 0 0 5px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_mailings_data_message_button {
		margin: 0 0 0 5px;
		cursor: pointer;
	}

	@media (max-width: 1200px) {
		.nox_account_desktop_mailings_data_subject,
		.nox_account_desktop_mailings_data_message {
			margin: 5px 0 0 0;
			display: inline-block;
			width: 100%;
		}
	}
</style>
