<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_admin_vpn">
				<div class="nox_account_desktop_admin_vpn_file">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title">NOX VPN File</div>
						<div class="nox_account_desktop_portlet_content">Редактирование основного текста для файла .ovpn</div>
						<div class="nox_account_desktop_portlet_buttons">
							<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalAdminVPN', { type: 'editVPNFile' })"><font-awesome-icon :icon="['fas', 'pencil-alt']" />Изменить</button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_admin_vpn_servers">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title">Список серверов NOX VPN</div>
						<div class="nox_account_desktop_portlet_content">
							<table class="nox_table" v-if="noxVPNServers.length">
								<thead>
									<tr>
										<th>№</th>
										<th>Адрес</th>
										<th>Страна</th>
										<th>Последний пинг</th>
										<th>Клиенты</th>
										<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
										<th><font-awesome-icon :icon="['fas', 'times']" /></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(value, index) in noxVPNServers" :key="index">
										<td data-label="№:">{{ (index + 1) }}</td>
										<td data-label="Адрес:" :class="[value.is_online ? 'available' : 'unavailable']">{{ value.address }}:{{ value.port }}</td>
										<td data-label="Страна:">{{ value.country }} ({{ noxVPNCountries[value.country] }})</td>
										<td data-label="Последний пинг:">{{ new Date(value.online_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
										<td data-label="Клиенты:">{{ value.clients }}</td>
										<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalAdminVPN', { type: 'editVPNServer', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
										<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalAdminVPN', { type: 'deleteVPNServer', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="nox_account_desktop_portlet_buttons">
							<button type="button" class="nox_button big green" @click="$modal.show('NoxModalAdminVPN', { type: 'addVPNServer' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить сервер</button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_admin_vpn_servers_statistics">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title">Статистика NOX VPN</div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_chart" v-if="noxVPNServersStatisticsChart.series[0].data.length">
								<div class="nox_chart_highcharts"><highcharts :options="noxVPNServersStatisticsChart"></highcharts></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalAdminVPN"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxVPNFile: {},
			noxVPNServers: [],
			noxVPNServersStatisticsChart: {},
			noxVPNCountries: {},
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.noxVPNCountries = this.$parent.$parent.getCountries();
					this.axios('getVPNData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getVPNData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/vpn';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getVPNData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.vpn_file !== undefined) { _this.noxVPNFile = data.data.vpn_file; }
								if (data.data.vpn_servers !== undefined) { _this.noxVPNServers = data.data.vpn_servers; }
								if (data.data.vpn_servers_statistics_details !== undefined) {
									_this.noxVPNServersStatisticsChart = {
										title: { text: 'Пользователей Online' },
										chart: { height: 300 },
										legend: { enabled: true },
										series: [
											{ name: 'Пользователей за сутки', color: '#1EC455', data: [] },
											{ name: 'Пользователей за неделю', color: '#7CB5EC', data: [] }
										]
									};
									if (data.data.vpn_servers_statistics_details.length) {
										var statistics = data.data.vpn_servers_statistics_details.reverse();
										for (var s in statistics) {
											_this.noxVPNServersStatisticsChart.series[0].data.push([(parseInt(statistics[s].clients_per_day_at) * 1000), parseInt(statistics[s].clients_per_day)]);
											_this.noxVPNServersStatisticsChart.series[1].data.push([(parseInt(statistics[s].clients_per_week_at) * 1000), parseInt(statistics[s].clients_per_week)]);
										}
									}
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalAdminVPN: () => import('@/views/modal_components/NoxModalAdminVPN.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_admin_vpn_servers tr th:nth-child(1),
	.nox_account_desktop_admin_vpn_servers tr td:nth-child(1),
	.nox_account_desktop_admin_vpn_servers tr th:nth-child(5),
	.nox_account_desktop_admin_vpn_servers tr td:nth-child(5),
	.nox_account_desktop_admin_vpn_servers tr th:nth-child(6),
	.nox_account_desktop_admin_vpn_servers tr td:nth-child(6),
	.nox_account_desktop_admin_vpn_servers tr th:nth-child(7),
	.nox_account_desktop_admin_vpn_servers tr td:nth-child(7) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_admin_vpn_servers tr th,
	.nox_account_desktop_admin_vpn_servers tr td {
		width: 40%;
	}
</style>
