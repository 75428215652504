<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_tb">
				<div class="nox_tabs">
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)">Конструктор</label>
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)">Автоответчик</label>
					<div class="nox_tab_block" id="nox_tab_block_1">
						<div class="nox_account_desktop_tb_mc_commands">
							<div class="nox_account_desktop_portlet">
								<div class="nox_account_desktop_portlet_title">Конструктор меню</div>
								<div class="nox_account_desktop_portlet_content">
									<div class="nox_account_desktop_portlet_content_accordion" v-if="Object.keys($store.state.noxTBData).length">
										<div class="nox_account_desktop_portlet_content_accordion_block" :class="{ active: (noxToggleAccordionIndex == index) }" :id="'nox_account_desktop_portlet_content_accordion_block_' + index" v-for="(value, index) in $store.state.noxTBData" :key="index">
											<div class="nox_account_desktop_portlet_content_accordion_block_item">
												<div class="nox_account_desktop_portlet_content_accordion_block_item_head" @click="toggleAccordion(index)">
													<div class="nox_account_desktop_portlet_content_accordion_block_item_head_title"><span>Команда #{{ value.data.id }}:</span><span class="uppercase margin_left_8">{{ value.data.name }}</span></div>
													<div class="nox_account_desktop_portlet_content_accordion_block_item_head_toggle">
														<font-awesome-icon :icon="['fas', 'angle-up']" v-if="noxIsToggleAccordion && noxToggleAccordionIndex == index" />
														<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
													</div>
												</div>
												<div class="nox_account_desktop_portlet_content_accordion_block_item_body" :class="{ hide: (!noxIsToggleAccordion || (noxIsToggleAccordion && noxToggleAccordionIndex !== index)) }">
													<div class="nox_account_desktop_portlet_content_accordion_block_item_body_content" :class="{ empty: !Object.keys(value.blocks).length }">
														<div class="nox_account_desktop_tb_mc_blocks" v-if="Object.keys(value.blocks).length">
															<div class="nox_account_desktop_tb_mc_block" v-for="(value2, name2, index2) in value.blocks" :key="index2">
																<div class="nox_account_desktop_tb_mc_block_content">
																	<div class="nox_account_desktop_tb_mc_block_content_head">
																		<div class="nox_account_desktop_tb_mc_block_content_head_title" :class="[(value2.data.type == 1 ? 'blue' : (value2.data.type == 2 ? 'red' : (value2.data.type == 3 ? 'orange' : '')))]"><font-awesome-icon :icon="['fas', (value2.data.type == 1 ? 'image' : (value2.data.type == 2 ? 'exclamation-triangle' : (value2.data.type == 3 ? 'edit' : 'align-left')))]" />{{ noxTBMCBlockTypes[value2.data.type].label }} #{{ value2.data.sorting }}</div>
																		<div class="nox_account_desktop_tb_mc_block_content_head_buttons">
																			<button type="button" class="nox_button" @click="$modal.show('NoxModalTelegramBot', { type: 'swapDownTBMCBlock', cid: value.data.id, bid: value2.data.id })" v-if="Object.keys(value.blocks).length > 1 && index2 < (Object.keys(value.blocks).length - 1)"><font-awesome-icon :icon="['fas', 'arrow-down']" /></button>
																			<button type="button" class="nox_button" @click="$modal.show('NoxModalTelegramBot', { type: 'swapUpTBMCBlock', cid: value.data.id, bid: value2.data.id })" v-if="Object.keys(value.blocks).length > 1 && index2 > 0"><font-awesome-icon :icon="['fas', 'arrow-up']" /></button>
																			<button type="button" class="nox_button" :class="[(value2.data.details.photo ? 'blue' : 'green')]" @click="$parent.$parent.chooseCropperImage('cropImageForTelegramBot', { cid: value.data.id, bid: value2.data.id })" v-if="value2.data.type == 1"><font-awesome-icon :icon="['fas', 'upload']" /></button>
																			<button type="button" class="nox_button" :class="[(Object.keys(value2.keyboard).length ? 'blue' : 'green')]" @click="$modal.show('NoxModalTelegramBot', { type: (Object.keys(value2.keyboard).length ? 'editTBMCKeyboard' : 'addTBMCKeyboard'), cid: value.data.id, bid: value2.data.id, kid: (Object.keys(value2.keyboard).length ? value2.keyboard.id : 0) })" v-if="[0,1].includes(value2.data.type)"><font-awesome-icon :icon="['fas', 'keyboard']" /></button>
																			<button type="button" class="nox_button blue" @click="$modal.show('NoxModalTelegramBot', { type: 'editTBMCBlock', cid: value.data.id, bid: value2.data.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
																			<button type="button" class="nox_button red" @click="$modal.show('NoxModalTelegramBot', { type: 'deleteTBMCBlock', cid: value.data.id, bid: value2.data.id })"><font-awesome-icon :icon="['fas', 'times']" /></button>
																		</div>
																	</div>
																	<div class="nox_account_desktop_tb_mc_block_content_body">
																		<div v-if="value2.data.type == 1">
																			<div v-if="value2.data.details.photo"><img :src="value2.data.details.photo" :class="{ margin_bottom_15: value2.data['text_' + $store.state.noxLanguage] }"><div class="caption" v-html="value2.data['text_' + $store.state.noxLanguage]"></div></div>
																			<div v-else>Загрузите изображение!!!</div>
																		</div>
																		<div v-else v-html="value2.data['text_' + $store.state.noxLanguage]"></div>
																	</div>
																</div>
																<div class="nox_account_desktop_tb_mc_keyboard" v-if="Object.keys(value2.keyboard).length">
																	<div class="nox_account_desktop_tb_mc_keyboard_rows">
																		<div :class="'nox_account_desktop_tb_mc_keyboard_row_' + value2.data.id" v-for="(value3, index3) in value2.keyboard.data" :key="index3">
																			<draggable class="nox_account_desktop_tb_mc_keyboard_row" :class="{ draggable: noxIsTBMCKeyboardDraggable[value2.data.id] }" :list="value3" :group="'group_' + value2.data.id" @change="$set(noxIsTBMCKeyboardDraggable, value2.data.id, 1)">
																				<div class="nox_account_desktop_tb_mc_keyboard_cell" :class="{ draggable: noxIsTBMCKeyboardDraggable[value2.data.id] }" v-for="(value4, index4) in value3" :key="index4">
																					<span class="nox_account_desktop_tb_mc_keyboard_cell_name" @click="$parent.$parent.goToAccordionBlock(value4.next_cid)">{{ value4['cell_name_' + $store.state.noxLanguage] }}</span>
																					<button type="button" class="nox_button blue" @click="$modal.show('NoxModalTelegramBot', { type: 'editTBMCKeyboardCell', cid: value.data.id, bid: value2.data.id, kid: value2.keyboard.id, row_id: index3, cell_id: index4 })" v-if="!noxIsTBMCKeyboardDraggable[value2.data.id]"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
																				</div>
																			</draggable>
																		</div>
																		<div class="nox_account_desktop_tb_mc_keyboard_row_add">
																			<button type="button" class="nox_button big" @click="resetTBMCKeyboard(value.data.id, value2.data.id)" v-if="noxIsTBMCKeyboardDraggable[value2.data.id]"><font-awesome-icon :icon="['fas', 'reply']" /><span>Сбросить</span></button>
																			<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalTelegramBot', { type: 'saveTBMCKeyboard', cid: value.data.id, bid: value2.data.id, kid: value2.keyboard.id })" v-if="noxIsTBMCKeyboardDraggable[value2.data.id]"><font-awesome-icon :icon="['fas', 'save']" /><span>Сохранить</span></button>
																			<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTelegramBot', { type: 'addTBMCKeyboardRow', cid: value.data.id, bid: value2.data.id, kid: value2.keyboard.id })" v-if="!noxIsTBMCKeyboardDraggable[value2.data.id]"><font-awesome-icon :icon="['fas', 'plus']" /><span>Добавить строку</span></button>
																		</div>
																	</div>
																	<div class="nox_account_desktop_tb_mc_keyboard_rows_tools" v-if="!noxIsTBMCKeyboardDraggable[value2.data.id]">
																		<div class="nox_account_desktop_tb_mc_keyboard_rows_tools_buttons" :class="{ show: noxIsTBMCKeyboardRowTools[(value2.keyboard.id + '_' + index5)] }" v-for="(value5, index5) in value2.keyboard.data" :key="index5">
																			<button type="button" class="nox_button red" @click="$modal.show('NoxModalTelegramBot', { type: 'deleteTBMCKeyboardRow', cid: value.data.id, bid: value2.data.id, kid: value2.keyboard.id, row_id: index5 })"><font-awesome-icon :icon="['fas', 'times']" /></button>
																			<button type="button" class="nox_button green" @click="$modal.show('NoxModalTelegramBot', { type: 'addTBMCKeyboardCell', cid: value.data.id, bid: value2.data.id, kid: value2.keyboard.id, row_id: index5 })"><font-awesome-icon :icon="['fas', 'plus']" /></button>
																			<button type="button" class="nox_button blue" @click="$set(noxIsTBMCKeyboardRowTools, (value2.keyboard.id + '_' + index5), (noxIsTBMCKeyboardRowTools[(value2.keyboard.id + '_' + index5)] == 1 ? 0 : 1))"><font-awesome-icon :icon="['fas', 'bars']" /></button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="nox_account_desktop_portlet_content_accordion_block_item_body_buttons">
														<button type="button" class="nox_button big red" @click="$modal.show('NoxModalTelegramBot', { type: 'deleteTBMCCommand', cid: value.data.id })"><font-awesome-icon :icon="['fas', 'trash']" /><span>Удалить команду</span></button>
														<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalTelegramBot', { type: 'editTBMCCommand', cid: value.data.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /><span>Изменить команду</span></button>
														<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTelegramBot', { type: 'addTBMCBlock', cid: value.data.id })"><font-awesome-icon :icon="['fas', 'plus']" /><span>Добавить блок</span></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="nox_account_desktop_portlet_buttons">
									<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTelegramBot', { type: 'addTBMCCommand' })"><font-awesome-icon :icon="['fas', 'plus']" /><span>Добавить команду</span></button>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_tab_block" id="nox_tab_block_2">
						<div class="nox_account_desktop_tb_ar">
							<div class="nox_account_desktop_portlet_wrapper">
								<div class="nox_account_desktop_portlet">
									<div class="nox_account_desktop_portlet_title">Список вопросов</div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_tb_ar_questions">
											<table class="nox_table" v-if="noxTBARQuestions.length">
												<thead>
													<tr>
														<th>Id</th>
														<th>Telegram ID</th>
														<th>Вопрос</th>
														<th>Дата / время</th>
														<th><font-awesome-icon :icon="['fas', 'copy']" /></th>
														<th><font-awesome-icon :icon="['fas', 'times']" /></th>
													</tr>
												</thead>
												<tbody>
													<tr class="nox_account_desktop_tb_ar_questions_item" :class="{ incorrect: value.type == 1 }" v-for="(value, index) in noxTBARQuestions" :key="index">
														<td data-label="Id:">{{ value.id }}</td>
														<td data-label="Telegram ID:">{{ value.tid }}</td>
														<td data-label="Вопрос:">{{ value.text }}</td>
														<td data-label="Дата / время:">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
														<td data-label="Копировать:"><button type="button" class="nox_button icon orange" @click="$parent.$parent.clipboard(value.text)"><font-awesome-icon :icon="['fas', 'copy']" /></button></td>
														<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="axios('deleteTBARQuestion', value.id)"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
													</tr>
												</tbody>
											</table>
											<div v-else>Список вопросов пуст!</div>
										</div>
									</div>
									<div class="nox_account_desktop_portlet_buttons" v-if="noxTBARQuestions.length">
										<button type="button" class="nox_button big red" @click="$modal.show('NoxModalTelegramBot', { type: 'deleteTBARQuestions' })"><font-awesome-icon :icon="['fas', 'trash']" /><span>Удалить все</span></button>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_wrapper">
								<div class="nox_account_desktop_portlet">
									<div class="nox_account_desktop_portlet_title">Список ответов</div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_tb_ar_answers">
											<table class="nox_table" v-if="noxTBARAnswers.length">
												<thead>
													<tr>
														<th>Id</th>
														<th>Ответ</th>
														<th>Дата / время</th>
														<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
														<th><font-awesome-icon :icon="['fas', 'times']" /></th>
													</tr>
												</thead>
												<tbody>
													<tr class="nox_account_desktop_tb_ar_answers_item" :class="{ active: (noxTBARAnswerActive == value.id) }" :id="'nox_account_desktop_tb_ar_answers_item_' + value.id" v-for="(value, index) in noxTBARAnswers" :key="index">
														<td data-label="Id:">{{ value.id }}</td>
														<td data-label="Ответ:">{{ value.text }}</td>
														<td data-label="Дата / время:">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
														<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalTelegramBot', { type: 'editTBARAnswer', index: index, aid: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
														<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalTelegramBot', { type: 'deleteTBARAnswer', index: index, aid: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
													</tr>
												</tbody>
											</table>
											<div v-else>Список ответов пуст!</div>
										</div>
									</div>
									<div class="nox_account_desktop_portlet_buttons">
										<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTelegramBot', { type: 'addTBARAnswer' })"><font-awesome-icon :icon="['fas', 'plus']" /><span>Добавить ответ</span></button>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_wrapper">
								<div class="nox_account_desktop_portlet">
									<div class="nox_account_desktop_portlet_title">Список шаблонов</div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_tb_ar_templates">
											<table class="nox_table" v-if="noxTBARTemplates.length">
												<thead>
													<tr>
														<th>Id</th>
														<th>AId</th>
														<th>Шаблон</th>
														<th><font-awesome-icon :icon="['fas', 'comment']" /></th>
														<th>Дата / время</th>
														<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
														<th><font-awesome-icon :icon="['fas', 'times']" /></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(value, index) in noxTBARTemplates" :key="index">
														<td data-label="Id:">{{ value.id }}</td>
														<td data-label="AId:" @click="goToTBARAnswer(value.aid)">{{ value.aid }}</td>
														<td data-label="Шаблон:">{{ value.text }}</td>
														<td data-label="Счетчик:">{{ value.counter }}</td>
														<td data-label="Дата / время:">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
														<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalTelegramBot', { type: 'editTBARTemplate', index: index, tid: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
														<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalTelegramBot', { type: 'deleteTBARTemplate', index: index, tid: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
													</tr>
												</tbody>
											</table>
											<div v-else>Список шаблонов пуст!</div>
										</div>
									</div>
									<div class="nox_account_desktop_portlet_buttons">
										<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTelegramBot', { type: 'addTBARTemplate' })"><font-awesome-icon :icon="['fas', 'plus']" /><span>Добавить шаблон</span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalCropper"></div>
				<div is="NoxModalTelegramBot"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import draggable from 'vuedraggable'
	export default {
		data: () => ({
			noxTBARAnswers: [],
			noxTBARAnswersOptions: [],
			noxTBARAnswerActive: 0,
			noxTBARQuestions: [],
			noxTBARTemplates: [],
			noxTBMCCommandsOptions: [],
			noxTBMCBlockTypes: [
				{ code: 0, label: 'Текст' },
				{ code: 1, label: 'Изображение' },
				{ code: 2, label: 'Предупреждение' },
				{ code: 3, label: 'Форма ввода' }
			],
			noxTBMCKeyboards: {},
			noxTBMCKeyboardsOld: {},
			noxToggleAccordionIndex: -1,
			noxIsTBMCKeyboardRowTools: {},
			noxIsTBMCKeyboardDraggable: {},
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsToggleAccordion: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || [355,463].includes(this.$store.state.noxAccountData.id)) {
					this.axios('getTBData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initTabs: function(tab) {
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				if (tab == 2) { this.noxIsTabMenu2Checked = true; }
				else          { this.noxIsTabMenu1Checked = true; }
			},
			selectTab: function(tab) {
				if (Number(this.$route.query.tab) !== tab) {
					this.$router.push({ path: '/account/telegram_bot', query: { tab: tab }});
				}
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getTBData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/alerts/tb/data';
					}
					else if (type == 'deleteTBARQuestion') {
						config.url = '/v2/admin/account/alerts/tb/ar/questions';
						config.data = { id: Number(var1) };
						config.method = 'delete';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (['getTBData', 'deleteTBARQuestion'].includes(type)) {
								if (type == 'getTBData') {
									_this.$parent.$parent.goToTop(true);
									_this.$parent.$parent.checkAppVersion(data.headers);
								}
								_this.getTBData(data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			getTBData: function(data) {

				this.noxTBARAnswersOptions = [];
				this.noxTBMCCommandsOptions = [];
				this.$store.state.noxTBData = {};
				this.noxIsTBMCKeyboardRowTools = {};
				this.noxIsTBMCKeyboardDraggable = {};

				if (data.tb_ar_answers !== undefined) {
					this.noxTBARAnswers = data.tb_ar_answers;
					for (var i in data.tb_ar_answers) {
						this.noxTBARAnswersOptions.push({ code: data.tb_ar_answers[i].id, label: '#' + data.tb_ar_answers[i].id + '. ' + data.tb_ar_answers[i].text.substring(0, 25) + (data.tb_ar_answers[i].text.length > 25 ? '...' : '') });
					}
				}
				if (data.tb_ar_questions !== undefined) { this.noxTBARQuestions = data.tb_ar_questions; }
				if (data.tb_ar_templates !== undefined) { this.noxTBARTemplates = data.tb_ar_templates; }
				if (data.tb_mc_commands !== undefined) {
					for (var j in data.tb_mc_commands) {
						this.$set(this.$store.state.noxTBData, data.tb_mc_commands[j].id, { data: data.tb_mc_commands[j], blocks: {} });
						this.noxTBMCCommandsOptions.push({ code: data.tb_mc_commands[j].id, label: '#' + data.tb_mc_commands[j].id + '. ' + data.tb_mc_commands[j].name });
					}
					if (data.tb_mc_blocks !== undefined) {
						if (data.tb_mc_keyboards !== undefined) {
							this.getTBMCKeyboards(data.tb_mc_keyboards);
						}
						for (var k in data.tb_mc_blocks) {
							var keyboard = {};
							if (this.noxTBMCKeyboards[data.tb_mc_blocks[k].cid] !== undefined) {
								if (this.noxTBMCKeyboards[data.tb_mc_blocks[k].cid][data.tb_mc_blocks[k].id] !== undefined) {
									keyboard = this.noxTBMCKeyboards[data.tb_mc_blocks[k].cid][data.tb_mc_blocks[k].id];
								}
							}
							this.$set(this.$store.state.noxTBData[data.tb_mc_blocks[k].cid].blocks, 'id' + data.tb_mc_blocks[k].id, { data: data.tb_mc_blocks[k], keyboard: keyboard });
						}
					}
				}
			},
			getTBMCKeyboards: function(data) {
				this.noxTBMCKeyboards = {};
				this.noxTBMCKeyboardsOld = {};
				if (data !== undefined && data.length) {
					for (var i in data) {
						if (this.noxTBMCKeyboards[data[i].cid] == undefined) {
							this.noxTBMCKeyboards[data[i].cid] = {};
						}
						if (this.noxTBMCKeyboards[data[i].cid][data[i].bid] == undefined) {
							this.noxTBMCKeyboards[data[i].cid][data[i].bid] = { id: data[i].id, type: data[i].type, data: [] };
						}
						if (this.noxTBMCKeyboards[data[i].cid][data[i].bid].data[data[i].row_id] == undefined) {
							this.noxTBMCKeyboards[data[i].cid][data[i].bid].data[data[i].row_id] = [];
						}
						this.noxTBMCKeyboards[data[i].cid][data[i].bid].data[data[i].row_id].push(data[i]);
					}
					this.noxTBMCKeyboardsOld = JSON.parse(JSON.stringify(this.noxTBMCKeyboards));
				}
			},
			goToTBARAnswer: function(id) {
				if (id) {
					this.noxTBARAnswerActive = Number(id);
					this.$el.querySelector('#nox_account_desktop_tb_ar_answers_item_' + id).scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				}
			},
			resetTBMCKeyboard: function(cid, bid) {
				this.$set(this.noxIsTBMCKeyboardDraggable, bid, 0);
				this.noxTBMCKeyboards[cid][bid].data = JSON.parse(JSON.stringify(this.noxTBMCKeyboardsOld[cid][bid].data));
			},
			toggleAccordion: function(index) {
				this.noxIsToggleAccordion = (this.noxToggleAccordionIndex == index ? !this.noxIsToggleAccordion : true);
				this.noxToggleAccordionIndex = (this.noxIsToggleAccordion ? index : -1);
				this.$parent.$parent.goToAccordionBlock(index);
			}
		},
		components: {
			draggable,
			NoxModalCropper: () => import('@/views/modal_components/NoxModalCropper.vue'),
			NoxModalTelegramBot: () => import('@/views/modal_components/NoxModalTelegramBot.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_tb .nox_account_desktop_portlet_content_accordion_block.active .nox_account_desktop_portlet_content_accordion_block_item_head {
		background: #FF0000B3;
	}
	.nox_account_desktop_tb #nox_account_desktop_portlet_content_accordion_block_38 .nox_account_desktop_portlet_content_accordion_block_item_head,
	.nox_account_desktop_tb #nox_account_desktop_portlet_content_accordion_block_39 .nox_account_desktop_portlet_content_accordion_block_item_head,
	.nox_account_desktop_tb #nox_account_desktop_portlet_content_accordion_block_40 .nox_account_desktop_portlet_content_accordion_block_item_head {
		background: #0066BBB3;
	}
	.nox_account_desktop_tb_mc_block {
		margin: 0 0 20px 0;
	}
	.nox_account_desktop_tb_mc_block_content {
		border: 1px solid #999999;
		background: #606060B3;
		border-radius: 5px;
		overflow: hidden;
	}
	.nox_account_desktop_tb_mc_block_content_head {
		position: relative;
		padding: 0 20px;
		border-bottom: 1px solid #999999;
		background: #505050B3;
		line-height: 38px;
		min-height: 40px;
	}
	.nox_account_desktop_tb_mc_block_content_head_title {
		color: #ABFFAB;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
	}
	.nox_account_desktop_tb_mc_block_content_head_title.blue {
		color: #A5E5FF;
	}
	.nox_account_desktop_tb_mc_block_content_head_title.orange {
		color: #FFC660;
	}
	.nox_account_desktop_tb_mc_block_content_head_title.red {
		color: #FFBBBB;
	}
	.nox_account_desktop_tb_mc_block_content_head_title svg {
		margin: 0 5px 0 0;
		font-size: 10px;
	}
	.nox_account_desktop_tb_mc_block_content_head_buttons {
		position: absolute;
		top: 0;
		right: 2px;
		height: 100%;
	}
	.nox_account_desktop_tb_mc_block_content_head_buttons button {
		margin: 0 5px 0 0;
		border-radius: 5px;
		background: #606060B3;
		font-size: 12px;
		width: 26px;
		height: 26px;
	}
	.nox_account_desktop_tb_mc_block_content_body {
		padding: 20px;
	}
	.nox_account_desktop_tb_mc_block_content_body img {
		border: 2px solid #777777;
		border-radius: 10px;
		box-shadow: 0 0 5px #555555;
		background: #000000;
		max-width: 300px;
	}
	.nox_account_desktop_tb_mc_block_content_body .caption {
		max-width: 300px;
	}
	.nox_account_desktop_tb_mc_keyboard {
		position: relative;
		margin: 0 15px;
		padding: 15px;
		border: 1px solid #999999;
		border-radius: 0 0 5px 5px;
		border-top: none;
		background: #505050B3;
	}
	.nox_account_desktop_tb_mc_keyboard_row {
		position: relative;
		margin: 2px 0;
		padding: 2px 38px 2px 2px;
		border-radius: 5px;
		background: #606060B3;
		display: flex;
		min-height: 38px;
	}
	.nox_account_desktop_tb_mc_keyboard_row.draggable {
		padding: 2px;
	}
	.nox_account_desktop_tb_mc_keyboard_row_add {
		margin: 8px 0 0 0;
		text-align: right;
	}
	.nox_account_desktop_tb_mc_keyboard_rows_tools {
		position: absolute;
		top: 13px;
		right: 17px;
		width: 34px;
	}
	.nox_account_desktop_tb_mc_keyboard_rows_tools_buttons {
		float: right;
		margin: 6px 0 0 0;
		padding: 2px 1px;
		border: 1px solid #999999;
		border-radius: 5px;
		background: #505050;
		width: 34px;
		height: 34px;
	}
	.nox_account_desktop_tb_mc_keyboard_rows_tools_buttons.show {
		width: 94px;
	}
	.nox_account_desktop_tb_mc_keyboard_rows_tools_buttons button {
		margin: 0 1px;
		border: 1px solid #999999;
		border-radius: 3px;
		background: #505050;
		line-height: 25px;
		display: none;
		width: 28px;
		height: 28px;
	}
	.nox_account_desktop_tb_mc_keyboard_rows_tools_buttons button svg {
		font-size: 12px;
	}
	.nox_account_desktop_tb_mc_keyboard_rows_tools_buttons.show button,
	.nox_account_desktop_tb_mc_keyboard_rows_tools_buttons button:last-child {
		display: inline-block;
	}
	.nox_account_desktop_tb_mc_keyboard_cell {
		position: relative;
		margin: 0 2px 0 0;
		padding: 0 34px 0 0;
		border: 1px solid #999999;
		border-radius: 5px;
		background: #606060B3;
		overflow: hidden;
		text-align: center;
		line-height: 34px;
		width: 100%;
		height: 34px;
	}
	.nox_account_desktop_tb_mc_keyboard_cell:last-child {
		margin: 0;
	}
	.nox_account_desktop_tb_mc_keyboard_cell.draggable {
		padding: 0;
	}
	.nox_account_desktop_tb_mc_keyboard_cell.draggable .nox_account_desktop_tb_mc_keyboard_cell_name {
		right: 0;
	}
	.nox_account_desktop_tb_mc_keyboard_cell button {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -17px 0 0 -17px;
		border-left: 1px solid #999999;
		border-radius: 5px;
		font-size: 11px;
		width: 34px;
		height: 34px;
	}
	.nox_account_desktop_tb_mc_keyboard_cell button {
		left: 100%;
		margin: -17px 0 0 -34px;
	}
	.nox_account_desktop_tb_mc_keyboard_cell_name {
		position: absolute;
		top: 0;
		left: 0;
		right: 34px;
		bottom: 0;
		padding: 0 10px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		line-height: 32px;
	}
	.nox_account_desktop_tb button.green {
		color: #ABFFAB;
	}
	.nox_account_desktop_tb button:hover,
	.nox_account_desktop_tb_mc_keyboard_cell_name:hover {
		opacity: 0.8;
	}
	.nox_account_desktop_tb_ar_answers tr th:nth-child(1),
	.nox_account_desktop_tb_ar_answers tr td:nth-child(1),
	.nox_account_desktop_tb_ar_answers tr th:nth-child(4),
	.nox_account_desktop_tb_ar_answers tr td:nth-child(4),
	.nox_account_desktop_tb_ar_answers tr th:nth-child(5),
	.nox_account_desktop_tb_ar_answers tr td:nth-child(5),
	.nox_account_desktop_tb_ar_questions tr th:nth-child(1),
	.nox_account_desktop_tb_ar_questions tr td:nth-child(1),
	.nox_account_desktop_tb_ar_questions tr th:nth-child(5),
	.nox_account_desktop_tb_ar_questions tr td:nth-child(5),
	.nox_account_desktop_tb_ar_questions tr th:nth-child(6),
	.nox_account_desktop_tb_ar_questions tr td:nth-child(6),
	.nox_account_desktop_tb_ar_templates tr th:nth-child(1),
	.nox_account_desktop_tb_ar_templates tr td:nth-child(1),
	.nox_account_desktop_tb_ar_templates tr th:nth-child(2),
	.nox_account_desktop_tb_ar_templates tr td:nth-child(2),
	.nox_account_desktop_tb_ar_templates tr th:nth-child(4),
	.nox_account_desktop_tb_ar_templates tr td:nth-child(4),
	.nox_account_desktop_tb_ar_templates tr th:nth-child(6),
	.nox_account_desktop_tb_ar_templates tr td:nth-child(6),
	.nox_account_desktop_tb_ar_templates tr th:nth-child(7),
	.nox_account_desktop_tb_ar_templates tr td:nth-child(7) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_tb_ar_answers tr th:nth-child(2),
	.nox_account_desktop_tb_ar_answers tr td:nth-child(2),
	.nox_account_desktop_tb_ar_questions tr th:nth-child(3),
	.nox_account_desktop_tb_ar_questions tr td:nth-child(3),
	.nox_account_desktop_tb_ar_templates tr th:nth-child(3),
	.nox_account_desktop_tb_ar_templates tr td:nth-child(3) {
		word-break: break-all;
		word-break: break-word;
		text-align: left;
		width: 100%;
	}
	.nox_account_desktop_tb_ar_answers tr th:nth-child(3),
	.nox_account_desktop_tb_ar_answers tr td:nth-child(3),
	.nox_account_desktop_tb_ar_questions tr th:nth-child(2),
	.nox_account_desktop_tb_ar_questions tr td:nth-child(2),
	.nox_account_desktop_tb_ar_questions tr th:nth-child(4),
	.nox_account_desktop_tb_ar_questions tr td:nth-child(4),
	.nox_account_desktop_tb_ar_templates tr th:nth-child(5),
	.nox_account_desktop_tb_ar_templates tr td:nth-child(5) {
		min-width: 170px;
	}
	.nox_account_desktop_tb_ar_answers_item.active,
	.nox_account_desktop_tb_ar_questions_item.incorrect {
		background: #FF0000B3;
	}
	.nox_account_desktop_tb_ar_templates tr td:nth-child(2) {
		cursor: pointer;
	}
	.nox_account_desktop_tb_ar_templates tr td:nth-child(2):hover {
		opacity: 0.8;
	}
</style>
