<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_loading" :class="{ progress: noxIsEditorLoadingProgress, hide: !$store.state.noxIsLoading }"><span v-if="noxIsEditorLoadingProgress">{{ noxEditorLoadingProgress }}%</span></div>
		<div class="nox_editor" :class="{ hide: $store.state.noxIsLoading }">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxEditorLoadingProgress: 0,
			noxIsEditorLoadingProgress: false
		}),
		methods: {
			isReady: function() {
				this.noxIsEditorLoadingProgress = (this.$route.path.indexOf('/editor/landing') >= 0 ? true : false);
				this.axios('getEditorData');
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getEditorData') {
						_this.$store.state.noxIsEditor = true;
						_this.$store.state.noxIsLoading = true;
						config.url = '/v2/editor';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getEditorData') {
								_this.$parent.goToTop(true);
								_this.$parent.checkAppVersion(data.headers);
							}
						}
					}).catch(function() {
						_this.$parent.exit();
					}).finally(function() {
						_this.$store.state.noxIsEditor = false;
					});
				}
				else {
					_this.$parent.exit();
				}
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_loading.progress:before {
		content: '';
	}
	.nox_editor {
		position: relative;
		color: #FFFFFF;
		width: 100%;
		height: 100%;
	}
</style>
