<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_team">
				<div class="nox_account_desktop_team_intro">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.10', 0)"></div>
						<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.10', 1)"></div>
					</div>
				</div>
				<div class="nox_account_desktop_team_content" v-if="noxTeamData.length && ((noxTeamData[0] !== undefined && noxTeamData[0].data.length) || (noxTeamData[1] !== undefined && noxTeamData[1].data.length))">
					<div class="nox_account_desktop_team_content_accordion">
						<div class="nox_account_desktop_team_content_accordion_item" v-for="(value, index) in noxTeamData" :key="index" v-if="value.data.length">
							<div class="nox_account_desktop_team_content_accordion_item_head" @click="$set(noxIsTeamDataAccordion, index, (noxIsTeamDataAccordion[index] == 1 ? 0 : 1))">
								<div class="nox_account_desktop_team_content_accordion_item_head_toggle">
									<font-awesome-icon :icon="['fas', 'angle-up']" v-if="noxIsTeamDataAccordion[index] == 1" />
									<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
								</div>
								<div class="nox_account_desktop_team_content_accordion_item_head_title" v-html="$store.getters.getLanguageText('3.10', 3, { options: [(index > 1 ? 2 : index)], level: index })"></div>
							</div>
							<div class="nox_account_desktop_team_content_accordion_item_body" :class="{ hide: !(noxIsTeamDataAccordion[index] == 1) }">
								<div class="nox_account_desktop_team_content_accordion_item_body_data">
									<div class="nox_account_desktop_team_content_accordion_item_body_data_block" v-for="(value2, index2) in value.data" :key="index2">
										<div class="nox_account_desktop_team_content_accordion_item_body_data_block_path"></div>
										<div class="nox_account_desktop_team_content_accordion_item_body_data_block_item" :class="[(index ? (!value2.is_subscription ? 'item_red' : (value2.group == 4 ? 'item_grey' : (!value2.is_qualification ? 'item_orange' : ''))) : '')]">
											<div class="nox_account_desktop_team_content_accordion_item_body_data_block_item_number">{{ (index2 + 1) }}</div>
											<div class="nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar">
												<router-link :to="'/account/client_program/' + value2.key" v-if="value2.key"><div class="nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_img"><img :src="$parent.$parent.getAvatarByLink(value2.avatar)"></div></router-link>
												<div class="nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_img" v-else><img :src="$parent.$parent.getAvatarByLink(value2.avatar)"></div>
												<div class="nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_status" :style="{ background: '#' + $store.state.noxSystemSettings['user_status_color_' + value2.status] }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 117, { status: $store.getters.getLanguageText('4.3.1', value2.status), count: $store.state.noxSystemSettings['user_status_cycles_' + value2.status] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="index && value2.status">{{ $store.state.noxSystemSettings['user_status_name_' + value2.status] }}</div>
											</div>
											<div class="nox_account_desktop_team_content_accordion_item_body_data_block_item_status" v-html="$store.getters.getLanguageText('3.5.1', (index ? (!value2.is_subscription ? 3 : (value2.group == 4 ? 4 : (value2.is_qualification ? 1 : 2))) : 8))"></div>
											<div class="nox_account_desktop_team_content_accordion_item_body_data_block_item_table" :class="[(index ? 'table_type_1' : 'table_type_2')]">
												<table class="nox_table">
													<thead>
														<tr>
															<th v-html="$store.getters.getLanguageText('1.7.1', 13)" v-if="index >= 0"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 15)" v-if="index >= 0"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 71)" v-if="index >= 2"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 16)" v-if="index >= 1"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 67)" v-if="index >= 0"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 17)" v-if="index >= 1"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 59)" v-if="index <= 0"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 18)" v-if="index == 1"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 25)" v-if="index <= 0"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 19)" v-if="index >= 1"></th>
															<th v-html="$store.getters.getLanguageText('1.7.1', 60)" v-if="index <= 0"></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 13) + ':'" v-if="index >= 0">{{ value2.id }}</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 14) + ':'" v-if="index >= 0"><router-link :to="'/account/client_program/' + value2.key" v-if="value2.key">{{ (value2.username ? value2.username : '-') }}</router-link><span v-else>{{ (value2.username ? value2.username : '-') }}</span></td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 71) + ':'" v-if="index >= 2">{{ value2.sponsor_id }}</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 68) + ':'" v-if="index >= 1">{{ $store.state.noxSystemSettings['package_name_x' + value2.package] }}</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 67) + ':'" v-if="index >= 0"><span :class="[(!value2.count_of_completed_quests ? 'sticker sticker_r_w' : '')]">{{ (value2.count_of_completed_quests > $store.state.noxSystemSettings.count_of_available_quests ? $store.state.noxSystemSettings.count_of_available_quests : value2.count_of_completed_quests) }} / {{ $store.state.noxSystemSettings.count_of_available_quests }}</span></td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 17) + ':'" v-if="index >= 1">
																<span :class="[(((value2.package_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)) < 14 ? 'sticker sticker_r_w' : '')]" v-if="value2.package_at > $store.state.noxSystemSettings.time">
																	<span v-if="((value2.package_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)) > 1" v-html="$store.getters.getLanguageText('1.7.2', 11, { count: Math.floor((value2.package_at - $store.state.noxSystemSettings.time) / (24 * 60 * 60)) })"></span>
																	<span v-else-if="((value2.package_at - $store.state.noxSystemSettings.time) / (60 * 60)) > 1" v-html="$store.getters.getLanguageText('1.7.2', 12, { count: Math.floor((value2.package_at - $store.state.noxSystemSettings.time) / (60 * 60)) })"></span>
																	<span v-else v-html="$store.getters.getLanguageText('1.7.2', 13)"></span>
																</span>
																<span class="sticker sticker_w_r" v-else v-html="$store.getters.getLanguageText('1.7.2', 10)"></span>
															</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 59) + ':'" v-if="index <= 0">{{ value2.country }} ({{ noxTeamDataCountries[value2.country] }})</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 69) + ':'" v-if="index == 1">{{ value2.count_start_bots }}</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 25) + ':'" v-if="index <= 0">{{ (value2.phone ? '+' + value2.phone : '') }}</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 70) + ':'" v-if="index >= 1">
																<span :class="{ count_of_invited_users: value2.count_of_invited_users }">{{ value2.count_of_invited_users }}</span>
																<button type="button" class="nox_button micro white" @click="$modal.show('NoxModalTeam', { type: 'getTeamInvitedUsers', id: value2.id })" v-if="value2.count_of_invited_users"><font-awesome-icon :icon="['fas', 'eye']" /><span v-html="$store.getters.getLanguageText('1.1', 130)"></span></button>
															</td>
															<td :data-label="$store.getters.getLanguageText('1.7.1', 60) + ':'" v-if="index <= 0">{{ (value2.created_at ? new Date(value2.created_at * 1000).toLocaleString($store.state.noxLanguage) : '') }}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div v-if="value.limit_offset">
										<div class="nox_account_desktop_page_content_loading" :class="{ hide: !(noxIsPageContentLoadings[index] == 1) }"><img src="@/assets/images/loading.gif"></div>
										<div class="nox_account_desktop_page_content_buttons" :class="{ hide: (noxIsPageContentLoadings[index] == 1) }">
											<button type="button" class="nox_button big" @click="axios('getTeamData', index)"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="noxTeamDataLimitOffset">
							<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
								<button type="button" class="nox_button big" @click="axios('getTeamData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_team_content" v-else>
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.10', 2)"></div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalTeam"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTeamData: [],
			noxTeamDataTableOffset: 0,
			noxTeamDataLimitOffset: 0,
			noxTeamDataLimitCount: 10,
			noxTeamDataCountries: {},
			noxIsTeamDataAccordion: {},
			noxIsPageContentLoadings: {},
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$parent.$parent.checkAccessToNoxBusiness()) {
					this.noxTeamDataCountries = this.$parent.$parent.getCountries();
					this.axios('getTeamData');
				}
				else {
					this.$router.push({ path: '/account/tariffs' });
				}
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getTeamData') {
						if (var1 !== undefined) {
							_this.$set(_this.noxIsPageContentLoadings, var1, 1);
							config.url = '/v2/account/users/team/data';
							config.params = { type: var1, tableOffset: _this.noxTeamData[var1].table_offset, limitOffset: _this.noxTeamData[var1].limit_offset, limitCount: _this.noxTeamData[var1].limit_count };
						}
						else {
							if (!_this.noxTeamDataLimitOffset) {
								_this.noxIsLoading = true;
								_this.$store.state.noxIsAccount = true;
							}
							_this.noxIsPageContentLoading = true;
							config.url = '/v2/account/users/team';
							config.params = { tableOffset: _this.noxTeamDataTableOffset, limitOffset: _this.noxTeamDataLimitOffset, limitCount: _this.noxTeamDataLimitCount };
						}
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getTeamData') {
								if (var1 !== undefined) {
									if (data.data.data.length) {
										for (var i in data.data.data) {
											_this.noxTeamData[var1].data.push(data.data.data[i]);
										}
									}
									_this.noxTeamData[var1].table_offset = data.data.info.max_id;
									_this.noxTeamData[var1].limit_offset = data.data.info.count > _this.noxTeamData[var1].limit_offset + _this.noxTeamData[var1].limit_count ? _this.noxTeamData[var1].limit_offset + _this.noxTeamData[var1].limit_count : 0;
								}
								else {
									_this.$parent.$parent.goToTop(!_this.noxTeamDataLimitOffset ? true : false);
									_this.$parent.$parent.checkAppVersion(data.headers);
									if (data.data.team_data !== undefined) {
										if (data.data.team_data.list.length) {
											for (var a in data.data.team_data.list) {
												var index = Number(a) + Number(_this.noxTeamDataLimitOffset);
												_this.noxTeamData[index] = { data: [], table_offset: 0, limit_offset: 0, limit_count: 10 };
												_this.noxTeamData[index].table_offset = data.data.team_data.list[a].info.max_id;
												_this.noxTeamData[index].limit_offset = data.data.team_data.list[a].info.count > _this.noxTeamData[index].limit_offset + _this.noxTeamData[index].limit_count ? _this.noxTeamData[index].limit_offset + _this.noxTeamData[index].limit_count : 0;
												if (data.data.team_data.list[a].data.length) {
													for (var b in data.data.team_data.list[a].data) {
														_this.noxTeamData[index].data.push(data.data.team_data.list[a].data[b]);
													}
												}
											}
											if (_this.noxTeamData.length) { _this.$set(_this.noxIsTeamDataAccordion, ((_this.noxTeamData[1] !== undefined && _this.noxTeamData[1].data.length) ? 1 : 0), 1); }
										}
										_this.noxTeamDataLimitOffset = data.data.team_data.count > _this.noxTeamDataLimitOffset + _this.noxTeamDataLimitCount ? _this.noxTeamDataLimitOffset + _this.noxTeamDataLimitCount : 0;
									}
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPageContentLoadings = {};
						_this.noxIsPageContentLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalTeam: () => import('@/views/modal_components/NoxModalTeam.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_team_content_accordion_item {
		margin: 5px 0 0 0;
	}
	.nox_account_desktop_team_content_accordion_item_head {
		position: relative;
		padding: 10px 20px 10px 50px;
		border: 1px solid #999999;
		border-radius: 10px;
		background: #555555;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		font-size: 12px;
		line-height: 20px;
		min-height: 40px;
		z-index: 1;
	}
	.nox_account_desktop_team_content_accordion_item_head_toggle {
		position: absolute;
		top: 20px;
		left: 20px;
		margin: -10px 0 0 0;
		font-size: 20px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block {
		position: relative;
		padding: 20px 0 0 90px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block:last-child {
		margin: 0 0 20px 0;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block:first-child .nox_account_desktop_team_content_accordion_item_body_data_block_path {
		top: 0;
		margin: 0;
		height: calc(50% + 12px);
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_path {
		position: absolute;
		top: -50%;
		left: 27px;
		margin: -8px 0 0 0;
		border: 1px solid #999999;
		border-top: none;
		border-right: none;
		display: table;
		width: 63px;
		height: calc(100% + 20px);
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item {
		position: relative;
		padding: 8px 10px 10px 30px;
		border-radius: 15px;
		background: #333333B3;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 22px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_grey {
		background: #CCCCCC99;
		color: #555555;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_orange {
		background: #CC840099;
		color: #FFFFFF;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_red {
		background: #FF000080;
		color: #FFFFFF;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_number {
		position: absolute;
		top: 50%;
		left: -30px;
		margin: -30px 0 0 0;
		border: 1px solid #FFFFFF;
		border-radius: 50%;
		background: #6F6F6F;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 20px;
		width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_grey .nox_account_desktop_team_content_accordion_item_body_data_block_item_number {
		background: #E1E1E1;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_orange .nox_account_desktop_team_content_accordion_item_body_data_block_item_number {
		background: #E1B673;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_red .nox_account_desktop_team_content_accordion_item_body_data_block_item_number {
		background: #FF848B;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_img {
		position: absolute;
		top: 50%;
		left: -30px;
		margin: -30px 0 0 0;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #6F6F6F;
		overflow: hidden;
		width: 60px;
		height: 60px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_grey .nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_img {
		box-shadow: 0 0 2px #E1E1E1;
		background: #E1E1E1;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_orange .nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_img {
		box-shadow: 0 0 2px #E1B673;
		background: #E1B673;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_red .nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_img {
		box-shadow: 0 0 2px #FF848B;
		background: #FF848B;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_status {
		position: absolute;
		top: 50%;
		left: 0;
		margin: -30px 0 0 8px;
		padding: 0 5px;
		border: 1px solid #FFFFFF;
		border-radius: 11px;
		background: #B8C3CA;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 20px;
		min-width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_status {
		position: absolute;
		top: 50%;
		left: 0;
		margin: 18px 0 0 -50px;
		border: 1px solid #FFFFFF;
		border-radius: 18px;
		background: #6F6F6F;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 8px;
		line-height: 16px;
		width: 100px;
		height: 20px;
		z-index: 1;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_grey .nox_account_desktop_team_content_accordion_item_body_data_block_item_status {
		background: #E1E1E1;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_orange .nox_account_desktop_team_content_accordion_item_body_data_block_item_status {
		background: #E1B673;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_red .nox_account_desktop_team_content_accordion_item_body_data_block_item_status {
		background: #FF848B;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr th,
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td {
		padding: 0 5px;
		border-color: transparent;
		font-family: 'OpenSansRegular', Arial, sans-serif;
		font-size: 10px;
		min-width: 100px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table.table_type_1 .nox_table tr th:nth-child(n + 2),
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table.table_type_1 .nox_table tr td:nth-child(n + 2) {
		width: 15%;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table.table_type_2 .nox_table tr th:nth-child(n + 2),
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table.table_type_2 .nox_table tr td:nth-child(n + 2) {
		width: 18%;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr th {
		padding: 0 5px 3px 5px;
		line-height: 10px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 18px;
		min-height: 18px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td .sticker {
		padding: 0 10px;
		border-radius: 10px;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 10px;
		line-height: 18px;
		display: inline-block;
		height: 18px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td .sticker.sticker_r_w {
		background: #FF4040;
		color: #FFFFFF;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td .sticker.sticker_w_r {
		background: #FFFFFF;
		color: #FF6666;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td .count_of_invited_users {
		position: relative;
		top: 1px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td .nox_button {
		margin: 0 0 0 8px;
	}
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_grey .nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr th,
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_grey .nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td,
	.nox_account_desktop_team_content_accordion_item_body_data_block_item.item_grey .nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td a {
		color: #555555;
	}

	@media (max-width: 1200px) {
		.nox_account_desktop_team_content_accordion_item_body_data_block {
			padding: 5px 0 0 90px;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block:first-child {
			padding: 20px 0 0 90px;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_path {
			margin: -15px 0 0 0;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item {
			padding: 10px 10px 10px 60px;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table {
			margin: 0;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr {
			border-color: transparent;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td {
			font-family: 'OpenSansRegular', Arial, sans-serif;
			font-size: 10px;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_table .nox_table tr td .sticker {
			line-height: 16px;
			height: 16px;
		}
	}
	@media (max-width: 450px) {
		.nox_account_desktop_team_content_accordion_item_body_data_block,
		.nox_account_desktop_team_content_accordion_item_body_data_block:first-child {
			padding: 5px 0 0 0;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_path {
			display: none;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item {
			margin: 70px 0 0 0;
			padding: 10px;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_number,
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_img,
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_status {
			top: 0;
			left: 50%;
			margin: -56px 0 0 -30px;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_avatar_status {
			margin: -56px 0 0 8px;
		}
		.nox_account_desktop_team_content_accordion_item_body_data_block_item_status {
			top: -10px;
			left: 50%;
			margin: 0 0 0 -50px;
		}
	}
</style>
