<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_stats">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.3', 0)"></div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_portlet_content_img_200">
							<img src="@/assets/images/bot_img_10.png">
							<div v-html="$store.getters.getLanguageText('3.3', 1)"></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_navigation">
					<div class="nox_account_desktop_page_content_navigation_filters">
						<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
						<div class="nox_account_desktop_page_content_navigation_filters_block col2">
							<div class="nox_account_desktop_page_content_navigation_filters_select" :class="{ not_selected: noxStatsFilter1 == null || noxStatsFilter1 == '' }"><v-select class="nox_select style-chooser" v-model.trim="noxStatsFilter1" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxStatsFilter1Options" @input="filterStatsData($event, 1)"></v-select></div>
						</div>
						<div class="nox_account_desktop_page_content_navigation_filters_block col2">
							<div class="nox_account_desktop_page_content_navigation_filters_select last" :class="{ not_selected: noxStatsFilter2 == null || noxStatsFilter2 == '' }" v-if="noxStatsFilter1 == null || Number(noxStatsFilter1.code) !== 3"><v-select class="nox_select style-chooser" v-model.trim="noxStatsFilter2" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="$parent.$parent.getSelectOptions(2)" @input="filterStatsData($event, 2)"></v-select></div>
							<div class="nox_account_desktop_page_content_navigation_filters_select last" :class="{ not_selected: noxStatsFilter3 == null || noxStatsFilter3 == '' }" v-else><v-select class="nox_select style-chooser" v-model.trim="noxStatsFilter3" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxStatsFilterStrategies" @input="filterStatsData($event, 3)"></v-select></div>
						</div>
					</div>
					<div class="nox_account_desktop_page_content_navigation_filters">
						<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 9)"></div>
						<div class="nox_account_desktop_page_content_navigation_filters_block">
							<div class="nox_account_desktop_page_content_navigation_filters_checkboxes">
								<label class="nox_checkbox" for="nox_account_desktop_stats_is_show_demo_account" v-if="$store.state.noxAccountData.group == 1">
									<input type="checkbox" id="nox_account_desktop_stats_is_show_demo_account" v-model.trim="noxIsStatsShowDemoAccount" @change="toggleChangeForIsStatsShowDemoAccount">
									<span class="nox_checkbox_mark"></span>
									<span class="nox_checkbox_text" v-html="$store.getters.getLanguageText('1.8.5', 0)"></span>
								</label>
								<label class="nox_checkbox" for="nox_account_desktop_stats_is_hide_locked_account">
									<input type="checkbox" id="nox_account_desktop_stats_is_hide_locked_account" v-model.trim="noxIsStatsHideLockedAccount" @change="toggleChangeForIsStatsHideLockedAccount">
									<span class="nox_checkbox_mark"></span>
									<span class="nox_checkbox_text" v-html="$store.getters.getLanguageText('1.8.5', 1)"></span>
								</label>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_page_content_navigation_search">
						<input type="text" class="nox_input" v-model.number="noxStatsSearch" @keyup.13="searchStatsData" :placeholder="$store.getters.getLanguageText('1.8', 4)" autocomplete="off">
						<div class="nox_account_desktop_page_content_navigation_search_button" @click="searchStatsData"><font-awesome-icon :icon="['fas', 'search']" /></div>
						<div class="nox_account_desktop_page_content_navigation_search_result" v-show="noxStatsSearchResult.length || noxIsStatsSearchLoading">
							<div class="nox_account_desktop_page_content_navigation_search_result_block">
								<div class="nox_account_desktop_page_content_navigation_search_result_loading" :class="{ hide: !noxIsStatsSearchLoading }"><span v-html="$store.getters.getLanguageText('1.8', 0)"></span>&#160;<img src="@/assets/images/loading.gif"></div>
								<div class="nox_account_desktop_page_content_navigation_search_result_content" :class="{ hide: noxIsStatsSearchLoading }">
									<span class="nox_account_desktop_page_content_navigation_search_result_content_link" v-if="noxStatsSearchResult[0] && Object.keys(noxStatsSearchResult[0]).length" @click="getStatsData(noxStatsSearchResult[0].bot_key)" v-html="$store.getters.getLanguageText('1.8', 6, { id: noxStatsSearchResult[0].bot_id })"></span>
									<span v-else v-html="$store.getters.getLanguageText('1.8', 5)"></span>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_page_content_navigation_search_background" :class="{ hide: !noxStatsSearchResult.length && !noxIsStatsSearchLoading }" @click="searchStatsDataClose"></div>
					</div>
				</div>
				<div v-if="noxStatsData.length">
					<div class="nox_account_desktop_portlet_wrapper" v-for="(value, index) in noxStatsData" :key="index">
						<div class="nox_account_desktop_portlet" :class="{ red: value.data.is_deleted }">
							<div class="nox_account_desktop_portlet_title">
								<span v-html="$store.getters.getLanguageText('3.3', 2, { id: value.data.id })"></span>
								<span v-if="$store.state.noxAccountData.group == 1 && (value.data.type_account == 1 || value.data.is_nox_demo_bot)">&#160;<span v-html="$store.getters.getLanguageText('3.3', 3)"></span></span>
								<span v-if="$store.state.noxAccountData.group == 1 && value.data.is_deleted">&#160;<span v-html="$store.getters.getLanguageText('3.3', 19)"></span></span>
							</div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_sdata">
									<div class="nox_account_desktop_bot_settings" :class="{ phoenix_settings: value.data.strategy_type == 2 }">
										<div class="nox_account_desktop_bot_settings_left">
											<div class="nox_account_desktop_bot_settings_cell">
												<div class="nox_account_desktop_bot_settings_logo" :class="{ bot_settings_logo_hide: value.is_shows.is_show_for_bot_prints }">
													<div class="nox_account_desktop_bot_settings_risk">
														<div class="bot_risk_phoenix" v-if="value.data.strategy_type == 2 && value.data.version[0] == 3">
															<img src="@/assets/images/bot_risk_phoenix_v3.png"><div>Phoenix PRO</div>
														</div>
														<div class="bot_risk_phoenix" v-else-if="value.data.strategy_type == 2">
															<img src="@/assets/images/bot_risk_phoenix_v2.png"><div>Phoenix</div>
														</div>
														<div class="bot_risk_insomnia" v-else-if="value.data.is_insomnia">
															<img src="@/assets/images/bot_risk_insomnia.png"><div>Insomnia</div>
														</div>
														<div class="bot_risk_1" v-else-if="value.info.risk_id == 1">
															<img src="@/assets/images/bot_risk_1.png"><div>Safe</div>
														</div>
														<div class="bot_risk_2" v-else-if="value.info.risk_id == 2">
															<img src="@/assets/images/bot_risk_2.png"><div>Medium</div>
														</div>
														<div class="bot_risk_3" v-else-if="value.info.risk_id == 3">
															<img src="@/assets/images/bot_risk_3.png"><div>Aggressive</div>
														</div>
														<div class="bot_risk_4" v-else-if="value.info.risk_id == 4">
															<img src="@/assets/images/bot_risk_4.png"><div>Insane</div>
														</div>
														<div class="bot_risk_0" v-else>
															<img src="@/assets/images/bot_risk_0.png"><div>Undefined</div>
														</div>
													</div>
													<div class="nox_account_desktop_bot_settings_stickers" v-if="value.data.strategy_type == 1">
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_gap">
															<div class="sticker_is_gap" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 56, { id: value.data.id }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">GAP</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_alien">
															<div class="sticker_is_alien" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 3), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">ALIEN</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.online_at">
															<div class="sticker_online" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 0), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at + (70 * 60) > $store.state.noxSystemSettings.time">ONLINE</div>
															<div class="sticker_offline" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 1), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-else>OFFLINE</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_use_insomnia">
															<div class="sticker_is_use_insomnia" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 45), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">INSOMNIA</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.online_at && value.data.status && value.data.withdrawals > 0 && (value.data.balance - value.data.deposits + value.data.withdrawals) > 0 && value.data.withdrawals > (value.data.balance - value.data.deposits + value.data.withdrawals)">
															<div class="sticker_overload" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 11), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">OVERLOAD</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.online_at && (value.data.version !== $store.state.noxSystemSettings[(value.data.strategy_type == 2 ? 'bot_version_phoenix' : 'bot_version')])">
															<div class="sticker_old_version" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 2), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">OLD VERSION</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_perk_free_bot">
															<div class="sticker_is_perk" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 53), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">PERK BOT</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_perk_deposit_limit">
															<div class="sticker_is_perk" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 54, { value: $store.state.noxSystemSettings.perk_bonus_2 }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">PERK +{{ $store.state.noxSystemSettings.perk_bonus_2 }}%</div>
														</div>
														<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_perk_insomnia">
															<div class="sticker_is_perk" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 55), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">PERK INSOMNIA</div>
														</div>
													</div>
												</div>
												<div class="nox_account_desktop_bot_settings_prints" :class="{ bot_settings_prints_hide: !value.is_shows.is_show_for_bot_prints }" v-if="value.data.strategy_type == 2">
													<div class="nox_account_desktop_bot_settings_prints_content">
														<div class="nox_account_desktop_bot_settings_prints_content_block">
															<div class="nox_account_desktop_bot_settings_prints_content_button" :class="'bot_settings_prints_count_' + $parent.$parent.getPrintsCount(value.data)" @click="value.is_shows.is_show_for_bot_prints = !value.is_shows.is_show_for_bot_prints"><font-awesome-icon :icon="['fas', (value.is_shows.is_show_for_bot_prints ? 'angle-double-down' : 'angle-double-up')]" /></div>
															<div class="nox_account_desktop_bot_settings_prints_content_items">
																<div class="nox_account_desktop_bot_settings_prints_content_item group_1_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 0), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.online_at + (70 * 60) >= $store.state.noxSystemSettings.time"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_2_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 1), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.online_at + (70 * 60) < $store.state.noxSystemSettings.time"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_2_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 3), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_alien"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_2_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 56, { id: value.data.id }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_gap"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_2_4" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 2), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.strategy_type && (value.data.version !== $store.state.noxSystemSettings[(value.data.strategy_type == 2 ? 'bot_version_phoenix' : 'bot_version')])"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_2_5" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 11), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.status && value.data.withdrawals > 0 && (value.data.balance - value.data.deposits + value.data.withdrawals) > 0 && value.data.withdrawals > (value.data.balance - value.data.deposits + value.data.withdrawals)"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item" :class="[(value.data.strategy_settings.ac == 3 ? 'group_2_7' : 'group_2_6')]" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', (value.data.strategy_settings.ac == 3 ? 217 : 212)), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_locked_currency_pairs"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_2_8" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 222, { min_lot: value.data.min_lot }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.min_lot > 0.01"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_3_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 175), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.u && value.data.strategy_settings.u == 'BO'"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_3_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 45), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_use_insomnia"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_3_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 159), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.t == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_3_4" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 160), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.u && !['OFF', 'BO'].includes(value.data.strategy_settings.u)"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_3_5" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 173), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.y == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_3_6" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 218), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_compound_interest"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_4_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 53), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_perk_free_bot"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_4_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 54, { value: $store.state.noxSystemSettings.perk_bonus_2 }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_perk_deposit_limit"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_4_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 55), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_perk_insomnia"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 130), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.behavior == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 131), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.behavior == 2"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 132), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.behavior == 3"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_4" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 133), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.k == 0"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_5" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 134), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.k == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_6" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 135), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.k == 2"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_27" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 205), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_nox_demo_bot"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_7" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 136), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.j >= 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_8" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 137), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.m == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_9" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 138), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.p == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_10" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 139), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.a == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_11" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 140), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.c == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_12" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 141), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.f == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_13" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 142), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.d == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_14" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 143), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.h == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_15" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 144), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.l == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_16" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 145), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.n == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_17" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 146), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.o == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_18" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 147), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.r == 0"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_19" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 148), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.r == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_20" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 164), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.r == 2"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_21" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 170), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.w == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_22" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 171), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.x == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_23" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 177), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="!value.data.is_compound_interest && value.data.funding_limit"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_24" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 178), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.purpose_of_account"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_25" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 186), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.z == 1"></div>
																<div class="nox_account_desktop_bot_settings_prints_content_item group_5_26" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 187), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.aa == 1"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_right">
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 4)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 12), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ new Date(value.data.start_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 5)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 13), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ value.data.count_of_working_days }} <span v-html="$store.getters.getLanguageText('1.9', 2, { options: [$parent.$parent.getDeclension(value.data.count_of_working_days)] })"></span></div>
											</div>
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 6)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 14), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<div class="nox_account_desktop_bot_settings_value_content">
														<span>{{ noxStatsDataStrategies[value.data.strategy_id].name }}</span>
														<span class="nox_account_desktop_bot_settings_value_buttons margin_left_10" v-if="value.data.strategy_type == 2 && [1,2,10,90,100].includes($store.state.noxAccountData.id)">
															<button type="button" class="nox_button nox_is_desktop orange" @click="$modal.show('NoxModalBotSettings', { type: 'viewPhoenixSettings', index: index, id: value.data.id })" v-html="$store.getters.getLanguageText('1.1', 98)"></button>
															<button type="button" class="nox_button nox_is_mobile orange" @click="$modal.show('NoxModalBotSettings', { type: 'viewPhoenixSettings', index: index, id: value.data.id })"><font-awesome-icon :icon="['fas', 'cog']" /></button>
														</span>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_type == 1">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 7)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 15), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ value.data.multiplier.toFixed(1) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_type == 2">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 17)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 77), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value" v-html="$store.getters.getLanguageText('3.3', 18, { options: [value.data.behavior] })"></div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.count_of_working_days >= 5">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 9)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 17), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar((value.data.profit_for_week / 100), 2) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.count_of_working_days >= 22">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 10)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 18), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar((value.data.profit_for_month / 100), 2) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 11)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 19), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar(((value.data.balance - value.data.deposits + value.data.withdrawals) / 100), 2) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.count_of_working_days >= 35 && ((value.data.avg_percentage_of_daily_profit_for_365d * 30 * 12) >= 10)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 20)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 206), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value" v-html="$store.getters.getLanguageText('3.3', 21, { percent: $parent.$parent.getNumberFormatForPercent((value.data.avg_percentage_of_daily_profit_for_365d * 30 * 12), 2) })"></div>
											</div>
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 13)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 50), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar((value.data.deposits / 100), 2) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 14)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 51), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar((value.data.withdrawals / 100), 2) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.3', 15)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 52), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar((value.data.balance / 100), 2) }}</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="[1,2].includes($store.state.noxAccountData.group)">
												<div class="nox_account_desktop_bot_settings_label">
													<span>Подробные данные</span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Подробные данные</b> - дополнительные данные по боту, которые видит только администратор.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<div class="nox_account_desktop_bot_settings_value_content">
														<div :class="{ hide: value.is_shows.is_show_for_bot_admin_data }">
															<button type="button" class="nox_button nox_is_desktop last blue" @click="showAdminData(index)" v-html="$store.getters.getLanguageText('1.1', 130)"></button>
															<button type="button" class="nox_button nox_is_mobile last blue" @click="showAdminData(index)"><font-awesome-icon :icon="['fas', 'eye']" /></button>
														</div>
														<div :class="{ hide: !value.is_shows.is_show_for_bot_admin_data }">
															<span class="margin_right_10">{{ value.data }}</span>
															<button type="button" class="nox_button nox_is_desktop last blue" @click="hideAdminData(index)" v-html="$store.getters.getLanguageText('1.1', 131)"></button>
															<button type="button" class="nox_button nox_is_mobile last blue" @click="hideAdminData(index)"><font-awesome-icon :icon="['fas', 'eye-slash']" /></button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_buttons">
								<button type="button" class="nox_button big white" @click="$modal.show('NoxModalBotSettings', { type: 'sendBotMessage', index: index, id: value.data.id })" v-if="[1,2].includes($store.state.noxAccountData.group)"><font-awesome-icon :icon="['fas', 'envelope']" /><span v-html="$store.getters.getLanguageText('1.1', 178)"></span></button>
								<button type="button" class="nox_button big white_red" @click="$modal.show('NoxModalBotSettings', { type: 'deleteArchiveBot', index: index, id: value.data.id })" v-if="value.data.is_deleted && $store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'trash']" /><span v-html="$store.getters.getLanguageText('1.1', 161)"></span></button>
								<div class="nox_dropdown" :class="{ open: $store.state.noxDropdownId == ('bot_trade_history_' + value.data.id) }" @blur="$parent.$parent.closeDropdown($event)" tabindex="-1">
									<div class="nox_dropdown_toggle transparent_white" @click="$parent.$parent.toggleDropdown('bot_trade_history_' + value.data.id)">
										<div class="nox_dropdown_toggle_value"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 169)"></span></div>
										<div class="nox_dropdown_toggle_button"><font-awesome-icon :icon="['fas', 'chevron-' + ($store.state.noxDropdownId == ('bot_trade_history_' + value.data.id) ? 'up' : 'down')]" /></div>
									</div>
									<div class="nox_dropdown_options">
										<div class="nox_dropdown_options_item" @click="$modal.show('NoxModalBotOrders', { type: 'getBotOrders', index: index, id: value.data.id })" v-if="value.data.strategy_type == 2" v-html="$store.getters.getLanguageText('1.1', 168)"></div>
										<div class="nox_dropdown_options_item" @click="$modal.show('NoxModalBotSettings', { type: 'getBotLogs', index: index, id: value.data.id })" v-html="$store.getters.getLanguageText('1.1', 122)"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_accordion" :class="{ red: value.data.is_deleted }">
							<div class="nox_account_desktop_portlet_accordion_loading" :class="{ hide: !value.is_shows.is_show_for_bot_details }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_accordion_button" :class="{ hide: value.is_shows.is_show_for_bot_details }" @click="axios('getStatsDetails', index, value.data.id)" v-if="!Object.keys(value.details).length">&#9660;&#160;<span v-html="$store.getters.getLanguageText('1.1', 18)"></span>&#160;&#9660;</div>
							<div class="nox_account_desktop_portlet_accordion_block" v-else>
								<div class="nox_account_desktop_portlet_accordion_button" @click="hideStatsDetails(index)">&#9650;&#160;<span v-html="$store.getters.getLanguageText('1.1', 19)"></span>&#160;&#9650;</div>
								<div class="nox_account_desktop_portlet_accordion_block_content">
									<div class="nox_account_desktop_portlet_accordion_block_content_description">
										<a :href="$store.getters.getLanguageText('3.4.5', 2)" class="button" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" /><div v-html="$store.getters.getLanguageText('3.4.5', 0)"></div></a><span v-html="$store.getters.getLanguageText('3.4.5', 1)"></span>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_1.series[0].data.length || value.details.details_chart_1.series[1].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_1"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 22)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_12.series[0].data.length || value.details.details_chart_12.series[1].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_12"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 38)"></div>
									</div>
									<div class="nox_chart hide" :id="'noxStatsDetailsChart9' + index">
										<div class="nox_chart_buttons">
											<div :class="{ active: value.details.details_chart_9_options.type == 1 }" @click="getStatsDetailsChart9(index, 1)" v-html="$store.getters.getLanguageText('1.6', 40, { options: [0] })"></div>
											<div :class="{ active: value.details.details_chart_9_options.type == 2 }" @click="getStatsDetailsChart9(index, 2)" v-html="$store.getters.getLanguageText('1.6', 40, { options: [1] })"></div>
										</div>
										<div class="nox_chart_highcharts" :id="'noxStatsDetailsChart9Highchart' + index"></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 23)"></div>
									</div>
									<div class="nox_chart columns" v-if="value.details.details_chart_10.series[0].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_10"></highcharts></div>
										<div class="nox_chart_description"><div v-html="$store.getters.getLanguageText('1.6.1', 21)"></div></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_2.series[0].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_2"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 24)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_3.series[0].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_3"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 25)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_4.series[0].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_4"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 26)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_5.series[0].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_5"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 27)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_6.series[0].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_6"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 28)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_7.series.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_7"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 29)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_8.series[0].data.length || value.details.details_chart_8.series[1].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_8"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 30)"></div>
									</div>
									<div class="nox_chart" v-if="value.details.details_chart_11.series[0].data.length || value.details.details_chart_11.series[1].data.length">
										<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_11"></highcharts></div>
										<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 34)"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="noxStatsDataLimitOffset">
						<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
							<button type="button" class="nox_button big" @click="axios('getStatsData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-else>
					<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.3', 12)"></div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalRecaptcha"></div>
				<div is="NoxModalBotOrders"></div>
				<div is="NoxModalBotSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import Highcharts from 'highcharts'
	export default {
		data: () => ({
			noxStatsSearch: '',
			noxStatsSearchResult: [],
			noxStatsFilter1: '',
			noxStatsFilter2: '',
			noxStatsFilter3: '',
			noxStatsFilter1Options: [],
			noxStatsFilterStrategies: [],
			noxStatsData: [],
			noxStatsDataLimitOffset: 0,
			noxStatsDataLimitCount: 5,
			noxStatsDataStrategies: {},
			noxIsStatsSearchLoading: false,
			noxIsStatsShowDemoAccount: true,
			noxIsStatsHideLockedAccount: true,
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route() {
				if (this.$route.params.key !== undefined || (this.$route.params.key == undefined && this.noxStatsData.length == 1)) {
					this.getStatsData(this.$route.params.key);
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$cookies.get('noxIsStatsShowDemoAccount')) {
					this.noxIsStatsShowDemoAccount = (this.$cookies.get('noxIsStatsShowDemoAccount') == 'true' ? true : false);
				}
				if (this.$cookies.get('noxIsStatsHideLockedAccount')) {
					this.noxIsStatsHideLockedAccount = (this.$cookies.get('noxIsStatsHideLockedAccount') == 'true' ? true : false);
				}
				this.$cookies.set('noxIsStatsShowDemoAccount', this.noxIsStatsShowDemoAccount, '30d');
				this.$cookies.set('noxIsStatsHideLockedAccount', this.noxIsStatsHideLockedAccount, '30d');
				this.getStatsData(this.$route.params.key);
			},
			resetStatsData: function() {
				this.noxStatsData = [];
				this.noxStatsDataLimitOffset = 0;
			},
			resetStatsFilter: function() {
				var isAdmin = ([1,2,10,90,100].includes(this.$store.state.noxAccountData.id) ? 1 : 0);
				this.noxStatsFilter1Options = this.$parent.$parent.getSelectOptions(3, isAdmin);
				this.noxStatsFilter1 = this.noxStatsFilter1Options[(isAdmin ? 10 : 6)];
				this.noxStatsFilter2 = { code: 11, label: 'NOX Phoenix' };
				this.noxStatsFilter3 = this.noxStatsFilter2;
				this.noxStatsFilterStrategies = [];
			},
			searchStatsData: function() {
				this.noxStatsSearchResult.splice(0, 1);
				if (this.$store.state.noxRegex_d.test(this.noxStatsSearch) && Number(this.noxStatsSearch) > 0) {
					this.axios('getStatsDataBySearch');
				}
			},
			searchStatsDataClose: function() {
				this.noxStatsSearch = '';
				this.noxStatsSearchResult.splice(0, 1);
			},
			filterStatsData: function(event, type) {
				if (event !== null && event.code !== null) {
					var go = 1, code = Number(event.code);
					if (type == 1) {
						go = code !== 3 ? 1 : 0;
						this.noxStatsFilter2 = this.$parent.$parent.getSelectOptions(2)[code == 7 ? 1 : 0];
						this.noxStatsFilter3 = '';
					}
					else if (type == 2) {
						go = this.noxStatsFilter1 !== null ? 1 : 0;
					}
					else if (type == 3) {
						go = this.noxStatsFilter1 !== null ? 1 : 0;
						this.noxStatsFilter2 = this.noxStatsFilter3;
					}
					if (go) {
						this.$parent.$parent.goToTop(true);
						this.resetStatsData();
						this.axios('getStatsData');
					}
				}
			},
			getStatsData: function(key) {
				this.$parent.$parent.goToTop(true);
				this.resetStatsData();
				this.resetStatsFilter();
				this.searchStatsDataClose();
				this.axios('getStatsData', key);
			},
			axios: function(type, var1, var2) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getStatsData') {
						if (!_this.noxStatsDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						if (var1 !== undefined) {
							config.url = '/v2' + ([1,2,10,90,100].includes(_this.$store.state.noxAccountData.id) ? '/admin' : '') + '/account/stats/bot/' + var1;
						}
						else {
							_this.noxIsPageContentLoading = true;
							config.url = '/v2' + ([1,2,10,90,100].includes(_this.$store.state.noxAccountData.id) ? '/admin' : '') + '/account/stats';
							config.params = { limitOffset: _this.noxStatsDataLimitOffset, limitCount: _this.noxStatsDataLimitCount, filterType: _this.noxStatsFilter1.code, filterSort: _this.noxStatsFilter2.code, filterConds: { is_demo: Number(_this.noxIsStatsShowDemoAccount), is_locked: Number(!_this.noxIsStatsHideLockedAccount) }};
						}
					}
					else if (type == 'getStatsDataBySearch') {
						_this.noxIsStatsSearchLoading = true;
						config.url = '/v2' + ([1,2,10,90,100].includes(_this.$store.state.noxAccountData.id) ? '/admin' : '') + '/account/stats/search/' + _this.noxStatsSearch;
					}
					else if (type == 'getStatsDetails') {
						_this.noxStatsData[var1].is_shows.is_show_for_bot_details = true;
						config.url = '/v2' + ([1,2,10,90,100].includes(_this.$store.state.noxAccountData.id) ? '/admin' : '') + '/account/stats/details';
						config.params = { bot_id: var2 };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getStatsData') {
								_this.$parent.$parent.goToTop(!_this.noxStatsDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.stats_data !== undefined) {
									if (data.data.stats_data.data.length) {
										for (var i in data.data.stats_data.data) {
											_this.noxStatsData.push({
												data: data.data.stats_data.data[i],
												info: {
													risk_id: _this.$parent.$parent.getRiskStrategies(data.data.stats_data.data[i].strategy_id, data.data.stats_data.data[i].masters, data.data.stats_data.data[i].multiplier)
												},
												details: {},
												is_shows: {
													is_show_for_bot_prints: false,
													is_show_for_bot_details: false,
													is_show_for_bot_admin_data: false
												}
											});
										}
									}
									_this.noxStatsDataLimitOffset = data.data.stats_data.count > _this.noxStatsDataLimitOffset + _this.noxStatsDataLimitCount ? _this.noxStatsDataLimitOffset + _this.noxStatsDataLimitCount : 0;
								}
								if (data.data.stats_masters !== undefined) {
									for (var j in data.data.stats_masters) {
										_this.$store.state.noxBotsMasters[data.data.stats_masters[j].id] = data.data.stats_masters[j];
									}
								}
								if (data.data.stats_strategies !== undefined) {
									_this.noxStatsFilterStrategies = [];
									for (var k in data.data.stats_strategies) {
										_this.noxStatsFilterStrategies.push({ code: data.data.stats_strategies[k].id, label: data.data.stats_strategies[k].name });
										_this.noxStatsDataStrategies[data.data.stats_strategies[k].id] = data.data.stats_strategies[k];
									}
									_this.$parent.$parent.sortDataByLabel(_this.noxStatsFilterStrategies);
								}
								if (var1 !== undefined) {
									if (_this.$route.path !== '/account/stats/' + var1) {
										_this.$router.push({ path: '/account/stats/' + var1 });
									}
								}
								else {
									if (_this.$route.path !== '/account/stats') {
										_this.$router.push({ path: '/account/stats' });
									}
								}
							}
							else if (type == 'getStatsDataBySearch') {
								_this.noxStatsSearchResult.splice(0, 1, data.data);
							}
							else if (type == 'getStatsDetails') {
								if (data.data.stats_data !== undefined) {
									if (data.data.stats_details_1 !== undefined) {
										if (data.data.stats_details_2 !== undefined) {
											if (data.data.stats_details_3 !== undefined) {
												_this.noxStatsData[var1].details = {
													data: data.data.stats_data,
													details_1: data.data.stats_details_1,
													details_2: data.data.stats_details_2,
													details_3: data.data.stats_details_3,
													details_chart_1: {},
													details_chart_2: {},
													details_chart_3: {},
													details_chart_4: {},
													details_chart_5: {},
													details_chart_6: {},
													details_chart_7: {},
													details_chart_7_options: { threshold: 0 },
													details_chart_8: {},
													details_chart_9: {},
													details_chart_9_options: { type: 1, data: [], udata: {}, deposits: {}, withdrawals: {} },
													details_chart_10: {},
													details_chart_11: {},
													details_chart_12: {}
												};
												_this.noxStatsData[var1].details.details_chart_7_options.threshold = (-1 * Number(_this.noxStatsData[var1].details.data.strategy_settings.b) * ((Number(_this.noxStatsData[var1].details.data.is_compound_interest) > 0 && Number(_this.noxStatsData[var1].details.data.funding_limit) > 0 && Number(_this.noxStatsData[var1].details.data.balance) > 0 && ((Number(_this.noxStatsData[var1].details.data.funding_limit) * 100) < Number(_this.noxStatsData[var1].details.data.balance))) ? (Number(_this.noxStatsData[var1].details.data.funding_limit) * 100 / Number(_this.noxStatsData[var1].details.data.balance)) : 1)); _this.noxStatsData[var1].details.details_chart_7_options.threshold = _this.noxStatsData[var1].details.details_chart_7_options.threshold > -1 ? 0 : _this.noxStatsData[var1].details.details_chart_7_options.threshold;
												_this.noxStatsData[var1].details.details_chart_1 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 0) },
													legend: { enabled: true },
													series: [
														{ name: _this.$store.getters.getLanguageText('1.6.1', 1), color: '#7CB5EC', data: [] },
														{ name: _this.$store.getters.getLanguageText('1.6.1', 2), color: '#F45B5B', data: [] }
													]
												};
												_this.noxStatsData[var1].details.details_chart_2 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 3) },
													series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 4), color: '#1EC455', data: [] }]
												};
												_this.noxStatsData[var1].details.details_chart_3 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 5) },
													series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 6), color: '#F7A35C', data: [] }],
													tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 0); }}
												};
												_this.noxStatsData[var1].details.details_chart_4 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 7) },
													series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 8), color: '#808080', data: [] }]
												};
												_this.noxStatsData[var1].details.details_chart_5 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 9) },
													series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 10), color: '#3D96AE', data: [] }],
													tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }}
												};
												_this.noxStatsData[var1].details.details_chart_6 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 11) },
													chart: { type: 'line', height: 200, events: { load: function() { this.redraw(); }}},
													xAxis: { events: { afterSetExtremes: function(event) { _this.$parent.$parent.addChartAvgLine(event, var1, 6, {}); }}},
													yAxis: [{ id: 'chart_6_' + var1 + '_avg' }],
													series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 12), color: '#A64CA6', data: [] }],
													tooltip: {
														shared: true, outside: true, padding: 8,
														formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }
													}
												};
												_this.noxStatsData[var1].details.details_chart_7 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 13) },
													chart: { type: 'line', height: 350, events: { load: function() { this.redraw(); }}},
													xAxis: { events: { afterSetExtremes: function(event) { _this.$parent.$parent.addChartLockThresholdLine(event, _this.noxStatsData[var1].details.details_chart_7_options.threshold, var1, 7, Number(_this.noxStatsData[var1].details.data.strategy_settings.a), 1); }}},
													yAxis: [{ id: 'chart_7_' + var1 + '_line', plotBands: [{ from: (Number(_this.noxStatsData[var1].details.data.strategy_settings.a) ? _this.noxStatsData[var1].details.details_chart_7_options.threshold : -100), to: -100, color: '#FF57221A' }] }],
													legend: { enabled: true }, series: [],
													tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }}
												};
												_this.noxStatsData[var1].details.details_chart_8 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 14) },
													chart: { type: 'column', height: 300 },
													xAxis: { type: 'category', categories: [], labels: { enabled: false }},
													legend: { enabled: true },
													series: [
														{ name: _this.$store.getters.getLanguageText('1.6.1', 15), color: '#1EC455', data: [] },
														{ name: _this.$store.getters.getLanguageText('1.6.1', 16), color: '#F45B5B', data: [] }
													],
													plotOptions: {
														column: { stacking: 'normal', minPointLength: 2 },
														series: { pointPadding: 0.1, groupPadding: 0, borderWidth: 0 }
													}
												};
												_this.noxStatsData[var1].details.details_chart_10 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 20) },
													chart: { type: 'solidgauge', height: 200 },
													pane: {
														size: '140%', center: ['50%','75%'], startAngle: -90, endAngle: 90,
														background: { shape: 'arc', innerRadius: '60%', outerRadius: '100%' }
													},
													yAxis: {
														min: 0, max: 100, stops: [[0.1,'#55BF3B'],[0.5,'#DDDF0D'],[0.9,'#DF5353']],
														lineWidth: 0, tickWidth: 0, tickAmount: 2, minorTickInterval: null, labels: { y: 20 }
													},
													series: [{
														data: [0],
														dataLabels: { format: '<div style="text-align:center"><span style="font-size:15px">{y}%</span></div>' }
													}],
													tooltip: { enabled: false },
													plotOptions: { solidgauge: { dataLabels: { borderWidth: 0 }}}
												};
												_this.noxStatsData[var1].details.details_chart_11 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 31) },
													legend: { enabled: true },
													series: [
														{ name: _this.$store.getters.getLanguageText('1.6.1', 32), color: '#7CB5EC', data: [] },
														{ name: _this.$store.getters.getLanguageText('1.6.1', 33), color: '#F45B5B', data: [] }
													]
												};
												_this.noxStatsData[var1].details.details_chart_12 = {
													title: { text: _this.$store.getters.getLanguageText('1.6.1', 35) },
													chart: { type: 'column', height: 300 },
													series: [
														{ name: _this.$store.getters.getLanguageText('1.6.1', 36), color: '#1EC455', data: [] },
														{ name: _this.$store.getters.getLanguageText('1.6.1', 37), color: '#F45B5B', data: [] }
													],
													plotOptions: {
														column: { borderWidth: 0, groupPadding: 0, minPointLength: 2, pointPadding: 0.1 }
													}
												};
												if (_this.noxStatsData[var1].details.details_1.length) {
													var statistics1 = _this.noxStatsData[var1].details.details_1.reverse();
													var statisticsChart3Data = false;
													var statisticsChart5Data = false;
													var statisticsDepositLoad = 0;
													if (statistics1.length) {
														statistics1.push({
															id: 'new',
															user_id: statistics1[(statistics1.length - 1)].user_id,
															bot_id: statistics1[(statistics1.length - 1)].bot_id,
															balans_scheta: _this.noxStatsData[var1].details.data.balance,
															balance_drawdown: _this.noxStatsData[var1].details.data.drawdown,
															razmer_svobodnykh_sredstv_na_schete: _this.noxStatsData[var1].details.data.equity,
															uroven_zalogovykh_sredstv_v_protsentakh: _this.noxStatsData[var1].details.data.margin_level,
															znachenie_sobstvennykh_sredstv: _this.noxStatsData[var1].details.data.funds,
															percentage_profitable_orders: _this.noxStatsData[var1].details.data.profitable_orders,
															is_working_day: statistics1[(statistics1.length - 1)].is_working_day,
															count_of_orders: _this.noxStatsData[var1].details.data.count_of_orders,
															size_of_nox_orders: _this.noxStatsData[var1].details.data.size_of_nox_orders,
															size_of_other_orders: _this.noxStatsData[var1].details.data.size_of_other_orders,
															created_at: _this.noxStatsData[var1].details.data.online_at
														});
														for (var s1 in statistics1) {
															var s1_created_at = parseInt(statistics1[s1].created_at) * 1000;
															_this.noxStatsData[var1].details.details_chart_1.series[0].data.push([s1_created_at, parseFloat((statistics1[s1].balans_scheta / 100).toFixed(2))]);
															_this.noxStatsData[var1].details.details_chart_1.series[1].data.push([s1_created_at, parseFloat((statistics1[s1].znachenie_sobstvennykh_sredstv / 100).toFixed(2))]);
															_this.noxStatsData[var1].details.details_chart_2.series[0].data.push([s1_created_at, parseFloat((statistics1[s1].razmer_svobodnykh_sredstv_na_schete / 100).toFixed(2))]);
															_this.noxStatsData[var1].details.details_chart_3.series[0].data.push([s1_created_at, Number(s1) > 5 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) > 0 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) < 10000 ? parseInt(Math.floor(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh)) : null]);
															_this.noxStatsData[var1].details.details_chart_4.series[0].data.push([s1_created_at, parseInt(statistics1[s1].count_of_orders)]);
															_this.noxStatsData[var1].details.details_chart_5.series[0].data.push([s1_created_at, Number(statistics1[s1].percentage_profitable_orders) !== 0 ? parseFloat(statistics1[s1].percentage_profitable_orders) : null]);
															_this.noxStatsData[var1].details.details_chart_6.series[0].data.push([s1_created_at, parseFloat(Number(statistics1[s1].balans_scheta) !== 0 ? (statistics1[s1].balance_drawdown / statistics1[s1].balans_scheta * 100).toFixed(2) : 0)]);
															_this.noxStatsData[var1].details.details_chart_11.series[0].data.push([s1_created_at, parseFloat(statistics1[s1].size_of_nox_orders.toFixed(2))]);
															_this.noxStatsData[var1].details.details_chart_11.series[1].data.push([s1_created_at, parseFloat(statistics1[s1].size_of_other_orders.toFixed(2))]);
															if (_this.noxStatsData[var1].details.details_2.length) {
																var statistics2Profit = _this.noxStatsData[var1].details.details_2;
																if (statistics1[s1].created_at >= statistics2Profit[(statistics2Profit.length - 1)].created_at) {
																	var profit = Number(statistics1[s1].znachenie_sobstvennykh_sredstv);
																	for (var s2Profit in statistics2Profit) {
																		if (statistics1[s1].created_at >= statistics2Profit[s2Profit].created_at) {
																			if (statistics2Profit[s2Profit].type == 1) { profit -= Number(statistics2Profit[s2Profit].deposit); }
																			if (statistics2Profit[s2Profit].type == 2) { profit += Number(statistics2Profit[s2Profit].withdrawal); }
																		}
																	}
																	_this.noxStatsData[var1].details.details_chart_12.series[0].data.push([s1_created_at, (profit >= 0 ? parseFloat((profit / 100).toFixed(2)) : null)]);
																	_this.noxStatsData[var1].details.details_chart_12.series[1].data.push([s1_created_at, (profit < 0 ? parseFloat((profit / 100).toFixed(2)) : null)]);
																}
															}
															if (Number(s1) > 5 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) > 0 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) < 10000) { statisticsChart3Data = true; }
															if (Number(statistics1[s1].percentage_profitable_orders) !== 0) { statisticsChart5Data = true; }
															statisticsDepositLoad = 100 - (Number(statistics1[s1].balans_scheta) !== 0 ? (statistics1[s1].znachenie_sobstvennykh_sredstv / statistics1[s1].balans_scheta * 100) : 0);
															statisticsDepositLoad = statisticsDepositLoad > 100 ? 100 : statisticsDepositLoad;
														}
													}
													if (!statisticsChart3Data) { _this.noxStatsData[var1].details.details_chart_3.series[0].data = []; }
													if (!statisticsChart5Data) { _this.noxStatsData[var1].details.details_chart_5.series[0].data = []; }
													_this.noxStatsData[var1].details.details_chart_10.series[0].data[0] = parseFloat(statisticsDepositLoad.toFixed(2));
												}
												if (_this.noxStatsData[var1].details.details_2.length) {
													var statistics2 = _this.noxStatsData[var1].details.details_2.reverse();
													var statistics2_UniqueData = {};
													var statistics2_1_1UniqueData = {};
													var statistics2_1_2UniqueData = {};
													var statistics2_2_1UniqueData = {};
													var statistics2_2_2UniqueData = {};
													for (var s2 in statistics2) {
														if (Number(statistics2[s2].type) == 1) {
															if (statistics2_1_1UniqueData[statistics2[s2].created_at] !== undefined) {
																statistics2_1_1UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].deposit);
																if (Number(statistics2[s2].number) > 0) {
																	statistics2_2_1UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].deposit);
																}
															}
															else {
																statistics2_1_1UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].deposit);
																if (Number(statistics2[s2].number) > 0) {
																	statistics2_2_1UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].deposit);
																}
															}
														}
														else if (Number(statistics2[s2].type) == 2) {
															if (statistics2_1_2UniqueData[statistics2[s2].created_at] !== undefined) {
																statistics2_1_2UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].withdrawal);
																if (Number(statistics2[s2].number) > 0) {
																	statistics2_2_2UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].withdrawal);
																}
															}
															else {
																statistics2_1_2UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].withdrawal);
																if (Number(statistics2[s2].number) > 0) {
																	statistics2_2_2UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].withdrawal);
																}
															}
														}
														if (statistics2_UniqueData[statistics2[s2].created_at] == undefined) {
															statistics2_UniqueData[statistics2[s2].created_at] = 1;
														}
														if (_this.noxStatsData[var1].details.details_chart_8.xAxis.categories.indexOf(parseInt(statistics2[s2].created_at)) < 0) {
															_this.noxStatsData[var1].details.details_chart_8.xAxis.categories.push(parseInt(statistics2[s2].created_at));
														}
													}
													for (var c1 in _this.noxStatsData[var1].details.details_chart_8.xAxis.categories) {
														var v2_1_1 = statistics2_1_1UniqueData[_this.noxStatsData[var1].details.details_chart_8.xAxis.categories[c1]];
														var v2_1_2 = statistics2_1_2UniqueData[_this.noxStatsData[var1].details.details_chart_8.xAxis.categories[c1]];
														_this.noxStatsData[var1].details.details_chart_8.series[0].data.push((v2_1_1 ? parseFloat((v2_1_1 / 100).toFixed(2)) : null));
														_this.noxStatsData[var1].details.details_chart_8.series[1].data.push((v2_1_2 ? parseFloat((v2_1_2 / -100).toFixed(2)) : null));
													}
													for (var c2 in _this.noxStatsData[var1].details.details_chart_8.xAxis.categories) {
														_this.noxStatsData[var1].details.details_chart_8.xAxis.categories[c2] = Highcharts.dateFormat('%d.%m.%Y, %H:%M:%S', _this.noxStatsData[var1].details.details_chart_8.xAxis.categories[c2] * 1000);
													}
													if (_this.noxStatsData[var1].details.details_1.length > 1) {
														setTimeout(function() {
															_this.noxStatsData[var1].details.details_chart_9 = Highcharts.chart('noxStatsDetailsChart9Highchart' + var1, {
																title: { text: _this.$store.getters.getLanguageText('1.6.1', 17) },
																chart: { type: 'column', height: 300, events: { load: function() { this.redraw(); }}},
																xAxis: { events: { afterSetExtremes: function(event) { _this.$parent.$parent.addChartAvgLine(event, var1, 9, { isCount: (_this.noxStatsData[var1].details.details_chart_9_options.type == 2 ? true : false) }); }}},
																yAxis: [{ id: 'chart_9_' + var1 + '_avg' }],
																legend: { enabled: true },
																series: [
																	{ name: _this.$store.getters.getLanguageText('1.6.1', 18), color: '#1EC455', data: [] },
																	{ name: _this.$store.getters.getLanguageText('1.6.1', 19), color: '#F45B5B', data: [] }
																],
																tooltip: {
																	shared: true, outside: true, padding: 8,
																	formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2, (_this.noxStatsData[var1].details.details_chart_9_options.type == 2 ? 2 : 0)); }
																},
																plotOptions: {
																	column: { stacking: 'normal', minPointLength: 2 },
																	series: { pointPadding: 0.1, groupPadding: 0, borderWidth: 0 }
																}
															});
															_this.noxStatsData[var1].details.details_chart_9_options.type = ((_this.$parent.$parent.getNumberOfMonths((new Date(parseInt(_this.noxStatsData[var1].details.details_1[0].created_at) * 1000)), (new Date(parseInt(_this.noxStatsData[var1].details.details_1[(_this.noxStatsData[var1].details.details_1.length - 1)].created_at) * 1000))) > 3) ? 2 : 1);
															_this.noxStatsData[var1].details.details_chart_9_options.udata = statistics2_UniqueData;
															_this.noxStatsData[var1].details.details_chart_9_options.deposits = statistics2_2_1UniqueData;
															_this.noxStatsData[var1].details.details_chart_9_options.withdrawals = statistics2_2_2UniqueData;
															_this.initStatsDetailsChart9(var1);
														}, 300);
													}
												}
												if (_this.noxStatsData[var1].details.details_3.length) {
													var statistics3 = _this.noxStatsData[var1].details.details_3.reverse();
													var statistics3Indexs = [];
													var statistics3PrevData = [];
													if (_this.noxStatsData[var1].details.details_1.length) {
														var statistics3_0 = _this.noxStatsData[var1].details.details_1;
														for (var s3_0 in statistics3_0) {
															if (Number(statistics3_0[s3_0].created_at) >= Number(statistics3[0].created_at)) { break; }
															statistics3PrevData.push(statistics3_0[s3_0].created_at);
														}
													}
													for (var s3_1 in statistics3) {
														if (!statistics3Indexs.includes(statistics3[s3_1].index)) {
															statistics3Indexs.push(statistics3[s3_1].index);
														}
													}
													statistics3Indexs.sort();
													for (var s3_2 in statistics3Indexs) {
														_this.noxStatsData[var1].details.details_chart_7.series.push({ name: statistics3Indexs[s3_2], color: _this.$store.state.noxChartColors[s3_2], data: [] });
													}
													for (var s3_3 in statistics3) {
														if (!Number(s3_3)) {
															for (var s3_4 in statistics3PrevData) {
																_this.noxStatsData[var1].details.details_chart_7.series[statistics3Indexs.indexOf(statistics3[s3_3].index)].data.push([parseInt(statistics3PrevData[s3_4]) * 1000, null]);
															}
														}
														_this.noxStatsData[var1].details.details_chart_7.series[statistics3Indexs.indexOf(statistics3[s3_3].index)].data.push([parseInt(statistics3[s3_3].created_at) * 1000, (Number(statistics3[s3_3].balance) !== 0 ? parseFloat((statistics3[s3_3].value / statistics3[s3_3].balance * 100).toFixed(2)) : null)]);
													}
												}
											}
										}
									}
								}
							}
						}
					}).catch(function() {
						if (type == 'getStatsDataBySearch') {
							_this.noxStatsSearchResult.splice(0, 1);
						}
						else {
							_this.$parent.$parent.exit();
						}
					}).finally(function() {
						if (type == 'getStatsData') {
							_this.noxIsLoading = false;
							_this.noxIsPageContentLoading = false;
							_this.$store.state.noxIsAccount = false;
							_this.$store.state.noxIsLoading = false;
						}
						else if (type == 'getStatsDataBySearch') {
							_this.noxIsStatsSearchLoading = false;
						}
						else if (type == 'getStatsDetails') {
							_this.noxStatsData[var1].is_shows.is_show_for_bot_details = false;
						}
					});
				}
				else {
					_this.$router.push({ path: String(_this.$route.path).replace(/account/gi, 'info') });
				}
			},
			toggleChangeForIsStatsShowDemoAccount: function(event) {
				this.$cookies.set('noxIsStatsShowDemoAccount', event.target.checked, '30d');
				this.noxIsStatsShowDemoAccount = event.target.checked;
				this.getStatsData(this.$route.params.key);
			},
			toggleChangeForIsStatsHideLockedAccount: function(event) {
				this.$cookies.set('noxIsStatsHideLockedAccount', event.target.checked, '30d');
				this.noxIsStatsHideLockedAccount = event.target.checked;
				this.getStatsData(this.$route.params.key);
			},
			hideStatsDetails: function(index) {
				this.noxStatsData[index].details = {};
			},
			showAdminData: function(index) {
				this.noxStatsData[index].is_shows.is_show_for_bot_admin_data = true;
			},
			hideAdminData: function(index) {
				this.noxStatsData[index].is_shows.is_show_for_bot_admin_data = false;
			},
			initStatsDetailsChart9: function(index, data = [[],[]]) {
				if (this.noxStatsData[index].details.details_chart_9_options.type == 1) {
					if (this.noxStatsData[index].details.details_1.length > 1) {
						for (var i1 = 1; i1 < this.noxStatsData[index].details.details_1.length; i1++) {
							if (this.noxStatsData[index].details.details_1[i1].id !== 'new') {
								var isAddT1 = 1;
								var createdAtT1 = (parseInt(this.noxStatsData[index].details.details_1[i1].created_at) * 1000);
								var profitDayT1 = (((this.noxStatsData[index].details.details_1[i1].balans_scheta - this.noxStatsData[index].details.details_1[(i1 - 1)].balans_scheta) / this.noxStatsData[index].details.details_1[(i1 - 1)].balans_scheta) * 100);
								for (var j1 in this.noxStatsData[index].details.details_chart_9_options.udata) {
									if ((createdAtT1 >= (parseInt(j1) * 1000)) && ((createdAtT1 - (parseInt(j1) * 1000)) < (24 * 60 * 60 * 1000))) {
										isAddT1 = 0; break;
									}
								}
								if (isAddT1) {
									if (profitDayT1 > 0 && ((profitDayT1 < Number(this.$store.state.noxSystemSettings.bot_profit_day_max)) || (Number(this.noxStatsData[index].details.data.is_news_trading) > 0))) {
										data[0].push([createdAtT1, parseFloat(profitDayT1.toFixed(2))]);
									}
									if (profitDayT1 < 0 && ((profitDayT1 > Number(this.$store.state.noxSystemSettings.bot_profit_day_min)) || (Number(this.noxStatsData[index].details.data.is_news_trading) > 0))) {
										data[1].push([createdAtT1, parseFloat(profitDayT1.toFixed(2))]);
									}
								}
							}
						}
					}
				}
				else {
					var objSeries = {};
					if (this.noxStatsData[index].details.details_1.length > 1) {
						for (var i2 in this.noxStatsData[index].details.details_1) {
							if (this.noxStatsData[index].details.details_1[i2].id !== 'new') {
								var i2_date = (new Date(parseInt(this.noxStatsData[index].details.details_1[i2].created_at) * 1000));
								var i2_time = (new Date(i2_date.getFullYear(), (i2_date.getMonth() + 1), 0).getTime());
								if (Object.keys(objSeries).length || this.noxStatsData[index].details.details_1[i2].balans_scheta !== 0) {
									if (objSeries[i2_time] == undefined) { objSeries[i2_time] = { created_ats: [], balances: [], deposits: 0, withdrawals: 0 }; }
									objSeries[i2_time].created_ats.push(this.noxStatsData[index].details.details_1[i2].created_at);
									objSeries[i2_time].balances.push(this.noxStatsData[index].details.details_1[i2].balans_scheta);
								}
							}
						}
					}
					if (Object.keys(objSeries).length) {
						if (Object.keys(this.noxStatsData[index].details.details_chart_9_options.deposits).length) {
							for (var d2 in this.noxStatsData[index].details.details_chart_9_options.deposits) {
								var d2_date = (new Date(parseInt(d2) * 1000));
								var d2_time = (new Date(d2_date.getFullYear(), (d2_date.getMonth() + 1), 0).getTime());
								if (objSeries[d2_time] !== undefined && !(((new Date(d2_date).getDate()) == (new Date(d2_time).getDate())) && (Number(d2) > Number(objSeries[d2_time].created_ats[(objSeries[d2_time].created_ats.length - 1)])))) { objSeries[d2_time].deposits += this.noxStatsData[index].details.details_chart_9_options.deposits[d2];
								}
							}
						}
						if (Object.keys(this.noxStatsData[index].details.details_chart_9_options.withdrawals).length) {
							for (var w2 in this.noxStatsData[index].details.details_chart_9_options.withdrawals) {
								var w2_date = (new Date(parseInt(w2) * 1000));
								var w2_time = (new Date(w2_date.getFullYear(), (w2_date.getMonth() + 1), 0).getTime());
								if (objSeries[w2_time] !== undefined && !(((new Date(w2_date).getDate()) == (new Date(w2_time).getDate())) && (Number(w2) > Number(objSeries[w2_time].created_ats[(objSeries[w2_time].created_ats.length - 1)])))) { objSeries[w2_time].withdrawals += this.noxStatsData[index].details.details_chart_9_options.withdrawals[w2];
								}
							}
						}
						for (var j2 in objSeries) {
							if (objSeries[j2].balances.length > 1) {
								var countT2 = objSeries[j2].balances.length;
								var balanceT2_1 = objSeries[j2].balances[0];
								var balanceT2_2 = objSeries[j2].balances[(countT2 - 1)] - objSeries[j2].deposits + objSeries[j2].withdrawals;
								var balanceT2_3 = ((objSeries[j2].balances[0] + objSeries[j2].balances[(countT2 - 1)]) / 2);
								var profitDayT2 = ((objSeries[j2].deposits || objSeries[j2].withdrawals) ? (balanceT2_3 ? ((balanceT2_2 - balanceT2_1) / balanceT2_3 * 100) : 0) : (balanceT2_1 ? (((balanceT2_2 * 100) / balanceT2_1) - 100) : 0));
								if (profitDayT2 > 0) { data[0].push([parseInt(j2) - (27 * 24 * 60 * 60 * 1000), parseFloat(profitDayT2.toFixed(2))]); }
								if (profitDayT2 < 0) { data[1].push([parseInt(j2) - (27 * 24 * 60 * 60 * 1000), parseFloat(profitDayT2.toFixed(2))]); }
							}
						}
					}
				}
				if (data[0].length || data[1].length) {
					document.getElementById('noxStatsDetailsChart9' + index).classList.remove('hide');
				}
				this.noxStatsData[index].details.details_chart_9.series[0].update({ data: data[0] });
				this.noxStatsData[index].details.details_chart_9.series[1].update({ data: data[1] });
			},
			getStatsDetailsChart9: function(index, type) {
				this.noxStatsData[index].details.details_chart_9_options.type = type;
				this.initStatsDetailsChart9(index);
			}
		},
		components: {
			NoxModalRecaptcha: () => import('@/views/modal_components/NoxModalRecaptcha.vue'),
			NoxModalBotOrders: () => import('@/views/modal_components/NoxModalBotOrders.vue'),
			NoxModalBotSettings: () => import('@/views/modal_components/NoxModalBotSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>
