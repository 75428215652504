<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Настройки крона</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_cron_settings">
						<table class="nox_table">
							<thead>
								<tr>
									<th>№</th>
									<th>Имя</th>
									<th>Ключ</th>
									<th>Вкл / Выкл</th>
									<th><font-awesome-icon :icon="['fas', 'sync-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxCronSettings" :key="index">
									<td data-label="№:">{{ (index + 1) }}</td>
									<td data-label="Имя:">{{ value.name }}</td>
									<td data-label="Ключ:">{{ value.key }}</td>
									<td data-label="Вкл / Выкл:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.value)" :width="42" :height="26" :color="{ checked: '#00FF0080', unchecked: '#FF6666CC' }" @change="toggleChange(value.key, $event)"></toggle-button></td>
									<td data-label="Выполнить:">
										<div :class="{ hide: !noxIsCronSettingsLoadings[index] }"><img src="@/assets/images/loading.gif"></div>
										<div :class="{ hide: noxIsCronSettingsLoadings[index] }"><button type="button" class="nox_button icon blue" @click="axios('executeCronSetting', index)"><font-awesome-icon :icon="['fas', 'sync-alt']" /></button></div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Запуск скриптов</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_cron_scripts">
						<table class="nox_table">
							<thead>
								<tr>
									<th>№</th>
									<th>Название</th>
									<th>Скрипт</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxCronScripts" :key="index">
									<td data-label="№:">{{ (index + 1) }}</td>
									<td data-label="Название:">{{ value }}</td>
									<td data-label="Скрипт:">
										<div :class="{ hide: !noxIsCronScriptsLoadings[index] }"><img src="@/assets/images/loading.gif"></div>
										<div :class="{ hide: noxIsCronScriptsLoadings[index] }"><button type="button" class="nox_button common blue" @click="axios('executeCronScript', index)" v-html="$store.getters.getLanguageText('1.1', 129)"></button></div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxCronSettings: {},
			noxCronScripts: [
				'Перерасчет рейтинга пользователей',
				'Проверка синхронности серверов NOX Box v.2',
				'Дополнение шаблонов настроек NOX Phoenix',
				'Перезагрузка Apache на сервере-ретрансляторе NOX Box v.2'
			],
			noxIsCronSettingsLoadings: [],
			noxIsCronScriptsLoadings: [],
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getCronSettings');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initData: function() {
				for (var i in this.noxCronSettings) {
					if (this.noxIsCronSettingsLoadings[i] == undefined) { this.noxIsCronSettingsLoadings.push(false); }
				}
				for (var j in this.noxCronScripts) {
					if (this.noxIsCronScriptsLoadings[j] == undefined) { this.noxIsCronScriptsLoadings.push(false); }
				}
			},
			axios: function(type, var1, var2) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getCronSettings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/cron/settings';
					}
					else if (type == 'editCronSettingActive') {
						config.url = '/v2/admin/account/settings/value';
						config.data = { key: var1, value: var2 };
						config.method = 'patch';
					}
					else if (type == 'executeCronSetting') {
						_this.noxIsCronSettingsLoadings.splice(var1, 1, true);
						config.url = '/v2/admin/account/cron/' + String(_this.noxCronSettings[var1].key).replace(/\D+/gi, '') + '/execute';
						config.method = 'patch';
					}
					else if (type == 'executeCronScript') {
						_this.noxIsCronScriptsLoadings.splice(var1, 1, true);
						config.url = '/v2/admin/account/scripts/' + (Number(var1) + 1) + '/execute';
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getCronSettings') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								_this.noxCronSettings = data.data;
								_this.initData();
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						if (type == 'executeCronSetting') {
							_this.noxIsCronSettingsLoadings.splice(var1, 1, false);
						}
						else if (type == 'executeCronScript') {
							_this.noxIsCronScriptsLoadings.splice(var1, 1, false);
						}
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(key, event) {
				this.axios('editCronSettingActive', key, Number(event.value));
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_cron_settings tr th:nth-child(1),
	.nox_account_desktop_cron_settings tr td:nth-child(1),
	.nox_account_desktop_cron_settings tr th:nth-child(5),
	.nox_account_desktop_cron_settings tr td:nth-child(5),
	.nox_account_desktop_cron_scripts tr td:nth-child(1),
	.nox_account_desktop_cron_scripts tr td:nth-child(1) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_cron_settings tr th:nth-child(n + 2),
	.nox_account_desktop_cron_settings tr td:nth-child(n + 2) {
		width: 34%;
	}
	.nox_account_desktop_cron_scripts tr th:nth-child(n + 2),
	.nox_account_desktop_cron_scripts tr td:nth-child(n + 2) {
		width: 50%;
	}
</style>
