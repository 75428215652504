<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portrait_settings">
				<div class="nox_tabs">
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)">Вопросы</label>
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)">Портреты</label>
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_3" name="nox_tab_menu" :checked="noxIsTabMenu3Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_3" @click="selectTab(3)">Тесты</label>
					<div class="nox_tab_block" id="nox_tab_block_1">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_row single">
									<div class="nox_account_desktop_portlet_content_label">Перемешивать вопросы:</div>
									<div class="nox_account_desktop_portlet_content_value"><toggle-button class="nox_toggle_button" :value="toggleValue(noxIsRandomPortraitQuestions)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsRandomPortraitQuestions"></toggle-button></div>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet" v-for="(value, index) in noxPortraitQuestions" :key="index">
							<div class="nox_account_desktop_portlet_title">Вопрос #{{ value.sorting }}</div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portrait_settings_question">
									<table class="nox_table">
										<thead>
											<tr>
												<th>Вопрос (RU)</th>
												<th>Вопрос (EN)</th>
												<th>Статус</th>
												<th><font-awesome-icon :icon="['fas', 'random']" /></th>
												<th><font-awesome-icon :icon="['fas', 'arrow-up']" /></th>
												<th><font-awesome-icon :icon="['fas', 'arrow-down']" /></th>
												<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td data-label="Вопрос (RU):" v-html="value.question_ru"></td>
												<td data-label="Вопрос (EN):" v-html="value.question_en"></td>
												<td data-label="Статус вопроса:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.status)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStatus(value.id, index, $event)"></toggle-button></td>
												<td data-label="Случайные ответы:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.is_random)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsRandom(value.id, index, $event)"></toggle-button></td>
												<td data-label="Переместить вверх:"><span :class="{ hide: !(noxPortraitQuestionsSwap == 'up' + value.id + '.' + index) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxPortraitQuestionsSwap == 'up' + value.id + '.' + index) }" @click="axios('swapUpPortraitQuestion', value.id, index, value.sorting, noxPortraitQuestions[index - 1].sorting)" v-if="index"><font-awesome-icon :icon="['fas', 'arrow-up']" /></button></td>
												<td data-label="Переместить вниз:"><span :class="{ hide: !(noxPortraitQuestionsSwap == 'down' + value.id + '.' + index) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxPortraitQuestionsSwap == 'down' + value.id + '.' + index) }" @click="axios('swapDownPortraitQuestion', value.id, index, value.sorting, noxPortraitQuestions[index + 1].sorting)" v-if="noxPortraitQuestions.length > index + 1"><font-awesome-icon :icon="['fas', 'arrow-down']" /></button></td>
												<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalPortraitSettings', { type: 'editPortraitQuestion', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
												<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalPortraitSettings', { type: 'deletePortraitQuestion', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_portrait_settings_answers" v-if="Object.keys(noxPortraitAnswers).length && noxPortraitAnswers[value.id] && noxPortraitAnswers[value.id].length">
									<div class="nox_account_desktop_portrait_settings_answers_title">Список ответов на вопрос:</div>
									<table class="nox_table">
										<thead>
											<tr>
												<th>№</th>
												<th>Вес</th>
												<th><font-awesome-icon :icon="['fas', 'asterisk']" /></th>
												<th>Ответ (RU)</th>
												<th>Ответ (EN)</th>
												<th><font-awesome-icon :icon="['fas', 'arrow-up']" /></th>
												<th><font-awesome-icon :icon="['fas', 'arrow-down']" /></th>
												<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value2, index2) in noxPortraitAnswers[value.id]" :key="index2">
												<td data-label="№:">{{ value2.sorting }}</td>
												<td data-label="Вес:">{{ value2.weight }}</td>
												<td data-label="Множитель:">{{ value2.multiplier }}</td>
												<td data-label="Ответ (RU):" v-html="value2.answer_ru"></td>
												<td data-label="Ответ (EN):" v-html="value2.answer_en"></td>
												<td data-label="Переместить вверх:"><span :class="{ hide: !(noxPortraitAnswersSwap == 'up' + value2.qid + '.' + index2) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxPortraitAnswersSwap == 'up' + value2.qid + '.' + index2) }" @click="axios('swapUpPortraitAnswer', value2.qid, index2, value2.sorting, noxPortraitAnswers[value.id][index2 - 1].sorting)" v-if="index2"><font-awesome-icon :icon="['fas', 'arrow-up']" /></button></td>
												<td data-label="Переместить вниз:"><span :class="{ hide: !(noxPortraitAnswersSwap == 'down' + value2.qid + '.' + index2) }"><img src="@/assets/images/loading.gif"></span><button type="button" class="nox_button icon blue" :class="{ hide: (noxPortraitAnswersSwap == 'down' + value2.qid + '.' + index2) }" @click="axios('swapDownPortraitAnswer', value2.qid, index2, value2.sorting, noxPortraitAnswers[value.id][index2 + 1].sorting)" v-if="noxPortraitAnswers[value.id].length > (index2 + 1)"><font-awesome-icon :icon="['fas', 'arrow-down']" /></button></td>
												<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalPortraitSettings', { type: 'editPortraitAnswer', index: index2, id: value2.id, qid: value2.qid })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
												<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalPortraitSettings', { type: 'deletePortraitAnswer', index: index2, id: value2.id, qid: value2.qid })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_buttons">
								<button type="button" class="nox_button big green" @click="$modal.show('NoxModalPortraitSettings', { type: 'addPortraitAnswer', qid: value.id })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить ответ</button>
							</div>
						</div>
						<div class="nox_account_desktop_page_content_buttons">
							<button type="button" class="nox_button big green" @click="$modal.show('NoxModalPortraitSettings', { type: 'addPortraitQuestion' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить вопрос</button>
						</div>
					</div>
					<div class="nox_tab_block" id="nox_tab_block_2">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_content">
								<div><b>Минимальный вес портрета:</b> {{ $store.state.noxSystemSettings.portrait_min_weight }}</div>
								<div><b>Максимальный вес портрета:</b> {{ $store.state.noxSystemSettings.portrait_max_weight }}</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet" v-for="(value, index) in noxPortraitResults" :key="index">
							<div class="nox_account_desktop_portlet_title">Портрет #{{ (index + 1) }}</div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portrait_settings_result">
									<table class="nox_table">
										<thead>
											<tr>
												<th>MIN</th>
												<th>MAX</th>
												<th>Цвет</th>
												<th>Портрет (RU)</th>
												<th>Портрет (EN)</th>
												<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td data-label="MIN:">{{ value.min }}</td>
												<td data-label="MAX:">{{ value.max }}</td>
												<td data-label="Цвет риска:">#{{ value.color }}</td>
												<td data-label="Портрет (RU):" v-html="value.result_ru"></td>
												<td data-label="Портрет (EN):" v-html="value.result_en"></td>
												<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalPortraitSettings', { type: 'editPortraitResult', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
												<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalPortraitSettings', { type: 'deletePortraitResult', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_page_content_buttons">
							<button type="button" class="nox_button big green" @click="$modal.show('NoxModalPortraitSettings', { type: 'addPortraitResult' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить портрет</button>
						</div>
					</div>
					<div class="nox_tab_block" id="nox_tab_block_3">
						<div class="nox_account_desktop_portrait_settings_rtests" v-if="noxPortraitRTests.length">
							<div class="nox_account_desktop_portlet">
								<div class="nox_account_desktop_portlet_title">Список тестов</div>
								<div class="nox_account_desktop_portlet_content">
									<table class="nox_table">
										<thead>
											<tr>
												<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 13)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 30)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxPortraitRTests" :key="index">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 13) + ':'">{{ value.user_id }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 30) + ':'">{{ value.weight }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
												<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalPortraitSettings', { type: 'deletePortraitRTest', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="noxPortraitRTestsLimitOffset">
									<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
									<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
										<button type="button" class="nox_button big" @click="axios('getPortraitRTests')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
									</div>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet" v-else>
							<div class="nox_account_desktop_portlet_content">Список тестов пуст.</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalPortraitSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxPortraitQuestions: [],
			noxPortraitQuestionsSwap: '',
			noxPortraitAnswers: {},
			noxPortraitAnswersSwap: '',
			noxPortraitResults: [],
			noxPortraitRTests: [],
			noxPortraitRTestsLimitOffset: 0,
			noxPortraitRTestsLimitCount: 50,
			noxPortraitLimitOffset: 0,
			noxPortraitLimitCount: 50,
			noxIsRandomPortraitQuestions: 0,
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsTabMenu3Checked: false,
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || [355,463].includes(this.$store.state.noxAccountData.id)) {
					this.noxIsRandomPortraitQuestions = this.$store.state.noxSystemSettings.is_random_portrait_questions;
					this.axios('getPortraitSettings');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initTabs: function(tab) {
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				this.noxIsTabMenu3Checked = false;
				if      (tab == 2) { this.noxIsTabMenu2Checked = true; }
				else if (tab == 3) { this.noxIsTabMenu3Checked = true; }
				else               { this.noxIsTabMenu1Checked = true; }
			},
			selectTab: function(tab) {
				if (Number(this.$route.query.tab) !== tab) {
					this.$router.push({ path: '/account/portrait_settings', query: { tab: tab }});
				}
			},
			axios: function(type, var1, var2, var3, var4) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getPortraitSettings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/portrait/settings';
						config.params = { limitOffset: _this.noxPortraitLimitOffset, limitCount: _this.noxPortraitLimitCount };
					}
					else if (type == 'getPortraitRTests') {
						_this.noxIsPortletLoading = true;
						config.url = '/v2/admin/account/portrait/rtests';
						config.params = { limitOffset: _this.noxPortraitRTestsLimitOffset, limitCount: _this.noxPortraitRTestsLimitCount };
					}
					else if (type == 'editPortraitQuestionStatus') {
						_this.noxPortraitQuestions[var2].status = var3;
						config.url = '/v2/admin/account/portrait/questions/status';
						config.data = { id: var1, status: var3 };
						config.method = 'patch';
					}
					else if (type == 'editPortraitQuestionIsRandom') {
						_this.noxPortraitQuestions[var2].is_random = var3;
						config.url = '/v2/admin/account/portrait/questions/is_random';
						config.data = { id: var1, is_random: var3 };
						config.method = 'patch';
					}
					else if (type == 'editPortraitQuestionsIsRandom') {
						_this.noxIsRandomPortraitQuestions = var1;
						_this.$set(_this.$store.state.noxSystemSettings, 'is_random_portrait_questions', var1);
						config.url = '/v2/admin/account/portrait/questions/is_random_portrait_questions';
						config.data = { is_random_portrait_questions: var1 };
						config.method = 'patch';
					}
					else if (['swapUpPortraitQuestion', 'swapDownPortraitQuestion'].includes(type)) {
						_this.noxPortraitQuestionsSwap = (type == 'swapUpPortraitQuestion' ? 'up' : 'down') + var1 + '.' + var2;
						config.url = '/v2/admin/account/portrait/questions/swap';
						config.data = { sorting_1: var3, sorting_2: var4 };
						config.method = 'patch';
					}
					else if (['swapUpPortraitAnswer', 'swapDownPortraitAnswer'].includes(type)) {
						_this.noxPortraitAnswersSwap = (type == 'swapUpPortraitAnswer' ? 'up' : 'down') + var1 + '.' + var2;
						config.url = '/v2/admin/account/portrait/answers/swap';
						config.data = { qid: var1, sorting_1: var3, sorting_2: var4 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getPortraitSettings') {
								_this.$parent.$parent.goToTop(!_this.noxPortraitLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.portrait_questions !== undefined) {
									_this.noxPortraitQuestions = data.data.portrait_questions;
								}
								if (data.data.portrait_answers !== undefined) {
									_this.noxPortraitAnswers = data.data.portrait_answers;
								}
								if (data.data.portrait_results !== undefined) {
									_this.noxPortraitResults = data.data.portrait_results;
								}
								if (data.data.portrait_rtests !== undefined) {
									if (data.data.portrait_rtests.data.length) {
										for (var i in data.data.portrait_rtests.data) {
											_this.noxPortraitRTests.push(data.data.portrait_rtests.data[i]);
										}
									}
									_this.noxPortraitRTestsLimitOffset = data.data.portrait_rtests.count > _this.noxPortraitRTestsLimitOffset + _this.noxPortraitRTestsLimitCount ? _this.noxPortraitRTestsLimitOffset + _this.noxPortraitRTestsLimitCount : 0;
								}
							}
							else if (type == 'getPortraitRTests') {
								if (data.data.data.length) {
									for (var ii in data.data.data) {
										_this.noxPortraitRTests.push(data.data.data[ii]);
									}
								}
								_this.noxPortraitRTestsLimitOffset = data.data.count > _this.noxPortraitRTestsLimitOffset + _this.noxPortraitRTestsLimitCount ? _this.noxPortraitRTestsLimitOffset + _this.noxPortraitRTestsLimitCount : 0;
							}
							else if (['swapUpPortraitQuestion', 'swapDownPortraitQuestion'].includes(type)) {
								_this.noxPortraitQuestions = data.data;
							}
							else if (['swapUpPortraitAnswer', 'swapDownPortraitAnswer'].includes(type)) {
								_this.$set(_this.noxPortraitAnswers, var1, data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
						_this.noxPortraitQuestionsSwap = '';
						_this.noxPortraitAnswersSwap = '';
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForStatus: function(id, index, event) {
				this.axios('editPortraitQuestionStatus', id, index, Number(event.value));
			},
			toggleChangeForIsRandom: function(id, index, event) {
				this.axios('editPortraitQuestionIsRandom', id, index, Number(event.value));
			},
			toggleChangeForIsRandomPortraitQuestions: function(event) {
				this.axios('editPortraitQuestionsIsRandom', Number(event.value));
			}
		},
		components: {
			NoxModalPortraitSettings: () => import('@/views/modal_components/NoxModalPortraitSettings.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_portrait_settings_question tr th:nth-child(1),
	.nox_account_desktop_portrait_settings_question tr td:nth-child(1),
	.nox_account_desktop_portrait_settings_question tr th:nth-child(2),
	.nox_account_desktop_portrait_settings_question tr td:nth-child(2) {
		width: 34%;
	}
	.nox_account_desktop_portrait_settings_question tr td:nth-child(1),
	.nox_account_desktop_portrait_settings_question tr td:nth-child(2) {
		text-align: left;
	}
	.nox_account_desktop_portrait_settings_question tr th:nth-child(n + 3),
	.nox_account_desktop_portrait_settings_question tr td:nth-child(n + 3) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_portrait_settings_answers {
		margin: 20px 0 0 0;
	}
	.nox_account_desktop_portrait_settings_answers_title {
		margin: 0 0 5px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_portrait_settings_answers tr th:nth-child(1),
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(1),
	.nox_account_desktop_portrait_settings_answers tr th:nth-child(2),
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(2),
	.nox_account_desktop_portrait_settings_answers tr th:nth-child(3),
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(3),
	.nox_account_desktop_portrait_settings_answers tr th:nth-child(n + 6),
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(n + 6) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_portrait_settings_answers tr th:nth-child(4),
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(4),
	.nox_account_desktop_portrait_settings_answers tr th:nth-child(5),
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(5) {
		width: 34%;
	}
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(4),
	.nox_account_desktop_portrait_settings_answers tr td:nth-child(5) {
		text-align: left;
	}
	.nox_account_desktop_portrait_settings_result tr th:nth-child(1),
	.nox_account_desktop_portrait_settings_result tr td:nth-child(1),
	.nox_account_desktop_portrait_settings_result tr th:nth-child(2),
	.nox_account_desktop_portrait_settings_result tr td:nth-child(2),
	.nox_account_desktop_portrait_settings_result tr th:nth-child(3),
	.nox_account_desktop_portrait_settings_result tr td:nth-child(3),
	.nox_account_desktop_portrait_settings_result tr th:nth-child(n + 6),
	.nox_account_desktop_portrait_settings_result tr td:nth-child(n + 6) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_portrait_settings_result tr th:nth-child(4),
	.nox_account_desktop_portrait_settings_result tr td:nth-child(4),
	.nox_account_desktop_portrait_settings_result tr th:nth-child(5),
	.nox_account_desktop_portrait_settings_result tr td:nth-child(5) {
		width: 36%;
	}
	.nox_account_desktop_portrait_settings_result tr td:nth-child(4),
	.nox_account_desktop_portrait_settings_result tr td:nth-child(5) {
		text-align: left;
	}
	.nox_account_desktop_portrait_settings_rtests tr th:nth-child(1),
	.nox_account_desktop_portrait_settings_rtests tr td:nth-child(1),
	.nox_account_desktop_portrait_settings_rtests tr th:nth-child(5),
	.nox_account_desktop_portrait_settings_rtests tr td:nth-child(5) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_portrait_settings_rtests tr th:nth-child(2),
	.nox_account_desktop_portrait_settings_rtests tr td:nth-child(2),
	.nox_account_desktop_portrait_settings_rtests tr th:nth-child(3),
	.nox_account_desktop_portrait_settings_rtests tr td:nth-child(3),
	.nox_account_desktop_portrait_settings_rtests tr th:nth-child(4),
	.nox_account_desktop_portrait_settings_rtests tr td:nth-child(4) {
		width: 30%;
	}
</style>
