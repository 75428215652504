<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_loading" :class="{ hide: !$store.state.noxIsLoading }"></div>
		<div class="nox_account" :class="{ hide: $store.state.noxIsLoading }">
			<div class="nox_account_sidebar">
				<div class="nox_account_sidebar_logo">
					<router-link to="/info">
						<div><img src="@/assets/images/logo.png"></div>
						<div v-html="$store.getters.getLanguageText('2', 10)"></div>
					</router-link>
				</div>
				<div class="nox_account_sidebar_socs" is="NoxCommonSocialNetworks"></div>
				<div class="nox_account_sidebar_menu">
					<router-link to="/info" class="account" :class="{ active: noxIsActiveNews }" v-html="$store.getters.getLanguageText('2.5.1', 1)"></router-link>
					<router-link to="/info/stats" v-html="$store.getters.getLanguageText('2.5.1', 3)"></router-link>
					<router-link to="/info/analysis" v-html="$store.getters.getLanguageText('2.5.1', 19)"></router-link>
					<a href="https://forum.nox.bz/topic/21-faq-otvety-na-chasto-zadavaemye-voprosy-pro-nox" target="_blank" v-html="$store.getters.getLanguageText('2.5.1', 16)"></a>
					<router-link to="/info/calculators" v-html="$store.getters.getLanguageText('2.5.1', 15)"></router-link>
				</div>
			</div>
			<div class="nox_account_sidebar_mobile">
				<div class="nox_account_sidebar_mobile_title">
					<router-link to="/info"><img src="@/assets/images/mobile_logo.png"><span>NOX</span><span v-html="$store.getters.getLanguageText('2', 11)"></span></router-link>
				</div>
				<div class="nox_account_sidebar_mobile_button" @click="$parent.toggleMobileMenu">
					<font-awesome-icon :icon="['fas', 'times']" v-if="$store.state.noxIsMobileMenu" />
					<font-awesome-icon :icon="['fas', 'bars']" v-else />
				</div>
				<div class="nox_account_sidebar_mobile_menu" :class="{ hidden: !$store.state.noxIsMobileMenu }">
					<div class="nox_account_sidebar_mobile_menu_content">
						<div class="nox_account_sidebar_mobile_menu_links">
							<div><router-link to="/info" class="account" :class="{ active: noxIsActiveNews }" v-html="$store.getters.getLanguageText('2.5.1', 1)"></router-link></div>
							<div><router-link to="/info/stats" v-html="$store.getters.getLanguageText('2.5.1', 3)"></router-link></div>
							<div><router-link to="/info/analysis" v-html="$store.getters.getLanguageText('2.5.1', 19)"></router-link></div>
							<div><a href="https://forum.nox.bz/topic/21-faq-otvety-na-chasto-zadavaemye-voprosy-pro-nox" target="_blank" v-html="$store.getters.getLanguageText('2.5.1', 16)"></a></div>
							<div><router-link to="/info/calculators" v-html="$store.getters.getLanguageText('2.5.1', 15)"></router-link></div>
						</div>
						<div class="nox_account_sidebar_mobile_menu_login">
							<div><router-link to="/login" v-html="$store.getters.getLanguageText('2.5.1', 10)"></router-link></div>
							<div><router-link to="/registration" v-html="$store.getters.getLanguageText('2.5.1', 11)"></router-link></div>
						</div>
						<div class="nox_account_sidebar_mobile_menu_language">
							<div class="nox_account_sidebar_mobile_menu_language_name" :class="{ hide: !$store.state.noxIsLanguageRU }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_sidebar_mobile_menu_language_name text" :class="{ hide: $store.state.noxIsLanguageRU, active: $store.state.noxLanguage == 'ru' }" @click="$parent.selectLanguage('ru')"><span v-html="$store.getters.getLanguageText('2', 1, { options: [0] })"></span></div>
							<div class="nox_account_sidebar_mobile_menu_language_name" :class="{ hide: !$store.state.noxIsLanguageEN }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_sidebar_mobile_menu_language_name text" :class="{ hide: $store.state.noxIsLanguageEN, active: $store.state.noxLanguage == 'en' }" @click="$parent.selectLanguage('en')"><span v-html="$store.getters.getLanguageText('2', 1, { options: [1] })"></span></div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop">
				<div class="nox_account_desktop_header">
					<div class="nox_account_desktop_header_item">
						<div class="nox_account_desktop_header_item_language">
							<div class="nox_account_desktop_header_item_language_name" :class="{ hide: !$store.state.noxIsLanguageRU }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_header_item_language_name text" :class="{ hide: $store.state.noxIsLanguageRU, active: $store.state.noxLanguage == 'ru' }" @click="$parent.selectLanguage('ru')" v-html="$store.getters.getLanguageText('2', 1, { options: [0] })"></div>
							<div class="nox_account_desktop_header_item_language_name" :class="{ hide: !$store.state.noxIsLanguageEN }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_header_item_language_name text" :class="{ hide: $store.state.noxIsLanguageEN, active: $store.state.noxLanguage == 'en' }" @click="$parent.selectLanguage('en')" v-html="$store.getters.getLanguageText('2', 1, { options: [1] })"></div>
						</div>
					</div>
					<router-link to="/registration" class="nox_account_desktop_header_item menu text" v-html="$store.getters.getLanguageText('2.5.1', 11)"></router-link>
					<router-link to="/login" class="nox_account_desktop_header_item menu text" v-html="$store.getters.getLanguageText('2.5.1', 10)"></router-link>
					<a :href="$store.state.noxBaseURL" class="nox_account_desktop_header_item menu text" v-html="$store.getters.getLanguageText('2.5.1', 23)"></a>
				</div>
				<div class="nox_account_desktop_content"><router-view></router-view></div>
				<div class="nox_account_desktop_footer">
					<div class="nox_account_desktop_footer_copyright" is="NoxCommonCopyright"></div>
					<div class="nox_account_desktop_footer_social_networks" is="NoxCommonSocialNetworks"></div>
					<div class="nox_account_desktop_footer_mobile_applications" is="NoxCommonMobileApplications"></div>
				</div>
			</div>
			<div class="nox_account_preload">
				<img src="@/assets/images/bot_img_10.png">
				<img src="@/assets/images/bot_risk_0.png">
				<img src="@/assets/images/bot_risk_1.png">
				<img src="@/assets/images/bot_risk_2.png">
				<img src="@/assets/images/bot_risk_3.png">
				<img src="@/assets/images/bot_risk_4.png">
				<img src="@/assets/images/bot_risk_phoenix_v2.png">
				<img src="@/assets/images/bot_risk_phoenix_v3.png">
				<img src="@/assets/images/bot_risk_insomnia.png">
				<img src="@/assets/images/phoenix_icons.png">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			noxIsActiveNews: false
		}),
		watch: {
			$route() {
				this.noxIsActiveNews = false;
			}
		},
		components: {
			NoxCommonCopyright: () => import('@/views/common_components/NoxCommonCopyright.vue'),
			NoxCommonSocialNetworks: () => import('@/views/common_components/NoxCommonSocialNetworks.vue'),
			NoxCommonMobileApplications: () => import('@/views/common_components/NoxCommonMobileApplications.vue')
		},
		created: function() {
			this.$store.state.noxIsLoading = true;
		}
	}
</script>
