<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_admin_quests">
				<div class="nox_tabs">
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)">Задания</label>
					<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
					<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)">Скрипты</label>
					<div class="nox_tab_block" id="nox_tab_block_1">
						<div class="nox_account_desktop_admin_quests_tasks">
							<div class="nox_account_desktop_portlet">
								<div class="nox_account_desktop_portlet_title">NOX Quest tasks</div>
								<div class="nox_account_desktop_portlet_content">
									<table class="nox_table" v-if="noxQuestsTasks.length">
										<thead>
											<tr>
												<th>№</th>
												<th>Тип</th>
												<th>Задание (RU)</th>
												<th>Задание (EN)</th>
												<th><font-awesome-icon :icon="['fas', 'star-half-alt']" /></th>
												<th>Статус</th>
												<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxQuestsTasks" :key="index">
												<td data-label="№:">{{ (index + 1) }}</td>
												<td data-label="Тип:" :class="[(value.type == 2 ? 'blue' : 'green')]">{{ noxQuestsTasksTypes[value.type - 1].label }}</td>
												<td data-label="Задание (RU):"><b v-html="value.name_ru"></b><br><br><span v-html="value.text_ru"></span></td>
												<td data-label="Задание (EN):"><b v-html="value.name_en"></b><br><br><span v-html="value.text_en"></span></td>
												<td data-label="Приоритет:">{{ value.priority }}</td>
												<td data-label="Статус:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.is_active)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActive(value.id, index, $event)"></toggle-button></td>
												<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalAdminQuests', { type: 'editQuestsTask', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
												<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalAdminQuests', { type: 'deleteQuestsTask', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_portlet_buttons">
									<button type="button" class="nox_button big green" @click="$modal.show('NoxModalAdminQuests', { type: 'addQuestsTask' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить задание</button>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_tab_block" id="nox_tab_block_2">
						<div class="nox_account_desktop_admin_quests_scripts">
							<div class="nox_account_desktop_portlet">
								<div class="nox_account_desktop_portlet_title">NOX Quest scripts</div>
								<div class="nox_account_desktop_portlet_content">
									<table class="nox_table" v-if="noxQuestsScripts.length">
										<thead>
											<tr>
												<th>№</th>
												<th>Название</th>
												<th>Статус</th>
												<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
												<th><font-awesome-icon :icon="['fas', 'times']" /></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxQuestsScripts" :key="index">
												<td data-label="№:">{{ value.id }}</td>
												<td data-label="Название:" v-html="value.name"></td>
												<td data-label="Статус:" :class="[(value.status ? 'green' : 'red')]">{{ (value.status ? 'Работает' : 'Не работает') }}</td>
												<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalAdminQuests', { type: 'editQuestsScript', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
												<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalAdminQuests', { type: 'deleteQuestsScript', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_portlet_buttons">
									<button type="button" class="nox_button big green" @click="$modal.show('NoxModalAdminQuests', { type: 'addQuestsScript' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить скрипт</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalAdminQuests"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxQuestsTasks: [],
			noxQuestsTasksTypes: [
				{ code: 1, label: 'Задание с кнопками' },
				{ code: 2, label: 'Задание со скриптом' }
			],
			noxQuestsTasksScripts: [],
			noxQuestsScripts: [],
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || [355,463].includes(this.$store.state.noxAccountData.id)) {
					this.axios('getQuests');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initQuestsTasksScripts: function() {
				this.noxQuestsTasksScripts = [];
				if (this.noxQuestsScripts.length) {
					for (var i in this.noxQuestsScripts) {
						if (this.noxQuestsScripts[i].status) {
							this.noxQuestsTasksScripts.push({ code: this.noxQuestsScripts[i].id, label: this.noxQuestsScripts[i].name, is_x_count: this.noxQuestsScripts[i].is_x_count });
						}
					}
				}
			},
			initTabs: function(tab) {
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				if (tab == 2) { this.noxIsTabMenu2Checked = true; }
				else          { this.noxIsTabMenu1Checked = true; }
			},
			selectTab: function(tab) {
				if (Number(this.$route.query.tab) !== tab) {
					this.$router.push({ path: '/account/admin/quests', query: { tab: tab }});
				}
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getQuests') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/quests';
					}
					else if (type == 'editQuestsTaskIsActive') {
						_this.noxQuestsTasks[var2].is_active = var3;
						config.url = '/v2/admin/account/quests/tasks/is_active';
						config.data = { id: var1, is_active: var3 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getQuests') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.quests_tasks !== undefined) {
									_this.noxQuestsTasks = data.data.quests_tasks;
								}
								if (data.data.quests_scripts !== undefined) {
									_this.noxQuestsScripts = data.data.quests_scripts;
									_this.initQuestsTasksScripts();
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForIsActive: function(id, index, event) {
				this.axios('editQuestsTaskIsActive', id, index, Number(event.value));
			}
		},
		components: {
			NoxModalAdminQuests: () => import('@/views/modal_components/NoxModalAdminQuests.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_admin_quests_tasks tr th:nth-child(1),
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(1),
	.nox_account_desktop_admin_quests_tasks tr th:nth-child(5),
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(5),
	.nox_account_desktop_admin_quests_tasks tr th:nth-child(6),
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(6),
	.nox_account_desktop_admin_quests_tasks tr th:nth-child(7),
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(7),
	.nox_account_desktop_admin_quests_tasks tr th:nth-child(8),
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(8),
	.nox_account_desktop_admin_quests_scripts tr th:nth-child(1),
	.nox_account_desktop_admin_quests_scripts tr td:nth-child(1),
	.nox_account_desktop_admin_quests_scripts tr th:nth-child(4),
	.nox_account_desktop_admin_quests_scripts tr td:nth-child(4),
	.nox_account_desktop_admin_quests_scripts tr th:nth-child(5),
	.nox_account_desktop_admin_quests_scripts tr td:nth-child(5) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_admin_quests_tasks tr th:nth-child(2),
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(2),
	.nox_account_desktop_admin_quests_scripts tr th:nth-child(3),
	.nox_account_desktop_admin_quests_scripts tr td:nth-child(3) {
		min-width: 100px;
		width: 1%;
	}
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(3),
	.nox_account_desktop_admin_quests_tasks tr td:nth-child(4) {
		text-align: left;
	}
	.nox_account_desktop_admin_quests_tasks tr td ul li {
		list-style: inside;
	}
	.nox_account_desktop_admin_quests_tasks tr td .quest_task_type_1,
	.nox_account_desktop_admin_quests_tasks tr td .quest_task_type_2 {
		display: table;
		width: 100%;
	}
	.nox_account_desktop_admin_quests_tasks tr td .quest_task_type_1 img {
		float: left;
		margin: 3px 10px 5px 0;
		display: block;
		width: 100px;
	}
	.nox_account_desktop_admin_quests_tasks tr td .quest_task_type_2 {
		text-align: center;
	}
	.nox_account_desktop_admin_quests_tasks tr td .quest_task_type_2 img {
		position: relative;
		left: 50%;
		margin: 0 0 0 -50px;
		display: block;
		width: 100px;
	}
</style>
