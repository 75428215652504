<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_statistics">
				<div class="nox_account_desktop_statistics_navigation type">
					<div class="nox_account_desktop_statistics_navigation_filters">
						<div class="nox_account_desktop_statistics_navigation_filters_label">Тип статистики:</div>
						<div class="nox_account_desktop_statistics_navigation_filters_value" :class="{ active: noxStatisticsFilterType == 0 }"><div @click="getStatisticsDataByType(1, 0)">Статистика работы ботов</div></div>
						<div class="nox_account_desktop_statistics_navigation_filters_value" :class="{ active: noxStatisticsFilterType == 1 }"><div @click="getStatisticsDataByType(2, 1)">Статистика клиентской программы</div></div>
						<div class="nox_account_desktop_statistics_navigation_filters_value" :class="{ active: noxStatisticsFilterType == 2 }"><div @click="getStatisticsDataByType(3, 2)">Аналитика NOX Phoenix</div></div>
					</div>
				</div>
				<div class="nox_account_desktop_statistics_navigation count">
					<div class="nox_account_desktop_statistics_navigation_filters">
						<div class="nox_account_desktop_statistics_navigation_filters_label">Количество дней:</div>
						<div class="nox_account_desktop_statistics_navigation_filters_select"><v-select class="nox_select style-chooser" v-model.trim="noxStatisticsFilter" placeholder="выберите фильтр" :options="noxStatisticsFilterOptions" @input="getStatisticsDataByCount"></v-select></div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-if="noxStatisticsFilterType == 0">
					<div class="nox_account_desktop_portlet_title">Статистика работы ботов</div>
					<div class="nox_account_desktop_portlet_content">
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart1"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart2"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart3"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart4"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart5"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart6"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart7"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart8"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart9"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart10"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart11"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart12"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart13"></highcharts>
						<highcharts class="nox_chart" :options="noxBotsStatisticsChart14"></highcharts>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-if="noxStatisticsFilterType == 1">
					<div class="nox_account_desktop_portlet_title">Статистика клиентской программы</div>
					<div class="nox_account_desktop_portlet_content">
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart1"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart2"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart3"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart4"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart5"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart6"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart7"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart8"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart9"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart10"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart11"></highcharts>
						<highcharts class="nox_chart" :options="noxTreeStatisticsChart12"></highcharts>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-if="noxStatisticsFilterType == 2">
					<div class="nox_account_desktop_portlet_title">Аналитика NOX Phoenix по шаблонам ботов</div>
					<div class="nox_account_desktop_portlet_content">
						<table class="nox_table" v-if="noxPhoenixStatistics1.length">
							<thead>
								<tr>
									<th>#</th>
									<th>Настройки шаблонов</th>
									<th>Список шаблонов</th>
									<th>Список ботов</th>
									<th>Средняя просадка</th>
									<th>Средняя прибыльность</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxPhoenixStatistics1" :key="index">
									<td data-label="#:">{{ (index + 1) }}</td>
									<td data-label="Настройки шаблонов:">{{ JSON.stringify(value.preset_settings) }}</td>
									<td data-label="Список шаблонов:">{{ value.preset_ids.join(', ') }}</td>
									<td data-label="Список ботов:">{{ value.bot_ids.join(', ') }}</td>
									<td data-label="Средняя просадка:">{{ value.bot_ids_balance_drawdown.toFixed(2) }}%</td>
									<td data-label="Средняя прибыльность:">{{ value.bot_ids_balance_profitability.toFixed(2) }}%</td>
								</tr>
							</tbody>
						</table>
						<div class="nox_account_desktop_portlet_content_line"></div>
						<div class="nox_chart" v-if="noxPhoenixStatistics1Chart1.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics1Chart1"></highcharts></div>
						</div>
						<div class="nox_chart" v-if="noxPhoenixStatistics1Chart2.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics1Chart2"></highcharts></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-if="noxStatisticsFilterType == 2">
					<div class="nox_account_desktop_portlet_title">Аналитика NOX Phoenix по агрессивности ботов</div>
					<div class="nox_account_desktop_portlet_content">
						<table class="nox_table" v-if="noxPhoenixStatistics2.length">
							<thead>
								<tr>
									<th>Тип потрендовой торговли</th>
									<th>Агрессивность бота</th>
									<th>Список ботов</th>
									<th>Средняя просадка</th>
									<th>Средняя прибыльность</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxPhoenixStatistics2" :key="index">
									<td data-label="Тип потрендовой торговли:">{{ $parent.$parent.getSelectOptions(13)[Number(value.bot_ids_trend_strategy)].label }}</td>
									<td data-label="Агрессивность бота:">{{ $parent.$parent.getSelectOptions(9)[(Number(value.bot_ids_aggressiveness) - 1)].label }}</td>
									<td data-label="Список ботов:">{{ value.bot_ids.join(', ') }}</td>
									<td data-label="Средняя просадка:">{{ value.bot_ids_balance_drawdown.toFixed(2) }}%</td>
									<td data-label="Средняя прибыльность:">{{ value.bot_ids_balance_profitability.toFixed(2) }}%</td>
								</tr>
							</tbody>
						</table>
						<div class="nox_account_desktop_portlet_content_line"></div>
						<div class="nox_chart" v-if="noxPhoenixStatistics2Chart1.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics2Chart1"></highcharts></div>
						</div>
						<div class="nox_chart" v-if="noxPhoenixStatistics2Chart2.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics2Chart2"></highcharts></div>
						</div>
						<div class="nox_chart" v-if="noxPhoenixStatistics2Chart3.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics2Chart3"></highcharts></div>
						</div>
						<div class="nox_chart" v-if="noxPhoenixStatistics2Chart4.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics2Chart4"></highcharts></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-if="noxStatisticsFilterType == 2">
					<div class="nox_account_desktop_portlet_title">Аналитика NOX Phoenix по ежедневной прибыли</div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_chart" v-if="noxPhoenixStatistics3Chart1.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics3Chart1"></highcharts></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet" v-if="noxStatisticsFilterType == 2">
					<div class="nox_account_desktop_portlet_title">Аналитика NOX Phoenix по просадкам ботов</div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_chart" v-if="noxPhoenixStatistics4Chart1.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics4Chart1"></highcharts></div>
						</div>
						<div class="nox_chart" v-if="noxPhoenixStatistics5Chart1.series.length">
							<div class="nox_chart_highcharts"><highcharts :options="noxPhoenixStatistics5Chart1"></highcharts></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxStatisticsFilter: '',
			noxStatisticsFilterType: 0,
			noxStatisticsFilterOptions: [
				{ code: 7, label: '7 дней' },
				{ code: 14, label: '14 дней' },
				{ code: 30, label: '30 дней' },
				{ code: 60, label: '60 дней' },
				{ code: 90, label: '90 дней' },
				{ code: 180, label: '180 дней' },
				{ code: 270, label: '270 дней' },
				{ code: 365, label: '365 дней' }
			],
			noxBotsStrategies: {},
			noxBotsStrategyNames: [],
			noxBotsNBServersNames: [],
			noxBotsStatisticsChart1: {},
			noxBotsStatisticsChart2: {},
			noxBotsStatisticsChart3: {},
			noxBotsStatisticsChart4: {},
			noxBotsStatisticsChart5: {},
			noxBotsStatisticsChart6: {},
			noxBotsStatisticsChart7: {},
			noxBotsStatisticsChart8: {},
			noxBotsStatisticsChart9: {},
			noxBotsStatisticsChart10: {},
			noxBotsStatisticsChart11: {},
			noxBotsStatisticsChart12: {},
			noxBotsStatisticsChart13: {},
			noxBotsStatisticsChart14: {},
			noxTreeStatisticsChart1: {},
			noxTreeStatisticsChart2: {},
			noxTreeStatisticsChart3: {},
			noxTreeStatisticsChart4: {},
			noxTreeStatisticsChart5: {},
			noxTreeStatisticsChart6: {},
			noxTreeStatisticsChart7: {},
			noxTreeStatisticsChart8: {},
			noxTreeStatisticsChart9: {},
			noxTreeStatisticsChart10: {},
			noxTreeStatisticsChart11: {},
			noxTreeStatisticsChart12: {},
			noxPhoenixStatistics1: [],
			noxPhoenixStatistics2: [],
			noxPhoenixStatistics3: {},
			noxPhoenixStatistics4: {},
			noxPhoenixStatistics5: {},
			noxPhoenixStatistics1Details: [],
			noxPhoenixStatistics2Details: [],
			noxPhoenixStatistics3Details: [],
			noxPhoenixStatistics1Chart1: {},
			noxPhoenixStatistics1Chart2: {},
			noxPhoenixStatistics2Chart1: {},
			noxPhoenixStatistics2Chart2: {},
			noxPhoenixStatistics2Chart3: {},
			noxPhoenixStatistics2Chart4: {},
			noxPhoenixStatistics3Chart1: {},
			noxPhoenixStatistics4Chart1: {},
			noxPhoenixStatistics5Chart1: {},
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				if (to.path == '/account/statistics' && !Object.keys(to.query).length) {
					this.noxStatisticsFilter = this.noxStatisticsFilterOptions[2];
					this.getStatisticsDataByType(0, 0);
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.noxStatisticsFilter = this.noxStatisticsFilterOptions[2];
					this.axios(['getBotsStatistics', 'getTreeStatistics', 'getPhoenixStatistics'][this.noxStatisticsFilterType]);
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initTabs: function(tab) {
				if (tab !== undefined && [1,2,3].includes(Number(tab))) {
					this.noxStatisticsFilterType = Number(tab) - 1;
				}
			},
			getStatisticsDataByType: function(tab, type) {
				if (tab && tab !== Number(this.$route.query.tab)) {
					this.$router.push({ path: '/account/statistics', query: { tab: tab }});
				}
				this.$parent.$parent.goToTop(true);
				this.noxStatisticsFilterType = type;
				this.axios(['getBotsStatistics', 'getTreeStatistics', 'getPhoenixStatistics'][this.noxStatisticsFilterType]);
			},
			getStatisticsDataByCount: function() {
				this.$parent.$parent.goToTop(true);
				this.axios(['getBotsStatistics', 'getTreeStatistics', 'getPhoenixStatistics'][this.noxStatisticsFilterType]);
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getBotsStatistics') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/bots/statistics/publish/details';
						config.params = { count: _this.noxStatisticsFilter.code };
					}
					else if (type == 'getTreeStatistics') {
						_this.noxIsLoading = true;
						config.url = '/v2/admin/account/tree/statistics/details';
						config.params = { count: _this.noxStatisticsFilter.code };
					}
					else if (type == 'getPhoenixStatistics') {
						_this.noxIsLoading = true;
						config.url = '/v2/admin/account/phoenix/statistics';
						config.params = { count: _this.noxStatisticsFilter.code };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.$parent.goToTop(true);
							_this.$parent.$parent.checkAppVersion(data.headers);
							if (type == 'getBotsStatistics') {
								_this.noxBotsStatisticsChart1 = {
									title: { text: 'Количество ботов и пользователей' },
									chart: { height: 400 },
									legend: { enabled: true },
									series: [
										{ name: 'Всего пользователей', data: [] },
										{ name: 'Всего ботов с Alien', data: [] },
										{ name: 'Всего ботов без Alien', data: [] },
										{ name: 'Ботов Demo с Alien', data: [] },
										{ name: 'Ботов Demo без Alien', data: [] },
										{ name: 'Ботов онлайн с Alien', data: [] },
										{ name: 'Ботов онлайн без Alien', data: [] },
										{ name: 'Ботов Insomnia с Alien', data: [] },
										{ name: 'Ботов Insomnia без Alien', data: [] },
										{ name: 'Ботов Old Version с Alien', data: [] },
										{ name: 'Ботов Old Version без Alien', data: [] },
										{ name: 'Ботов Telegram уведомлений', data: [] },
										{ name: 'Просроченная подписка', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormat(this, 0);
									}}
								};
								_this.noxBotsStatisticsChart2 = {
									title: { text: 'Количество ботов NOX Phoenix' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Ботов NOX Phoenix Classic с Alien', data: [] },
										{ name: 'Ботов NOX Phoenix Classic без Alien', data: [] },
										{ name: 'Ботов NOX Phoenix ZZ с Alien', data: [] },
										{ name: 'Ботов NOX Phoenix ZZ без Alien', data: [] },
										{ name: 'Ботов NOX Phoenix NT с Alien', data: [] },
										{ name: 'Ботов NOX Phoenix NT без Alien', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormat(this, 0);
									}}
								};
								_this.noxBotsStatisticsChart3 = {
									title: { text: 'Статистика серых приглашений' },
									chart: { height: 200 },
									legend: { enabled: true },
									series: [
										{ name: 'Общее количество серых аккаунтов', color: '#7CB5EC', data: [] },
										{ name: 'Общее количество перепроданных серых аккаунтов', color: '#1EC455', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormat(this, 0);
									}}
								};
								_this.noxBotsStatisticsChart4 = {
									title: { text: 'Чистая и балансовая прибыль' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Чистая прибыль с Alien', data: [] },
										{ name: 'Чистая прибыль без Alien', data: [] },
										{ name: 'Балансовая прибыль с Alien', data: [] },
										{ name: 'Балансовая прибыль без Alien', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxBotsStatisticsChart5 = {
									title: { text: 'Прибыльные сделки и дельта прибыли' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Прибыльные сделки с Alien', data: [] },
										{ name: 'Прибыльные сделки без Alien', data: [] },
										{ name: 'Дельта прибыли с Alien', data: [] },
										{ name: 'Дельта прибыли без Alien', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 2);
									}}
								};
								_this.noxBotsStatisticsChart6 = {
									title: { text: 'Средняя месячная прибыль по стратегиям' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 2);
									}}
								};
								_this.noxBotsStatisticsChart7 = {
									title: { text: 'Сумма депозитов, снятий, балансов и средств' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'SUM депозитов с Alien', data: [] },
										{ name: 'SUM депозитов без Alien', data: [] },
										{ name: 'SUM снятий с Alien', data: [] },
										{ name: 'SUM снятий без Alien', data: [] },
										{ name: 'SUM балансов с Alien', data: [] },
										{ name: 'SUM балансов без Alien', data: [] },
										{ name: 'SUM средств с Alien', data: [] },
										{ name: 'SUM средств без Alien', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxBotsStatisticsChart8 = {
									title: { text: 'Дельта сумм' },
									legend: { enabled: true },
									series: [
										{ name: 'Дельта сумм с Alien', color: '#F45B5B', data: [] },
										{ name: 'Дельта сумм без Alien', color: '#1EC455', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 2);
									}}
								};
								_this.noxBotsStatisticsChart9 = {
									title: { text: 'Просадка' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'MIN просадка с Alien', data: [] },
										{ name: 'MIN просадка без Alien', data: [] },
										{ name: 'MAX просадка с Alien', data: [] },
										{ name: 'MAX просадка без Alien', data: [] },
										{ name: 'AVG просадка с Alien', data: [] },
										{ name: 'AVG просадка без Alien', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 2);
									}}
								};
								_this.noxBotsStatisticsChart10 = {
									title: { text: 'Объем средств' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'MIN объем средств с Alien', data: [] },
										{ name: 'MIN объем средств без Alien', data: [] },
										{ name: 'MAX объем средств с Alien', data: [] },
										{ name: 'MAX объем средств без Alien', data: [] },
										{ name: 'AVG объем средств с Alien', data: [] },
										{ name: 'AVG объем средств без Alien', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxBotsStatisticsChart11 = {
									title: { text: 'Уровень маржи' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'MIN уровень маржи с Alien', data: [] },
										{ name: 'MIN уровень маржи без Alien', data: [] },
										{ name: 'MAX уровень маржи с Alien', data: [] },
										{ name: 'MAX уровень маржи без Alien', data: [] },
										{ name: 'AVG уровень маржи с Alien', data: [] },
										{ name: 'AVG уровень маржи без Alien', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 0);
									}}
								};
								_this.noxBotsStatisticsChart12 = {
									title: { text: 'Интеграция продукта' },
									legend: { enabled: true },
									series: [
										{ name: 'Интеграция продукта с Alien', color: '#F45B5B', data: [] },
										{ name: 'Интеграция продукта без Alien', color: '#1EC455', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 2);
									}}
								};
								_this.noxBotsStatisticsChart13 = {
									title: { text: 'Нагрузка на сервера NOX Box v.2' },
									chart: { height: 550 },
									legend: { enabled: true },
									series: [],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 2);
									}}
								};
								_this.noxBotsStatisticsChart14 = {
									title: { text: 'Балансы на счетах' },
									chart: { type: 'pie', height: 450 },
									series: [{ name: 'Количество ботов', colorByPoint: true, data: [] }],
									plotOptions: {
										pie: {
											allowPointSelect: true,
											cursor: 'pointer',
											dataLabels: { enabled: true, format: '<b>{point.name}</b>: {point.percentage:.2f}%' }
										}
									}
								};
								if (data.data.bots_strategies !== undefined) {
									if (data.data.bots_strategies.length) {
										for (var strategy in data.data.bots_strategies) {
											_this.noxBotsStrategies[data.data.bots_strategies[strategy].id] = data.data.bots_strategies[strategy];
										}
									}
								}
								if (data.data.bots_statistics_publish_details_1 !== undefined) {
									if (data.data.bots_statistics_publish_details_1.length) {
										var statistics1 = data.data.bots_statistics_publish_details_1;
										for (var s1 in statistics1) {
											var s1_created_at = parseInt(statistics1[s1].created_at) * 1000;
											_this.noxBotsStatisticsChart1.series[0].data.push([s1_created_at, parseInt(statistics1[s1].number_of_users)]);
											_this.noxBotsStatisticsChart1.series[1].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients)]);
											_this.noxBotsStatisticsChart1.series[3].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_demo)]);
											_this.noxBotsStatisticsChart1.series[5].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_online)]);
											_this.noxBotsStatisticsChart1.series[7].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_insomnia)]);
											_this.noxBotsStatisticsChart1.series[9].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_old_version)]);
											_this.noxBotsStatisticsChart1.series[11].data.push([s1_created_at, parseInt(statistics1[s1].number_of_telegram_users)]);
											_this.noxBotsStatisticsChart1.series[12].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_without_subscription)]);
											_this.noxBotsStatisticsChart2.series[0].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_classic_trading)]);
											_this.noxBotsStatisticsChart2.series[2].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_zigzag_trading)]);
											_this.noxBotsStatisticsChart2.series[4].data.push([s1_created_at, parseInt(statistics1[s1].number_of_clients_news_trading)]);
											_this.noxBotsStatisticsChart3.series[0].data.push([s1_created_at, parseInt(statistics1[s1].number_of_grey_buy_users)]);
											_this.noxBotsStatisticsChart3.series[1].data.push([s1_created_at, parseInt(statistics1[s1].number_of_grey_3_users)]);
											_this.noxBotsStatisticsChart4.series[0].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].total_customer_real_profit / 100))]);
											_this.noxBotsStatisticsChart4.series[2].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].total_customer_profit / 100))]);
											_this.noxBotsStatisticsChart5.series[0].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].percentage_of_profitable_trades))]);
											_this.noxBotsStatisticsChart5.series[2].data.push([s1_created_at, parseFloat(((1 - (statistics1[s1].total_customer_real_profit / statistics1[s1].total_customer_profit)) * 100).toFixed(2))]);
											_this.noxBotsStatisticsChart7.series[0].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].sum_deposits / 100))]);
											_this.noxBotsStatisticsChart7.series[2].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].sum_withdrawals / 100))]);
											_this.noxBotsStatisticsChart7.series[4].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].sum_balance / 100))]);
											_this.noxBotsStatisticsChart7.series[6].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].sum_funds / 100))]);
											_this.noxBotsStatisticsChart8.series[0].data.push([s1_created_at, parseFloat(((1 - (statistics1[s1].sum_funds / statistics1[s1].sum_balance)) * 100).toFixed(2))]);
											_this.noxBotsStatisticsChart9.series[0].data.push([s1_created_at, parseFloat(statistics1[s1].max_drawdown.toFixed(2))]);
											_this.noxBotsStatisticsChart9.series[2].data.push([s1_created_at, parseFloat(statistics1[s1].min_drawdown.toFixed(2))]);
											_this.noxBotsStatisticsChart9.series[4].data.push([s1_created_at, parseFloat(statistics1[s1].avg_drawdown.toFixed(2))]);
											_this.noxBotsStatisticsChart10.series[0].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].min_funds / 100))]);
											_this.noxBotsStatisticsChart10.series[2].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].max_funds / 100))]);
											_this.noxBotsStatisticsChart10.series[4].data.push([s1_created_at, parseInt(Math.ceil(statistics1[s1].avg_funds / 100))]);
											_this.noxBotsStatisticsChart11.series[0].data.push([s1_created_at, parseInt(Math.floor(statistics1[s1].min_margin_level))]);
											_this.noxBotsStatisticsChart11.series[2].data.push([s1_created_at, parseInt(Math.floor(statistics1[s1].max_margin_level))]);
											_this.noxBotsStatisticsChart11.series[4].data.push([s1_created_at, parseInt(Math.floor(statistics1[s1].avg_margin_level))]);
											_this.noxBotsStatisticsChart12.series[0].data.push([s1_created_at, parseFloat((statistics1[s1].number_of_users_with_bots_online * 100 / (statistics1[s1].number_of_users_with_buy_package ? statistics1[s1].number_of_users_with_buy_package : 1)).toFixed(2))]);
											if (Object.keys(statistics1[s1].profitability_strategies).length) {
												for (var s1_strategy_id in statistics1[s1].profitability_strategies) {
													if (!_this.noxBotsStrategyNames.includes(_this.noxBotsStrategies[s1_strategy_id].name + ' (c Alien)')) {
														_this.noxBotsStrategyNames.push(_this.noxBotsStrategies[s1_strategy_id].name + ' (c Alien)');
														_this.noxBotsStrategyNames.push(_this.noxBotsStrategies[s1_strategy_id].name + ' (без Alien)');
													}
												}
											}
											if (Object.keys(statistics1[s1].nox_box_servers).length) {
												for (var s1_server_name in statistics1[s1].nox_box_servers) {
													if (!_this.noxBotsNBServersNames.includes(s1_server_name + ' (CPU)')) {
														_this.noxBotsNBServersNames.push(s1_server_name + ' (CPU)');
														_this.noxBotsNBServersNames.push(s1_server_name + ' (RAM)');
														_this.noxBotsNBServersNames.push(s1_server_name + ' (SSD)');
													}
												}
											}
										}
									}
									if (_this.noxBotsStrategyNames.length) {
										_this.noxBotsStrategyNames.sort();
										for (var m in _this.noxBotsStrategyNames) {
											_this.noxBotsStatisticsChart6.series.push({ name: _this.noxBotsStrategyNames[m], color: _this.$store.state.noxChartColors[m], data: [] });
										}
										if (data.data.bots_statistics_publish_details_1 !== undefined) {
											if (data.data.bots_statistics_publish_details_1.length) {
												for (var m_1 in data.data.bots_statistics_publish_details_1) {
													if (Object.keys(data.data.bots_statistics_publish_details_1[m_1].profitability_strategies).length) {
														for (var m_1_1 in data.data.bots_statistics_publish_details_1[m_1].profitability_strategies) {
															_this.noxBotsStatisticsChart6.series[_this.noxBotsStrategyNames.indexOf(_this.noxBotsStrategies[m_1_1].name + ' (c Alien)')].data.push([parseInt(data.data.bots_statistics_publish_details_1[m_1].created_at) * 1000, parseFloat(data.data.bots_statistics_publish_details_1[m_1].profitability_strategies[m_1_1][2])]);
														}
													}
												}
											}
										}
										if (data.data.bots_statistics_publish_details_2 !== undefined) {
											if (data.data.bots_statistics_publish_details_2.length) {
												for (var m_2 in data.data.bots_statistics_publish_details_2) {
													if (Object.keys(data.data.bots_statistics_publish_details_2[m_2].profitability_strategies).length) {
														for (var m_2_1 in data.data.bots_statistics_publish_details_2[m_2].profitability_strategies) {
															_this.noxBotsStatisticsChart6.series[_this.noxBotsStrategyNames.indexOf(_this.noxBotsStrategies[m_2_1].name + ' (без Alien)')].data.push([parseInt(data.data.bots_statistics_publish_details_2[m_2].created_at) * 1000, parseFloat(data.data.bots_statistics_publish_details_2[m_2].profitability_strategies[m_2_1][2])]);
														}
													}
												}
											}
										}
									}
									if (_this.noxBotsNBServersNames.length) {
										_this.noxBotsNBServersNames.sort();
										for (var n in _this.noxBotsNBServersNames) {
											_this.noxBotsStatisticsChart13.series.push({ name: _this.noxBotsNBServersNames[n], color: _this.$store.state.noxChartColors[n], data: [] });
										}
										if (data.data.bots_statistics_publish_details_1 !== undefined) {
											if (data.data.bots_statistics_publish_details_1.length) {
												for (var n_1 in data.data.bots_statistics_publish_details_1) {
													if (Object.keys(data.data.bots_statistics_publish_details_1[n_1].nox_box_servers).length) {
														for (var n_1_1 in data.data.bots_statistics_publish_details_1[n_1].nox_box_servers) {
															_this.noxBotsStatisticsChart13.series[_this.noxBotsNBServersNames.indexOf(n_1_1 + ' (CPU)')].data.push([parseInt(data.data.bots_statistics_publish_details_1[n_1].created_at) * 1000, parseFloat(data.data.bots_statistics_publish_details_1[n_1].nox_box_servers[n_1_1][0])]);
															_this.noxBotsStatisticsChart13.series[_this.noxBotsNBServersNames.indexOf(n_1_1 + ' (RAM)')].data.push([parseInt(data.data.bots_statistics_publish_details_1[n_1].created_at) * 1000, parseFloat(data.data.bots_statistics_publish_details_1[n_1].nox_box_servers[n_1_1][1])]);
															_this.noxBotsStatisticsChart13.series[_this.noxBotsNBServersNames.indexOf(n_1_1 + ' (SSD)')].data.push([parseInt(data.data.bots_statistics_publish_details_1[n_1].created_at) * 1000, parseFloat(data.data.bots_statistics_publish_details_1[n_1].nox_box_servers[n_1_1][2])]);
														}
													}
												}
											}
										}
									}
								}
								if (data.data.bots_statistics_publish_details_2 !== undefined) {
									if (data.data.bots_statistics_publish_details_2.length) {
										var statistics2 = data.data.bots_statistics_publish_details_2;
										for (var s2 in statistics2) {
											var s2_created_at = parseInt(statistics2[s2].created_at) * 1000;
											_this.noxBotsStatisticsChart1.series[2].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients)]);
											_this.noxBotsStatisticsChart1.series[4].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients_demo)]);
											_this.noxBotsStatisticsChart1.series[6].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients_online)]);
											_this.noxBotsStatisticsChart1.series[8].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients_insomnia)]);
											_this.noxBotsStatisticsChart1.series[10].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients_old_version)]);
											_this.noxBotsStatisticsChart2.series[1].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients_classic_trading)]);
											_this.noxBotsStatisticsChart2.series[3].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients_zigzag_trading)]);
											_this.noxBotsStatisticsChart2.series[5].data.push([s2_created_at, parseInt(statistics2[s2].number_of_clients_news_trading)]);
											_this.noxBotsStatisticsChart4.series[1].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].total_customer_real_profit / 100))]);
											_this.noxBotsStatisticsChart4.series[3].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].total_customer_profit / 100))]);
											_this.noxBotsStatisticsChart5.series[1].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].percentage_of_profitable_trades))]);
											_this.noxBotsStatisticsChart5.series[3].data.push([s2_created_at, parseFloat(((1 - (statistics2[s2].total_customer_real_profit / statistics2[s2].total_customer_profit)) * 100).toFixed(2))]);
											_this.noxBotsStatisticsChart7.series[1].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].sum_deposits / 100))]);
											_this.noxBotsStatisticsChart7.series[3].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].sum_withdrawals / 100))]);
											_this.noxBotsStatisticsChart7.series[5].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].sum_balance / 100))]);
											_this.noxBotsStatisticsChart7.series[7].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].sum_funds / 100))]);
											_this.noxBotsStatisticsChart8.series[1].data.push([s2_created_at, parseFloat(((1 - (statistics2[s2].sum_funds / statistics2[s2].sum_balance)) * 100).toFixed(2))]);
											_this.noxBotsStatisticsChart9.series[1].data.push([s2_created_at, parseFloat(statistics2[s2].max_drawdown.toFixed(2))]);
											_this.noxBotsStatisticsChart9.series[3].data.push([s2_created_at, parseFloat(statistics2[s2].min_drawdown.toFixed(2))]);
											_this.noxBotsStatisticsChart9.series[5].data.push([s2_created_at, parseFloat(statistics2[s2].avg_drawdown.toFixed(2))]);
											_this.noxBotsStatisticsChart10.series[1].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].min_funds / 100))]);
											_this.noxBotsStatisticsChart10.series[3].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].max_funds / 100))]);
											_this.noxBotsStatisticsChart10.series[5].data.push([s2_created_at, parseInt(Math.ceil(statistics2[s2].avg_funds / 100))]);
											_this.noxBotsStatisticsChart11.series[1].data.push([s2_created_at, parseInt(Math.floor(statistics2[s2].min_margin_level))]);
											_this.noxBotsStatisticsChart11.series[3].data.push([s2_created_at, parseInt(Math.floor(statistics2[s2].max_margin_level))]);
											_this.noxBotsStatisticsChart11.series[5].data.push([s2_created_at, parseInt(Math.floor(statistics2[s2].avg_margin_level))]);
											_this.noxBotsStatisticsChart12.series[1].data.push([s2_created_at, parseFloat((statistics2[s2].number_of_users_with_bots_online * 100 / (statistics2[s2].number_of_users_with_buy_package ? statistics2[s2].number_of_users_with_buy_package : 1)).toFixed(2))]);
										}
									}
								}
								if (data.data.bots_statistics_by_balance_range !== undefined) {
									if (Object.keys(data.data.bots_statistics_by_balance_range).length) {
										var statistics3 = data.data.bots_statistics_by_balance_range;
										for (var s3 in statistics3) {
											var range = String(s3).split('_');
											if (range.length == 2) {
												_this.noxBotsStatisticsChart14.series[0].data.push({ name: '$' + range[0] + (range[1] == 'more' ? ' и больше' : ' - $' + range[1]), y: parseInt(statistics3[s3]) });
											}
										}
									}
								}
							}
							else if (type == 'getTreeStatistics') {
								_this.noxTreeStatisticsChart1 = {
									title: { text: 'Общие данные по финансам' },
									chart: { height: 350 },
									legend: { enabled: true },
									series: [
										{ name: 'Пополнение старого баланса', data: [] },
										{ name: 'Списание со старого баланса', data: [] },
										{ name: 'Пополнение основного баланса', data: [] },
										{ name: 'Списание с основного баланса', data: [] },
										{ name: 'Пополнение бонусного баланса', data: [] },
										{ name: 'Списание с бонусного баланса', data: [] },
										{ name: 'Пополнение баланса NOX ZP', data: [] },
										{ name: 'Списание с баланса NOX ZP', data: [] },
										{ name: 'Успешные выплаты с бонусного баланса', data: [] },
										{ name: 'Неудачные выплаты с бонусного баланса', data: [] },
										{ name: 'Успешные выплаты с баланса NOX ZP', data: [] },
										{ name: 'Неудачные выплаты с баланса NOX ZP', data: [] },
										{ name: 'Сумма основных балансов', data: [] },
										{ name: 'Сумма бонусных балансов', data: [] },
										{ name: 'Сумма балансов NOX ZP', data: [] },
										{ name: 'Итоговые доходы', data: [] },
										{ name: 'Итоговые расходы', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart2 = {
									title: { text: 'Убыточность бинара' },
									series: [{ name: 'Убыточность бинара', color: '#F45B5B', data: [] }],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForPercent(this, 2);
									}}
								};
								_this.noxTreeStatisticsChart3 = {
									title: { text: 'Доходы с основных услуг сервиса' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Покупка бота', data: [] },
										{ name: 'Покупка пакета', data: [] },
										{ name: 'Улучшение пакета', data: [] },
										{ name: 'Продление абонентской платы', data: [] },
										{ name: 'Автопродление абонентской платы', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart4 = {
									title: { text: 'Доходы и возвраты с NOX Box' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Покупка сервера NOX Box', data: [] },
										{ name: 'Продление абонентской платы сервера NOX Box', data: [] },
										{ name: 'Автопродление абонентской платы сервера NOX Box', data: [] },
										{ name: 'Возврат остатка стоимости абонентской платы при удалении сервера NOX Box', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart5 = {
									title: { text: 'Доходы с сервиса NOX VPN' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Продление абонентского обслуживания NOX VPN', data: [] },
										{ name: 'Автопродление абонентского обслуживания NOX VPN', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart6 = {
									title: { text: 'Доходы и возвраты с NOX Tickets' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Покупка билета на мероприятие (с организатором)', data: [] },
										{ name: 'Возврат билета на мероприятие (с организатором)', data: [] },
										{ name: 'Покупка билета на мероприятие (без организатора)', data: [] },
										{ name: 'Возврат билета на мероприятие (без организатора)', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart7 = {
									title: { text: 'Расходы на бонусы сервиса' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Командный бонус', data: [] },
										{ name: 'Бонус за продажу', data: [] },
										{ name: 'Бонус за апгрейд', data: [] },
										{ name: 'Кураторский бонус', data: [] },
										{ name: 'Кураторский бонус за апгрейд', data: [] },
										{ name: 'Бустер бонус', data: [] },
										{ name: 'Лидерский бонус', data: [] },
										{ name: 'Подарки компании', data: [] },
										{ name: 'Управленческий бонус', data: [] },
										{ name: 'Промо бонус', data: [] },
										{ name: 'iB бонус', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart8 = {
									title: { text: 'Доходы и отказы с iB бонуса' },
									chart: { height: 200 },
									legend: { enabled: true },
									series: [
										{ name: 'Сумма начислений', color: '#1EC455', data: [] },
										{ name: 'Сумма отказов', color: '#F45B5B', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart9 = {
									title: { text: 'Статистика перевода средств' },
									chart: { height: 200 },
									legend: { enabled: true },
									series: [
										{ name: 'Перевод средств с бонусного баланса', color: '#1EC455', data: [] },
										{ name: 'Перевод средств с основного серого баланса', color: '#7CB5EC', data: [] },
										{ name: 'Возврат перевода средств на бонусный баланс', color: '#F45B5B', data: [] },
										{ name: 'Возврат перевода средств на основной серый баланс', color: '#434348', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart10 = {
									title: { text: 'Статистика выставленных счетов' },
									chart: { height: 300 },
									legend: { enabled: true },
									series: [
										{ name: 'Предоплата выставленного разделенного счета', data: [] },
										{ name: 'Возврат выставленной предоплаты разделенного счета', data: [] },
										{ name: 'Оплата выставленного полного счета', data: [] },
										{ name: 'Оплата выставленного разделенного счета', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart11 = {
									title: { text: 'Статистика созданных купонов' },
									chart: { height: 200 },
									legend: { enabled: true },
									series: [
										{ name: 'Сумма активированных купонов (созданные администратором)', color: '#7CB5EC', data: [] },
										{ name: 'Сумма активированных купонов (созданные пользователями)', color: '#1EC455', data: [] },
										{ name: 'Сумма неактивированных купонов (созданные пользователями)', color: '#F45B5B', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormatForDollar(this, 2, 0);
									}}
								};
								_this.noxTreeStatisticsChart12 = {
									title: { text: 'Статистика количества пользователей' },
									chart: { height: 500 },
									legend: { enabled: true },
									series: [
										{ name: 'Общее количество пользователей', data: [] },
										{ name: 'Общее количество пользователей, которые купили пакет', data: [] },
										{ name: 'Количество пользователей с пакетом x0', data: [] },
										{ name: 'Количество пользователей с пакетом x1', data: [] },
										{ name: 'Количество пользователей с пакетом x2', data: [] },
										{ name: 'Количество пользователей с пакетом x3', data: [] },
										{ name: 'Количество пользователей с пакетом x4', data: [] },
										{ name: 'Количество пользователей с пакетом x5', data: [] },
										{ name: 'Количество пользователей с пакетом x6', data: [] },
										{ name: 'Количество пользователей с пакетом x7', data: [] },
										{ name: 'Количество пользователей с пакетом x8', data: [] },
										{ name: 'Количество пользователей с пакетом x9', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x0', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x1', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x2', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x3', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x4', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x5', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x6', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x7', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x8', data: [] },
										{ name: 'Количество пользователей, которые превысили лимит на пакете x9', data: [] }
									],
									tooltip: { shared: true, formatter: function() {
										return _this.$parent.$parent.getChartFormat(this, 0);
									}}
								};
								if (data.data.length) {
									for (var i in data.data) {
										var created_at = parseInt(data.data[i].created_at) * 1000;
										_this.noxTreeStatisticsChart1.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_1.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_25.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[2].data.push([created_at, parseFloat(data.data[i].sum_type_34.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[3].data.push([created_at, parseFloat(data.data[i].sum_type_35.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[4].data.push([created_at, parseFloat(data.data[i].sum_type_36.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[5].data.push([created_at, parseFloat(data.data[i].sum_type_37.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[6].data.push([created_at, parseFloat(data.data[i].sum_type_32.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[7].data.push([created_at, parseFloat(data.data[i].sum_type_33.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[8].data.push([created_at, parseFloat(data.data[i].payment_type_3_1.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[9].data.push([created_at, parseFloat(data.data[i].payment_type_3_4.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[10].data.push([created_at, parseFloat(data.data[i].payment_type_4_1.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[11].data.push([created_at, parseFloat(data.data[i].payment_type_4_4.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[12].data.push([created_at, parseFloat(data.data[i].sum_balance_nox.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[13].data.push([created_at, parseFloat(data.data[i].sum_balance_of_bonuses.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[14].data.push([created_at, parseFloat(data.data[i].sum_balance_nox_zp.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[15].data.push([created_at, parseFloat(data.data[i].total_income.toFixed(2))]);
										_this.noxTreeStatisticsChart1.series[16].data.push([created_at, parseFloat(data.data[i].total_expense.toFixed(2))]);
										_this.noxTreeStatisticsChart2.series[0].data.push([created_at, parseFloat(data.data[i].program_loss.toFixed(2))]);
										_this.noxTreeStatisticsChart3.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_15.toFixed(2))]);
										_this.noxTreeStatisticsChart3.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_2.toFixed(2))]);
										_this.noxTreeStatisticsChart3.series[2].data.push([created_at, parseFloat(data.data[i].sum_type_3.toFixed(2))]);
										_this.noxTreeStatisticsChart3.series[3].data.push([created_at, parseFloat(data.data[i].sum_type_4.toFixed(2))]);
										_this.noxTreeStatisticsChart3.series[4].data.push([created_at, parseFloat(data.data[i].sum_type_45.toFixed(2))]);
										_this.noxTreeStatisticsChart4.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_26.toFixed(2))]);
										_this.noxTreeStatisticsChart4.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_27.toFixed(2))]);
										_this.noxTreeStatisticsChart4.series[2].data.push([created_at, parseFloat(data.data[i].sum_type_28.toFixed(2))]);
										_this.noxTreeStatisticsChart4.series[3].data.push([created_at, parseFloat(data.data[i].sum_type_29.toFixed(2))]);
										_this.noxTreeStatisticsChart5.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_56.toFixed(2))]);
										_this.noxTreeStatisticsChart5.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_57.toFixed(2))]);
										_this.noxTreeStatisticsChart6.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_47.toFixed(2))]);
										_this.noxTreeStatisticsChart6.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_48.toFixed(2))]);
										_this.noxTreeStatisticsChart6.series[2].data.push([created_at, parseFloat(data.data[i].sum_type_42.toFixed(2))]);
										_this.noxTreeStatisticsChart6.series[3].data.push([created_at, parseFloat(data.data[i].sum_type_43.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_5.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_6.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[2].data.push([created_at, parseFloat(data.data[i].sum_type_7.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[3].data.push([created_at, parseFloat(data.data[i].sum_type_8.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[4].data.push([created_at, parseFloat(data.data[i].sum_type_9.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[5].data.push([created_at, parseFloat(data.data[i].sum_type_10.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[6].data.push([created_at, parseFloat(data.data[i].sum_type_11.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[7].data.push([created_at, parseFloat(data.data[i].sum_type_12.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[8].data.push([created_at, parseFloat(data.data[i].sum_type_13.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[9].data.push([created_at, parseFloat(data.data[i].sum_type_44.toFixed(2))]);
										_this.noxTreeStatisticsChart7.series[10].data.push([created_at, parseFloat(data.data[i].sum_type_60.toFixed(2))]);
										_this.noxTreeStatisticsChart8.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_60.toFixed(2))]);
										_this.noxTreeStatisticsChart8.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_61.toFixed(2))]);
										_this.noxTreeStatisticsChart9.series[0].data.push([created_at, parseFloat(data.data[i].transfer_bonus_balance_status_1.toFixed(2))]);
										_this.noxTreeStatisticsChart9.series[1].data.push([created_at, parseFloat(data.data[i].transfer_grey_balance_status_1.toFixed(2))]);
										_this.noxTreeStatisticsChart9.series[2].data.push([created_at, parseFloat(data.data[i].transfer_bonus_balance_status_2.toFixed(2))]);
										_this.noxTreeStatisticsChart9.series[3].data.push([created_at, parseFloat(data.data[i].transfer_grey_balance_status_2.toFixed(2))]);
										_this.noxTreeStatisticsChart10.series[0].data.push([created_at, parseFloat(data.data[i].sum_type_17.toFixed(2))]);
										_this.noxTreeStatisticsChart10.series[1].data.push([created_at, parseFloat(data.data[i].sum_type_18.toFixed(2))]);
										_this.noxTreeStatisticsChart10.series[2].data.push([created_at, parseFloat(data.data[i].sum_type_19.toFixed(2))]);
										_this.noxTreeStatisticsChart10.series[3].data.push([created_at, parseFloat(data.data[i].sum_type_20.toFixed(2))]);
										_this.noxTreeStatisticsChart11.series[0].data.push([created_at, parseFloat(data.data[i].coupon_by_admin.toFixed(2))]);
										_this.noxTreeStatisticsChart11.series[1].data.push([created_at, parseFloat(data.data[i].coupon_status_1.toFixed(2))]);
										_this.noxTreeStatisticsChart11.series[2].data.push([created_at, parseFloat(data.data[i].coupon_status_0.toFixed(2))]);
										_this.noxTreeStatisticsChart12.series[0].data.push([created_at, parseInt(data.data[i].number_of_users)]);
										_this.noxTreeStatisticsChart12.series[1].data.push([created_at, parseInt(data.data[i].number_of_users_package)]);
										_this.noxTreeStatisticsChart12.series[2].data.push([created_at, parseInt(data.data[i].number_of_users_package_x0)]);
										_this.noxTreeStatisticsChart12.series[3].data.push([created_at, parseInt(data.data[i].number_of_users_package_x1)]);
										_this.noxTreeStatisticsChart12.series[4].data.push([created_at, parseInt(data.data[i].number_of_users_package_x2)]);
										_this.noxTreeStatisticsChart12.series[5].data.push([created_at, parseInt(data.data[i].number_of_users_package_x3)]);
										_this.noxTreeStatisticsChart12.series[6].data.push([created_at, parseInt(data.data[i].number_of_users_package_x4)]);
										_this.noxTreeStatisticsChart12.series[7].data.push([created_at, parseInt(data.data[i].number_of_users_package_x5)]);
										_this.noxTreeStatisticsChart12.series[8].data.push([created_at, parseInt(data.data[i].number_of_users_package_x6)]);
										_this.noxTreeStatisticsChart12.series[9].data.push([created_at, parseInt(data.data[i].number_of_users_package_x7)]);
										_this.noxTreeStatisticsChart12.series[10].data.push([created_at, parseInt(data.data[i].number_of_users_package_x8)]);
										_this.noxTreeStatisticsChart12.series[11].data.push([created_at, parseInt(data.data[i].number_of_users_package_x9)]);
										_this.noxTreeStatisticsChart12.series[12].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x0)]);
										_this.noxTreeStatisticsChart12.series[13].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x1)]);
										_this.noxTreeStatisticsChart12.series[14].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x2)]);
										_this.noxTreeStatisticsChart12.series[15].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x3)]);
										_this.noxTreeStatisticsChart12.series[16].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x4)]);
										_this.noxTreeStatisticsChart12.series[17].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x5)]);
										_this.noxTreeStatisticsChart12.series[18].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x6)]);
										_this.noxTreeStatisticsChart12.series[19].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x7)]);
										_this.noxTreeStatisticsChart12.series[20].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x8)]);
										_this.noxTreeStatisticsChart12.series[21].data.push([created_at, parseInt(data.data[i].number_of_users_exceeded_limit_package_x9)]);
									}
								}
							}
							else if (type == 'getPhoenixStatistics') {
								if (data.data.phoenix_statistics_1 !== undefined) {
									_this.noxPhoenixStatistics1 = data.data.phoenix_statistics_1;
								}
								if (data.data.phoenix_statistics_2 !== undefined) {
									_this.noxPhoenixStatistics2 = data.data.phoenix_statistics_2;
								}
								if (data.data.phoenix_statistics_3 !== undefined) {
									_this.noxPhoenixStatistics3 = data.data.phoenix_statistics_3;
								}
								if (data.data.phoenix_statistics_4 !== undefined) {
									_this.noxPhoenixStatistics4 = data.data.phoenix_statistics_4;
									_this.noxPhoenixStatistics4Chart1 = {
										title: { text: 'Распределение просадок по количеству ботов' },
										chart: { type: 'column', height: 300 },
										xAxis: { type: 'category' },
										series: [{ name: 'Количество ботов', color: '#1EC455', data: [] }],
										plotOptions: { column: { borderWidth: 0, groupPadding: 0, minPointLength: 2, pointPadding: 0.1 }}
									};
									if (Object.keys(_this.noxPhoenixStatistics4).length) {
										for (var ps4 in _this.noxPhoenixStatistics4) {
											var ps4_range = String(ps4).split('_');
											if (ps4_range.length == 2) {
												_this.noxPhoenixStatistics4Chart1.series[0].data.push([(ps4_range[0] + '-' + ps4_range[1] + '%'), _this.noxPhoenixStatistics4[ps4]]);
											}
										}
									}
								}
								if (data.data.phoenix_statistics_5 !== undefined) {
									_this.noxPhoenixStatistics5 = data.data.phoenix_statistics_5;
									_this.noxPhoenixStatistics5Chart1 = {
										title: { text: 'Распределение просадок по балансам счетов' },
										chart: { type: 'column', height: 300 },
										xAxis: { type: 'category' },
										series: [{ name: 'Сумма балансов', color: '#7CB5EC', data: [] }],
										plotOptions: { column: { borderWidth: 0, groupPadding: 0, minPointLength: 2, pointPadding: 0.1 }},
										tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForDollar2(this, 2, 0); }}
									};
									if (Object.keys(_this.noxPhoenixStatistics5).length) {
										for (var ps5 in _this.noxPhoenixStatistics5) {
											var ps5_range = String(ps5).split('_');
											if (ps5_range.length == 2) {
												_this.noxPhoenixStatistics5Chart1.series[0].data.push([(ps5_range[0] + '-' + ps5_range[1] + '%'), (_this.noxPhoenixStatistics5[ps5] / 100)]);
											}
										}
									}
								}
								if (data.data.phoenix_statistics_1_details !== undefined) {
									_this.noxPhoenixStatistics1Details = data.data.phoenix_statistics_1_details;
									_this.noxPhoenixStatistics1Chart1 = {
										title: { text: 'Средняя прибыльность NOX Phoenix по шаблонам ботов' },
										chart: { height: 400 }, legend: { enabled: true }, series: [],
										tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }}
									};
									_this.noxPhoenixStatistics1Chart2 = {
										title: { text: 'Средняя просадка NOX Phoenix по шаблонам ботов' },
										chart: { height: 400 }, legend: { enabled: true }, series: [],
										tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }}
									};
									var statisticsSettingsJsons = [];
									var statisticsSettingsNames = [];
									if (_this.noxPhoenixStatistics1.length) {
										for (var s1_1 in _this.noxPhoenixStatistics1) {
											if (!statisticsSettingsJsons.includes(JSON.stringify(_this.noxPhoenixStatistics1[s1_1].preset_settings))) {
												statisticsSettingsJsons.push(JSON.stringify(_this.noxPhoenixStatistics1[s1_1].preset_settings));
												statisticsSettingsNames.push('#' + (Number(s1_1) + 1));
											}
										}
										for (var s1_2 in statisticsSettingsJsons) {
											_this.noxPhoenixStatistics1Chart1.series.push({ name: 'Прибыльность шаблона ' + statisticsSettingsNames[s1_2], color: _this.$store.state.noxChartColors[s1_2], data: [] });
											_this.noxPhoenixStatistics1Chart2.series.push({ name: 'Просадка шаблона ' + statisticsSettingsNames[s1_2], color: _this.$store.state.noxChartColors[s1_2], data: [] });
										}
										if (_this.noxPhoenixStatistics1Details.length) {
											for (var s1_3 in _this.noxPhoenixStatistics1Details) {
												var index = statisticsSettingsJsons.indexOf(JSON.stringify(_this.noxPhoenixStatistics1Details[s1_3].settings));
												if (index >= 0) {
													_this.noxPhoenixStatistics1Chart1.series[index].data.push([parseInt(_this.noxPhoenixStatistics1Details[s1_3].created_at) * 1000, parseFloat(_this.noxPhoenixStatistics1Details[s1_3].balance_profitability)]);
													_this.noxPhoenixStatistics1Chart2.series[index].data.push([parseInt(_this.noxPhoenixStatistics1Details[s1_3].created_at) * 1000, parseFloat(_this.noxPhoenixStatistics1Details[s1_3].balance_drawdown)]);
												}
											}
										}
									}
								}
								if (data.data.phoenix_statistics_2_details !== undefined) {
									_this.noxPhoenixStatistics2Details = data.data.phoenix_statistics_2_details;
									_this.noxPhoenixStatistics2Chart1 = {
										title: { text: 'Средняя прибыльность NOX Phoenix по агрессивности ботов - Пирамидинг' },
										chart: { height: 300 },
										legend: { enabled: true },
										series: [
											{ name: 'Прибыльность низкой агрессивности', data: [] },
											{ name: 'Прибыльность средней агрессивности', data: [] },
											{ name: 'Прибыльность высокой агрессивности', data: [] }
										],
										tooltip: { shared: true, formatter: function() {
											return _this.$parent.$parent.getChartFormatForPercent(this, 2);
										}}
									};
									_this.noxPhoenixStatistics2Chart2 = {
										title: { text: 'Средняя прибыльность NOX Phoenix по агрессивности ботов - Пошаговый' },
										chart: { height: 300 },
										legend: { enabled: true },
										series: [
											{ name: 'Прибыльность низкой агрессивности', data: [] },
											{ name: 'Прибыльность средней агрессивности', data: [] },
											{ name: 'Прибыльность высокой агрессивности', data: [] }
										],
										tooltip: { shared: true, formatter: function() {
											return _this.$parent.$parent.getChartFormatForPercent(this, 2);
										}}
									};
									_this.noxPhoenixStatistics2Chart3 = {
										title: { text: 'Средняя просадка NOX Phoenix по агрессивности ботов - Пирамидинг' },
										chart: { height: 300 },
										legend: { enabled: true },
										series: [
											{ name: 'Просадка низкой агрессивности', data: [] },
											{ name: 'Просадка средней агрессивности', data: [] },
											{ name: 'Просадка высокой агрессивности', data: [] }
										],
										tooltip: { shared: true, formatter: function() {
											return _this.$parent.$parent.getChartFormatForPercent(this, 2);
										}}
									};
									_this.noxPhoenixStatistics2Chart4 = {
										title: { text: 'Средняя просадка NOX Phoenix по агрессивности ботов - Пошаговый' },
										chart: { height: 300 },
										legend: { enabled: true },
										series: [
											{ name: 'Просадка низкой агрессивности', data: [] },
											{ name: 'Просадка средней агрессивности', data: [] },
											{ name: 'Просадка высокой агрессивности', data: [] }
										],
										tooltip: { shared: true, formatter: function() {
											return _this.$parent.$parent.getChartFormatForPercent(this, 2);
										}}
									};
									if (_this.noxPhoenixStatistics2Details.length) {
										for (var s2_1 in _this.noxPhoenixStatistics2Details) {
											if (_this.noxPhoenixStatistics2Details[s2_1].trend_strategy == 0) {
												_this.noxPhoenixStatistics2Chart1.series[(Number(_this.noxPhoenixStatistics2Details[s2_1].aggressiveness) - 1)].data.push([parseInt(_this.noxPhoenixStatistics2Details[s2_1].created_at) * 1000, parseFloat(_this.noxPhoenixStatistics2Details[s2_1].balance_profitability)]);
												_this.noxPhoenixStatistics2Chart3.series[(Number(_this.noxPhoenixStatistics2Details[s2_1].aggressiveness) - 1)].data.push([parseInt(_this.noxPhoenixStatistics2Details[s2_1].created_at) * 1000, parseFloat(_this.noxPhoenixStatistics2Details[s2_1].balance_drawdown)]);
											}
											else if (_this.noxPhoenixStatistics2Details[s2_1].trend_strategy == 1) {
												_this.noxPhoenixStatistics2Chart2.series[(Number(_this.noxPhoenixStatistics2Details[s2_1].aggressiveness) - 1)].data.push([parseInt(_this.noxPhoenixStatistics2Details[s2_1].created_at) * 1000, parseFloat(_this.noxPhoenixStatistics2Details[s2_1].balance_profitability)]);
												_this.noxPhoenixStatistics2Chart4.series[(Number(_this.noxPhoenixStatistics2Details[s2_1].aggressiveness) - 1)].data.push([parseInt(_this.noxPhoenixStatistics2Details[s2_1].created_at) * 1000, parseFloat(_this.noxPhoenixStatistics2Details[s2_1].balance_drawdown)]);
											}
										}
									}
								}
								if (data.data.phoenix_statistics_3_details !== undefined) {
									_this.noxPhoenixStatistics3Details = data.data.phoenix_statistics_3_details;
									_this.noxPhoenixStatistics3Chart1 = {
										title: { text: 'Ежедневная прибыль всех ботов NOX Phoenix' },
										chart: { height: 300 },
										legend: { enabled: true },
										series: [
											{ name: 'Ежедневная прибыль классической торговли', data: [] },
											{ name: 'Ежедневная прибыль новостной торговли', data: [] },
											{ name: 'Ежедневная прибыль IntraDay торговли', data: [] }
										],
										tooltip: { shared: true, formatter: function() {
											return _this.$parent.$parent.getChartFormatForPercent(this, 2);
										}}
									};
									if (_this.noxPhoenixStatistics3Details.length > 1) {
										for (var s3_1 = 1; s3_1 < _this.noxPhoenixStatistics3Details.length; s3_1++) {
											var s3_1_created_at = parseInt(_this.noxPhoenixStatistics3Details[s3_1].created_at) * 1000;
											_this.noxPhoenixStatistics3Chart1.series[0].data.push([s3_1_created_at, parseFloat(((((_this.noxPhoenixStatistics3Details[s3_1].balance_is_classic_trading - _this.noxPhoenixStatistics3Details[s3_1].deposits_is_classic_trading + _this.noxPhoenixStatistics3Details[s3_1].withdrawals_is_classic_trading) - (_this.noxPhoenixStatistics3Details[(s3_1 - 1)].balance_is_classic_trading - _this.noxPhoenixStatistics3Details[(s3_1 - 1)].deposits_is_classic_trading + _this.noxPhoenixStatistics3Details[(s3_1 - 1)].withdrawals_is_classic_trading)) / (_this.noxPhoenixStatistics3Details[(s3_1 - 1)].balance_is_classic_trading - _this.noxPhoenixStatistics3Details[(s3_1 - 1)].deposits_is_classic_trading + _this.noxPhoenixStatistics3Details[(s3_1 - 1)].withdrawals_is_classic_trading)) * 100).toFixed(2))]);
											_this.noxPhoenixStatistics3Chart1.series[1].data.push([s3_1_created_at, parseFloat(((((_this.noxPhoenixStatistics3Details[s3_1].balance_is_news_trading - _this.noxPhoenixStatistics3Details[s3_1].deposits_is_news_trading + _this.noxPhoenixStatistics3Details[s3_1].withdrawals_is_news_trading) - (_this.noxPhoenixStatistics3Details[(s3_1 - 1)].balance_is_news_trading - _this.noxPhoenixStatistics3Details[(s3_1 - 1)].deposits_is_news_trading + _this.noxPhoenixStatistics3Details[(s3_1 - 1)].withdrawals_is_news_trading)) / (_this.noxPhoenixStatistics3Details[(s3_1 - 1)].balance_is_news_trading - _this.noxPhoenixStatistics3Details[(s3_1 - 1)].deposits_is_news_trading + _this.noxPhoenixStatistics3Details[(s3_1 - 1)].withdrawals_is_news_trading)) * 100).toFixed(2))]);
											_this.noxPhoenixStatistics3Chart1.series[2].data.push([s3_1_created_at, parseFloat(((((_this.noxPhoenixStatistics3Details[s3_1].balance_is_zigzag_trading - _this.noxPhoenixStatistics3Details[s3_1].deposits_is_zigzag_trading + _this.noxPhoenixStatistics3Details[s3_1].withdrawals_is_zigzag_trading) - (_this.noxPhoenixStatistics3Details[(s3_1 - 1)].balance_is_zigzag_trading - _this.noxPhoenixStatistics3Details[(s3_1 - 1)].deposits_is_zigzag_trading + _this.noxPhoenixStatistics3Details[(s3_1 - 1)].withdrawals_is_zigzag_trading)) / (_this.noxPhoenixStatistics3Details[(s3_1 - 1)].balance_is_zigzag_trading - _this.noxPhoenixStatistics3Details[(s3_1 - 1)].deposits_is_zigzag_trading + _this.noxPhoenixStatistics3Details[(s3_1 - 1)].withdrawals_is_zigzag_trading)) * 100).toFixed(2))]);
										}
									}
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_statistics_navigation {
		position: relative;
		margin: 0 0 2px 0;
		padding: 0 0 0 142px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_statistics_navigation.count {
		margin: 0 0 15px 0;
	}
	.nox_account_desktop_statistics_navigation_filters_label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px;
		border-radius: 15px 2px 2px 15px;
		background: #333333B3;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 140px;
		height: 30px;
	}
	.nox_account_desktop_statistics_navigation_filters_value {
		float: left;
		padding: 0 2px 0 0;
		width: 33.33333%;
	}
	.nox_account_desktop_statistics_navigation_filters_value:last-child {
		padding: 0;
	}
	.nox_account_desktop_statistics_navigation_filters_value div {
		border-radius: 2px;
		background: #111111B3;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		overflow: hidden;
		text-align: center;
		text-transform: lowercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 100%;
		height: 30px;
	}
	.nox_account_desktop_statistics_navigation_filters_value.active div,
	.nox_account_desktop_statistics_navigation_filters_value:hover div {
		background: #111111CC;
	}
	.nox_account_desktop_statistics_navigation_filters_value:last-child div {
		border-radius: 2px 15px 15px 2px;
	}
	.nox_account_desktop_statistics_navigation_filters_select {
		padding: 0 15px;
		border-radius: 2px 15px 15px 2px;
		background: #111111B3;
		width: 100%;
		height: 30px;
	}
	.nox_account_desktop_statistics_navigation_filters_select .nox_select {
		margin: 0;
	}
	.nox_account_desktop_statistics_navigation_filters_select .nox_select .vs__dropdown-toggle {
		padding: 0 0 2px 0;
		border-radius: 0;
		background: transparent;
		line-height: 30px;
		height: 30px;
	}
	.nox_account_desktop_statistics_navigation_filters_select .nox_select .vs__dropdown-menu {
		margin: 1px 0 0 0;
	}
	.nox_account_desktop_statistics tr th:nth-child(1),
	.nox_account_desktop_statistics tr td:nth-child(1) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_statistics tr td {
		word-break: break-all;
		word-break: break-word;
	}

	@media (max-width: 1000px) {
		.nox_account_desktop_statistics_navigation.count {
			margin: 0 0 10px 0;
		}
	}
	@media (max-width: 650px) {
		.nox_account_desktop_statistics_navigation {
			margin: 0 0 10px 0;
			padding: 0;
		}
		.nox_account_desktop_statistics_navigation_filters_label {
			position: relative;
			border-radius: 15px 15px 2px 2px;
			width: 100%;
		}
		.nox_account_desktop_statistics_navigation_filters_value {
			padding: 2px 0 0 0;
			width: 100%;
		}
		.nox_account_desktop_statistics_navigation_filters_value:last-child {
			padding: 2px 0 0 0;
		}
		.nox_account_desktop_statistics_navigation_filters_value:last-child div {
			border-radius: 2px 2px 15px 15px;
		}
		.nox_account_desktop_statistics_navigation_filters_select {
			margin: 2px 0 0 0;
			border-radius: 2px 2px 15px 15px;
		}
	}
</style>
