<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_promo_materials">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 0)"></div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_portlet_content_video"><a href="https://www.youtube.com/watch?v=j6x9agyv5Qk" target="_blank"><div class="video"><img src="@/assets/images/video_1.png"><font-awesome-icon :icon="['fab', 'youtube']" /></div></a><span v-html="$store.getters.getLanguageText('3.16', 1)"></span></div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_row">
					<div class="nox_account_desktop_page_content_col buttons buttons_2">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 2)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.16', 3)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<a :href="$store.getters.getLanguageText('3.16', 20)" class="nox_button big green" target="_blank"><font-awesome-icon :icon="['fas', 'play']" /><span v-html="$store.getters.getLanguageText('1.1', 153)"></span></a>
								<a :href="$store.getters.getLanguageText('3.16', 4)" class="nox_button big green" target="_blank"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 149)"></span></a>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_page_content_col buttons buttons_1">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 5)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.16', 6)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<a :href="$store.getters.getLanguageText('3.16', 7)" class="nox_button big green" target="_blank"><font-awesome-icon :icon="['fas', 'file-pdf']" /><span v-html="$store.getters.getLanguageText('1.1', 113)"></span></a>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_row">
					<div class="nox_account_desktop_page_content_col buttons buttons_1">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 8)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.16', 9)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<a :href="$store.getters.getLanguageText('3.16', 10)" class="nox_button big green" target="_blank"><font-awesome-icon :icon="['fas', 'play']" /><span v-html="$store.getters.getLanguageText('1.1', 153)"></span></a>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_page_content_col buttons buttons_1">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 22)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.16', 23)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<a :href="$store.getters.getLanguageText('3.16', 24)" class="nox_button big green" target="_blank"><font-awesome-icon :icon="['fas', 'eye']" /><span v-html="$store.getters.getLanguageText('1.1', 150)"></span></a>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_row">
					<div class="nox_account_desktop_page_content_col buttons buttons_1">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 11)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.16', 12)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<a :href="$store.getters.getLanguageText('3.16', 13)" class="nox_button big green" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /><span v-html="$store.getters.getLanguageText('1.1', 148)"></span></a>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_page_content_col buttons buttons_2">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 17)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.16', 18)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<div class="nox_dropdown" :class="{ open: $store.state.noxDropdownId == 'promo_materials_open_pdf' }" @blur="$parent.$parent.closeDropdown($event)" tabindex="-1">
									<div class="nox_dropdown_toggle green" @click="$parent.$parent.toggleDropdown('promo_materials_open_pdf')">
										<div class="nox_dropdown_toggle_value"><font-awesome-icon :icon="['fas', 'file-pdf']" /><span v-html="$store.getters.getLanguageText('1.1', 113)"></span></div>
										<div class="nox_dropdown_toggle_button"><font-awesome-icon :icon="['fas', 'chevron-' + ($store.state.noxDropdownId == 'promo_materials_open_pdf' ? 'up' : 'down')]" /></div>
									</div>
									<div class="nox_dropdown_options">
										<a :href="$store.getters.getLanguageText('3.16', 21)" class="nox_dropdown_options_item" target="_blank" v-html="$store.getters.getLanguageText('1.1', 154)"></a>
										<a :href="$store.getters.getLanguageText('3.16', 26)" class="nox_dropdown_options_item" target="_blank" v-html="$store.getters.getLanguageText('1.1', 155)"></a>
									</div>
								</div>
								<div class="nox_dropdown" :class="{ open: $store.state.noxDropdownId == 'promo_materials_download_archive' }" @blur="$parent.$parent.closeDropdown($event)" tabindex="-1">
									<div class="nox_dropdown_toggle green" @click="$parent.$parent.toggleDropdown('promo_materials_download_archive')">
										<div class="nox_dropdown_toggle_value"><font-awesome-icon :icon="['fas', 'download']" /><span v-html="$store.getters.getLanguageText('1.1', 148)"></span></div>
										<div class="nox_dropdown_toggle_button"><font-awesome-icon :icon="['fas', 'chevron-' + ($store.state.noxDropdownId == 'promo_materials_download_archive' ? 'up' : 'down')]" /></div>
									</div>
									<div class="nox_dropdown_options">
										<a :href="$store.getters.getLanguageText('3.16', 19)" class="nox_dropdown_options_item" target="_blank" v-html="$store.getters.getLanguageText('1.1', 154)"></a>
										<a :href="$store.getters.getLanguageText('3.16', 25)" class="nox_dropdown_options_item" target="_blank" v-html="$store.getters.getLanguageText('1.1', 155)"></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_row">
					<div class="nox_account_desktop_page_content_col buttons buttons_1">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 14)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.16', 15)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<a :href="$store.getters.getLanguageText('3.16', 16)" class="nox_button big green" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /><span v-html="$store.getters.getLanguageText('1.1', 148)"></span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.initPromoMaterials();
			},
			initPromoMaterials: function() {
				var _this = this;
				setTimeout(function() {
					if (_this.$store.state.noxAccountData.group == 1) {
						if (_this.$parent.$parent.checkAccessToNoxBusiness()) {
							_this.noxIsLoading = false;
							_this.$store.state.noxIsAccount = false;
							_this.$store.state.noxIsLoading = false;
						}
						else {
							_this.$router.push({ path: '/account/tariffs' });
						}
					}
					else {
						_this.$parent.$parent.exit();
					}
				}, 100);
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	@media (max-width: 1100px) {
		.nox_account_desktop_promo_materials .nox_account_desktop_page_content_row {
			margin: 0;
			display: block;
		}
		.nox_account_desktop_promo_materials .nox_account_desktop_page_content_col {
			float: none;
			padding: 0;
		}
		.nox_account_desktop_promo_materials .nox_account_desktop_page_content_col .nox_account_desktop_portlet {
			margin: 0 0 15px 0;
			height: auto;
		}
		.nox_account_desktop_promo_materials .nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet {
			padding: 0;
		}
		.nox_account_desktop_promo_materials .nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet_content {
			height: auto;
		}
		.nox_account_desktop_promo_materials .nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet_buttons {
			position: relative;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_desktop_promo_materials .nox_account_desktop_page_content_col .nox_account_desktop_portlet {
			margin: 0 0 10px 0;
		}
	}
</style>
