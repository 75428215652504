<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_balance" :class="{ is_bonus_9: noxIsBonus9Countdown }">
				<div class="nox_account_desktop_balance_widgets">
					<div class="nox_account_desktop_balance_widget" :class="{ red: noxIsBonus9CountdownExpires }" v-if="noxIsBonus9Countdown">
						<div class="nox_countdown">
							<div class="nox_countdown_title" v-html="$store.getters.getLanguageText('3.6', 1)"></div>
							<div class="nox_countdown_content">
								<div class="title" v-html="$store.getters.getLanguageText('3.6', 2)"></div>
								<div class="values" v-html="noxBonus9CountdownValues"></div>
								<div class="labels" v-html="noxBonus9CountdownLabels"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_balance_content">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6', 0)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_portlet_content_img_200">
								<img src="@/assets/images/bot_img_4.png">
								<div v-html="$store.getters.getLanguageText('3.6', 3, { balance: $parent.$parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox, 2), color: ($store.state.noxAccountData.balance_nox < 0 ? 'red' : '') })"></div>
								<div v-html="$store.getters.getLanguageText('3.6', 4, { balance: $parent.$parent.getNumberFormatForDollar($store.state.noxAccountData.balance_of_bonuses, 2) })"></div>
								<div v-html="$store.getters.getLanguageText('3.6', 5, { balance: $parent.$parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox_zp, 2) })" v-if="$store.state.noxAccountData.balance_nox_zp !== 0"></div>
								<div v-html="$store.getters.getLanguageText('3.6', 6, { count: $store.state.noxAccountData.count_of_perks })" v-if="$store.state.noxAccountData.count_of_perks"></div><br>
								<div v-html="$store.getters.getLanguageText('3.6', 7)"></div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_buttons">
							<button type="button" class="nox_button big violet" @click="$modal.show('NoxModalBalance', { type: 'payoutNOXZPBalance' })" v-if="$store.state.noxAccountData.balance_nox_zp"><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('1.1', 108)"></span></button>
							<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalBalance', { type: 'payoutBonusBalance' })"><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('1.1', 62)"></span></button>
							<button type="button" class="nox_button big orange" @click="$modal.show('NoxModalBalance', { type: 'transferBonusBalance' })"><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('1.1', 105)"></span></button>
							<button type="button" class="nox_button big light_red" @click="$modal.show('NoxModalBalance', { type: 'createCouponBonusBalance' })"><font-awesome-icon class="rotate_135" :icon="['fas', 'ticket-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 126)"></span></button>
							<button type="button" class="nox_button big green" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }" @click="$modal.show('NoxModalBalance', { type: 'addMainBalance' })"><font-awesome-icon :icon="['fas', ($store.state.noxAccountData.balance_nox < 0 ? 'exclamation-triangle' : 'plus')]" /><span v-html="$store.getters.getLanguageText('1.1', 100)"></span></button>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="$store.state.noxAccountData.package && ($store.state.noxAccountData.id >= 100 || $store.state.noxAccountData.group == 1)">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.1', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_tree_statistics">
						<div v-if="Object.keys(noxTreeStatistics).length">
							<div v-if="$store.state.noxAccountData.group == 1">
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Общая статистика:</b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['sum_type_1', 'sum_type_25', 'sum_type_34', 'sum_type_35', 'sum_type_36', 'sum_type_37'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'sum_type_1'">Пополнение старого баланса</span>
													<span v-else-if="index == 'sum_type_25'">Списание со старого баланса</span>
													<span v-else-if="index == 'sum_type_34'">Пополнение основного баланса</span>
													<span v-else-if="index == 'sum_type_35'">Списание с основного баланса</span>
													<span v-else-if="index == 'sum_type_36'">Пополнение бонусного баланса</span>
													<span v-else-if="index == 'sum_type_37'">Списание с бонусного баланса</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ value.toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Пополнение баланса NOX ZP</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_type_32'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Списание с баланса NOX ZP</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_type_33'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Успешные выплаты с бонусного баланса</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['payment_type_3_1'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Неудачные выплаты с бонусного баланса</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['payment_type_3_4'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Успешные выплаты с баланса NOX ZP</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['payment_type_4_1'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Неудачные выплаты с баланса NOX ZP</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['payment_type_4_4'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Перевод средств с бонусного баланса</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['transfer_bonus_balance_status_1'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Перевод средств с основного серого баланса</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['transfer_grey_balance_status_1'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Возврат перевода средств на бонусный баланс</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['transfer_bonus_balance_status_2'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Возврат перевода средств на основной серый баланс</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['transfer_grey_balance_status_2'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Сумма активированных купонов (созданные администратором)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['coupon_by_admin'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Сумма активированных купонов (созданные пользователями)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['coupon_status_1'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Сумма неактивированных купонов (созданные пользователями)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['coupon_status_0'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Сумма основных балансов пользователей</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_balance_nox'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Сумма бонусных балансов пользователей</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_balance_of_bonuses'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Сумма балансов NOX ZP пользователей</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_balance_nox_zp'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Ручные операции, выполненные администратором</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_type_0'].toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Статистика услуг:</b>
									<table class="nox_table">
										<tbody>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Покупка пакета</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_type_2'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Улучшение пакета</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_type_3'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Продление абонентской платы</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_type_4'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">Автопродление абонентской платы</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ noxTreeStatistics['sum_type_45'].toFixed(2) }}</td>
											</tr>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['sum_type_15', 'sum_type_26', 'sum_type_27', 'sum_type_28', 'sum_type_29', 'sum_type_42', 'sum_type_43', 'sum_type_47', 'sum_type_48'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'sum_type_15'">Покупка NOX бота (советника)</span>
													<span v-else-if="index == 'sum_type_16'">Улучшение NOX бота (советника)</span>
													<span v-else-if="index == 'sum_type_26'">Покупка сервера NOX Box</span>
													<span v-else-if="index == 'sum_type_27'">Продление абонентской платы сервера NOX Box</span>
													<span v-else-if="index == 'sum_type_28'">Автопродление абонентской платы сервера NOX Box</span>
													<span v-else-if="index == 'sum_type_29'">Возврат абонентской платы при удалении сервера NOX Box</span>
													<span v-else-if="index == 'sum_type_42'">Покупка билета на мероприятие (без организатора)</span>
													<span v-else-if="index == 'sum_type_43'">Возврат билета на мероприятие (без организатора)</span>
													<span v-else-if="index == 'sum_type_47'">Покупка билета на мероприятие (с организатором)</span>
													<span v-else-if="index == 'sum_type_48'">Возврат билета на мероприятие (с организатором)</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ value.toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Статистика бонусов:</b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['sum_type_5', 'sum_type_6', 'sum_type_7', 'sum_type_8', 'sum_type_9', 'sum_type_10', 'sum_type_11', 'sum_type_12', 'sum_type_13', 'sum_type_44', 'sum_type_60'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'sum_type_5'">Командный бонус</span>
													<span v-else-if="index == 'sum_type_6'">Бонус за продажу</span>
													<span v-else-if="index == 'sum_type_7'">Бонус за апгрейд</span>
													<span v-else-if="index == 'sum_type_8'">Кураторский бонус</span>
													<span v-else-if="index == 'sum_type_9'">Кураторский бонус за апгрейд</span>
													<span v-else-if="index == 'sum_type_10'">Бустер бонус</span>
													<span v-else-if="index == 'sum_type_11'">Лидерский бонус</span>
													<span v-else-if="index == 'sum_type_12'">Подарки компании</span>
													<span v-else-if="index == 'sum_type_13'">Управленческий бонус</span>
													<span v-else-if="index == 'sum_type_14'">Бонус за диверсификацию</span>
													<span v-else-if="index == 'sum_type_44'">Промо бонус</span>
													<span v-else-if="index == 'sum_type_60'">iB бонус</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ value.toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Статистика выставленных счетов:</b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['sum_type_17', 'sum_type_18', 'sum_type_19', 'sum_type_20'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'sum_type_17'">Предоплата выставленного разделенного счета</span>
													<span v-else-if="index == 'sum_type_18'">Возврат выставленной предоплаты разделенного счета</span>
													<span v-else-if="index == 'sum_type_19'">Оплата выставленного полного счета</span>
													<span v-else-if="index == 'sum_type_20'">Оплата выставленного разделенного счета</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ value.toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Статистика оплаченных полных счетов:</b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['payment_type_1_1', 'payment_type_1_2', 'payment_type_1_3', 'payment_type_1_4', 'payment_type_1_5', 'payment_type_1_6', 'payment_type_1_7'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'payment_type_1_1'">Полная оплата счета за покупку пакета</span>
													<span v-else-if="index == 'payment_type_1_2'">Полная оплата счета за улучшение пакета</span>
													<span v-else-if="index == 'payment_type_1_3'">Полная оплата счета за продление абонентской платы</span>
													<span v-else-if="index == 'payment_type_1_4'">Полная оплата счета за покупку NOX бота (советника)</span>
													<span v-else-if="index == 'payment_type_1_5'">Полная оплата счета за покупку сервера NOX Box</span>
													<span v-else-if="index == 'payment_type_1_6'">Полная оплата счета за продление абонентской платы сервера NOX Box</span>
													<span v-else-if="index == 'payment_type_1_7'">Полная оплата счета за пополнение основного баланса</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ value.toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Статистика оплаченных разделенных счетов:</b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['payment_type_2_1_0', 'payment_type_2_1_1', 'payment_type_2_1_2', 'payment_type_2_2_0', 'payment_type_2_2_1', 'payment_type_2_2_2', 'payment_type_2_3_0', 'payment_type_2_3_1', 'payment_type_2_3_2', 'payment_type_2_4_0', 'payment_type_2_4_1', 'payment_type_2_4_2', 'payment_type_2_5_0', 'payment_type_2_5_1', 'payment_type_2_5_2', 'payment_type_2_6_0', 'payment_type_2_6_1', 'payment_type_2_6_2'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'payment_type_2_1_0'">Оплата второй части счета за покупку пакета</span>
													<span v-else-if="index == 'payment_type_2_1_1'">Предоплата первой части счета за покупку пакета</span>
													<span v-else-if="index == 'payment_type_2_1_2'">Возврат предоплаты первой части счета за покупку пакета</span>
													<span v-else-if="index == 'payment_type_2_2_0'">Оплата второй части счета за улучшение пакета</span>
													<span v-else-if="index == 'payment_type_2_2_1'">Предоплата первой части счета за улучшение пакета</span>
													<span v-else-if="index == 'payment_type_2_2_2'">Возврат предоплаты первой части счета за улучшение пакета</span>
													<span v-else-if="index == 'payment_type_2_3_0'">Оплата второй части счета за продление абонентской платы</span>
													<span v-else-if="index == 'payment_type_2_3_1'">Предоплата первой части счета за продление абонентской платы</span>
													<span v-else-if="index == 'payment_type_2_3_2'">Возврат предоплаты первой части счета за продление абонентской платы</span>
													<span v-else-if="index == 'payment_type_2_4_0'">Оплата второй части счета за покупку NOX бота (советника)</span>
													<span v-else-if="index == 'payment_type_2_4_1'">Предоплата первой части счета за покупку NOX бота (советника)</span>
													<span v-else-if="index == 'payment_type_2_4_2'">Возврат предоплаты первой части счета за покупку NOX бота (советника)</span>
													<span v-else-if="index == 'payment_type_2_5_0'">Оплата второй части счета за покупку сервера NOX Box</span>
													<span v-else-if="index == 'payment_type_2_5_1'">Предоплата первой части счета за покупку сервера NOX Box</span>
													<span v-else-if="index == 'payment_type_2_5_2'">Возврат предоплаты первой части счета за покупку сервера NOX Box</span>
													<span v-else-if="index == 'payment_type_2_6_0'">Оплата второй части счета за продление абонентской платы сервера NOX Box</span>
													<span v-else-if="index == 'payment_type_2_6_1'">Предоплата первой части счета за продление абонентской платы сервера NOX Box</span>
													<span v-else-if="index == 'payment_type_2_6_2'">Возврат предоплаты первой части счета за продление абонентской платы сервера NOX Box</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ value.toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Статистика потраченных перков:</b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['number_of_perks_1', 'number_of_perks_2', 'number_of_perks_3', 'number_of_perks_4'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'number_of_perks_1'">Продление абонентского обслуживания</span>
													<span v-else-if="index == 'number_of_perks_2'">Увеличение лимита для NOX бота (советника)</span>
													<span v-else-if="index == 'number_of_perks_3'">Добавление бесплатного NOX бота (советника)</span>
													<span v-else-if="index == 'number_of_perks_4'">Открытие класса торговых стратегий "Insomnia"</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ value }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Статистика количества пользователей:</b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in noxTreeStatistics" :key="index" v-show="['number_of_users', 'number_of_users_package', 'number_of_users_package_x0', 'number_of_users_package_x1', 'number_of_users_package_x2', 'number_of_users_package_x3', 'number_of_users_package_x4', 'number_of_users_package_x5', 'number_of_users_package_x6', 'number_of_users_package_x7', 'number_of_users_package_x8', 'number_of_users_package_x9', 'number_of_users_exceeded_limit_package_x0', 'number_of_users_exceeded_limit_package_x1', 'number_of_users_exceeded_limit_package_x2', 'number_of_users_exceeded_limit_package_x3', 'number_of_users_exceeded_limit_package_x4', 'number_of_users_exceeded_limit_package_x5', 'number_of_users_exceeded_limit_package_x6', 'number_of_users_exceeded_limit_package_x7', 'number_of_users_exceeded_limit_package_x8', 'number_of_users_exceeded_limit_package_x9'].includes(index)">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'">
													<span v-if="index == 'number_of_users'">Общее количество пользователей</span>
													<span v-else-if="index == 'number_of_users_package'">Количество пользователей с пакетом</span>
													<span v-else-if="index == 'number_of_users_package_x0'">Количество пользователей с пакетом x0</span>
													<span v-else-if="index == 'number_of_users_package_x1'">Количество пользователей с пакетом x1</span>
													<span v-else-if="index == 'number_of_users_package_x2'">Количество пользователей с пакетом x2</span>
													<span v-else-if="index == 'number_of_users_package_x3'">Количество пользователей с пакетом x3</span>
													<span v-else-if="index == 'number_of_users_package_x4'">Количество пользователей с пакетом x4</span>
													<span v-else-if="index == 'number_of_users_package_x5'">Количество пользователей с пакетом x5</span>
													<span v-else-if="index == 'number_of_users_package_x6'">Количество пользователей с пакетом x6</span>
													<span v-else-if="index == 'number_of_users_package_x7'">Количество пользователей с пакетом x7</span>
													<span v-else-if="index == 'number_of_users_package_x8'">Количество пользователей с пакетом x8</span>
													<span v-else-if="index == 'number_of_users_package_x9'">Количество пользователей с пакетом x9</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x0'">Количество пользователей, которые превысили лимит на пакете x0 (${{ noxSystemSettings.weekly_limit_x0 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x1'">Количество пользователей, которые превысили лимит на пакете x1 (${{ noxSystemSettings.weekly_limit_x1 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x2'">Количество пользователей, которые превысили лимит на пакете x2 (${{ noxSystemSettings.weekly_limit_x2 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x3'">Количество пользователей, которые превысили лимит на пакете x3 (${{ noxSystemSettings.weekly_limit_x3 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x4'">Количество пользователей, которые превысили лимит на пакете x4 (${{ noxSystemSettings.weekly_limit_x4 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x5'">Количество пользователей, которые превысили лимит на пакете x5 (${{ noxSystemSettings.weekly_limit_x5 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x6'">Количество пользователей, которые превысили лимит на пакете x6 (${{ noxSystemSettings.weekly_limit_x6 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x7'">Количество пользователей, которые превысили лимит на пакете x7 (${{ noxSystemSettings.weekly_limit_x7 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x8'">Количество пользователей, которые превысили лимит на пакете x8 (${{ noxSystemSettings.weekly_limit_x8 }})</span>
													<span v-else-if="index == 'number_of_users_exceeded_limit_package_x9'">Количество пользователей, которые превысили лимит на пакете x9 (${{ noxSystemSettings.weekly_limit_x9 }})</span>
												</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">
													<span v-if="['number_of_users', 'number_of_users_package', 'number_of_users_package_x0', 'number_of_users_package_x1', 'number_of_users_package_x2', 'number_of_users_package_x3', 'number_of_users_package_x4', 'number_of_users_package_x5', 'number_of_users_package_x6', 'number_of_users_package_x7', 'number_of_users_package_x8', 'number_of_users_package_x9'].includes(index)">{{ value }}</span>
													<span v-else-if="['number_of_users_exceeded_limit_package_x0', 'number_of_users_exceeded_limit_package_x1', 'number_of_users_exceeded_limit_package_x2', 'number_of_users_exceeded_limit_package_x3', 'number_of_users_exceeded_limit_package_x4', 'number_of_users_exceeded_limit_package_x5', 'number_of_users_exceeded_limit_package_x6', 'number_of_users_exceeded_limit_package_x7', 'number_of_users_exceeded_limit_package_x8', 'number_of_users_exceeded_limit_package_x9'].includes(index)">{{ value }} ({{ (value * 100 / noxTreeStatistics['number_of_users_package']).toFixed(2) }}%)</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title">Итоговая статистика:</b>
									<table class="nox_table">
										<tbody>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="orange">Пополнения средств (пользователями)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="orange">${{ noxTreeStatistics['balance_replenishment'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="orange">Переводы средств (администратором)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="orange">${{ noxTreeStatistics['transfer_by_admin'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="orange">Выставленные счета (администратору)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="orange">${{ noxTreeStatistics['invoices_to_admin'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="orange">Потраченные перки на абонентскую плату</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="orange">{{ noxTreeStatistics['number_of_perks_1'] }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="orange">Потраченные перки на NOX бота (советника)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="orange">{{ noxTreeStatistics['number_of_perks_3'] }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="orange">Активированные купоны (созданные администратором)</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="orange">${{ noxTreeStatistics['coupon_by_admin'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="green">Прибыль за оплату NOX VPN</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="green">${{ noxTreeStatistics['profit_nox_vpn'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="green">Прибыль за покупки NOX Box</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="green">${{ noxTreeStatistics['profit_nox_box'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="green">Прибыль за покупки NOX Tickets</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="green">${{ noxTreeStatistics['profit_nox_tickets'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="green">Прибыль за покупки основных услуг</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="green">${{ noxTreeStatistics['profit_nox_services'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="red">Расходы на пополнения баланса NOX ZP</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="red">${{ noxTreeStatistics['expense_by_balance_nox_zp'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="red">Расходы на пополнения бонусного баланса</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="red">${{ noxTreeStatistics['expense_by_balance_of_bonuses'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="red">Расходы на бонусы клиентской программы</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="red">${{ noxTreeStatistics['expense_by_bonuses'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="red">Убыточность клиентской программы</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="red">{{ noxTreeStatistics['program_loss'].toFixed(2) }}%</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="red">Итоговая сумма расходов</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="red">${{ noxTreeStatistics['total_expense'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="green">Итоговая сумма прибыли</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="green">${{ noxTreeStatistics['total_profit'].toFixed(2) }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" class="green">Итоговая сумма доходов</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="green">${{ noxTreeStatistics['total_income'].toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div v-else>
								<div class="nox_account_desktop_tree_statistics_data">
									<b class="nox_account_desktop_tree_statistics_data_title" v-html="$store.getters.getLanguageText('3.6.1', 2)"></b>
									<table class="nox_table">
										<tbody>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 3)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.total_points_for_1_week }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 7)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.total_points_for_prev_1_week }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 4)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.total_points_for_1_month }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 8)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.total_points_for_prev_1_month }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 5)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.total_points_for_6_months }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 9)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.total_points_for_prev_6_months }}</td>
											</tr>
											<tr v-if="noxTreeStatistics.sprint_status">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 10, { start: $parent.$parent.getSprintFormat(noxTreeStatistics.sprint_start), finish: $parent.$parent.getSprintFormat(noxTreeStatistics.sprint_finish) })"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.sprint_points }}</td>
											</tr>
											<tr>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 6)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">{{ noxTreeStatistics.total_points_for_all_time }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data" v-if="noxTreeStatistics.bonus_amount">
									<b class="nox_account_desktop_tree_statistics_data_title" v-html="$store.getters.getLanguageText('3.6.1', 11)"></b>
									<table class="nox_table">
										<tbody>
											<tr v-for="(value, index) in [5,6,7,8,9,10,11,12,13,14,44,60]" :key="index" v-show="noxTreeStatistics['bonus_amount_for_' + value]">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', (12 + Number(index)))"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ (noxTreeStatistics['bonus_amount_for_' + value] ? noxTreeStatistics['bonus_amount_for_' + value] : 0).toFixed(2) }}</td>
											</tr>
											<tr class="green">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'" v-html="$store.getters.getLanguageText('3.6.1', 24)"></td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'">${{ (noxTreeStatistics.bonus_amount ? noxTreeStatistics.bonus_amount : 0).toFixed(2) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="nox_account_desktop_tree_statistics_data" v-if="noxTreeStatistics.bonus_amount">
									<b class="nox_account_desktop_tree_statistics_data_title" v-html="$store.getters.getLanguageText('3.6.1', 25)"></b>
									<div class="nox_chart" v-if="noxTreeStatisticsChart.series.length">
										<div class="nox_chart_highcharts"><highcharts :options="noxTreeStatisticsChart"></highcharts></div>
									</div>
								</div>
							</div>
						</div>
						<div v-else v-html="$store.getters.getLanguageText('3.6.1', 1)"></div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading1 }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading1 }">
					<button type="button" class="nox_button big" @click="closeTreeStatistics" v-if="Object.keys(noxTreeStatistics).length"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 38)"></span></button>
					<button type="button" class="nox_button big green" @click="axios('getTreeStatistics')" v-else><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 37)"></span></button>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="noxOperationsData.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.2', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_operations moperations">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 5)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxOperationsData" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 5) + ':'">
										<div v-if="[40,41,46,52,53,54,58,61].includes(value.type) || (value.type == 12 && value.details.conditions[0] == 0)">−</div>
										<a :href="$store.state.noxMeBaseURL + '/files/purchase/receipt.php?id=' + value.id + value.created_at" class="red" target="_blank" v-else-if="[2,3,4,15,16,19,20,25,26,27,28,30,31,33,35,37,38,42,45,47,50,55,56,57].includes(value.type)">−&#160;${{ value.amount.toFixed(2) }}</a>
										<div :class="{ blue: [17].includes(value.type), green: ([1,5,6,7,8,9,10,11,13,14,18,29,32,34,36,39,43,44,48,49,51,60].includes(value.type) || (value.type == 12 && value.details.conditions[0] == 1)) }" v-else>
											<span v-if="[1,5,6,7,8,9,10,11,12,13,14,18,29,32,34,36,39,43,44,48,49,51,60].includes(value.type)">+</span>
											<span v-else-if="value.type == 17" v-html="$store.getters.getLanguageText('1.7.2', 6)"></span>
											<span>&#160;${{ value.amount.toFixed(2) }}</span>
										</div>
									</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.3', value.details)"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
									<td class="nox_account_desktop_moperations">
										<div class="nox_account_desktop_moperations_row title">
											<div><b><span v-html="$store.getters.getLanguageText('1.7.1', 22)"></span>{{ value.id }}.</b> {{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
											<div>
												<div v-if="[40,41,46,52,53,54,58,61].includes(value.type) || (value.type == 12 && value.details.conditions[0] == 0)">−</div>
												<a :href="$store.state.noxMeBaseURL + '/files/purchase/receipt.php?id=' + value.id + value.created_at" class="red" target="_blank" v-else-if="[2,3,4,15,16,19,20,25,26,27,28,30,31,33,35,37,38,42,45,47,50,55,56,57].includes(value.type)">−&#160;${{ value.amount.toFixed(2) }}</a>
												<div :class="{ blue: [17].includes(value.type), green: ([1,5,6,7,8,9,10,11,13,14,18,29,32,34,36,39,43,44,48,49,51,60].includes(value.type) || (value.type == 12 && value.details.conditions[0] == 1)) }" v-else>
													<span v-if="[1,5,6,7,8,9,10,11,12,13,14,18,29,32,34,36,39,43,44,48,49,51,60].includes(value.type)">+</span>
													<span v-else-if="value.type == 17" v-html="$store.getters.getLanguageText('1.7.2', 6)"></span>
													<span>&#160;${{ value.amount.toFixed(2) }}</span>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_moperations_row content">
											<div v-html="$parent.$parent.getDescription('3.6.3', value.details)"></div>
											<div></div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxOperationsLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading2 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading2 }">
						<button type="button" class="nox_button big" @click="axios('getOperationsData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="noxPerksData.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.10', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_perks">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxPerksData" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.11', value.details)"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxPerksLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading3 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading3 }">
						<button type="button" class="nox_button big" @click="axios('getPerksData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="$store.state.noxTransfersData.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.12', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_transfers">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 2)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 42)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 43)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 45)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxTransfersData" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 2) + ':'">{{ value.sender_id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 42) + ':'">{{ value.recipient_id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 43) + ':'"><span class="orange" v-if="value.sender_id == $store.state.noxAccountData.id" v-html="$store.getters.getLanguageText('1.7.2', 19)"></span><span class="green" v-else v-html="$store.getters.getLanguageText('1.7.2', 18)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 45) + ':'">${{ value.amount.toFixed(2) }} + ${{ (value.amount * value.commission / 100).toFixed(2) }} ({{ value.commission }}%)</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.13', value.details)"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-if="value.status == 2"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 8)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.sender_id == $store.state.noxAccountData.id && value.status == 1"><span class="green" v-html="$store.getters.getLanguageText('1.7.2', 20)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" :class="{ button: Number($store.state.noxSystemSettings.time) < (Number(value.created_at) + (30 * 24 * 60 * 60)) }" v-else-if="value.recipient_id == $store.state.noxAccountData.id && value.status == 1">
										<span class="green" v-html="$store.getters.getLanguageText('1.7.2', 21)"></span>
										<div v-if="Number($store.state.noxSystemSettings.time) < (Number(value.created_at) + (30 * 24 * 60 * 60))"><button type="button" class="nox_button red" @click="$modal.show('NoxModalBalance', { type: (value.type == 'grey' ? 'refundTransferGreyBalance' : 'refundTransferBonusBalance'), index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></div>
									</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxTransfersLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading4 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading4 }">
						<button type="button" class="nox_button big" @click="axios('getTransfersData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="noxPaymentPurchaseData.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.5', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_payment_purchase">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 5)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 54)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxPaymentPurchaseData" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 5) + ':'">
										<span v-if="value.payment_type == 2 && value.payment_subtype == 1" v-html="$store.getters.getLanguageText('1.7.2', 6)"></span>
										<span>&#160;${{ value.payment_cost.toFixed(2) }}</span>
									</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 54) + ':'">
										<span v-if="value.payment_type == 2 && value.payment_subtype == 1" v-html="$store.getters.getLanguageText('1.7.2', 6)"></span>
										<span>&#160;${{ value.payment_amount.toFixed(2) }} ({{ value.service_vat }}%)</span>
									</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.6', value.payment_description)"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'">
										<a :href="$store.state.noxMeBaseURL + '/files/payment/receipt.php?id=' + value.payment_id.replace(/#/g, '')" :class="[([1,2,3,4].includes(value.status) ? (value.status == 1 ? 'green' : 'red') : '')]" target="_blank">
											<span v-html="$store.getters.getLanguageText('1.7.2', (value.payment_type == 2 ? (value.payment_subtype == 1 ? 7 : (value.payment_subtype == 2 ? 8 : 2)) : 2))" v-if="value.status == 1"></span>
											<span v-html="$store.getters.getLanguageText('1.7.2', 3)" v-else-if="value.status == 2"></span>
											<span v-html="$store.getters.getLanguageText('1.7.2', 4)" v-else-if="value.status == 3"></span>
											<span v-html="$store.getters.getLanguageText('1.7.2', 9)" v-else-if="value.status == 4"></span>
											<span v-html="$store.getters.getLanguageText('1.7.2', 5)" v-else></span>
										</a>
									</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxPaymentPurchaseLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading5 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading5 }">
						<button type="button" class="nox_button big" @click="axios('getPaymentPurchaseData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="noxPaymentPayoutData.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.8', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_payment_payout">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 20)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 21)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxPaymentPayoutData" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 20) + ':'" v-if="[6,10].includes(value.payment_method_id)">${{ (Math.ceil((value.payment_amount + value.payment_description.fee_amount) * 10000 / (100 - value.payment_description.fee_percent)) / 100).toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 20) + ':'" v-else>${{ (Math.ceil(value.payment_amount * 10000 / (100 - value.payment_description.commission)) / 100).toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 21) + ':'" v-if="[6,10].includes(value.payment_method_id)">{{ value.payment_amount.toFixed(2) }} USDT + ${{ (Math.ceil((value.payment_amount + value.payment_description.fee_amount) * 100 / (100 - value.payment_description.fee_percent) * value.payment_description.fee_percent) / 100).toFixed(2) }} ({{ value.payment_description.fee_percent }}%) + ${{ value.payment_description.fee_amount.toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 21) + ':'" v-else>${{ value.payment_amount.toFixed(2) }} + ${{ (Math.ceil(value.payment_amount * 100 / (100 - value.payment_description.commission) * value.payment_description.commission) / 100).toFixed(2) }} ({{ value.payment_description.commission }}%)</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.9', value.payment_description)"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-if="value.status == 1"><span class="green" v-html="$store.getters.getLanguageText('1.7.2', 14)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 2"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 3)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 3"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 4)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 4"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 9)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else><span v-html="$store.getters.getLanguageText('1.7.2', 5)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxPaymentPayoutLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading6 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading6 }">
						<button type="button" class="nox_button big" @click="axios('getPaymentPayoutData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="noxPaymentPayoutZPData.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.8', 1)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_payment_payout">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 20)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 21)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxPaymentPayoutZPData" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 20) + ':'" v-if="[6,10].includes(value.payment_method_id)">${{ (Math.ceil((value.payment_amount + value.payment_description.fee_amount) * 10000 / (100 - value.payment_description.fee_percent)) / 100).toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 20) + ':'" v-else>${{ (Math.ceil(value.payment_amount * 10000 / (100 - value.payment_description.commission)) / 100).toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 21) + ':'" v-if="[6,10].includes(value.payment_method_id)">{{ value.payment_amount.toFixed(2) }} USDT + ${{ (Math.ceil((value.payment_amount + value.payment_description.fee_amount) * 100 / (100 - value.payment_description.fee_percent) * value.payment_description.fee_percent) / 100).toFixed(2) }} ({{ value.payment_description.fee_percent }}%) + ${{ value.payment_description.fee_amount.toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 21) + ':'" v-else>${{ value.payment_amount.toFixed(2) }} + ${{ (Math.ceil(value.payment_amount * 100 / (100 - value.payment_description.commission) * value.payment_description.commission) / 100).toFixed(2) }} ({{ value.payment_description.commission }}%)</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" v-html="$parent.$parent.getDescription('3.6.9', value.payment_description)"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-if="value.status == 1"><span class="green" v-html="$store.getters.getLanguageText('1.7.2', 14)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 2"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 3)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 3"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 4)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 4"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 9)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else><span v-html="$store.getters.getLanguageText('1.7.2', 5)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxPaymentPayoutZPLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading7 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading7 }">
						<button type="button" class="nox_button big" @click="axios('getPaymentPayoutZPData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="noxInvoicesDataBySenderId.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.4', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_invoices_by_sender_id">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 3)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 4)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 6)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 7)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxInvoicesDataBySenderId" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 3) + ':'">{{ value.recipient_id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 4) + ':'">${{ value.service_cost.toFixed(2) }} + ${{ (value.service_cost * value.vat / 100).toFixed(2) }} ({{ value.vat }}%)</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 6) + ':'">${{ value.amount.toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 7) + ':'" v-html="$store.getters.getLanguageText('1.7.2', (value.type == 1 ? 0 : 1))"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-if="value.status == 1"><span class="green" v-html="$store.getters.getLanguageText('1.7.2', 2)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 2"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 3)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 3"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 4)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else><span v-html="$store.getters.getLanguageText('1.7.2', 5)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxInvoicesBySenderIdLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading8 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading8 }">
						<button type="button" class="nox_button big" @click="axios('getInvoicesDataBySenderId')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="noxInvoicesDataByRecipientId.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.4', 1)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_invoices_by_recipient_id">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 2)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 6)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 7)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxInvoicesDataByRecipientId" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 2) + ':'">{{ value.sender_id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 6) + ':'">${{ value.amount.toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 7) + ':'" v-html="$store.getters.getLanguageText('1.7.2', (value.type == 1 ? 0 : 1))"></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-if="value.status == 1"><span class="green" v-html="$store.getters.getLanguageText('1.7.2', 2)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 2"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 3)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else-if="value.status == 3"><span class="red" v-html="$store.getters.getLanguageText('1.7.2', 4)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else><span v-html="$store.getters.getLanguageText('1.7.2', 5)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="noxInvoicesByRecipientIdLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading9 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading9 }">
						<button type="button" class="nox_button big" @click="axios('getInvoicesDataByRecipientId')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-if="$store.state.noxCouponsData.length">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.6.14', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_coupons">
						<table class="nox_table">
							<thead>
								<tr>
									<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 46)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 42)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 47)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 48)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
									<th v-html="$store.getters.getLanguageText('1.7.1', 12)"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxCouponsData" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 46) + ':'">{{ (value.creator_id == $store.state.noxAccountData.id ? value.creator_id : '−') }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 42) + ':'">{{ (value.recipient_id ? value.recipient_id : '−') }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 47) + ':'">
										<span class="margin_right_5" v-if="noxIsShowCoupons[value.id] == 1">{{ value.coupon }}</span>
										<span class="margin_right_5" v-else>{{ (value.coupon.slice(0, 4) + '************' + value.coupon.slice(-4)) }}</span>
										<button type="button" class="nox_button nox_is_icon blue" @click="$set(noxIsShowCoupons, value.id, (noxIsShowCoupons[value.id] == 1 ? 0 : 1))"><font-awesome-icon :icon="['fas', (noxIsShowCoupons[value.id] == 1 ? 'eye-slash' : 'eye')]" /></button>
									</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 48) + ':'">${{ value.amount.toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-if="value.status"><span class="green" v-html="$store.getters.getLanguageText('1.7.2', 22)"></span><br>{{ new Date(value.updated_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" v-else><span class="orange" v-html="$store.getters.getLanguageText('1.7.2', 23)"></span></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 12) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="$store.state.noxCouponsLimitOffset">
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading10 }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading10 }">
						<button type="button" class="nox_button big" @click="axios('getCouponsData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxBonus9CountdownTime: 0,
			noxBonus9CountdownValues: '',
			noxBonus9CountdownLabels: '',
			noxBonus9CountdownTimerId: 0,
			noxSystemSettings: {},
			noxTreeStatistics: {},
			noxTreeStatisticsChart: [],
			noxOperationsData: [],
			noxOperationsLimitOffset: 0,
			noxOperationsLimitCount: 10,
			noxPerksData: [],
			noxPerksLimitOffset: 0,
			noxPerksLimitCount: 10,
			noxTransfersLimitOffset: 0,
			noxTransfersLimitCount: 10,
			noxPaymentPurchaseData: [],
			noxPaymentPurchaseLimitOffset: 0,
			noxPaymentPurchaseLimitCount: 10,
			noxPaymentPayoutData: [],
			noxPaymentPayoutLimitOffset: 0,
			noxPaymentPayoutLimitCount: 10,
			noxPaymentPayoutZPData: [],
			noxPaymentPayoutZPLimitOffset: 0,
			noxPaymentPayoutZPLimitCount: 10,
			noxInvoicesDataBySenderId: [],
			noxInvoicesBySenderIdLimitOffset: 0,
			noxInvoicesBySenderIdLimitCount: 10,
			noxInvoicesDataByRecipientId: [],
			noxInvoicesByRecipientIdLimitOffset: 0,
			noxInvoicesByRecipientIdLimitCount: 10,
			noxLimitOffset: 0,
			noxLimitCount: 10,
			noxIsShowCoupons: {},
			noxIsBonus9Countdown: false,
			noxIsBonus9CountdownExpires: false,
			noxIsPortletLoading1: false,
			noxIsPortletLoading2: false,
			noxIsPortletLoading3: false,
			noxIsPortletLoading4: false,
			noxIsPortletLoading5: false,
			noxIsPortletLoading6: false,
			noxIsPortletLoading7: false,
			noxIsPortletLoading8: false,
			noxIsPortletLoading9: false,
			noxIsPortletLoading10: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getBalanceData');
			},
			getBonus9Countdown: function() {

				var time = this.noxBonus9CountdownTime;
				var seconds = (time - (new Date().getTime())) / 1000;
				var days = parseInt(seconds / 86400); seconds = seconds % 86400;
				var hours = parseInt(seconds / 3600); seconds = seconds % 3600;
				var minutes = parseInt(seconds / 60);
				var values = '<span>' + this.$parent.$parent.getNumberPad(days) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(hours) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(minutes) + '</span>';
				var labels = '<span>' + this.$store.getters.getLanguageText('1.4', 0) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 1) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 2) + '</span>';

				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
					values = '<span>' + Math.floor(seconds) + '</span>';
					labels = '<span>' + this.$store.getters.getLanguageText('1.4', 3) + '</span>';
				}

				this.noxBonus9CountdownValues = values;
				this.noxBonus9CountdownLabels = labels;

				if (Number(days) <= 0) { this.noxIsBonus9CountdownExpires = true; }
				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
					this.noxIsBonus9Countdown = false; clearInterval(this.noxBonus9CountdownTimerId);
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getBalanceData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/account/balance';
						config.params = { limitOffset: _this.noxLimitOffset, limitCount: _this.noxLimitCount };
					}
					else if (type == 'getTreeStatistics') {
						_this.noxIsPortletLoading1 = true;
						config.url = '/v2' + (_this.$store.state.noxAccountData.group == 1 ? '/admin' : '') + '/account/tree/statistics';
					}
					else if (type == 'getOperationsData') {
						_this.noxIsPortletLoading2 = true;
						config.url = '/v2/account/balance/operations';
						config.params = { limitOffset: _this.noxOperationsLimitOffset, limitCount: _this.noxOperationsLimitCount };
					}
					else if (type == 'getPerksData') {
						_this.noxIsPortletLoading3 = true;
						config.url = '/v2/account/balance/perks';
						config.params = { limitOffset: _this.noxPerksLimitOffset, limitCount: _this.noxPerksLimitCount };
					}
					else if (type == 'getTransfersData') {
						_this.noxIsPortletLoading4 = true;
						config.url = '/v2/account/balance/transfers';
						config.params = { limitOffset: _this.noxTransfersLimitOffset, limitCount: _this.noxTransfersLimitCount };
					}
					else if (type == 'getPaymentPurchaseData') {
						_this.noxIsPortletLoading5 = true;
						config.url = '/v2/account/balance/payments/purchase';
						config.params = { limitOffset: _this.noxPaymentPurchaseLimitOffset, limitCount: _this.noxPaymentPurchaseLimitCount };
					}
					else if (type == 'getPaymentPayoutData') {
						_this.noxIsPortletLoading6 = true;
						config.url = '/v2/account/balance/payments/payout';
						config.params = { limitOffset: _this.noxPaymentPayoutLimitOffset, limitCount: _this.noxPaymentPayoutLimitCount };
					}
					else if (type == 'getPaymentPayoutZPData') {
						_this.noxIsPortletLoading7 = true;
						config.url = '/v2/account/balance/payments/payout/zp';
						config.params = { limitOffset: _this.noxPaymentPayoutZPLimitOffset, limitCount: _this.noxPaymentPayoutZPLimitCount };
					}
					else if (type == 'getInvoicesDataBySenderId') {
						_this.noxIsPortletLoading8 = true;
						config.url = '/v2/account/balance/invoices';
						config.params = { type: 1, limitOffset: _this.noxInvoicesBySenderIdLimitOffset, limitCount: _this.noxInvoicesBySenderIdLimitCount };
					}
					else if (type == 'getInvoicesDataByRecipientId') {
						_this.noxIsPortletLoading9 = true;
						config.url = '/v2/account/balance/invoices';
						config.params = { type: 2, limitOffset: _this.noxInvoicesByRecipientIdLimitOffset, limitCount: _this.noxInvoicesByRecipientIdLimitCount };
					}
					else if (type == 'getCouponsData') {
						_this.noxIsPortletLoading10 = true;
						config.url = '/v2/account/balance/coupons';
						config.params = { limitOffset: _this.$store.state.noxCouponsLimitOffset, limitCount: _this.$store.state.noxCouponsLimitCount };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getBalanceData') {
								_this.$parent.$parent.goToTop(!_this.noxLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.account_data !== undefined) {
									_this.$parent.$parent.initAccountData(data.data.account_data);
								}
								if (data.data.tree_data !== undefined) {
									if (data.data.tree_data !== null && data.data.tree_data.bonus_9_finish > 0) {
										_this.noxBonus9CountdownTime = data.data.tree_data.bonus_9_finish * 1000;
										if (_this.noxBonus9CountdownTime > new Date().getTime()) {
											_this.noxIsBonus9Countdown = true; _this.getBonus9Countdown();
											_this.noxBonus9CountdownTimerId = setInterval(function() { _this.getBonus9Countdown(); }, 1000);
										}
									}
								}
								if (data.data.operations_data !== undefined) {
									if (data.data.operations_data.data.length) {
										for (var a in data.data.operations_data.data) {
											_this.noxOperationsData.push(data.data.operations_data.data[a]);
										}
									}
									_this.noxOperationsLimitOffset = data.data.operations_data.count > _this.noxOperationsLimitOffset + _this.noxOperationsLimitCount ? _this.noxOperationsLimitOffset + _this.noxOperationsLimitCount : 0;
								}
								if (data.data.perks_data !== undefined) {
									if (data.data.perks_data.data.length) {
										for (var b in data.data.perks_data.data) {
											_this.noxPerksData.push(data.data.perks_data.data[b]);
										}
									}
									_this.noxPerksLimitOffset = data.data.perks_data.count > _this.noxPerksLimitOffset + _this.noxPerksLimitCount ? _this.noxPerksLimitOffset + _this.noxPerksLimitCount : 0;
								}
								if (data.data.transfers_data !== undefined) {
									_this.$store.state.noxTransfersData = [];
									if (data.data.transfers_data.data.length) {
										for (var c in data.data.transfers_data.data) {
											_this.$store.state.noxTransfersData.push(data.data.transfers_data.data[c]);
										}
									}
									_this.noxTransfersLimitOffset = data.data.transfers_data.count > _this.noxTransfersLimitOffset + _this.noxTransfersLimitCount ? _this.noxTransfersLimitOffset + _this.noxTransfersLimitCount : 0;
								}
								if (data.data.payment_purchase_data !== undefined) {
									if (data.data.payment_purchase_data.data.length) {
										for (var d in data.data.payment_purchase_data.data) {
											_this.noxPaymentPurchaseData.push(data.data.payment_purchase_data.data[d]);
										}
									}
									_this.noxPaymentPurchaseLimitOffset = data.data.payment_purchase_data.count > _this.noxPaymentPurchaseLimitOffset + _this.noxPaymentPurchaseLimitCount ? _this.noxPaymentPurchaseLimitOffset + _this.noxPaymentPurchaseLimitCount : 0;
								}
								if (data.data.payment_payout_data !== undefined) {
									if (data.data.payment_payout_data.data.length) {
										for (var e in data.data.payment_payout_data.data) {
											_this.noxPaymentPayoutData.push(data.data.payment_payout_data.data[e]);
										}
									}
									_this.noxPaymentPayoutLimitOffset = data.data.payment_payout_data.count > _this.noxPaymentPayoutLimitOffset + _this.noxPaymentPayoutLimitCount ? _this.noxPaymentPayoutLimitOffset + _this.noxPaymentPayoutLimitCount : 0;
								}
								if (data.data.payment_payout_zp_data !== undefined) {
									if (data.data.payment_payout_zp_data.data.length) {
										for (var f in data.data.payment_payout_zp_data.data) {
											_this.noxPaymentPayoutZPData.push(data.data.payment_payout_zp_data.data[f]);
										}
									}
									_this.noxPaymentPayoutZPLimitOffset = data.data.payment_payout_zp_data.count > _this.noxPaymentPayoutZPLimitOffset + _this.noxPaymentPayoutZPLimitCount ? _this.noxPaymentPayoutZPLimitOffset + _this.noxPaymentPayoutZPLimitCount : 0;
								}
								if (data.data.invoices_data_by_sender_id !== undefined) {
									if (data.data.invoices_data_by_sender_id.data.length) {
										for (var g in data.data.invoices_data_by_sender_id.data) {
											_this.noxInvoicesDataBySenderId.push(data.data.invoices_data_by_sender_id.data[g]);
										}
									}
									_this.noxInvoicesBySenderIdLimitOffset = data.data.invoices_data_by_sender_id.count > _this.noxInvoicesBySenderIdLimitOffset + _this.noxInvoicesBySenderIdLimitCount ? _this.noxInvoicesBySenderIdLimitOffset + _this.noxInvoicesBySenderIdLimitCount : 0;
								}
								if (data.data.invoices_data_by_recipient_id !== undefined) {
									if (data.data.invoices_data_by_recipient_id.data.length) {
										for (var h in data.data.invoices_data_by_recipient_id.data) {
											_this.noxInvoicesDataByRecipientId.push(data.data.invoices_data_by_recipient_id.data[h]);
										}
									}
									_this.noxInvoicesByRecipientIdLimitOffset = data.data.invoices_data_by_recipient_id.count > _this.noxInvoicesByRecipientIdLimitOffset + _this.noxInvoicesByRecipientIdLimitCount ? _this.noxInvoicesByRecipientIdLimitOffset + _this.noxInvoicesByRecipientIdLimitCount : 0;
								}
								if (data.data.coupons_data !== undefined) {
									_this.$store.state.noxCouponsData = [];
									if (data.data.coupons_data.data.length) {
										for (var i in data.data.coupons_data.data) {
											_this.$store.state.noxCouponsData.push(data.data.coupons_data.data[i]);
										}
									}
									_this.$store.state.noxCouponsLimitOffset = data.data.coupons_data.count > _this.$store.state.noxCouponsLimitOffset + _this.$store.state.noxCouponsLimitCount ? _this.$store.state.noxCouponsLimitOffset + _this.$store.state.noxCouponsLimitCount : 0;
								}
							}
							else if (type == 'getTreeStatistics') {
								if (data.data.settings !== undefined) { _this.noxSystemSettings = data.data.settings; }
								if (data.data.statistics !== undefined) {
									_this.noxTreeStatistics = data.data.statistics;
									if (_this.$store.state.noxAccountData.group !== 1) {
										_this.noxTreeStatisticsChart = {
											title: { text: _this.$store.getters.getLanguageText('3.6.1', 26) },
											chart: { height: 300 }, legend: { enabled: true }, series: [],
											tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForDollar(this, 2, 1); }}
										};
										if (data.data.statistics.operations.length) {
											var operations = data.data.statistics.operations.reverse();
											var bonusTypes = data.data.statistics.bonus_types, bonusAmounts = {}, bonusTempAmounts = {};
											if (bonusTypes.length) {
												bonusTypes.sort(function(a, b) { return a - b; });
												operations.push({ created_at: (new Date().getTime() / 1000) });
												for (var i1 in bonusTypes) { bonusTempAmounts[bonusTypes[i1]] = 0; }
												for (var i2 in operations) {
													if (operations[i2].type !== undefined) { bonusTempAmounts[operations[i2].type] += operations[i2].amount; }
												}
												for (var i3 in bonusTypes) {
													bonusAmounts[bonusTypes[i3]] = (data.data.statistics['bonus_amount_for_' + bonusTypes[i3]] - bonusTempAmounts[bonusTypes[i3]]); _this.noxTreeStatisticsChart.series.push({ name: _this.$store.getters.getLanguageText('3.6.1', (bonusTypes[i3] == 44 ? 22 : (bonusTypes[i3] == 60 ? 23 : (7 + bonusTypes[i3])))), color: _this.$store.state.noxChartColors[i3], data: [] });
												}
												for (var i4 in operations) {
													var curDate = new Date(operations[i4].created_at * 1000);
													var newDate = new Date(curDate.getUTCFullYear(), curDate.getUTCMonth(), curDate.getUTCDate()).getTime();
													if (operations[i4].type !== undefined) { bonusAmounts[operations[i4].type] += operations[i4].amount; }
													for (var i5 in bonusTypes) {
														_this.noxTreeStatisticsChart.series[i5].data.push([newDate, parseFloat(bonusAmounts[bonusTypes[i5]].toFixed(2))]);
													}
												}
											}
										}
									}
								}
							}
							else if (type == 'getOperationsData') {
								if (data.data.data.length) {
									for (var aa in data.data.data) {
										_this.noxOperationsData.push(data.data.data[aa]);
									}
								}
								_this.noxOperationsLimitOffset = data.data.count > _this.noxOperationsLimitOffset + _this.noxOperationsLimitCount ? _this.noxOperationsLimitOffset + _this.noxOperationsLimitCount : 0;
							}
							else if (type == 'getPerksData') {
								if (data.data.data.length) {
									for (var bb in data.data.data) {
										_this.noxPerksData.push(data.data.data[bb]);
									}
								}
								_this.noxPerksLimitOffset = data.data.count > _this.noxPerksLimitOffset + _this.noxPerksLimitCount ? _this.noxPerksLimitOffset + _this.noxPerksLimitCount : 0;
							}
							else if (type == 'getTransfersData') {
								if (data.data.data.length) {
									for (var cc in data.data.data) {
										_this.$store.state.noxTransfersData.push(data.data.data[cc]);
									}
								}
								_this.noxTransfersLimitOffset = data.data.count > _this.noxTransfersLimitOffset + _this.noxTransfersLimitCount ? _this.noxTransfersLimitOffset + _this.noxTransfersLimitCount : 0;
							}
							else if (type == 'getPaymentPurchaseData') {
								if (data.data.data.length) {
									for (var dd in data.data.data) {
										_this.noxPaymentPurchaseData.push(data.data.data[dd]);
									}
								}
								_this.noxPaymentPurchaseLimitOffset = data.data.count > _this.noxPaymentPurchaseLimitOffset + _this.noxPaymentPurchaseLimitCount ? _this.noxPaymentPurchaseLimitOffset + _this.noxPaymentPurchaseLimitCount : 0;
							}
							else if (type == 'getPaymentPayoutData') {
								if (data.data.data.length) {
									for (var ee in data.data.data) {
										_this.noxPaymentPayoutData.push(data.data.data[ee]);
									}
								}
								_this.noxPaymentPayoutLimitOffset = data.data.count > _this.noxPaymentPayoutLimitOffset + _this.noxPaymentPayoutLimitCount ? _this.noxPaymentPayoutLimitOffset + _this.noxPaymentPayoutLimitCount : 0;
							}
							else if (type == 'getPaymentPayoutZPData') {
								if (data.data.data.length) {
									for (var ff in data.data.data) {
										_this.noxPaymentPayoutZPData.push(data.data.data[ff]);
									}
								}
								_this.noxPaymentPayoutZPLimitOffset = data.data.count > _this.noxPaymentPayoutZPLimitOffset + _this.noxPaymentPayoutZPLimitCount ? _this.noxPaymentPayoutZPLimitOffset + _this.noxPaymentPayoutZPLimitCount : 0;
							}
							else if (type == 'getInvoicesDataBySenderId') {
								if (data.data.data.length) {
									for (var gg in data.data.data) {
										_this.noxInvoicesDataBySenderId.push(data.data.data[gg]);
									}
								}
								_this.noxInvoicesBySenderIdLimitOffset = data.data.count > _this.noxInvoicesBySenderIdLimitOffset + _this.noxInvoicesBySenderIdLimitCount ? _this.noxInvoicesBySenderIdLimitOffset + _this.noxInvoicesBySenderIdLimitCount : 0;
							}
							else if (type == 'getInvoicesDataByRecipientId') {
								if (data.data.data.length) {
									for (var hh in data.data.data) {
										_this.noxInvoicesDataByRecipientId.push(data.data.data[hh]);
									}
								}
								_this.noxInvoicesByRecipientIdLimitOffset = data.data.count > _this.noxInvoicesByRecipientIdLimitOffset + _this.noxInvoicesByRecipientIdLimitCount ? _this.noxInvoicesByRecipientIdLimitOffset + _this.noxInvoicesByRecipientIdLimitCount : 0;
							}
							else if (type == 'getCouponsData') {
								if (data.data.data.length) {
									for (var ii in data.data.data) {
										_this.$store.state.noxCouponsData.push(data.data.data[ii]);
									}
								}
								_this.$store.state.noxCouponsLimitOffset = data.data.count > _this.$store.state.noxCouponsLimitOffset + _this.$store.state.noxCouponsLimitCount ? _this.$store.state.noxCouponsLimitOffset + _this.$store.state.noxCouponsLimitCount : 0;
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading1 = false;
						_this.noxIsPortletLoading2 = false;
						_this.noxIsPortletLoading3 = false;
						_this.noxIsPortletLoading4 = false;
						_this.noxIsPortletLoading5 = false;
						_this.noxIsPortletLoading6 = false;
						_this.noxIsPortletLoading7 = false;
						_this.noxIsPortletLoading8 = false;
						_this.noxIsPortletLoading9 = false;
						_this.noxIsPortletLoading10 = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			closeTreeStatistics: function() {
				this.noxTreeStatistics = {};
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_balance {
		position: relative;
	}
	.nox_account_desktop_balance.is_bonus_9 {
		padding: 0 0 0 285px;
	}
	.nox_account_desktop_balance_widget {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 15px;
		background: #333333B3;
		text-align: center;
		width: 270px;
	}
	.nox_account_desktop_balance_widget:nth-child(2) {
		left: 285px;
	}
	.nox_account_desktop_balance_widget.red {
		background: #FF000080;
	}
	.nox_account_desktop_balance_widget .nox_countdown {
		position: absolute;
		top: 50%;
		margin: -70px 0 0 0;
		width: 100%;
		height: 140px;
	}
	.nox_account_desktop_balance_widget .nox_countdown .title {
		font-size: 12px;
	}
	.nox_account_desktop_balance_widget .nox_countdown_title {
		margin: 3px 0 15px 0;
		color: #00FF00;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
		display: inline-block;
	}
	.nox_account_desktop_balance_widget.red .nox_countdown_title {
		color: #FFFFFF;
	}
	.nox_account_desktop_balance_content .red {
		color: #FFBBBB;
	}
	.nox_account_desktop_tree_statistics_data {
		margin: 0 0 25px 0;
	}
	.nox_account_desktop_tree_statistics_data:last-child {
		margin: 0;
	}
	.nox_account_desktop_tree_statistics_data tr th,
	.nox_account_desktop_tree_statistics_data tr td {
		width: 50%;
	}
	.nox_account_desktop_tree_statistics_data_title {
		margin: 0 0 15px 0;
		text-transform: uppercase;
		text-align: center;
		font-size: 12px;
		display: block;
		width: 100%;
	}
	.nox_account_desktop_operations tr th:nth-child(1),
	.nox_account_desktop_operations tr td:nth-child(1),
	.nox_account_desktop_coupons tr th:nth-child(1),
	.nox_account_desktop_coupons tr td:nth-child(1),
	.nox_account_desktop_coupons tr th:nth-child(2),
	.nox_account_desktop_coupons tr td:nth-child(2),
	.nox_account_desktop_coupons tr th:nth-child(3),
	.nox_account_desktop_coupons tr td:nth-child(3),
	.nox_account_desktop_transfers tr th:nth-child(1),
	.nox_account_desktop_transfers tr td:nth-child(1),
	.nox_account_desktop_transfers tr th:nth-child(2),
	.nox_account_desktop_transfers tr td:nth-child(2),
	.nox_account_desktop_transfers tr th:nth-child(3),
	.nox_account_desktop_transfers tr td:nth-child(3),
	.nox_account_desktop_transfers tr th:nth-child(4),
	.nox_account_desktop_transfers tr td:nth-child(4),
	.nox_account_desktop_payment_purchase tr th:nth-child(1),
	.nox_account_desktop_payment_purchase tr td:nth-child(1),
	.nox_account_desktop_payment_payout tr th:nth-child(1),
	.nox_account_desktop_payment_payout tr td:nth-child(1) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_operations tr th:nth-child(2),
	.nox_account_desktop_operations tr td:nth-child(2),
	.nox_account_desktop_operations tr th:nth-child(4),
	.nox_account_desktop_operations tr td:nth-child(4) {
		width: 25%;
	}
	.nox_account_desktop_operations tr td:nth-child(2) a {
		border-bottom: 1px solid;
		text-decoration: none;
	}
	.nox_account_desktop_operations tr td.nox_account_desktop_moperations {
		display: none;
	}
	.nox_account_desktop_perks tr th:nth-child(n + 2),
	.nox_account_desktop_perks tr td:nth-child(n + 2) {
		width: 50%;
	}
	.nox_account_desktop_coupons tr th:nth-child(n + 4),
	.nox_account_desktop_coupons tr td:nth-child(n + 4) {
		width: 10%;
	}
	.nox_account_desktop_transfers tr th:nth-child(5),
	.nox_account_desktop_transfers tr td:nth-child(5),
	.nox_account_desktop_transfers tr th:nth-child(7),
	.nox_account_desktop_transfers tr td:nth-child(7),
	.nox_account_desktop_payment_purchase tr th:nth-child(2),
	.nox_account_desktop_payment_purchase tr td:nth-child(2),
	.nox_account_desktop_payment_purchase tr th:nth-child(3),
	.nox_account_desktop_payment_purchase tr td:nth-child(3),
	.nox_account_desktop_payment_purchase tr th:nth-child(5),
	.nox_account_desktop_payment_purchase tr td:nth-child(5),
	.nox_account_desktop_payment_payout tr th:nth-child(2),
	.nox_account_desktop_payment_payout tr td:nth-child(2),
	.nox_account_desktop_payment_payout tr th:nth-child(3),
	.nox_account_desktop_payment_payout tr td:nth-child(3),
	.nox_account_desktop_payment_payout tr th:nth-child(5),
	.nox_account_desktop_payment_payout tr td:nth-child(5) {
		position: relative;
		width: 14%;
	}
	.nox_account_desktop_transfers tr th:nth-child(8),
	.nox_account_desktop_transfers tr td:nth-child(8),
	.nox_account_desktop_payment_purchase tr th:nth-child(6),
	.nox_account_desktop_payment_purchase tr td:nth-child(6),
	.nox_account_desktop_payment_payout tr th:nth-child(6),
	.nox_account_desktop_payment_payout tr td:nth-child(6) {
		width: 16%;
	}
	.nox_account_desktop_payment_purchase tr td:nth-child(5) a {
		border-bottom: 1px solid;
		text-decoration: none;
	}
	.nox_account_desktop_payment_payout tr th:nth-child(4),
	.nox_account_desktop_payment_payout tr td:nth-child(4) {
		word-break: break-all;
		word-break: break-word;
	}
	.nox_account_desktop_payment_payout tr td:nth-child(4) a {
		color: #A5E5FF;
	}
	.nox_account_desktop_payment_payout tr td:nth-child(4) a,
	.nox_account_desktop_payment_payout tr td:nth-child(4) span.address {
		word-break: break-all;
		font-size: 11px;
	}
	.nox_account_desktop_invoices_by_sender_id tr th:nth-child(2),
	.nox_account_desktop_invoices_by_sender_id tr td:nth-child(2),
	.nox_account_desktop_invoices_by_sender_id tr th:nth-child(3),
	.nox_account_desktop_invoices_by_sender_id tr td:nth-child(3),
	.nox_account_desktop_invoices_by_sender_id tr th:nth-child(4),
	.nox_account_desktop_invoices_by_sender_id tr td:nth-child(4),
	.nox_account_desktop_invoices_by_sender_id tr th:nth-child(5),
	.nox_account_desktop_invoices_by_sender_id tr td:nth-child(5),
	.nox_account_desktop_invoices_by_sender_id tr th:nth-child(6),
	.nox_account_desktop_invoices_by_sender_id tr td:nth-child(6),
	.nox_account_desktop_invoices_by_sender_id tr th:nth-child(7),
	.nox_account_desktop_invoices_by_sender_id tr td:nth-child(7) {
		position: relative;
		width: 16.66666%;
	}
	.nox_account_desktop_invoices_by_recipient_id tr th:nth-child(2),
	.nox_account_desktop_invoices_by_recipient_id tr td:nth-child(2),
	.nox_account_desktop_invoices_by_recipient_id tr th:nth-child(3),
	.nox_account_desktop_invoices_by_recipient_id tr td:nth-child(3),
	.nox_account_desktop_invoices_by_recipient_id tr th:nth-child(4),
	.nox_account_desktop_invoices_by_recipient_id tr td:nth-child(4),
	.nox_account_desktop_invoices_by_recipient_id tr th:nth-child(5),
	.nox_account_desktop_invoices_by_recipient_id tr td:nth-child(5),
	.nox_account_desktop_invoices_by_recipient_id tr th:nth-child(6),
	.nox_account_desktop_invoices_by_recipient_id tr td:nth-child(6) {
		position: relative;
		width: 20%;
	}

	@media (max-width: 1200px) {
		.nox_account_desktop_operations.moperations tr:last-child {
			border: none;
		}
		.nox_account_desktop_operations.moperations tr:first-child td.nox_account_desktop_moperations {
			padding: 0 0 15px 0;
		}
		.nox_account_desktop_operations.moperations tr:last-child td.nox_account_desktop_moperations {
			padding: 5px 0 0 0;
		}
		.nox_account_desktop_operations.moperations tr td {
			display: none;
		}
		.nox_account_desktop_operations.moperations tr td.nox_account_desktop_moperations {
			padding: 5px 0 15px 0;
			border: none;
			display: block;
		}
		.nox_account_desktop_operations.moperations tr td .nox_account_desktop_moperations_row {
			position: relative;
			margin: 0 0 5px 0;
			text-align: left;
			display: table;
			width: 100%;
		}
		.nox_account_desktop_operations.moperations tr td .nox_account_desktop_moperations_row:last-child {
			margin: 0;
		}
		.nox_account_desktop_operations.moperations tr td .nox_account_desktop_moperations_row.title div:last-child {
			position: absolute;
			top: 0;
			right: 0;
			font-family: 'OpenSansSemiBold', Arial, sans-serif;
		}
	}
	@media (max-width: 1150px) {
		.nox_account_desktop_balance.is_bonus_9 {
			padding: 0;
		}
		.nox_account_desktop_balance_widget {
			position: relative;
			left: 0 !important;
			margin: 0 0 15px 0;
			width: 100%;
			height: 200px;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_desktop_balance_widget {
			margin: 0 0 10px 0;
			height: 180px;
		}
	}
</style>
