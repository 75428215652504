<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Список web-серверов</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_server_settings type_0">
						<table class="nox_table" v-if="$store.state.noxServerSettings.length">
							<thead>
								<tr>
									<th>Номер</th>
									<th>Адрес мастера</th>
									<th>Адрес слейва</th>
									<th>Боты</th>
									<th>Описание</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
									<th><font-awesome-icon :icon="['fas', 'times']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxServerSettings" :key="index" v-show="value.type == 0">
									<td data-label="Номер:">{{ value.server_id }}</td>
									<td data-label="Адрес мастера:" :class="[value.master_status ? 'available' : 'unavailable']">{{ value.master_protocol ? value.master_protocol + '://' : '' }}{{ value.master_address }}:{{ value.master_port }}</td>
									<td data-label="Адрес слейва:">{{ (value.slave_protocol ? (value.slave_protocol + '://') : '') }}{{ value.slave_address }}:{{ value.slave_port }}</td>
									<td data-label="Боты:">{{ value.count_bots }} ({{ value.count_bots_active }})</td>
									<td data-label="Описание:">{{ value.description }}</td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalServerSettings', { type: 'editServer', index: index, id: value.id, serverType: value.type })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
									<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalServerSettings', { type: 'deleteServer', index: index, id: value.id, serverType: value.type })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalServerSettings', { type: 'addServer', serverType: 0 })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить сервер</button>
				</div>
			</div>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Список серверов ретрансляторов</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_server_settings type_1">
						<table class="nox_table" v-if="$store.state.noxServerSettings.length">
							<thead>
								<tr>
									<th>Номер</th>
									<th>Адрес ретранслятора</th>
									<th>Описание</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
									<th><font-awesome-icon :icon="['fas', 'times']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in $store.state.noxServerSettings" :key="index" v-show="value.type == 1">
									<td data-label="Номер:">{{ value.server_id }}</td>
									<td data-label="Адрес ретранслятора:" :class="[value.relay_status ? 'available' : 'unavailable']">{{ (value.relay_protocol ? (value.relay_protocol + '://') : '') }}{{ value.relay_address }}:{{ value.relay_port }}</td>
									<td data-label="Описание:">{{ value.description }}</td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalServerSettings', { type: 'editServer', index: index, id: value.id, serverType: value.type })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
									<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalServerSettings', { type: 'deleteServer', index: index, id: value.id, serverType: value.type })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalServerSettings', { type: 'addServer', serverType: 1 })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить сервер</button>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalServerSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios();
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/admin/account/bots/servers',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.noxIsLoading = true;
					_this.$store.state.noxIsAccount = true;

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.$parent.goToTop(true);
							_this.$parent.$parent.checkAppVersion(data.headers);
							_this.$store.state.noxServerSettings = data.data;
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalServerSettings: () => import('@/views/modal_components/NoxModalServerSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_server_settings.type_0 tr th,
	.nox_account_desktop_server_settings.type_0 tr td {
		width: 20%;
	}
	.nox_account_desktop_server_settings.type_1 tr th,
	.nox_account_desktop_server_settings.type_1 tr td {
		width: 33.33333%;
	}
</style>
