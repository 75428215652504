<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">NOX Splash уведомления</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_splash">
						<table class="nox_table" v-if="noxSplashData.length">
							<thead>
								<tr>
									<th>№</th>
									<th>Точка отсчета</th>
									<th>Время отсчета</th>
									<th>Текст (RU)</th>
									<th>Текст (EN)</th>
									<th><font-awesome-icon :icon="['fas', 'star-half-alt']" /></th>
									<th>Статус</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
									<th><font-awesome-icon :icon="['fas', 'times']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxSplashData" :key="index">
									<td data-label="№:">{{ value.id }}</td>
									<td data-label="Точка отсчета:">{{ noxSplashTypes[value.type - 1].label }}</td>
									<td data-label="Время отсчета:">{{ value.time }} <span v-html="$store.getters.getLanguageText('1.9', 4, { options: [$parent.$parent.getDeclension(value.time)] })"></span></td>
									<td data-label="Текст (RU):" v-html="value.text_ru"></td>
									<td data-label="Текст (EN):" v-html="value.text_en"></td>
									<td data-label="Приоритет:">{{ value.priority }}</td>
									<td data-label="Статус:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.is_active)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActive(value.id, index, $event)"></toggle-button></td>
									<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalSplash', { type: 'editSplash', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
									<td data-label="Удалить:"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalSplash', { type: 'deleteSplash', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
					<button type="button" class="nox_button big" @click="axios('getSplashData')" v-if="noxSplashDataLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalSplash', { type: 'addSplash' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить уведомление</button>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalSplash"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxSplashData: [],
			noxSplashDataLimitOffset: 0,
			noxSplashDataLimitCount: 20,
			noxSplashTypes: [
				{ code: 1, label: 'Дата регистрации' },
				{ code: 2, label: 'Дата покупки пакета' }
			],
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1 || this.$store.state.noxAccountData.id == 355) {
					this.axios('getSplashData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getSplashData') {
						if (!_this.noxSplashDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPortletLoading = true;
						config.url = '/v2/admin/account/splash';
						config.params = { limitOffset: _this.noxSplashDataLimitOffset, limitCount: _this.noxSplashDataLimitCount };
					}
					else if (type == 'editSplashIsActive') {
						_this.noxSplashData[var2].is_active = var3;
						config.url = '/v2/admin/account/splash/is_active';
						config.data = { id: var1, is_active: var3 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getSplashData') {
								_this.$parent.$parent.goToTop(!_this.noxSplashDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.data.length) {
									for (var i in data.data.data) {
										_this.noxSplashData.push(data.data.data[i]);
									}
								}
								_this.noxSplashDataLimitOffset = data.data.count > _this.noxSplashDataLimitOffset + _this.noxSplashDataLimitCount ? _this.noxSplashDataLimitOffset + _this.noxSplashDataLimitCount : 0;
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForIsActive: function(id, index, event) {
				this.axios('editSplashIsActive', id, index, Number(event.value));
			}
		},
		components: {
			NoxModalSplash: () => import('@/views/modal_components/NoxModalSplash.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_splash tr th:nth-child(1),
	.nox_account_desktop_splash tr td:nth-child(1),
	.nox_account_desktop_splash tr th:nth-child(6),
	.nox_account_desktop_splash tr td:nth-child(6),
	.nox_account_desktop_splash tr th:nth-child(7),
	.nox_account_desktop_splash tr td:nth-child(7),
	.nox_account_desktop_splash tr th:nth-child(8),
	.nox_account_desktop_splash tr td:nth-child(8),
	.nox_account_desktop_splash tr th:nth-child(9),
	.nox_account_desktop_splash tr td:nth-child(9) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_splash tr th:nth-child(4),
	.nox_account_desktop_splash tr td:nth-child(4),
	.nox_account_desktop_splash tr th:nth-child(5),
	.nox_account_desktop_splash tr td:nth-child(5) {
		width: 30%;
	}
	.nox_account_desktop_splash tr td .splash_type_1 img {
		position: relative;
		left: 50%;
		margin: 15px 0 15px -50px;
		display: block;
		width: 100px;
	}
	.nox_account_desktop_splash tr td .splash_type_2 img {
		width: 100px;
	}

	@media (max-width: 1000px) {
		.nox_account_desktop_splash tr td .splash_type_1 img {
			float: left;
			left: auto;
			margin: 0;
		}
	}
</style>
