<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet" v-for="bonus in 9" :key="bonus">
				<div class="nox_account_desktop_portlet_title">
					<span v-if="bonus == 1">#1. Командный бонус</span>
					<span v-else-if="bonus == 2">#2. Бонус за продажу</span>
					<span v-else-if="bonus == 3">#3. Кураторский бонус</span>
					<span v-else-if="bonus == 4">#4. Бустер бонус</span>
					<span v-else-if="bonus == 5">#5. Лидерский бонус</span>
					<span v-else-if="bonus == 6">#6. Подарки</span>
					<span v-else-if="bonus == 7">#7. Управленческий бонус</span>
					<span v-else-if="bonus == 8">#8. Бонус за диверсификацию</span>
					<span v-else-if="bonus == 9">#9. Perk бонус</span>
				</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_bonus_settings">
						<table class="nox_table">
							<thead>
								<tr>
									<th>№</th>
									<th>Номер группы</th>
									<th>Название группы</th>
									<th>Вкл / Выкл</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxSystemGroups" :key="index">
									<td data-label="№:">{{ (index + 1) }}</td>
									<td data-label="Номер группы:">{{ index }}</td>
									<td data-label="Название группы:">{{ value['name_' + $store.state.noxLanguage] }}</td>
									<td data-label="Вкл / Выкл:"><toggle-button class="nox_toggle_button" :value="bonusActive(bonus, value.group)" :width="42" :height="26" :color="{ checked: '#00FF0080', unchecked: '#FF6666CC' }" @change="bonusToggle(bonus, value.group, $event)"></toggle-button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons" v-if="[4,6,9].includes(bonus)">
					<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalBonusSettings', { type: 'sendBonusAlert', bonus: bonus })" v-if="[4,9].includes(bonus)"><font-awesome-icon :icon="['fab', 'telegram']" /><span v-html="$store.getters.getLanguageText('1.1', 93)"></span></button>
					<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalBonusSettings', { type: 'editBonusSettings', bonus: bonus })" v-else-if="[6].includes(bonus)"><font-awesome-icon :icon="['fas', 'tools']" /><span v-html="$store.getters.getLanguageText('1.1', 157)"></span></button>
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalBonusSettings', { type: 'restartBonus', bonus: bonus })"><font-awesome-icon :icon="['fas', 'sync-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 70)"></span></button>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalBonusSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxSystemSettings: {},
			noxSystemGroups: {},
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getSystemSettings');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type, var1, var2) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getSystemSettings') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/admin/account/settings';
					}
					else if (type == 'getSystemGroups') {
						config.url = '/v2/admin/account/groups';
					}
					else if (type == 'editBonusActive') {
						config.url = '/v2/admin/account/settings/value';
						config.data = { key: var1, value: var2 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getSystemSettings') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								for (var i in data.data) {
									_this.noxSystemSettings[data.data[i].key] = data.data[i].value;
								}
							}
							else if (type == 'getSystemGroups') {
								_this.noxSystemGroups = data.data;
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						if (type == 'getSystemSettings') {
							_this.axios('getSystemGroups');
						}
						else if (type == 'getSystemGroups') {
							_this.noxIsLoading = false;
							_this.$store.state.noxIsAccount = false;
							_this.$store.state.noxIsLoading = false;
						}
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			bonusActive: function(bonus, group) {
				return this.noxSystemSettings['is_active_bonus_' + bonus + '_for_group_' + group] == 1 ? true : false;
			},
			bonusToggle: function(bonus, group, event) {
				this.axios('editBonusActive', 'is_active_bonus_' + bonus + '_for_group_' + group, Number(event.value));
			}
		},
		components: {
			NoxModalBonusSettings: () => import('@/views/modal_components/NoxModalBonusSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_bonus_settings tr th:nth-child(n + 2),
	.nox_account_desktop_bonus_settings tr td:nth-child(n + 2) {
		width: 33.33333%;
	}
</style>
