<template>
	<div class="nox_editor_website">
		<div class="nox_editor_website_loading" :class="{ hide: !noxIsLoading }"><div class="nox_loading"></div></div>
		<div class="nox_editor_website_content" :class="{ hide: noxIsLoading }"></div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsEditor) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getEditorWebsiteData');
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getEditorWebsiteData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsEditor = true;
						config.url = '/v2/editor/website';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getEditorWebsiteData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsEditor = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<!--script>
	import axios from 'axios'
	import { Sortable, MultiDrag, Swap } from 'sortablejs'
	export default {
		data: () => ({
			noxEditorWebsite: '',
			noxEditorWebsiteStyle: '',
			noxEditorWebsiteContent: '',
			noxEditorWebsiteCssRules: '',
			noxEditorWebsiteSortable: '',
			noxIsLoading: true
		}),
		methods: {
			initEditorWebsite: function() {

				var _this = this;
				setTimeout(function() {

					_this.noxEditorWebsite = document.createElement('iframe');
					_this.noxEditorWebsite.setAttribute('allowfullscreen', 'allowfullscreen');
					document.getElementsByClassName('nox_editor_desktop')[0].append(_this.noxEditorWebsite);

					_this.noxEditorWebsiteStyle = '<style>* { box-sizing: border-box; } html, body, .nox_website { min-height: 100%; } body { margin: 0; background: #FFFFFF; height: 100%; } .nox_website { overflow: auto; overflow-x: hidden; } .nox_editor *[data-nox-editor] { outline: 1px dashed #AAAAAAB3; outline-offset: -2px; }</style>';
					_this.noxEditorWebsiteContent =
					'<div class="nox_website" data-nox-editor="1">' +
						'<div class="nox_website_row" data-nox-editor="1">' +
							'<div class="nox_website_col" data-nox-editor="1">11</div>' +
							'<div class="nox_website_col" data-nox-editor="1">12</div>' +
							'<div class="nox_website_col" data-nox-editor="1">13</div>' +
						'</div>' +
						'<div class="nox_website_row" data-nox-editor="1">' +
							'<div class="nox_website_col" data-nox-editor="1">21</div>' +
							'<div class="nox_website_col" data-nox-editor="1">22</div>' +
							'<div class="nox_website_col" data-nox-editor="1">23</div>' +
						'</div>' +
						'<div class="nox_website_row" data-nox-editor="1">' +
							'<div class="nox_website_col" data-nox-editor="1">31</div>' +
							'<div class="nox_website_col" data-nox-editor="1">32</div>' +
							'<div class="nox_website_col" data-nox-editor="1">33</div>' +
						'</div>' +
					'</div>';
					_this.noxEditorWebsiteCssRules =
					'<div class="nox_editor_css_rules">' +
						'<style>.nox_website_row { padding: 10px; display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: nowrap; } .nox_website_col { flex-grow: 1; flex-basis: 100%; min-height: 80px; } .nox_website_swap { background: #F0F8FF; }</style>' +
					'</div>';

					_this.noxEditorWebsite.contentWindow.document.body.classList.add('nox_editor');
					_this.noxEditorWebsite.contentWindow.document.body.innerHTML = _this.noxEditorWebsiteStyle + _this.noxEditorWebsiteContent + _this.noxEditorWebsiteCssRules;
					_this.noxEditorWebsiteSortable = _this.noxEditorWebsite.contentWindow.document.getElementsByClassName('nox_website')[0];

					Sortable.mount(new MultiDrag(), new Swap());
					Sortable.create(document.getElementsByClassName('nox_editor_sidebar_tools_row')[0], {
						group: { name: 'shared', pull: 'clone', revertClone: false }, sort: false
					});
					Sortable.create(_this.noxEditorWebsiteSortable, {
						group: 'shared',
						swap: true,
						swapClass: 'nox_website_swap',
						animation: 150
					});

				}, 0);
			}
		}
	}
</script-->
