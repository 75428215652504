<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_loading" v-if="noxIsLoading"></div>
		<div class="nox_auth registration_confirm" v-else>
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.2', 0)"></div>
					<div class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_text" v-html="noxAlert"></div>
						<div class="nox_auth_block_content_form_footer">
							<router-link to="/">[<span v-html="$store.getters.getLanguageText('1.1', 31)"></span>]</router-link>
						</div>
					</div>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			isReady: function() {
				this.axios('registrationConfirm');
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 28); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 29); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (_this.$store.state.noxOAuthTokenForRegistration && _this.$route.query.key !== undefined) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxOAuthTokenForRegistration }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'registrationConfirm') {
						config.url = '/v2/oauth/registration/confirm';
						config.data = { key: _this.$route.query.key };
						config.method = 'patch';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'registrationConfirm') {
									_this.noxAlert = '<b>' + _this.$store.getters.getLanguageText('1.3', 1) + '</b> ' + _this.$store.getters.getLanguageText('1.3.1', 33);
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'registrationConfirm') {
										if (data.response.data.error == 'KEY_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									}
								}
								else if (data.response.status == 404) {
									if (type == 'registrationConfirm') {
										if (data.response.data.error == 'KEY_NOT_FOUND') { _this.noxAlert = _this.getError(2); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.noxAlert = _this.getError(1);
				}
			}
		},
		components: {
			NoxAuthHeader,
			NoxAuthFooter
		},
		created: function() {
			this.isReady();
		}
	}
</script>
