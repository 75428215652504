<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_top_partners">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.14', 0)"></div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_portlet_content_img_200">
							<img src="@/assets/images/bot_img_3.png">
							<div v-html="$store.getters.getLanguageText('3.14', 1)"></div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_buttons" v-if="$store.state.noxAccountData.group == 1">
						<button type="button" class="nox_button big" @click="$modal.show('NoxModalTopPartners', { type: 'editSprintSettings' })"><font-awesome-icon :icon="['fas', 'tools']" /><span v-html="$store.getters.getLanguageText('1.1', 116)"></span></button>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_navigation">
					<div class="nox_account_desktop_page_content_navigation_filters">
						<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
						<div class="nox_account_desktop_page_content_navigation_filters_value col3" :class="{ active: noxTopPartnersDataFilterType == 0 }"><div @click="getTopPartnersDataByFilter(0, 0, 0)" v-html="$store.getters.getLanguageText('1.8.1', 0)"></div></div>
						<div class="nox_account_desktop_page_content_navigation_filters_value col3" :class="{ active: noxTopPartnersDataFilterType == 2 }"><div @click="getTopPartnersDataByFilter(2, 0, 0)" v-html="$store.getters.getLanguageText('1.8.1', 2)"></div></div>
						<div class="nox_account_desktop_page_content_navigation_filters_value col3" :class="{ active: noxTopPartnersDataFilterType == 1 }"><div @click="getTopPartnersDataByFilter(1, 1, 0)" v-html="$store.getters.getLanguageText('1.8.1', 1)"></div></div>
					</div>
				</div>
				<div class="nox_account_desktop_page_content_navigation extra" v-if="noxTopPartnersDataFilterType == 1">
					<div class="nox_account_desktop_page_content_navigation_filters">
						<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 10)"></div>
						<div class="nox_account_desktop_page_content_navigation_filters_block">
							<div class="nox_account_desktop_page_content_navigation_filters_select last"><v-select class="nox_select style-chooser" v-model.trim="noxTopPartnersDataFilterSType" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxSelectOptions" @input="getTopPartnersSTypeByFilter"></v-select></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_top_partners_data" v-if="noxTopPartnersData.length">
					<div class="nox_account_desktop_top_partners_data_item" :class="{ rating: noxTopPartnersDataFilterType == 2 }" v-for="(value, index) in noxTopPartnersData" :key="index">
						<div class="nox_account_desktop_top_partners_data_item_number">{{ (index + 1) }}.</div>
						<div class="nox_account_desktop_top_partners_data_item_avatar">
							<div class="nox_account_desktop_top_partners_data_item_avatar_img"><img :src="$parent.$parent.getAvatarByLink(value.avatar)"></div>
							<div class="nox_account_desktop_top_partners_data_item_avatar_rating" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 150), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="noxTopPartnersDataFilterType == 2"><div class="nox_account_desktop_top_partners_data_item_avatar_rating_block"><div><span :style="{ width: (value.rating * 100 / $store.state.noxSystemSettings.max_rating) + '%' }"></span></div></div></div>
							<div class="nox_account_desktop_top_partners_data_item_avatar_status" :style="{ background: '#' + $store.state.noxSystemSettings['user_status_color_' + value.status] }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 117, { status: $store.getters.getLanguageText('4.3.1', value.status), count: $store.state.noxSystemSettings['user_status_cycles_' + value.status] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.status">{{ $store.state.noxSystemSettings['user_status_name_' + value.status] }}</div>
						</div>
						<div class="nox_account_desktop_top_partners_data_item_content">
							<span><b>NOX ID</b>: {{ value.user_id }},</span>
							<span v-if="value.username"><b>{{ value.username }}</b></span>
							<span v-if="value.package">[{{ $store.state.noxSystemSettings['package_name_x' + value.package] }}]</span>
							<span v-if="value.status">({{ $store.getters.getLanguageText('4.3.1', value.status) }})</span>
							<span v-if="value.amount && $store.state.noxAccountData.group == 1">[${{ value.amount.toFixed(2) }}]</span>
						</div>
					</div>
					<div v-if="noxTopPartnersDataLimitOffset && noxTopPartnersDataFilterType !== 2">
						<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
							<button type="button" class="nox_button big" @click="axios('getTopPartnersData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_top_partners_data" v-else>
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.14', 2)"></div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalTopPartners"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxSelectOptions: [],
			noxSprintSettings: [],
			noxTopPartnersData: [],
			noxTopPartnersDataFilterType: 0,
			noxTopPartnersDataFilterSType: '',
			noxTopPartnersDataFilterSort: 0,
			noxTopPartnersDataLimitOffset: 0,
			noxTopPartnersDataLimitCount: 20,
			noxTopPartnersDataLimitIndex: -1,
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$parent.$parent.checkAccessToNoxBusiness()) {
					this.noxSelectOptions = this.$parent.$parent.getSelectOptions(12);
					this.noxTopPartnersDataFilterSType = this.noxSelectOptions[1];
					this.axios('getTopPartnersData');
				}
				else {
					this.$router.push({ path: '/account/tariffs' });
				}
			},
			initSelectOptions: function() {
				this.noxSelectOptions = this.$parent.$parent.getSelectOptions(12);
				if (this.noxSprintSettings.status) {
					this.noxSelectOptions.push({ code: 5, label: this.$store.getters.getLanguageText('1.5.8', 5, { start: this.$parent.$parent.getSprintFormat(this.noxSprintSettings.sprint_start), finish: this.$parent.$parent.getSprintFormat(this.noxSprintSettings.sprint_finish) }) });
				}
			},
			getTopPartnersDataByFilter: function(type, stype, sort) {
				this.$parent.$parent.goToTop(true);
				this.noxSprintSettings = [];
				this.noxTopPartnersData = [];
				this.noxTopPartnersDataFilterType = type;
				this.noxTopPartnersDataFilterSType = this.noxSelectOptions[stype];
				this.noxTopPartnersDataFilterSort = sort;
				this.noxTopPartnersDataLimitOffset = 0;
				this.axios('getTopPartnersData');
			},
			getTopPartnersSTypeByFilter: function(event) {
				if (event !== null && event.code !== null) {
					this.getTopPartnersDataByFilter(1, Number(event.code), 0);
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getTopPartnersData') {
						if (!_this.noxTopPartnersDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPageContentLoading = true;
						config.url = '/v2' + (_this.$store.state.noxAccountData.group == 1 ? '/admin' : '') + '/account/top/partners';
						config.params = { limitOffset: _this.noxTopPartnersDataLimitOffset, limitCount: _this.noxTopPartnersDataLimitCount, filterType: _this.noxTopPartnersDataFilterType, filterSType: _this.noxTopPartnersDataFilterSType.code, filterSort: _this.noxTopPartnersDataFilterSort };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getTopPartnersData') {
								_this.$parent.$parent.goToTop(!_this.noxTopPartnersDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.system_settings !== undefined) {
									_this.$parent.$parent.initSystemSettings(data.data.system_settings);
								}
								if (data.data.sprint_settings !== undefined) {
									_this.noxSprintSettings = data.data.sprint_settings;
									_this.initSelectOptions();
								}
								if (data.data.top_partners_data !== undefined) {
									if (data.data.top_partners_data.data.length) {
										for (var i in data.data.top_partners_data.data) {
											if (_this.noxTopPartnersDataLimitOffset && (_this.noxTopPartnersDataLimitIndex = _this.noxTopPartnersData.findIndex(a => a.user_id == data.data.top_partners_data.data[i].user_id)) >= 0) {
												_this.noxTopPartnersData.splice(_this.noxTopPartnersDataLimitIndex, 1);
											}
											_this.noxTopPartnersData.push(data.data.top_partners_data.data[i]);
										}
									}
									_this.noxTopPartnersDataLimitOffset = data.data.top_partners_data.count > _this.noxTopPartnersDataLimitOffset + _this.noxTopPartnersDataLimitCount ? _this.noxTopPartnersDataLimitOffset + _this.noxTopPartnersDataLimitCount : 0;
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPageContentLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalTopPartners: () => import('@/views/modal_components/NoxModalTopPartners.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_top_partners_data_item {
		position: relative;
		margin: 10px 0;
		padding: 15px 15px 15px 120px;
		border-radius: 15px;
		background: #333333B3;
		color: #FFFFFF;
		align-items: center;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		min-height: 55px;
	}
	.nox_account_desktop_top_partners_data_item.rating {
		margin: 10px 0 15px 0;
	}
	.nox_account_desktop_top_partners_data_item_number {
		position: absolute;
		top: 50%;
		left: 15px;
		margin: -10px 0 0 0;
		text-align: center;
		line-height: 20px;
		width: 20px;
		height: 20px;
	}
	.nox_account_desktop_top_partners_data_item_avatar {
		position: absolute;
		top: 50%;
		left: 45px;
		margin: -30px 0 0 0;
	}
	.nox_account_desktop_top_partners_data_item_avatar_img {
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #6F6F6F;
		overflow: hidden;
		width: 60px;
		height: 60px;
	}
	.nox_account_desktop_top_partners_data_item_avatar_rating {
		position: absolute;
		left: 50%;
		margin: -13px 0 0 -40px;
		padding: 2px 0;
		border: 1px solid #FFFFFF;
		border-radius: 10px;
		background: #6F6F6F;
		text-align: center;
		width: 80px;
		height: 20px;
		z-index: 2;
	}
	.nox_account_desktop_top_partners_data_item_avatar_rating_block {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 14px;
	}
	.nox_account_desktop_top_partners_data_item_avatar_rating_block div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url('../../assets/images/stars.png');
		background-size: 12px 27.84px;
		width: 100%;
		height: 100%;
	}
	.nox_account_desktop_top_partners_data_item_avatar_rating_block div span {
		background: inherit;
		background-position: 0 -14px;
		display: block;
		width: 0;
		height: 100%;
	}
	.nox_account_desktop_top_partners_data_item_avatar_status {
		position: absolute;
		top: 50%;
		left: 0;
		margin: -30px 0 0 38px;
		padding: 0 5px;
		border: 1px solid #FFFFFF;
		border-radius: 11px;
		background: #B8C3CA;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 20px;
		min-width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_account_desktop_top_partners_data_item_content span {
		margin: 0 8px 0 0;
	}

	@media (max-width: 1000px) {
		.nox_account_desktop_top_partners_data_item_content span {
			margin: 0 5px 0 0;
		}
	}
	@media (max-width: 700px) {
		.nox_account_desktop_top_partners_data_item {
			font-size: 11px;
		}
	}
</style>
