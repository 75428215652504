<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_servers">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.11', 0)"></div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_portlet_content_img_200">
							<img src="@/assets/images/bot_img_2.png">
							<div v-html="$store.getters.getLanguageText('3.11', 1)"></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_servers_data" v-if="noxServersActiveCount || noxServersDeletedCount">
					<div class="nox_account_desktop_page_content_title" v-html="$store.getters.getLanguageText('3.11', 2)"></div>
					<div class="nox_account_desktop_page_content_navigation">
						<div class="nox_account_desktop_page_content_navigation_filters">
							<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
							<div class="nox_account_desktop_page_content_navigation_filters_block col2">
								<div class="nox_account_desktop_page_content_navigation_filters_select"><v-select class="nox_select style-chooser" v-model.trim="noxServersFilter1" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxServersFilter1Options" @input="getServersDataByFilter($event, 1)"></v-select></div>
							</div>
							<div class="nox_account_desktop_page_content_navigation_filters_block col2">
								<div class="nox_account_desktop_page_content_navigation_filters_select last"><v-select class="nox_select style-chooser" v-model.trim="noxServersFilter2" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxServersFilter2Options" @input="getServersDataByFilter($event, 2)"></v-select></div>
							</div>
						</div>
					</div>
					<div class="nox_tabs">
						<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
						<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)" v-html="$store.getters.getLanguageText('3.11', 5)"></label>
						<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
						<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)" v-html="$store.getters.getLanguageText('3.11', 6)"></label>
						<div class="nox_tab_block" id="nox_tab_block_1">
							<div class="nox_account_desktop_servers_active" v-if="noxServersData.length">
								<div class="nox_account_desktop_portlet_wrapper" v-for="(value, index) in noxServersData" :key="index">
									<div class="nox_account_desktop_portlet" :class="{ violet: value.is_nox_demo }">
										<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.11', 7, { id: value.server_id, version: value.version })"></div>
										<div class="nox_account_desktop_portlet_content">
											<div class="nox_account_desktop_servers_data_row">
												<div class="nox_account_desktop_servers_data_col">
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 8)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 57), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<span class="margin_right_10">{{ value.server_id }}</span>
															<button type="button" class="nox_button nox_is_icon green" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [5] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$parent.$parent.saveAsFile(('noxbox_' + value.server_id + '.cmd'), ('text/plain'), ('cmdkey /delete:TERMSRV/' + value.server_hostname + '\r\ncmdkey /generic:TERMSRV/' + value.server_hostname + ' /user:' + value.server_username + ' /pass:' + value.server_password + '\r\nmstsc /v:' + value.server_hostname + ':' + value.server_port + '\r\ncmdkey /delete:TERMSRV/' + value.server_hostname))"><font-awesome-icon :icon="['fab', 'windows']" /></button>
														</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 9)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 58), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<span class="margin_right_10">{{ (value.version == 2 && value.server_hostname ? value.server_hostname : value.server_ip) }}:{{ value.server_port }}</span>
															<button type="button" class="nox_button nox_is_icon orange" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [0] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$parent.$parent.clipboard((value.version == 2 && value.server_hostname ? value.server_hostname : value.server_ip) + ':' + value.server_port)"><font-awesome-icon :icon="['fas', 'copy']" /></button>
															<button type="button" class="nox_button nox_is_icon blue" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [1] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$modal.show('NoxModalServers', { type: 'refreshServer', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'sync-alt']" /></button>
														</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 10)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 59), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<span class="margin_right_10">{{ value.server_username }}</span>
															<button type="button" class="nox_button nox_is_icon orange" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [0] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$parent.$parent.clipboard(value.server_username)"><font-awesome-icon :icon="['fas', 'copy']" /></button>
														</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 11)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 60), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<span class="margin_right_10">
																<span v-if="noxIsServerPasswords[value.id] == 1">{{ value.server_password }}</span>
																<span v-else>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</span>
															</span>
															<button type="button" class="nox_button nox_is_icon orange" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [0] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$parent.$parent.clipboard(value.server_password)"><font-awesome-icon :icon="['fas', 'copy']" /></button>
															<button type="button" class="nox_button nox_is_icon" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [(noxIsServerPasswords[value.id] == 1 ? 4 : 3)] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$set(noxIsServerPasswords, value.id, (noxIsServerPasswords[value.id] == 1 ? 0 : 1))"><font-awesome-icon :icon="['fas', (noxIsServerPasswords[value.id] == 1 ? 'eye-slash' : 'eye')]" /></button>
															<button type="button" class="nox_button nox_is_icon blue" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 162, { options: [2] }), trigger: $store.state.noxIsMobile ? '' : 'hover' }" @click="$modal.show('NoxModalServers', { type: 'editServerPassword', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														</div>
													</div>
												</div>
												<div class="nox_account_desktop_servers_data_col">
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 12)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 61), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">{{ new Date(value.server_start * 1000).toLocaleString($store.state.noxLanguage) }}</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 13)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 62), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">{{ new Date(value.server_finish * 1000).toLocaleString($store.state.noxLanguage) }}</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 15)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 64), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<span class="margin_right_10" :class="{ red: ((value.server_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)) < 14 }" v-if="value.server_finish > $parent.$parent.getTimeSeconds()">
																<span v-if="((value.server_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)) > 1" v-html="$store.getters.getLanguageText('1.7.2', 15, { count: Math.floor((value.server_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)), options: [$parent.$parent.getDeclension(Math.floor((value.server_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)))] })"></span>
																<span v-else-if="((value.server_finish - $parent.$parent.getTimeSeconds()) / (60 * 60)) > 1" v-html="$store.getters.getLanguageText('1.7.2', 16, { count: Math.floor((value.server_finish - $parent.$parent.getTimeSeconds()) / (60 * 60)), options: [$parent.$parent.getDeclension(Math.floor((value.server_finish - $parent.$parent.getTimeSeconds()) / (60 * 60)))] })"></span>
																<span v-else v-html="$store.getters.getLanguageText('1.7.2', 17)"></span>
															</span>
															<span class="margin_right_10 red" v-else v-html="$store.getters.getLanguageText('1.7.2', 15, { count: Math.floor((value.server_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)), options: [$parent.$parent.getDeclension(Math.floor((value.server_finish - $parent.$parent.getTimeSeconds()) / (24 * 60 * 60)))] })"></span>
															<button type="button" class="nox_button nox_is_desktop blue" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalServers' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? (value.version == 1 ? 'extendServerV1' : 'extendServerV2') : 'addMainBalance'), index: index, id: value.id })" v-if="!(value.is_nox_demo && value.service_numbers > 1) || [1,2,10,40,41,42,90,100,109,522].includes($store.state.noxAccountData.id)" v-html="$store.getters.getLanguageText('1.1', 36)"></button>
															<button type="button" class="nox_button nox_is_mobile blue" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalServers' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? (value.version == 1 ? 'extendServerV1' : 'extendServerV2') : 'addMainBalance'), index: index, id: value.id })" v-if="!(value.is_nox_demo && value.service_numbers > 1) || [1,2,10,40,41,42,90,100,109,522].includes($store.state.noxAccountData.id)"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														</div>
													</div>
													<div class="nox_account_desktop_servers_data_block" v-if="(value.version == 2 && !(value.is_nox_demo && value.service_numbers > 1)) || [1,2,10,40,41,42,90,100,109,522].includes($store.state.noxAccountData.id)">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 16)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 65), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<toggle-button class="nox_toggle_button" :value="toggleValue(value.is_extend)" :sync="true" :width="38" :height="23" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, index, value.id)"></toggle-button>
														</div>
													</div>
												</div>
												<div class="nox_account_desktop_servers_data_col">
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 17)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 66), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<span class="margin_right_10" v-if="value.comment" v-html="String(value.comment).replace(/\n/g, '<br>')"></span>
															<button type="button" class="nox_button nox_is_desktop blue" @click="$modal.show('NoxModalServers', { type: 'editServerComment', index: index, id: value.id })" v-html="$store.getters.getLanguageText('1.1', (value.comment ? 6 : 5))"></button>
															<button type="button" class="nox_button nox_is_mobile blue" @click="$modal.show('NoxModalServers', { type: 'editServerComment', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_portlet_buttons">
											<button type="button" class="nox_button big orange" @click="$modal.show('NoxModalServers', { type: 'upgradeServer', index: index, id: value.id })" v-if="value.version == 1"><font-awesome-icon :icon="['fas', 'arrow-up']" /><span v-html="$store.getters.getLanguageText('1.1', 128)"></span></button>
											<button type="button" class="nox_button big white" @click="$modal.show('NoxModalServers', { type: 'restartServer', index: index, id: value.id })" v-if="value.version == 2"><font-awesome-icon :icon="['fas', 'redo-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 138)"></span></button>
											<button type="button" class="nox_button big orange" @click="$modal.show('NoxModalServers', { type: 'recreateServer', index: index, id: value.id })" v-if="value.version == 2"><font-awesome-icon :icon="['fas', 'recycle']" /><span v-html="$store.getters.getLanguageText('1.1', 139)"></span></button>
											<!--button type="button" class="nox_button big orange" @click="$modal.show('NoxModalServers', { type: 'reinstallServer', index: index, id: value.id })" v-if="value.version == 2"><font-awesome-icon :icon="['fas', 'recycle']" /><span v-html="$store.getters.getLanguageText('1.1', 139)"></span></button-->
											<button type="button" class="nox_button big red" @click="$modal.show('NoxModalServers', { type: 'deleteServer', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'trash']" /><span v-html="$store.getters.getLanguageText('1.1', 86)"></span></button>
										</div>
									</div>
									<div class="nox_account_desktop_portlet_accordion" :class="{ violet: value.is_nox_demo }" v-if="value.version == 2">
										<div class="nox_account_desktop_portlet_accordion_loading" :class="{ hide: !noxIsServerAccordionLoadings[index] }"><img src="@/assets/images/loading.gif"></div>
										<div class="nox_account_desktop_portlet_accordion_button" :class="{ hide: noxIsServerAccordionLoadings[index] }" @click="axios('getServersDataStatistics', index, value.server_username)" v-if="!noxServersDataStatistics[index].data">&#9660;&#160;<span v-html="$store.getters.getLanguageText('1.1', 18)"></span>&#160;&#9660;</div>
										<div class="nox_account_desktop_portlet_accordion_block" v-else>
											<div class="nox_account_desktop_portlet_accordion_button" @click="hideServersDataStatistics(index)">&#9650;&#160;<span v-html="$store.getters.getLanguageText('1.1', 19)"></span>&#160;&#9650;</div>
											<div class="nox_account_desktop_portlet_accordion_block_content">
												<div v-if="noxServersDataStatisticsChart1[index].series[0].data.length">
													<div class="nox_chart">
														<div class="nox_chart_highcharts"><highcharts :options="noxServersDataStatisticsChart1[index]"></highcharts></div>
														<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.3', 4)"></div>
													</div>
													<div class="nox_chart">
														<div class="nox_chart_highcharts"><highcharts :options="noxServersDataStatisticsChart2[index]"></highcharts></div>
														<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.3', 7)"></div>
													</div>
												</div>
												<div v-else v-html="$store.getters.getLanguageText('1.6.3', 0)"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet" v-else>
								<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.11', 3)"></div>
							</div>
						</div>
						<div class="nox_tab_block" id="nox_tab_block_2">
							<div class="nox_account_desktop_servers_deleted" v-if="noxServersData.length">
								<div class="nox_account_desktop_portlet_wrapper" v-for="(value, index) in noxServersData" :key="index">
									<div class="nox_account_desktop_portlet" :class="{ violet: value.is_nox_demo }">
										<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.11', 7, { id: value.server_id, version: value.version })"></div>
										<div class="nox_account_desktop_portlet_content">
											<div class="nox_account_desktop_servers_data_row">
												<div class="nox_account_desktop_servers_data_col">
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 8)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 57), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">{{ value.server_id }}</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 9)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 58), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">{{ (value.version == 2 && value.server_hostname ? value.server_hostname : value.server_ip) }}:{{ value.server_port }}</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 10)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 59), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">{{ value.server_username }}</div>
													</div>
												</div>
												<div class="nox_account_desktop_servers_data_col">
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 12)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 61), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">{{ new Date(value.server_start * 1000).toLocaleString($store.state.noxLanguage) }}</div>
													</div>
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 14)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 63), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">{{ new Date(value.deleted_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
													</div>
												</div>
												<div class="nox_account_desktop_servers_data_col">
													<div class="nox_account_desktop_servers_data_block">
														<div class="nox_account_desktop_servers_data_label">
															<span v-html="$store.getters.getLanguageText('3.11', 17)"></span>
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 66), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</div>
														<div class="nox_account_desktop_servers_data_value">
															<span v-if="value.comment" v-html="String(value.comment).replace(/\n/g, '<br>')"></span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="nox_account_desktop_portlet_accordion" :class="{ violet: value.is_nox_demo }" v-if="value.version == 2">
										<div class="nox_account_desktop_portlet_accordion_loading" :class="{ hide: !noxIsServerAccordionLoadings[index] }"><img src="@/assets/images/loading.gif"></div>
										<div class="nox_account_desktop_portlet_accordion_button" :class="{ hide: noxIsServerAccordionLoadings[index] }" @click="axios('getServersDataStatistics', index, value.server_username)" v-if="!noxServersDataStatistics[index].data">&#9660;&#160;<span v-html="$store.getters.getLanguageText('1.1', 18)"></span>&#160;&#9660;</div>
										<div class="nox_account_desktop_portlet_accordion_block" v-else>
											<div class="nox_account_desktop_portlet_accordion_button" @click="hideServersDataStatistics(index)">&#9650;&#160;<span v-html="$store.getters.getLanguageText('1.1', 19)"></span>&#160;&#9650;</div>
											<div class="nox_account_desktop_portlet_accordion_block_content">
												<div v-if="noxServersDataStatisticsChart1[index].series[0].data.length">
													<div class="nox_chart">
														<div class="nox_chart_highcharts"><highcharts :options="noxServersDataStatisticsChart1[index]"></highcharts></div>
														<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.3', 4)"></div>
													</div>
													<div class="nox_chart">
														<div class="nox_chart_highcharts"><highcharts :options="noxServersDataStatisticsChart2[index]"></highcharts></div>
														<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.3', 7)"></div>
													</div>
												</div>
												<div v-else v-html="$store.getters.getLanguageText('1.6.3', 0)"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet" v-else>
								<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.11', 4)"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
			<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }" v-if="noxServersDataType == 1 || noxServersDataLimitOffset">
				<button type="button" class="nox_button big" @click="axios('getServersData')" v-if="noxServersDataLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalServers' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'buyServer' : 'addMainBalance'), version: 1 })" v-if="(($store.state.noxSystemSettings.is_active_buy_server_v1 && noxServersDataType == 1 && noxServersActiveCount < Number($store.state.noxSystemSettings.server_max_count)) || $store.state.noxAccountData.group == 1)"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 85)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalServers' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'buyServer' : 'addMainBalance'), version: 2 })" v-if="(($store.state.noxSystemSettings.is_active_buy_server_v2 && noxServersDataType == 1 && noxServersActiveCount < Number($store.state.noxSystemSettings.server_max_count)) || $store.state.noxAccountData.group == 1)"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 127)"></span></button>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalServers"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxServersFilter1: '',
			noxServersFilter2: '',
			noxServersFilter1Options: [],
			noxServersFilter2Options: [],
			noxServersData: [],
			noxServersDataType: 1,
			noxServersDataLimitOffset: 0,
			noxServersDataLimitCount: 30,
			noxServersDataStatistics: [],
			noxServersDataStatisticsChart1: [],
			noxServersDataStatisticsChart2: [],
			noxServersActiveCount: 0,
			noxServersDeletedCount: 0,
			noxIsServerPasswords: {},
			noxIsServerAccordionLoadings: [],
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsPageContentLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
				if (to.path == '/account/servers' && !Object.keys(to.query).length) {
					this.getServersData();
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.noxServersFilter1 = this.$parent.$parent.getSelectOptions(8)[(this.noxServersDataType == 2 ? 3 : 1)];
				this.noxServersFilter2 = this.$parent.$parent.getSelectOptions(7)[(this.noxServersDataType == 2 ? 0 : 1)];
				this.noxServersFilter1Options = this.$parent.$parent.getSelectOptions(8);
				this.noxServersFilter2Options = this.$parent.$parent.getSelectOptions(7);
				this.noxServersFilter1Options.splice((this.noxServersDataType == 1 ? 3 : 2), 1);
				this.getServersData();
			},
			initData: function(type, var1) {
				if (type == 'deleteServer') {
					this.noxServersDataStatistics.splice(var1, 1);
					this.noxServersDataStatisticsChart1.splice(var1, 1);
					this.noxServersDataStatisticsChart2.splice(var1, 1);
					this.noxIsServerAccordionLoadings.splice(var1, 1);
				}
				for (var i in this.noxServersData) {
					if (this.noxServersDataStatistics[i] == undefined) { this.noxServersDataStatistics.push([]); }
					if (this.noxServersDataStatisticsChart1[i] == undefined) { this.noxServersDataStatisticsChart1.push({}); }
					if (this.noxServersDataStatisticsChart2[i] == undefined) { this.noxServersDataStatisticsChart2.push({}); }
					if (this.noxIsServerAccordionLoadings[i] == undefined) { this.noxIsServerAccordionLoadings.push(false); }
				}
			},
			initTabs: function(tab) {
				this.noxServersDataType = 1;
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				if (tab == 2) { this.noxIsTabMenu2Checked = true; this.noxServersDataType = 2; }
				else          { this.noxIsTabMenu1Checked = true; }
			},
			selectTab: function(tab) {
				if (this.noxServersDataType !== tab) {
					this.noxServersDataType = tab;
					this.$router.push({ path: '/account/servers', query: { tab: tab }});
					this.noxServersFilter1 = this.$parent.$parent.getSelectOptions(8)[(this.noxServersDataType == 2 ? 3 : 1)];
					this.noxServersFilter2 = this.$parent.$parent.getSelectOptions(7)[(this.noxServersDataType == 2 ? 0 : 1)];
					this.noxServersFilter1Options = this.$parent.$parent.getSelectOptions(8);
					this.noxServersFilter2Options = this.$parent.$parent.getSelectOptions(7);
					this.noxServersFilter1Options.splice((this.noxServersDataType == 1 ? 3 : 2), 1);
					this.getServersData();
				}
			},
			getServersData: function() {
				this.$parent.$parent.goToTop(true);
				this.noxServersData = [];
				this.noxServersDataLimitOffset = 0;
				this.axios('getServersData');
			},
			getServersDataByFilter: function(event, type) {
				if (event !== null && event.code !== null) {
					if (type == 1) {
						this.noxServersFilter2 = this.$parent.$parent.getSelectOptions(7)[(Number(event.code) == 2 ? 1 : 0)];
					}
					this.getServersData();
				}
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getServersData') {
						if (!_this.noxServersDataLimitOffset) {
							_this.noxServersDataStatistics = [];
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPageContentLoading = true;
						config.url = '/v2/account/servers';
						config.params = { type: _this.noxServersDataType, limitOffset: _this.noxServersDataLimitOffset, limitCount: _this.noxServersDataLimitCount, filterType: _this.noxServersFilter1.code, filterSort: _this.noxServersFilter2.code };
					}
					else if (type == 'getServersDataStatistics') {
						_this.noxServersDataStatistics.splice(var1, 1, []);
						_this.noxIsServerAccordionLoadings.splice(var1, 1, true);
						config.url = '/v2/account/servers/statistics';
						config.params = { username: var2 };
					}
					else if (type == 'editServerIsExtend') {
						_this.noxServersData[var1].is_extend = var3;
						config.url = '/v2/account/servers/is_extend';
						config.data = { id: var2, is_extend: var3 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getServersData') {
								_this.$parent.$parent.goToTop(!_this.noxServersDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.count_of_active !== undefined) {
									_this.noxServersActiveCount = data.data.count_of_active;
								}
								if (data.data.count_of_deleted !== undefined) {
									_this.noxServersDeletedCount = data.data.count_of_deleted;
								}
								if (data.data.servers_data !== undefined) {
									if (data.data.servers_data.data.length) {
										for (var i in data.data.servers_data.data) {
											_this.noxServersData.push(data.data.servers_data.data[i]);
										}
									}
									_this.noxServersDataLimitOffset = data.data.servers_data.count > _this.noxServersDataLimitOffset + _this.noxServersDataLimitCount ? _this.noxServersDataLimitOffset + _this.noxServersDataLimitCount : 0;
								}
								_this.initData();
							}
							else if (type == 'getServersDataStatistics') {
								_this.noxServersDataStatisticsChart1[var1] = {
									title: { text: _this.$store.getters.getLanguageText('1.6.3', 1) },
									legend: { enabled: true },
									series: [
										{ name: _this.$store.getters.getLanguageText('1.6.3', 2), color: '#7CB5EC', data: [] },
										{ name: _this.$store.getters.getLanguageText('1.6.3', 3), color: '#F45B5B', data: [] }
									],
									tooltip: {
										shared: true,
										formatter: function() {
											return _this.$parent.$parent.getChartFormatForPercent(this, 2);
										}
									}
								};
								_this.noxServersDataStatisticsChart2[var1] = {
									title: { text: _this.$store.getters.getLanguageText('1.6.3', 5) },
									series: [{ name: _this.$store.getters.getLanguageText('1.6.3', 6), color: '#1EC455', data: [] }],
									tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }}
								};
								if (data.data.length) {
									for (var j in data.data) {
										var statistics = data.data[j];
										var created_at = parseInt(statistics.created_at) * 1000;
										_this.noxServersDataStatisticsChart1[var1].series[0].data.push([created_at, parseFloat(statistics.cpu.toFixed(2))]);
										_this.noxServersDataStatisticsChart1[var1].series[1].data.push([created_at, parseFloat(statistics.ram.toFixed(2))]);
										_this.noxServersDataStatisticsChart2[var1].series[0].data.push([created_at, parseFloat(statistics.ssd.toFixed(2))]);
									}
								}
								_this.noxServersDataStatistics[var1] = { data: data.data };
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						if (type == 'getServersDataStatistics') {
							_this.noxIsServerAccordionLoadings.splice(var1, 1, false);
						}
						_this.noxIsLoading = false;
						_this.noxIsPageContentLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(event, index, id) {
				this.axios('editServerIsExtend', index, id, Number(event.value));
			},
			hideServersDataStatistics: function(index) {
				this.noxServersDataStatistics.splice(index, 1, []);
			}
		},
		components: {
			NoxModalServers: () => import('@/views/modal_components/NoxModalServers.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_servers_data_row {
		margin: 0 -7.5px;
		display: flex;
	}
	.nox_account_desktop_servers_data_col {
		float: left;
		padding: 0 7.5px;
		width: 100%;
	}
	.nox_account_desktop_servers_data_block {
		line-height: 26px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_servers_data_label {
		float: left;
		margin: 0 10px 0 0;
		text-align: right;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		min-width: 132px;
	}
	.nox_account_desktop_servers_data_value {
		float: left;
		padding: 4px 0;
		word-break: break-all;
		word-break: break-word;
		font-size: 11px;
		line-height: 18px;
		display: table;
		width: calc(100% - 142px);
	}
	.nox_account_desktop_servers_data_value .green {
		color: #00FF00;
	}
	.nox_account_desktop_servers_data_value .red {
		color: #FFBBBB;
	}
	.nox_account_desktop_servers_data_value .nox_button {
		margin: -1px 5px 0 0;
	}
	.nox_account_desktop_servers_data_value .nox_button:last-child {
		margin: -1px 0 0 0;
	}
	.nox_account_desktop_servers_data_value .nox_button.nox_is_desktop {
		font-size: 12px;
	}
	.nox_account_desktop_servers_data_value .vue-js-switch {
		margin: -3px 0;
	}

	@media (max-width: 1400px) {
		.nox_account_desktop_servers_data_row {
			margin: 0;
			display: table;
			width: 100%;
		}
		.nox_account_desktop_servers_data_col {
			float: none;
			display: inline-table;
			width: 50%;
		}
	}
	@media (max-width: 1050px) {
		.nox_account_desktop_servers_data_col {
			padding: 0;
			width: 100%;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_desktop_servers_data_col {
			padding: 0 7.5px;
			width: 50%;
		}
	}
	@media (max-width: 750px) {
		.nox_account_desktop_servers_data_col {
			padding: 0;
			width: 100%;
		}
	}
	@media (max-width: 400px) {
		.nox_account_desktop_servers_data_label {
			text-align: left;
			min-width: auto;
		}
		.nox_account_desktop_servers_data_value {
			width: auto;
		}
	}
</style>
