<template>
	<div class="nox_editor_desktop">
		<div class="nox_editor_desktop_loading" :class="{ hide: !noxIsLoading }"><div class="nox_loading"></div></div>
		<div class="nox_editor_desktop_content" :class="{ hide: noxIsLoading }">
			<div class="nox_editor_desktop_content_logo">
				<router-link to="/account"><img src="@/assets/images/logo.png"></router-link>
			</div>
			<div class="nox_editor_desktop_content_title" v-html="$store.getters.getLanguageText('3.19', 0)"></div>
			<div class="nox_editor_desktop_content_buttons">
				<router-link to="/editor/landing" v-html="$store.getters.getLanguageText('3.19', 1)"></router-link>
				<router-link to="/editor/website" v-html="$store.getters.getLanguageText('3.19', 2)"></router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsEditor) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getEditorDesktopData');
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getEditorDesktopData') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsEditor = true;
						config.url = '/v2/editor/desktop';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getEditorDesktopData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsEditor = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_editor_desktop {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		vertical-align: middle;
		display: table-cell;
		width: 100vw;
		height: 100vh;
	}
	.nox_editor_desktop_content_logo img {
		height: 60px;
	}
	.nox_editor_desktop_content_title {
		margin: 20px 0;
		text-transform: uppercase;
		text-shadow: 1px 1px 3px #000000;
		font-size: 35px;
		color: #FFFFFF;
	}
	.nox_editor_desktop_content_buttons a {
		margin: 5px 5px 0 5px;
		padding: 0 15px;
		border-radius: 15px;
		background: #33333380;
		color: #FFFFFF;
		text-align: center;
		text-decoration: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 30px;
		align-items: center;
		justify-content: center;
		display: inline-flex;
		height: 30px;
	}
</style>
