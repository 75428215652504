<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_news" :class="{ full: !noxIsWidget1 && !noxIsWidget2 && !noxIsWidget3 && !noxIsWidget4_1 && !noxIsWidget4_2 && !noxIsWidget5 && !noxIsWidget6 && !noxIsWidget7 }">
				<div class="nox_account_desktop_widgets">
					<div class="nox_account_desktop_widget nox_account_desktop_widget_1" v-if="noxIsWidget1">
						<div class="nox_account_desktop_portlet" :class="[ noxIsWidget1Expires ? 'red' : 'brown' ]">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('4.1', 1)"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_countdown">
									<div class="nox_countdown_content" v-if="noxIsWidget1Countdown">
										<div class="title" v-html="$store.getters.getLanguageText('4.1', 0)"></div>
										<div class="values" v-html="noxWidget1CountdownValues"></div>
										<div class="labels" v-html="noxWidget1CountdownLabels"></div>
									</div>
									<div class="nox_countdown_content" v-else>
										<div class="title" v-html="$store.getters.getLanguageText('4.1', 2)"></div>
									</div>
									<div class="nox_countdown_button">
										<button type="button" class="nox_button common" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalPackageExtend' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? '' : 'addMainBalance') })" v-html="$store.getters.getLanguageText('1.1', 22)"></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_widget nox_account_desktop_widget_5" v-if="noxIsWidget5">
						<div class="nox_account_desktop_portlet avatar blue">
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_avatar">
									<div class="nox_account_desktop_portlet_content_avatar_img"><img :src="$parent.$parent.getAvatarLink()"></div>
									<div class="nox_account_desktop_portlet_content_avatar_edit" @click="$parent.$parent.chooseCropperImage('cropImageForAvatar')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></div>
									<div class="nox_account_desktop_portlet_content_avatar_menu">
										<router-link to="/account/settings"><font-awesome-icon :icon="['fas', 'cog']" /></router-link>
										<router-link to="/account/alerts"><font-awesome-icon :icon="['fas', 'bell']" /></router-link>
									</div>
									<div class="nox_account_desktop_portlet_content_avatar_status" :style="{ background: '#' + $store.state.noxSystemSettings['user_status_color_' + $store.state.noxAccountData.status] }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 117, { status: $store.getters.getLanguageText('4.3.1', $store.state.noxAccountData.status), count: $store.state.noxSystemSettings['user_status_cycles_' + $store.state.noxAccountData.status] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="$store.state.noxAccountData.status">{{ $store.state.noxSystemSettings['user_status_name_' + $store.state.noxAccountData.status] }}</div>
								</div>
								<div class="nox_account_desktop_portlet_content_sdata">
									<div class="nox_account_desktop_widget_5_item">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 1)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxAccountData.id }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 3)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxSystemSettings['package_name_x' + $store.state.noxAccountData.package] }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item" v-if="$store.state.noxAccountData.status">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 2)"></div>
										<div class="nox_account_desktop_widget_5_value"><a href="https://forum.nox.bz/topic/204-statusy-v-klientskoy-programme-nox/" target="_blank" v-html="$store.getters.getLanguageText('4.3.1', $store.state.noxAccountData.status)"></a></div>
									</div>
									<div class="nox_account_desktop_widget_5_item" v-if="$store.state.noxAccountData.username">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 4)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxAccountData.username }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item" v-if="$store.state.noxAccountData.fullname">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 5)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxAccountData.fullname }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 6)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxAccountData.count_start_bots }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 7)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxAccountData.count_buy_servers }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 8)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxAccountData.count_of_invited_users }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item">
										<div class="nox_account_desktop_widget_5_label" v-html="$store.getters.getLanguageText('4.3', 9)"></div>
										<div class="nox_account_desktop_widget_5_value">{{ $store.state.noxAccountData.count_of_active_invited_users }}</div>
									</div>
									<div class="nox_account_desktop_widget_5_item">
										<router-link to="/account/quests" class="nox_account_desktop_widget_5_item_quests" v-if="$store.state.noxAccountData.group !== 4">
											<div class="nox_account_desktop_widget_5_item_quests_circle">
												<div class="nox_account_desktop_widget_5_item_quests_circle_mask part">
													<div class="nox_account_desktop_widget_5_item_quests_circle_mask_fill" :style="noxWidget5QuestsProgressStyle"></div>
												</div>
												<div class="nox_account_desktop_widget_5_item_quests_circle_mask full" :style="noxWidget5QuestsProgressStyle">
													<div class="nox_account_desktop_widget_5_item_quests_circle_mask_fill" :style="noxWidget5QuestsProgressStyle"></div>
												</div>
												<div class="nox_account_desktop_widget_5_item_quests_circle_data" v-html="$store.getters.getLanguageText('4.3', 10, { count: ($store.state.noxAccountData.count_of_completed_quests > $store.state.noxSystemSettings.count_of_available_quests ? $store.state.noxSystemSettings.count_of_available_quests : $store.state.noxAccountData.count_of_completed_quests), total: $store.state.noxSystemSettings.count_of_available_quests })"></div>
											</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_widget nox_account_desktop_widget_2" v-if="noxIsWidget2">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="noxWidget2['name_' + $store.state.noxLanguage]"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_edit" @click="$modal.show('NoxModalNews', { type: 'editWidget2', index: 0, id: noxWidget2.id })" v-if="[1,2].includes($store.state.noxAccountData.group)"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></div>
								<div class="nox_account_desktop_portlet_content_sdata">
									<div class="nox_account_desktop_widget_2_text">
										<div class="theme"><b v-html="$store.getters.getLanguageText('4.2', 1)"></b>:<br><span v-html="noxWidget2.data['theme_' + $store.state.noxLanguage]"></span></div>
										<div class="datetime"><b v-html="$store.getters.getLanguageText('4.2', 2)"></b>:<br>{{ new Date(noxWidget2.data.datetime).toLocaleString($store.state.noxLanguage) }}</div>
									</div>
									<div class="nox_account_desktop_widget_2_countdown">
										<div class="nox_countdown">
											<div class="nox_countdown_content" v-if="noxIsWidget2Countdown">
												<div class="title" v-html="$store.getters.getLanguageText('4.2', 0)"></div>
												<div class="values" v-html="noxWidget2CountdownValues"></div>
												<div class="labels" v-html="noxWidget2CountdownLabels"></div>
											</div>
											<div class="nox_countdown_content" v-else>
												<div class="title blue" v-if="noxWidget2CountdownStatus == 2" v-html="$store.getters.getLanguageText('4.2', 3)"></div>
												<div class="title green" v-else-if="noxWidget2CountdownStatus == 1" v-html="$store.getters.getLanguageText('4.2', 4)"></div>
												<div class="title red" v-else v-html="$store.getters.getLanguageText('4.2', 5)"></div>
											</div>
											<div class="nox_countdown_button">
												<a :href="$store.state.noxBaseURL + '/webinar'" target="_blank"><button type="button" class="nox_button common" v-html="$store.getters.getLanguageText('1.1', 25)"></button></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_widget nox_account_desktop_widget_6" v-if="noxIsWidget6">
						<div class="nox_account_desktop_widget_6_events">
							<div class="nox_account_desktop_widget_6_events_content">
								<div class="nox_account_desktop_widget_6_events_content_item" v-for="(value, index) in noxWidget6" :key="index">
									<div class="nox_account_desktop_portlet" v-if="$store.state.noxAccountData.group == 1 || $store.state.noxAccountData.id == value.user_id || value.status">
										<div class="nox_account_desktop_portlet_title" :style="'background: ' + value.bg_color + 'CC;'" v-html="value['name_' + $store.state.noxLanguage]"></div>
										<div class="nox_account_desktop_portlet_content" :style="'background: ' + value.bg_color + 'B3;'">
											<div class="nox_account_desktop_portlet_content_edit" @click="$modal.show('NoxModalEvents', { type: 'editEvent', index: index, id: value.id })" v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></div>
											<div class="nox_account_desktop_portlet_content_sdata" v-html="value['text_' + $store.state.noxLanguage]"></div>
										</div>
										<div class="nox_account_desktop_portlet_buttons" :style="'background: ' + value.bg_color + 'CC;'">
											<button type="button" class="nox_button big full white" :style="'color: ' + value.bg_color + 'CC;'" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalEvents' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'buyTickets' : 'addMainBalance'), index: index, id: value.id })"><font-awesome-icon :icon="['fas', (value.count_tickets > value.count_buy_tickets ? 'coins' : 'ban')]" /><span v-if="value.count_tickets > value.count_buy_tickets" v-html="$store.getters.getLanguageText('1.1', 109, { cost: value.ticket_cost })"></span><span v-else v-html="$store.getters.getLanguageText('1.1', 115)"></span></button>
											<button type="button" class="nox_button big full transparent_white" @click="$modal.show('NoxModalEvents', { type: 'getTickets', index: index, id: value.id, stype: 'user' })" v-if="value.count_buy_tickets_by_user"><font-awesome-icon class="rotate_135" :icon="['fas', 'ticket-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 111)"></span></button>
											<button type="button" class="nox_button big full transparent_white" @click="$modal.show('NoxModalEvents', { type: 'getTickets', index: index, id: value.id, stype: ($store.state.noxAccountData.group == 1 ? 'admin' : 'owner') })" v-if="$store.state.noxAccountData.group == 1 || $store.state.noxAccountData.id == value.user_id"><font-awesome-icon class="rotate_135" :icon="['fas', 'ticket-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 112)"></span></button>
											<button type="button" class="nox_button big full transparent_white" @click="$modal.show('NoxModalEvents', { type: 'getTickets', index: index, id: value.id, stype: 'team' })"><font-awesome-icon :icon="['fas', 'users']" /><span v-html="$store.getters.getLanguageText('1.1', 140)"></span></button>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_widget_6_events_buttons"><button type="button" class="nox_button big green" @click="$modal.show('NoxModalEvents', { type: 'addEvent' })" v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 136)"></span></button></div>
						</div>
					</div>
					<div class="nox_account_desktop_widget nox_account_desktop_widget_7" v-if="noxIsWidget7">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="noxWidget7['name_' + $store.state.noxLanguage]"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_edit" @click="$modal.show('NoxModalNews', { type: 'editWidget7', index: 0, id: noxWidget7.id })" v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></div>
								<div class="nox_account_desktop_portlet_content_sdata" v-if="noxWidget7.data && noxWidget7.data.is_hidden_in_weekends && noxWidget7.is_weekends && noxIsWidget7Countdown">
									<div class="nox_countdown">
										<div class="nox_countdown_content">
											<div class="title" v-html="$store.getters.getLanguageText('4.5', 1)"></div>
											<div class="values" v-html="noxWidget7CountdownValues"></div>
											<div class="labels" v-html="noxWidget7CountdownLabels"></div>
										</div>
									</div>
								</div>
								<div class="nox_account_desktop_portlet_content_sdata" v-else>
									<div class="nox_account_desktop_widget_7_item" :class="{ hidden: noxWidget7.data.hidden_symbols.includes(name) }" v-if="noxWidget7.data && noxWidget7.data.clv_currency_pairs && ($store.state.noxAccountData.group == 1 || !noxWidget7.data.hidden_symbols.includes(name))" v-for="(value, name, index) in noxWidget7.data.clv_currency_pairs" :key="index">
										<div class="nox_account_desktop_widget_7_item_symbol" :class="$store.state.noxWidget7CLVDataByMultipliers[value[0].toFixed(1)].color" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 209, { options: [$store.state.noxWidget7CLVDataByMultipliers[value[0].toFixed(1)].index], symbol: name.toUpperCase() }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">{{ name }}</div>
										<div class="nox_account_desktop_widget_7_item_icon" :class="$store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].color"><span class="nox_account_desktop_widget_7_item_icon_block" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 210, { options: [$store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].index] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }"><font-awesome-icon :icon="['fas', $store.state.noxWidget7CLVDataByTrendStatuses[value[1].toFixed(1)].icon]" /></span></div>
										<div class="nox_account_desktop_widget_7_item_bar" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 211, { options: [((((value[3] - 0.5) * 100) >= 60) ? 0 : (((value[2] - 0.5) * 100) >= 60 ? 1 : 2))] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">
											<div class="nox_account_desktop_widget_7_item_bar_label sell" v-if="!index" v-html="$store.getters.getLanguageText('4.5', 0, { options: [0] })"></div>
											<div class="nox_account_desktop_widget_7_item_bar_value sell">{{ (100 - ((value[2] - 0.5) * 100)).toFixed(0) }}%</div>
											<div class="nox_account_desktop_widget_7_item_bar_label buy" v-if="!index" v-html="$store.getters.getLanguageText('4.5', 0, { options: [1] })"></div>
											<div class="nox_account_desktop_widget_7_item_bar_value buy">{{ ((value[2] - 0.5) * 100).toFixed(0) }}%</div>
											<div class="nox_account_desktop_widget_7_item_bar_line" :style="'width:' + ((value[2] - 0.5) * 100) + '%;'"></div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_buttons">
								<div class="nox_account_desktop_widget_7_text" v-html="noxWidget7['text_' + $store.state.noxLanguage]"></div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_widget nox_account_desktop_widget_3" v-if="noxIsWidget3">
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="noxWidget3['name_' + $store.state.noxLanguage]"></div>
							<div class="nox_account_desktop_portlet_content">
								<div class="nox_account_desktop_portlet_content_edit" @click="$modal.show('NoxModalNews', { type: 'editWidget3', index: 0, id: noxWidget3.id })" v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></div>
								<div class="nox_account_desktop_portlet_content_sdata" v-html="noxWidget3['text_' + $store.state.noxLanguage]"></div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_widget nox_account_desktop_widget_4" v-if="noxIsWidget4_1 && noxIsWidget4_2">
						<div class="nox_tabs">
							<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsWidget4TabMenu1Checked">
							<label class="nox_tab_menu_label red" for="nox_tab_menu_1">с Alien</label>
							<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsWidget4TabMenu2Checked">
							<label class="nox_tab_menu_label red" for="nox_tab_menu_2">без Alien</label>
							<div class="nox_tab_block" id="nox_tab_block_1">
								<div class="nox_account_desktop_widget_4_1">
									<div class="nox_account_desktop_portlet red">
										<div class="nox_account_desktop_portlet_title">Статистика с Alien</div>
										<div class="nox_account_desktop_portlet_content">
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Всего ботов:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients }} ({{ noxWidget4_1Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов Demo:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_demo }} ({{ noxWidget4_1Old.number_of_clients_demo }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_demo }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов онлайн:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_online }} ({{ noxWidget4_1Old.number_of_clients_online }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_online }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Всего пакетов:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_users_with_buy_package }} ({{ noxWidget4_1Old.number_of_users_with_buy_package }})</span>
													<span v-else>{{ noxWidget4_1.number_of_users_with_buy_package }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Всего пользователей:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_users }} ({{ noxWidget4_1Old.number_of_users }})</span>
													<span v-else>{{ noxWidget4_1.number_of_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ликвидность Grey:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_grey_3_users }} / {{ noxWidget4_1New.number_of_grey_buy_users }} ({{ noxWidget4_1Old.number_of_grey_3_users }} / {{ noxWidget4_1Old.number_of_grey_buy_users }})</span>
													<span v-else>{{ noxWidget4_1.number_of_grey_3_users }} / {{ noxWidget4_1.number_of_grey_buy_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Просрочено:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_without_subscription }} / {{ noxWidget4_1New.number_of_users }} ({{ noxWidget4_1Old.number_of_clients_without_subscription }} / {{ noxWidget4_1Old.number_of_users }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_without_subscription }} / {{ noxWidget4_1.number_of_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов Telegram:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_telegram_users }} / {{ noxWidget4_1New.number_of_users }} ({{ noxWidget4_1Old.number_of_telegram_users }} / {{ noxWidget4_1Old.number_of_users }})</span>
													<span v-else>{{ noxWidget4_1.number_of_telegram_users }} / {{ noxWidget4_1.number_of_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов Insomnia:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_insomnia }} / {{ noxWidget4_1New.number_of_clients }} ({{ noxWidget4_1Old.number_of_clients_insomnia }} / {{ noxWidget4_1Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_insomnia }} / {{ noxWidget4_1.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов Old Version:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_old_version }} / {{ noxWidget4_1New.number_of_clients }} ({{ noxWidget4_1Old.number_of_clients_old_version }} / {{ noxWidget4_1Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_old_version }} / {{ noxWidget4_1.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов Phoenix Classic:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_classic_trading }} / {{ noxWidget4_1New.number_of_clients }} ({{ noxWidget4_1Old.number_of_clients_classic_trading }} / {{ noxWidget4_1Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_classic_trading }} / {{ noxWidget4_1.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов Phoenix ZZ:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_zigzag_trading }} / {{ noxWidget4_1New.number_of_clients }} ({{ noxWidget4_1Old.number_of_clients_zigzag_trading }} / {{ noxWidget4_1Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_zigzag_trading }} / {{ noxWidget4_1.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Ботов Phoenix NT:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.number_of_clients_news_trading }} / {{ noxWidget4_1New.number_of_clients }} ({{ noxWidget4_1Old.number_of_clients_news_trading }} / {{ noxWidget4_1Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_1.number_of_clients_news_trading }} / {{ noxWidget4_1.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Чистая прибыль:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.total_customer_real_profit / 100) }} ({{ Math.ceil(noxWidget4_1Old.total_customer_real_profit / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.total_customer_real_profit / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Балансовая прибыль:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.total_customer_profit / 100) }} ({{ Math.ceil(noxWidget4_1Old.total_customer_profit / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.total_customer_profit / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Прибыльные сделки:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ Math.ceil(noxWidget4_1New.percentage_of_profitable_trades) }} ({{ Math.ceil(noxWidget4_1Old.percentage_of_profitable_trades) }})%</span>
													<span v-else>{{ Math.ceil(noxWidget4_1.percentage_of_profitable_trades) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Дельта прибыли:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ ((1 - (noxWidget4_1New.total_customer_real_profit / noxWidget4_1New.total_customer_profit)) * 100).toFixed(2) }} ({{ ((1 - (noxWidget4_1Old.total_customer_real_profit / noxWidget4_1Old.total_customer_profit)) * 100).toFixed(2) }})%</span>
													<span v-else>{{ ((1 - (noxWidget4_1.total_customer_real_profit / noxWidget4_1.total_customer_profit)) * 100).toFixed(2) }}%</span>
												</div>
											</div>
											<div v-if="Object.keys(noxWidget4_1.profitability_strategies).length">
												<div v-if="Object.keys(noxWidget4_1Old).length">
													<div class="nox_account_desktop_widget_4_1_item" v-for="(value, index) in noxWidget4_1New.profitability_strategies" :key="index">
														<div class="nox_account_desktop_widget_4_1_label">Прибыль {{ noxWidget4Strategies[index].name.substr(0,3).toUpperCase() }}. ({{ index }}):</div>
														<div class="nox_account_desktop_widget_4_1_value">{{ noxWidget4_1New.profitability_strategies[index][0] }} / {{ noxWidget4_1New.profitability_strategies[index][1] }} / {{ noxWidget4_1New.profitability_strategies[index][2] }}%</div>
													</div>
												</div>
												<div v-else>
													<div class="nox_account_desktop_widget_4_1_item" v-for="(value, index) in noxWidget4_1.profitability_strategies" :key="index">
														<div class="nox_account_desktop_widget_4_1_label">Прибыль {{ noxWidget4Strategies[index].name.substr(0,3).toUpperCase() }}. ({{ index }}):</div>
														<div class="nox_account_desktop_widget_4_1_value">{{ noxWidget4_1.profitability_strategies[index][0] }} / {{ noxWidget4_1.profitability_strategies[index][1] }} / {{ noxWidget4_1.profitability_strategies[index][2] }}%</div>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">MIN просадка:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.max_drawdown.toFixed(2) }} ({{ noxWidget4_1Old.max_drawdown.toFixed(2) }})%</span>
													<span v-else>{{ noxWidget4_1.max_drawdown.toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">MAX просадка:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.min_drawdown.toFixed(2) }} ({{ noxWidget4_1Old.min_drawdown.toFixed(2) }})%</span>
													<span v-else>{{ noxWidget4_1.min_drawdown.toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">AVG просадка:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ noxWidget4_1New.avg_drawdown.toFixed(2) }} ({{ noxWidget4_1Old.avg_drawdown.toFixed(2) }})%</span>
													<span v-else>{{ noxWidget4_1.avg_drawdown.toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">MIN объем средств:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.min_funds / 100) }} ({{ Math.ceil(noxWidget4_1Old.min_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.min_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">MAX объем средств:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.max_funds / 100) }} ({{ Math.ceil(noxWidget4_1Old.max_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.max_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">AVG объем средств:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.avg_funds / 100) }} ({{ Math.ceil(noxWidget4_1Old.avg_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.avg_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">SUM депозитов:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.sum_deposits / 100) }} ({{ Math.ceil(noxWidget4_1Old.sum_deposits / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.sum_deposits / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">SUM снятий:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.sum_withdrawals / 100) }} ({{ Math.ceil(noxWidget4_1Old.sum_withdrawals / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.sum_withdrawals / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">SUM балансов:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.sum_balance / 100) }} ({{ Math.ceil(noxWidget4_1Old.sum_balance / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.sum_balance / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">SUM средств:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">${{ Math.ceil(noxWidget4_1New.sum_funds / 100) }} ({{ Math.ceil(noxWidget4_1Old.sum_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_1.sum_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Дельта сумм:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ ((1 - (noxWidget4_1New.sum_funds / noxWidget4_1New.sum_balance)) * 100).toFixed(2) }} ({{ ((1 - (noxWidget4_1Old.sum_funds / noxWidget4_1Old.sum_balance)) * 100).toFixed(2) }})%</span>
													<span v-else>{{ ((1 - (noxWidget4_1.sum_funds / noxWidget4_1.sum_balance)) * 100).toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">MIN уров. маржи:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ Math.floor(noxWidget4_1New.min_margin_level) }} ({{ Math.floor(noxWidget4_1Old.min_margin_level) }})%</span>
													<span v-else>{{ Math.floor(noxWidget4_1.min_margin_level) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">MAX уров. маржи:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ Math.floor(noxWidget4_1New.max_margin_level) }} ({{ Math.floor(noxWidget4_1Old.max_margin_level) }})%</span>
													<span v-else>{{ Math.floor(noxWidget4_1.max_margin_level) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">AVG уров. маржи:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ Math.floor(noxWidget4_1New.avg_margin_level) }} ({{ Math.floor(noxWidget4_1Old.avg_margin_level) }})%</span>
													<span v-else>{{ Math.floor(noxWidget4_1.avg_margin_level) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_1_item">
												<div class="nox_account_desktop_widget_4_1_label">Интеграция продукта:</div>
												<div class="nox_account_desktop_widget_4_1_value">
													<span v-if="Object.keys(noxWidget4_1Old).length">{{ (noxWidget4_1New.number_of_users_with_bots_online * 100 / noxWidget4_1New.number_of_users_with_buy_package).toFixed(2) }} ({{ (noxWidget4_1Old.number_of_users_with_bots_online * 100 / noxWidget4_1Old.number_of_users_with_buy_package).toFixed(2) }})%</span>
													<span v-else>{{ (noxWidget4_1.number_of_users_with_bots_online * 100 / noxWidget4_1.number_of_users_with_buy_package).toFixed(2) }}%</span>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
										<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
											<button type="button" class="nox_button big white_red" @click="axios('refreshWidget4', 1)"><font-awesome-icon :icon="['fas', 'sync-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 8)"></span></button>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_tab_block" id="nox_tab_block_2">
								<div class="nox_account_desktop_widget_4_2">
									<div class="nox_account_desktop_portlet red">
										<div class="nox_account_desktop_portlet_title">Статистика без Alien</div>
										<div class="nox_account_desktop_portlet_content">
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Всего ботов:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients }} ({{ noxWidget4_2Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов Demo:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_demo }} ({{ noxWidget4_2Old.number_of_clients_demo }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_demo }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов онлайн:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_online }} ({{ noxWidget4_2Old.number_of_clients_online }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_online }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Всего пакетов:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_users_with_buy_package }} ({{ noxWidget4_2Old.number_of_users_with_buy_package }})</span>
													<span v-else>{{ noxWidget4_2.number_of_users_with_buy_package }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Всего пользователей:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_users }} ({{ noxWidget4_2Old.number_of_users }})</span>
													<span v-else>{{ noxWidget4_2.number_of_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ликвидность Grey:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_grey_3_users }} / {{ noxWidget4_2New.number_of_grey_buy_users }} ({{ noxWidget4_2Old.number_of_grey_3_users }} / {{ noxWidget4_2Old.number_of_grey_buy_users }})</span>
													<span v-else>{{ noxWidget4_2.number_of_grey_3_users }} / {{ noxWidget4_2.number_of_grey_buy_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Просрочено:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_without_subscription }} / {{ noxWidget4_2New.number_of_users }} ({{ noxWidget4_2Old.number_of_clients_without_subscription }} / {{ noxWidget4_2Old.number_of_users }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_without_subscription }} / {{ noxWidget4_2.number_of_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов Telegram:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_telegram_users }} / {{ noxWidget4_2New.number_of_users }} ({{ noxWidget4_2Old.number_of_telegram_users }} / {{ noxWidget4_2Old.number_of_users }})</span>
													<span v-else>{{ noxWidget4_2.number_of_telegram_users }} / {{ noxWidget4_2.number_of_users }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов Insomnia:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_insomnia }} / {{ noxWidget4_2New.number_of_clients }} ({{ noxWidget4_2Old.number_of_clients_insomnia }} / {{ noxWidget4_2Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_insomnia }} / {{ noxWidget4_2.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов Old Version:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_old_version }} / {{ noxWidget4_2New.number_of_clients }} ({{ noxWidget4_2Old.number_of_clients_old_version }} / {{ noxWidget4_2Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_old_version }} / {{ noxWidget4_2.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов Phoenix Classic:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_classic_trading }} / {{ noxWidget4_2New.number_of_clients }} ({{ noxWidget4_2Old.number_of_clients_classic_trading }} / {{ noxWidget4_2Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_classic_trading }} / {{ noxWidget4_2.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов Phoenix ZZ:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_zigzag_trading }} / {{ noxWidget4_2New.number_of_clients }} ({{ noxWidget4_2Old.number_of_clients_zigzag_trading }} / {{ noxWidget4_2Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_zigzag_trading }} / {{ noxWidget4_2.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Ботов Phoenix NT:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.number_of_clients_news_trading }} / {{ noxWidget4_2New.number_of_clients }} ({{ noxWidget4_2Old.number_of_clients_news_trading }} / {{ noxWidget4_2Old.number_of_clients }})</span>
													<span v-else>{{ noxWidget4_2.number_of_clients_news_trading }} / {{ noxWidget4_2.number_of_clients }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Чистая прибыль:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.total_customer_real_profit / 100) }} ({{ Math.ceil(noxWidget4_2Old.total_customer_real_profit / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.total_customer_real_profit / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Балансовая прибыль:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.total_customer_profit / 100) }} ({{ Math.ceil(noxWidget4_2Old.total_customer_profit / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.total_customer_profit / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Прибыльные сделки:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ Math.ceil(noxWidget4_2New.percentage_of_profitable_trades) }} ({{ Math.ceil(noxWidget4_2Old.percentage_of_profitable_trades) }})%</span>
													<span v-else>{{ Math.ceil(noxWidget4_2.percentage_of_profitable_trades) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Дельта прибыли:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ ((1 - (noxWidget4_2New.total_customer_real_profit / noxWidget4_2New.total_customer_profit)) * 100).toFixed(2) }} ({{ ((1 - (noxWidget4_2Old.total_customer_real_profit / noxWidget4_2Old.total_customer_profit)) * 100).toFixed(2) }})%</span>
													<span v-else>{{ ((1 - (noxWidget4_2.total_customer_real_profit / noxWidget4_2.total_customer_profit)) * 100).toFixed(2) }}%</span>
												</div>
											</div>
											<div v-if="Object.keys(noxWidget4_2.profitability_strategies).length">
												<div v-if="Object.keys(noxWidget4_2Old).length">
													<div class="nox_account_desktop_widget_4_2_item" v-for="(value, index) in noxWidget4_2New.profitability_strategies" :key="index">
														<div class="nox_account_desktop_widget_4_2_label">Прибыль {{ noxWidget4Strategies[index].name.substr(0,3).toUpperCase() }}. ({{ index }}):</div>
														<div class="nox_account_desktop_widget_4_2_value">{{ noxWidget4_2New.profitability_strategies[index][0] }} / {{ noxWidget4_2New.profitability_strategies[index][1] }} / {{ noxWidget4_2New.profitability_strategies[index][2] }}%</div>
													</div>
												</div>
												<div v-else>
													<div class="nox_account_desktop_widget_4_2_item" v-for="(value, index) in noxWidget4_2.profitability_strategies" :key="index">
														<div class="nox_account_desktop_widget_4_2_label">Прибыль {{ noxWidget4Strategies[index].name.substr(0,3).toUpperCase() }}. ({{ index }}):</div>
														<div class="nox_account_desktop_widget_4_2_value">{{ noxWidget4_2.profitability_strategies[index][0] }} / {{ noxWidget4_2.profitability_strategies[index][1] }} / {{ noxWidget4_2.profitability_strategies[index][2] }}%</div>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">MIN просадка:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.max_drawdown.toFixed(2) }} ({{ noxWidget4_2Old.max_drawdown.toFixed(2) }})%</span>
													<span v-else>{{ noxWidget4_2.max_drawdown.toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">MAX просадка:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.min_drawdown.toFixed(2) }} ({{ noxWidget4_2Old.min_drawdown.toFixed(2) }})%</span>
													<span v-else>{{ noxWidget4_2.min_drawdown.toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">AVG просадка:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ noxWidget4_2New.avg_drawdown.toFixed(2) }} ({{ noxWidget4_2Old.avg_drawdown.toFixed(2) }})%</span>
													<span v-else>{{ noxWidget4_2.avg_drawdown.toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">MIN объем средств:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.min_funds / 100) }} ({{ Math.ceil(noxWidget4_2Old.min_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.min_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">MAX объем средств:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.max_funds / 100) }} ({{ Math.ceil(noxWidget4_2Old.max_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.max_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">AVG объем средств:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.avg_funds / 100) }} ({{ Math.ceil(noxWidget4_2Old.avg_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.avg_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">SUM депозитов:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.sum_deposits / 100) }} ({{ Math.ceil(noxWidget4_2Old.sum_deposits / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.sum_deposits / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">SUM снятий:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.sum_withdrawals / 100) }} ({{ Math.ceil(noxWidget4_2Old.sum_withdrawals / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.sum_withdrawals / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">SUM балансов:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.sum_balance / 100) }} ({{ Math.ceil(noxWidget4_2Old.sum_balance / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.sum_balance / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">SUM средств:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">${{ Math.ceil(noxWidget4_2New.sum_funds / 100) }} ({{ Math.ceil(noxWidget4_2Old.sum_funds / 100) }})</span>
													<span v-else>${{ Math.ceil(noxWidget4_2.sum_funds / 100) }}</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Дельта сумм:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ ((1 - (noxWidget4_2New.sum_funds / noxWidget4_2New.sum_balance)) * 100).toFixed(2) }} ({{ ((1 - (noxWidget4_2Old.sum_funds / noxWidget4_2Old.sum_balance)) * 100).toFixed(2) }})%</span>
													<span v-else>{{ ((1 - (noxWidget4_2.sum_funds / noxWidget4_2.sum_balance)) * 100).toFixed(2) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">MIN уров. маржи:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ Math.floor(noxWidget4_2New.min_margin_level) }} ({{ Math.floor(noxWidget4_2Old.min_margin_level) }})%</span>
													<span v-else>{{ Math.floor(noxWidget4_2.min_margin_level) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">MAX уров. маржи:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ Math.floor(noxWidget4_2New.max_margin_level) }} ({{ Math.floor(noxWidget4_2Old.max_margin_level) }})%</span>
													<span v-else>{{ Math.floor(noxWidget4_2.max_margin_level) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">AVG уров. маржи:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ Math.floor(noxWidget4_2New.avg_margin_level) }} ({{ Math.floor(noxWidget4_2Old.avg_margin_level) }})%</span>
													<span v-else>{{ Math.floor(noxWidget4_2.avg_margin_level) }}%</span>
												</div>
											</div>
											<div class="nox_account_desktop_widget_4_2_item">
												<div class="nox_account_desktop_widget_4_2_label">Интеграция продукта:</div>
												<div class="nox_account_desktop_widget_4_2_value">
													<span v-if="Object.keys(noxWidget4_2Old).length">{{ (noxWidget4_2New.number_of_users_with_bots_online * 100 / noxWidget4_2New.number_of_users_with_buy_package).toFixed(2) }} ({{ (noxWidget4_2Old.number_of_users_with_bots_online * 100 / noxWidget4_2Old.number_of_users_with_buy_package).toFixed(2) }})%</span>
													<span v-else>{{ (noxWidget4_2.number_of_users_with_bots_online * 100 / noxWidget4_2.number_of_users_with_buy_package).toFixed(2) }}%</span>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
										<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
											<button type="button" class="nox_button big white_red" @click="axios('refreshWidget4', 2)"><font-awesome-icon :icon="['fas', 'sync-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 8)"></span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_news_data" v-if="noxNewsData.length">
					<div class="nox_account_desktop_portlet" v-for="(value, index) in noxNewsData" :key="index">
						<div class="nox_account_desktop_portlet_title" v-if="!index" v-html="$store.getters.getLanguageText('3.1', 0)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_news_data_item">
								<div class="nox_account_desktop_news_data_item_edit" @click="$modal.show('NoxModalNews', { type: 'deleteNews', index: index, id: value.id })" v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'times']" /></div>
								<div class="nox_account_desktop_news_data_item_title" :class="{ fix: $store.state.noxAccountData.group == 1 }">
									<div class="nox_account_desktop_news_data_item_title_text">
										<span><span v-html="$store.getters.getLanguageText('3.1', 1)"></span>:&#160;</span>
										<span v-if="value.message_data.username"><a :href="'https://t.me/' + value.message_data.username + '/' + value.message_id" rel="dofollow" target="_blank">{{ value.title }}</a></span>
										<span v-else>{{ value.title }}</span>
									</div>
									<div class="nox_account_desktop_news_data_item_title_created_at">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
								</div>
								<div class="nox_account_desktop_news_data_item_content" v-html="value.text"></div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet_buttons" v-if="value.url">
							<a :href="value.url" class="nox_button big transparent_white" target="_blank"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 156)"></span></a>
						</div>
					</div>
					<div v-if="noxNewsDataLimitOffset">
						<div class="nox_account_desktop_page_content_loading" :class="{ hide: !noxIsPageContentLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_page_content_buttons" :class="{ hide: noxIsPageContentLoading }">
							<button type="button" class="nox_button big" @click="axios('getNewsData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_news_data" v-else>
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.1', 0)"></div>
						<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.1', 2)"></div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalNews"></div>
				<div is="NoxModalSplash"></div>
				<div is="NoxModalEvents"></div>
				<div is="NoxModalCropper"></div>
				<div is="NoxModalPackageExtend"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxNewsData: [],
			noxNewsDataLimitOffset: 0,
			noxNewsDataLimitCount: 5,
			noxSplashData: {},
			noxWidget1CountdownTime: 0,
			noxWidget1CountdownValues: '',
			noxWidget1CountdownLabels: '',
			noxWidget1CountdownTimerId: 0,
			noxWidget2: {},
			noxWidget2CountdownTime: 0,
			noxWidget2CountdownStatus: 0,
			noxWidget2CountdownValues: '',
			noxWidget2CountdownLabels: '',
			noxWidget2CountdownTimerId: 0,
			noxWidget3: {},
			noxWidget4_1New: {},
			noxWidget4_2New: {},
			noxWidget4_1Old: {},
			noxWidget4_2Old: {},
			noxWidget4Strategies: {},
			noxWidget5QuestsProgressPercent: 0,
			noxWidget5QuestsProgressStyle: '',
			noxWidget6: [],
			noxWidget7: {},
			noxWidget7CountdownTime: 0,
			noxWidget7CountdownValues: '',
			noxWidget7CountdownLabels: '',
			noxWidget7CountdownTimerId: 0,
			noxIsWidget1: false,
			noxIsWidget1Expires: false,
			noxIsWidget1Countdown: false,
			noxIsWidget2: false,
			noxIsWidget2Countdown: false,
			noxIsWidget3: false,
			noxIsWidget4_1: false,
			noxIsWidget4_2: false,
			noxIsWidget4TabMenu1Checked: true,
			noxIsWidget4TabMenu2Checked: false,
			noxIsWidget5: true,
			noxIsWidget6: true,
			noxIsWidget7: false,
			noxIsWidget7Countdown: false,
			noxIsWidget7CountdownEnd: false,
			noxIsPageContentLoading: false,
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route() {
				this.$parent.noxIsActiveNews = true;
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$session.get('login') == 'START' && this.$cookies.get('noxRoutePath') == null) {
					if (this.$store.state.noxSystemSettings.time !== undefined && this.$store.state.noxAccountData.created_at !== undefined) {
						if ((this.$store.state.noxAccountData.created_at + (2 * 24 * 60 * 60)) > this.$store.state.noxSystemSettings.time) {
							this.$session.set('login', 'FINISH'); this.$router.push({ path: '/account/quests' }); return false;
						}
					}
				}
				this.$parent.noxIsActiveNews = true;
				this.axios('getNewsData');
			},
			initWidget: function(type, data) {
				var _this = this;
				if (type == 2) {
					_this.noxWidget2 = data;
					_this.noxIsWidget2 = (([1,2].includes(_this.$store.state.noxAccountData.group) || (data.status && data.data.link)) ? true : false);
					if (_this.noxIsWidget2) {
						_this.noxWidget2CountdownTime = new Date(data.data.datetime).getTime();
						_this.noxWidget2CountdownStatus = 0;
						_this.noxIsWidget2Countdown = false;
						if (_this.noxWidget2CountdownTimerId) { clearInterval(_this.noxWidget2CountdownTimerId); }
						if (_this.noxWidget2CountdownTime + (2 * 60 * 60 * 1000) > new Date().getTime()) {
							_this.noxWidget2CountdownStatus = 1;
							if (_this.noxWidget2CountdownTime > new Date().getTime()) {
								_this.noxWidget2CountdownStatus = 2;
								_this.noxIsWidget2Countdown = true;
							}
							_this.getWidgetsCountdown(2);
							_this.noxWidget2CountdownTimerId = setInterval(function() { _this.getWidgetsCountdown(2); }, 1000);
						}
					}
				}
				else if (type == 3) {
					_this.noxWidget3 = data;
					_this.noxIsWidget3 = ((_this.$store.state.noxAccountData.group == 1 || data.status) ? true : false);
				}
				else if (type == 5) {
					_this.noxWidget5QuestsProgressPercent = _this.$store.state.noxAccountData.count_of_completed_quests * 100;
					_this.noxWidget5QuestsProgressPercent /= _this.$store.state.noxSystemSettings.count_of_available_quests;
					_this.noxWidget5QuestsProgressPercent = (_this.noxWidget5QuestsProgressPercent > 100 ? 100 : _this.noxWidget5QuestsProgressPercent);
					_this.noxWidget5QuestsProgressStyle = 'transform: rotate(' + (180 / 100 * _this.noxWidget5QuestsProgressPercent) + 'deg);';
				}
				else if (type == 7) {
					_this.noxWidget7 = data;
					_this.noxIsWidget7 = ((_this.$store.state.noxAccountData.group == 1 || data.status) ? true : false);
					if (_this.noxIsWidget7) {
						_this.noxWidget7CountdownTime = data.weekends_at * 1000;
						if (_this.noxWidget7CountdownTime > data.time * 1000) {
							_this.noxIsWidget7Countdown = true;
							_this.noxIsWidget7CountdownEnd = false;
							_this.getWidgetsCountdown(7);
							_this.noxWidget7CountdownTimerId = setInterval(function() { _this.getWidgetsCountdown(7); }, 1000);
						}
					}
				}
			},
			getWidgetsCountdown: function(type) {

				var time = (type == 1 ? this.noxWidget1CountdownTime : 0);
						time = (type == 2 ? this.noxWidget2CountdownTime : time);
						time = (type == 7 ? this.noxWidget7CountdownTime : time);
				var seconds = (time - (new Date().getTime())) / 1000;
				var days = parseInt(seconds / 86400); seconds = seconds % 86400;
				var hours = parseInt(seconds / 3600); seconds = seconds % 3600;
				var minutes = parseInt(seconds / 60);
				var values = '<span>' + this.$parent.$parent.getNumberPad(days) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(hours) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(minutes) + '</span>';
				var labels = '<span>' + this.$store.getters.getLanguageText('1.4', 0) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 1) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 2) + '</span>';

				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
					values = '<span>' + Math.floor(seconds) + '</span>';
					labels = '<span>' + this.$store.getters.getLanguageText('1.4', 3) + '</span>';
				}

				if (type == 1) {
					this.noxWidget1CountdownValues = values;
					this.noxWidget1CountdownLabels = labels;
					if (Number(days) <= 0) { this.noxIsWidget1Expires = true; }
					if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
						this.noxIsWidget1Countdown = false; clearInterval(this.noxWidget1CountdownTimerId);
					}
				}
				else if (type == 2) {
					this.noxWidget2CountdownStatus = 2;
					this.noxWidget2CountdownValues = values;
					this.noxWidget2CountdownLabels = labels;
					if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
						this.noxWidget2CountdownStatus = 1;
						this.noxIsWidget2Countdown = false;
						if (Number(hours) <= -2) {
							this.noxWidget2CountdownStatus = 0; clearInterval(this.noxWidget2CountdownTimerId);
						}
					}
				}
				else if (type == 7) {
					this.noxWidget7CountdownValues = values;
					this.noxWidget7CountdownLabels = labels;
					if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
						if (!this.noxIsWidget7CountdownEnd) { this.noxIsWidget7CountdownEnd = true; this.axios('refreshWidget7'); }
						if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
							this.noxIsWidget7Countdown = false; clearInterval(this.noxWidget7CountdownTimerId);
						}
					}
				}
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getNewsData') {
						if (!_this.noxNewsDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPageContentLoading = true;
						config.url = '/v2' + (_this.$store.state.noxAccountData.group == 1 ? '/admin' : '') + '/account/news';
						config.params = { limitOffset: _this.noxNewsDataLimitOffset, limitCount: _this.noxNewsDataLimitCount };
					}
					else if (type == 'refreshWidget4') {
						_this.noxIsPortletLoading = true;
						config.url = '/v2/admin/account/bots/statistics/publish';
						config.data = { id: var1 };
						config.method = 'patch';
					}
					else if (type == 'refreshWidget7') {
						config.url = '/v2/account/news/widget';
						config.params = { id: 3 };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getNewsData') {
								_this.$parent.$parent.goToTop(!_this.noxNewsDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (!_this.noxNewsDataLimitOffset) {
									if (data.data.account_data !== undefined) {
										_this.$parent.$parent.initAccountData(data.data.account_data);
										if (_this.$store.state.noxAccountData.package && ((_this.$store.state.noxAccountData.package_at - _this.$store.state.noxSystemSettings.time) / (24 * 60 * 60)) < 30) {
											_this.noxIsWidget1 = true; _this.noxIsWidget1Expires = true;
											_this.noxWidget1CountdownTime = _this.$store.state.noxAccountData.package_at * 1000;
											if (_this.noxWidget1CountdownTime > _this.$store.state.noxSystemSettings.time * 1000) {
												_this.noxIsWidget1Expires = false;
												_this.noxIsWidget1Countdown = true; _this.getWidgetsCountdown(1);
												_this.noxWidget1CountdownTimerId = setInterval(function() { _this.getWidgetsCountdown(1); }, 1000);
											}
										}
									}
									if (data.data.splash_data !== undefined) {
										if (Object.keys(data.data.splash_data).length) {
											_this.noxSplashData = data.data.splash_data;
											if (_this.$store.state.noxAccountData.phone && _this.$store.state.noxAccountData.country) {
												if (!_this.$store.state.noxModalIntervalId) {
													_this.$store.state.noxModalIntervalId = setInterval(function() {
														_this.$modal.show('NoxModalSplash', { type: 'showSplash', id: _this.noxSplashData.id });
													}, 0);
												}
											}
										}
										else {
											if (data.data.quest_data !== undefined) {
												if (Object.keys(data.data.quest_data).length) {
													if (_this.$store.state.noxAccountData.group !== 4) {
														if (_this.$session.get('login') == 'START') {
															_this.$session.set('login', 'FINISH'); setTimeout(function() { _this.$parent.$parent.$notify({ id: 'quest', group: 'quest', title: _this.$store.getters.getLanguageText('3.18', 0), text: _this.$store.getters.getLanguageText('3.18', 1, { name: data.data.quest_data['name_' + _this.$store.state.noxLanguage] }), duration: 60000, speed: 1000, data: { button_name: _this.$store.getters.getLanguageText('3.18', 2), button_link: '/account/quests' }}); }, 1000);
														}
													}
												}
											}
										}
									}
									if (data.data.events_data !== undefined) {
										if (data.data.events_data.length) {
											for (var i in data.data.events_data) {
												_this.noxWidget6.push(data.data.events_data[i]);
											}
										}
									}
									if (data.data.widgets_data !== undefined) {
										if (data.data.widgets_data.length) {
											for (var j in data.data.widgets_data) {
												if (data.data.widgets_data[j].id == 1) {
													_this.initWidget(2, data.data.widgets_data[j]);
												}
												else if (data.data.widgets_data[j].id == 2) {
													_this.initWidget(3, data.data.widgets_data[j]);
												}
												else if (data.data.widgets_data[j].id == 3) {
													_this.initWidget(7, data.data.widgets_data[j]);
												}
											}
										}
										_this.initWidget(5);
									}
									if (data.data.bots_strategies !== undefined) {
										_this.noxWidget4Strategies = {};
										for (var k in data.data.bots_strategies) {
											_this.noxWidget4Strategies[data.data.bots_strategies[k].id] = data.data.bots_strategies[k];
										}
									}
									if (data.data.bots_statistics_1 !== undefined) {
										_this.noxWidget4_1 = data.data.bots_statistics_1;
										_this.noxWidget4_1New = data.data.bots_statistics_1;
										_this.noxIsWidget4_1 = true;
									}
									if (data.data.bots_statistics_2 !== undefined) {
										_this.noxWidget4_2 = data.data.bots_statistics_2;
										_this.noxWidget4_2New = data.data.bots_statistics_2;
										_this.noxIsWidget4_2 = true;
									}
								}
								if (data.data.news_data !== undefined) {
									if (data.data.news_data.data.length) {
										for (var l in data.data.news_data.data) {
											_this.noxNewsData.push(data.data.news_data.data[l]);
										}
									}
									_this.noxNewsDataLimitOffset = data.data.news_data.count > _this.noxNewsDataLimitOffset + _this.noxNewsDataLimitCount ? _this.noxNewsDataLimitOffset + _this.noxNewsDataLimitCount : 0;
								}
							}
							else if (type == 'refreshWidget4') {
								if (var1 == 1) {
									_this.noxWidget4_1Old = _this.noxWidget4_1New;
									_this.noxWidget4_1New = data.data;
								}
								else if (var1 == 2) {
									_this.noxWidget4_2Old = _this.noxWidget4_2New;
									_this.noxWidget4_2New = data.data;
								}
							}
							else if (type == 'refreshWidget7') {
								_this.initWidget(7, data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.noxIsPageContentLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$router.push({ path: String(_this.$route.path).replace(/account/gi, 'info') });
				}
			}
		},
		components: {
			NoxModalNews: () => import('@/views/modal_components/NoxModalNews.vue'),
			NoxModalSplash: () => import('@/views/modal_components/NoxModalSplash.vue'),
			NoxModalEvents: () => import('@/views/modal_components/NoxModalEvents.vue'),
			NoxModalCropper: () => import('@/views/modal_components/NoxModalCropper.vue'),
			NoxModalPackageExtend: () => import('@/views/modal_components/NoxModalPackageExtend.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_news {
		position: relative;
		padding: 0 315px 0 0;
	}
	.nox_account_desktop_news.full {
		padding: 0;
	}
	.nox_account_desktop_news_data_item {
		position: relative;
	}
	.nox_account_desktop_news_data_item_edit {
		position: absolute;
		top: 0;
		right: 0;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		font-size: 10px;
		line-height: 18px;
		width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_account_desktop_news_data_item_edit:hover {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_account_desktop_news_data_item_title {
		position: relative;
		margin: 0 0 25px 0;
		padding: 0 120px 5px 0;
		border-bottom: 1px dashed #999999;
	}
	.nox_account_desktop_news_data_item_title.fix {
		padding: 0 155px 5px 0;
	}
	.nox_account_desktop_news_data_item_title.fix .nox_account_desktop_news_data_item_title_created_at {
		right: 35px;
	}
	.nox_account_desktop_news_data_item_title_text {
		padding: 2px 0 6px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 16px;
	}
	.nox_account_desktop_news_data_item_title_created_at {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 10px;
	}
	.nox_account_desktop_news_data_item_content .photo,
	.nox_account_desktop_news_data_item_content .iframe {
		text-align: center;
		display: inline-block;
		width: 100%;
	}
	.nox_account_desktop_news_data_item_content .photo {
		margin: 20px 0;
	}
	.nox_account_desktop_news_data_item_content .photo:first-child {
		margin: 0 0 20px 0;
	}
	.nox_account_desktop_news_data_item_content .photo img,
	.nox_account_desktop_news_data_item_content .iframe iframe {
		border: 2px solid #777777;
		border-radius: 10px;
		box-shadow: 0 0 5px #555555;
		background: #000000;
		max-width: 260px;
	}
	.nox_account_desktop_news_data_item_content .iframe iframe {
		margin: 0 0 -7px 0;
		max-width: 500px;
	}

	@media (max-width: 1150px) {
		.nox_account_desktop_news_data_item_title {
			padding: 0 0 5px 0;
		}
		.nox_account_desktop_news_data_item_title.fix {
			padding: 0 25px 5px 0;
		}
		.nox_account_desktop_news_data_item_title_created_at {
			position: relative;
			top: -2px !important;
			right: 0 !important;
			display: table;
			width: 100%;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_desktop_news {
			padding: 0 310px 0 0;
		}
	}
	@media (max-width: 644px) {
		.nox_account_desktop_news {
			padding: 0;
		}
		.nox_account_desktop_news_data_item_content .iframe iframe {
			width: 100%;
		}
	}
	@media (max-width: 500px) {
		.nox_account_desktop_news_data_item_content .photo img {
			width: 100%;
		}
	}
</style>
