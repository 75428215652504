<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_users">
				<div class="nox_account_desktop_page_content_navigation">
					<div class="nox_account_desktop_page_content_navigation_filters">
						<div class="nox_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 8)"></div>
						<div class="nox_account_desktop_page_content_navigation_filters_block col2">
							<div class="nox_account_desktop_page_content_navigation_filters_select"><v-select class="nox_select style-chooser" v-model.trim="noxUsersFilter1" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxUsersFilter1Options" @input="getUsersDataByFilter"></v-select></div>
						</div>
						<div class="nox_account_desktop_page_content_navigation_filters_block col2">
							<div class="nox_account_desktop_page_content_navigation_filters_select last"><v-select class="nox_select style-chooser" v-model.trim="noxUsersFilter2" :placeholder="$store.getters.getLanguageText('1.8', 7)" :options="noxUsersFilter2Options" @input="getUsersDataByFilter"></v-select></div>
						</div>
					</div>
					<div class="nox_account_desktop_page_content_navigation_search">
						<input type="text" class="nox_input" v-model.number="noxUsersSearch" @keyup.13="getUsersDataBySearch" :placeholder="$store.getters.getLanguageText('1.8.4', 0)" autocomplete="off">
						<div class="nox_account_desktop_page_content_navigation_search_button" @click="getUsersDataBySearch"><font-awesome-icon :icon="['fas', 'search']" /></div>
						<div class="nox_account_desktop_page_content_navigation_search_result" v-show="noxUsersSearchResult.length || noxIsUsersSearchLoading">
							<div class="nox_account_desktop_page_content_navigation_search_result_block">
								<div class="nox_account_desktop_page_content_navigation_search_result_loading" :class="{ hide: !noxIsUsersSearchLoading }"><span v-html="$store.getters.getLanguageText('1.8', 0)"></span>&#160;<img src="@/assets/images/loading.gif"></div>
								<div class="nox_account_desktop_page_content_navigation_search_result_content" :class="{ hide: noxIsUsersSearchLoading }">
									<span v-if="noxUsersSearchResult[0] && noxUsersSearchResult[0].length">
										<span class="nox_account_desktop_page_content_navigation_search_result_content_link" v-if="noxUsersSearchResult[0].length == 1" @click="getUsersData(noxUsersSearchResult[0][0].user_key)" v-html="$store.getters.getLanguageText('1.8.4', 2, { id: noxUsersSearchResult[0][0].user_id })"></span>
										<span class="nox_account_desktop_page_content_navigation_search_result_content_links" v-else>
											<span v-html="$store.getters.getLanguageText('1.8.4', 3)"></span>
											<span v-for="(value, index) in noxUsersSearchResult[0]" :key="index">
												<span>{{ (index ? ',' : '') }} </span>
												<span class="nox_account_desktop_page_content_navigation_search_result_content_link" @click="getUsersData(value.user_key)">{{ value.user_id }}</span>
											</span>
										</span>
									</span>
									<span v-else v-html="$store.getters.getLanguageText('1.8.4', 1)"></span>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_page_content_navigation_search_background" :class="{ hide: !noxUsersSearchResult.length && !noxIsUsersSearchLoading }" @click="closeUsersDataSearch"></div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title">Список пользователей</div>
					<div class="nox_account_desktop_portlet_content">
						<table class="nox_table" v-if="noxUsersData.length">
							<thead>
								<tr>
									<th>Id</th>
									<th>SId</th>
									<th>Email</th>
									<th>Телефон</th>
									<th>Псевдоним</th>
									<th>Страна</th>
									<th>Основной баланс</th>
									<th>Бонусный баланс</th>
									<th>Баланс NOX_ZP</th>
									<th><font-awesome-icon :icon="['fas', 'search']" /></th>
									<th v-if="$store.state.noxAccountData.group == 1"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in noxUsersData" :key="index">
									<td data-label="Id:">{{ value.id }}</td>
									<td data-label="SId:">{{ value.sponsor_id }}</td>
									<td data-label="Email:">{{ value.email }}</td>
									<td data-label="Телефон:">{{ value.phone }}</td>
									<td data-label="Псевдоним:">{{ value.username }}</td>
									<td data-label="Страна:">{{ value.country }}</td>
									<td data-label="Основной баланс:">{{ $parent.$parent.getNumberFormatForDollar(value.balance_nox, 2) }}</td>
									<td data-label="Бонусный баланс:">{{ $parent.$parent.getNumberFormatForDollar(value.balance_of_bonuses, 2) }}</td>
									<td data-label="Баланс NOX ZP:">{{ $parent.$parent.getNumberFormatForDollar(value.balance_nox_zp, 2) }}</td>
									<td data-label="Просмотр:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalUsers', { type: 'getUser', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'search']" /></button></td>
									<td data-label="Изменить:" v-if="$store.state.noxAccountData.group == 1"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalUsers', { type: 'editUser', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="noxUsersDataLimitOffset">
						<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
							<button type="button" class="nox_button big" @click="axios('getUsersData')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalUsers"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxUsersSearch: '',
			noxUsersSearchResult: [],
			noxUsersFilter1: '',
			noxUsersFilter2: '',
			noxUsersFilter1Options: [],
			noxUsersFilter2Options: [],
			noxUsersData: [],
			noxUsersDataLimitOffset: 0,
			noxUsersDataLimitCount: 200,
			noxIsUsersSearchLoading: false,
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				if (to.path == '/account/users' && !Object.keys(to.query).length) {
					this.getUsersData();
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if ([1,2].includes(this.$store.state.noxAccountData.group)) {
					this.noxUsersFilter1 = this.$parent.$parent.getSelectOptions(5)[0];
					this.noxUsersFilter2 = this.$parent.$parent.getSelectOptions(4)[1];
					this.noxUsersFilter1Options = this.$parent.$parent.getSelectOptions(5);
					this.noxUsersFilter2Options = this.$parent.$parent.getSelectOptions(4);
					this.getUsersData(this.$route.params.key);
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			getUsersData: function(key) {
				this.$parent.$parent.goToTop(true);
				this.noxUsersData = [];
				this.noxUsersDataLimitOffset = 0;
				this.closeUsersDataSearch();
				this.axios('getUsersData', key);
			},
			getUsersDataByFilter: function() {
				this.getUsersData();
			},
			getUsersDataBySearch: function() {
				this.noxUsersSearchResult.splice(0, 1);
				if (this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(this.noxUsersSearch)) {
					this.axios('getUsersDataBySearch');
				}
			},
			closeUsersDataSearch: function() {
				this.noxUsersSearch = '';
				this.noxUsersSearchResult.splice(0, 1);
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getUsersData') {
						if (!_this.noxUsersDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						if (var1 !== undefined) {
							config.url = '/v2/admin/account/users/data/' + var1;
						}
						else {
							_this.noxIsPortletLoading = true;
							config.url = '/v2/admin/account/users';
							config.params = { limitOffset: _this.noxUsersDataLimitOffset, limitCount: _this.noxUsersDataLimitCount, filterType: _this.noxUsersFilter1.code, filterSort: _this.noxUsersFilter2.code };
						}
					}
					else if (type == 'getUsersDataBySearch') {
						_this.noxIsUsersSearchLoading = true;
						config.url = '/v2/admin/account/users/search/' + _this.noxUsersSearch;
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getUsersData') {
								_this.$parent.$parent.goToTop(!_this.noxUsersDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.users_data !== undefined) {
									if (data.data.users_data.data.length) {
										for (var i in data.data.users_data.data) {
											data.data.users_data.data[i].phone = ((data.data.users_data.data[i].phone ? '+' : '') + data.data.users_data.data[i].phone);
											_this.noxUsersData.push(data.data.users_data.data[i]);
										}
									}
									_this.noxUsersDataLimitOffset = data.data.users_data.count > _this.noxUsersDataLimitOffset + _this.noxUsersDataLimitCount ? _this.noxUsersDataLimitOffset + _this.noxUsersDataLimitCount : 0;
								}
								if (var1 !== undefined) {
									if (_this.$route.path !== '/account/users/' + var1) {
										_this.$router.push({ path: '/account/users/' + var1 });
									}
								}
								else {
									if (_this.$route.path !== '/account/users') {
										_this.$router.push({ path: '/account/users' });
									}
								}
							}
							else if (type == 'getUsersDataBySearch') {
								_this.noxUsersSearchResult.splice(0, 1, data.data);
							}
						}
					}).catch(function() {
						if (type == 'getUsersDataBySearch') {
							_this.noxUsersSearchResult.splice(0, 1);
						}
						else {
							_this.$parent.$parent.exit();
						}
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.noxIsUsersSearchLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalUsers: () => import('@/views/modal_components/NoxModalUsers.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_users tr th:nth-child(1),
	.nox_account_desktop_users tr td:nth-child(1),
	.nox_account_desktop_users tr th:nth-child(2),
	.nox_account_desktop_users tr td:nth-child(2),
	.nox_account_desktop_users tr th:nth-child(6),
	.nox_account_desktop_users tr td:nth-child(6),
	.nox_account_desktop_users tr th:nth-child(7),
	.nox_account_desktop_users tr td:nth-child(7),
	.nox_account_desktop_users tr th:nth-child(8),
	.nox_account_desktop_users tr td:nth-child(8),
	.nox_account_desktop_users tr th:nth-child(9),
	.nox_account_desktop_users tr td:nth-child(9),
	.nox_account_desktop_users tr th:nth-child(10),
	.nox_account_desktop_users tr td:nth-child(10),
	.nox_account_desktop_users tr th:nth-child(11),
	.nox_account_desktop_users tr td:nth-child(11) {
		min-width: 45px;
		width: 1%;
	}
</style>
