<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_auth password_recovery">
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.3', 0)"></div>
					<form class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_row last">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.3', 1)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="text" class="nox_input" id="email" name="email" v-model.trim="noxEmail" autocomplete="off">
								<div v-html="noxAlertEmail"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_alert" v-html="noxAlert"></div>
						<div class="nox_auth_block_content_form_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_auth_block_content_form_buttons" :class="{ hide: noxIsLoading }">
							<button type="submit" class="nox_button common green" @click="submit($event, 'passwordRecovery')" v-html="$store.getters.getLanguageText('1.1', 32)"></button>
						</div>
						<div class="nox_auth_block_content_form_footer">
							<router-link to="/">[<span v-html="$store.getters.getLanguageText('1.1', 31)"></span>]</router-link>
							<router-link to="/registration">[<span v-html="$store.getters.getLanguageText('1.1', 30)"></span>]</router-link>
						</div>
					</form>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertEmail: '',
			noxEmail: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertEmail = '';
			},
			getError: function(i, data) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 4); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 5); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 36); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 37); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 6); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 61, data); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event, type) {
				event.preventDefault(); this.axios(type);
			},
			axios: function(type) {

				var _this = this;
				if (_this.$store.state.noxOAuthTokenForPassword) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxOAuthTokenForPassword }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'passwordRecovery') {
						if (!_this.noxEmail) {
							_this.noxAlertEmail = _this.getError(1);
						}
						else if (!_this.$store.state.noxRegexEmail.test(_this.noxEmail)) {
							_this.noxAlertEmail = _this.getError(2);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/oauth/password/recovery';
							config.data = { email: _this.noxEmail, language: _this.$store.state.noxLanguage };
							config.method = 'post';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'passwordRecovery') {
									_this.$router.push({ path: '/password/recovery/success', query: { email: _this.noxEmail }});
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'passwordRecovery') {
										if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlertEmail = _this.getError(1); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlertEmail = _this.getError(2); }
										else if (data.response.data.error == 'LANGUAGE_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'LANGUAGE_NOT_VALID') { _this.noxAlert = _this.getError(4); }
									}
								}
								else if (data.response.status == 404) {
									if (type == 'passwordRecovery') {
										if (data.response.data.error == 'EMAIL_NOT_FOUND') { _this.noxAlertEmail = _this.getError(5); }
									}
								}
								else if (data.response.status == 429) {
									if (data.response.headers['retry-after'] !== undefined) {
										if (type == 'passwordRecovery') {
											_this.noxAlert = _this.getError(6, { options: [_this.$parent.getDeclension(Number(data.response.headers['retry-after']))], seconds: Number(data.response.headers['retry-after']) });
										}
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
			}
		},
		components: {
			NoxAuthHeader,
			NoxAuthFooter
		}
	}
</script>
