<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.4.1', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_portlet_content_img_200">
						<img src="@/assets/images/bot_img_6.png">
						<div v-html="$store.getters.getLanguageText('3.4.1', 1)"></div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big green" @click="$modal.show('NoxModalPortraitTests')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 83)"></span></button>
				</div>
			</div>
			<div v-if="$store.state.noxMBotsData.length">
				<div class="nox_account_desktop_portlet_wrapper" v-for="(value, index) in $store.state.noxMBotsData" :key="index">
					<div class="nox_account_desktop_portlet" :class="{ violet: value.data.is_nox_demo_bot }">
						<div class="nox_account_desktop_portlet_title">
							<span v-html="$store.getters.getLanguageText('3.4.3', 0, { number: (index + 1), id: value.data.id })"></span>
							<span v-if="value.data.type_account == 1 || value.data.is_nox_demo_bot">&#160;<span v-html="$store.getters.getLanguageText('3.4.3', 1)"></span></span>
						</div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_portlet_content_sdata">
								<div class="nox_account_desktop_bot_settings" :class="{ phoenix_settings: value.data.strategy_type == 2 }">
									<div class="nox_account_desktop_bot_settings_left">
										<div class="nox_account_desktop_bot_settings_cell">
											<div class="nox_account_desktop_bot_settings_logo" :class="{ bot_settings_logo_hide: value.is_shows.is_show_for_bot_prints }">
												<div class="nox_account_desktop_bot_settings_risk">
													<div class="bot_risk_phoenix" v-if="value.data.strategy_type == 2 && value.data.version[0] == 3">
														<img src="@/assets/images/bot_risk_phoenix_v3.png"><div>Phoenix PRO</div>
													</div>
													<div class="bot_risk_phoenix" v-else-if="value.data.strategy_type == 2">
														<img src="@/assets/images/bot_risk_phoenix_v2.png"><div>Phoenix</div>
													</div>
													<div class="bot_risk_insomnia" v-else-if="value.data.is_insomnia">
														<img src="@/assets/images/bot_risk_insomnia.png"><div>Insomnia</div>
													</div>
													<div class="bot_risk_1" v-else-if="value.info.risk_id == 1">
														<img src="@/assets/images/bot_risk_1.png"><div>Safe</div>
													</div>
													<div class="bot_risk_2" v-else-if="value.info.risk_id == 2">
														<img src="@/assets/images/bot_risk_2.png"><div>Medium</div>
													</div>
													<div class="bot_risk_3" v-else-if="value.info.risk_id == 3">
														<img src="@/assets/images/bot_risk_3.png"><div>Aggressive</div>
													</div>
													<div class="bot_risk_4" v-else-if="value.info.risk_id == 4">
														<img src="@/assets/images/bot_risk_4.png"><div>Insane</div>
													</div>
													<div class="bot_risk_0" v-else>
														<img src="@/assets/images/bot_risk_0.png"><div>Undefined</div>
													</div>
												</div>
												<div class="nox_account_desktop_bot_settings_stickers" v-if="value.data.strategy_type == 1">
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_gap">
														<div class="sticker_is_gap" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 56, { id: value.data.id }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">GAP</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_alien">
														<div class="sticker_is_alien" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 3), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">ALIEN</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.online_at">
														<div class="sticker_online" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 0), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at + (70 * 60) > $store.state.noxSystemSettings.time">ONLINE</div>
														<div class="sticker_offline" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 1), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-else>OFFLINE</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_use_insomnia">
														<div class="sticker_is_use_insomnia" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 45), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">INSOMNIA</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.online_at && value.data.status && value.data.withdrawals > 0 && (value.data.balance - value.data.deposits + value.data.withdrawals) > 0 && value.data.withdrawals > (value.data.balance - value.data.deposits + value.data.withdrawals)">
														<div class="sticker_overload" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 11), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">OVERLOAD</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.online_at && (value.data.version !== $store.state.noxSystemSettings[(value.data.strategy_type == 2 ? 'bot_version_phoenix' : 'bot_version')])">
														<div class="sticker_old_version" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 2), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">OLD VERSION</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_perk_free_bot">
														<div class="sticker_is_perk" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 53), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">PERK BOT</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_perk_deposit_limit">
														<div class="sticker_is_perk" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 54, { value: $store.state.noxSystemSettings.perk_bonus_2 }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">PERK +{{ $store.state.noxSystemSettings.perk_bonus_2 }}%</div>
													</div>
													<div class="nox_account_desktop_bot_settings_sticker" v-if="value.data.is_perk_insomnia">
														<div class="sticker_is_perk" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 55), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }">PERK INSOMNIA</div>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_prints" :class="{ bot_settings_prints_hide: !value.is_shows.is_show_for_bot_prints }" v-if="value.data.strategy_type == 2">
												<div class="nox_account_desktop_bot_settings_prints_content">
													<div class="nox_account_desktop_bot_settings_prints_content_block">
														<div class="nox_account_desktop_bot_settings_prints_content_button" :class="'bot_settings_prints_count_' + $parent.$parent.getPrintsCount(value.data)" @click="value.is_shows.is_show_for_bot_prints = !value.is_shows.is_show_for_bot_prints"><font-awesome-icon :icon="['fas', (value.is_shows.is_show_for_bot_prints ? 'angle-double-down' : 'angle-double-up')]" /></div>
														<div class="nox_account_desktop_bot_settings_prints_content_items">
															<div class="nox_account_desktop_bot_settings_prints_content_item group_1_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 0), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.online_at + (70 * 60) >= $store.state.noxSystemSettings.time"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_2_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 1), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.online_at + (70 * 60) < $store.state.noxSystemSettings.time"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_2_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 3), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_alien"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_2_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 56, { id: value.data.id }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_gap"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_2_4" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 2), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.strategy_type && (value.data.version !== $store.state.noxSystemSettings[(value.data.strategy_type == 2 ? 'bot_version_phoenix' : 'bot_version')])"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_2_5" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 11), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.online_at && value.data.status && value.data.withdrawals > 0 && (value.data.balance - value.data.deposits + value.data.withdrawals) > 0 && value.data.withdrawals > (value.data.balance - value.data.deposits + value.data.withdrawals)"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item" :class="[(value.data.strategy_settings.ac == 3 ? 'group_2_7' : 'group_2_6')]" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', (value.data.strategy_settings.ac == 3 ? 217 : 212)), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_locked_currency_pairs"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_2_8" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 222, { min_lot: value.data.min_lot }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.min_lot > 0.01"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_3_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 175), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.u && value.data.strategy_settings.u == 'BO'"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_3_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 45), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_use_insomnia"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_3_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 159), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.t == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_3_4" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 160), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.u && !['OFF', 'BO'].includes(value.data.strategy_settings.u)"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_3_5" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 173), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.y == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_3_6" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 218), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_compound_interest"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_4_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 53), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_perk_free_bot"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_4_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 54, { value: $store.state.noxSystemSettings.perk_bonus_2 }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_perk_deposit_limit"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_4_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 55), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_perk_insomnia"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_1" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 130), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.behavior == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_2" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 131), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.behavior == 2"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_3" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 132), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.behavior == 3"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_4" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 133), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.k == 0"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_5" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 134), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.k == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_6" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 135), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.k == 2"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_27" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 205), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.is_nox_demo_bot"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_7" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 136), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.j >= 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_8" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 137), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.m == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_9" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 138), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.p == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_10" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 139), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.a == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_11" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 140), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.c == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_12" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 141), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.f == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_13" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 142), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.d == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_14" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 143), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.h == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_15" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 144), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.l == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_16" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 145), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.n == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_17" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 146), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.o == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_18" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 147), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.r == 0"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_19" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 148), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.r == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_20" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 164), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.r == 2"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_21" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 170), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.w == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_22" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 171), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.x == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_23" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 177), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="!value.data.is_compound_interest && value.data.funding_limit"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_24" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 178), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.purpose_of_account"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_25" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 186), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.z == 1"></div>
															<div class="nox_account_desktop_bot_settings_prints_content_item group_5_26" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 187), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.data.strategy_settings.aa == 1"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="nox_account_desktop_bot_settings_right">
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.number || !value.data.is_nox_demo_bot">
											<div class="nox_account_desktop_bot_settings_block">
												<div class="nox_account_desktop_bot_settings_label">
													<span class="nox_is_desktop" v-html="$store.getters.getLanguageText('3.4.3', 2)"></span>
													<span class="nox_is_mobile" v-html="$store.getters.getLanguageText('3.4.3', 3)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 5), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<div v-if="value.data.number">
														<span v-if="value.data.leverage" v-html="$store.getters.getLanguageText('3.4.3', 18, { number: value.data.number, leverage: value.data.leverage })"></span><span v-else>{{ value.data.number }}</span>
													</div>
													<div v-else>
														<form>
															<input type="text" class="nox_input" v-model.trim="value.edits.edit_for_bot_number" :placeholder="$store.getters.getLanguageText('3.4.3', 3)" autocomplete="off">
															<span class="nox_account_desktop_bot_settings_value_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_number }"><img src="@/assets/images/loading.gif"></span>
															<span class="nox_account_desktop_bot_settings_value_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_number }">
																<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editBotNumber', index, value.data.id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
																<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editBotNumber', index, value.data.id)"><font-awesome-icon :icon="['fas', 'check']" /></button>
															</span>
														</form>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_alert" v-html="value.alerts.alert_for_bot_number"></div>
										</div>
										<div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 4)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 6), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_content">
													<div :class="{ red: compareBotFullname(value.data.fullname) }" v-if="value.data.fullname">
														<span>{{ value.data.fullname }}</span>
														<span v-if="compareBotFullname(value.data.fullname)">
															<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'exclamation-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 7), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
														</span>
													</div>
													<div class="uppercase" v-else v-html="$store.getters.getLanguageText('3.4.3', 6)"></div>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 5)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 8), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_content">
													<div v-if="value.data.fullname_company">{{ value.data.fullname_company }}</div>
													<div class="uppercase" v-else v-html="$store.getters.getLanguageText('3.4.3', 6)"></div>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span class="nox_is_desktop" v-html="$store.getters.getLanguageText('3.4.3', 7)"></span>
												<span class="nox_is_mobile" v-html="$store.getters.getLanguageText('3.4.3', 30)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 9), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_content">
													<div v-if="value.data.strategy_id">
														<span class="margin_right_10">{{ $store.state.noxBotsStrategies[value.data.strategy_id].name }}</span>
														<span class="margin_right_10 bold" v-if="value.data.strategy_id && value.data.strategy_type == 2">({{ noxPresetsSettings['preset_' + value.data.strategy_preset_id]['name' + (noxPresetsSettings['preset_' + value.data.strategy_preset_id].type == 1 ? ('_' + $store.state.noxLanguage) : '')] }})</span>
														<span class="nox_account_desktop_bot_settings_value_buttons">
															<button type="button" class="nox_button nox_is_desktop orange" @click="$modal.show('NoxModalBotSettings', { type: 'getPhoenixSettings', index: index, id: value.data.id })" v-if="value.data.strategy_type == 2" v-html="$store.getters.getLanguageText('1.1', 98)"></button>
															<button type="button" class="nox_button nox_is_mobile orange" @click="$modal.show('NoxModalBotSettings', { type: 'getPhoenixSettings', index: index, id: value.data.id })" v-if="value.data.strategy_type == 2"><font-awesome-icon :icon="['fas', 'cog']" /></button>
															<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show('NoxModalBotSettings', { type: 'viewBotStrategy', index: index, id: value.data.id })" v-html="$store.getters.getLanguageText('1.1', 13)"></button>
															<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show('NoxModalBotSettings', { type: 'viewBotStrategy', index: index, id: value.data.id })"><font-awesome-icon :icon="['fas', 'eye']" /></button>
														</span>
													</div>
													<div v-else>
														<span class="uppercase margin_right_10" v-html="$store.getters.getLanguageText('3.4.3', 8)"></span>
														<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show('NoxModalBotSettings', { type: 'addBotStrategy', index: index, id: value.data.id })" v-html="$store.getters.getLanguageText('1.1', 12)"></button>
														<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show('NoxModalBotSettings', { type: 'addBotStrategy', index: index, id: value.data.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
													</div>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 1">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 9)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 10), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<span v-if="$store.state.noxBotsStrategies[value.data.strategy_id].min_multiplier !== $store.state.noxBotsStrategies[value.data.strategy_id].max_multiplier">
													<span class="nox_range_slider_value">{{ value.edits.edit_for_bot_multiplier.toFixed(1) }}</span>
													<vue-slider class="nox_range_slider" :min="$store.state.noxBotsStrategies[value.data.strategy_id].min_multiplier" :max="$store.state.noxBotsStrategies[value.data.strategy_id].max_multiplier" :interval="0.1" :tooltip-formatter="$parent.$parent.getNumberFormat(value.edits.edit_for_bot_multiplier, 1)" width="100px" v-model="value.edits.edit_for_bot_multiplier" @change="botMultiplierRangeChange(index)" @drag-end="botMultiplierRangeDragEnd(index, value.data.id)"></vue-slider>
												</span>
												<span v-else>{{ $store.state.noxBotsStrategies[value.data.strategy_id].max_multiplier.toFixed(1) }}</span>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 2">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 34)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 213), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_content">
													<b class="uppercase" :class="{ margin_right_10: !value.data.is_use_start }"><span v-if="!value.data.is_auto_allowed_pairs && value.data.allowed_pairs.length">{{ value.data.allowed_pairs.join(', ') }}</span><span v-else v-html="$store.getters.getLanguageText('3.4.3', 35)"></span></b>
													<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show('NoxModalBotSettings', { type: 'editBotAllowedPairs', index: index, id: value.data.id })" v-if="!value.data.is_use_start" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
													<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show('NoxModalBotSettings', { type: 'editBotAllowedPairs', index: index, id: value.data.id })" v-if="!value.data.is_use_start"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 2">
											<div class="nox_account_desktop_bot_settings_label">
												<span class="nox_is_desktop" v-html="$store.getters.getLanguageText('3.4.3', 10)"></span>
												<span class="nox_is_mobile" v-html="$store.getters.getLanguageText('3.4.3', 31)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 77), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_behavior">
													<span class="nox_account_desktop_bot_settings_value_behavior_select">
														<div class="nox_cselect" :class="{ open: $store.state.noxCSelectId == ('behavior_' + index) }" tabindex="-1" @blur="$parent.$parent.closeCSelect">
															<div class="nox_cselect_toggle" @click="$parent.$parent.toggleCSelect('behavior_' + index)">
																<div class="nox_cselect_toggle_value" v-if="value.edits.edit_for_bot_behavior">{{ value.edits.edit_for_bot_behavior.label }}</div>
																<div class="nox_cselect_toggle_placeholder" v-else v-html="$store.getters.getLanguageText('3.4.3', 11)"></div>
																<div class="nox_cselect_toggle_button"><font-awesome-icon :icon="['fas', 'chevron-' + ($store.state.noxCSelectId == ('behavior_' + index) ? 'up' : 'down')]" /></div>
															</div>
															<div class="nox_cselect_options">
																<div class="nox_cselect_options_item" v-for="(value2, index2) in $parent.$parent.getSelectOptions(9)" :key="index2" v-if="!(value2.code == 3 && !value.data.is_nox_demo_bot) || $store.state.noxAccountData.group == 1">
																	<div class="nox_cselect_options_item_content">
																		<div class="nox_cselect_options_item_content_value bold" :style="'color: ' + noxBehaviorsColors[index2] + ';'" @click="botBehaviorSelectChange(value2, index, value.data.id)">{{ value2.label }}</div>
																	</div>
																</div>
															</div>
														</div>
													</span>
													<span class="nox_account_desktop_bot_settings_value_behavior_loading" :class="{ active: value.edits.edit_for_bot_behavior_save_id == 1 }"><img src="@/assets/images/loading.gif"></span>
													<span class="nox_account_desktop_bot_settings_value_behavior_result" :class="[(value.edits.edit_for_bot_behavior_save_id == 2 ? 'active' : (value.edits.edit_for_bot_behavior_save_id == 3 ? 'inactive' : ''))]" v-html="$store.getters.getLanguageText('3.4.3', 23)"></span>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 1 && [1,2].includes($store.state.noxAccountData.group)">
											<div class="nox_account_desktop_bot_settings_block">
												<div class="nox_account_desktop_bot_settings_label">
													<span>Порог остановки</span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Порог остановки</b> - при достижении указанного значения в средствах на счете пользователя бот перестанет открывать новые сделки либо до момента снижения уровня просадки, либо до пополнения счета его владельцем.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<div v-if="value.is_edits.is_edit_for_bot_threshold">
														<form>
															<input type="text" class="nox_input small" v-model.trim="value.edits.edit_for_bot_threshold" placeholder="порог остановки" autocomplete="off">
															<span class="nox_account_desktop_bot_settings_value_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_threshold }"><img src="@/assets/images/loading.gif"></span>
															<span class="nox_account_desktop_bot_settings_value_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_threshold }">
																<button type="button" class="nox_button nox_is_desktop red" @click="editBotThreshold(index, 'cancel')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
																<button type="button" class="nox_button nox_is_mobile red" @click="editBotThreshold(index, 'cancel')"><font-awesome-icon :icon="['fas', 'times']" /></button>
																<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editBotThreshold', index, value.data.id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
																<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editBotThreshold', index, value.data.id)"><font-awesome-icon :icon="['fas', 'check']" /></button>
															</span>
														</form>
													</div>
													<div v-else>
														<span class="margin_right_10">${{ value.edits.edit_for_bot_threshold }}</span>
														<span v-if="value.data.balance / 200 >= $store.state.noxSystemSettings.bot_threshold">
															<button type="button" class="nox_button nox_is_desktop last blue" @click="editBotThreshold(index, 'edit')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
															<button type="button" class="nox_button nox_is_mobile last blue" @click="editBotThreshold(index, 'edit')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														</span>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_alert big" v-html="value.alerts.alert_for_bot_threshold"></div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 1 && [1,2].includes($store.state.noxAccountData.group)">
											<div class="nox_account_desktop_bot_settings_label">
												<span>Чистый лист</span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Чистый лист</b> - опция, определяющая то, будет ли производиться автоматическая <b>просушка</b> (закрытие всех сделок) каждую пятницу вечером (перед закрытием торгов). Сделки будут закрываться только в том случае, если текущая неделя оказалась достаточно прибыльной.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.friday_drops)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="botFridayDropsToggleChange($event, index, value.data.id)"></toggle-button>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 1 && ($store.state.noxBotsStrategies[value.data.strategy_id].is_insomnia || (value.data.strategy_id == 5 && $store.state.noxAccountData.package > 5) || $store.state.noxAccountData.group == 1) && ($store.state.noxAccountData.package > 4 || value.data.is_perk_insomnia)">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 12)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 44), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_insomnia)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="botIsInsomniaToggleChange($event, index, value.data.id)"></toggle-button>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 1 && [1,2].includes($store.state.noxAccountData.group)">
											<div class="nox_account_desktop_bot_settings_label">
												<span>Показывать info</span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Показывать info</b> - если опция активирована, то на графике Meta Trader будут выведен дополнительный информационный блок.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_show_info)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="botIsShowInfoToggleChange($event, index, value.data.id)"></toggle-button>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 1 && [1,2].includes($store.state.noxAccountData.group)">
											<div class="nox_account_desktop_bot_settings_label">
												<span>Шифровать индексы</span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Шифровать индексы</b> - шифрование данных при передаче данных из облака.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_encrypt_comments)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="botIsEncryptCommentsToggleChange($event, index, value.data.id)"></toggle-button>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 1 && [1,2].includes($store.state.noxAccountData.group)">
											<div class="nox_account_desktop_bot_settings_label">
												<span>Синхронизация с CLV</span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: '<b>Синхронизация с CLV</b> позволит нашим специалистам напрямую влиять на то, как бот NOX будет вести торговлю.', trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_use_clv)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="botIsUseCLVToggleChange($event, index, value.data.id)"></toggle-button>
											</div>
										</div>
										<!--div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 13)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 16), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_content">
													<span>{{ $parent.$parent.getNumberFormatForPercent(value.data.balance_profitability, 2) }}</span>
													<span class="red" v-if="value.data.balance_profitability < 0"><font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'exclamation-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 119), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" /><span class="margin_left_8" v-html="$store.getters.getLanguageText('3.3', 16)"></span></span>
												</div>
											</div>
										</div-->
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.count_of_working_days >= 35 && ((value.data.avg_percentage_of_daily_profit_for_365d * 30 * 12) >= 10)">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 32)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 206), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value" v-html="$store.getters.getLanguageText('3.4.3', 33, { percent: $parent.$parent.getNumberFormatForPercent((value.data.avg_percentage_of_daily_profit_for_365d * 30 * 12), 2) })"></div>
										</div>
										<div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 14)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 50), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar((value.data.deposits / 100), 2) }}</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 15)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 51), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">{{ $parent.$parent.getNumberFormatForDollar((value.data.withdrawals / 100), 2) }}</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 16)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 52), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_content">
													<span class="margin_right_10">{{ $parent.$parent.getNumberFormatForDollar((value.data.balance / 100), 2) }}</span>
													<a :href="$store.getters.getLanguageText('3.4.3', 27)" class="nox_account_desktop_bot_settings_value_tooltip" target="_blank" v-if="value.data.number && value.data.strategy_id"><font-awesome-icon :icon="['fas', 'question-circle']" /><span v-html="$store.getters.getLanguageText('3.4.3', 26)"></span></a>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_rows" :class="{ rows_bg: value.data.is_modifier_symbols }" v-if="value.data.strategy_id && value.data.strategy_type == 2 && [1,2,10,40,41,100,107,109,110,112,119,124,455,567,795,846,1217,1236,1252,1253,1314,1374,1378,1402,2337,2601].includes($store.state.noxAccountData.id)">
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 43)"></span>
													<font-awesome-icon class="nox_tooltip_icon red" :icon="['fas', 'exclamation-triangle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 225), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_modifier_symbols)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsModifierSymbolsToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_modifier_symbols">
												<div class="nox_account_desktop_bot_settings_block">
													<div class="nox_account_desktop_bot_settings_label">
														<span v-html="$store.getters.getLanguageText('3.4.3', 44)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 226), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_bot_settings_value">
														<div v-if="value.is_edits.is_edit_for_bot_symbol_prefix">
															<form>
																<input type="text" class="nox_input small" v-model.trim="value.edits.edit_for_bot_symbol_prefix" :placeholder="$store.getters.getLanguageText('3.4.3', 45)" autocomplete="off">
																<span class="nox_account_desktop_bot_settings_value_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_symbol_prefix }"><img src="@/assets/images/loading.gif"></span>
																<span class="nox_account_desktop_bot_settings_value_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_symbol_prefix }">
																	<button type="button" class="nox_button nox_is_desktop red" @click="editBotSymbolPrefix(index, 'cancel')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
																	<button type="button" class="nox_button nox_is_mobile red" @click="editBotSymbolPrefix(index, 'cancel')"><font-awesome-icon :icon="['fas', 'times']" /></button>
																	<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editBotSymbolPrefix', index, value.data.id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
																	<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editBotSymbolPrefix', index, value.data.id)"><font-awesome-icon :icon="['fas', 'check']" /></button>
																</span>
															</form>
														</div>
														<div class="nox_account_desktop_bot_settings_value_content" v-else>
															<span class="margin_right_10"><span v-if="value.edits.edit_for_bot_symbol_prefix">{{ value.edits.edit_for_bot_symbol_prefix }}</span><span class="uppercase" v-else v-html="$store.getters.getLanguageText('3.4.3', 22)"></span></span>
															<button type="button" class="nox_button nox_is_desktop last blue" @click="editBotSymbolPrefix(index, 'edit')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
															<button type="button" class="nox_button nox_is_mobile last blue" @click="editBotSymbolPrefix(index, 'edit')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														</div>
													</div>
												</div>
												<div class="nox_account_desktop_bot_settings_alert big" v-html="value.alerts.alert_for_bot_symbol_prefix"></div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_modifier_symbols">
												<div class="nox_account_desktop_bot_settings_block">
													<div class="nox_account_desktop_bot_settings_label">
														<span v-html="$store.getters.getLanguageText('3.4.3', 46)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 227), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_bot_settings_value">
														<div v-if="value.is_edits.is_edit_for_bot_symbol_postfix">
															<form>
																<input type="text" class="nox_input small" v-model.trim="value.edits.edit_for_bot_symbol_postfix" :placeholder="$store.getters.getLanguageText('3.4.3', 47)" autocomplete="off">
																<span class="nox_account_desktop_bot_settings_value_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_symbol_postfix }"><img src="@/assets/images/loading.gif"></span>
																<span class="nox_account_desktop_bot_settings_value_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_symbol_postfix }">
																	<button type="button" class="nox_button nox_is_desktop red" @click="editBotSymbolPostfix(index, 'cancel')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
																	<button type="button" class="nox_button nox_is_mobile red" @click="editBotSymbolPostfix(index, 'cancel')"><font-awesome-icon :icon="['fas', 'times']" /></button>
																	<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editBotSymbolPostfix', index, value.data.id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
																	<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editBotSymbolPostfix', index, value.data.id)"><font-awesome-icon :icon="['fas', 'check']" /></button>
																</span>
															</form>
														</div>
														<div class="nox_account_desktop_bot_settings_value_content" v-else>
															<span class="margin_right_10"><span v-if="value.edits.edit_for_bot_symbol_postfix">{{ value.edits.edit_for_bot_symbol_postfix }}</span><span class="uppercase" v-else v-html="$store.getters.getLanguageText('3.4.3', 22)"></span></span>
															<button type="button" class="nox_button nox_is_desktop last blue" @click="editBotSymbolPostfix(index, 'edit')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
															<button type="button" class="nox_button nox_is_mobile last blue" @click="editBotSymbolPostfix(index, 'edit')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														</div>
													</div>
												</div>
												<div class="nox_account_desktop_bot_settings_alert big" v-html="value.alerts.alert_for_bot_symbol_postfix"></div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 2 && [1,2,10,40,41,100,107,109,110,112,119,124,455,567,795,846,1217,1236,1252,1253,1314,1374,1378,1402,2337,2601].includes($store.state.noxAccountData.id)">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 38)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 219), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_classic_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsClassicTradingToggleChange($event, index, value.data.id)"></toggle-button>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_rows" :class="{ rows_bg: value.data.is_news_trading && [1,10,40,41,100,107,109,110,112,307,471].includes($store.state.noxAccountData.id) }" v-if="value.data.strategy_id && value.data.strategy_type == 2 && [1,2,10,40,41,100,107,109,110,112,119,124,307,455,567,471,795,846,1217,1236,1252,1253,1314,1374,1378,1402,2337,2601].includes($store.state.noxAccountData.id)">
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 39)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 220), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<div class="nox_account_desktop_bot_settings_value_content">
														<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsTradingToggleChange($event, index, value.data.id)"></toggle-button>
														<span class="red" v-if="value.data.is_news_trading && compareBotFullnameCompany(value.data.fullname_company)"><span class="margin_left_10" v-html="$store.getters.getLanguageText('3.4.3', 48)"></span><font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'exclamation-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 228), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" /></span>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10,100].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 56)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 238), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_trading_trailing_pro)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsTradingTrailingPROToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 49)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 229), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_stp_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsSTPTradingToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10,107,110,112,307,471].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 42)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 224), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_test_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsTestTradingToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 51)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 231), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_trading_in_one_direction)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsTradingInOneDirectionToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10,40,41,100].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 50)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 230), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_postponed_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsPostponedTradingToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10,40,41,100].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 57)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 239), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_r2_stars_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsR2StarsTradingToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10,100,109].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 55)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 237), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_news_trading_broker_check)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsNewsTradingBrokerCheckToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_news_trading && [1,10].includes($store.state.noxAccountData.id)">
												<div class="nox_account_desktop_bot_settings_block">
													<div class="nox_account_desktop_bot_settings_label">
														<span v-html="$store.getters.getLanguageText('3.4.3', 52)"></span>
														<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 236), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
													</div>
													<div class="nox_account_desktop_bot_settings_value">
														<div v-if="value.is_edits.is_edit_for_bot_max_count_of_nt_symbols">
															<form>
																<input type="text" class="nox_input small" v-model.trim="value.edits.edit_for_bot_max_count_of_nt_symbols" :placeholder="$store.getters.getLanguageText('3.4.3', 53)" autocomplete="off">
																<span class="nox_account_desktop_bot_settings_value_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_max_count_of_nt_symbols }"><img src="@/assets/images/loading.gif"></span>
																<span class="nox_account_desktop_bot_settings_value_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_max_count_of_nt_symbols }">
																	<button type="button" class="nox_button nox_is_desktop red" @click="editBotMaxCountNTSymbols(index, 'cancel')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
																	<button type="button" class="nox_button nox_is_mobile red" @click="editBotMaxCountNTSymbols(index, 'cancel')"><font-awesome-icon :icon="['fas', 'times']" /></button>
																	<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editBotMaxCountNTSymbols', index, value.data.id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
																	<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editBotMaxCountNTSymbols', index, value.data.id)"><font-awesome-icon :icon="['fas', 'check']" /></button>
																</span>
															</form>
														</div>
														<div class="nox_account_desktop_bot_settings_value_content" v-else>
															<span class="margin_right_10"><span v-if="value.edits.edit_for_bot_max_count_of_nt_symbols">{{ value.edits.edit_for_bot_max_count_of_nt_symbols }}</span><span class="uppercase" v-else v-html="$store.getters.getLanguageText('3.4.3', 54)"></span></span>
															<button type="button" class="nox_button nox_is_desktop last blue" @click="editBotMaxCountNTSymbols(index, 'edit')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
															<button type="button" class="nox_button nox_is_mobile last blue" @click="editBotMaxCountNTSymbols(index, 'edit')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														</div>
													</div>
												</div>
												<div class="nox_account_desktop_bot_settings_alert big" v-html="value.alerts.alert_for_bot_max_count_of_nt_symbols"></div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_rows" :class="{ rows_bg: value.data.is_zigzag_trading }" v-if="value.data.strategy_id && value.data.strategy_type == 2 && [1,2,10,40,41,100,112].includes($store.state.noxAccountData.id)">
											<div class="nox_account_desktop_bot_settings_row">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 40)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 221), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_zigzag_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsZigzagTradingToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_zigzag_trading">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 42)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 240), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_zigzag_test_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsZigzagTestTradingToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_row" v-if="value.data.is_zigzag_trading">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 41)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 223), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_zigzag_news_trading)" :sync="true" :width="42" :height="26" :color="{ checked: '#5093CB' }" @change="botIsZigzagNewsTradingToggleChange($event, index, value.data.id)"></toggle-button>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 2">
											<div class="nox_account_desktop_bot_settings_block">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 36)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 216), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<span class="margin_right_10"><toggle-button class="nox_toggle_button" :value="toggleValue(value.data.is_compound_interest)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="botIsCompoundInterestToggleChange($event, index, value.data.id)"></toggle-button></span>
													<a :href="$store.getters.getLanguageText('3.4.3', 37)" class="nox_account_desktop_bot_settings_value_tooltip" target="_blank"><font-awesome-icon :icon="['fas', 'question-circle']" /><span v-html="$store.getters.getLanguageText('3.4.3', 28)"></span></a>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_alert" v-html="value.alerts.alert_for_bot_is_compound_interest"></div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 2 && value.data.is_compound_interest && $store.state.noxAccountData.group == 1">
											<div class="nox_account_desktop_bot_settings_block">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 24)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 176), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<div v-if="value.is_edits.is_edit_for_bot_funding_limit">
														<form>
															<input type="text" class="nox_input small" v-model.trim="value.edits.edit_for_bot_funding_limit" :placeholder="$store.getters.getLanguageText('3.4.3', 25)" autocomplete="off">
															<span class="nox_account_desktop_bot_settings_value_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_funding_limit }"><img src="@/assets/images/loading.gif"></span>
															<span class="nox_account_desktop_bot_settings_value_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_funding_limit }">
																<button type="button" class="nox_button nox_is_desktop red" @click="editBotFundingLimit(index, 'cancel')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
																<button type="button" class="nox_button nox_is_mobile red" @click="editBotFundingLimit(index, 'cancel')"><font-awesome-icon :icon="['fas', 'times']" /></button>
																<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editBotFundingLimit', index, value.data.id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
																<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editBotFundingLimit', index, value.data.id)"><font-awesome-icon :icon="['fas', 'check']" /></button>
															</span>
														</form>
													</div>
													<div v-else>
														<span class="margin_right_10"><span v-if="value.edits.edit_for_bot_funding_limit">${{ Number(value.edits.edit_for_bot_funding_limit).toFixed(2) }}</span><span class="uppercase" v-else v-html="$store.getters.getLanguageText('3.4.3', 22)"></span></span>
														<button type="button" class="nox_button nox_is_desktop last blue margin_right_10" @click="((Object.keys(value.data.strategy_settings).length && value.data.strategy_settings.a) ? $modal.show('NoxModalBotSettings', { type: 'editBotFundingLimitWithChart', index: index, id: value.data.id }) : editBotFundingLimit(index, 'edit'))" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
														<button type="button" class="nox_button nox_is_mobile last blue margin_right_10" @click="((Object.keys(value.data.strategy_settings).length && value.data.strategy_settings.a) ? $modal.show('NoxModalBotSettings', { type: 'editBotFundingLimitWithChart', index: index, id: value.data.id }) : editBotFundingLimit(index, 'edit'))"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
														<a :href="$store.getters.getLanguageText('3.4.3', 29)" class="nox_account_desktop_bot_settings_value_tooltip" target="_blank"><font-awesome-icon :icon="['fas', 'question-circle']" /><span v-html="$store.getters.getLanguageText('3.4.3', 28)"></span></a>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_alert big" v-html="value.alerts.alert_for_bot_funding_limit"></div>
										</div>
										<div class="nox_account_desktop_bot_settings_row" v-if="value.data.strategy_id && value.data.strategy_type == 2">
											<div class="nox_account_desktop_bot_settings_block">
												<div class="nox_account_desktop_bot_settings_label">
													<span v-html="$store.getters.getLanguageText('3.4.3', 19)"></span>
													<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 174), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
												</div>
												<div class="nox_account_desktop_bot_settings_value">
													<div v-if="value.is_edits.is_edit_for_bot_purpose_of_account">
														<form>
															<input type="text" class="nox_input small" v-model.trim="value.edits.edit_for_bot_purpose_of_account" :placeholder="$store.getters.getLanguageText('3.4.3', 20)" autocomplete="off">
															<span class="nox_account_desktop_bot_settings_value_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_purpose_of_account }"><img src="@/assets/images/loading.gif"></span>
															<span class="nox_account_desktop_bot_settings_value_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_purpose_of_account }">
																<button type="button" class="nox_button nox_is_desktop red" @click="editBotPurposeOfAccount(index, 'cancel')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
																<button type="button" class="nox_button nox_is_mobile red" @click="editBotPurposeOfAccount(index, 'cancel')"><font-awesome-icon :icon="['fas', 'times']" /></button>
																<button type="submit" class="nox_button nox_is_desktop last green" @click="submit($event, 'editBotPurposeOfAccount', index, value.data.id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
																<button type="submit" class="nox_button nox_is_mobile last green" @click="submit($event, 'editBotPurposeOfAccount', index, value.data.id)"><font-awesome-icon :icon="['fas', 'check']" /></button>
															</span>
														</form>
													</div>
													<div class="nox_account_desktop_bot_settings_value_content" v-else>
														<span class="margin_right_10"><span v-if="value.edits.edit_for_bot_purpose_of_account">${{ Number(value.edits.edit_for_bot_purpose_of_account).toFixed(2) }}</span><span class="uppercase" v-else v-html="$store.getters.getLanguageText('3.4.3', 21)"></span></span>
														<button type="button" class="nox_button nox_is_desktop last blue" @click="editBotPurposeOfAccount(index, 'edit')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
														<button type="button" class="nox_button nox_is_mobile last blue" @click="editBotPurposeOfAccount(index, 'edit')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_bot_settings_alert big" v-html="value.alerts.alert_for_bot_purpose_of_account"></div>
										</div>
										<div class="nox_account_desktop_bot_settings_row">
											<div class="nox_account_desktop_bot_settings_label">
												<span v-html="$store.getters.getLanguageText('3.4.3', 17)"></span>
												<font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 120), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" />
											</div>
											<div class="nox_account_desktop_bot_settings_value">
												<div class="nox_account_desktop_bot_settings_value_content">
													<span class="margin_right_10" v-if="value.data.comment">
														<span v-if="value.is_shows.is_show_for_bot_comment" v-html="value.data.comment.replace(/\n/g, '<br>')"></span>
														<span v-else>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</span>
													</span>
													<span class="nox_account_desktop_bot_settings_value_buttons">
														<button type="button" class="nox_button nox_is_desktop orange" @click="value.is_shows.is_show_for_bot_comment = !value.is_shows.is_show_for_bot_comment" v-if="value.data.comment" v-html="$store.getters.getLanguageText('1.1', (value.is_shows.is_show_for_bot_comment ? 131 : 130))"></button>
														<button type="button" class="nox_button nox_is_mobile orange" @click="value.is_shows.is_show_for_bot_comment = !value.is_shows.is_show_for_bot_comment" v-if="value.data.comment"><font-awesome-icon :icon="['fas', (value.is_shows.is_show_for_bot_comment ? 'eye-slash' : 'eye')]" /></button>
														<button type="button" class="nox_button nox_is_desktop last blue" @click="$modal.show('NoxModalBotSettings', { type: 'editBotComment', index: index, id: value.data.id })" v-html="$store.getters.getLanguageText('1.1', (value.data.comment ? 6 : 5))"></button>
														<button type="button" class="nox_button nox_is_mobile last blue" @click="$modal.show('NoxModalBotSettings', { type: 'editBotComment', index: index, id: value.data.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_portlet_content_alert" v-html="value.alerts.alert_for_bot_data"></div>
						</div>
						<div class="nox_account_desktop_portlet_loading" :class="{ hide: !value.is_loadings.is_loading_for_bot_data }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_portlet_buttons" :class="{ hide: value.is_loadings.is_loading_for_bot_data }">
							<button type="button" class="nox_button big violet" @click="$modal.show('NoxModalBotSettings', { type: (value.data.is_use_start ? 'startBot' : 'startBotDemo'), index: index, id: value.data.id })" v-if="!value.data.number && (!value.data.strategy_id || (value.data.strategy_id && value.data.strategy_type == 2)) && !value.data.is_nox_demo_bot && [1,2,10,40,41,42,100].includes($store.state.noxAccountData.id)"><font-awesome-icon :icon="['fas', 'play']" /><span v-html="$store.getters.getLanguageText('1.1', 162)"></span></button>
							<button type="button" class="nox_button big green" @click="(value.data.strategy_type == 2 ? ($modal.show('NoxModalBotSettings', { type: (value.data.is_use_start ? 'startBot' : 'startBotIntro'), index: index, id: value.data.id })) : (axios('startBot', index, value.data.id)))" v-if="value.data.number && value.data.strategy_id && value.data.strategy_type && (value.data.strategy_type == 1 || (value.data.strategy_type == 2 && value.data.behavior)) && !value.data.status"><font-awesome-icon :icon="['fas', 'play']" /><span v-html="$store.getters.getLanguageText('1.1', 15)"></span></button>
							<button type="button" class="nox_button big blue" @click="(value.data.strategy_type == 2 ? ($modal.show('NoxModalBotSettings', { type: 'pauseBot', index: index, id: value.data.id })) : (axios('pauseBot', index, value.data.id)))" v-else-if="value.data.number && value.data.strategy_id && value.data.strategy_type && (value.data.strategy_type == 1 || (value.data.strategy_type == 2 && value.data.behavior)) && value.data.status"><font-awesome-icon :icon="['fas', 'pause']" /><span v-html="$store.getters.getLanguageText('1.1', 16)"></span></button>
							<button type="button" class="nox_button big orange" @click="$modal.show('NoxModalBotSettings', { type: 'resetBot', index: index, id: value.data.id })" v-if="((value.data.number || value.data.strategy_id || value.data.comment) && (value.data.is_perk_free_bot || value.data.is_nox_basic_bot))"><font-awesome-icon :icon="['fas', 'sync-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 91)"></span></button>
							<button type="button" class="nox_button big red" @click="$modal.show('NoxModalBotSettings', { type: (value.data.is_perk_free_bot ? 'deletePerkBot' : 'deleteBot'), index: index, id: value.data.id })" v-if="!value.data.is_perk_free_bot || [1,2].includes($store.state.noxAccountData.group)"><font-awesome-icon :icon="['fas', 'trash']" /><span v-html="$store.getters.getLanguageText('1.1', 17)"></span></button>
							<div class="nox_dropdown" :class="{ open: $store.state.noxDropdownId == ('bot_trade_history_' + value.data.id) }" @blur="$parent.$parent.closeDropdown($event)" tabindex="-1">
								<div class="nox_dropdown_toggle transparent_white" @click="$parent.$parent.toggleDropdown('bot_trade_history_' + value.data.id)">
									<div class="nox_dropdown_toggle_value"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 169)"></span></div>
									<div class="nox_dropdown_toggle_button"><font-awesome-icon :icon="['fas', 'chevron-' + ($store.state.noxDropdownId == ('bot_trade_history_' + value.data.id) ? 'up' : 'down')]" /></div>
								</div>
								<div class="nox_dropdown_options">
									<div class="nox_dropdown_options_item" @click="$modal.show('NoxModalBotOrders', { type: 'getBotOrders', index: index, id: value.data.id })" v-if="value.data.fullname !== '' && value.data.strategy_type == 2" v-html="$store.getters.getLanguageText('1.1', 168)"></div>
									<div class="nox_dropdown_options_item" @click="$modal.show('NoxModalBotSettings', { type: 'getBotLogs', index: index, id: value.data.id })" v-html="$store.getters.getLanguageText('1.1', 122)"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_accordion" :class="{ violet: value.data.is_nox_demo_bot }" v-if="value.data.fullname !== ''">
						<div class="nox_account_desktop_portlet_accordion_loading" :class="{ hide: !value.is_shows.is_show_for_bot_details }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_portlet_accordion_button" :class="{ hide: value.is_shows.is_show_for_bot_details }" @click="axios('getBotStatisticsDetails', index, value.data.id)" v-if="!Object.keys(value.details).length">&#9660;&#160;<span v-html="$store.getters.getLanguageText('1.1', 18)"></span>&#160;&#9660;</div>
						<div class="nox_account_desktop_portlet_accordion_block" v-else>
							<div class="nox_account_desktop_portlet_accordion_button" @click="hideBotStatisticsDetails(index)">&#9650;&#160;<span v-html="$store.getters.getLanguageText('1.1', 19)"></span>&#160;&#9650;</div>
							<div class="nox_account_desktop_portlet_accordion_block_content">
								<div class="nox_account_desktop_portlet_accordion_block_content_description">
									<a :href="$store.getters.getLanguageText('3.4.5', 2)" class="button" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" /><div v-html="$store.getters.getLanguageText('3.4.5', 0)"></div></a><span v-html="$store.getters.getLanguageText('3.4.5', 1)"></span>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_1.series[0].data.length || value.details.details_chart_1.series[1].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_1"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 22)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_12.series[0].data.length || value.details.details_chart_12.series[1].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_12"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 38)"></div>
								</div>
								<div class="nox_chart hide" :id="'noxBotStatisticsDetailsChart9' + index">
									<div class="nox_chart_buttons">
										<div :class="{ active: value.details.details_chart_9_options.type == 1 }" @click="getBotStatisticsDetailsChart9(index, 1)" v-html="$store.getters.getLanguageText('1.6', 40, { options: [0] })"></div>
										<div :class="{ active: value.details.details_chart_9_options.type == 2 }" @click="getBotStatisticsDetailsChart9(index, 2)" v-html="$store.getters.getLanguageText('1.6', 40, { options: [1] })"></div>
									</div>
									<div class="nox_chart_highcharts" :id="'noxBotStatisticsDetailsChart9Highchart' + index"></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 23)"></div>
								</div>
								<div class="nox_chart columns" v-if="value.details.details_chart_10.series[0].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_10"></highcharts></div>
									<div class="nox_chart_description"><div v-html="$store.getters.getLanguageText('1.6.1', 21)"></div></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_2.series[0].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_2"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 24)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_3.series[0].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_3"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 25)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_4.series[0].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_4"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 26)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_5.series[0].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_5"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 27)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_6.series[0].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_6"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 28)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_7.series.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_7"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 29)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_8.series[0].data.length || value.details.details_chart_8.series[1].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_8"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 30)"></div>
								</div>
								<div class="nox_chart" v-if="value.details.details_chart_11.series[0].data.length || value.details.details_chart_11.series[1].data.length">
									<div class="nox_chart_highcharts"><highcharts :options="value.details.details_chart_11"></highcharts></div>
									<div class="nox_chart_description" v-html="$store.getters.getLanguageText('1.6.1', 34)"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_portlet" v-else>
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.4', 0)"></div>
				<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.4', 1)"></div>
			</div>
			<div class="nox_account_desktop_page_content_buttons">
				<button type="button" class="nox_button big violet" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalBotSettings' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'addBotDemo' : 'addMainBalance') })"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 165)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show('NoxModalKYCTests')" v-if="$store.state.noxSystemSettings.is_active_kyc_questions && (!$store.state.noxAccountData.is_kyc || ($store.state.noxAccountData.kyc_at < ($store.state.noxSystemSettings.time - ($store.state.noxSystemSettings.kyc_lifetime * 24 * 60 * 60))))"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 14)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalBotSettings' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'addBot' : 'addMainBalance') })" v-else-if="$store.state.noxMBotsData.length < $store.state.noxNumberOfFreeBots"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 14)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalBotSettings' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'buyBot' : 'addMainBalance') })" v-else-if="$store.state.noxMBotsData.length < $store.state.noxNumberOfBots"><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 14)"></span></button>
				<button type="button" class="nox_button big green" @click="$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalBotSettings' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'updatePackage' : 'addMainBalance') })" v-else><font-awesome-icon :icon="['fas', 'plus']" /><span v-html="$store.getters.getLanguageText('1.1', 14)"></span></button>
			</div>
			<div class="nox_tabs">
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_1" v-html="$store.getters.getLanguageText('3.4.2', 0, { options: [0] })"></label>
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_2" v-html="$store.getters.getLanguageText('3.4.2', 0, { options: [1] })"></label>
				<div class="nox_tab_block" id="nox_tab_block_1">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.4.2', 1)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div v-html="$store.getters.getLanguageText('3.4.2', 2)"></div>
							<div class="nox_account_desktop_portlet_content_video"><a href="https://www.youtube.com/watch?v=c_lJXTp4OGE" target="_blank"><div class="video"><img src="@/assets/images/video_2.png"><font-awesome-icon :icon="['fab', 'youtube']" /></div></a><span v-html="$store.getters.getLanguageText('3.4.2', 3, { options: [(noxSponsorData.forex4you ? 0 : 1)], forex4you: noxSponsorData.forex4you })"></span></div>
							<div v-html="$store.getters.getLanguageText('3.4.2', 4)"></div>
							<div v-html="$store.getters.getLanguageText('3.4.2', 6)"></div>
						</div>
					</div>
				</div>
				<div class="nox_tab_block" id="nox_tab_block_2">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.4.2', 1)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div v-html="$store.getters.getLanguageText('3.4.2', 2)"></div>
							<div class="nox_account_desktop_portlet_content_video"><a href="https://www.youtube.com/watch?v=kJGnH1uPOC4" target="_blank"><div class="video"><img src="@/assets/images/video_3.png"><font-awesome-icon :icon="['fab', 'youtube']" /></div></a><span v-html="$store.getters.getLanguageText('3.4.2', 3, { options: [(noxSponsorData.forex4you ? 0 : 1)], forex4you: noxSponsorData.forex4you })"></span></div>
							<div v-html="$store.getters.getLanguageText('3.4.2', 5)"></div>
							<div v-html="$store.getters.getLanguageText('3.4.2', 6)"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalErrors"></div>
				<div is="NoxModalKYCTests"></div>
				<div is="NoxModalRecaptcha"></div>
				<div is="NoxModalBotOrders"></div>
				<div is="NoxModalBotSettings"></div>
				<div is="NoxModalPackageExtend"></div>
				<div is="NoxModalPortraitTests"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import Highcharts from 'highcharts'
	export default {
		data: () => ({
			noxTemp: '',
			noxBotParamsKey: '',
			noxBotParamsType: 0,
			noxBotStrategyId: 0,
			noxBehaviorsColors: ['#44AD44', '#FFA500', '#FF4040'],
			noxPresetsSettings: {},
			noxSponsorData: {},
			noxIsTabMenu1Checked: true,
			noxIsTabMenu2Checked: false,
			noxIsAccount: false,
			noxIsLoading: true,
			noxIsError: false
		}),
		watch: {
			$route() {
				this.getBots();
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					this.noxIsAccount = true; setTimeout(this.isReady, 0); return false;
				}
				this.getBots();
			},
			getBots: function() {
				this.$parent.$parent.goToTop(true);
				this.$store.state.noxMBotsData = [];
				this.noxBotParamsKey = this.$route.params.key;
				this.noxBotParamsType = ((this.$route.params.type !== undefined && this.$route.params.type == 'crypto') ? 2 : 1);
				this.axios('getBots', !this.noxIsAccount ? 1 : 0);
			},
			getError: function(i, data) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 5); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 6); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 7); }
				else if (i == 9) { this.noxTemp = 'Необходимо указать порог остановки бота.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный порог остановки бота. Разрешено вводить только целые значения. Допустимое значение: от $' + this.$store.state.noxSystemSettings.bot_threshold + ' до $' + Math.floor(data.max) + '.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо выбрать чистый лист бота.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо выбрать верный чистый лист бота.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо выбрать показать info.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо выбрать верный показать info.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо выбрать шифрование сделок.'; }
				else if (i == 16) { this.noxTemp = 'Необходимо выбрать верное шифрование сделок.'; }
				else if (i == 17) { this.noxTemp = 'Необходимо выбрать синхронизацию с CLV.'; }
				else if (i == 18) { this.noxTemp = 'Необходимо выбрать верную синхронизацию с CLV.'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать статус запуска или паузы бота.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать верный статус запуска или паузы бота.'; }
				else if (i == 21) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 8); }
				else if (i == 22) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 9); }
				else if (i == 23) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 16); }
				else if (i == 24) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 17); }
				else if (i == 25) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 20); }
				else if (i == 26) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 23); }
				else if (i == 27) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 24); }
				else if (i == 28) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 25); }
				else if (i == 29) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 43); }
				else if (i == 30) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 44, data); }
				else if (i == 31) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 50); }
				else if (i == 32) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 51, data); }
				else if (i == 33) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 52); }
				else if (i == 34) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 53); }
				else if (i == 35) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 54); }
				else if (i == 36) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 55); }
				else if (i == 37) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 56); }
				else if (i == 38) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 57); }
				else if (i == 39) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 58); }
				else if (i == 40) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 59); }
				else if (i == 41) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 60); }
				else if (i == 42) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 61); }
				else if (i == 43) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 62); }
				else if (i == 44) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 74); }
				else if (i == 45) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 97); }
				else if (i == 46) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 98); }
				else if (i == 47) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 102); }
				else if (i == 48) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 105); }
				else if (i == 49) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 106); }
				else if (i == 50) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 107); }
				else if (i == 51) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 108); }
				else if (i == 52) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 109); }
				else if (i == 53) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 110, data); }
				else if (i == 54) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 111); }
				else if (i == 55) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 112); }
				else if (i == 56) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 113); }
				else if (i == 57) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 114); }
				else if (i == 58) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 115); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event, type, var1, var2, var3) {
				event.preventDefault(); this.axios(type, var1, var2, var3);
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.noxIsError = false;

					if (type == 'getBots') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/account/bots' + (_this.noxBotParamsKey !== undefined ? '/data/' + _this.noxBotParamsKey : '');
						config.params = { type: _this.noxBotParamsType, isAllData: var1 };
					}
					else if (type == 'getBotStatisticsDetails') {
						_this.$store.state.noxMBotsData[var1].is_shows.is_show_for_bot_details = true;
						config.url = '/v2/account/bots/statistics/details';
						config.params = { bot_id: var2 };
					}
					else if (type == 'startBot') {
						_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = '';
						_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_data = true;
						config.url = '/v2/account/bots/start';
						config.data = { id: var2 };
						config.method = 'patch';
					}
					else if (type == 'pauseBot') {
						_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = '';
						_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_data = true;
						config.url = '/v2/account/bots/pause';
						config.data = { id: var2 };
						config.method = 'patch';
					}
					else if (['editBotNumber', 'editBotBehavior', 'editBotMultiplier', 'editBotThreshold', 'editBotFridayDrops', 'editBotIsUseCLV', 'editBotIsShowInfo', 'editBotIsNewsTrading', 'editBotIsNewsSTPTrading', 'editBotIsNewsTestTrading', 'editBotIsNewsPostponedTrading', 'editBotIsNewsR2StarsTrading', 'editBotIsNewsTradingInOneDirection', 'editBotIsNewsTradingBrokerCheck', 'editBotIsNewsTradingTrailingPRO', 'editBotIsZigzagTrading', 'editBotIsZigzagTestTrading', 'editBotIsZigzagNewsTrading', 'editBotIsClassicTrading', 'editBotIsModifierSymbols', 'editBotIsEncryptComments', 'editBotIsCompoundInterest', 'editBotFundingLimit', 'editBotPurposeOfAccount', 'editBotMaxCountNTSymbols', 'editBotSymbolPrefix', 'editBotSymbolPostfix'].includes(type)) {
						_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = '';
						if (type == 'editBotNumber') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_number = '';
							if (!_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_number) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_number = _this.getError(3);
							}
							else if (!_this.$store.state.noxRegex_d.test(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_number) || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_number.length < 5 || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_number.length > 15) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_number = _this.getError(4);
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_number = true;
								config.url = '/v2/account/bots/number';
								config.data = { id: var2, number: _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_number };
								config.method = 'patch';
							}
						}
						else if (type == 'editBotBehavior') {
							config.url = '/v2/account/bots/behavior';
							config.data = { id: var2, behavior: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotMultiplier') {
							config.url = '/v2/account/bots/multiplier';
							config.data = { id: var2, multiplier: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotThreshold') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_threshold = '';
							if (!_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_threshold) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_threshold = _this.getError(9);
							}
							else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_threshold) || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_threshold < _this.$store.state.noxSystemSettings.bot_threshold || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_threshold > (_this.$store.state.noxMBotsData[var1].data.balance / 200)) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_threshold = _this.getError(10, { max: (_this.$store.state.noxMBotsData[var1].data.balance / 200) });
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_threshold = true;
								config.url = '/v2/account/bots/threshold';
								config.data = { id: var2, threshold: _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_threshold };
								config.method = 'patch';
							}
						}
						else if (type == 'editBotFridayDrops') {
							_this.$store.state.noxMBotsData[var1].data.friday_drops = var3;
							config.url = '/v2/account/bots/friday_drops';
							config.data = { id: var2, friday_drops: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsUseCLV') {
							_this.$store.state.noxMBotsData[var1].data.is_use_clv = var3;
							config.url = '/v2/account/bots/is_use_clv';
							config.data = { id: var2, is_use_clv: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsShowInfo') {
							_this.$store.state.noxMBotsData[var1].data.is_show_info = var3;
							config.url = '/v2/account/bots/is_show_info';
							config.data = { id: var2, is_show_info: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_news_trading = var3;
							config.url = '/v2/account/bots/is_news_trading';
							config.data = { id: var2, is_news_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsSTPTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_news_stp_trading = var3;
							config.url = '/v2/account/bots/is_news_stp_trading';
							config.data = { id: var2, is_news_stp_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsTestTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_news_test_trading = var3;
							config.url = '/v2/account/bots/is_news_test_trading';
							config.data = { id: var2, is_news_test_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsPostponedTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_news_postponed_trading = var3;
							config.url = '/v2/account/bots/is_news_postponed_trading';
							config.data = { id: var2, is_news_postponed_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsR2StarsTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_news_r2_stars_trading = var3;
							config.url = '/v2/account/bots/is_news_r2_stars_trading';
							config.data = { id: var2, is_news_r2_stars_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsTradingInOneDirection') {
							_this.$store.state.noxMBotsData[var1].data.is_news_trading_in_one_direction = var3;
							config.url = '/v2/account/bots/is_news_trading_in_one_direction';
							config.data = { id: var2, is_news_trading_in_one_direction: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsTradingBrokerCheck') {
							_this.$store.state.noxMBotsData[var1].data.is_news_trading_broker_check = var3;
							config.url = '/v2/account/bots/is_news_trading_broker_check';
							config.data = { id: var2, is_news_trading_broker_check: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsNewsTradingTrailingPRO') {
							_this.$store.state.noxMBotsData[var1].data.is_news_trading_trailing_pro = var3;
							config.url = '/v2/account/bots/is_news_trading_trailing_pro';
							config.data = { id: var2, is_news_trading_trailing_pro: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsZigzagTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_zigzag_trading = var3;
							config.url = '/v2/account/bots/is_zigzag_trading';
							config.data = { id: var2, is_zigzag_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsZigzagTestTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_zigzag_test_trading = var3;
							config.url = '/v2/account/bots/is_zigzag_test_trading';
							config.data = { id: var2, is_zigzag_test_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsZigzagNewsTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_zigzag_news_trading = var3;
							config.url = '/v2/account/bots/is_zigzag_news_trading';
							config.data = { id: var2, is_zigzag_news_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsClassicTrading') {
							_this.$store.state.noxMBotsData[var1].data.is_classic_trading = var3;
							config.url = '/v2/account/bots/is_classic_trading';
							config.data = { id: var2, is_classic_trading: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsModifierSymbols') {
							_this.$store.state.noxMBotsData[var1].data.is_modifier_symbols = var3;
							config.url = '/v2/account/bots/is_modifier_symbols';
							config.data = { id: var2, is_modifier_symbols: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsEncryptComments') {
							_this.$store.state.noxMBotsData[var1].data.is_encrypt_comments = var3;
							config.url = '/v2/account/bots/is_encrypt_comments';
							config.data = { id: var2, is_encrypt_comments: var3 };
							config.method = 'patch';
						}
						else if (type == 'editBotIsCompoundInterest') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_is_compound_interest = '';
							if (!var3 && _this.$store.state.noxMBotsData[var1].data.strategy_settings.a && _this.$store.state.noxMBotsData[var1].data.funds > _this.$store.state.noxMBotsData[var1].data.deposits) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_is_compound_interest = _this.getError(47);
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].data.is_compound_interest = var3;
								config.url = '/v2/account/bots/is_compound_interest';
								config.data = { id: var2, is_compound_interest: var3 };
								config.method = 'patch';
							}
						}
						else if (type == 'editBotFundingLimit') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_funding_limit = '';
							if (_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_funding_limit && !_this.$store.state.noxRegex_d.test(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_funding_limit)) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_funding_limit = _this.getError(33);
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_funding_limit = true;
								config.url = '/v2/account/bots/funding_limit';
								config.data = { id: var2, funding_limit: Number(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_funding_limit), is_check_sharp_decrease: 1 };
								config.method = 'patch';
							}
						}
						else if (type == 'editBotPurposeOfAccount') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_purpose_of_account = '';
							if (_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_purpose_of_account && (!_this.$store.state.noxRegex_d.test(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_purpose_of_account) || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_purpose_of_account < _this.$store.state.noxSystemSettings.purpose_of_account_min_amount || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_purpose_of_account > _this.$store.state.noxSystemSettings.purpose_of_account_max_amount)) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_purpose_of_account = _this.getError(30, { min: _this.$store.state.noxSystemSettings.purpose_of_account_min_amount, max: _this.$store.state.noxSystemSettings.purpose_of_account_max_amount });
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_purpose_of_account = true;
								config.url = '/v2/account/bots/purpose_of_account';
								config.data = { id: var2, purpose_of_account: Number(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_purpose_of_account) };
								config.method = 'patch';
							}
						}
						else if (type == 'editBotMaxCountNTSymbols') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_max_count_of_nt_symbols = '';
							if (_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_max_count_of_nt_symbols && (!_this.$store.state.noxRegex_d.test(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_max_count_of_nt_symbols) || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_max_count_of_nt_symbols < _this.$store.state.noxSystemSettings.max_count_of_news_trading_symbols_min || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_max_count_of_nt_symbols > _this.$store.state.noxSystemSettings.max_count_of_news_trading_symbols_max)) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_max_count_of_nt_symbols = _this.getError(53, { min: _this.$store.state.noxSystemSettings.max_count_of_news_trading_symbols_min, max: _this.$store.state.noxSystemSettings.max_count_of_news_trading_symbols_max });
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_max_count_of_nt_symbols = true;
								config.url = '/v2/account/bots/max_count_of_news_trading_symbols';
								config.data = { id: var2, max_count_of_news_trading_symbols: Number(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_max_count_of_nt_symbols) };
								config.method = 'patch';
							}
						}
						else if (type == 'editBotSymbolPrefix') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_prefix = '';
							if (_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_prefix && (!_this.$store.state.noxRegexENLettersAndNumbersAndMarks.test(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_prefix) || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_prefix.length > 10)) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_prefix = _this.getError(49);
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_symbol_prefix = true;
								config.url = '/v2/account/bots/symbol_prefix';
								config.data = { id: var2, symbol_prefix: _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_prefix };
								config.method = 'patch';
							}
						}
						else if (type == 'editBotSymbolPostfix') {
							_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_postfix = '';
							if (_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_postfix && (!_this.$store.state.noxRegexENLettersAndNumbersAndMarks.test(_this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_postfix) || _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_postfix.length > 10)) {
								_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_postfix = _this.getError(51);
							}
							if (!_this.noxIsError) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_symbol_postfix = true;
								config.url = '/v2/account/bots/symbol_postfix';
								config.data = { id: var2, symbol_postfix: _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_postfix };
								config.method = 'patch';
							}
						}
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getBots') {
									_this.$parent.$parent.goToTop(true);
									_this.$parent.$parent.checkAppVersion(data.headers);
									if (data.data.account_data !== undefined) {
										_this.$parent.$parent.initAccountData(data.data.account_data);
									}
									if (data.data.system_settings !== undefined) {
										_this.$parent.$parent.initSystemSettings(data.data.system_settings);
									}
									if (data.data.bots_masters !== undefined) {
										for (var i in data.data.bots_masters) {
											_this.$store.state.noxBotsMasters[data.data.bots_masters[i].id] = data.data.bots_masters[i];
										}
									}
									if (data.data.bots_strategies !== undefined) {
										if (data.data.bots_strategies.length) {
											for (var j in data.data.bots_strategies) {
												_this.$store.state.noxBotsStrategies[data.data.bots_strategies[j].id] = data.data.bots_strategies[j];
											}
											if (data.data.bots_strategies.length == 1) {
												_this.noxBotStrategyId = data.data.bots_strategies[0].id;
											}
										}
									}
									if (data.data.presets_settings !== undefined) {
										_this.noxPresetsSettings = data.data.presets_settings;
									}
									if (data.data.sponsor_data !== undefined) {
										_this.noxSponsorData = data.data.sponsor_data;
									}
									if (data.data.bots_data !== undefined) {
										_this.$parent.$parent.initBotsData(data.data.bots_data);
									}
								}
								else if (type == 'getBotStatisticsDetails') {
									if (data.data.bot_statistics_data !== undefined) {
										if (data.data.bot_statistics_details_1 !== undefined) {
											if (data.data.bot_statistics_details_2 !== undefined) {
												if (data.data.bot_statistics_details_3 !== undefined) {
													_this.$store.state.noxMBotsData[var1].details = {
														data: data.data.bot_statistics_data,
														details_1: data.data.bot_statistics_details_1,
														details_2: data.data.bot_statistics_details_2,
														details_3: data.data.bot_statistics_details_3,
														details_chart_1: {},
														details_chart_2: {},
														details_chart_3: {},
														details_chart_4: {},
														details_chart_5: {},
														details_chart_6: {},
														details_chart_7: {},
														details_chart_7_options: { threshold: 0 },
														details_chart_8: {},
														details_chart_9: {},
														details_chart_9_options: { type: 1, data: [], udata: {}, deposits: {}, withdrawals: {} },
														details_chart_10: {},
														details_chart_11: {},
														details_chart_12: {}
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_7_options.threshold = (-1 * Number(_this.$store.state.noxMBotsData[var1].details.data.strategy_settings.b) * ((Number(_this.$store.state.noxMBotsData[var1].details.data.is_compound_interest) > 0 && Number(_this.$store.state.noxMBotsData[var1].details.data.funding_limit) > 0 && Number(_this.$store.state.noxMBotsData[var1].details.data.balance) > 0 && ((Number(_this.$store.state.noxMBotsData[var1].details.data.funding_limit) * 100) < Number(_this.$store.state.noxMBotsData[var1].details.data.balance))) ? (Number(_this.$store.state.noxMBotsData[var1].details.data.funding_limit) * 100 / Number(_this.$store.state.noxMBotsData[var1].details.data.balance)) : 1)); _this.$store.state.noxMBotsData[var1].details.details_chart_7_options.threshold = _this.$store.state.noxMBotsData[var1].details.details_chart_7_options.threshold > -1 ? 0 : _this.$store.state.noxMBotsData[var1].details.details_chart_7_options.threshold;
													_this.$store.state.noxMBotsData[var1].details.details_chart_1 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 0) },
														legend: { enabled: true },
														series: [
															{ name: _this.$store.getters.getLanguageText('1.6.1', 1), color: '#7CB5EC', data: [] },
															{ name: _this.$store.getters.getLanguageText('1.6.1', 2), color: '#F45B5B', data: [] }
														]
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_2 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 3) },
														series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 4), color: '#1EC455', data: [] }]
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_3 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 5) },
														series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 6), color: '#F7A35C', data: [] }],
														tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 0); }}
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_4 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 7) },
														series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 8), color: '#808080', data: [] }]
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_5 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 9) },
														series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 10), color: '#3D96AE', data: [] }],
														tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }}
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_6 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 11) },
														chart: { type: 'line', height: 200, events: { load: function() { this.redraw(); }}},
														xAxis: { events: { afterSetExtremes: function(event) { _this.$parent.$parent.addChartAvgLine(event, var1, 6, {}); }}},
														yAxis: [{ id: 'chart_6_' + var1 + '_avg' }],
														series: [{ name: _this.$store.getters.getLanguageText('1.6.1', 12), color: '#A64CA6', data: [] }],
														tooltip: {
															shared: true, outside: true, padding: 8,
															formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }
														}
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_7 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 13) },
														chart: { type: 'line', height: 350, events: { load: function() { this.redraw(); }}},
														xAxis: { events: { afterSetExtremes: function(event) { _this.$parent.$parent.addChartLockThresholdLine(event, _this.$store.state.noxMBotsData[var1].details.details_chart_7_options.threshold, var1, 7, Number(_this.$store.state.noxMBotsData[var1].details.data.strategy_settings.a), 1); }}},
														yAxis: [{ id: 'chart_7_' + var1 + '_line', plotBands: [{ from: (Number(_this.$store.state.noxMBotsData[var1].details.data.strategy_settings.a) ? _this.$store.state.noxMBotsData[var1].details.details_chart_7_options.threshold : -100), to: -100, color: '#FF57221A' }] }],
														legend: { enabled: true }, series: [],
														tooltip: { shared: true, formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2); }}
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_8 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 14) },
														chart: { type: 'column', height: 300 },
														xAxis: { type: 'category', categories: [], labels: { enabled: false }},
														legend: { enabled: true },
														series: [
															{ name: _this.$store.getters.getLanguageText('1.6.1', 15), color: '#1EC455', data: [] },
															{ name: _this.$store.getters.getLanguageText('1.6.1', 16), color: '#F45B5B', data: [] }
														],
														plotOptions: {
															column: { stacking: 'normal', minPointLength: 2 },
															series: { pointPadding: 0.1, groupPadding: 0, borderWidth: 0 }
														}
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_10 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 20) },
														chart: { type: 'solidgauge', height: 200 },
														pane: {
															size: '140%', center: ['50%','75%'], startAngle: -90, endAngle: 90,
															background: { shape: 'arc', innerRadius: '60%', outerRadius: '100%' }
														},
														yAxis: {
															min: 0, max: 100, stops: [[0.1,'#55BF3B'],[0.5,'#DDDF0D'],[0.9,'#DF5353']],
															lineWidth: 0, tickWidth: 0, tickAmount: 2, minorTickInterval: null, labels: { y: 20 }
														},
														series: [{
															data: [0],
															dataLabels: { format: '<div style="text-align:center"><span style="font-size:15px">{y}%</span></div>' }
														}],
														tooltip: { enabled: false },
														plotOptions: { solidgauge: { dataLabels: { borderWidth: 0 }}}
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_11 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 31) },
														legend: { enabled: true },
														series: [
															{ name: _this.$store.getters.getLanguageText('1.6.1', 32), color: '#7CB5EC', data: [] },
															{ name: _this.$store.getters.getLanguageText('1.6.1', 33), color: '#F45B5B', data: [] }
														]
													};
													_this.$store.state.noxMBotsData[var1].details.details_chart_12 = {
														title: { text: _this.$store.getters.getLanguageText('1.6.1', 35) },
														chart: { type: 'column', height: 300 },
														series: [
															{ name: _this.$store.getters.getLanguageText('1.6.1', 36), color: '#1EC455', data: [] },
															{ name: _this.$store.getters.getLanguageText('1.6.1', 37), color: '#F45B5B', data: [] }
														],
														plotOptions: {
															column: { borderWidth: 0, groupPadding: 0, minPointLength: 2, pointPadding: 0.1 }
														}
													};
													if (_this.$store.state.noxMBotsData[var1].details.details_1.length) {
														var statistics1 = _this.$store.state.noxMBotsData[var1].details.details_1.reverse();
														var statisticsChart3Data = false;
														var statisticsChart5Data = false;
														var statisticsDepositLoad = 0;
														if (statistics1.length) {
															statistics1.push({
																id: 'new',
																user_id: statistics1[(statistics1.length - 1)].user_id,
																bot_id: statistics1[(statistics1.length - 1)].bot_id,
																balans_scheta: _this.$store.state.noxMBotsData[var1].details.data.balance,
																balance_drawdown: _this.$store.state.noxMBotsData[var1].details.data.drawdown,
																razmer_svobodnykh_sredstv_na_schete: _this.$store.state.noxMBotsData[var1].details.data.equity,
																uroven_zalogovykh_sredstv_v_protsentakh: _this.$store.state.noxMBotsData[var1].details.data.margin_level,
																znachenie_sobstvennykh_sredstv: _this.$store.state.noxMBotsData[var1].details.data.funds,
																percentage_profitable_orders: _this.$store.state.noxMBotsData[var1].details.data.profitable_orders,
																is_working_day: statistics1[(statistics1.length - 1)].is_working_day,
																count_of_orders: _this.$store.state.noxMBotsData[var1].details.data.count_of_orders,
																size_of_nox_orders: _this.$store.state.noxMBotsData[var1].details.data.size_of_nox_orders,
																size_of_other_orders: _this.$store.state.noxMBotsData[var1].details.data.size_of_other_orders,
																created_at: _this.$store.state.noxMBotsData[var1].details.data.online_at
															});
														}
														for (var s1 in statistics1) {
															var s1_created_at = parseInt(statistics1[s1].created_at) * 1000;
															_this.$store.state.noxMBotsData[var1].details.details_chart_1.series[0].data.push([s1_created_at, parseFloat((statistics1[s1].balans_scheta / 100).toFixed(2))]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_1.series[1].data.push([s1_created_at, parseFloat((statistics1[s1].znachenie_sobstvennykh_sredstv / 100).toFixed(2))]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_2.series[0].data.push([s1_created_at, parseFloat((statistics1[s1].razmer_svobodnykh_sredstv_na_schete / 100).toFixed(2))]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_3.series[0].data.push([s1_created_at, Number(s1) > 5 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) > 0 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) < 10000 ? parseInt(Math.floor(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh)) : null]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_4.series[0].data.push([s1_created_at, parseInt(statistics1[s1].count_of_orders)]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_5.series[0].data.push([s1_created_at, Number(statistics1[s1].percentage_profitable_orders) !== 0 ? parseFloat(statistics1[s1].percentage_profitable_orders) : null]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_6.series[0].data.push([s1_created_at, parseFloat(Number(statistics1[s1].balans_scheta) !== 0 ? (statistics1[s1].balance_drawdown / statistics1[s1].balans_scheta * 100).toFixed(2) : 0)]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_11.series[0].data.push([s1_created_at, parseFloat(statistics1[s1].size_of_nox_orders.toFixed(2))]);
															_this.$store.state.noxMBotsData[var1].details.details_chart_11.series[1].data.push([s1_created_at, parseFloat(statistics1[s1].size_of_other_orders.toFixed(2))]);
															if (_this.$store.state.noxMBotsData[var1].details.details_2.length) {
																var statistics2Profit = _this.$store.state.noxMBotsData[var1].details.details_2;
																if (statistics1[s1].created_at >= statistics2Profit[(statistics2Profit.length - 1)].created_at) {
																	var profit = Number(statistics1[s1].znachenie_sobstvennykh_sredstv);
																	for (var s2Profit in statistics2Profit) {
																		if (statistics1[s1].created_at >= statistics2Profit[s2Profit].created_at) {
																			if (statistics2Profit[s2Profit].type == 1) { profit -= Number(statistics2Profit[s2Profit].deposit); }
																			if (statistics2Profit[s2Profit].type == 2) { profit += Number(statistics2Profit[s2Profit].withdrawal); }
																		}
																	}
																	_this.$store.state.noxMBotsData[var1].details.details_chart_12.series[0].data.push([s1_created_at, (profit >= 0 ? parseFloat((profit / 100).toFixed(2)) : null)]);
																	_this.$store.state.noxMBotsData[var1].details.details_chart_12.series[1].data.push([s1_created_at, (profit < 0 ? parseFloat((profit / 100).toFixed(2)) : null)]);
																}
															}
															if (Number(s1) > 5 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) > 0 && Number(statistics1[s1].uroven_zalogovykh_sredstv_v_protsentakh) < 10000) { statisticsChart3Data = true; }
															if (Number(statistics1[s1].percentage_profitable_orders) !== 0) { statisticsChart5Data = true; }
															statisticsDepositLoad = 100 - (Number(statistics1[s1].balans_scheta) !== 0 ? (statistics1[s1].znachenie_sobstvennykh_sredstv / statistics1[s1].balans_scheta * 100) : 0);
															statisticsDepositLoad = statisticsDepositLoad > 100 ? 100 : statisticsDepositLoad;
														}
														if (!statisticsChart3Data) { _this.$store.state.noxMBotsData[var1].details.details_chart_3.series[0].data = []; }
														if (!statisticsChart5Data) { _this.$store.state.noxMBotsData[var1].details.details_chart_5.series[0].data = []; }
														_this.$store.state.noxMBotsData[var1].details.details_chart_10.series[0].data[0] = parseFloat(statisticsDepositLoad.toFixed(2));
													}
													if (_this.$store.state.noxMBotsData[var1].details.details_2.length) {
														var statistics2 = _this.$store.state.noxMBotsData[var1].details.details_2.reverse();
														var statistics2_UniqueData = {};
														var statistics2_1_1UniqueData = {};
														var statistics2_1_2UniqueData = {};
														var statistics2_2_1UniqueData = {};
														var statistics2_2_2UniqueData = {};
														for (var s2 in statistics2) {
															if (Number(statistics2[s2].type) == 1) {
																if (statistics2_1_1UniqueData[statistics2[s2].created_at] !== undefined) {
																	statistics2_1_1UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].deposit);
																	if (Number(statistics2[s2].number) > 0) {
																		statistics2_2_1UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].deposit);
																	}
																}
																else {
																	statistics2_1_1UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].deposit);
																	if (Number(statistics2[s2].number) > 0) {
																		statistics2_2_1UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].deposit);
																	}
																}
															}
															else if (Number(statistics2[s2].type) == 2) {
																if (statistics2_1_2UniqueData[statistics2[s2].created_at] !== undefined) {
																	statistics2_1_2UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].withdrawal);
																	if (Number(statistics2[s2].number) > 0) {
																		statistics2_2_2UniqueData[statistics2[s2].created_at] += Number(statistics2[s2].withdrawal);
																	}
																}
																else {
																	statistics2_1_2UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].withdrawal);
																	if (Number(statistics2[s2].number) > 0) {
																		statistics2_2_2UniqueData[statistics2[s2].created_at] = Number(statistics2[s2].withdrawal);
																	}
																}
															}
															if (statistics2_UniqueData[statistics2[s2].created_at] == undefined) {
																statistics2_UniqueData[statistics2[s2].created_at] = 1;
															}
															if (_this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories.indexOf(parseInt(statistics2[s2].created_at)) < 0) {
																_this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories.push(parseInt(statistics2[s2].created_at));
															}
														}
														for (var c1 in _this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories) {
															var v2_1_1 = statistics2_1_1UniqueData[_this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories[c1]];
															var v2_1_2 = statistics2_1_2UniqueData[_this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories[c1]];
															_this.$store.state.noxMBotsData[var1].details.details_chart_8.series[0].data.push((v2_1_1 ? parseFloat((v2_1_1 / 100).toFixed(2)) : null));
															_this.$store.state.noxMBotsData[var1].details.details_chart_8.series[1].data.push((v2_1_2 ? parseFloat((v2_1_2 / -100).toFixed(2)) : null));
														}
														for (var c2 in _this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories) {
															_this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories[c2] = Highcharts.dateFormat('%d.%m.%Y, %H:%M:%S', _this.$store.state.noxMBotsData[var1].details.details_chart_8.xAxis.categories[c2] * 1000);
														}
														if (_this.$store.state.noxMBotsData[var1].details.details_1.length > 1) {
															setTimeout(function() {
																_this.$store.state.noxMBotsData[var1].details.details_chart_9 = Highcharts.chart('noxBotStatisticsDetailsChart9Highchart' + var1, {
																	title: { text: _this.$store.getters.getLanguageText('1.6.1', 17) },
																	chart: { type: 'column', height: 300, events: { load: function() { this.redraw(); }}},
																	xAxis: { events: { afterSetExtremes: function(event) { _this.$parent.$parent.addChartAvgLine(event, var1, 9, { isCount: (_this.$store.state.noxMBotsData[var1].details.details_chart_9_options.type == 2 ? true : false) }); }}},
																	yAxis: [{ id: 'chart_9_' + var1 + '_avg' }],
																	legend: { enabled: true },
																	series: [
																		{ name: _this.$store.getters.getLanguageText('1.6.1', 18), color: '#1EC455', data: [] },
																		{ name: _this.$store.getters.getLanguageText('1.6.1', 19), color: '#F45B5B', data: [] }
																	],
																	tooltip: {
																		shared: true, outside: true, padding: 8,
																		formatter: function() { return _this.$parent.$parent.getChartFormatForPercent(this, 2, (_this.$store.state.noxMBotsData[var1].details.details_chart_9_options.type == 2 ? 2 : 0)); }
																	},
																	plotOptions: {
																		column: { stacking: 'normal', minPointLength: 2 },
																		series: { pointPadding: 0.1, groupPadding: 0, borderWidth: 0 }
																	}
																});
																_this.$store.state.noxMBotsData[var1].details.details_chart_9_options.type = ((_this.$parent.$parent.getNumberOfMonths((new Date(parseInt(_this.$store.state.noxMBotsData[var1].details.details_1[0].created_at) * 1000)), (new Date(parseInt(_this.$store.state.noxMBotsData[var1].details.details_1[(_this.$store.state.noxMBotsData[var1].details.details_1.length - 1)].created_at) * 1000))) > 3) ? 2 : 1);
																_this.$store.state.noxMBotsData[var1].details.details_chart_9_options.udata = statistics2_UniqueData;
																_this.$store.state.noxMBotsData[var1].details.details_chart_9_options.deposits = statistics2_2_1UniqueData;
																_this.$store.state.noxMBotsData[var1].details.details_chart_9_options.withdrawals = statistics2_2_2UniqueData;
																_this.initBotStatisticsDetailsChart9(var1);
															}, 300);
														}
													}
													if (_this.$store.state.noxMBotsData[var1].details.details_3.length) {
														var statistics3 = _this.$store.state.noxMBotsData[var1].details.details_3.reverse();
														var statistics3Indexs = [];
														var statistics3PrevData = [];
														if (_this.$store.state.noxMBotsData[var1].details.details_1.length) {
															var statistics3_0 = _this.$store.state.noxMBotsData[var1].details.details_1;
															for (var s3_0 in statistics3_0) {
																if (Number(statistics3_0[s3_0].created_at) >= Number(statistics3[0].created_at)) { break; }
																statistics3PrevData.push(statistics3_0[s3_0].created_at);
															}
														}
														for (var s3_1 in statistics3) {
															if (!statistics3Indexs.includes(statistics3[s3_1].index)) {
																statistics3Indexs.push(statistics3[s3_1].index);
															}
														}
														statistics3Indexs.sort();
														for (var s3_2 in statistics3Indexs) {
															_this.$store.state.noxMBotsData[var1].details.details_chart_7.series.push({ name: statistics3Indexs[s3_2], color: _this.$store.state.noxChartColors[s3_2], data: [] });
														}
														for (var s3_3 in statistics3) {
															if (!Number(s3_3)) {
																for (var s3_4 in statistics3PrevData) {
																	_this.$store.state.noxMBotsData[var1].details.details_chart_7.series[statistics3Indexs.indexOf(statistics3[s3_3].index)].data.push([parseInt(statistics3PrevData[s3_4]) * 1000, null]);
																}
															}
															_this.$store.state.noxMBotsData[var1].details.details_chart_7.series[statistics3Indexs.indexOf(statistics3[s3_3].index)].data.push([parseInt(statistics3[s3_3].created_at) * 1000, (Number(statistics3[s3_3].balance) !== 0 ? parseFloat((statistics3[s3_3].value / statistics3[s3_3].balance * 100).toFixed(2)) : null)]);
														}
													}
												}
											}
										}
									}
								}
								else if (['startBot', 'pauseBot'].includes(type)) {
									_this.$store.state.noxMBotsData[var1].data.status = (type == 'startBot' ? 1 : 0);
								}
								else if (type == 'editBotNumber') {
									_this.$store.state.noxMBotsData[var1].data.number = _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_number;
								}
								else if (type == 'editBotBehavior') {
									_this.$store.state.noxMBotsData[var1].data.behavior = var3;
									setTimeout(function() { _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_behavior_save_id = 2; }, 0);
								}
								else if (type == 'editBotMultiplier') {
									_this.$store.state.noxMBotsData[var1].data.multiplier = var3;
								}
								else if (type == 'editBotThreshold') {
									_this.$store.state.noxMBotsData[var1].data.threshold = _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_threshold;
								}
								else if (type == 'editBotFundingLimit') {
									_this.$store.state.noxMBotsData[var1].data.funding_limit = _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_funding_limit;
								}
								else if (type == 'editBotPurposeOfAccount') {
									_this.$store.state.noxMBotsData[var1].data.purpose_of_account = _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_purpose_of_account;
								}
								else if (type == 'editBotMaxCountNTSymbols') {
									_this.$store.state.noxMBotsData[var1].data.max_count_of_news_trading_symbols = _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_max_count_of_nt_symbols;
								}
								else if (type == 'editBotSymbolPrefix') {
									_this.$store.state.noxMBotsData[var1].data.symbol_prefix = _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_prefix;
								}
								else if (type == 'editBotSymbolPostfix') {
									_this.$store.state.noxMBotsData[var1].data.symbol_postfix = _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_symbol_postfix;
								}
							}
						}).catch(function(data) {
							if (['startBot', 'pauseBot', 'editBotNumber', 'editBotBehavior', 'editBotMultiplier', 'editBotThreshold', 'editBotFridayDrops', 'editBotIsUseCLV', 'editBotIsShowInfo', 'editBotIsNewsTrading', 'editBotIsNewsSTPTrading', 'editBotIsNewsTestTrading', 'editBotIsNewsPostponedTrading', 'editBotIsNewsR2StarsTrading', 'editBotIsNewsTradingInOneDirection', 'editBotIsNewsTradingBrokerCheck', 'editBotIsNewsTradingTrailingPRO', 'editBotIsZigzagTrading', 'editBotIsZigzagTestTrading', 'editBotIsZigzagNewsTrading', 'editBotIsClassicTrading', 'editBotIsModifierSymbols', 'editBotIsEncryptComments', 'editBotIsCompoundInterest', 'editBotFundingLimit', 'editBotPurposeOfAccount', 'editBotMaxCountNTSymbols', 'editBotSymbolPrefix', 'editBotSymbolPostfix'].includes(type)) {
								if (data.response !== undefined) {
									if (data.response.status == 400) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(2); }
										else if (data.response.data.error == 'NUMBER_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_number = _this.getError(3); }
										else if (data.response.data.error == 'NUMBER_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_number = _this.getError(4); }
										else if (data.response.data.error == 'STRATEGY_ID_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(5); }
										else if (data.response.data.error == 'STRATEGY_ID_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(6); }
										else if (data.response.data.error == 'BEHAVIOR_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(23); }
										else if (data.response.data.error == 'BEHAVIOR_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(24); }
										else if (data.response.data.error == 'MULTIPLIER_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(7); }
										else if (data.response.data.error == 'MULTIPLIER_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(8); }
										else if (data.response.data.error == 'THRESHOLD_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(9); }
										else if (data.response.data.error == 'THRESHOLD_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(10, { max: (_this.$store.state.noxMBotsData[var1].data.balance / 200) }); }
										else if (data.response.data.error == 'FRIDAY_DROPS_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(11); }
										else if (data.response.data.error == 'FRIDAY_DROPS_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(12); }
										else if (data.response.data.error == 'IS_SHOW_INFO_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(13); }
										else if (data.response.data.error == 'IS_SHOW_INFO_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(14); }
										else if (data.response.data.error == 'IS_ENCRYPT_COMMENTS_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(15); }
										else if (data.response.data.error == 'IS_ENCRYPT_COMMENTS_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(16); }
										else if (data.response.data.error == 'IS_COMPOUND_INTEREST_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(45); }
										else if (data.response.data.error == 'IS_COMPOUND_INTEREST_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(46); }
										else if (data.response.data.error == 'IS_USE_CLV_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(17); }
										else if (data.response.data.error == 'IS_USE_CLV_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(18); }
										else if (data.response.data.error == 'PURPOSE_OF_ACCOUNT_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_purpose_of_account = _this.getError(29); }
										else if (data.response.data.error == 'PURPOSE_OF_ACCOUNT_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_purpose_of_account = _this.getError(30, { min: _this.$store.state.noxSystemSettings.purpose_of_account_min_amount, max: _this.$store.state.noxSystemSettings.purpose_of_account_max_amount }); }
										else if (data.response.data.error == 'MAX_COUNT_OF_NEWS_TRADING_SYMBOLS_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_max_count_of_nt_symbols = _this.getError(52); }
										else if (data.response.data.error == 'MAX_COUNT_OF_NEWS_TRADING_SYMBOLS_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_max_count_of_nt_symbols = _this.getError(53, { min: _this.$store.state.noxSystemSettings.max_count_of_news_trading_symbols_min, max: _this.$store.state.noxSystemSettings.max_count_of_news_trading_symbols_max }); }
										else if (data.response.data.error == 'FUNDING_LIMIT_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_funding_limit = _this.getError(31); }
										else if (data.response.data.error == 'FUNDING_LIMIT_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_funding_limit = _this.getError(32, { min: data.response.data.min, max: data.response.data.max }); }
										else if (data.response.data.error == 'SYMBOL_PREFIX_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_prefix = _this.getError(48); }
										else if (data.response.data.error == 'SYMBOL_PREFIX_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_prefix = _this.getError(49); }
										else if (data.response.data.error == 'SYMBOL_POSTFIX_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_postfix = _this.getError(50); }
										else if (data.response.data.error == 'SYMBOL_POSTFIX_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_symbol_postfix = _this.getError(51); }
										else if (data.response.data.error == 'IS_CHECK_SHARP_DECREASE_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_funding_limit = _this.getError(34); }
										else if (data.response.data.error == 'IS_CHECK_SHARP_DECREASE_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_funding_limit = _this.getError(35); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(19); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(20); }
									}
									else if (data.response.status == 401) { _this.$parent.$parent.exit(); }
									else if (data.response.status == 403) { _this.$parent.$parent.exit(); }
									else if (data.response.status == 404) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(21); }
										else if (data.response.data.error == 'TELEGRAM_ID_NOT_FOUND') { _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_behavior = (Number(_this.$store.state.noxMBotsData[var1].data.behavior) ? _this.$parent.$parent.getSelectOptions(9)[(Number(_this.$store.state.noxMBotsData[var1].data.behavior) - 1)] : ''); _this.$modal.show('NoxModalBotSettings', { type: 'editBotBehavior' }); return false; }
									}
									else if (data.response.status == 409) {
										if      (data.response.data.error == 'NUMBER_DUPLICATE') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_number = _this.getError(22); }
										else if (data.response.data.error == 'EXPIRED_SUBSCRIPTION') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(25); }
										else if (data.response.data.error == 'NEGATIVE_BALANCE') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(26); }
										else if (data.response.data.error == 'ALREADY_RUNNING') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(27); }
										else if (data.response.data.error == 'ALREADY_STOPPED') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(28); }
										else if (data.response.data.error == 'ALREADY_DELETED') { _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(44); }
										else if (data.response.data.error == 'SHARP_DECREASE_FUNDING_LIMIT') { _this.noxIsError = true; _this.$modal.show('NoxModalBotSettings', { type: 'editBotFundingLimit', index: var1, id: var2 }); return false; }
										else if (data.response.data.error == 'IS_COMPOUND_INTEREST_CANNOT_BE_TURNED_OFF') { _this.$store.state.noxMBotsData[var1].data.is_compound_interest = 1; _this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_is_compound_interest = _this.getError(47); }
									}
								}
								if (!_this.noxIsError) {
									_this.$store.state.noxMBotsData[var1].alerts.alert_for_bot_data = _this.getError(0);
								}
							}
							else {
								_this.$parent.$parent.exit();
							}
						}).finally(function() {
							if (type == 'getBotStatisticsDetails') {
								_this.$store.state.noxMBotsData[var1].is_shows.is_show_for_bot_details = false;
							}
							else if (['startBot', 'pauseBot'].includes(type)) {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_data = false;
							}
							else if (type == 'editBotNumber') {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_number = false;
							}
							else if (type == 'editBotBehavior') {
								setTimeout(function() { _this.$store.state.noxMBotsData[var1].edits.edit_for_bot_behavior_save_id = 3; }, 100);
							}
							else if (type == 'editBotThreshold') {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_threshold = false;
								if (!_this.noxIsError) { _this.editBotThreshold(var1, 'save'); }
							}
							else if (type == 'editBotFundingLimit') {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_funding_limit = false;
								if (!_this.noxIsError) { _this.editBotFundingLimit(var1, 'save'); }
							}
							else if (type == 'editBotPurposeOfAccount') {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_purpose_of_account = false;
								if (!_this.noxIsError) { _this.editBotPurposeOfAccount(var1, 'save'); }
							}
							else if (type == 'editBotMaxCountNTSymbols') {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_max_count_of_nt_symbols = false;
								if (!_this.noxIsError) { _this.editBotMaxCountNTSymbols(var1, 'save'); }
							}
							else if (type == 'editBotSymbolPrefix') {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_symbol_prefix = false;
								if (!_this.noxIsError) { _this.editBotSymbolPrefix(var1, 'save'); }
							}
							else if (type == 'editBotSymbolPostfix') {
								_this.$store.state.noxMBotsData[var1].is_loadings.is_loading_for_bot_symbol_postfix = false;
								if (!_this.noxIsError) { _this.editBotSymbolPostfix(var1, 'save'); }
							}
							_this.noxIsLoading = false;
							_this.$store.state.noxIsAccount = false;
							_this.$store.state.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			compareBotFullname: function(fullname) {
				return ((this.$store.state.noxAccountData.fullname && (this.$parent.$parent.similarText(this.$store.state.noxAccountData.fullname, fullname, true) < Number(this.$store.state.noxSystemSettings.broker_fullname_trust_threshold))) ? true : false);
			},
			compareBotFullnameCompany: function(fullnameCompany) {
				var whiteBrokersListNT = String(this.$store.state.noxSystemSettings.news_trading_white_brokers_list).toLowerCase().split('|');
				return ((!fullnameCompany || !whiteBrokersListNT.length || !whiteBrokersListNT.includes(fullnameCompany)) ? true : false);
			},
			botBehaviorSelectChange: function(event, index, id) {
				this.$parent.$parent.closeCSelect();
				if (event.code !== undefined) {
					this.$store.state.noxMBotsData[index].edits.edit_for_bot_behavior = event;
					this.$store.state.noxMBotsData[index].edits.edit_for_bot_behavior_save_id = 1;
					this.axios('editBotBehavior', index, id, Number(event.code));
				}
			},
			botMultiplierRangeChange: function(index) {
				this.$store.state.noxMBotsData[index].info.risk_id = this.$parent.$parent.getRiskStrategies(this.$store.state.noxMBotsData[index].data.strategy_id, this.$store.state.noxMBotsData[index].data.masters, this.$store.state.noxMBotsData[index].edits.edit_for_bot_multiplier);
			},
			botMultiplierRangeDragEnd: function(index, id) {
				if (Number(this.$store.state.noxMBotsData[index].edits.edit_for_bot_multiplier) !== Number(this.$store.state.noxMBotsData[index].data.multiplier)) {
					this.axios('editBotMultiplier', index, id, Number(this.$store.state.noxMBotsData[index].edits.edit_for_bot_multiplier));
				}
			},
			botFridayDropsToggleChange: function(event, index, id) {
				this.axios('editBotFridayDrops', index, id, Number(event.value));
			},
			botIsUseCLVToggleChange: function(event, index, id) {
				this.axios('editBotIsUseCLV', index, id, Number(event.value));
			},
			botIsShowInfoToggleChange: function(event, index, id) {
				this.axios('editBotIsShowInfo', index, id, Number(event.value));
			},
			botIsNewsTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsTrading', index, id, Number(event.value));
			},
			botIsNewsSTPTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsSTPTrading', index, id, Number(event.value));
			},
			botIsNewsTestTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsTestTrading', index, id, Number(event.value));
			},
			botIsNewsPostponedTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsPostponedTrading', index, id, Number(event.value));
			},
			botIsNewsR2StarsTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsR2StarsTrading', index, id, Number(event.value));
			},
			botIsNewsTradingInOneDirectionToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsTradingInOneDirection', index, id, Number(event.value));
			},
			botIsNewsTradingBrokerCheckToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsTradingBrokerCheck', index, id, Number(event.value));
			},
			botIsNewsTradingTrailingPROToggleChange: function(event, index, id) {
				this.axios('editBotIsNewsTradingTrailingPRO', index, id, Number(event.value));
			},
			botIsZigzagTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsZigzagTrading', index, id, Number(event.value));
			},
			botIsZigzagTestTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsZigzagTestTrading', index, id, Number(event.value));
			},
			botIsZigzagNewsTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsZigzagNewsTrading', index, id, Number(event.value));
			},
			botIsClassicTradingToggleChange: function(event, index, id) {
				this.axios('editBotIsClassicTrading', index, id, Number(event.value));
			},
			botIsModifierSymbolsToggleChange: function(event, index, id) {
				this.axios('editBotIsModifierSymbols', index, id, Number(event.value));
			},
			botIsEncryptCommentsToggleChange: function(event, index, id) {
				this.axios('editBotIsEncryptComments', index, id, Number(event.value));
			},
			botIsCompoundInterestToggleChange: function(event, index, id) {
				this.axios('editBotIsCompoundInterest', index, id, Number(event.value));
			},
			botIsInsomniaToggleChange: function(event, index, id) {
				this.$modal.show('NoxModalBotSettings', { type: (Number(event.value) ? 'onBotInsomnia' : 'offBotInsomnia'), index: index, id: id });
			},
			editBotThreshold: function(index, type) {
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_data = '';
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_threshold = '';
				this.$store.state.noxMBotsData[index].edits.edit_for_bot_threshold = Number(['edit', 'save'].includes(type) ? this.$store.state.noxMBotsData[index].edits.edit_for_bot_threshold : this.$store.state.noxMBotsData[index].data.threshold);
				this.$store.state.noxMBotsData[index].is_edits.is_edit_for_bot_threshold = (type == 'edit' ? true : false);
			},
			editBotFundingLimit: function(index, type) {
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_data = '';
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_funding_limit = '';
				this.$store.state.noxMBotsData[index].edits.edit_for_bot_funding_limit = (['edit', 'save'].includes(type) ? this.$store.state.noxMBotsData[index].edits.edit_for_bot_funding_limit : (this.$store.state.noxMBotsData[index].data.funding_limit ? this.$store.state.noxMBotsData[index].data.funding_limit : ''));
				this.$store.state.noxMBotsData[index].is_edits.is_edit_for_bot_funding_limit = (type == 'edit' ? true : false);
			},
			editBotPurposeOfAccount: function(index, type) {
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_data = '';
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_purpose_of_account = '';
				this.$store.state.noxMBotsData[index].edits.edit_for_bot_purpose_of_account = (['edit', 'save'].includes(type) ? this.$store.state.noxMBotsData[index].edits.edit_for_bot_purpose_of_account : (this.$store.state.noxMBotsData[index].data.purpose_of_account ? this.$store.state.noxMBotsData[index].data.purpose_of_account : ''));
				this.$store.state.noxMBotsData[index].is_edits.is_edit_for_bot_purpose_of_account = (type == 'edit' ? true : false);
			},
			editBotMaxCountNTSymbols: function(index, type) {
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_data = '';
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_max_count_of_nt_symbols = '';
				this.$store.state.noxMBotsData[index].edits.edit_for_bot_max_count_of_nt_symbols = (['edit', 'save'].includes(type) ? this.$store.state.noxMBotsData[index].edits.edit_for_bot_max_count_of_nt_symbols : (this.$store.state.noxMBotsData[index].data.max_count_of_news_trading_symbols ? this.$store.state.noxMBotsData[index].data.max_count_of_news_trading_symbols : ''));
				this.$store.state.noxMBotsData[index].is_edits.is_edit_for_bot_max_count_of_nt_symbols = (type == 'edit' ? true : false);
			},
			editBotSymbolPrefix: function(index, type) {
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_data = '';
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_symbol_prefix = '';
				this.$store.state.noxMBotsData[index].edits.edit_for_bot_symbol_prefix = (['edit', 'save'].includes(type) ? this.$store.state.noxMBotsData[index].edits.edit_for_bot_symbol_prefix : (this.$store.state.noxMBotsData[index].data.symbol_prefix ? this.$store.state.noxMBotsData[index].data.symbol_prefix : ''));
				this.$store.state.noxMBotsData[index].is_edits.is_edit_for_bot_symbol_prefix = (type == 'edit' ? true : false);
			},
			editBotSymbolPostfix: function(index, type) {
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_data = '';
				this.$store.state.noxMBotsData[index].alerts.alert_for_bot_symbol_postfix = '';
				this.$store.state.noxMBotsData[index].edits.edit_for_bot_symbol_postfix = (['edit', 'save'].includes(type) ? this.$store.state.noxMBotsData[index].edits.edit_for_bot_symbol_postfix : (this.$store.state.noxMBotsData[index].data.symbol_postfix ? this.$store.state.noxMBotsData[index].data.symbol_postfix : ''));
				this.$store.state.noxMBotsData[index].is_edits.is_edit_for_bot_symbol_postfix = (type == 'edit' ? true : false);
			},
			hideBotStatisticsDetails: function(index) {
				this.$store.state.noxMBotsData[index].details = {};
			},
			initBotStatisticsDetailsChart9: function(index, data = [[],[]]) {
				if (this.$store.state.noxMBotsData[index].details.details_chart_9_options.type == 1) {
					if (this.$store.state.noxMBotsData[index].details.details_1.length > 1) {
						for (var i1 = 1; i1 < this.$store.state.noxMBotsData[index].details.details_1.length; i1++) {
							if (this.$store.state.noxMBotsData[index].details.details_1[i1].id !== 'new') {
								var isAddT1 = 1;
								var createdAtT1 = (parseInt(this.$store.state.noxMBotsData[index].details.details_1[i1].created_at) * 1000);
								var profitDayT1 = (((this.$store.state.noxMBotsData[index].details.details_1[i1].balans_scheta - this.$store.state.noxMBotsData[index].details.details_1[(i1 - 1)].balans_scheta) / this.$store.state.noxMBotsData[index].details.details_1[(i1 - 1)].balans_scheta) * 100);
								for (var j1 in this.$store.state.noxMBotsData[index].details.details_chart_9_options.udata) {
									if ((createdAtT1 >= (parseInt(j1) * 1000)) && ((createdAtT1 - (parseInt(j1) * 1000)) < (24 * 60 * 60 * 1000))) {
										isAddT1 = 0; break;
									}
								}
								if (isAddT1) {
									if (profitDayT1 > 0 && ((profitDayT1 < Number(this.$store.state.noxSystemSettings.bot_profit_day_max)) || (Number(this.$store.state.noxMBotsData[index].details.data.is_news_trading) > 0))) {
										data[0].push([createdAtT1, parseFloat(profitDayT1.toFixed(2))]);
									}
									if (profitDayT1 < 0 && ((profitDayT1 > Number(this.$store.state.noxSystemSettings.bot_profit_day_min)) || (Number(this.$store.state.noxMBotsData[index].details.data.is_news_trading) > 0))) {
										data[1].push([createdAtT1, parseFloat(profitDayT1.toFixed(2))]);
									}
								}
							}
						}
					}
				}
				else {
					var objSeries = {};
					if (this.$store.state.noxMBotsData[index].details.details_1.length > 1) {
						for (var i2 in this.$store.state.noxMBotsData[index].details.details_1) {
							if (this.$store.state.noxMBotsData[index].details.details_1[i2].id !== 'new') {
								var i2_date = (new Date(parseInt(this.$store.state.noxMBotsData[index].details.details_1[i2].created_at) * 1000));
								var i2_time = (new Date(i2_date.getFullYear(), (i2_date.getMonth() + 1), 0).getTime());
								if (Object.keys(objSeries).length || this.$store.state.noxMBotsData[index].details.details_1[i2].balans_scheta !== 0) {
									if (objSeries[i2_time] == undefined) { objSeries[i2_time] = { created_ats: [], balances: [], deposits: 0, withdrawals: 0 }; }
									objSeries[i2_time].created_ats.push(this.$store.state.noxMBotsData[index].details.details_1[i2].created_at);
									objSeries[i2_time].balances.push(this.$store.state.noxMBotsData[index].details.details_1[i2].balans_scheta);
								}
							}
						}
					}
					if (Object.keys(objSeries).length) {
						if (Object.keys(this.$store.state.noxMBotsData[index].details.details_chart_9_options.deposits).length) {
							for (var d2 in this.$store.state.noxMBotsData[index].details.details_chart_9_options.deposits) {
								var d2_date = (new Date(parseInt(d2) * 1000));
								var d2_time = (new Date(d2_date.getFullYear(), (d2_date.getMonth() + 1), 0).getTime());
								if (objSeries[d2_time] !== undefined && !(((new Date(d2_date).getDate()) == (new Date(d2_time).getDate())) && (Number(d2) > Number(objSeries[d2_time].created_ats[(objSeries[d2_time].created_ats.length - 1)])))) { objSeries[d2_time].deposits += this.$store.state.noxMBotsData[index].details.details_chart_9_options.deposits[d2];
								}
							}
						}
						if (Object.keys(this.$store.state.noxMBotsData[index].details.details_chart_9_options.withdrawals).length) {
							for (var w2 in this.$store.state.noxMBotsData[index].details.details_chart_9_options.withdrawals) {
								var w2_date = (new Date(parseInt(w2) * 1000));
								var w2_time = (new Date(w2_date.getFullYear(), (w2_date.getMonth() + 1), 0).getTime());
								if (objSeries[w2_time] !== undefined && !(((new Date(w2_date).getDate()) == (new Date(w2_time).getDate())) && (Number(w2) > Number(objSeries[w2_time].created_ats[(objSeries[w2_time].created_ats.length - 1)])))) { objSeries[w2_time].withdrawals += this.$store.state.noxMBotsData[index].details.details_chart_9_options.withdrawals[w2];
								}
							}
						}
						for (var j2 in objSeries) {
							if (objSeries[j2].balances.length > 1) {
								var countT2 = objSeries[j2].balances.length;
								var balanceT2_1 = objSeries[j2].balances[0];
								var balanceT2_2 = objSeries[j2].balances[(countT2 - 1)] - objSeries[j2].deposits + objSeries[j2].withdrawals;
								var balanceT2_3 = ((objSeries[j2].balances[0] + objSeries[j2].balances[(countT2 - 1)]) / 2);
								var profitDayT2 = ((objSeries[j2].deposits || objSeries[j2].withdrawals) ? (balanceT2_3 ? ((balanceT2_2 - balanceT2_1) / balanceT2_3 * 100) : 0) : (balanceT2_1 ? (((balanceT2_2 * 100) / balanceT2_1) - 100) : 0));
								if (profitDayT2 > 0) { data[0].push([parseInt(j2) - (27 * 24 * 60 * 60 * 1000), parseFloat(profitDayT2.toFixed(2))]); }
								if (profitDayT2 < 0) { data[1].push([parseInt(j2) - (27 * 24 * 60 * 60 * 1000), parseFloat(profitDayT2.toFixed(2))]); }
							}
						}
					}
				}
				if (data[0].length || data[1].length) {
					document.getElementById('noxBotStatisticsDetailsChart9' + index).classList.remove('hide');
				}
				this.$store.state.noxMBotsData[index].details.details_chart_9.series[0].update({ data: data[0] });
				this.$store.state.noxMBotsData[index].details.details_chart_9.series[1].update({ data: data[1] });
			},
			getBotStatisticsDetailsChart9: function(index, type) {
				this.$store.state.noxMBotsData[index].details.details_chart_9_options.type = type;
				this.initBotStatisticsDetailsChart9(index);
			}
		},
		components: {
			NoxModalErrors: () => import('@/views/modal_components/NoxModalErrors.vue'),
			NoxModalKYCTests: () => import('@/views/modal_components/NoxModalKYCTests.vue'),
			NoxModalRecaptcha: () => import('@/views/modal_components/NoxModalRecaptcha.vue'),
			NoxModalBotOrders: () => import('@/views/modal_components/NoxModalBotOrders.vue'),
			NoxModalBotSettings: () => import('@/views/modal_components/NoxModalBotSettings.vue'),
			NoxModalPackageExtend: () => import('@/views/modal_components/NoxModalPackageExtend.vue'),
			NoxModalPortraitTests: () => import('@/views/modal_components/NoxModalPortraitTests.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_bot_settings {
		position: relative;
		padding: 0 0 0 240px;
	}
	.nox_account_desktop_bot_settings_left {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		display: table;
		width: 240px;
		height: 100%;
		min-height: 310px;
	}
	.nox_account_desktop_bot_settings_cell {
		text-align: center;
		vertical-align: middle;
		display: table-cell;
	}
	.nox_account_desktop_bot_settings_logo {
		transition: all 0.3s;
		opacity: 1;
	}
	.nox_account_desktop_bot_settings_logo.bot_settings_logo_hide {
		opacity: 0;
	}
	.nox_account_desktop_bot_settings_risk {
		margin: 0 0 10px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
	}
	.nox_account_desktop_bot_settings_risk .bot_risk_1 {
		color: #CCCCCC;
	}
	.nox_account_desktop_bot_settings_risk .bot_risk_2 {
		color: #3CC363;
	}
	.nox_account_desktop_bot_settings_risk .bot_risk_3 {
		color: #FFB137;
	}
	.nox_account_desktop_bot_settings_risk .bot_risk_4,
	.nox_account_desktop_bot_settings_risk .bot_risk_insomnia {
		color: #FFBBBB;
	}
	.nox_account_desktop_bot_settings_risk img {
		max-width: 120px;
		max-height: 120px;
	}
	.nox_account_desktop_bot_settings_risk .bot_risk_phoenix img {
		max-width: none;
		max-height: none;
		height: 150px;
	}
	.nox_account_desktop_bot_settings_sticker .sticker_online,
	.nox_account_desktop_bot_settings_sticker .sticker_offline,
	.nox_account_desktop_bot_settings_sticker .sticker_overload,
	.nox_account_desktop_bot_settings_sticker .sticker_old_version,
	.nox_account_desktop_bot_settings_sticker .sticker_is_use_insomnia,
	.nox_account_desktop_bot_settings_sticker .sticker_is_alien,
	.nox_account_desktop_bot_settings_sticker .sticker_is_perk,
	.nox_account_desktop_bot_settings_sticker .sticker_is_gap {
		margin: 0 0 3px 0;
		border: 1px solid #FFFFFF;
		border-radius: 3px;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 24px;
		display: inline-block;
		width: 110px;
		height: 26px;
	}
	.nox_account_desktop_bot_settings_sticker .sticker_online {
		border-color: #00EA00;
		color: #00EA00;
	}
	.nox_account_desktop_bot_settings_sticker .sticker_offline,
	.nox_account_desktop_bot_settings_sticker .sticker_overload,
	.nox_account_desktop_bot_settings_sticker .sticker_old_version,
	.nox_account_desktop_bot_settings_sticker .sticker_is_alien,
	.nox_account_desktop_bot_settings_sticker .sticker_is_gap {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_account_desktop_bot_settings_sticker .sticker_is_use_insomnia {
		border-color: #A5E5FF;
		color: #A5E5FF;
	}
	.nox_account_desktop_bot_settings_sticker .sticker_is_perk {
		border-color: #FFC660;
		color: #FFC660;
	}
	.nox_account_desktop_bot_settings_prints {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 0.3s;
		display: table;
		width: 100%;
		height: 100%;
	}
	.nox_account_desktop_bot_settings_prints.bot_settings_prints_hide {
		top: 100%;
		margin: -95px 0 0 0;
		height: 0%;
	}
	.nox_account_desktop_bot_settings_prints.bot_settings_prints_hide .nox_account_desktop_bot_settings_prints_content {
		padding: 0;
	}
	.nox_account_desktop_bot_settings_prints_content {
		padding: 30px 0 0 0;
		vertical-align: middle;
		display: table-cell;
	}
	.nox_account_desktop_bot_settings_prints_content_block {
		position: relative;
		display: inline-block;
		width: 193px;
	}
	.nox_account_desktop_bot_settings_prints_content_button {
		position: absolute;
		top: -35px;
		left: 0;
		border-radius: 13.5px;
		cursor: pointer;
		display: none;
		width: 100%;
		height: 27px;
	}
	.nox_account_desktop_bot_settings_prints_content_button:hover {
		background: #BBBBBB33;
	}
	.nox_account_desktop_portlet.red .nox_account_desktop_bot_settings_prints_content_button:hover {
		background: #FFFFFF33;
	}
	.nox_account_desktop_bot_settings_prints_content_button.bot_settings_prints_count_more_7 {
		display: block;
	}
	.nox_account_desktop_bot_settings_prints_content_button svg {
		margin: 5px 0 0 0;
		font-size: 15px;
	}
	.nox_account_desktop_bot_settings_prints_content_items {
		display: table;
	}
	.nox_account_desktop_bot_settings_prints_content_item {
		float: left;
		margin: -4px -1px;
		background: url('../../assets/images/phoenix_icons.png') no-repeat;
		background-size: 2250px 50px;
		display: inline-block;
		width: 50px;
		height: 50px;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_1_1 {
		background-position: -950px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_1 {
		background-position: -1000px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_2 {
		background-position: -1100px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_3 {
		background-position: -1050px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_4 {
		background-position: -1250px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_5 {
		background-position: -1200px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_6 {
		background-position: -2050px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_7 {
		background-position: -2100px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_2_8 {
		background-position: -2200px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_3_1 {
		background-position: -1750px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_3_2 {
		background-position: -1150px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_3_3 {
		background-position: -1450px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_3_4 {
		background-position: -1500px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_3_5 {
		background-position: -1700px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_3_6 {
		background-position: -2150px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_4_1 {
		background-position: -1300px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_4_2 {
		background-position: -1350px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_4_3 {
		background-position: -1400px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_1 {
		background-position: -900px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_2 {
		background-position: -850px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_3 {
		background-position: -800px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_4 {
		background-position: -650px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_5 {
		background-position: -700px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_6 {
		background-position: -750px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_7 {
		background-position: 0 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_8 {
		background-position: -50px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_9 {
		background-position: -100px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_10 {
		background-position: -150px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_11 {
		background-position: -200px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_12 {
		background-position: -250px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_13 {
		background-position: -300px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_14 {
		background-position: -350px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_15 {
		background-position: -400px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_16 {
		background-position: -450px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_17 {
		background-position: -500px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_18 {
		background-position: -550px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_19 {
		background-position: -600px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_20 {
		background-position: -1550px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_21 {
		background-position: -1600px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_22 {
		background-position: -1650px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_23 {
		background-position: -1800px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_24 {
		background-position: -1850px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_25 {
		background-position: -1900px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_26 {
		background-position: -1950px 0;
	}
	.nox_account_desktop_bot_settings_prints_content_item.group_5_27 {
		background-position: -2000px 0;
	}
	.nox_account_desktop_bot_settings.phoenix_settings .nox_account_desktop_bot_settings_cell {
		padding: 0 0 120px 0;
	}
	.nox_account_desktop_bot_settings_rows.rows_bg {
		margin: 10px 0;
		padding: 10px 0;
		border: 1px solid #858585;
		border-radius: 15px;
		background: #3333334D;
	}
	.nox_account_desktop_portlet.violet .nox_account_desktop_bot_settings_rows.rows_bg {
		border-color: #AD75AD;
		background: #7711774D;
	}
	.nox_account_desktop_bot_settings_rows,
	.nox_account_desktop_bot_settings_row {
		position: relative;
		margin: 0 0 5px 0;
		line-height: 30px;
		display: table;
		width: 100%;
		min-height: 30px;
	}
	.nox_account_desktop_bot_settings_row:last-child {
		margin: 0;
	}
	.nox_account_desktop_bot_settings_label {
		position: absolute;
		text-align: right;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 30px;
		width: 200px;
		z-index: 1;
	}
	.nox_account_desktop_bot_settings_value {
		position: relative;
		padding: 0 0 0 230px;
	}
	.nox_account_desktop_bot_settings_value .nox_input {
		padding: 0 8px 2px 8px;
		margin: 2px 10px 2px 0;
		width: 100px;
	}
	.nox_account_desktop_bot_settings_value .nox_range_slider {
		position: absolute;
		top: 0;
		margin: 6px 0 0 35px;
	}
	.nox_account_desktop_bot_settings_value .nox_range_slider .vue-slider-rail {
		background: rgb(255,0,0);
		background: linear-gradient(90deg, rgba(0,128,0,1) 0%, rgba(255,255,255,1) 50%, rgba(255,104,104,1) 100%);
	}
	.nox_account_desktop_bot_settings_value .nox_range_slider .vue-slider-process,
	.nox_account_desktop_bot_settings_value .nox_range_slider:hover .vue-slider-process {
		background: none;
	}
	.nox_account_desktop_bot_settings_value_content {
		padding: 5px 0;
		line-height: 20px;
	}
	.nox_account_desktop_bot_settings_value_content .red {
		color: #FFBBBB;
	}
	.nox_account_desktop_bot_settings_value_content .nox_toggle_button {
		margin: -3px 0;
	}
	.nox_account_desktop_bot_settings_value_loading img {
		margin: -2px 0 0 0;
	}
	.nox_account_desktop_bot_settings_value_buttons .nox_button {
		margin: 0 10px 0 0;
	}
	.nox_account_desktop_bot_settings_value_buttons .nox_button.nox_is_mobile {
		margin: 0 5px 0 0;
	}
	.nox_account_desktop_bot_settings_value_buttons .nox_button.last {
		margin: 0;
	}
	.nox_account_desktop_bot_settings_value_behavior_select .nox_cselect {
		width: 100px;
	}
	.nox_account_desktop_bot_settings_value_behavior_select .nox_cselect .nox_cselect_toggle {
		padding: 0 30px 0 8px;
	}
	.nox_account_desktop_bot_settings_value_behavior_loading,
	.nox_account_desktop_bot_settings_value_behavior_result {
		float: left;
		margin: 0 0 0 10px;
		color: #00EA00;
		opacity: 0;
		font-size: 12px;
		line-height: 28px;
		display: inline-block;
		min-width: 75px;
	}
	.nox_account_desktop_bot_settings_value_behavior_result {
		margin: 0 0 0 -75px;
	}
	.nox_account_desktop_bot_settings_value_behavior_loading.active,
	.nox_account_desktop_bot_settings_value_behavior_result.active {
		transition: none;
		opacity: 1;
	}
	.nox_account_desktop_bot_settings_value_behavior_result.inactive {
		transition: all 5s;
		opacity: 0;
	}
	.nox_account_desktop_bot_settings_value_tooltip {
		color: #FFFFFF !important;
	}
	.nox_account_desktop_bot_settings_value_tooltip span {
		margin: 0 0 0 5px;
	}
	.nox_account_desktop_bot_settings_alert {
		display: table;
		width: 100%;
	}
	.nox_account_desktop_bot_settings_alert .alert {
		position: relative;
		margin: 8px 0 5px 230px;
		padding: 6px 10px 8px 10px;
		border: 1px solid #FFFFFF;
		border-radius: 15px;
		background: #FFFFFF;
		color: #333333;
		text-align: center;
		font-size: 11px;
		line-height: 16px;
		display: inline-block;
		width: 200px;
	}
	.nox_account_desktop_bot_settings_alert.big .alert {
		width: 274px;
	}

	@media (max-width: 1130px) {
		.nox_account_desktop_bot_settings_value .nox_input.small {
			width: 60px;
		}
		.nox_account_desktop_bot_settings_alert.big .alert {
			width: 224px;
		}
	}
	@media (min-width: 1101px) {
		.nox_account_desktop_bot_settings_prints_content_item:nth-child(7n) {
			margin: -4px 23px -4px -1px;
		}
		.nox_account_desktop_bot_settings_prints_content_item:nth-child(7n-2) {
			margin: -4px -1px -4px 23px;
		}
		.nox_account_desktop_bot_settings_prints.bot_settings_prints_hide .nox_account_desktop_bot_settings_prints_content_item:nth-child(n + 8) {
			display: none;
		}
	}
	@media (max-width: 1100px) and (min-width: 1001px) {
		.nox_account_desktop_bot_settings {
			padding: 0 0 0 170px;
		}
		.nox_account_desktop_bot_settings_left {
			width: 170px;
		}
		.nox_account_desktop_bot_settings_prints_content_block {
			width: 145px;
		}
		.nox_account_desktop_bot_settings_prints_content_button.bot_settings_prints_count_6,
		.nox_account_desktop_bot_settings_prints_content_button.bot_settings_prints_count_7 {
			display: block;
		}
		.nox_account_desktop_bot_settings_prints_content_item:nth-child(5n) {
			margin: -4px 23px -4px -1px;
		}
		.nox_account_desktop_bot_settings_prints_content_item:nth-child(5n-1) {
			margin: -4px -1px -4px 23px;
		}
		.nox_account_desktop_bot_settings_prints.bot_settings_prints_hide .nox_account_desktop_bot_settings_prints_content_item:nth-child(n + 6) {
			display: none;
		}
		.nox_account_desktop_bot_settings_value {
			padding: 0 0 0 220px;
		}
		.nox_account_desktop_bot_settings_alert .alert {
			margin: 8px 0 5px 220px;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_desktop_bot_settings {
			padding: 0 0 0 200px;
		}
		.nox_account_desktop_bot_settings_left {
			width: 200px;
		}
		.nox_account_desktop_bot_settings_prints_content_item:nth-child(7n) {
			margin: -4px 23px -4px -1px;
		}
		.nox_account_desktop_bot_settings_prints_content_item:nth-child(7n-2) {
			margin: -4px -1px -4px 23px;
		}
		.nox_account_desktop_bot_settings_prints.bot_settings_prints_hide .nox_account_desktop_bot_settings_prints_content_item:nth-child(n + 8) {
			display: none;
		}
	}
	@media (max-width: 800px) {
		.nox_account_desktop_bot_settings {
			padding: 0;
		}
		.nox_account_desktop_bot_settings_left {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			margin: 0 0 15px 0;
			width: 100%;
			min-height: auto;
		}
		.nox_account_desktop_bot_settings.phoenix_settings .nox_account_desktop_bot_settings_left {
			height: 310px;
		}
		.nox_account_desktop_bot_settings_rows.rows_bg {
			margin: 5px 0;
			padding: 5px 0;
		}
		.nox_account_desktop_bot_settings_rows,
		.nox_account_desktop_bot_settings_row {
			margin: 0;
		}
		.nox_account_desktop_bot_settings_label {
			float: left;
			position: relative;
			padding: 0 10px 0 0;
			width: 50%;
		}
		.nox_account_desktop_bot_settings_value {
			float: right;
			padding: 0 0 0 10px;
			width: 50%;
		}
		.nox_account_desktop_bot_settings_alert {
			float: right;
			padding: 0 0 0 10px;
			width: 50%;
		}
		.nox_account_desktop_bot_settings_alert .alert {
			margin: 8px 0;
		}
	}
	@media (max-width: 550px) {
		.nox_account_desktop_bot_settings_rows.rows_bg {
			margin: 5px -10px;
			padding: 5px 10px;
			width: calc(100% + 20px);
		}
		.nox_account_desktop_bot_settings_rows,
		.nox_account_desktop_bot_settings_row {
			line-height: 24px;
			min-height: 24px;
		}
		.nox_account_desktop_bot_settings_label {
			font-size: 10px;
			line-height: 24px;
			width: auto;
		}
		.nox_account_desktop_bot_settings_value {
			float: left;
			padding: 0;
			font-size: 10px;
			line-height: 24px;
			width: auto;
		}
		.nox_account_desktop_bot_settings_value .nox_input {
			font-size: 10px;
			line-height: 20px;
			height: 20px;
		}
		.nox_account_desktop_bot_settings_value .nox_range_slider_value {
			float: left;
		}
		.nox_account_desktop_bot_settings_value .nox_range_slider {
			float: left;
			position: relative;
			margin: 3px 0 0 15px;
		}
		.nox_account_desktop_bot_settings_value .nox_toggle_button {
			margin: 1px 0;
		}
		.nox_account_desktop_bot_settings_value .nox_toggle_button .v-switch-core {
			border-radius: 11px !important;
			width: 35px !important;
			height: 22px !important;
		}
		.nox_account_desktop_bot_settings_value .nox_toggle_button .v-switch-button {
			width: 16px !important;
			height: 16px !important;
		}
		.nox_account_desktop_bot_settings_value .nox_toggle_button.toggled .v-switch-button {
			transform: translate3d(16px, 3px, 0) !important;
		}
		.nox_account_desktop_bot_settings_value_content {
			padding: 2px 0;
		}
		.nox_account_desktop_bot_settings_value_content .nox_toggle_button {
			margin: -1px 0;
		}
		.nox_account_desktop_bot_settings_value_behavior_select .nox_cselect {
			font-size: 10px;
			width: 84px;
			height: 20px;
		}
		.nox_account_desktop_bot_settings_value_behavior_select .nox_cselect .nox_cselect_toggle {
			padding: 0 20px 0 8px;
			line-height: 20px;
		}
		.nox_account_desktop_bot_settings_value_behavior_select .nox_cselect .nox_cselect_toggle_value {
			padding: 2px 0;
		}
		.nox_account_desktop_bot_settings_value_behavior_select .nox_cselect .nox_cselect_toggle_button {
			right: 3px;
			font-size: 12px;
		}
		.nox_account_desktop_bot_settings_value_behavior_select .nox_cselect .nox_cselect_options_item_content_value {
			padding: 4px 18px;
			font-size: 10px;
		}
		.nox_account_desktop_bot_settings_value_behavior_loading,
		.nox_account_desktop_bot_settings_value_behavior_result {
			font-size: 10px;
			line-height: 24px;
			min-width: 55px;
		}
		.nox_account_desktop_bot_settings_value_behavior_result {
			margin: 0 0 0 -60px;
		}
		.nox_account_desktop_bot_settings_alert {
			float: left;
			padding: 0;
			width: 100%;
		}
		.nox_account_desktop_bot_settings_alert .alert,
		.nox_account_desktop_bot_settings_alert.big .alert {
			width: 100%;
		}
	}
	@media (max-width: 370px) {
		.nox_account_desktop_bot_settings_value .nox_range_slider {
			width: 55px !important;
		}
	}
	@media (max-width: 350px) {
		.nox_account_desktop_bot_settings_label {
			padding: 0 8px 0 0;
		}
		.nox_account_desktop_bot_settings_label .nox_tooltip_icon {
			margin: 0 0 0 8px;
		}
	}
</style>
