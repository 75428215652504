<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_loading" :class="{ hide: !$store.state.noxIsLoading }"></div>
		<div class="nox_account" :class="{ hide: $store.state.noxIsLoading }">
			<div class="nox_account_sidebar" id="nox_account_sidebar">
				<div class="nox_account_sidebar_logo">
					<router-link to="/account">
						<div><img src="@/assets/images/logo.png"></div>
						<div v-html="$store.getters.getLanguageText('2', 10)"></div>
					</router-link>
				</div>
				<div class="nox_account_sidebar_socs" is="NoxCommonSocialNetworks"></div>
				<div class="nox_account_sidebar_menu">
					<router-link to="/account/verification" class="red" v-if="!$store.state.noxAccountData.is_verification" v-html="$store.getters.getLanguageText('2.5.1', 9)"></router-link>
					<router-link to="/account" class="account" :class="{ active: noxIsActiveNews }" v-html="$store.getters.getLanguageText('2.5.1', 1)"></router-link>
					<router-link to="/account/quests" class="green" v-if="$store.state.noxAccountData.group !== 4" v-html="$store.getters.getLanguageText('2.5.1', 24)"></router-link>
					<router-link to="/account/tariffs" v-html="$store.getters.getLanguageText('2.5.1', 2)"></router-link>
					<router-link to="/account/stats" v-html="$store.getters.getLanguageText('2.5.1', 3)"></router-link>
					<router-link to="/account/bots" class="blue" v-html="$store.getters.getLanguageText('2.5.1', 4)"></router-link>
					<div class="nox_account_sidebar_menu_block" v-if="$store.state.noxAccountData.group == 1">
						<div class="nox_account_sidebar_menu_block_title">NOX Bots</div>
						<router-link to="/account/bots/forex" class="blue">Настройки Forex ботов</router-link>
						<router-link to="/account/bots/crypto" class="orange">Настройки Crypto ботов</router-link>
					</div>
					<router-link to="/account/servers" v-html="$store.getters.getLanguageText('2.5.1', 14)"></router-link>
					<router-link to="/account/vpn" v-if="$store.state.noxAccountData.group == 1" v-html="$store.getters.getLanguageText('2.5.1', 25)"></router-link>
					<router-link to="/account/balance" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }" v-html="$store.getters.getLanguageText('2.5.1', 8)"></router-link>
					<router-link to="/account/alerts" v-html="$store.getters.getLanguageText('2.5.1', 7)"></router-link>
					<router-link to="/account/settings" v-html="$store.getters.getLanguageText('2.5.1', 6)"></router-link>
					<div class="nox_account_sidebar_menu_block" v-if="$store.state.noxAccountData.group !== 4 && $store.state.noxAccountData.package && $store.state.noxAccountData.is_client_program">
						<div class="nox_account_sidebar_menu_block_title" v-html="$store.getters.getLanguageText('2.5.1', 21)"></div>
						<router-link to="/account/client_program" v-html="$store.getters.getLanguageText('2.5.1', 5)"></router-link>
						<router-link to="/account/team" v-html="$store.getters.getLanguageText('2.5.1', 13)"></router-link>
						<router-link to="/account/top_partners" v-html="$store.getters.getLanguageText('2.5.1', 18)"></router-link>
						<router-link to="/account/promo_materials" v-if="$store.state.noxAccountData.group == 1" v-html="$store.getters.getLanguageText('2.5.1', 22)"></router-link>
						<router-link to="/account/websites" v-if="$store.state.noxAccountData.group == 1" v-html="$store.getters.getLanguageText('2.5.1', 17)"></router-link>
						<router-link to="/account/landings" v-html="$store.getters.getLanguageText('2.5.1', 26)"></router-link>
					</div>
					<router-link to="/account/client_program" v-else-if="$store.state.noxAccountData.group !== 4 && $store.state.noxAccountData.package && !$store.state.noxAccountData.is_client_program" v-html="$store.getters.getLanguageText('2.5.1', 5)"></router-link>
					<router-link to="/account/analysis" v-html="$store.getters.getLanguageText('2.5.1', 19)"></router-link>
					<a href="https://forum.nox.bz/topic/21-faq-otvety-na-chasto-zadavaemye-voprosy-pro-nox" target="_blank" v-html="$store.getters.getLanguageText('2.5.1', 16)"></a>
					<router-link to="/account/calculators" v-html="$store.getters.getLanguageText('2.5.1', 15)"></router-link>
					<router-link to="/account/zp" class="red" v-if="$store.state.noxAccountData.group == 1">NOX ZP</router-link>
					<router-link to="/account/api" class="red" v-if="$store.state.noxAccountData.group == 1 || [40,42].includes($store.state.noxAccountData.id)">NOX API</router-link>
					<router-link to="/account/admin/vpn" class="red" v-if="$store.state.noxAccountData.group == 1">NOX VPN</router-link>
					<router-link to="/account/delivery" class="red" v-if="$store.state.noxAccountData.group == 1 || [100,479].includes($store.state.noxAccountData.id)">Доставка</router-link>
					<router-link to="/account/mailings" class="red" v-if="$store.state.noxAccountData.group == 1">Рассылки</router-link>
					<router-link to="/account/splash" class="red" v-if="$store.state.noxAccountData.group == 1 || $store.state.noxAccountData.id == 355">NOX SPLASH</router-link>
					<router-link to="/account/admin/quests" class="red" v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)">NOX Quests</router-link>
					<router-link to="/account/statistics" class="red" v-if="$store.state.noxAccountData.group == 1">Статистика</router-link>
					<router-link to="/account/database" class="red" v-if="$store.state.noxAccountData.group == 1">База данных</router-link>
					<router-link to="/account/telegram_bot" class="red" v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)">Telegram Bot</router-link>
					<router-link to="/account/admin/landings" class="red" v-if="$store.state.noxAccountData.group == 1">NOX Landings</router-link>
					<router-link to="/account/verification" class="red" v-if="[1,2].includes($store.state.noxAccountData.group)">Верификация</router-link>
					<router-link to="/account/users" class="red" v-if="[1,2].includes($store.state.noxAccountData.group)">Пользователи</router-link>
					<router-link to="/account/all_servers" class="red" v-if="[1,2].includes($store.state.noxAccountData.group)">Серверы NOX Box</router-link>
					<router-link to="/account/transliteration" class="red" v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)">Транслитерация</router-link>
					<router-link to="/account/bonus_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки бонусов</router-link>
					<router-link to="/account/system_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки системы</router-link>
					<router-link to="/account/portrait_settings" class="red" v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)">Настройки портрета</router-link>
					<router-link to="/account/strategy_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки стратегий</router-link>
					<router-link to="/account/master_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки индексов</router-link>
					<router-link to="/account/server_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки серверов</router-link>
					<router-link to="/account/phoenix_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки феникса</router-link>
					<router-link to="/account/bots_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки ботов</router-link>
					<router-link to="/account/cron_settings" class="red" v-if="$store.state.noxAccountData.group == 1">Настройки крона</router-link>
					<router-link to="/account/kyc_settings" class="red" v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)">Конструктор KYC</router-link>
					<router-link to="/account/phoenix_trading" class="red" v-if="$store.state.noxAccountData.group == 1">Phoenix trading</router-link>
					<router-link to="/account/zigzag_trading" class="red" v-if="$store.state.noxAccountData.group == 1">Zigzag trading</router-link>
					<router-link to="/account/news_trading" class="red" v-if="$store.state.noxAccountData.group == 1">News trading</router-link>
				</div>
			</div>
			<div class="nox_account_sidebar_mini" :class="'status_' + $store.state.noxSidebarMiniId">
				<div class="nox_account_sidebar_menu">
					<a class="white" @click="$parent.goToTop(true)"><font-awesome-icon :icon="['fas', 'arrow-up']" /><span v-html="$store.getters.getLanguageText('2.5.1', 20)"></span></a>
					<router-link to="/account/stats" v-html="$store.getters.getLanguageText('2.5.1', 3)"></router-link>
					<router-link to="/account/bots" class="blue" v-html="$store.getters.getLanguageText('2.5.1', 4)"></router-link>
					<router-link to="/account/servers" v-html="$store.getters.getLanguageText('2.5.1', 14)"></router-link>
				</div>
			</div>
			<div class="nox_account_sidebar_mobile">
				<div class="nox_account_sidebar_mobile_title">
					<router-link to="/account"><img src="@/assets/images/mobile_logo.png"><span>NOX</span><span v-html="$store.getters.getLanguageText('2', 11)"></span></router-link>
					<div v-html="$store.getters.getLanguageText('2', 4)"></div>
				</div>
				<div class="nox_account_sidebar_mobile_button" @click="$parent.toggleMobileMenu">
					<font-awesome-icon :icon="['fas', 'times']" v-if="$store.state.noxIsMobileMenu" />
					<font-awesome-icon :icon="['fas', 'bars']" v-else />
				</div>
				<div class="nox_account_sidebar_mobile_menu" :class="{ hidden: !$store.state.noxIsMobileMenu }">
					<div class="nox_account_sidebar_mobile_menu_content">
						<div class="nox_account_sidebar_mobile_menu_user_data">
							<div class="nox_account_sidebar_mobile_menu_user_id">
								<div><span v-html="$store.getters.getLanguageText('2', 0)"></span><span>: {{ $store.state.noxAccountData.id }}</span><span v-if="$store.state.noxAccountData.username"> ({{ $store.state.noxAccountData.username }})</span><span v-else-if="$store.state.noxAccountData.fullname"> ({{ $store.state.noxAccountData.fullname }})</span></div>
							</div>
							<div class="nox_account_sidebar_mobile_menu_user_balance_nox" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }">
								<div @click="$parent.toggleMobileMenu();$modal.show('NoxModalBalance', { type: ($store.state.noxAccountData.is_greys == 1 ? 'openGreyBalance' : 'addMainBalance'), operation: 0 })"><span v-html="$store.getters.getLanguageText('2', 5)"></span>: {{ $parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox, 2) }}</div>
							</div>
							<div class="nox_account_sidebar_mobile_menu_user_balance_of_bonuses">
								<div @click="$parent.toggleMobileMenu();$modal.show('NoxModalBalance', { type: 'openBonusBalance', operation: 0 })"><span v-html="$store.getters.getLanguageText('2', 6)"></span>: {{ $parent.getNumberFormatForDollar($store.state.noxAccountData.balance_of_bonuses, 2) }}</div>
							</div>
							<div class="nox_account_sidebar_mobile_menu_user_balance_nox_zp" v-if="$store.state.noxAccountData.balance_nox_zp">
								<div @click="$parent.toggleMobileMenu();$modal.show('NoxModalBalance', { type: 'payoutNOXZPBalance' })"><span v-html="$store.getters.getLanguageText('2', 7)"></span>: {{ $parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox_zp, 2) }}</div>
							</div>
							<div class="nox_account_sidebar_mobile_menu_user_count_of_perks" v-if="$store.state.noxAccountData.count_of_perks">
								<div @click="$parent.toggleMobileMenu();$modal.show($store.state.noxAccountData.is_verification ? 'NoxModalPerks' : 'NoxModalBalance')"><span v-html="$store.getters.getLanguageText('2', 8)"></span>: {{ $store.state.noxAccountData.count_of_perks }}</div>
							</div>
						</div>
						<div class="nox_account_sidebar_mobile_menu_links">
							<div v-if="!$store.state.noxAccountData.is_verification"><router-link to="/account/verification" class="red" v-html="$store.getters.getLanguageText('2.5.1', 9)"></router-link></div>
							<div><router-link to="/account" class="account" :class="{ active: noxIsActiveNews }" v-html="$store.getters.getLanguageText('2.5.1', 1)"></router-link></div>
							<div v-if="$store.state.noxAccountData.group !== 4"><router-link to="/account/quests" class="green" v-html="$store.getters.getLanguageText('2.5.1', 24)"></router-link></div>
							<div><router-link to="/account/tariffs" v-html="$store.getters.getLanguageText('2.5.1', 2)"></router-link></div>
							<div><router-link to="/account/stats" v-html="$store.getters.getLanguageText('2.5.1', 3)"></router-link></div>
							<div><router-link to="/account/bots" class="blue" v-html="$store.getters.getLanguageText('2.5.1', 4)"></router-link></div>
							<div><router-link to="/account/servers" v-html="$store.getters.getLanguageText('2.5.1', 14)"></router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/vpn" v-html="$store.getters.getLanguageText('2.5.1', 25)"></router-link></div>
							<div><router-link to="/account/balance" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }" v-html="$store.getters.getLanguageText('2.5.1', 8)"></router-link></div>
							<div><router-link to="/account/alerts" v-html="$store.getters.getLanguageText('2.5.1', 7)"></router-link></div>
							<div><router-link to="/account/settings" v-html="$store.getters.getLanguageText('2.5.1', 6)"></router-link></div>
							<div class="nox_account_sidebar_mobile_menu_links_block" v-if="$store.state.noxAccountData.group !== 4 && $store.state.noxAccountData.package && $store.state.noxAccountData.is_client_program">
								<div><router-link to="/account/client_program" v-html="$store.getters.getLanguageText('2.5.1', 5)"></router-link></div>
								<div><router-link to="/account/team" v-html="$store.getters.getLanguageText('2.5.1', 13)"></router-link></div>
								<div><router-link to="/account/top_partners" v-html="$store.getters.getLanguageText('2.5.1', 18)"></router-link></div>
								<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/promo_materials" v-html="$store.getters.getLanguageText('2.5.1', 22)"></router-link></div>
								<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/websites" v-html="$store.getters.getLanguageText('2.5.1', 17)"></router-link></div>
								<div><router-link to="/account/landings" v-html="$store.getters.getLanguageText('2.5.1', 26)"></router-link></div>
							</div>
							<div v-else-if="$store.state.noxAccountData.group !== 4 && $store.state.noxAccountData.package && !$store.state.noxAccountData.is_client_program"><router-link to="/account/client_program" v-html="$store.getters.getLanguageText('2.5.1', 5)"></router-link></div>
							<div><router-link to="/account/analysis" v-html="$store.getters.getLanguageText('2.5.1', 19)"></router-link></div>
							<div><a href="https://forum.nox.bz/topic/21-faq-otvety-na-chasto-zadavaemye-voprosy-pro-nox" target="_blank" v-html="$store.getters.getLanguageText('2.5.1', 16)"></a></div>
							<div><router-link to="/account/calculators" v-html="$store.getters.getLanguageText('2.5.1', 15)"></router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/zp" class="red">NOX ZP</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || [40,42].includes($store.state.noxAccountData.id)"><router-link to="/account/api" class="red">NOX API</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/admin/vpn" class="red">NOX VPN</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || [100,479].includes($store.state.noxAccountData.id)"><router-link to="/account/delivery" class="red">Доставка</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/mailings" class="red">Рассылки</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || $store.state.noxAccountData.id == 355"><router-link to="/account/splash" class="red">NOX SPLASH</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)"><router-link to="/account/admin/quests" class="red">NOX Quests</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/statistics" class="red">Статистика</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/database" class="red">База данных</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)"><router-link to="/account/telegram_bot" class="red">Telegram Bot</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/admin/landings" class="red">NOX Landings</router-link></div>
							<div v-if="[1,2].includes($store.state.noxAccountData.group)"><router-link to="/account/verification" class="red">Верификация</router-link></div>
							<div v-if="[1,2].includes($store.state.noxAccountData.group)"><router-link to="/account/users" class="red">Пользователи</router-link></div>
							<div v-if="[1,2].includes($store.state.noxAccountData.group)"><router-link to="/account/all_servers" class="red">Сервера NOX Box</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)"><router-link to="/account/transliteration" class="red">Транслитерация</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/bonus_settings" class="red">Настройки бонусов</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/system_settings" class="red">Настройки системы</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)"><router-link to="/account/portrait_settings" class="red">Настройки портрета</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/strategy_settings" class="red">Настройки стратегий</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/master_settings" class="red">Настройки индексов</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/server_settings" class="red">Настройки серверов</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/phoenix_settings" class="red">Настройки феникса</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/bots_settings" class="red">Настройки ботов</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/cron_settings" class="red">Настройки крона</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1 || [355,463].includes($store.state.noxAccountData.id)"><router-link to="/account/kyc_settings" class="red">Конструктор KYC</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/phoenix_trading" class="red">Phoenix trading</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/zigzag_trading" class="red">Zigzag trading</router-link></div>
							<div v-if="$store.state.noxAccountData.group == 1"><router-link to="/account/news_trading" class="red">News trading</router-link></div>
							<div><a class="red" @click="$parent.exit" v-html="$store.getters.getLanguageText('2.5.1', 0)"></a></div>
						</div>
						<div class="nox_account_sidebar_mobile_menu_language">
							<div class="nox_account_sidebar_mobile_menu_language_name" :class="{ hide: !$store.state.noxIsLanguageRU }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_sidebar_mobile_menu_language_name text" :class="{ hide: $store.state.noxIsLanguageRU, active: $store.state.noxLanguage == 'ru' }" @click="selectLanguage('ru')"><span v-html="$store.getters.getLanguageText('2', 1, { options: [0] })"></span></div>
							<div class="nox_account_sidebar_mobile_menu_language_name" :class="{ hide: !$store.state.noxIsLanguageEN }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_sidebar_mobile_menu_language_name text" :class="{ hide: $store.state.noxIsLanguageEN, active: $store.state.noxLanguage == 'en' }" @click="selectLanguage('en')"><span v-html="$store.getters.getLanguageText('2', 1, { options: [1] })"></span></div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop">
				<div class="nox_account_desktop_header">
					<div class="nox_account_desktop_header_item">
						<div class="nox_account_desktop_header_item_language">
							<div class="nox_account_desktop_header_item_language_name" :class="{ hide: !$store.state.noxIsLanguageRU }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_header_item_language_name text" :class="{ hide: $store.state.noxIsLanguageRU, active: $store.state.noxLanguage == 'ru' }" @click="selectLanguage('ru')" v-html="$store.getters.getLanguageText('2', 1, { options: [0] })"></div>
							<div class="nox_account_desktop_header_item_language_name" :class="{ hide: !$store.state.noxIsLanguageEN }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_header_item_language_name text" :class="{ hide: $store.state.noxIsLanguageEN, active: $store.state.noxLanguage == 'en' }" @click="selectLanguage('en')" v-html="$store.getters.getLanguageText('2', 1, { options: [1] })"></div>
						</div>
					</div>
					<div class="nox_account_desktop_header_item menu icon red" @click="$parent.exit"><font-awesome-icon :icon="['fas', 'sign-out-alt']" /></div>
					<router-link to="/account/settings" class="nox_account_desktop_header_item menu icon blue"><font-awesome-icon :icon="['fas', 'cog']" /></router-link>
					<div class="nox_account_desktop_header_item menu icon alerts" :class="{ active: noxIsAlerts, icon_active: noxIsIconAlerts }" @click="toggleAlerts">
						<div class="nox_account_desktop_header_item_menu">
							<div class="nox_account_desktop_header_item_menu_icon"><font-awesome-icon :icon="['fas', 'bell']" /></div>
							<div class="nox_account_desktop_header_item_menu_list">
								<div class="nox_account_desktop_header_item_menu_list_title" v-html="$store.getters.getLanguageText('2.5.2', 0)"></div>
								<router-link to="/account/alerts?tab=2" class="nox_account_desktop_header_item_menu_list_row"><font-awesome-icon class="orange" :icon="['fab', 'first-order']" /><span v-html="$store.getters.getLanguageText('2.5.2', 1)"></span></router-link>
								<router-link to="/account/alerts?tab=3" class="nox_account_desktop_header_item_menu_list_row"><font-awesome-icon class="blue" :icon="['fas', 'users']" /><span v-html="$store.getters.getLanguageText('2.5.2', 2)"></span></router-link>
								<router-link to="/account/alerts?tab=4" class="nox_account_desktop_header_item_menu_list_row"><font-awesome-icon class="green" :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('2.5.2', 3)"></span></router-link>
								<router-link to="/account/alerts?tab=5" class="nox_account_desktop_header_item_menu_list_row"><font-awesome-icon class="red" :icon="['fas', 'bullhorn']" /><span v-html="$store.getters.getLanguageText('2.5.2', 4)"></span></router-link>
								<router-link to="/account/alerts" class="nox_account_desktop_header_item_menu_list_row black"><font-awesome-icon :icon="['fas', 'eye']" /><span v-html="$store.getters.getLanguageText('2.5.2', 5)"></span></router-link>
								<router-link to="/account/alert_settings" class="nox_account_desktop_header_item_menu_list_row black"><font-awesome-icon :icon="['fas', 'tools']" /><span v-html="$store.getters.getLanguageText('2.5.2', 6)"></span></router-link>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_header_item menu icon funds" :class="{ red: $store.state.noxAccountData.balance_nox < 0, active: noxIsFunds, icon_active: noxIsIconFunds }" @click="toggleFunds">
						<div class="nox_account_desktop_header_item_menu">
							<div class="nox_account_desktop_header_item_menu_icon"><font-awesome-icon :icon="['fas', 'dollar-sign']" /></div>
							<div class="nox_account_desktop_header_item_menu_list">
								<div class="nox_account_desktop_header_item_menu_list_title" v-html="$store.getters.getLanguageText('2.5.3', 0)"></div>
								<div class="nox_account_desktop_header_item_menu_list_row row_1" :class="{ red: $store.state.noxAccountData.balance_nox < 0 }" @click="$modal.show('NoxModalBalance', { type: ($store.state.noxAccountData.is_greys == 1 ? 'openGreyBalance' : 'addMainBalance'), operation: 0 })"><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('2.5.3', 1, { balance: $parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox, 2) })"></span><font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 106), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" /></div>
								<div class="nox_account_desktop_header_item_menu_list_row row_2" @click="$modal.show('NoxModalBalance', { type: 'openBonusBalance', operation: 0 })"><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('2.5.3', 2, { balance: $parent.getNumberFormatForDollar($store.state.noxAccountData.balance_of_bonuses, 2) })"></span><font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 107), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" /></div>
								<div class="nox_account_desktop_header_item_menu_list_row row_3" @click="$modal.show('NoxModalBalance', { type: 'payoutNOXZPBalance' })" v-if="$store.state.noxAccountData.balance_nox_zp"><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('2.5.3', 3, { balance: $parent.getNumberFormatForDollar($store.state.noxAccountData.balance_nox_zp, 2) })"></span><font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 108), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" /></div>
								<div class="nox_account_desktop_header_item_menu_list_row row_4" @click="$modal.show($store.state.noxAccountData.is_verification ? 'NoxModalPerks' : 'NoxModalBalance')" v-if="$store.state.noxAccountData.count_of_perks"><font-awesome-icon :icon="['fas', 'coins']" /><span v-html="$store.getters.getLanguageText('2.5.3', 4, { count: $store.state.noxAccountData.count_of_perks })"></span><font-awesome-icon class="nox_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 109), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" /></div>
								<router-link to="/account/balance" class="nox_account_desktop_header_item_menu_list_row row_5 black"><font-awesome-icon :icon="['fas', 'tools']" /><span v-html="$store.getters.getLanguageText('2.5.3', 5)"></span></router-link>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_header_item text"><span v-html="$store.getters.getLanguageText('2', 0)"></span>: <span>{{ $store.state.noxAccountData.id }}</span></div>
				</div>
				<div class="nox_account_desktop_content"><router-view></router-view></div>
				<div class="nox_account_desktop_footer" :class="{ account: $route.path == '/account' }">
					<div class="nox_account_desktop_footer_copyright" is="NoxCommonCopyright"></div>
					<div class="nox_account_desktop_footer_social_networks" is="NoxCommonSocialNetworks"></div>
					<div class="nox_account_desktop_footer_mobile_applications" is="NoxCommonMobileApplications"></div>
				</div>
				<div class="nox_account_desktop_background" :class="{ active: noxIsBackground }" @click="hideBackground"></div>
			</div>
			<div class="nox_account_preload">
				<img src="@/assets/images/stars.png">
				<img src="@/assets/images/gift_1.png">
				<img src="@/assets/images/gift_2.png">
				<img src="@/assets/images/gift_3.png">
				<img src="@/assets/images/gift_4.png">
				<img src="@/assets/images/video_1.png">
				<img src="@/assets/images/video_2.png">
				<img src="@/assets/images/video_3.png">
				<img src="@/assets/images/bot_img_2.png">
				<img src="@/assets/images/bot_img_3.png">
				<img src="@/assets/images/bot_img_4.png">
				<img src="@/assets/images/bot_img_6.png">
				<img src="@/assets/images/bot_img_8.png">
				<img src="@/assets/images/bot_img_9.png">
				<img src="@/assets/images/bot_img_10.png">
				<img src="@/assets/images/bot_risk_0.png">
				<img src="@/assets/images/bot_risk_1.png">
				<img src="@/assets/images/bot_risk_2.png">
				<img src="@/assets/images/bot_risk_3.png">
				<img src="@/assets/images/bot_risk_4.png">
				<img src="@/assets/images/bot_risk_phoenix_v2.png">
				<img src="@/assets/images/bot_risk_phoenix_v3.png">
				<img src="@/assets/images/bot_risk_insomnia.png">
				<img src="@/assets/images/payment_method_1.png">
				<img src="@/assets/images/payment_method_2.png">
				<img src="@/assets/images/payment_method_3.png">
				<img src="@/assets/images/payment_method_4.png">
				<img src="@/assets/images/payment_method_5.png">
				<img src="@/assets/images/payment_method_6.png">
				<img src="@/assets/images/payment_method_7.png">
				<img src="@/assets/images/payment_method_8.png">
				<img src="@/assets/images/payment_method_9_1.png">
				<img src="@/assets/images/payment_method_9_2.png">
				<img src="@/assets/images/payment_method_10.png">
				<img src="@/assets/images/phoenix_icons.png">
			</div>
		</div>
		<div class="nox_modals">
			<div is="NoxModalPerks"></div>
			<div is="NoxModalWelcome"></div>
			<div is="NoxModalBalance"></div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxIsFunds: false,
			noxIsAlerts: false,
			noxIsIconFunds: false,
			noxIsIconAlerts: false,
			noxIsBackground: false,
			noxIsActiveNews: false
		}),
		watch: {
			$route() {
				this.init();
			}
		},
		methods: {
			isReady: function() {
				this.init();
				this.axios('getAccountData');
			},
			init: function() {
				this.noxIsIconFunds = ['/account/balance'].includes(this.$route.path) ? true : false;
				this.noxIsIconAlerts = ['/account/alerts', '/account/alert_settings'].includes(this.$route.path) ? true : false;
				this.noxIsActiveNews = false;
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getAccountData') {
						_this.$store.state.noxIsAccount = true;
						_this.$store.state.noxIsLoading = true;
						config.url = '/v2/account';
					}
					else if (type == 'selectLanguage') {
						config.url = '/v2/account/users/language';
						config.data = { language: var1 };
						config.method = 'patch';
					}
					else if (type == 'setUserBrowserDetails') {
						config.url = '/v2/account/users/browser_details';
						config.data = { timezone_offset: var1 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getAccountData') {
								_this.$parent.goToTop(true);
								_this.$parent.checkAppVersion(data.headers);
								if (data.data.account_data !== undefined) {
									_this.$parent.initAccountData(data.data.account_data);
									setTimeout(function() {
										var timezoneOffset = (new Date().getTimezoneOffset());
										if (_this.$store.state.noxAccountData.browser_details.timezone_offset == undefined || _this.$store.state.noxAccountData.browser_details.timezone_offset !== timezoneOffset) {
											_this.axios('setUserBrowserDetails', timezoneOffset);
										}
									}, 0);
								}
								if (data.data.system_settings !== undefined) {
									_this.$parent.initSystemSettings(data.data.system_settings);
								}
							}
							else if (type == 'selectLanguage') {
								_this.$parent.changeLanguage(var1);
							}
						}
					}).catch(function() {
						_this.$parent.exit();
					}).finally(function() {
						_this.$store.state.noxIsAccount = false;
					});
				}
				else {
					if (_this.$route.path !== undefined) {
						var result = String(_this.$route.path).split('/');
						if (result.length > 2 && result[1] == 'account') {
							if (['news', 'stats', 'analysis'].includes(result[2])) {
								result[1] = 'info'; _this.$router.push({ path: result.join('/') }); return false;
							}
							else {
								_this.$cookies.set('noxRoutePath', _this.$route.path, '24h');
							}
						}
					}
					_this.$parent.exit();
				}
			},
			toggleFunds: function() {
				this.noxIsFunds = !this.noxIsFunds;
				this.noxIsBackground = this.noxIsFunds;
			},
			toggleAlerts: function() {
				this.noxIsAlerts = !this.noxIsAlerts;
				this.noxIsBackground = this.noxIsAlerts;
			},
			selectLanguage: function(language) {
				if (this.$store.state.noxLanguage !== language && ['ru', 'en'].includes(language)) {
					this.axios('selectLanguage', language);
				}
			},
			hideBackground: function() {
				this.noxIsFunds = false;
				this.noxIsAlerts = false;
				this.noxIsBackground = false;
			}
		},
		components: {
			NoxModalPerks: () => import('@/views/modal_components/NoxModalPerks.vue'),
			NoxModalWelcome: () => import('@/views/modal_components/NoxModalWelcome.vue'),
			NoxModalBalance: () => import('@/views/modal_components/NoxModalBalance.vue'),
			NoxCommonCopyright: () => import('@/views/common_components/NoxCommonCopyright.vue'),
			NoxCommonSocialNetworks: () => import('@/views/common_components/NoxCommonSocialNetworks.vue'),
			NoxCommonMobileApplications: () => import('@/views/common_components/NoxCommonMobileApplications.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account {
		color: #FFFFFF;
	}
	.nox_account_sidebar {
		float: left;
		padding: 30px;
		text-align: center;
		width: 300px;
	}
	.nox_account_sidebar_logo {
		margin: 5px 0 0 0;
	}
	.nox_account_sidebar_logo a {
		text-decoration: none;
	}
	.nox_account_sidebar_logo img {
		margin: 0 0 -5px 0;
		height: 60px;
	}
	.nox_account_sidebar_logo div {
		display: inline-block;
		width: 60%;
	}
	.nox_account_sidebar_logo div:last-child {
		margin: 8px 0 0 0;
		color: #555555;
		font-size: 15.8px;
	}
	.nox_account_sidebar_socs {
		margin: 0 0 30px 0;
	}
	.nox_account_sidebar_socs a {
		margin: 0 3px;
		color: #333333B3;
		font-size: 30px;
	}
	.nox_account_sidebar_socs a:hover {
		color: #111111B3;
	}
	.nox_account_sidebar_menu a {
		position: relative;
		margin: 8px 0 0 0;
		border-radius: 16px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #33333380;
		color: #FFFFFF;
		cursor: pointer;
		text-decoration: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 32px;
		display: block;
	}
	.nox_account_sidebar_menu a:hover,
	.nox_account_sidebar_menu a.router-link-exact-active,
	.nox_account_sidebar_menu a.router-link-active.active,
	.nox_account_sidebar_menu a.router-link-active:not(.account) {
		background: #333333B3;
	}
	.nox_account_sidebar_menu a.blue {
		background: #0066BBB3;
	}
	.nox_account_sidebar_menu a.blue:hover,
	.nox_account_sidebar_menu a.blue.router-link-active {
		background: #0066BBCC;
	}
	.nox_account_sidebar_menu a.green {
		background: #008000B3;
	}
	.nox_account_sidebar_menu a.green:hover,
	.nox_account_sidebar_menu a.green.router-link-active {
		background: #008000CC;
	}
	.nox_account_sidebar_menu a.orange {
		background: #CC840099;
	}
	.nox_account_sidebar_menu a.orange:hover,
	.nox_account_sidebar_menu a.orange.router-link-active {
		background: #CC8400B3;
	}
	.nox_account_sidebar_menu a.red {
		background: #FF000080;
	}
	.nox_account_sidebar_menu a.red:hover,
	.nox_account_sidebar_menu a.red.router-link-active {
		background: #FF0000B3;
	}
	.nox_account_sidebar_menu a.white {
		border: 1px solid #DDDDDD;
		background: #FFFFFF80;
		color: #666666;
	}
	.nox_account_sidebar_menu a.white:hover {
		background: #F1F1F1B3;
	}
	.nox_account_sidebar_menu a svg {
		margin: 0 5px 0 0;
		font-size: 10px;
	}
	.nox_account_sidebar_menu_block {
		margin: 8px 0 0 0;
		border-radius: 16px;
	}
	.nox_account_sidebar_menu_block a {
		margin: 1px 0 0 0;
		border-radius: 0;
	}
	.nox_account_sidebar_menu_block a:last-child {
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
	}
	.nox_account_sidebar_menu_block_title {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		background: #77777780;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
		line-height: 35px;
	}
	.nox_account_sidebar_mini {
		position: fixed;
		padding: 30px;
		left: 0;
		bottom: 0;
		opacity: 0;
		visibility: hidden;
		transition: all .5s linear 0s;
		text-align: center;
		width: 300px;
	}
	.nox_account_sidebar_mini.status_0,
	.nox_account_sidebar_mini.status_2 {
		transition: none;
	}
	.nox_account_sidebar_mini.status_1 {
		opacity: 1;
		visibility: visible;
	}
	.nox_account_sidebar_mobile {
		position: fixed;
		background: #444444;
		text-align: center;
		display: none;
		width: 100%;
		height: 40px;
		z-index: 100000;
	}
	.nox_account_sidebar_mobile_title {
		float: left;
		margin: 0 0 0 15px;
		text-align: left;
	}
	.nox_account_sidebar_mobile_title a {
		color: #FFFFFF;
		text-decoration: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 16px;
		line-height: 38px;
		display: block;
	}
	.nox_account_sidebar_mobile_title a img {
		margin: -2px 2px 0 0;
		height: 13px;
	}
	.nox_account_sidebar_mobile_title a span:last-child {
		margin: 0 0 0 3px;
		font-size: 60%;
	}
	.nox_account_sidebar_mobile_title div {
		position: absolute;
		top: 11px;
		right: 40px;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
	}
	.nox_account_sidebar_mobile_button {
		position: fixed;
		top: 0;
		right: 0;
		padding: 0 15px 0 0;
		cursor: pointer;
		text-align: right;
		font-size: 15px;
		line-height: 38px;
		width: 95px;
		height: 40px;
		z-index: 1;
	}
	.nox_account_sidebar_mobile_menu {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #555555F2;
		flex-direction: column;
		justify-content: center;
		display: flex;
		width: 100%;
	}
	.nox_account_sidebar_mobile_menu.hidden {
		display: none;
	}
	.nox_account_sidebar_mobile_menu_content {
		padding: 40px 10px;
		overflow-y: auto;
	}
	.nox_account_sidebar_mobile_menu_content a {
		margin: 5px 0;
		border-bottom: 1px solid transparent;
		color: #FFFFFF;
		text-decoration: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
		line-height: 22px;
		display: inline-block;
	}
	.nox_account_sidebar_mobile_menu_content a:hover,
	.nox_account_sidebar_mobile_menu_content a.router-link-exact-active,
	.nox_account_sidebar_mobile_menu_content a.router-link-active.active,
	.nox_account_sidebar_mobile_menu_content a.router-link-active:not(.account) {
		border-bottom: 1px solid;
	}
	.nox_account_sidebar_mobile_menu_content a.blue {
		color: #A5E5FF;
	}
	.nox_account_sidebar_mobile_menu_content a.green {
		color: #00FF00;
	}
	.nox_account_sidebar_mobile_menu_content a.orange {
		color: #FFC660;
	}
	.nox_account_sidebar_mobile_menu_content a.red {
		color: #FFBBBB;
	}
	.nox_account_sidebar_mobile_menu_user_data {
		margin: 0 0 20px 0;
		color: #F5F5DC;
		overflow: hidden;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 28px;
	}
	.nox_account_sidebar_mobile_menu_user_id {
		text-transform: none;
	}
	.nox_account_sidebar_mobile_menu_user_balance_nox div,
	.nox_account_sidebar_mobile_menu_user_balance_nox_zp div,
	.nox_account_sidebar_mobile_menu_user_balance_of_bonuses div,
	.nox_account_sidebar_mobile_menu_user_count_of_perks div {
		padding: 3px 15px 5px 15px;
		border-radius: 15px;
		display: inline;
	}
	.nox_account_sidebar_mobile_menu_user_balance_nox div span,
	.nox_account_sidebar_mobile_menu_user_balance_nox_zp div span,
	.nox_account_sidebar_mobile_menu_user_balance_of_bonuses div span,
	.nox_account_sidebar_mobile_menu_user_count_of_perks div span {
		font-size: 9px;
	}
	.nox_account_sidebar_mobile_menu_user_balance_nox div {
		background: #5093CB;
	}
	.nox_account_sidebar_mobile_menu_user_balance_nox.red div {
		background: #E26868;
	}
	.nox_account_sidebar_mobile_menu_user_balance_of_bonuses div {
		background: #48B849;
	}
	.nox_account_sidebar_mobile_menu_user_balance_nox_zp div {
		background: #AC70AC;
	}
	.nox_account_sidebar_mobile_menu_user_count_of_perks div {
		background: #E1B673;
	}
	.nox_account_sidebar_mobile_menu_links_block {
		margin: 5px 0;
		padding: 5px 15px;
		border: 1px solid #888888;
		border-radius: 15px;
		display: inline-block;
	}
	.nox_account_sidebar_mobile_menu_login {
		margin: 20px 0 -20px 0;
	}
	.nox_account_sidebar_mobile_menu_login div {
		padding: 0 8px;
		text-align: right;
		display: inline-block;
		width: 50%;
	}
	.nox_account_sidebar_mobile_menu_login div:last-child {
		text-align: left;
	}
	.nox_account_sidebar_mobile_menu_login div a {
		font-size: 11px;
	}
	.nox_account_sidebar_mobile_menu_language {
		margin: 20px 0 5px 0;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
	}
	.nox_account_sidebar_mobile_menu_language_name {
		margin: 0 6px;
		line-height: 15px;
		display: inline-block;
		width: 24px;
	}
	.nox_account_sidebar_mobile_menu_language_name img {
		margin: -2px 0 0 0;
	}
	.nox_account_sidebar_mobile_menu_language_name.text {
		cursor: pointer;
	}
	.nox_account_sidebar_mobile_menu_language_name.text.active {
		border-bottom: 1px solid #FFFFFF;
		cursor: default;
	}
	.nox_account_desktop {
		padding: 30px 30px 30px 300px;
		width:100%;
	}
	.nox_account_desktop_header {
		margin: 0 0 22px 0;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_header_item {
		float: right;
		position: relative;
		margin: 0 0 8px 8px;
		padding: 0 15px;
		border-radius: 15px;
		background: #33333380;
		color: #FFFFFF;
		text-align: center;
		text-decoration: none;
		line-height: 30px;
	}
	.nox_account_desktop_header_item:first-child {
		padding: 0 10px;
	}
	.nox_account_desktop_header_item:last-child {
		margin: 0 0 8px 0;
	}
	.nox_account_desktop_header_item.menu {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
	}
	.nox_account_desktop_header_item.menu:hover,
	.nox_account_desktop_header_item.menu.funds.active,
	.nox_account_desktop_header_item.menu.funds.icon_active,
	.nox_account_desktop_header_item.menu.alerts.active,
	.nox_account_desktop_header_item.menu.alerts.icon_active,
	.nox_account_desktop_header_item.menu.router-link-active {
		background: #333333B3;
	}
	.nox_account_desktop_header_item.menu.blue {
		background: #0066BBB3;
	}
	.nox_account_desktop_header_item.menu.blue:hover,
	.nox_account_desktop_header_item.menu.blue.active,
	.nox_account_desktop_header_item.menu.blue.icon_active,
	.nox_account_desktop_header_item.menu.blue.router-link-active {
		background: #0066BBCC;
	}
	.nox_account_desktop_header_item.menu.red {
		background: #FF000080;
	}
	.nox_account_desktop_header_item.menu.red:hover,
	.nox_account_desktop_header_item.menu.red.active,
	.nox_account_desktop_header_item.menu.red.icon_active,
	.nox_account_desktop_header_item.menu.red.router-link-active {
		background: #FF0000B3;
	}
	.nox_account_desktop_header_item.menu.funds .nox_account_desktop_header_item_menu_list_row.row_1 b {
		color: #A5E5FF;
	}
	.nox_account_desktop_header_item.menu.funds .nox_account_desktop_header_item_menu_list_row.row_2 b {
		color: #00FF00;
	}
	.nox_account_desktop_header_item.menu.funds .nox_account_desktop_header_item_menu_list_row.row_3 b {
		color: #E4A5E4;
	}
	.nox_account_desktop_header_item.menu.funds .nox_account_desktop_header_item_menu_list_row.row_4 b {
		color: #FFC660;
	}
	.nox_account_desktop_header_item.menu.funds .nox_account_desktop_header_item_menu_list_row.red b {
		color: #FFFFFF;
	}
	.nox_account_desktop_header_item.menu.funds.active,
	.nox_account_desktop_header_item.menu.alerts.active {
		z-index: 16;
	}
	.nox_account_desktop_header_item.menu.funds.active .nox_account_desktop_header_item_menu_list,
	.nox_account_desktop_header_item.menu.alerts.active .nox_account_desktop_header_item_menu_list {
		display: block;
	}
	.nox_account_desktop_header_item.text {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_header_item.icon {
		font-size: 14px;
	}
	.nox_account_desktop_header_item.icon svg {
		min-width: 15px;
	}
	.nox_account_desktop_header_item_menu_list {
		position: absolute;
		top: 40px;
		left: 50%;
		margin: 0 0 0 -120px;
		border-radius: 10px;
		box-shadow: 0 0 5px #555555;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: auto;
		display: none;
		width: 240px;
	}
	.nox_account_desktop_header_item_menu_list:before,
	.nox_account_desktop_header_item_menu_list:after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 100%;
		border: solid transparent;
		pointer-events: none;
		width: 0;
		height: 0;
	}
	.nox_account_desktop_header_item_menu_list:before {
		margin-left: -10px;
		border-width: 10px;
		border-bottom-color: transparent;
	}
	.nox_account_desktop_header_item_menu_list:after {
		margin-left: -8px;
		border-width: 8px;
		border-bottom-color: #6F6F6F;
	}
	.nox_account_desktop_header_item_menu_list_title {
		padding: 5px 0;
		border-radius: 10px 10px 0 0;
		background: #6F6F6F;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
	}
	.nox_account_desktop_header_item_menu_list_row {
		position: relative;
		padding: 10px 15px 12px 15px;
		border-top: 1px solid #969696;
		background: #828282;
		color: #FFFFFF;
		cursor: pointer;
		text-align: left;
		text-decoration: none;
		font-size: 12px;
		line-height: 14px;
		display: block;
	}
	.nox_account_desktop_header_item_menu_list_row:hover {
		background: #777777;
	}
	.nox_account_desktop_header_item_menu_list_row:last-child {
		border-radius: 0 0 10px 10px;
	}
	.nox_account_desktop_header_item_menu_list_row.black {
		background: #6F6F6F;
	}
	.nox_account_desktop_header_item_menu_list_row.black:hover {
		background: #656565;
	}
	.nox_account_desktop_header_item_menu_list_row.red {
		background: #FF848B;
	}
	.nox_account_desktop_header_item_menu_list_row.red:hover {
		background: #FF535B;
	}
	.nox_account_desktop_header_item_menu_list_row svg {
		margin: 0 10px 0 0;
		color: #FFFFFF;
		font-size: 11px;
	}
	.nox_account_desktop_header_item_menu_list_row svg.blue {
		color: #A5E5FF;
	}
	.nox_account_desktop_header_item_menu_list_row svg.green {
		color: #00FF00;
	}
	.nox_account_desktop_header_item_menu_list_row svg.orange {
		color: #FFC660;
	}
	.nox_account_desktop_header_item_menu_list_row svg.red {
		color: #FFBBBB;
	}
	.nox_account_desktop_header_item_menu_list_row .nox_tooltip_icon {
		position: absolute;
		top: 11px;
		right: 14px;
		margin: 0;
	}
	.nox_account_desktop_header_item_language {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		width: 80px;
	}
	.nox_account_desktop_header_item_language_name {
		margin: 0 6px;
		line-height: 15px;
		display: inline-block;
		width: 24px;
	}
	.nox_account_desktop_header_item_language_name img {
		margin: -2px 0 0 0;
	}
	.nox_account_desktop_header_item_language_name.text {
		cursor: pointer;
	}
	.nox_account_desktop_header_item_language_name.text:hover,
	.nox_account_desktop_header_item_language_name.text:focus {
		border-bottom: 1px solid #FFFFFF;
	}
	.nox_account_desktop_header_item_language_name.text.active {
		border-bottom: 1px solid #FFFFFF;
		cursor: default;
	}
	.nox_account_desktop_page_loading {
		position: relative;
		height: 300px;
		height: calc(100vh - 120px);
		min-height: 200px;
	}
	.nox_account_desktop_page_content .nox_tabs .nox_account_desktop_portlet:first-child > div:first-child {
		border-top-left-radius: 0;
	}
	.nox_account_desktop_page_content .nox_tabs .nox_account_desktop_portlet:first-child .nox_account_desktop_portlet_content:first-child {
		background: #333333CC;
		color: #FFFFFF;
	}
	.nox_account_desktop_page_content .nox_tabs .nox_account_desktop_portlet_wrapper:nth-child(n + 2) .nox_account_desktop_portlet > div:first-child {
		border-top-left-radius: 15px;
	}
	.nox_account_desktop_page_content_row {
		margin: 0 -7.5px;
		display: flex;
	}
	.nox_account_desktop_page_content_col {
		float: left;
		padding: 0 7.5px 15px 7.5px;
		width: 100%;
	}
	.nox_account_desktop_page_content_col .nox_account_desktop_portlet {
		margin: 0;
		height: 100%;
	}
	.nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet {
		padding: 0 0 71px 0;
	}
	.nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet_content {
		height: calc(100% - 53px);
	}
	.nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet_buttons {
		position: absolute;
	}
	.nox_account_desktop_page_content_title,
	.nox_account_desktop_page_content_navigation_title {
		margin: 0 0 2px 0;
		border-radius: 16px;
		background: #333333B3;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
		line-height: 30px;
		height: 32px;
	}
	.nox_account_desktop_page_content_navigation {
		margin: 0 0 15px 0;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_page_content_navigation.extra {
		margin: -13px 0 15px 0;
	}
	.nox_account_desktop_page_content_navigation_search {
		position: relative;
		margin: 2px 0 0 0;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_page_content_navigation_search input {
		position: relative;
		margin: 0;
		padding: 0 40px 2px 15px;
		border: 1px solid #AAAAAA;
		border-radius: 15px;
		box-shadow: inset 0 0 2px 0 #888888;
		caret-color: #555555;
		background: #FFFFFF;
		color: #555555;
		text-align: left;
		line-height: 30px;
		height: 30px;
		z-index: 3;
	}
	.nox_account_desktop_page_content_navigation_search ::-webkit-input-placeholder { color: #AAAAAA; }
	.nox_account_desktop_page_content_navigation_search ::-moz-placeholder          { color: #AAAAAA; }
	.nox_account_desktop_page_content_navigation_search :-moz-placeholder           { color: #AAAAAA; }
	.nox_account_desktop_page_content_navigation_search :-ms-input-placeholder      { color: #AAAAAA; }
	.nox_account_desktop_page_content_navigation_search_button {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0 15px 15px 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		color: #777777;
		cursor: pointer;
		text-align: center;
		line-height: 30px;
		width: 40px;
		height: 30px;
		z-index: 4;
	}
	.nox_account_desktop_page_content_navigation_search_button:hover {
		color: #555555;
	}
	.nox_account_desktop_page_content_navigation_search_result {
		position: absolute;
		top: 30px;
		left: 0;
		padding: 0 15px;
		width: 100%;
		z-index: 2;
	}
	.nox_account_desktop_page_content_navigation_search_result_block {
		padding: 10px 15px 12px 15px;
		border-radius: 0 0 15px 15px;
		box-shadow: 0 0 3px #777777;
		background: #FFFFFF;
		color: #555555;
	}
	.nox_account_desktop_page_content_navigation_search_result_content_link {
		cursor: pointer;
	}
	.nox_account_desktop_page_content_navigation_search_result_content_link:hover {
		text-decoration: underline;
	}
	.nox_account_desktop_page_content_navigation_search_background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #777777;
		opacity: 0.3;
		z-index: 1;
	}
	.nox_account_desktop_page_content_navigation_filters {
		position: relative;
		margin: 2px 0 0 0;
		padding: 0 0 0 105px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_page_content_navigation_filters:first-child {
		margin: 0;
	}
	.nox_account_desktop_page_content_navigation_filters_label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px;
		border-radius: 15px 2px 2px 15px;
		background: #333333B3;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 105px;
		height: 30px;
	}
	.nox_account_desktop_page_content_navigation_filters_block,
	.nox_account_desktop_page_content_navigation_filters_value {
		float: left;
		padding: 0 0 0 2px;
		width: 100%;
	}
	.nox_account_desktop_page_content_navigation_filters_block.col2,
	.nox_account_desktop_page_content_navigation_filters_value.col2 {
		width: 50%;
	}
	.nox_account_desktop_page_content_navigation_filters_block.col3,
	.nox_account_desktop_page_content_navigation_filters_value.col3 {
		width: 33.33333%;
	}
	.nox_account_desktop_page_content_navigation_filters_value div {
		border-radius: 2px;
		background: #111111B3;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		overflow: hidden;
		text-align: center;
		text-transform: lowercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 100%;
		height: 30px;
	}
	.nox_account_desktop_page_content_navigation_filters_value.active div,
	.nox_account_desktop_page_content_navigation_filters_value:hover div {
		background: #111111CC;
	}
	.nox_account_desktop_page_content_navigation_filters_value:last-child div {
		border-radius: 2px 15px 15px 2px;
	}
	.nox_account_desktop_page_content_navigation_filters_select {
		padding: 0 15px;
		border-radius: 2px;
		background: #111111B3;
		width: 100%;
		height: 30px;
	}
	.nox_account_desktop_page_content_navigation_filters_select.last {
		border-radius: 2px 15px 15px 2px;
	}
	.nox_account_desktop_page_content_navigation_filters_select.not_selected {
		background: #004177B3;
	}
	.nox_account_desktop_page_content_navigation_filters_select.not_selected .nox_select .vs__search,
	.nox_account_desktop_page_content_navigation_filters_select.not_selected .nox_select .vs__search:focus {
		color: #DADADA;
	}
	.nox_account_desktop_page_content_navigation_filters_select.not_selected .nox_select .vs__actions .vs__clear,
	.nox_account_desktop_page_content_navigation_filters_select.not_selected .nox_select .vs__actions .vs__open-indicator {
		fill: #DADADA;
	}
	.nox_account_desktop_page_content_navigation_filters_select .nox_select {
		margin: 0;
	}
	.nox_account_desktop_page_content_navigation_filters_select .nox_select .vs__dropdown-toggle {
		padding: 0 0 2px 0;
		border-radius: 0;
		background: transparent;
		line-height: 30px;
		height: 30px;
	}
	.nox_account_desktop_page_content_navigation_filters_checkboxes {
		padding: 0 15px;
		border-radius: 2px;
		background: #111111B3;
		width: 100%;
		min-height: 30px;
	}
	.nox_account_desktop_page_content_navigation_filters_checkboxes:last-child {
		border-radius: 2px 15px 15px 2px;
	}
	.nox_account_desktop_page_content_navigation_filters_checkboxes .nox_checkbox {
		margin: 6px 15px 8px 0;
		display: inline-block;
	}
	.nox_account_desktop_page_content_navigation_filters_checkboxes .nox_checkbox:last-child {
		margin: 6px 0 8px 0;
	}
	.nox_account_desktop_page_content_navigation_filters_checkboxes .nox_checkbox_mark {
		top: 1px;
	}
	.nox_account_desktop_page_content_loading {
		line-height: 35px;
		height: 35px;
	}
	.nox_account_desktop_page_content_loading,
	.nox_account_desktop_page_content_buttons {
		margin: 15px 0 20px 0;
		text-align: right;
	}
	.nox_account_desktop_portlet {
		position: relative;
		margin: 0 0 15px 0;
	}
	.nox_account_desktop_portlet > div:first-child {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
	.nox_account_desktop_portlet > div:last-child,
	.nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet_buttons {
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}
	.nox_account_desktop_portlet.blue .nox_account_desktop_portlet_title {
		border-bottom-color: #6DA4D7;
		background: #0066BBCC;
	}
	.nox_account_desktop_portlet.blue .nox_account_desktop_portlet_content {
		background: #0066BBB3;
	}
	.nox_account_desktop_portlet.blue .nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet.blue .nox_account_desktop_portlet_buttons {
		border-top-color: #6DA4D7;
		background: #0066BBCC;
	}
	.nox_account_desktop_portlet.brown .nox_account_desktop_portlet_title {
		border-bottom-color: #C97F81;
		background: #A52A2ACC;
	}
	.nox_account_desktop_portlet.brown .nox_account_desktop_portlet_content {
		background: #A52A2AB3;
	}
	.nox_account_desktop_portlet.brown .nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet.brown .nox_account_desktop_portlet_buttons {
		border-top-color: #C97F81;
		background: #A52A2ACC;
	}
	.nox_account_desktop_portlet.green .nox_account_desktop_portlet_title {
		border-bottom-color: #6DB36E;
		background: #008000CC;
	}
	.nox_account_desktop_portlet.green .nox_account_desktop_portlet_content {
		background: #008000B3;
	}
	.nox_account_desktop_portlet.green .nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet.green .nox_account_desktop_portlet_buttons {
		border-top-color: #6DB36E;
		background: #008000CC;
	}
	.nox_account_desktop_portlet.grey .nox_account_desktop_portlet_title {
		border-bottom-color: #E6E6E6;
		background: #CCCCCCB3;
		color: #555555;
	}
	.nox_account_desktop_portlet.grey .nox_account_desktop_portlet_content {
		background: #CCCCCC99;
		color: #555555;
	}
	.nox_account_desktop_portlet.grey .nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet.grey .nox_account_desktop_portlet_buttons {
		border-top-color: #E6E6E6;
		background: #CCCCCCB3;
	}
	.nox_account_desktop_portlet.orange .nox_account_desktop_portlet_title {
		border-bottom-color: #E6C18A;
		background: #CC8400B3;
	}
	.nox_account_desktop_portlet.orange .nox_account_desktop_portlet_content {
		background: #CC840099;
	}
	.nox_account_desktop_portlet.orange .nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet.orange .nox_account_desktop_portlet_buttons {
		border-top-color: #E6C18A;
		background: #CC8400B3;
	}
	.nox_account_desktop_portlet.red .nox_account_desktop_portlet_title {
		border-bottom-color: #FF9DA2;
		background: #FF000099;
	}
	.nox_account_desktop_portlet.red .nox_account_desktop_portlet_content {
		background: #FF000080;
	}
	.nox_account_desktop_portlet.red .nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet.red .nox_account_desktop_portlet_buttons {
		border-top-color: #FF9DA2;
		background: #FF000099;
	}
	.nox_account_desktop_portlet.violet .nox_account_desktop_portlet_title {
		border-bottom-color: #AD75AD;
		background: #771177CC;
	}
	.nox_account_desktop_portlet.violet .nox_account_desktop_portlet_content {
		background: #771177B3;
	}
	.nox_account_desktop_portlet.violet .nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet.violet .nox_account_desktop_portlet_buttons {
		border-top-color: #AD75AD;
		background: #771177CC;
	}
	.nox_account_desktop_portlet.avatar {
		margin: 65px 0 15px 0;
		overflow: visible;
	}
	.nox_account_desktop_portlet.avatar .nox_account_desktop_portlet_content {
		border-radius: 15px;
	}
	.nox_account_desktop_portlet_title {
		padding: 15px 30px;
		border-bottom: 1px solid #858585;
		background: #333333CC;
		color: #FFFFFF;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
		line-height: 22px;
	}
	.nox_account_desktop_portlet_content {
		padding: 25px 30px 30px 30px;
		background: #333333B3;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 22px;
	}
	.nox_account_desktop_portlet_content p,
	.nox_account_desktop_portlet_content ul {
		margin: 0 0 15px 0;
	}
	.nox_account_desktop_portlet_content p:last-child,
	.nox_account_desktop_portlet_content ul:last-child {
		margin: 0;
	}
	.nox_account_desktop_portlet_content a,
	.nox_account_desktop_portlet_buttons a {
		color: #A5E5FF;
	}
	.nox_account_desktop_portlet_content a:hover,
	.nox_account_desktop_portlet_buttons a:hover {
		opacity: 0.8;
	}
	.nox_account_desktop_portlet_content_edit {
		position: absolute;
		top: 15px;
		right: 30px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		font-size: 10px;
		line-height: 18px;
		width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_account_desktop_portlet_content_edit:hover {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_account_desktop_portlet_content_avatar {
		margin: 50px 0 0 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	.nox_account_desktop_portlet_content_avatar_img {
		position: absolute;
		top: 0;
		left: 50%;
		margin: -65px 0 0 -65px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #5695CE;
		overflow: hidden;
		display: inline-block;
		width: 130px;
		height: 130px;
		z-index: 1;
	}
	.nox_account_desktop_portlet_content_avatar_img img {
		pointer-events: none;
		width: 130px;
		height: 130px;
	}
	.nox_account_desktop_portlet_content_avatar_edit {
		position: absolute;
		top: 0;
		left: 50%;
		margin: 33px 0 0 -65px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		background: #48B849;
		color: #FFFFFF;
		text-align: center;
		cursor: pointer;
		font-size: 12px;
		line-height: 28px;
		width: 32px;
		height: 32px;
		z-index: 1;
	}
	.nox_account_desktop_portlet_content_avatar_edit:hover {
		background: #5695CE;
	}
	.nox_account_desktop_portlet_content_avatar_menu {
		display: none;
	}
	.nox_account_desktop_portlet_content_avatar_menu a {
		position: absolute;
		top: 0;
		margin: -63px 0 0 0;
		background: #333333B3;
		color: #FFFFFF;
		cursor: pointer;
		text-align: center;
		font-size: 20px;
		line-height: 58px;
		width: 50%;
		height: 58px;
	}
	.nox_account_desktop_portlet_content_avatar_menu a:first-child {
		left: 0;
		padding: 0 55px 0 0;
		border-radius: 15px 0 0 15px;
	}
	.nox_account_desktop_portlet_content_avatar_menu a:last-child {
		right: 0;
		padding: 0 0 0 55px;
		border-radius: 0 15px 15px 0;
	}
	.nox_account_desktop_portlet_content_avatar_menu a:hover {
		opacity: 0.9;
	}
	.nox_account_desktop_portlet_content_avatar_status {
		position: absolute;
		top: 0;
		right: 50%;
		margin: -65px -65px 0 0;
		padding: 0 8px;
		border: 2px solid #FFFFFF;
		border-radius: 16px;
		background: #B8C3CA;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 28px;
		min-width: 32px;
		height: 32px;
		z-index: 1;
	}
	.nox_account_desktop_portlet_content_line {
		margin: 25px 0;
		border-bottom: 2px solid #FFFFFF;
	}
	.nox_account_desktop_portlet_content_img_200 {
		min-height: 200px;
	}
	.nox_account_desktop_portlet_content_img_250 {
		min-height: 250px;
	}
	.nox_account_desktop_portlet_content_img_200 img,
	.nox_account_desktop_portlet_content_img_250 img {
		float: left;
		margin: 0 15px 10px 0;
		display: block;
		width: 200px;
		height: 200px;
	}
	.nox_account_desktop_portlet_content_img_250 img {
		width: 250px;
		height: 250px;
	}
	.nox_account_desktop_portlet_content_video {
		display: table;
		width: 100%;
	}
	.nox_account_desktop_portlet_content_video:hover a {
		opacity: 1;
	}
	.nox_account_desktop_portlet_content_video .video {
		float: left;
		position: relative;
		margin: 5px 20px 10px 0;
		border: 2px solid #777777;
		border-radius: 5px;
		box-shadow: 0 0 5px #555555;
		cursor: pointer;
		width: 390px;
	}
	.nox_account_desktop_portlet_content_video .video img {
		width: 100%;
		height: 100%;
	}
	.nox_account_desktop_portlet_content_video .video svg {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -24px 0 0 -25px;
		color: #212121;
		font-size: 50px;
		opacity: 0.8;
	}
	.nox_account_desktop_portlet_content_video .video:hover svg {
		color: #FF0000;
		opacity: 1;
	}
	.nox_account_desktop_portlet_content_intro {
		margin: 0 0 25px 0;
	}
	.nox_account_desktop_portlet_content_row {
		position: relative;
		margin: 0 0 5px 0;
		line-height: 30px;
		display: table;
		width: 100%;
		min-height: 30px;
	}
	.nox_account_desktop_portlet_content_row:last-child {
		margin: 0;
	}
	.nox_account_desktop_portlet_content_row.single .nox_account_desktop_portlet_content_label {
		float: left;
		position: relative;
		padding: 0 10px 0 0;
		width: auto;
	}
	.nox_account_desktop_portlet_content_row.single .nox_account_desktop_portlet_content_value {
		float: left;
		padding: 0;
		width: auto;
	}
	.nox_account_desktop_portlet_content_label {
		position: absolute;
		text-align: right;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 30px;
		width: 280px;
		z-index: 1;
	}
	.nox_account_desktop_portlet_content_value {
		position: relative;
		padding: 0 0 0 310px;
	}
	.nox_account_desktop_portlet_content_value .nox_input {
		margin: 2px 10px 2px 0;
		text-align: left;
		width: 200px;
	}
	.nox_account_desktop_portlet_content_value_editing_alert {
		display: table;
		width: 100%;
	}
	.nox_account_desktop_portlet_content_value_editing_alert .alert {
		position: relative;
		margin: 8px 0 5px 0;
		padding: 6px 10px 8px 10px;
		border: 1px solid #FFFFFF;
		border-radius: 15px;
		background: #FFFFFF;
		color: #333333;
		text-align: center;
		font-size: 11px;
		line-height: 16px;
		display: inline-block;
		width: 200px;
	}
	.nox_account_desktop_portlet_content_value_content {
		padding: 5px 0;
		line-height: 20px;
	}
	.nox_account_desktop_portlet_content_value_content .nox_value {
		word-break: break-all;
		word-break: break-word;
	}
	.nox_account_desktop_portlet_content_value_content .nox_value.blue {
		color: #A5E5FF;
	}
	.nox_account_desktop_portlet_content_value_content .nox_value.green {
		color: #00FF00;
	}
	.nox_account_desktop_portlet_content_value_content .nox_value.red {
		color: #FFBBBB;
	}
	.nox_account_desktop_portlet_content_value_content .nox_value,
	.nox_account_desktop_portlet_content_value_buttons button {
		margin: 0 10px 0 0;
	}
	.nox_account_desktop_portlet_content_value_content .nox_toggle_button {
		margin: -3px 0 -4px 0;
	}
	.nox_account_desktop_portlet_content_value_loading img {
		margin: -2px 0 0 0;
	}
	.nox_account_desktop_portlet_content_value_buttons .nox_button.nox_is_mobile {
		margin: 0 5px 0 0;
	}
	.nox_account_desktop_portlet_content_value_buttons button.nox_is_desktop.last,
	.nox_account_desktop_portlet_content_value_buttons button.nox_is_mobile.last,
	.nox_account_desktop_portlet_content_value_buttons button:last-child {
		margin: 0;
	}
	.nox_account_desktop_portlet_content_loading {
		text-align: center;
	}
	.nox_account_desktop_portlet_content_accordion_block {
		padding: 5px 0 0 0;
	}
	.nox_account_desktop_portlet_content_accordion_block.active .nox_account_desktop_portlet_content_accordion_block_item_head {
		border-bottom: 1px solid #999999;
		background: #404040B3;
	}
	.nox_account_desktop_portlet_content_accordion_block.active .nox_account_desktop_portlet_content_accordion_block_item_body {
		border: none;
	}
	.nox_account_desktop_portlet_content_accordion_block_item {
		border: 1px solid #999999;
		border-radius: 10px;
		background: #505050B3;
		overflow: hidden;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_head {
		position: relative;
		padding: 10px 40px 10px 20px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		font-size: 12px;
		line-height: 20px;
		min-height: 40px;
		z-index: 1;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_head_title {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_head_title svg {
		margin: 0 0 0 8px;
		color: #FFBBBB;
		font-size: 10px;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_head_title svg.active {
		color: #ABFFAB;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_head_toggle {
		position: absolute;
		top: 20px;
		right: 20px;
		margin: -10px 0 0 0;
		font-size: 20px;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_body {
		position: relative;
		margin: 0 20px 20px 20px;
		padding: 20px 0 0 0;
		border-top: 1px solid #999999;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_body_value pre {
		padding: 15px 20px;
		border: 1px solid #999999;
		font-family: monospace;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_body_value table {
		margin: 0;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_body_content.empty {
		margin: -20px 0 0 0;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_body_loading,
	.nox_account_desktop_portlet_content_accordion_block_item_body_buttons {
		margin: 20px 0 0 0;
		text-align: right;
		line-height: 20px;
		min-height: 20px;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_body_buttons button {
		margin: 0 0 0 10px;
	}
	.nox_account_desktop_portlet_content_accordion_block_item_body_buttons button:first-child {
		margin: 0;
	}
	.nox_account_desktop_portlet_content_alert .alert {
		margin: 20px 0 0 0;
		color: #FFBBBB;
	}
	.nox_account_desktop_portlet_loading,
	.nox_account_desktop_portlet_buttons {
		padding: 15px 30px 20px 30px;
		border-top: 1px solid #858585;
		background: #333333CC;
		text-align: right;
		display: block;
		width: 100%;
	}
	.nox_account_desktop_portlet_loading img {
		margin: 15px 0 9px 0;
	}
	.nox_account_desktop_portlet_button {
		margin: 0 0 0 5px;
		display: inline-block;
	}
	.nox_account_desktop_portlet_button:first-child {
		margin: 0;
	}
	.nox_account_desktop_portlet_accordion {
		margin: -15px 15px 15px 15px;
		border-radius: 0 0 15px 15px;
		background: #000000CC;
		font-size: 12px;
		line-height: 22px;
	}
	.nox_account_desktop_portlet_accordion.red {
		background: #FF0000B3;
	}
	.nox_account_desktop_portlet_accordion.violet {
		background: #771177D9;
	}
	.nox_account_desktop_portlet_accordion_loading {
		padding: 10px;
		text-align: center;
		line-height: 20px;
		height: 42px;
	}
	.nox_account_desktop_portlet_accordion_button {
		padding: 10px 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
		height: 42px;
	}
	.nox_account_desktop_portlet_accordion_block {
		padding: 30px;
	}
	.nox_account_desktop_portlet_accordion_block .nox_account_desktop_portlet_accordion_button {
		margin: -30px 0 30px 0;
		border-bottom: 1px solid #FFFFFF;
	}
	.nox_account_desktop_portlet_accordion_block_content_description {
		margin: 0 0 30px 0;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_portlet_accordion_block_content_description span a {
		color: #A5E5FF;
	}
	.nox_account_desktop_portlet_accordion_block_content_description .button {
		float: left;
		position: relative;
		margin: 0 15px 0 0;
		color: #DDDDDD;
		cursor: pointer;
		text-align: center;
		text-decoration: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		width: 130px;
	}
	.nox_account_desktop_portlet_accordion_block_content_description .button svg {
		margin: -5px 0;
		color: #DDDDDD;
		font-size: 85px;
		opacity: 0.8;
	}
	.nox_account_desktop_portlet_accordion_block_content_description .button:hover svg {
		color: #F78383;
		opacity: 1;
	}
	.nox_account_desktop_widgets {
		position: absolute;
		top: 0;
		right: 0;
		width: 300px;
	}
	.nox_account_desktop_widgets .nox_account_desktop_widget:last-child {
		margin: 0 0 55px 0;
	}
	.nox_account_desktop_widget_1 .nox_account_desktop_portlet {
		margin: 0 0 80px 0;
	}
	.nox_account_desktop_widget_1 .nox_countdown_content,
	.nox_account_desktop_widget_2 .nox_countdown_content {
		margin: 0 0 20px 0;
	}
	.nox_account_desktop_widget_2_text {
		margin: 0 0 20px 0;
		text-align: center;
		line-height: 20px;
	}
	.nox_account_desktop_widget_2_text .datetime {
		margin: 10px 0 0 0;
	}
	.nox_account_desktop_widget_4_1_item,
	.nox_account_desktop_widget_4_2_item,
	.nox_account_desktop_widget_5_item {
		font-size: 12px;
	}
	.nox_account_desktop_widget_4_1_label,
	.nox_account_desktop_widget_4_2_label,
	.nox_account_desktop_widget_5_label {
		float: left;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_widget_4_1_value,
	.nox_account_desktop_widget_4_2_value,
	.nox_account_desktop_widget_5_value {
		text-align: right;
	}
	.nox_account_desktop_widget_4_1_value,
	.nox_account_desktop_widget_4_2_value {
		font-size: 11px;
	}
	.nox_account_desktop_widget_5_value a {
		border-bottom: 1px dashed #FFFFFF;
		text-decoration: none;
		color: #FFFFFF;
	}
	.nox_account_desktop_widget_5_item_quests {
		margin: 25px auto 0 auto;
		border-radius: 50%;
		background: #FFFFFF4D;
		display: block;
		width: 100px;
		height: 100px;
	}
	.nox_account_desktop_widget_5_item_quests_circle_mask,
	.nox_account_desktop_widget_5_item_quests_circle_mask_fill {
		position: absolute;
		border-radius: 50%;
		width: 100px;
		height: 100px;
	}
	.nox_account_desktop_widget_5_item_quests_circle_mask {
		clip: rect(0, 100px, 100px, 50px);
	}
	.nox_account_desktop_widget_5_item_quests_circle_mask_fill {
		background: #00FF00;
		clip: rect(0, 50px, 100px, 0);
	}
	.nox_account_desktop_widget_5_item_quests_circle_data {
		position: absolute;
		margin: 5px;
		padding: 24px 0;
		border-radius: 50%;
		background: #5695CE;
		color: #FFFFFF;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		width: 90px;
		height: 90px;
	}
	.nox_account_desktop_widget_6_events_buttons {
		text-align: center;
	}
	.nox_account_desktop_widget_6_events_buttons .nox_button {
		margin: 0 0 15px 0 !important;
	}
	.nox_account_desktop_widget_7 .nox_countdown .title {
		margin: 0 0 20px 0;
		text-transform: none;
		font-size: 11px;
	}
	.nox_account_desktop_widget_7_item {
		margin: 5px 0 0 0;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_widget_7_item.hidden {
		opacity: 0.2;
	}
	.nox_account_desktop_widget_7_item .green {
		color: #00FF00;
	}
	.nox_account_desktop_widget_7_item .orange {
		color: #FFC660;
	}
	.nox_account_desktop_widget_7_item .red {
		color: #FFBBBB;
	}
	.nox_account_desktop_widget_7_item_symbol {
		float: left;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		display: inline-block;
		width: 55px;
	}
	.nox_account_desktop_widget_7_item_icon {
		float: left;
		text-align: center;
		display: inline-block;
		width: calc(50% - 55px);
	}
	.nox_account_desktop_widget_7_item_icon.green svg {
		margin: 0 0 1px 0;
		font-size: 8px;
	}
	.nox_account_desktop_widget_7_item_icon_block {
		display: inline-block;
		width: 20px;
		height: 100%;
	}
	.nox_account_desktop_widget_7_item_bar {
		float: right;
		position: relative;
		top: 2px;
		border-radius: 9px;
		background: #E26868;
		display: inline-block;
		width: 50%;
		height: 18px;
	}
	.nox_account_desktop_widget_7_item_bar_label {
		position: absolute;
		top: -25px;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 10px;
	}
	.nox_account_desktop_widget_7_item_bar_value {
		position: absolute;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 10px;
		line-height: 18px;
		z-index: 1;
	}
	.nox_account_desktop_widget_7_item_bar_label.sell,
	.nox_account_desktop_widget_7_item_bar_value.sell {
		left: 5px;
	}
	.nox_account_desktop_widget_7_item_bar_label.buy,
	.nox_account_desktop_widget_7_item_bar_value.buy {
		right: 5px;
	}
	.nox_account_desktop_widget_7_item_bar_line {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		border-left: 1px solid #FFFFFF;
		border-radius: 0 9px 9px 0;
		background: #48B849;
	}
	.nox_account_desktop_widget_7_text {
		text-align: justify;
		font-size: 10px;
	}
	.nox_account_desktop_widget_7_text p:last-child {
		margin: 0;
	}
	.nox_account_desktop_widget_7_text p .nox_button:first-child {
		margin: 5px 0 0 0;
	}
	.nox_account_desktop_footer {
		margin: 30px 0 0 0;
		color: #555555;
		text-align: center;
	}
	.nox_account_desktop_footer.account {
		padding: 0 315px 0 0;
	}
	.nox_account_desktop_footer_copyright a {
		color: #555555;
	}
	.nox_account_desktop_footer_copyright a:hover {
		opacity: 0.8;
	}
	.nox_account_desktop_footer_social_networks {
		margin: 10px 0 5px 0;
	}
	.nox_account_desktop_footer_social_networks a {
		margin: 0 3px;
		color: #333333B3;
		font-size: 26px;
	}
	.nox_account_desktop_footer_social_networks a:hover {
		color: #111111B3;
	}
	.nox_account_desktop_footer_mobile_applications img {
		margin: 0 -4px -9px -4px;
		width: 140px;
		opacity: 0.7;
	}
	.nox_account_desktop_footer_mobile_applications a:hover img {
		opacity: 0.8;
	}
	.nox_account_desktop_background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #FFFFFFE6;
		display: none;
		width: 100%;
		height: 100%;
		z-index: 15;
	}
	.nox_account_desktop_background.active {
		display: block;
	}
	.nox_account_preload {
		position: absolute;
		display: none;
		width: 0;
		height: 0;
	}

	@media (max-width: 1080px) {
		.nox_account_desktop_portlet_content_label {
			width: 255px;
		}
		.nox_account_desktop_portlet_content_value {
			padding: 0 0 0 285px;
		}
		.nox_account_desktop_portlet_content_value .nox_input {
			width: 154px;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_sidebar {
			position: fixed;
			background: #FFFFFF;
			overflow-x: hidden;
			overflow-y: auto;
			display: none;
			height: 100vh;
			z-index: 1;
		}
		.nox_account_sidebar_mobile {
			display: block;
		}
		.nox_account_desktop {
			padding: 50px 10px 20px 10px;
		}
		.nox_account_desktop_header {
			display: none;
		}
		.nox_account_desktop_page_loading {
			height: calc(100vh - 60px);
		}
		.nox_account_desktop_page_content_row {
			margin: 0;
			display: block;
		}
		.nox_account_desktop_page_content_col {
			float: none;
			padding: 0;
		}
		.nox_account_desktop_page_content_col .nox_account_desktop_portlet {
			margin: 0 0 10px 0;
			height: auto;
		}
		.nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet {
			padding: 0;
		}
		.nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet_content {
			height: auto;
		}
		.nox_account_desktop_page_content_col.buttons .nox_account_desktop_portlet_buttons {
			position: relative;
		}
		.nox_account_desktop_page_content_title,
		.nox_account_desktop_page_content_navigation_title {
			font-size: 12px;
		}
		.nox_account_desktop_page_content_navigation {
			margin: 0 0 10px 0;
		}
		.nox_account_desktop_page_content_navigation.extra {
			margin: -8px 0 10px 0;
		}
		.nox_account_desktop_page_content_loading,
		.nox_account_desktop_page_content_buttons {
			text-align: center;
		}
		.nox_account_desktop_portlet {
			margin: 0 0 10px 0;
		}
		.nox_account_desktop_portlet.avatar {
			margin: 63px 0 10px 0;
		}
		.nox_account_desktop_portlet .nox_account_desktop_portlet_content {
			padding: 20px;
			font-size: 12px;
			line-height: 20px;
		}
		.nox_account_desktop_portlet.avatar .nox_account_desktop_portlet_content {
			padding: 30px 20px 20px 20px;
		}
		.nox_account_desktop_portlet_title {
			padding: 15px 20px;
			font-size: 13px;
		}
		.nox_account_desktop_portlet_content_edit {
			top: 15px;
			right: 20px;
		}
		.nox_account_desktop_portlet_content_avatar_menu {
			display: block;
		}
		.nox_account_desktop_portlet_content_line {
			margin: 20px 0;
		}
		.nox_account_desktop_portlet_content_intro {
			margin: 0 0 15px 0;
		}
		.nox_account_desktop_portlet_content_value .nox_input {
			width: 200px;
		}
		.nox_account_desktop_portlet_content_accordion {
			margin: -5px 0 0 0;
		}
		.nox_account_desktop_portlet_buttons {
			padding: 15px 20px 20px 20px;
		}
		.nox_account_desktop_portlet_accordion {
			margin: -10px 15px 15px 15px;
		}
		.nox_account_desktop_portlet_accordion_button {
			font-size: 11px;
		}
		.nox_account_desktop_portlet_accordion_block {
			padding: 30px 15px 15px 15px;
		}
		.nox_account_desktop_portlet_accordion_block .nox_account_desktop_portlet_accordion_button {
			margin: -30px 0 15px 0;
		}
		.nox_account_desktop_portlet_accordion_block_content_description {
			margin: 0 0 15px 0;
		}
		.nox_account_desktop_widgets .nox_account_desktop_widget:last-child {
			margin: 0 0 50px 0;
		}
		.nox_account_desktop_widget_1 .nox_account_desktop_portlet {
			margin: 0 0 73px 0;
		}
		.nox_account_desktop_widget_5_item_quests {
			margin: 20px auto 5px auto;
		}
		.nox_account_desktop_widget_6_events_buttons .nox_button {
			margin: 0 0 10px 0 !important;
		}
		.nox_account_desktop_footer {
			margin: 20px 0 0 0;
		}
	}
	@media (max-width: 800px) {
		.nox_account_desktop_portlet_content_row {
			margin: 0;
		}
		.nox_account_desktop_portlet_content_label {
			float: left;
			position: relative;
			padding: 0 10px 0 0;
			width: 50%;
		}
		.nox_account_desktop_portlet_content_value {
			float: right;
			padding: 0 0 0 10px;
			width: 50%;
		}
		.nox_account_desktop_portlet_content_value .nox_input {
			width: 180px;
		}
		.nox_account_desktop_portlet_content_value_editing .nox_is_desktop {
			display: none;
		}
		.nox_account_desktop_portlet_content_value_editing .nox_is_mobile {
			display: inline;
		}
		.nox_account_desktop_portlet_content_value_editing_alert .alert {
			margin: 8px 0;
		}
	}
	@media (max-width: 700px) {
		.nox_account_desktop_portlet_content_video .video {
			float: none;
			left: 50%;
			margin: 5px 0 25px -195px;
		}
	}
	@media (max-width: 650px) {
		.nox_account_desktop_page_content_title {
			margin: 0 0 10px 0;
		}
		.nox_account_desktop_page_content_navigation.extra {
			margin: 0 0 10px 0;
		}
		.nox_account_desktop_page_content_navigation_filters,
		.nox_account_desktop_page_content_navigation_filters:first-child {
			margin: 0 0 10px 0;
			padding: 0;
		}
		.nox_account_desktop_page_content_navigation_filters:last-child {
			margin: 0;
		}
		.nox_account_desktop_page_content_navigation_filters_label {
			position: relative;
			border-radius: 15px 15px 2px 2px;
			width: 100%;
		}
		.nox_account_desktop_page_content_navigation_filters_block,
		.nox_account_desktop_page_content_navigation_filters_value {
			padding: 2px 0 0 0;
			width: 100% !important;
		}
		.nox_account_desktop_page_content_navigation_filters_value:last-child div,
		.nox_account_desktop_page_content_navigation_filters_select.last,
		.nox_account_desktop_page_content_navigation_filters_checkboxes:last-child {
			border-radius: 2px 2px 15px 15px;
		}
		.nox_account_desktop_page_content_navigation_filters_checkboxes {
			padding: 15px;
		}
		.nox_account_desktop_page_content_navigation_filters_checkboxes .nox_checkbox,
		.nox_account_desktop_page_content_navigation_filters_checkboxes .nox_checkbox:last-child {
			margin: 0 0 5px 0;
			width: 100%;
		}
		.nox_account_desktop_page_content_navigation_filters_checkboxes .nox_checkbox:last-child {
			margin: 0 0 3px 0;
		}
	}
	@media (max-width: 644px) {
		.nox_account_desktop_widgets {
			position: relative;
			width: 100%;
		}
		.nox_account_desktop_widgets .nox_account_desktop_widget:last-child {
			margin: 0;
		}
		.nox_account_desktop_footer.account {
			padding: 0;
		}
	}
	@media (max-width: 550px) {
		.nox_account_desktop_portlet_content_img_250 img {
			float: none;
			position: relative;
			left: 50%;
			margin: 0 0 20px -100px;
			width: 200px;
			height: 200px;
		}
		.nox_account_desktop_portlet_content_row {
			line-height: 24px;
			min-height: 24px;
		}
		.nox_account_desktop_portlet_content_label {
			font-size: 10px;
			line-height: 24px;
			width: auto;
		}
		.nox_account_desktop_portlet_content_value {
			float: left;
			padding: 0;
			font-size: 10px;
			line-height: 24px;
			width: auto;
		}
		.nox_account_desktop_portlet_content_value .nox_input {
			font-size: 10px;
			line-height: 20px;
			height: 20px;
		}
		.nox_account_desktop_portlet_content_value .nox_toggle_button {
			margin: 1px 0;
		}
		.nox_account_desktop_portlet_content_value .nox_toggle_button .v-switch-core {
			border-radius: 11px !important;
			width: 35px !important;
			height: 22px !important;
		}
		.nox_account_desktop_portlet_content_value .nox_toggle_button .v-switch-button {
			width: 16px !important;
			height: 16px !important;
		}
		.nox_account_desktop_portlet_content_value .nox_toggle_button.toggled .v-switch-button {
			transform: translate3d(16px, 3px, 0) !important;
		}
		.nox_account_desktop_portlet_content_value_editing_alert .alert {
			width: 100%;
		}
		.nox_account_desktop_portlet_content_value_content {
			padding: 2px 0;
		}
		.nox_account_desktop_portlet_loading {
			text-align: center;
		}
		.nox_account_desktop_portlet_buttons {
			padding: 20px;
		}
		.nox_account_desktop_portlet_buttons .nox_button,
		.nox_account_desktop_portlet_buttons .nox_dropdown,
		.nox_account_desktop_portlet_button {
			margin: 5px 0 0 0;
			width: 100%;
		}
		.nox_account_desktop_portlet_buttons .nox_button:first-child,
		.nox_account_desktop_portlet_buttons .nox_dropdown:first-child {
			margin: 0;
		}
		.nox_account_desktop_portlet_accordion_block_content_description .button {
			float: none;
			left: 50%;
			margin: 0 0 10px -100px;
			display: block;
			width: 200px;
		}
	}
	@media (max-width: 450px) {
		.nox_account_desktop_portlet_content_img_200 img {
			float: none;
			position: relative;
			left: 50%;
			margin: 0 0 20px -100px;
			width: 200px;
			height: 200px;
		}
		.nox_account_desktop_portlet_content_video .video {
			left: auto;
			margin: 5px 0 25px 0;
			width: 100%;
		}
	}
	@media (max-width: 350px) {
		.nox_account_desktop_portlet_content_label {
			padding: 0 8px 0 0;
		}
		.nox_account_desktop_portlet_content_label .nox_tooltip_icon {
			margin: 0 0 0 8px;
		}
	}
</style>
