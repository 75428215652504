<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_quests">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.16', 27)"></div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_portlet_content_img_200">
							<img src="@/assets/images/quests_logo.png">
							<div v-html="$store.getters.getLanguageText('3.16', 28)"></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_quests_tasks">
					<div class="nox_account_desktop_portlet" :class="{ blue: value.status !== 2 }" v-for="(value, index) in noxQuestsProgress" :key="index">
						<div class="nox_account_desktop_portlet_title">{{ (noxQuestsProgress.length - index) }}. <span v-html="value['name_' + $store.state.noxLanguage]"></span><br><span class="nox_account_desktop_quests_tasks_status" :class="[(value.status == 2 ? 'green' : 'white')]"><font-awesome-icon :icon="['fas', (value.status == 2 ? 'check' : 'hourglass-half')]" /><span class="nox_account_desktop_quests_tasks_status_value" v-html="$store.getters.getLanguageText('3.16', 29, { options: [value.status], countdown: noxQuestsProgressCountdownValue })"></span></span></div>
						<div class="nox_account_desktop_portlet_content"><span class="nox_account_desktop_quests_tasks_content" v-html="value['text_' + $store.state.noxLanguage]"></span></div>
						<div class="nox_account_desktop_portlet_loading" :class="{ hide: !value.is_loading }" v-if="(value.type == 1 || (value.type == 2 && !value.status))"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_portlet_buttons" :class="{ hide: value.is_loading }" v-if="(value.type == 1 || (value.type == 2 && !value.status))">
							<div class="nox_account_desktop_portlet_button" v-for="(value2, index2) in value.buttons" :key="index2" v-if="value.type == 1">
								<a :href="value2.url" class="nox_button big green" @click="(value.status ? true : axios('addQuestsProgress', index, value.id))" target="_blank" v-if="value2.type == 1"><font-awesome-icon :icon="[value2.icon.slice(0, 3), value2.icon.slice(7)]" /><span v-html="value2['name_' + $store.state.noxLanguage]"></span></a>
								<div class="nox_dropdown" :class="{ open: $store.state.noxDropdownId == ('noxQuestTaskButton_' + index + '_' + index2) }" @blur="$parent.$parent.closeDropdown($event)" tabindex="-1" v-else-if="value2.type == 2">
									<div class="nox_dropdown_toggle green" @click="$parent.$parent.toggleDropdown('noxQuestTaskButton_' + index + '_' + index2)">
										<div class="nox_dropdown_toggle_value"><font-awesome-icon :icon="[value2.icon.slice(0, 3), value2.icon.slice(7)]" /><span v-html="value2['name_' + $store.state.noxLanguage]"></span></div>
										<div class="nox_dropdown_toggle_button"><font-awesome-icon :icon="['fas', 'chevron-' + ($store.state.noxDropdownId == ('noxQuestTaskButton_' + index + '_' + index2) ? 'up' : 'down')]" /></div>
									</div>
									<div class="nox_dropdown_options">
										<a :href="value3.url" class="nox_dropdown_options_item" v-for="(value3, index3) in value2.items" :key="index3" @click="(value.status ? true : axios('addQuestsProgress', index, value.id))" target="_blank" v-html="value3['name_' + $store.state.noxLanguage]"></a>
									</div>
								</div>
							</div>
							<button type="button" class="nox_button big white" @click="axios('addQuestsProgress', index, value.id)" v-if="value.type == 2 && !value.status"><font-awesome-icon :icon="['fas', 'check']" /><span v-html="$store.getters.getLanguageText('1.1', 107)"></span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxQuestsProgress: [],
			noxQuestsProgressCountdownTime: 0,
			noxQuestsProgressCountdownValue: '',
			noxQuestsProgressCountdownTimerId: 0,
			noxQuestsCheckTaskId: 0,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group !== 4) {
					this.$parent.$parent.$notify.close('quest');
					this.axios('getQuestsProgress');
				}
				else {
					this.$router.push({ path: '/account/tariffs' });
				}
			},
			initQuestsProgress: function(data) {
				var _this = this;
				_this.noxQuestsProgress = [];
				if (data.account_data !== undefined) {
					_this.$parent.$parent.initAccountData(data.account_data);
				}
				if (data.quests_progress !== undefined) {
					if (data.quests_progress.length) {
						for (var i in data.quests_progress) {
							data.quests_progress[i].is_loading = false;
							_this.noxQuestsProgress.push(data.quests_progress[i]);
						}
					}
				}
				if (_this.noxQuestsProgress.length) {
					if (_this.noxQuestsProgress[0].type == 1) {
						if (_this.noxQuestsProgress[0].status == 1) {
							if (_this.noxQuestsProgress[0].deferral_at > 0) {
								_this.noxQuestsProgressCountdownTime = _this.noxQuestsProgress[0].deferral_at * 1000;
								if (_this.noxQuestsProgressCountdownTime > new Date().getTime()) {
									_this.noxQuestsProgressCountdownTimerId = 0; _this.getQuestsProgressCountdown();
									_this.noxQuestsProgressCountdownTimerId = setInterval(function() { _this.getQuestsProgressCountdown(); }, 1000);
								}
							}
						}
					}
					else if (_this.noxQuestsProgress[0].type == 2) {
						if (_this.noxQuestsProgress[0].status == 0) {
							if (_this.noxQuestsProgress[0].id !== _this.noxQuestsCheckTaskId) {
								if (!_this.$cookies.get('nox' + _this.$store.state.noxAccountData.id + 'IsQuestsProgress')) {
									setTimeout(function() { _this.noxQuestsProgress[0].is_loading = true; }, 0);
									setTimeout(function() { _this.axios('addQuestsProgress', 0, _this.noxQuestsProgress[0].id); }, 3000);
								}
							}
						}
					}
				}
			},
			getQuestsProgressCountdown: function() {

				var _this = this;
				var seconds = Math.ceil((_this.noxQuestsProgressCountdownTime - (new Date().getTime())) / 1000); seconds = (seconds > 0 ? seconds : 0);
				var days = parseInt(seconds / 86400); seconds = seconds % 86400;
				var hours = parseInt(seconds / 3600); seconds = seconds % 3600;
				var minutes = parseInt(seconds / 60); seconds = parseInt(seconds % 60);

				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
					_this.noxQuestsProgress[0].is_loading = true; _this.noxQuestsProgressCountdownValue = '0 ' + _this.$store.getters.getLanguageText('1.9', 5, { options: [_this.$parent.$parent.getDeclension(0)] }); clearInterval(_this.noxQuestsProgressCountdownTimerId); setTimeout(function() { _this.axios('addQuestsProgress', 0, _this.noxQuestsProgress[0].id); }, 1000);
				}
				else {
					_this.noxQuestsProgressCountdownValue = ((days > 0 ? (days + ' ' + _this.$store.getters.getLanguageText('1.9', 2, { options: [_this.$parent.$parent.getDeclension(days)] })) : '') + ((days > 0 ? ' ' : '') + ((days > 0 || hours > 0) ? (hours + ' ' + _this.$store.getters.getLanguageText('1.9', 3, { options: [_this.$parent.$parent.getDeclension(hours)] })) : '')) + (((days > 0 || hours > 0) > 0 ? ' ' : '') + ((days > 0 || hours > 0 || minutes > 0) ? (minutes + ' ' + _this.$store.getters.getLanguageText('1.9', 4, { options: [_this.$parent.$parent.getDeclension(minutes)] })) : '')) + (((days > 0 || hours > 0 || minutes > 0) > 0 ? ' ' : '') + ((days > 0 || hours > 0 || minutes > 0 || seconds > 0) ? (seconds + ' ' + _this.$store.getters.getLanguageText('1.9', 5, { options: [_this.$parent.$parent.getDeclension(seconds)] })) : '')));
				}
			},
			axios: function(type, var1, var2) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getQuestsProgress') {
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2/account/quests/progress';
					}
					else if (type == 'addQuestsProgress') {
						_this.noxQuestsCheckTaskId = var2;
						_this.noxQuestsProgress[var1].is_loading = true;
						config.url = '/v2/account/quests/progress';
						config.data = { task_id: var2 };
						config.method = 'post';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getQuestsProgress') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								_this.initQuestsProgress(data.data);
								_this.$cookies.set(('nox' + _this.$store.state.noxAccountData.id + 'IsQuestsProgress'), true, '60s');
							}
							else if (type == 'addQuestsProgress') {
								_this.initQuestsProgress(data.data);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						if (type == 'addQuestsProgress') {
							_this.noxQuestsProgress[var1].is_loading = false;
						}
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_quests_tasks_status:before {
		content: '\(';
		padding: 0 5px 0 0;
	}
	.nox_account_desktop_quests_tasks_status:after {
		content: '\)';
		padding: 0 0 0 5px;
	}
	.nox_account_desktop_quests_tasks_status:before,
	.nox_account_desktop_quests_tasks_status:after {
		position: relative;
		top: -1px;
	}
	.nox_account_desktop_quests_tasks_status.green {
		color: #00FF00;
	}
	.nox_account_desktop_quests_tasks_status svg {
		margin: 0 5px 0 0;
		font-size: 13px;
	}
	.nox_account_desktop_quests_tasks_status_value span {
		text-transform: none;
		font-size: 12px;
	}
	.nox_account_desktop_quests_tasks_content ul li {
		list-style: inside;
	}
	.nox_account_desktop_quests_tasks_content .quest_task_type_1,
	.nox_account_desktop_quests_tasks_content .quest_task_type_2 {
		display: table;
		width: 100%;
	}
	.nox_account_desktop_quests_tasks_content .quest_task_type_1 img {
		float: left;
		margin: 5px 20px 10px 0;
		display: block;
		width: 200px;
	}
	.nox_account_desktop_quests_tasks_content .quest_task_type_2 {
		text-align: center;
	}
	.nox_account_desktop_quests_tasks_content .quest_task_type_2 img {
		position: relative;
		left: 50%;
		margin: 0 0 0 -100px;
		display: block;
		width: 200px;
	}

	@media (max-width: 550px) {
		.nox_account_desktop_quests_tasks_content .quest_task_type_1 img {
			position: relative;
			float: none;
			left: 50%;
			margin: 0 0 15px -75px;
			width: 150px !important;
			height: auto !important;
		}
	}
</style>
