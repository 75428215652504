<template>
	<div class="nox">
		<div class="nox_bg"><img src="@/assets/images/bg.png"></div>
		<div class="nox_auth login">
			<div class="nox_auth_block">
				<div is="NoxAuthHeader"></div>
				<div class="nox_auth_block_content">
					<div class="nox_auth_block_content_name" v-html="$store.getters.getLanguageText('2.1', 0)"></div>
					<form class="nox_auth_block_content_form">
						<div class="nox_auth_block_content_form_row">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.1', 1)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="text" class="nox_input" id="email" name="email" v-model.trim="noxEmail" autocomplete="off">
								<div v-html="noxAlertEmail"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row last">
							<div class="nox_auth_block_content_form_row_label" v-html="$store.getters.getLanguageText('2.1', 2)"></div>
							<div class="nox_auth_block_content_form_row_input">
								<input type="password" class="nox_input" id="password" name="password" v-model.trim="noxPassword" autocomplete="off">
								<div v-html="noxAlertPassword"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_row last">
							<div class="nox_auth_block_content_form_row_recaptcha">
								<vue-recaptcha class="nox_recaptcha" ref="recaptcha" size="normal" theme="light" :sitekey="$store.state.noxRecaptchaSiteKey" @verify="verifyRecaptcha" @expired="resetRecaptcha"></vue-recaptcha>
								<div v-html="noxAlertRecaptcha"></div>
							</div>
						</div>
						<div class="nox_auth_block_content_form_alert" v-html="noxAlert"></div>
						<div class="nox_auth_block_content_form_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_auth_block_content_form_buttons" :class="{ hide: noxIsLoading }">
							<button type="submit" class="nox_button common green" @click="submit($event, 'login')" v-html="$store.getters.getLanguageText('1.1', 29)"></button>
						</div>
						<div class="nox_auth_block_content_form_footer">
							<router-link to="/password/recovery">[<span v-html="$store.getters.getLanguageText('1.1', 33)"></span>]</router-link>
							<router-link to="/registration">[<span v-html="$store.getters.getLanguageText('1.1', 30)"></span>]</router-link>
						</div>
					</form>
				</div>
				<div is="NoxAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import NoxAuthHeader from './NoxAuthHeader.vue'
	import NoxAuthFooter from './NoxAuthFooter.vue'
	import VueRecaptcha from 'vue-recaptcha'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertEmail: '',
			noxAlertPassword: '',
			noxAlertRecaptcha: '',
			noxEmail: '',
			noxPassword: '',
			noxRecaptcha: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			isReady: function() {
				var _this = this;
				_this.$parent.initRecaptcha();
				if (_this.$route.query.r !== undefined) {
					_this.$cookies.set('noxInvitation', _this.$route.query.r, '6m');
					_this.$cookies.set('noxInvitation', _this.$route.query.r, '6m', '/', '.nox.bz', true);
					setTimeout(function() { _this.$router.push({ path: '/registration' }); }, 0);
				}
				if (localStorage.getItem('noxToken')) {
					setTimeout(function() { _this.$router.push({ path: '/account' }); }, 0);
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertEmail = '';
				this.noxAlertPassword = '';
				this.noxAlertRecaptcha = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 4); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 5); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 16); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 18); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 27); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 32); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 34); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 35); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 55); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			resetRecaptcha: function() {
				this.noxRecaptcha = '';
				this.$refs.recaptcha.reset();
			},
			verifyRecaptcha: function(token) {
				this.noxRecaptcha = token;
			},
			setRoutePath: function() {
				var noxRoutePath = '/account';
				if (this.$cookies.get('noxRoutePath')) {
					noxRoutePath = this.$cookies.get('noxRoutePath'); this.$cookies.remove('noxRoutePath');
				}
				this.$router.push({ path: noxRoutePath });
			},
			submit: function(event, type) {
				event.preventDefault(); this.axios(type);
			},
			axios: function(type) {

				var _this = this;
				if (_this.$store.state.noxOAuthTokenForLogin) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxOAuthTokenForLogin }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'login') {
						if (!_this.noxEmail) {
							_this.noxAlertEmail = _this.getError(1);
						}
						else if (!_this.$store.state.noxRegexEmail.test(_this.noxEmail)) {
							_this.noxAlertEmail = _this.getError(2);
						}
						if (!_this.noxPassword) {
							_this.noxAlertPassword = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexPassword.test(_this.noxPassword) || _this.noxPassword.search(_this.$store.state.noxRegexSearchENRULetters) < 0) {
							_this.noxAlertPassword = _this.getError(4);
						}
						if (!_this.noxRecaptcha) {
							_this.noxAlertRecaptcha = _this.getError(5);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/oauth/login';
							config.data = { email: _this.noxEmail, password: _this.noxPassword, recaptcha: _this.noxRecaptcha };
							config.method = 'post';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'login') {
									if (data.data.token) {
										_this.setRoutePath();
										_this.$session.start();
										_this.$session.set('login', 'START');
										localStorage.setItem('noxToken', data.data.token);
									}
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'login') {
										if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlertEmail = _this.getError(1); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlertEmail = _this.getError(2); }
										else if (data.response.data.error == 'PASSWORD_IS_EMPTY') { _this.noxAlertPassword = _this.getError(3); }
										else if (data.response.data.error == 'PASSWORD_NOT_VALID') { _this.noxAlertPassword = _this.getError(4); }
										else if (data.response.data.error == 'RECAPTCHA_IS_EMPTY') { _this.noxAlertRecaptcha = _this.getError(5); }
										else if (data.response.data.error == 'RECAPTCHA_NOT_VALID') { _this.noxAlertRecaptcha = _this.getError(5); }
									}
								}
								else if (data.response.status == 404) {
									if (type == 'login') {
										if (data.response.data.error == 'BAD_CREDENTIALS') { _this.noxAlert = _this.getError(6); }
									}
								}
								else if (data.response.status == 409) {
									if (type == 'login') {
										if      (data.response.data.error == 'ACCOUNT_NOT_ACTIVATED') { _this.noxAlert = _this.getError(7); }
										else if (data.response.data.error == 'ACCOUNT_BANNED') { _this.noxAlert = _this.getError(8); }
										else if (data.response.data.error == 'TOKEN_NOT_CREATED') { _this.noxAlert = _this.getError(9); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
							if (type == 'login') {
								_this.resetRecaptcha();
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
			}
		},
		components: {
			NoxAuthHeader,
			NoxAuthFooter,
			VueRecaptcha
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_auth {
		text-align: center;
		vertical-align: middle;
		display: table-cell;
		width: 100vw;
		height: 100vh;
	}
	.nox_auth_block {
		padding: 30px 0;
		display: inline-block;
		width: 500px;
	}
	.nox_auth_block_logo {
		margin: 0 0 20px 0;
	}
	.nox_auth_block_logo a {
		text-decoration: none;
	}
	.nox_auth_block_logo img {
		margin: 0 0 -5px 0;
		height: 60px;
	}
	.nox_auth_block_logo div {
		display: inline-block;
		width: 60%;
	}
	.nox_auth_block_logo div:last-child {
		margin: 8px 0 0 0;
		color: #555555;
		font-size: 15.8px;
	}
	.nox_auth_block_content {
		margin: 15px 0;
		padding: 20px 30px;
		border-radius: 15px;
		background: #333333B3;
		color: #FFFFFF;
		overflow: hidden;
	}
	.nox_auth_block_content a,
	.nox_auth_block_content input,
	.nox_auth_block_content button {
		color: #FFFFFF;
	}
	.nox_auth_block_content_name {
		margin: -20px -30px 20px -30px;
		padding: 15px 30px;
		border-bottom: 1px solid #858585;
		background: #3333334D;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 15px;
	}
	.nox_auth_block_content_form .alert {
		position: relative;
		margin: 8px 0;
		padding: 5px 10px 7px 10px;
		border: 1px solid #FFFFFF;
		border-radius: 15px;
		background: #FFFFFF;
		color: #333333;
		font-size: 10px;
		line-height: 14px;
		display: inline-block;
		width: 100%;
	}
	.nox_auth_block_content_form_text {
		margin: 0 0 20px 0;
	}
	.nox_auth_block_content_form_text.mailings_unsubscribe_success {
		margin: 0 0 5px 0;
		color: #77FF77;
	}
	.nox_auth_block_content_form_row {
		position: relative;
		display: table;
		width: 100%;
	}
	.nox_auth_block_content_form_row .alert:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 100%;
		margin-left: -8px;
		border: 8px solid transparent;
		border-bottom-color: #FFFFFF;
		pointer-events: none;
		width: 0;
		height: 0;
	}
	.nox_auth_block_content_form_row.last .alert {
		margin: 8px 0 0 0;
	}
	.nox_auth_block_content_form_row.agreement {
		margin: 20px 0 0 0;
	}
	.nox_auth_block_content_form_row.agreement .nox_checkbox {
		padding: 0 0 0 25px;
		font-size: 10px;
	}
	.nox_auth_block_content_form_row.agreement .nox_checkbox a {
		margin: 0 1px;
	}
	.nox_auth_block_content_form_row.agreement .between .alert {
		margin: 8px 0;
	}
	.nox_auth_block_content_form_row_label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px 0 0;
		overflow: hidden;
		text-align: right;
		line-height: 28px;
		width: 28%;
		height: 30px;
	}
	.nox_auth_block_content_form_row_input {
		float: right;
		width: 72%;
	}
	.nox_auth_block_content_form_row_recaptcha {
		float: right;
		position: relative;
		margin: 20px 0 0 0;
		display: table;
		width: 70%;
		height: 60px;
	}
	.nox_auth_block_content_form_row_recaptcha .nox_recaptcha {
		position: absolute;
		top: 0;
		left: 50%;
		margin: 0 0 0 -86px;
		transform: scale(0.795);
		transform-origin: 0 0;
	}
	.nox_auth_block_content_form_row_recaptcha .alert {
		margin: 70px 0 0 0 !important;
	}
	.nox_auth_block_content_form_alert {
		margin: 20px 0 0 0;
	}
	.nox_auth_block_content_form_text .alert,
	.nox_auth_block_content_form_alert .alert {
		margin: 0;
	}
	.nox_auth_block_content_form_loading,
	.nox_auth_block_content_form_buttons {
		margin: 20px 0 0 0;
		text-align: right;
		line-height: 20px;
		display: table;
		width: 100%;
		height: 20px;
	}
	.nox_auth_block_content_form_buttons button {
		margin: 0 0 0 10px;
	}
	.nox_auth_block_content_form_buttons button:first-child {
		margin: 0;
	}
	.nox_auth_block_content_form_footer {
		margin: 18px -30px -20px -30px;
		padding: 12px 0 15px 0;
		border-top: 1px solid #858585;
		background: #3333334D;
	}
	.nox_auth_block_content_form_footer a {
		margin: 0 3px;
		text-decoration: none;
		font-size: 11px;
	}
	.nox_auth_block_content_form_footer a:hover {
		text-decoration: underline;
	}
	.nox_auth_block_content_form_footer_mcountdown {
		margin: 0 0 5px 0;
		color: #A5E5FF;
	}
	.nox_auth_block_content_form_footer_mcountdown .nox_mcountdown {
		margin: 0 0 0 5px;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_auth_block_footer_menu {
		margin: 5px 0 0 0;
		display: inline;
	}
	.nox_auth_block_footer_menu a {
		margin: 0 5px 5px 0;
		padding: 0 15px;
		border-radius: 15px;
		background: #33333380;
		color: #FFFFFF;
		text-align: center;
		text-decoration: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 30px;
		display: inline-block;
	}
	.nox_auth_block_footer_menu a:hover {
		background: #333333B3;
	}
	.nox_auth_block_footer_language {
		margin: 5px 0;
		border-radius: 15px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #33333380;
		color: #FFFFFF;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 30px;
		display: inline-block;
		width: 100px;
		height: 30px;
	}
	.nox_auth_block_footer_language_name {
		margin: 0 6px;
		line-height: 15px;
		display: inline-block;
		width: 24px;
	}
	.nox_auth_block_footer_language_name img {
		margin: -2px 0 0 0;
	}
	.nox_auth_block_footer_language_name.text {
		cursor: pointer;
	}
	.nox_auth_block_footer_language_name.text:hover,
	.nox_auth_block_footer_language_name.text:focus {
		border-bottom: 1px solid #FFFFFF;
	}
	.nox_auth_block_footer_language_name.text.active {
		border-bottom: 1px solid #FFFFFF;
		cursor: default;
	}
	.nox_auth_block_footer_copyright,
	.nox_auth_block_footer_social_networks {
		margin: 15px 0 0 0;
		color: #555555;
		display: inline-block;
		width: 100%;
	}
	.nox_auth_block_footer_copyright a {
		color: #555555;
	}
	.nox_auth_block_footer_copyright a:hover {
		opacity: 0.8;
	}
	.nox_auth_block_footer_social_networks {
		margin: 10px 0 5px 0;
	}
	.nox_auth_block_footer_social_networks a {
		margin: 0 3px;
		color: #333333B3;
		font-size: 26px;
	}
	.nox_auth_block_footer_social_networks a:hover {
		color: #111111B3;
	}
	.nox_auth_block_footer_mobile_applications img {
		margin: 0 -4px -9px -4px;
		width: 140px;
		opacity: 0.7;
	}
	.nox_auth_block_footer_mobile_applications a:hover img {
		opacity: 0.8;
	}

	@media (max-width: 540px) {
		.nox_auth_block {
			padding: 30px 10px;
			width: 100%;
		}
		.nox_auth_block_content {
			padding: 20px;
		}
		.nox_auth_block_content_form_row_label {
			padding: 0 0 0 10px;
			width: auto;
		}
		.nox_auth_block_content_form_row_input {
			position: relative;
			width: 100%;
		}
		.nox_auth_block_content_form_row_recaptcha {
			width: 100%;
		}
		.nox_auth_block_content_form_row_recaptcha .nox_recaptcha {
			margin: 0 0 0 -120px;
		}
		.nox_auth_block_content_form_loading,
		.nox_auth_block_content_form_buttons {
			text-align: center;
		}
		.nox_auth.login .nox_input {
			padding: 0 10px 2px 75px;
		}
		.nox_auth.registration .nox_input {
			padding: 0 10px 2px 125px;
		}
		.nox_auth.password_recovery .nox_input {
			padding: 0 10px 2px 55px;
		}
		.nox_auth.password_recovery_reset .nox_input {
			padding: 0 10px 2px 125px;
		}
	}
	@media (max-width: 350px) {
		.nox_auth_block_footer_menu a {
			padding: 0 14px;
		}
	}
</style>
