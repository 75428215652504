import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		noxRegex_w: /^\w+$/,
		noxRegex_d: /^\d+$/,
		noxRegexList: /^[a-z0-9.,_]+$/,
		noxRegexEmail: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
		noxRegexPhone: /^\+[1-9]{1,3}[0-9]{7,10}$/,
		noxRegexCountry: /^[A-Z]{2}$/,
		noxRegexLanguage: /^[a-z]{2}$/,
		noxRegexUsername: /^[a-zа-яё0-9\-_. ]{4,30}$/ui,
		noxRegexPassword: /^[a-zа-яё0-9]{6,30}$/ui,
		noxRegexAnyLink: /^https?:\/\/(www\.)?(.+)\.(.+)$/,
		noxRegexForex4you: /^https?:\/\/(\w+\.)?(fx4u|forex4you|forex4uid)\.(online|com|org|io|(ru\.com))(\/[a-z]{2})?(\/user-registration)?\/\?affid=\w{1,}$/,
		noxRegexForex_Box: /^https?:\/\/(www\.)?my\.forex-box\.com\/aff\.php\?aff=\d+$/,
		noxRegexVkontakte: /^https?:\/\/(www\.)?(m\.)?vk\.com\/[\w.]+$/,
		noxRegexYoutube: /^https?:\/\/(www\.)?youtu(\.be\/|be\.com\/watch\?v=)[\w-]+$/,
		noxRegexInstagram: /^https?:\/\/(www\.)?instagram\.com\/(?!(p|tv)\/.+)[\w.]+$/,
		noxRegexFacebook: /^https?:\/\/(www\.)?(((m|mbasic)\.)?facebook|fb)\.(com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w.-]*)?$/,
		noxRegexWhatsApp: /^\+[1-9]{1,3}[0-9]{1,10}$/,
		noxRegexTelegram: /^@[a-z0-9_]{5,100}$/i,
		noxRegexTelegramId: /^[1-9]{1}\d+$/,
		noxRegexSkype: /^[a-z][\w\-.,]{5,31}$/i,
		noxRegexERC20: /^(0x){1}[a-f0-9]{40}$/i,
		noxRegexTRC20: /^(T){1}[a-z0-9]{33}$/i,
		noxRegexSHA256: /^(0x){1}[a-f0-9]{64}$/i,
		noxRegexCoupon: /^[1-9A-HK-NP-Z]{20}$/,
		noxRegexHEXColor: /^#[a-f0-9]{6}$/i,
		noxRegexServerName: /^([a-z0-9-]+\.)*[a-z]+$/,
		noxRegexServerAddr: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
		noxRegexServerPort: /^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/,
		noxRegexServerV1Username: /^U\d+$/,
		noxRegexServerV2Username: /^nox\d+$/,
		noxRegexAPIEndpointsPath: /^[a-z0-9/{}_]{1,100}$/,
		noxRegexFloatNumbers: /^\d+\.\d+$/,
		noxRegexNumbersAndComma: /^[0-9,]+$/i,
		noxRegexIntOrFloatNumbers: /^\d+(\.\d+)?$/,
		noxRegexENLetters: /^[a-z]+$/i,
		noxRegexENLettersAndSpace: /^[a-z ]+$/i,
		noxRegexENLettersAndNumbers: /^[a-z0-9]+$/i,
		noxRegexENLettersAndNumbersAndMarks: /^[a-z0-9№%!?.,:;=*+−\-/@_«»()[\]\r\n ]+$/i,
		noxRegexENRULetters: /^[a-zа-яё]+$/ui,
		noxRegexENRULettersAndNumbers: /^[a-zа-яё0-9]+$/ui,
		noxRegexENRULettersAndNumbersAndSpace: /^[a-zа-яё0-9 ]+$/ui,
		noxRegexENRULettersAndNumbersAndMarks: /^[a-zа-яё0-9№%!?.,:;=*+−\-/@_«»()[\]\r\n ]+$/ui,
		noxRegexIsMobile1Part: /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i,
		noxRegexIsMobile2Part: /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i,
		noxRegexReplaceNotENLetters: /[^a-z]/gi,
		noxRegexSearchENRULetters: /[a-zа-яё]/ui,
		noxRegexSearchRULetters: /[а-яё]/ui,
		noxAppVersion: '1.6.0.2',
		noxLanguage: 'ru',
		noxRN: 'SL34179',
		noxBaseURL: 'https://nox.bz',
		noxMeBaseURL: 'https://me.nox.bz',
		noxApiBaseURL: 'https://api.nox.bz',
		noxForumBaseURL: 'https://forum.nox.bz',
		noxMobileApp1URL: 'https://apps.apple.com/ru/app/nox-ecosystem/id1618230599',
		noxMobileApp2URL: 'https://play.google.com/store/apps/details?id=com.art_cloud_technologies.nox',
		noxMobileApp3URL: 'https://appgallery.huawei.com/app/C105950607',
		noxSiteName: 'ART Cloud Technologies',
		noxRecaptchaSiteKey: '6LdSg8IUAAAAAPBsgvHfxBRg6s0_rslZCf42Iq16',
		noxInfoTokenForNews: '15d99f72925dd1d7a169a298cc547a9a3196a29e9a7af9593a1df833bdb2',
		noxInfoTokenForStats: '18d7ae422ac38f992bcc2b09d3ff9795c9a5659f1d46b0ffe6ddff2a6f69',
		noxInfoTokenForWidgets: '2e8acef03b948926b53e6a40b1a87472f1083e18d3b08b7270ab009f5cce',
		noxInfoTokenForAnalysis: '34ff34a0034b993784cc54e42918c5ac89255c94c2cec4a523436931a573',
		noxOAuthTokenForEmail: '0ed066f29b2ea3c51f14a975102fbe225abe82de960e74537189235ca260',
		noxOAuthTokenForLogin: '14577a3f1931025cdfbd7e3964f80add3df497c7b87330fbbcea9bf8307d',
		noxOAuthTokenForPassword: '2f0c36e16ec66e997933f23213df9c4e1cb1ff6763dc3bd3e515018b9bb7',
		noxOAuthTokenForRegistration: '353b26c8efd54e2acf9a63092f263fd2802a75901fc93e1feaf37d22dd44',
		noxOAuthTokenForMailings: '401c241ed54a7287ed4626d372c3d392d9c274e8c48a56a7595272cb18b2',
		noxOAuthTokenForNOXAPI: '50194a563deb6e1e0a6b061e0f12bac480672096224b0d82c2301f1ae221',
		noxModalIntervalId: 0,
		noxSidebarMiniId: 0,
		noxCropperType: '',
		noxCropperData: {},
		noxDropdownId: '',
		noxCSelectId: '',
		noxMTabs: {},
		noxIsEditor: false,
		noxIsAccount: false,
		noxIsLoading: false,
		noxIsMobile: false,
		noxIsMobileMenu: false,
		noxIsLanguageRU: false,
		noxIsLanguageEN: false,
		noxIsModalClose: false,
		noxLanguages: {},
		noxAccountData: {},
		noxSystemSettings: {},
		noxTransliteration: {},
		noxStrategySettings: {},
		noxServerSettings: {},
		noxMasterSettings: {},
		noxBotsStrategies: {},
		noxBotsSettings: {},
		noxBotsMasters: {},
		noxMBotsData: [],
		noxNumberOfBots: 0,
		noxNumberOfDemoBots: 0,
		noxNumberOfFreeBots: 0,
		noxNumberOfPaidBots: 0,
		noxTBData: {},
		noxTransfersData: [],
		noxCouponsData: [],
		noxCouponsLimitOffset: 0,
		noxCouponsLimitCount: 10,
		noxChartColors: [
			'#7CB5EC', '#434348', '#1EC455', '#F7A35C', '#8085E9',
			'#F15C80', '#E4D354', '#2B908F', '#F45B5B', '#91E8E1',
			'#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
			'#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#000000',
			'#2F7ED8', '#0D233A', '#8BBC21', '#910000', '#1AADCE',
			'#492970', '#F28F43', '#77A1E5', '#C42525', '#A6C96A',
			'#7CB5EC', '#434348', '#1EC455', '#F7A35C', '#8085E9',
			'#F15C80', '#E4D354', '#2B908F', '#F45B5B', '#91E8E1',
			'#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
			'#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#000000',
			'#2F7ED8', '#0D233A', '#8BBC21', '#910000', '#1AADCE',
			'#492970', '#F28F43', '#77A1E5', '#C42525', '#A6C96A',
			'#7CB5EC', '#434348', '#1EC455', '#F7A35C', '#8085E9',
			'#F15C80', '#E4D354', '#2B908F', '#F45B5B', '#91E8E1',
			'#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
			'#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#000000',
			'#2F7ED8', '#0D233A', '#8BBC21', '#910000', '#1AADCE',
			'#492970', '#F28F43', '#77A1E5', '#C42525', '#A6C96A',
			'#7CB5EC', '#434348', '#1EC455', '#F7A35C', '#8085E9',
			'#F15C80', '#E4D354', '#2B908F', '#F45B5B', '#91E8E1',
			'#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
			'#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#000000',
			'#2F7ED8', '#0D233A', '#8BBC21', '#910000', '#1AADCE',
			'#492970', '#F28F43', '#77A1E5', '#C42525', '#A6C96A',
			'#7CB5EC', '#434348', '#1EC455', '#F7A35C', '#8085E9',
			'#F15C80', '#E4D354', '#2B908F', '#F45B5B', '#91E8E1',
			'#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
			'#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#000000',
			'#2F7ED8', '#0D233A', '#8BBC21', '#910000', '#1AADCE',
			'#492970', '#F28F43', '#77A1E5', '#C42525', '#A6C96A'
		],
		noxWidget7CLVDataByMultipliers: {
			'0.1': { index: 0, color: 'red' },
			'0.2': { index: 0, color: 'red' },
			'0.3': { index: 0, color: 'red' },
			'0.4': { index: 0, color: 'red' },
			'0.5': { index: 0, color: 'red' },
			'0.6': { index: 0, color: 'red' },
			'0.7': { index: 1, color: 'orange' },
			'0.8': { index: 2, color: 'green' },
			'0.9': { index: 2, color: 'green' },
			'1.0': { index: 2, color: 'green' }
		},
		noxWidget7CLVDataByTrendStatuses: {
			'-1.0': { index: 0, icon: 'angle-double-down', color: 'red' },
			'-0.9': { index: 0, icon: 'angle-double-down', color: 'red' },
			'-0.8': { index: 0, icon: 'angle-double-down', color: 'red' },
			'-0.7': { index: 0, icon: 'angle-double-down', color: 'red' },
			'-0.6': { index: 1, icon: 'angle-down', color: 'orange' },
			'-0.5': { index: 1, icon: 'angle-down', color: 'orange' },
			'-0.4': { index: 1, icon: 'angle-down', color: 'orange' },
			'-0.3': { index: 1, icon: 'angle-down', color: 'orange' },
			'-0.2': { index: 2, icon: 'circle', color: 'green' },
			'-0.1': { index: 2, icon: 'circle', color: 'green' },
			'0.0': { index: 2, icon: 'circle', color: 'green' },
			'0.1': { index: 2, icon: 'circle', color: 'green' },
			'0.2': { index: 2, icon: 'circle', color: 'green' },
			'0.3': { index: 3, icon: 'angle-up', color: 'orange' },
			'0.4': { index: 3, icon: 'angle-up', color: 'orange' },
			'0.5': { index: 3, icon: 'angle-up', color: 'orange' },
			'0.6': { index: 3, icon: 'angle-up', color: 'orange' },
			'0.7': { index: 4, icon: 'angle-double-up', color: 'red' },
			'0.8': { index: 4, icon: 'angle-double-up', color: 'red' },
			'0.9': { index: 4, icon: 'angle-double-up', color: 'red' },
			'1.0': { index: 4, icon: 'angle-double-up', color: 'red' }
		}
	},
	getters: {
		getLanguageText: state => function(type, index, data) {
			var text = state.noxLanguages[type][index];
			if (data !== undefined && Object.keys(data).length) {
				if (data.conditions !== undefined) { text = this.getLanguageTextForDetailsConditions(text, data.conditions); }
				if (data.data_arrays !== undefined) { text = this.getLanguageTextForDetailsDataArrays(text, data.data_arrays); }
				if (data.data_lists !== undefined) { text = this.getLanguageTextForDetailsDataLists(text, data.data_lists); }
				if (data.options !== undefined) { text = this.getLanguageTextForDetailsOptions(text, data.options); }
				text = this.getLanguageTextForDetailsVars(text, data);
			}
			return text;
		},
		getLanguageTextForDetailsVars: state => function(text, details) {
			if (Object.keys(state).length) {
				if (Object.keys(details).length) {
					for (var i in details) {
						if (!['index', 'options', 'conditions', 'data_lists', 'data_arrays'].includes(i)) {
							if (Array.isArray(details[i])) { details[i] = details[i].join(', '); }
							text = text.replace(new RegExp('{{ ' + i + ' }}', 'g'), details[i]);
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsOptions: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\[\[ (.*?) \]\]/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('[[ ', '');
						template[i] = template[i].replace(' ]]', '');
						template[i] = template[i].split('|');
						if (template[i][details[i]] !== undefined) {
							text = text.replace(/\[\[ (.*?) \]\]/, template[i][details[i]]);
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsConditions: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\(\( (.*?) \)\)/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('(( ', '');
						template[i] = template[i].replace(' ))', '');
						if (details[i] !== undefined) {
							text = text.replace(/\(\( (.*?) \)\)/, (Number(details[i]) ? template[i] : ''));
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsDataArrays: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\[ARRAY\] (.*?) \[ARRAY\]/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('[ARRAY] ', '');
						template[i] = template[i].replace(' [ARRAY]', '');
						if (details[i] !== undefined && Object.keys(details[i]).length) {
							if (details[i].indexes !== undefined && details[i].indexes.length) {
								if (details[i].separator !== undefined && details[i].separator !== '') {
									var temp = []; template[i] = String(template[i]).split('|');
									if (template[i].length && template[i].length == details[i].indexes.length) {
										for (var j in details[i].indexes) {
											if (details[i].indexes[j]) { temp.push(template[i][j]); }
										}
									}
									text = text.replace(/\[ARRAY\] (.*?) \[ARRAY\]/, (temp.length ? temp.join(details[i].separator) : ''));
								}
							}
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsDataLists: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\[LIST\] (.*?) \[LIST\]/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('[LIST] ', '');
						template[i] = template[i].replace(' [LIST]', '');
						if (details[i] !== undefined && Object.keys(details[i]).length) {
							if (details[i].data !== undefined && Object.keys(details[i].data).length) {
								if (details[i].count !== undefined && Number(details[i].count) > 0) {
									var temp = '';
									for (var j = 0; j < Number(details[i].count); j++) {
										var temp2 = template[i];
										for (var k in details[i].data) {
											temp2 = (k == 'odata1' ? this.getLanguageTextForDetailsOptions(temp2, [details[i].data[k][j]]) : temp2.replace((new RegExp('{{ ' + k + ' }}', 'g')), details[i].data[k][j]));
										}
										temp += temp2;
									}
									text = text.replace(/\[LIST\] (.*?) \[LIST\]/, temp);
								}
							}
						}
					}
				}
			}
			return text;
		}
	}
})
