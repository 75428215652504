<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_admin_landings">
				<div class="nox_account_desktop_portlet">
					<div class="nox_account_desktop_portlet_title">Список элементов</div>
					<div class="nox_account_desktop_portlet_content">
						<div class="nox_account_desktop_admin_landings_elements">
							<div v-if="noxLandingsElementsData.length">
								<vue-easy-lightbox class="nox_lightbox" :index="noxLightboxIndex" :imgs="noxLightboxImages" :visible="noxIsLightboxVisible" @hide="hideLightbox"></vue-easy-lightbox>
								<table class="nox_table">
									<thead>
										<tr>
											<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
											<th>Фото элемента</th>
											<th>Код элемента</th>
											<th>Приоритет</th>
											<th>Статус</th>
											<th><font-awesome-icon :icon="['fas', 'image']" /></th>
											<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
											<th><font-awesome-icon :icon="['fas', 'times']" /></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value, index) in noxLandingsElementsData" :key="index">
											<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.id }}</td>
											<td data-label="Фото элемента:">
												<div class="image" @click="showLightbox(0, [value.image_1])" v-if="value.image_1 && value.image_2">
													<font-awesome-icon :icon="['fas', 'search-plus']" />
													<img :src="value.image_2">
												</div>
											</td>
											<td data-label="Код элемента:">{{ value.code }}</td>
											<td data-label="Приоритет:">{{ value.priority }}</td>
											<td data-label="Статус:"><toggle-button class="nox_toggle_button" :value="toggleValue(value.is_active)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActive($event, index, value.id)"></toggle-button></td>
											<td :data-label="$store.getters.getLanguageText('1.7.1', 28) + ':'"><button type="button" class="nox_button icon blue" @click="$parent.$parent.chooseCropperImage('cropImageForEditLandingsElement', { index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'download']" /></button></td>
											<td :data-label="$store.getters.getLanguageText('1.7.1', 28) + ':'"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalAdminLandings', { type: 'editLandingsElement', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
											<td :data-label="$store.getters.getLanguageText('1.7.1', 29) + ':'"><button type="button" class="nox_button icon red" @click="$modal.show('NoxModalAdminLandings', { type: 'deleteLandingsElement', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-else>Список элементов пуст!</div>
						</div>
					</div>
					<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
						<button type="button" class="nox_button big" @click="axios('getLandingsElementsData')" v-if="noxLandingsElementsDataLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
						<button type="button" class="nox_button big green" @click="$parent.$parent.chooseCropperImage('cropImageForAddLandingsElement')"><font-awesome-icon :icon="['fas', 'plus']" />Добавить элемент</button>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalCropper"></div>
				<div is="NoxModalAdminLandings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxLandingsElementsData: [],
			noxLandingsElementsDataLimitOffset: 0,
			noxLandingsElementsDataLimitCount: 20,
			noxLightboxIndex: 0,
			noxLightboxImages: [],
			noxIsLightboxVisible: false,
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios('getLandingsElementsData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getLandingsElementsData') {
						if (!_this.noxLandingsElementsDataLimitOffset) {
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						_this.noxIsPortletLoading = true;
						config.url = '/v2/admin/account/landings/elements';
						config.params = { limit_offset: _this.noxLandingsElementsDataLimitOffset, limit_count: _this.noxLandingsElementsDataLimitCount };
					}
					else if (type == 'editLandingsElementIsActive') {
						_this.noxLandingsElementsData[var1].is_active = var3;
						config.url = '/v2/admin/account/landings/elements/is_active';
						config.data = { id: var2, is_active: var3 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getLandingsElementsData') {
								_this.$parent.$parent.goToTop(!_this.noxLandingsElementsDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.landings_elements_data !== undefined) {
									if (data.data.landings_elements_data.data.length) {
										for (var i in data.data.landings_elements_data.data) {
											_this.noxLandingsElementsData.push(data.data.landings_elements_data.data[i]);
										}
									}
									_this.noxLandingsElementsDataLimitOffset = data.data.landings_elements_data.count > _this.noxLandingsElementsDataLimitOffset + _this.noxLandingsElementsDataLimitCount ? _this.noxLandingsElementsDataLimitOffset + _this.noxLandingsElementsDataLimitCount : 0;
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForIsActive: function(event, index, id) {
				this.axios('editLandingsElementIsActive', index, id, Number(event.value));
			},
			showLightbox: function(index, images) {
				this.noxLightboxIndex = index;
				this.noxLightboxImages = images;
				this.noxIsLightboxVisible = true;
			},
			hideLightbox: function() {
				this.noxIsLightboxVisible = false;
			}
		},
		components: {
			NoxModalCropper: () => import('@/views/modal_components/NoxModalCropper.vue'),
			NoxModalAdminLandings: () => import('@/views/modal_components/NoxModalAdminLandings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_admin_landings_elements tr th:nth-child(1),
	.nox_account_desktop_admin_landings_elements tr td:nth-child(1),
	.nox_account_desktop_admin_landings_elements tr th:nth-child(5),
	.nox_account_desktop_admin_landings_elements tr td:nth-child(5),
	.nox_account_desktop_admin_landings_elements tr th:nth-child(6),
	.nox_account_desktop_admin_landings_elements tr td:nth-child(6),
	.nox_account_desktop_admin_landings_elements tr th:nth-child(7),
	.nox_account_desktop_admin_landings_elements tr td:nth-child(7),
	.nox_account_desktop_admin_landings_elements tr th:nth-child(8),
	.nox_account_desktop_admin_landings_elements tr td:nth-child(8) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_admin_landings_elements tr th:nth-child(3),
	.nox_account_desktop_admin_landings_elements tr td:nth-child(3),
	.nox_account_desktop_admin_landings_elements tr th:nth-child(4),
	.nox_account_desktop_admin_landings_elements tr td:nth-child(4) {
		width: 120px;
	}
	.nox_account_desktop_admin_landings_elements tr td .image {
		position: relative;
		display: inline-block;
	}
	.nox_account_desktop_admin_landings_elements tr td .image:hover svg {
		display: block;
	}
	.nox_account_desktop_admin_landings_elements tr td .image img {
		border: 5px solid #F1F1F1;
		border-radius: 5px;
		box-shadow: 0 0 5px #333333;
		background: #FFFFFF;
		cursor: pointer;
		max-width: 300px;
	}
	.nox_account_desktop_admin_landings_elements tr td .image svg {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -20px 0 0 -20px;
		padding: 10px;
		border-radius: 50%;
		box-shadow: 0 0 10px #333333;
		background: #FFFFFF;
		color: #000000;
		cursor: pointer;
		font-size: 40px;
		display: none;
		opacity: 0.6;
	}
</style>
