<template>
	<div id="app">
		<router-view></router-view>
		<input type="file" class="hide" ref="cropper_image" name="image" accept="image/png, image/jpeg" @change="changeCropperImage">
		<notifications class="nox_notification clipboard" group="clipboard" position="top center" :duration="3000"></notifications>
		<notifications class="nox_notification quest" group="quest" position="bottom left" animation-type="velocity">
			<template slot="body" slot-scope="{ item, close }">
				<div class="nox_notification_quest">
					<div class="nox_notification_quest_head">
						<div class="nox_notification_quest_title" v-html="item.title"></div>
						<div class="nox_notification_quest_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					</div>
					<div class="nox_notification_quest_body">
						<div class="nox_notification_quest_content" v-html="item.text"></div>
						<div class="nox_notification_quest_buttons">
							<div class="nox_notification_quest_button_close" @click="close">
								<router-link :to="item.data.button_link" v-html="item.data.button_name"></router-link>
							</div>
						</div>
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
	import Highcharts from 'highcharts';
	export default {
		watch: {
			$route(to, from) {
				if (to.path !== from.path) {
					var search = to.path.indexOf('/account/client_program');
					if (search >= 0 && to.params.key !== undefined) { return false; }
					this.goToTop(true); this.initMetaTags();
				}
			}
		},
		methods: {
			isMobile: function() {
				var _this = this;
				(function(userAgent) {
					if (_this.$store.state.noxRegexIsMobile1Part.test(userAgent) ||
							_this.$store.state.noxRegexIsMobile2Part.test(userAgent.substr(0,4))) {
							_this.$store.state.noxIsMobile = true;
					}
				})(navigator.userAgent || navigator.vendor || window.opera);
			},
			isInteger: function(num) {
				return (num ^ 0) === num;
			},
			isJSONParse: function(data) {
				try { JSON.parse(data); } catch (e) { return false; } return true;
			},
			isEventResize: function() {
				window.addEventListener('resize', this.initEventResize);
			},
			isEventScroll: function() {
				window.addEventListener('scroll', this.initEventScroll);
			},
			isEventStorage: function() {
				window.addEventListener('storage', this.initEventStorage);
			},
			isEventOrientation: function() {
				window.addEventListener('orientationchange', this.initEventOrientation);
			},
			isActivePaymentMethod: function(id, mode) {
				var isActive = 0;
				if (Number(this.$store.state.noxSystemSettings['is_active_payment_' + mode + '_method_' + id])) {
					var countries = String(this.$store.state.noxSystemSettings['payment_' + mode + '_countries_for_method_' + id]).split(',');
					if (countries.length) {
						if      (countries[0] == 'ALL') { isActive = 1; }
						else if (countries[0] == '!ALL') { isActive = (!countries.includes(this.$store.state.noxAccountData.country) ? 1 : 0); }
						else if (countries.includes(this.$store.state.noxAccountData.country)) { isActive = 1; }
					}
				}
				return isActive;
			},
			initEventResize: function() {
				this.initSidebarMini();
				this.initModalWidth();
				this.resizeMTabs();
			},
			initEventScroll: function() {
				this.initSidebarMini();
			},
			initEventStorage: function(event) {
				if (event.key == 'noxToken') {
					if (localStorage.getItem('noxToken')) {
						var _this = this; setTimeout(function() { _this.$router.push({ path: '/account' }); }, 0);
					}
					else {
						this.exit(false);
					}
				}
			},
			initEventOrientation: function() {
				this.initModalWidth();
				this.resizeMTabs();
			},
			initAccountData: function(data) {
				var _this = this;
				if (data !== undefined) {
					_this.$store.state.noxAccountData = data;
					_this.$store.state.noxAccountData.phone = ((data.phone ? '+' : '') + data.phone);
					if (!data.phone || !data.country) { setTimeout(() => _this.$modal.show('NoxModalWelcome'), 0); }
					if (_this.$store.state.noxAccountData.language !== _this.$store.state.noxLanguage) {
						_this.setLanguage(_this.$store.state.noxAccountData.language);
					}
				}
			},
			initSystemSettings: function(settings) {
				if (settings !== undefined) {
					for (var i in settings) {
						this.$store.state.noxSystemSettings[settings[i].key] = settings[i].value;
					}
					if (this.$store.state.noxSystemSettings['vat'] !== undefined) {
						this.$store.state.noxSystemSettings['vat_value'] = this.getVatByCountry();
					}
				}
			},
			initSidebarMini: function() {
				if (document.documentElement.clientWidth >= 1000) {
					var sidebar = document.getElementById('nox_account_sidebar');
					this.$store.state.noxSidebarMiniId = (sidebar && ((window.pageYOffset || document.documentElement.scrollTop) >= ((sidebar.clientHeight > sidebar.offsetHeight ? sidebar.clientHeight : sidebar.offsetHeight) + 200)) ? 1 : (this.$store.state.noxSidebarMiniId ? 2 : 0));
				}
				else {
					this.$store.state.noxSidebarMiniId = 0;
				}
			},
			initModalWidth: function() {
				if (document.getElementsByClassName('v--modal')[0] !== undefined) {
					if (document.getElementsByClassName('v--modal-overlay')[0] !== undefined) {
						if (document.getElementsByClassName('v--modal-overlay')[0].getAttribute('modal-width') !== null) {
							if (document.getElementsByClassName('v--modal')[0].clientWidth !== document.documentElement.clientWidth) {
								if (document.documentElement.clientWidth < Number(document.getElementsByClassName('v--modal-overlay')[0].getAttribute('modal-width'))) {
									// if ([1,10,100].includes(this.$store.state.noxAccountData.id)) { alert('Window innerWidth: ' + window.innerWidth + ', Document clientWidth: ' + document.documentElement.clientWidth + ', Modal clientWidth: ' + document.getElementsByClassName('v--modal')[0].clientWidth); }
									document.getElementsByClassName('v--modal')[0].style.width = document.documentElement.clientWidth + 'px';
								}
							}
						}
					}
				}
			},
			initRecaptcha: function() {
				var script = document.createElement('script');
				script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=' + this.$store.state.noxLanguage; script.async = true; script.defer = true;
				document.body.append(script);
			},
			initMetaTags: function() {
				document.title = this.$store.getters.getLanguageText('1.10', (this.$route.meta.transliteration !== undefined && this.$route.meta.transliteration[0] ? this.$route.meta.transliteration[0] : 0));
				document.querySelector('meta[name="description"]').setAttribute('content', this.$store.getters.getLanguageText('1.10', (this.$route.meta.transliteration !== undefined && this.$route.meta.transliteration[1] ? this.$route.meta.transliteration[1] : 1)));
			},
			initBotsData: function(data, index) {
				if (Array.isArray(data) && data.length) {
					this.$store.state.noxMBotsData = []; for (var i in data) { this.$store.state.noxMBotsData.push(this.initBotData(data[i])); }
				}
				else if (Object.keys(data).length && index == undefined) {
					this.$store.state.noxMBotsData.push(this.initBotData(data));
				}
				else if (Object.keys(data).length && index !== undefined) {
					this.$store.state.noxMBotsData.splice(index, 1, this.initBotData(data));
				}
				else if (!Object.keys(data).length && index !== undefined) {
					this.$store.state.noxMBotsData.splice(index, 1);
				}
				if (Object.keys(this.$store.state.noxAccountData).length) {
					if (Object.keys(this.$store.state.noxSystemSettings).length) {
						var noxBotsPerkCount = 0;
						if (this.$store.state.noxMBotsData.length) {
							for (var j in this.$store.state.noxMBotsData) {
								if (this.$store.state.noxMBotsData[j].data.is_perk_free_bot) { noxBotsPerkCount++; }
							}
						}
						this.$store.state.noxNumberOfFreeBots = (([1,2,10,40,41,42,100].includes(this.$store.state.noxAccountData.id) ? 100 : Number(this.$store.state.noxSystemSettings['number_of_free_bots_for_x' + this.$store.state.noxAccountData.package])) + Number(this.$store.state.noxAccountData.count_buy_bots) + noxBotsPerkCount);
						this.$store.state.noxNumberOfPaidBots = (Number(this.$store.state.noxSystemSettings['number_of_paid_bots_for_x' + this.$store.state.noxAccountData.package]) - Number(this.$store.state.noxAccountData.count_buy_bots));
						this.$store.state.noxNumberOfBots = (this.$store.state.noxNumberOfFreeBots + this.$store.state.noxNumberOfPaidBots);
					}
				}
			},
			initBotData: function(data) {
				return {
					data: data,
					info: {
						risk_id: this.getRiskStrategies(data.strategy_id, data.masters, data.multiplier)
					},
					edits: {
						edit_for_bot_number: '',
						edit_for_bot_behavior: ([1,2,3].includes(data.behavior) ? this.getSelectOptions(9)[(data.behavior - 1)] : ''),
						edit_for_bot_behavior_save_id: 0,
						edit_for_bot_threshold: data.threshold,
						edit_for_bot_multiplier: Number(data.multiplier ? data.multiplier : (this.$store.state.noxBotsStrategies[data.strategy_id] !== undefined ? this.$store.state.noxBotsStrategies[data.strategy_id].min_multiplier : 0)),
						edit_for_bot_funding_limit: (data.funding_limit ? data.funding_limit : ''),
						edit_for_bot_symbol_prefix: (data.symbol_prefix ? data.symbol_prefix : ''),
						edit_for_bot_symbol_postfix: (data.symbol_postfix ? data.symbol_postfix : ''),
						edit_for_bot_purpose_of_account: (data.purpose_of_account ? data.purpose_of_account : ''),
						edit_for_bot_max_count_of_nt_symbols: (data.max_count_of_news_trading_symbols ? data.max_count_of_news_trading_symbols : '')
					},
					alerts: {
						alert_for_bot_data: '',
						alert_for_bot_number: '',
						alert_for_bot_threshold: '',
						alert_for_bot_multiplier: '',
						alert_for_bot_funding_limit: '',
						alert_for_bot_symbol_prefix: '',
						alert_for_bot_symbol_postfix: '',
						alert_for_bot_purpose_of_account: '',
						alert_for_bot_is_compound_interest: '',
						alert_for_bot_max_count_of_nt_symbols: ''
					},
					details: {},
					is_shows: {
						is_show_for_bot_prints: false,
						is_show_for_bot_details: false,
						is_show_for_bot_comment: false,
						is_show_for_bot_admin_data: false
					},
					is_edits: {
						is_edit_for_bot_data: false,
						is_edit_for_bot_number: false,
						is_edit_for_bot_threshold: false,
						is_edit_for_bot_multiplier: false,
						is_edit_for_bot_funding_limit: false,
						is_edit_for_bot_symbol_prefix: false,
						is_edit_for_bot_symbol_postfix: false,
						is_edit_for_bot_purpose_of_account: false,
						is_edit_for_bot_is_compound_interest: false,
						is_edit_for_bot_max_count_of_nt_symbols: false
					},
					is_loadings: {
						is_loading_for_bot_data: false,
						is_loading_for_bot_number: false,
						is_loading_for_bot_threshold: false,
						is_loading_for_bot_multiplier: false,
						is_loading_for_bot_funding_limit: false,
						is_loading_for_bot_symbol_prefix: false,
						is_loading_for_bot_symbol_postfix: false,
						is_loading_for_bot_purpose_of_account: false,
						is_loading_for_bot_is_compound_interest: false,
						is_loading_for_bot_max_count_of_nt_symbols: false
					}
				};
			},
			initMTab: function(id, isResize = false) {
				var _this = this;
				setTimeout(function() {
					if (document.getElementById('nox_mtabs_' + id)) {
						if (Object.keys(_this.$store.state.noxMTabs[id]).length) {
							_this.initMTabTools(id); if (isResize) { _this.fillMTab(id); }
						}
					}
				}, 0);
			},
			initMTabOptions: function() {
				return { titles: [], blocks: [], active: 0, offset: 0, offset_width: 0, is_prev: false, is_next: false };
			},
			initMTabTools: function(id) {
				if (document.getElementById('nox_mtabs_titles_' + id)) {
					this.$store.state.noxMTabs[id].is_prev = (this.$store.state.noxMTabs[id].offset ? true : false);
					this.$store.state.noxMTabs[id].is_next = ((document.querySelector('#nox_mtabs_' + id + ' .nox_mtabs_titles_buttons_content').scrollWidth > (document.querySelector('#nox_mtabs_' + id + ' .nox_mtabs_titles_buttons').clientWidth + this.$store.state.noxMTabs[id].offset_width)) ? true : false);
				}
			},
			nextMTab: function(id, isInit = false) {
				this.$store.state.noxMTabs[id].offset++;
				this.$store.state.noxMTabs[id].offset_width += document.querySelector('#nox_mtabs_' + id + ' .nox_mtabs_titles_buttons_content_button:nth-child(' + this.$store.state.noxMTabs[id].offset + ')').offsetWidth;
				if (isInit) { this.initMTab(id, false); }
			},
			prevMTab: function(id, isInit = false) {
				if (this.$store.state.noxMTabs[id].offset) {
					this.$store.state.noxMTabs[id].offset--;
					this.$store.state.noxMTabs[id].offset_width -= document.querySelector('#nox_mtabs_' + id + ' .nox_mtabs_titles_buttons_content_button:nth-child(' + (this.$store.state.noxMTabs[id].offset + 1) + ')').offsetWidth;
					if (isInit) { this.initMTab(id, false); }
				}
			},
			lastMTab: function(id) {
				var _this = this;
				setTimeout(function() {
					if (document.getElementById('nox_mtabs_' + id)) {
						if (Object.keys(_this.$store.state.noxMTabs[id]).length) {
							if (_this.$store.state.noxMTabs[id].is_next) {
								while (_this.$store.state.noxMTabs[id].is_next) {
									_this.nextMTab(id, false);
									_this.initMTabTools(id);
								}
							}
						}
					}
				}, 0);
			},
			fillMTab: function(id) {
				var _this = this;
				setTimeout(function() {
					if (document.getElementById('nox_mtabs_' + id)) {
						if (Object.keys(_this.$store.state.noxMTabs[id]).length) {
							if (_this.$store.state.noxMTabs[id].offset_width && !_this.$store.state.noxMTabs[id].is_next) {
								while (_this.$store.state.noxMTabs[id].offset_width && !_this.$store.state.noxMTabs[id].is_next) {
									_this.prevMTab(id, false);
									_this.initMTabTools(id);
								}
								_this.nextMTab(id, false);
								_this.initMTabTools(id);
							}
						}
					}
				}, 0);
			},
			selectMTab: function(id, index) {
				this.$set(this.$store.state.noxMTabs[id], 'active', index);
			},
			deleteMTab: function(id) {
				var _this = this;
				setTimeout(function() {
					if (document.getElementById('nox_mtabs_' + id)) {
						if (Object.keys(_this.$store.state.noxMTabs[id]).length) {
							if (_this.$store.state.noxMTabs[id].is_prev) {
								_this.prevMTab(id, false);
								_this.initMTabTools(id);
							}
						}
					}
				}, 0);
			},
			resizeMTabs: function() {
				if (Object.keys(this.$store.state.noxMTabs).length) {
					for (var i in this.$store.state.noxMTabs) { this.initMTab(i, true); }
				}
			},
			initLanguage: function() {
				var language = this.$cookies.get('noxLanguage');
				if (!language) { language = (navigator.language || navigator.systemLanguage || navigator.userLanguage).substring(0,2).toLowerCase(); }
				if (!['ru', 'en'].includes(language)) { language = 'ru'; }
				this.setLanguage(language);
			},
			setLanguage: function(language) {
				this.$store.state.noxLanguage = language;
				this.$store.state.noxLanguages = window.noxLanguages[language];
				this.$cookies.set('noxLanguage', language, -1);
				Highcharts.setOptions({ lang: this.getChartLang() });
				document.documentElement.setAttribute('lang', language);
			},
			getNumberPad: function(value) {
				return (Number(value) < 0 ? '−' : '') + (Math.abs(value) < 10 ? '0' : '') + Math.abs(value);
			},
			getNumberFormat: function(value, precision) {
				return (Number(value) < 0 ? '−' : '') + parseFloat(Math.abs(value)).toFixed(precision);
			},
			getNumberFormatForDollar: function(value, precision) {
				return (Number(value) < 0 ? '− ' : '') + '$' + parseFloat(Math.abs(value)).toFixed(precision);
			},
			getNumberFormatForPercent: function(value, precision) {
				return (Number(value) < 0 ? '− ' : '') + parseFloat(Math.abs(value)).toFixed(precision) + '%';
			},
			getFloorAmount: function(value, precision) {
				value = value.toString().split('.');
				return Number(value[0] + (value.length > 1 ? '.' + value[1].substr(0, precision) : ''));
			},
			getDeclension: function(value) {
				value = Math.abs(value);
				return ((value % 100 > 4 && value % 100 < 20) ? 2 : [2,0,1,1,1,2][(value % 10 < 5) ? value % 10 : 5]);
			},
			getTimeSeconds: function() {
				return (new Date().getTime()) / 1000;
			},
			getDaysPassed: function(time) {
				return this.getNumberPad(parseInt((this.getTimeSeconds() - Number(time)) / (24 * 60 * 60)));
			},
			getChartLang: function() {
				return {
					loading: this.$store.getters.getLanguageText('1.6', 38),
					resetZoom: this.$store.getters.getLanguageText('1.6', 37),
					resetZoomTitle: this.$store.getters.getLanguageText('1.6', 39),
					months: [
						this.$store.getters.getLanguageText('1.6', 0),
						this.$store.getters.getLanguageText('1.6', 1),
						this.$store.getters.getLanguageText('1.6', 2),
						this.$store.getters.getLanguageText('1.6', 3),
						this.$store.getters.getLanguageText('1.6', 4),
						this.$store.getters.getLanguageText('1.6', 5),
						this.$store.getters.getLanguageText('1.6', 6),
						this.$store.getters.getLanguageText('1.6', 7),
						this.$store.getters.getLanguageText('1.6', 8),
						this.$store.getters.getLanguageText('1.6', 9),
						this.$store.getters.getLanguageText('1.6', 10),
						this.$store.getters.getLanguageText('1.6', 11)
					],
					shortMonths: [
						this.$store.getters.getLanguageText('1.6', 12),
						this.$store.getters.getLanguageText('1.6', 13),
						this.$store.getters.getLanguageText('1.6', 14),
						this.$store.getters.getLanguageText('1.6', 15),
						this.$store.getters.getLanguageText('1.6', 16),
						this.$store.getters.getLanguageText('1.6', 17),
						this.$store.getters.getLanguageText('1.6', 18),
						this.$store.getters.getLanguageText('1.6', 19),
						this.$store.getters.getLanguageText('1.6', 20),
						this.$store.getters.getLanguageText('1.6', 21),
						this.$store.getters.getLanguageText('1.6', 22),
						this.$store.getters.getLanguageText('1.6', 23)
					],
					weekdays: [
						this.$store.getters.getLanguageText('1.6', 30),
						this.$store.getters.getLanguageText('1.6', 24),
						this.$store.getters.getLanguageText('1.6', 25),
						this.$store.getters.getLanguageText('1.6', 26),
						this.$store.getters.getLanguageText('1.6', 27),
						this.$store.getters.getLanguageText('1.6', 28),
						this.$store.getters.getLanguageText('1.6', 29)
					],
					numericSymbols: [
						this.$store.getters.getLanguageText('1.6', 31),
						this.$store.getters.getLanguageText('1.6', 32),
						this.$store.getters.getLanguageText('1.6', 33),
						this.$store.getters.getLanguageText('1.6', 34),
						this.$store.getters.getLanguageText('1.6', 35),
						this.$store.getters.getLanguageText('1.6', 36)
					]
				};
			},
			getChartFormat: function(event, precision) {
				var result = '<span style="font-size:10px">' + Highcharts.dateFormat('%A, %b %e, %H:%M:%S', event.x) + '</span>';
				for (var i in event.points) { result += '<br><span style="color:' + event.points[i].color + '">\u25CF</span> ' + event.points[i].series.name + ': <b>' + (event.points[i].y).toFixed(precision) + '</b>'; } return result;
			},
			getChartFormatForDollar: function(event, precision, dateFormatType = 0) {
				var format = (['%A, %b %e, %H:%M:%S', '%A, %b %e', '%B %Y'][dateFormatType]);
				var result = '<span style="font-size:10px">' + Highcharts.dateFormat(format, event.x) + '</span>';
				for (var i in event.points) { result += '<br><span style="color:' + event.points[i].color + '">\u25CF</span> ' + event.points[i].series.name + ': <b>' + this.getNumberFormatForDollar(event.points[i].y, precision) + '</b>'; } return result;
			},
			getChartFormatForDollar2: function(event, precision) {
				var result = '<span style="font-size:10px">' + ((event.points && event.points[0]) ? event.points[0].key : event.x) + '</span>';
				for (var i in event.points) { result += '<br><span style="color:' + event.points[i].color + '">\u25CF</span> ' + event.points[i].series.name + ': <b>' + this.getNumberFormatForDollar(event.points[i].y, precision) + '</b>'; } return result;
			},
			getChartFormatForPercent: function(event, precision, dateFormatType = 0) {
				var format = (['%A, %b %e, %H:%M:%S', '%A, %b %e', '%B %Y'][dateFormatType]);
				var result = '<span style="font-size:10px">' + Highcharts.dateFormat(format, event.x) + '</span>';
				for (var i in event.points) { result += '<br><span style="color:' + event.points[i].color + '">\u25CF</span> ' + event.points[i].series.name + ': <b>' + this.getNumberFormatForPercent(event.points[i].y, precision) + '</b>'; } return result;
			},
			getChartFormatForCompoundInterest: function(event, precision) {
				var result = '<span style="font-size:10px">' + event.x + ' ' + this.$store.getters.getLanguageText('1.6.2', 6) + '</span>';
				for (var i in event.points) { result += '<br><span style="color:' + event.points[i].color + '">\u25CF</span> ' + event.points[i].series.name + ': <b>' + (event.points[i].y).toFixed(precision) + '</b>'; } result += '<br><span style="color:#333333">\u25CF</span> ' + this.$store.getters.getLanguageText('1.6.2', 5) + ': <b>' + (event.points[i].total).toFixed(precision) + '</b>'; return result;
			},
			addChartAvgLine: function(event, index, id, details = {}) {
				var avg = 0, amount = 0;
				var min = (event.min !== undefined ? event.min : 0);
				var max = (event.max !== undefined ? event.max : 0);
				var series_0 = (event.target.series[0] !== undefined ? event.target.series[0].points : []);
				var series_1 = (event.target.series[1] !== undefined ? event.target.series[1].points : []);
				var count = Math.floor(details.isCount ? (series_0.length + series_1.length) : ((max - min) / (24 * 60 * 60 * 1000)));
				var data = series_0.concat(series_1);
				if (data.length) {
					for (var i in data) {
						if (data[i].isInside) { amount += data[i].y; }
					}
					if (count) { avg = parseFloat((amount / count).toFixed(2)); }
				}
				event.target.chart.get('chart_' + id + '_' + index + '_avg').removePlotLine('avg');
				event.target.chart.get('chart_' + id + '_' + index + '_avg').addPlotLine({ id: 'avg', color: '#FF5722', dashStyle: 'Dash', label: { x: -32, y: 3, text: '<span>' + avg.toFixed(2) + '%</span>', useHTML: true, style: { padding: '4px 6px', color: '#FFFFFF', fontSize: '9px', fontWeight: 'bold', lineHeight: '10px', borderRadius: '11px', backgroundColor: '#FF5722' }}, value: avg, width: 1.5, zIndex: 10 });
			},
			addChartLockThresholdLine: function(event, value, index, id, is_show = 0, is_show_label = 0) {
				event.target.chart.get('chart_' + id + '_' + index + '_line').removePlotLine('line');
				if (is_show) { event.target.chart.get('chart_' + id + '_' + index + '_line').addPlotLine({ id: 'line', color: '#FF5722', dashStyle: 'Solid', label: { x: -32, y: 3, text: '<span>' + value.toFixed(0) + '%</span>', useHTML: true, style: { padding: '4px 6px', color: '#FFFFFF', fontSize: '9px', fontWeight: 'bold', lineHeight: '10px', borderRadius: '11px', backgroundColor: '#FF5722', opacity: is_show_label }}, value: value, width: 0.5, zIndex: 10 }); }
			},
			getNumberOfMonths: function(from, to) {
				return ((to.getMonth() - from.getMonth() + 1) + ((to.getFullYear() - from.getFullYear()) * 12));
			},
			getSprintFormat: function(value) {
				return ((value = value.split('T')[0].split('-')) && value.length == 3 ? (value[2] + '.' + value[1] + '.' + value[0]) : '');
			},
			getCountries: function() {
				var countries = {};
				for (var i in this.getSelectOptions(1)) {
					countries[this.getSelectOptions(1)[i].code] = this.getSelectOptions(1)[i].label;
				}
				return countries;
			},
			getVatByCountry: function() {
				var country = this.$store.state.noxAccountData.country;
				var vat = 0, vat_array = {}, vat_value = this.$store.state.noxSystemSettings['vat'].split(',');
				for (var v = 0; v < vat_value.length; v += 2) { vat_array[vat_value[v]] = Number(vat_value[v + 1]); }
				vat = ((!vat && vat_array.hasOwnProperty(country)) ? vat_array[country] : 0);
				vat = ((!vat && vat_array.hasOwnProperty('ALL')) ? vat_array['ALL'] : vat);
				return vat;
			},
			getDescription: function(type, data) {
				if (Object.keys(data).length && data.index !== undefined) {
					return this.$store.getters.getLanguageText(type, data.index, { id: data.id, prev_id: data.prev_id, next_id: data.next_id, user_id: data.user_id, name: data.name, name_ru: data.name_ru, name_en: data.name_en, symbol: data.symbol, symbols: data.symbols, count: data.count, value: data.value, number: data.number, amount: Number(data.amount).toFixed(2), commission: data.commission, fee_amount: Number(data.fee_amount).toFixed(2), fee_percent: data.fee_percent, ticket_id: data.ticket_id, address: data.address, txid: data.txid, vat: data.vat, from: data.from, ffrom: Number(data.ffrom).toFixed(1), to: data.to, tto: Number(data.tto).toFixed(1), conditions: data.conditions, data_arrays: data.data_arrays, data_lists: data.data_lists, options: data.options, comment: data.comment });
				}
			},
			getLandingLink: function(invitation, codes = []) {
				var codeList = '';
				if (codes.length) {
					for (var i in codes) {
						codeList += (((!invitation && !Number(i)) ? '?' : '&') + i + '=' + codes[i]);
					}
				}
				return this.$store.state.noxBaseURL + (invitation ? ('/?r=' + invitation) : (codeList ? '/' : '')) + codeList;
			},
			getAvatarLink: function() {
				return (this.$store.state.noxAccountData.avatar ? this.$store.state.noxAccountData.avatar : (this.$store.state.noxMeBaseURL + '/files/avatar/avatar.png'));
			},
			getAvatarByLink: function(avatar) {
				return (avatar ? avatar : (this.$store.state.noxMeBaseURL + '/files/avatar/avatar.png'));
			},
			getPrintsCount: function(data) {

				var count = 0;
				if (data.behavior) { count++; }
				if (data.online_at) { count++; }
				if (data.funding_limit) { count++; }
				if (data.min_lot > 0.01) { count++; }
				if (data.purpose_of_account) { count++; }
				if (data.online_at && data.strategy_type && this.$store.state.noxSystemSettings['time'] !== undefined && (data.version !== this.$store.state.noxSystemSettings[(data.strategy_type == 2 ? 'bot_version_phoenix' : 'bot_version')])) { count++; }
				if (data.online_at && data.status && data.withdrawals > 0 && (data.balance - data.deposits + data.withdrawals) > 0 && data.withdrawals > (data.balance - data.deposits + data.withdrawals)) { count++; }
				if (data.strategy_settings.a !== undefined && data.strategy_settings.a) { count++; }
				if (data.strategy_settings.c !== undefined && data.strategy_settings.c) { count++; }
				if (data.strategy_settings.d !== undefined && data.strategy_settings.d) { count++; }
				if (data.strategy_settings.f !== undefined && data.strategy_settings.f) { count++; }
				if (data.strategy_settings.h !== undefined && data.strategy_settings.h) { count++; }
				if (data.strategy_settings.j !== undefined && data.strategy_settings.j) { count++; }
				if (data.strategy_settings.l !== undefined && data.strategy_settings.l) { count++; }
				if (data.strategy_settings.m !== undefined && data.strategy_settings.m) { count++; }
				if (data.strategy_settings.n !== undefined && data.strategy_settings.n) { count++; }
				if (data.strategy_settings.o !== undefined && data.strategy_settings.o) { count++; }
				if (data.strategy_settings.p !== undefined && data.strategy_settings.p) { count++; }
				if (data.strategy_settings.t !== undefined && data.strategy_settings.t) { count++; }
				if (data.strategy_settings.w !== undefined && data.strategy_settings.w) { count++; }
				if (data.strategy_settings.x !== undefined && data.strategy_settings.x) { count++; }
				if (data.strategy_settings.y !== undefined && data.strategy_settings.y) { count++; }
				if (data.strategy_settings.z !== undefined && data.strategy_settings.z) { count++; }
				if (data.strategy_settings.aa !== undefined && data.strategy_settings.aa) { count++; }
				if (data.strategy_settings.u !== undefined && data.strategy_settings.u !== 'OFF') { count++; }
				if (data.strategy_settings.k !== undefined) { count++; }
				if (data.strategy_settings.r !== undefined) { count++; }
				if (data.is_compound_interest) { count++; }
				if (data.is_compound_interest && data.funding_limit) { count--; }
				if (data.is_locked_currency_pairs) { count++; }
				if (data.is_perk_deposit_limit) { count++; }
				if (data.is_perk_insomnia) { count++; }
				if (data.is_perk_free_bot) { count++; }
				if (data.is_nox_demo_bot) { count++; }
				if (data.is_use_insomnia) { count++; }
				if (data.is_alien) { count++; }
				if (data.is_gap) { count++; }

				return (count < 6 ? 'less_6' : (count > 7 ? 'more_7' : count));
			},
			getRiskStrategies: function(strategyId, masters, multiplier) {
				var result = 0, typeAccount = ''; masters = String(masters).split(',');
				if (this.$store.state.noxBotsStrategies[strategyId] !== undefined) {
					if (this.$store.state.noxBotsStrategies[strategyId].type_account !== undefined) {
						typeAccount = this.$store.state.noxBotsStrategies[strategyId].type_account;
					}
					if (typeAccount !== 'demo') {
						for (var i in masters) {
							if (this.$store.state.noxBotsMasters[masters[i]] !== undefined) {
								if (this.$store.state.noxBotsMasters[masters[i]].multiplier !== undefined) {
									result += Number(this.$store.state.noxBotsMasters[masters[i]].multiplier);
								}
							}
						}
					}
				}
				result *= Number(multiplier);
				return (strategyId ? (typeAccount !== 'demo' ? (result < 1 ? 2 : (result >= 1 && result < 2 ? 3 : 4)) : 1) : 0);
			},
			getSelectOptions: function(type, is_admin = 0) {
				if (type == 1) {
					return this.sortDataByLabel([
						{ code: 'AF', label: this.$store.getters.getLanguageText('1.5.1', 0) },
						{ code: 'AX', label: this.$store.getters.getLanguageText('1.5.1', 1) },
						{ code: 'AL', label: this.$store.getters.getLanguageText('1.5.1', 2) },
						{ code: 'DZ', label: this.$store.getters.getLanguageText('1.5.1', 3) },
						{ code: 'AS', label: this.$store.getters.getLanguageText('1.5.1', 4) },
						{ code: 'AD', label: this.$store.getters.getLanguageText('1.5.1', 5) },
						{ code: 'AO', label: this.$store.getters.getLanguageText('1.5.1', 6) },
						{ code: 'AI', label: this.$store.getters.getLanguageText('1.5.1', 7) },
						{ code: 'AQ', label: this.$store.getters.getLanguageText('1.5.1', 8) },
						{ code: 'AG', label: this.$store.getters.getLanguageText('1.5.1', 9) },
						{ code: 'AR', label: this.$store.getters.getLanguageText('1.5.1', 10) },
						{ code: 'AM', label: this.$store.getters.getLanguageText('1.5.1', 11) },
						{ code: 'AW', label: this.$store.getters.getLanguageText('1.5.1', 12) },
						{ code: 'AU', label: this.$store.getters.getLanguageText('1.5.1', 13) },
						{ code: 'AT', label: this.$store.getters.getLanguageText('1.5.1', 14) },
						{ code: 'AZ', label: this.$store.getters.getLanguageText('1.5.1', 15) },
						{ code: 'BS', label: this.$store.getters.getLanguageText('1.5.1', 16) },
						{ code: 'BH', label: this.$store.getters.getLanguageText('1.5.1', 17) },
						{ code: 'BD', label: this.$store.getters.getLanguageText('1.5.1', 18) },
						{ code: 'BB', label: this.$store.getters.getLanguageText('1.5.1', 19) },
						{ code: 'BY', label: this.$store.getters.getLanguageText('1.5.1', 20) },
						{ code: 'BE', label: this.$store.getters.getLanguageText('1.5.1', 21) },
						{ code: 'BZ', label: this.$store.getters.getLanguageText('1.5.1', 22) },
						{ code: 'BJ', label: this.$store.getters.getLanguageText('1.5.1', 23) },
						{ code: 'BM', label: this.$store.getters.getLanguageText('1.5.1', 24) },
						{ code: 'BT', label: this.$store.getters.getLanguageText('1.5.1', 25) },
						{ code: 'BO', label: this.$store.getters.getLanguageText('1.5.1', 26) },
						{ code: 'BQ', label: this.$store.getters.getLanguageText('1.5.1', 27) },
						{ code: 'BA', label: this.$store.getters.getLanguageText('1.5.1', 28) },
						{ code: 'BW', label: this.$store.getters.getLanguageText('1.5.1', 29) },
						{ code: 'BV', label: this.$store.getters.getLanguageText('1.5.1', 30) },
						{ code: 'BR', label: this.$store.getters.getLanguageText('1.5.1', 31) },
						{ code: 'IO', label: this.$store.getters.getLanguageText('1.5.1', 32) },
						{ code: 'BN', label: this.$store.getters.getLanguageText('1.5.1', 33) },
						{ code: 'BG', label: this.$store.getters.getLanguageText('1.5.1', 34) },
						{ code: 'BF', label: this.$store.getters.getLanguageText('1.5.1', 35) },
						{ code: 'BI', label: this.$store.getters.getLanguageText('1.5.1', 36) },
						{ code: 'KH', label: this.$store.getters.getLanguageText('1.5.1', 37) },
						{ code: 'CM', label: this.$store.getters.getLanguageText('1.5.1', 38) },
						{ code: 'CA', label: this.$store.getters.getLanguageText('1.5.1', 39) },
						{ code: 'CV', label: this.$store.getters.getLanguageText('1.5.1', 40) },
						{ code: 'KY', label: this.$store.getters.getLanguageText('1.5.1', 41) },
						{ code: 'CF', label: this.$store.getters.getLanguageText('1.5.1', 42) },
						{ code: 'TD', label: this.$store.getters.getLanguageText('1.5.1', 43) },
						{ code: 'CL', label: this.$store.getters.getLanguageText('1.5.1', 44) },
						{ code: 'CN', label: this.$store.getters.getLanguageText('1.5.1', 45) },
						{ code: 'CX', label: this.$store.getters.getLanguageText('1.5.1', 46) },
						{ code: 'CC', label: this.$store.getters.getLanguageText('1.5.1', 47) },
						{ code: 'CO', label: this.$store.getters.getLanguageText('1.5.1', 48) },
						{ code: 'KM', label: this.$store.getters.getLanguageText('1.5.1', 49) },
						{ code: 'CG', label: this.$store.getters.getLanguageText('1.5.1', 50) },
						{ code: 'CD', label: this.$store.getters.getLanguageText('1.5.1', 51) },
						{ code: 'CK', label: this.$store.getters.getLanguageText('1.5.1', 52) },
						{ code: 'CR', label: this.$store.getters.getLanguageText('1.5.1', 53) },
						{ code: 'CI', label: this.$store.getters.getLanguageText('1.5.1', 54) },
						{ code: 'HR', label: this.$store.getters.getLanguageText('1.5.1', 55) },
						{ code: 'CU', label: this.$store.getters.getLanguageText('1.5.1', 56) },
						{ code: 'CW', label: this.$store.getters.getLanguageText('1.5.1', 57) },
						{ code: 'CY', label: this.$store.getters.getLanguageText('1.5.1', 58) },
						{ code: 'CZ', label: this.$store.getters.getLanguageText('1.5.1', 59) },
						{ code: 'DK', label: this.$store.getters.getLanguageText('1.5.1', 60) },
						{ code: 'DJ', label: this.$store.getters.getLanguageText('1.5.1', 61) },
						{ code: 'DM', label: this.$store.getters.getLanguageText('1.5.1', 62) },
						{ code: 'DO', label: this.$store.getters.getLanguageText('1.5.1', 63) },
						{ code: 'EC', label: this.$store.getters.getLanguageText('1.5.1', 64) },
						{ code: 'EG', label: this.$store.getters.getLanguageText('1.5.1', 65) },
						{ code: 'SV', label: this.$store.getters.getLanguageText('1.5.1', 66) },
						{ code: 'GQ', label: this.$store.getters.getLanguageText('1.5.1', 67) },
						{ code: 'ER', label: this.$store.getters.getLanguageText('1.5.1', 68) },
						{ code: 'EE', label: this.$store.getters.getLanguageText('1.5.1', 69) },
						{ code: 'ET', label: this.$store.getters.getLanguageText('1.5.1', 70) },
						{ code: 'FK', label: this.$store.getters.getLanguageText('1.5.1', 71) },
						{ code: 'FO', label: this.$store.getters.getLanguageText('1.5.1', 72) },
						{ code: 'FJ', label: this.$store.getters.getLanguageText('1.5.1', 73) },
						{ code: 'FI', label: this.$store.getters.getLanguageText('1.5.1', 74) },
						{ code: 'FR', label: this.$store.getters.getLanguageText('1.5.1', 75) },
						{ code: 'GF', label: this.$store.getters.getLanguageText('1.5.1', 76) },
						{ code: 'PF', label: this.$store.getters.getLanguageText('1.5.1', 77) },
						{ code: 'TF', label: this.$store.getters.getLanguageText('1.5.1', 78) },
						{ code: 'GA', label: this.$store.getters.getLanguageText('1.5.1', 79) },
						{ code: 'GM', label: this.$store.getters.getLanguageText('1.5.1', 80) },
						{ code: 'GE', label: this.$store.getters.getLanguageText('1.5.1', 81) },
						{ code: 'DE', label: this.$store.getters.getLanguageText('1.5.1', 82) },
						{ code: 'GH', label: this.$store.getters.getLanguageText('1.5.1', 83) },
						{ code: 'GI', label: this.$store.getters.getLanguageText('1.5.1', 84) },
						{ code: 'GR', label: this.$store.getters.getLanguageText('1.5.1', 85) },
						{ code: 'GL', label: this.$store.getters.getLanguageText('1.5.1', 86) },
						{ code: 'GD', label: this.$store.getters.getLanguageText('1.5.1', 87) },
						{ code: 'GP', label: this.$store.getters.getLanguageText('1.5.1', 88) },
						{ code: 'GU', label: this.$store.getters.getLanguageText('1.5.1', 89) },
						{ code: 'GT', label: this.$store.getters.getLanguageText('1.5.1', 90) },
						{ code: 'GG', label: this.$store.getters.getLanguageText('1.5.1', 91) },
						{ code: 'GN', label: this.$store.getters.getLanguageText('1.5.1', 92) },
						{ code: 'GW', label: this.$store.getters.getLanguageText('1.5.1', 93) },
						{ code: 'GY', label: this.$store.getters.getLanguageText('1.5.1', 94) },
						{ code: 'HT', label: this.$store.getters.getLanguageText('1.5.1', 95) },
						{ code: 'HM', label: this.$store.getters.getLanguageText('1.5.1', 96) },
						{ code: 'VA', label: this.$store.getters.getLanguageText('1.5.1', 97) },
						{ code: 'HN', label: this.$store.getters.getLanguageText('1.5.1', 98) },
						{ code: 'HK', label: this.$store.getters.getLanguageText('1.5.1', 99) },
						{ code: 'HU', label: this.$store.getters.getLanguageText('1.5.1', 100) },
						{ code: 'IS', label: this.$store.getters.getLanguageText('1.5.1', 101) },
						{ code: 'IN', label: this.$store.getters.getLanguageText('1.5.1', 102) },
						{ code: 'ID', label: this.$store.getters.getLanguageText('1.5.1', 103) },
						{ code: 'IR', label: this.$store.getters.getLanguageText('1.5.1', 104) },
						{ code: 'IQ', label: this.$store.getters.getLanguageText('1.5.1', 105) },
						{ code: 'IE', label: this.$store.getters.getLanguageText('1.5.1', 106) },
						{ code: 'IM', label: this.$store.getters.getLanguageText('1.5.1', 107) },
						{ code: 'IL', label: this.$store.getters.getLanguageText('1.5.1', 108) },
						{ code: 'IT', label: this.$store.getters.getLanguageText('1.5.1', 109) },
						{ code: 'JM', label: this.$store.getters.getLanguageText('1.5.1', 110) },
						{ code: 'JP', label: this.$store.getters.getLanguageText('1.5.1', 111) },
						{ code: 'JE', label: this.$store.getters.getLanguageText('1.5.1', 112) },
						{ code: 'JO', label: this.$store.getters.getLanguageText('1.5.1', 113) },
						{ code: 'KZ', label: this.$store.getters.getLanguageText('1.5.1', 114) },
						{ code: 'KE', label: this.$store.getters.getLanguageText('1.5.1', 115) },
						{ code: 'KI', label: this.$store.getters.getLanguageText('1.5.1', 116) },
						{ code: 'KP', label: this.$store.getters.getLanguageText('1.5.1', 117) },
						{ code: 'KR', label: this.$store.getters.getLanguageText('1.5.1', 118) },
						{ code: 'KW', label: this.$store.getters.getLanguageText('1.5.1', 119) },
						{ code: 'KG', label: this.$store.getters.getLanguageText('1.5.1', 120) },
						{ code: 'LA', label: this.$store.getters.getLanguageText('1.5.1', 121) },
						{ code: 'LV', label: this.$store.getters.getLanguageText('1.5.1', 122) },
						{ code: 'LB', label: this.$store.getters.getLanguageText('1.5.1', 123) },
						{ code: 'LS', label: this.$store.getters.getLanguageText('1.5.1', 124) },
						{ code: 'LR', label: this.$store.getters.getLanguageText('1.5.1', 125) },
						{ code: 'LY', label: this.$store.getters.getLanguageText('1.5.1', 126) },
						{ code: 'LI', label: this.$store.getters.getLanguageText('1.5.1', 127) },
						{ code: 'LT', label: this.$store.getters.getLanguageText('1.5.1', 128) },
						{ code: 'LU', label: this.$store.getters.getLanguageText('1.5.1', 129) },
						{ code: 'MO', label: this.$store.getters.getLanguageText('1.5.1', 130) },
						{ code: 'MK', label: this.$store.getters.getLanguageText('1.5.1', 131) },
						{ code: 'MG', label: this.$store.getters.getLanguageText('1.5.1', 132) },
						{ code: 'MW', label: this.$store.getters.getLanguageText('1.5.1', 133) },
						{ code: 'MY', label: this.$store.getters.getLanguageText('1.5.1', 134) },
						{ code: 'MV', label: this.$store.getters.getLanguageText('1.5.1', 135) },
						{ code: 'ML', label: this.$store.getters.getLanguageText('1.5.1', 136) },
						{ code: 'MT', label: this.$store.getters.getLanguageText('1.5.1', 137) },
						{ code: 'MH', label: this.$store.getters.getLanguageText('1.5.1', 138) },
						{ code: 'MQ', label: this.$store.getters.getLanguageText('1.5.1', 139) },
						{ code: 'MR', label: this.$store.getters.getLanguageText('1.5.1', 140) },
						{ code: 'MU', label: this.$store.getters.getLanguageText('1.5.1', 141) },
						{ code: 'YT', label: this.$store.getters.getLanguageText('1.5.1', 142) },
						{ code: 'MX', label: this.$store.getters.getLanguageText('1.5.1', 143) },
						{ code: 'FM', label: this.$store.getters.getLanguageText('1.5.1', 144) },
						{ code: 'MD', label: this.$store.getters.getLanguageText('1.5.1', 145) },
						{ code: 'MC', label: this.$store.getters.getLanguageText('1.5.1', 146) },
						{ code: 'MN', label: this.$store.getters.getLanguageText('1.5.1', 147) },
						{ code: 'ME', label: this.$store.getters.getLanguageText('1.5.1', 148) },
						{ code: 'MS', label: this.$store.getters.getLanguageText('1.5.1', 149) },
						{ code: 'MA', label: this.$store.getters.getLanguageText('1.5.1', 150) },
						{ code: 'MZ', label: this.$store.getters.getLanguageText('1.5.1', 151) },
						{ code: 'MM', label: this.$store.getters.getLanguageText('1.5.1', 152) },
						{ code: 'NA', label: this.$store.getters.getLanguageText('1.5.1', 153) },
						{ code: 'NR', label: this.$store.getters.getLanguageText('1.5.1', 154) },
						{ code: 'NP', label: this.$store.getters.getLanguageText('1.5.1', 155) },
						{ code: 'NL', label: this.$store.getters.getLanguageText('1.5.1', 156) },
						{ code: 'NC', label: this.$store.getters.getLanguageText('1.5.1', 157) },
						{ code: 'NZ', label: this.$store.getters.getLanguageText('1.5.1', 158) },
						{ code: 'NI', label: this.$store.getters.getLanguageText('1.5.1', 159) },
						{ code: 'NE', label: this.$store.getters.getLanguageText('1.5.1', 160) },
						{ code: 'NG', label: this.$store.getters.getLanguageText('1.5.1', 161) },
						{ code: 'NU', label: this.$store.getters.getLanguageText('1.5.1', 162) },
						{ code: 'NF', label: this.$store.getters.getLanguageText('1.5.1', 163) },
						{ code: 'MP', label: this.$store.getters.getLanguageText('1.5.1', 164) },
						{ code: 'NO', label: this.$store.getters.getLanguageText('1.5.1', 165) },
						{ code: 'OM', label: this.$store.getters.getLanguageText('1.5.1', 166) },
						{ code: 'PK', label: this.$store.getters.getLanguageText('1.5.1', 167) },
						{ code: 'PW', label: this.$store.getters.getLanguageText('1.5.1', 168) },
						{ code: 'PS', label: this.$store.getters.getLanguageText('1.5.1', 169) },
						{ code: 'PA', label: this.$store.getters.getLanguageText('1.5.1', 170) },
						{ code: 'PG', label: this.$store.getters.getLanguageText('1.5.1', 171) },
						{ code: 'PY', label: this.$store.getters.getLanguageText('1.5.1', 172) },
						{ code: 'PE', label: this.$store.getters.getLanguageText('1.5.1', 173) },
						{ code: 'PH', label: this.$store.getters.getLanguageText('1.5.1', 174) },
						{ code: 'PN', label: this.$store.getters.getLanguageText('1.5.1', 175) },
						{ code: 'PL', label: this.$store.getters.getLanguageText('1.5.1', 176) },
						{ code: 'PT', label: this.$store.getters.getLanguageText('1.5.1', 177) },
						{ code: 'PR', label: this.$store.getters.getLanguageText('1.5.1', 178) },
						{ code: 'QA', label: this.$store.getters.getLanguageText('1.5.1', 179) },
						{ code: 'RE', label: this.$store.getters.getLanguageText('1.5.1', 180) },
						{ code: 'RO', label: this.$store.getters.getLanguageText('1.5.1', 181) },
						{ code: 'RU', label: this.$store.getters.getLanguageText('1.5.1', 182) },
						{ code: 'RW', label: this.$store.getters.getLanguageText('1.5.1', 183) },
						{ code: 'BL', label: this.$store.getters.getLanguageText('1.5.1', 184) },
						{ code: 'SH', label: this.$store.getters.getLanguageText('1.5.1', 185) },
						{ code: 'KN', label: this.$store.getters.getLanguageText('1.5.1', 186) },
						{ code: 'LC', label: this.$store.getters.getLanguageText('1.5.1', 187) },
						{ code: 'MF', label: this.$store.getters.getLanguageText('1.5.1', 188) },
						{ code: 'PM', label: this.$store.getters.getLanguageText('1.5.1', 189) },
						{ code: 'VC', label: this.$store.getters.getLanguageText('1.5.1', 190) },
						{ code: 'WS', label: this.$store.getters.getLanguageText('1.5.1', 191) },
						{ code: 'SM', label: this.$store.getters.getLanguageText('1.5.1', 192) },
						{ code: 'ST', label: this.$store.getters.getLanguageText('1.5.1', 193) },
						{ code: 'SA', label: this.$store.getters.getLanguageText('1.5.1', 194) },
						{ code: 'SN', label: this.$store.getters.getLanguageText('1.5.1', 195) },
						{ code: 'RS', label: this.$store.getters.getLanguageText('1.5.1', 196) },
						{ code: 'SC', label: this.$store.getters.getLanguageText('1.5.1', 197) },
						{ code: 'SL', label: this.$store.getters.getLanguageText('1.5.1', 198) },
						{ code: 'SG', label: this.$store.getters.getLanguageText('1.5.1', 199) },
						{ code: 'SX', label: this.$store.getters.getLanguageText('1.5.1', 200) },
						{ code: 'SK', label: this.$store.getters.getLanguageText('1.5.1', 201) },
						{ code: 'SI', label: this.$store.getters.getLanguageText('1.5.1', 202) },
						{ code: 'SB', label: this.$store.getters.getLanguageText('1.5.1', 203) },
						{ code: 'SO', label: this.$store.getters.getLanguageText('1.5.1', 204) },
						{ code: 'ZA', label: this.$store.getters.getLanguageText('1.5.1', 205) },
						{ code: 'GS', label: this.$store.getters.getLanguageText('1.5.1', 206) },
						{ code: 'SS', label: this.$store.getters.getLanguageText('1.5.1', 207) },
						{ code: 'ES', label: this.$store.getters.getLanguageText('1.5.1', 208) },
						{ code: 'LK', label: this.$store.getters.getLanguageText('1.5.1', 209) },
						{ code: 'SD', label: this.$store.getters.getLanguageText('1.5.1', 210) },
						{ code: 'SR', label: this.$store.getters.getLanguageText('1.5.1', 211) },
						{ code: 'SJ', label: this.$store.getters.getLanguageText('1.5.1', 212) },
						{ code: 'SZ', label: this.$store.getters.getLanguageText('1.5.1', 213) },
						{ code: 'SE', label: this.$store.getters.getLanguageText('1.5.1', 214) },
						{ code: 'CH', label: this.$store.getters.getLanguageText('1.5.1', 215) },
						{ code: 'SY', label: this.$store.getters.getLanguageText('1.5.1', 216) },
						{ code: 'TW', label: this.$store.getters.getLanguageText('1.5.1', 217) },
						{ code: 'TJ', label: this.$store.getters.getLanguageText('1.5.1', 218) },
						{ code: 'TZ', label: this.$store.getters.getLanguageText('1.5.1', 219) },
						{ code: 'TH', label: this.$store.getters.getLanguageText('1.5.1', 220) },
						{ code: 'TL', label: this.$store.getters.getLanguageText('1.5.1', 221) },
						{ code: 'TG', label: this.$store.getters.getLanguageText('1.5.1', 222) },
						{ code: 'TK', label: this.$store.getters.getLanguageText('1.5.1', 223) },
						{ code: 'TO', label: this.$store.getters.getLanguageText('1.5.1', 224) },
						{ code: 'TT', label: this.$store.getters.getLanguageText('1.5.1', 225) },
						{ code: 'TN', label: this.$store.getters.getLanguageText('1.5.1', 226) },
						{ code: 'TR', label: this.$store.getters.getLanguageText('1.5.1', 227) },
						{ code: 'TM', label: this.$store.getters.getLanguageText('1.5.1', 228) },
						{ code: 'TC', label: this.$store.getters.getLanguageText('1.5.1', 229) },
						{ code: 'TV', label: this.$store.getters.getLanguageText('1.5.1', 230) },
						{ code: 'UG', label: this.$store.getters.getLanguageText('1.5.1', 231) },
						{ code: 'UA', label: this.$store.getters.getLanguageText('1.5.1', 232) },
						{ code: 'AE', label: this.$store.getters.getLanguageText('1.5.1', 233) },
						{ code: 'GB', label: this.$store.getters.getLanguageText('1.5.1', 234) },
						{ code: 'US', label: this.$store.getters.getLanguageText('1.5.1', 235) },
						{ code: 'UM', label: this.$store.getters.getLanguageText('1.5.1', 236) },
						{ code: 'UY', label: this.$store.getters.getLanguageText('1.5.1', 237) },
						{ code: 'UZ', label: this.$store.getters.getLanguageText('1.5.1', 238) },
						{ code: 'VU', label: this.$store.getters.getLanguageText('1.5.1', 239) },
						{ code: 'VE', label: this.$store.getters.getLanguageText('1.5.1', 240) },
						{ code: 'VN', label: this.$store.getters.getLanguageText('1.5.1', 241) },
						{ code: 'VG', label: this.$store.getters.getLanguageText('1.5.1', 242) },
						{ code: 'VI', label: this.$store.getters.getLanguageText('1.5.1', 243) },
						{ code: 'WF', label: this.$store.getters.getLanguageText('1.5.1', 244) },
						{ code: 'EH', label: this.$store.getters.getLanguageText('1.5.1', 245) },
						{ code: 'YE', label: this.$store.getters.getLanguageText('1.5.1', 246) },
						{ code: 'ZM', label: this.$store.getters.getLanguageText('1.5.1', 247) },
						{ code: 'ZW', label: this.$store.getters.getLanguageText('1.5.1', 248) }
					]);
				}
				else if (type == 2) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.2', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.2', 1) }
					];
				}
				else if (type == 3) {
					return (is_admin ? [
						{ code: 13, label: this.$store.getters.getLanguageText('1.5.2', 15) },
						{ code: 14, label: this.$store.getters.getLanguageText('1.5.2', 16) },
						{ code: 15, label: this.$store.getters.getLanguageText('1.5.2', 17) },
						{ code: 16, label: this.$store.getters.getLanguageText('1.5.2', 18) },
						{ code: 17, label: this.$store.getters.getLanguageText('1.5.2', 19) },
						{ code: 18, label: this.$store.getters.getLanguageText('1.5.2', 20) },
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.2', 2) },
						{ code: 19, label: this.$store.getters.getLanguageText('1.5.2', 21) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.2', 3) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.2', 4) },
						{ code: 3, label: this.$store.getters.getLanguageText('1.5.2', 5) },
						{ code: 4, label: this.$store.getters.getLanguageText('1.5.2', 6) },
						{ code: 5, label: this.$store.getters.getLanguageText('1.5.2', 7) },
						{ code: 6, label: this.$store.getters.getLanguageText('1.5.2', 8) },
						{ code: 7, label: this.$store.getters.getLanguageText('1.5.2', 9) },
						{ code: 8, label: this.$store.getters.getLanguageText('1.5.2', 10) },
						{ code: 9, label: this.$store.getters.getLanguageText('1.5.2', 11) },
						{ code: 10, label: this.$store.getters.getLanguageText('1.5.2', 12) },
						{ code: 11, label: this.$store.getters.getLanguageText('1.5.2', 13) },
						{ code: 12, label: this.$store.getters.getLanguageText('1.5.2', 14) }
					] : [
						{ code: 14, label: this.$store.getters.getLanguageText('1.5.2', 16) },
						{ code: 15, label: this.$store.getters.getLanguageText('1.5.2', 17) },
						{ code: 16, label: this.$store.getters.getLanguageText('1.5.2', 18) },
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.2', 2) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.2', 3) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.2', 4) },
						{ code: 3, label: this.$store.getters.getLanguageText('1.5.2', 5) },
						{ code: 4, label: this.$store.getters.getLanguageText('1.5.2', 6) },
						{ code: 5, label: this.$store.getters.getLanguageText('1.5.2', 7) },
						{ code: 6, label: this.$store.getters.getLanguageText('1.5.2', 8) },
						{ code: 7, label: this.$store.getters.getLanguageText('1.5.2', 9) },
						{ code: 8, label: this.$store.getters.getLanguageText('1.5.2', 10) }
					]);
				}
				else if (type == 4) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.11', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.11', 1) }
					];
				}
				else if (type == 5) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.11', 2) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.11', 3) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.11', 4) },
						{ code: 3, label: this.$store.getters.getLanguageText('1.5.11', 5) },
						{ code: 4, label: this.$store.getters.getLanguageText('1.5.11', 6) },
						{ code: 5, label: this.$store.getters.getLanguageText('1.5.11', 7) },
						{ code: 6, label: this.$store.getters.getLanguageText('1.5.11', 8) },
						{ code: 7, label: this.$store.getters.getLanguageText('1.5.11', 9) },
						{ code: 8, label: this.$store.getters.getLanguageText('1.5.11', 10) }
					];
				}
				else if (type == 6) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.4', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.4', 1) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.4', 2) }
					];
				}
				else if (type == 7) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.5', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.5', 1) }
					];
				}
				else if (type == 8) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.5', 2) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.5', 3) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.5', 4) },
						{ code: 3, label: this.$store.getters.getLanguageText('1.5.5', 5) }
					];
				}
				else if (type == 9) {
					return [
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.6', 0) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.6', 1) },
						{ code: 3, label: this.$store.getters.getLanguageText('1.5.6', 2) }
					];
				}
				else if (type == 10) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.7', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.7', 1) }
					];
				}
				else if (type == 11) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.7', 2) }
					];
				}
				else if (type == 12) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.8', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.8', 1) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.8', 2) },
						{ code: 3, label: this.$store.getters.getLanguageText('1.5.8', 3) },
						{ code: 4, label: this.$store.getters.getLanguageText('1.5.8', 4) }
					];
				}
				else if (type == 13) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.9', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.9', 1) }
					];
				}
				else if (type == 14) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.10', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.10', 1) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.10', 2) }
					];
				}
				else if (type == 15) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.12', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.12', 1) },
						{ code: 2, label: this.$store.getters.getLanguageText('1.5.12', 2) },
						{ code: 3, label: this.$store.getters.getLanguageText('1.5.12', 3) },
						{ code: 4, label: this.$store.getters.getLanguageText('1.5.12', 4) }
					];
				}
				else if (type == 16) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.13', 0) },
						{ code: 1, label: this.$store.getters.getLanguageText('1.5.13', 1) }
					];
				}
				else if (type == 17) {
					return [
						{ code: 0, label: this.$store.getters.getLanguageText('1.5.13', 2) }
					];
				}
			},
			checkAccessToNoxBusiness: function() {
				return ((this.$store.state.noxAccountData.group !== 4 && this.$store.state.noxAccountData.package && this.$store.state.noxAccountData.is_client_program) ? 1 : 0);
			},
			checkAppVersion: function(headers) {
				if (headers['x-client-application-version'] !== undefined) {
					if (String(headers['x-client-application-version']) !== this.$store.state.noxAppVersion) {
						document.location.reload(true);
					}
				}
			},
			selectLanguage: function(language) {
				if (this.$store.state.noxLanguage !== language && ['ru', 'en'].includes(language)) {
					this.changeLanguage(language);
				}
			},
			changeLanguage: function(language) {
				this.$store.state.noxIsLanguageRU = (language == 'ru' ? true : false);
				this.$store.state.noxIsLanguageEN = (language == 'en' ? true : false);
				this.$cookies.set('noxLanguage', language, -1);
				document.location.reload(false);
			},
			changeCropperImage: function(event) {
				const file = event.target.files[0];
				if (['image/png', 'image/jpeg'].includes(file.type)) {
					if (typeof FileReader === 'function') {
						const reader = new FileReader();
						reader.onload = (event) => {
							this.$modal.show('NoxModalCropper', { type: this.$store.state.noxCropperType, data: this.$store.state.noxCropperData, image: event.target.result });
						};
						reader.readAsDataURL(file);
					}
				}
			},
			chooseCropperImage: function(type, data = {}) {
				this.$store.state.noxCropperType = type;
				this.$store.state.noxCropperData = data;
				this.$refs.cropper_image.value = '';
				this.$refs.cropper_image.click();
			},
			toggleMobileMenu: function() {
				document.body.style.overflow = (this.$store.state.noxIsMobileMenu ? 'visible' : 'hidden');
				this.$store.state.noxIsMobileMenu = !this.$store.state.noxIsMobileMenu;
				this.$modal.hide('NoxModalPerks');
				this.$modal.hide('NoxModalBalance');
				this.$modal.hide('NoxModalVerification');
			},
			toggleDropdown: function(id) {
				this.$store.state.noxDropdownId = (this.$store.state.noxDropdownId == id ? this.$store.state.noxDropdownId : '');
				this.$store.state.noxDropdownId = (!this.$store.state.noxDropdownId ? id : '');
			},
			closeDropdown: function(event) {
				var _this = this;
				if (event && event.relatedTarget && event.relatedTarget.className == 'nox_dropdown_options_item') {
					setTimeout(function() { _this.$store.state.noxDropdownId = ''; }, 500);
				}
				else {
					_this.$store.state.noxDropdownId = '';
				}
			},
			toggleCSelect: function(id) {
				this.$store.state.noxCSelectId = (this.$store.state.noxCSelectId == id ? this.$store.state.noxCSelectId : '');
				this.$store.state.noxCSelectId = (!this.$store.state.noxCSelectId ? id : '');
			},
			closeCSelect: function() {
				this.$store.state.noxCSelectId = '';
			},
			sortDataByName(data) {
				return data.sort((a, b) => a.name > b.name ? 1 : -1);
			},
			sortDataByLabel(data) {
				return data.sort((a, b) => a.label > b.label ? 1 : -1);
			},
			similarText(string1, string2, isPercent) {
				var result = 0, pos1 = 0, pos2 = 0, max = 0;
				if ((string1 = String(string1)) && (string2 = String(string2))) {
					for (var i = 0; i < string1.length; i++) {
						for (var j = 0; j < string2.length; j++) {
							for (var k = 0; (((i + k) < string1.length) && ((j + k) < string2.length) && (string1.charAt(i + k) === string2.charAt(j + k))); k++);
							if (k > max) { pos1 = i; pos2 = j; max = k; }
						}
					}
					result = max;
					if (result) {
						if (pos1 && pos2) {
							result += this.similarText(string1.substr(0, pos1), string2.substr(0, pos2));
						}
						if (((pos1 + max) < string1.length) && ((pos2 + max) < string2.length)) {
							result += this.similarText(string1.substr((pos1 + max), (string1.length - pos1 - max)), string2.substr((pos2 + max), (string2.length - pos2 - max)));
						}
					}
					result = (isPercent ? ((result * 200) / (string1.length + string2.length)) : result);
				}
				return Number(result);
			},
			saveAsFile: function(fileName, blobType = 'text/plain', blobData = '') {
				if (typeof document !== undefined) {

					var blob = new Blob([blobData], { type: blobType });
					var blobURL = window.URL.createObjectURL(blob);
					var tempLink = document.createElement('a');

					tempLink.href = blobURL;
					tempLink.target = '_blank';
					tempLink.download = fileName;
					tempLink.style.display = 'none';
					tempLink.id = 'download_' + (+(new Date)).toString();

					document.body.appendChild(tempLink); tempLink.click();
					document.body.removeChild(tempLink);
					window.URL.revokeObjectURL(blobURL);
				}
			},
			clipboard: function(value) {
				this.$clipboard(value);
				this.$notify({ group: 'clipboard', text: this.$store.getters.getLanguageText('1.2', 163) });
			},
			ucFirst: function(string) {
				return (string ? (string[0].toUpperCase() + string.slice(1)) : string);
			},
			goToAccordionBlock: function(id) {
				var _this = this;
				setTimeout(function() {
					_this.$el.querySelector('#nox_account_desktop_portlet_content_accordion_block_' + id).scrollIntoView({
						behavior: 'smooth', block: 'start'
					});
				}, 0);
			},
			goToTop: function(isExecute) {
				if (isExecute) { window.scrollTo(0, 0); }
			},
			exit: function(isRemoveLocalStorage = true) {
				this.$session.destroy();
				this.$notify.close('quest');
				this.$router.push({ path: '/' });
				if (isRemoveLocalStorage) { localStorage.removeItem('noxToken'); }
				document.querySelectorAll('.nox_notification').forEach(e => e.remove());
				document.location.reload(true);
			}
		},
		created: function() {
			this.isMobile();
			this.isEventResize();
			this.isEventScroll();
			this.isEventStorage();
			this.isEventOrientation();
			this.initLanguage();
			this.initMetaTags();
		}
	}
</script>

<style>
	@font-face {
		font-family: 'OpenSansRegular';
		src: url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot');
		src: url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff') format('woff'),
		url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: 'OpenSansSemiBold';
		src: url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot');
		src: url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.woff') format('woff'),
		url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	html, body, #app, .nox {
		height: 100%;
	}
	a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas,
	caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset,
	figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i,
	iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre,
	progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup,
	table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video, xmp {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
	}
	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		margin: 0;
		padding: 0;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		-webkit-focus-ring-color: rgba(255, 255, 255, 0);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-text-size-adjust: none;
		-ms-text-size-adjust: none;
		background: #FFFFFF;
		color: #333333;
		cursor: default;
		font-family: 'OpenSansRegular', Arial, sans-serif;
		font-size: 12px;
		line-height: normal;
	}
	b, strong, .bold {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-weight: normal;
		font-style: normal;
	}
	ol, ul, ul li {
		margin: 0;
		padding: 0;
		list-style: none;
		list-style-type: none;
	}
	li {
		display: list-item;
	}
	p {
		margin: 0 0 10px 0;
	}
	img {
		-ms-interpolation-mode: bicubic;
		vertical-align: middle;
		font-size: 0;
	}
	img, audio, video {
		max-width: 100%;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
	}
	th, td, caption {
		text-align: left;
		vertical-align: middle;
		font-weight: normal;
		line-height: 16px;
	}
	td a {
		word-break: break-all;
		word-break: break-word;
	}
	.hide,
	.hide_span span {
		display: none !important;
	}
	.center {
		text-align: center;
	}
	.uppercase {
		text-transform: uppercase;
	}
	.margin_0 {
		margin: 0;
	}
	.margin_top_0 {
		margin-top: 0;
	}
	.margin_top_10 {
		margin-top: 10px;
	}
	.margin_top_15 {
		margin-top: 15px;
	}
	.margin_top_20 {
		margin-top: 20px;
	}
	.margin_top_25 {
		margin-top: 25px;
	}
	.margin_top_30 {
		margin-top: 30px;
	}
	.margin_left_3 {
		margin-left: 3px;
	}
	.margin_left_5 {
		margin-left: 5px;
	}
	.margin_left_8 {
		margin-left: 8px;
	}
	.margin_left_10 {
		margin-left: 10px;
	}
	.margin_right_5 {
		margin-right: 5px;
	}
	.margin_right_8 {
		margin-right: 8px;
	}
	.margin_right_10 {
		margin-right: 10px;
	}
	.margin_bottom_10 {
		margin-bottom: 10px;
	}
	.margin_bottom_15 {
		margin-bottom: 15px;
	}
	.margin_bottom_20 {
		margin-bottom: 20px;
	}
	.margin_bottom_25 {
		margin-bottom: 25px;
	}
	.margin_bottom_30 {
		margin-bottom: 30px;
	}
	.margin_15_0_20_0 {
		margin: 15px 0 20px 0;
	}
	.rotate_45 {
		transform: rotate(45deg);
	}
	.rotate_135 {
		transform: rotate(135deg);
	}
	.nox_input {
		float: left;
		margin: 2px 0;
		padding: 0 10px 2px 10px;
		border: none;
		border-radius: 13px;
		caret-color: #FFFFFF;
		background: #FFFFFF33;
		color: #FFFFFF;
		outline: none;
		text-align: center;
		font-family: 'OpenSansRegular', Arial, sans-serif;
		font-size: 12px;
		line-height: 26px;
		width: 100%;
		height: 26px;
	}
	.nox_input:-webkit-autofill,
	.nox_input:-webkit-autofill:hover,
	.nox_input:-webkit-autofill:focus,
	.nox_input:-webkit-autofill:active {
		-webkit-text-fill-color: #FFFFFF;
		transition: background-color 5000s ease-in-out 0s;
	}
	.nox_textarea {
		float: left;
		margin: 2px 0;
		padding: 5px 10px;
		border: none;
		border-radius: 13px;
		background: #FFFFFF33;
		color: #FFFFFF;
		outline: none;
		resize: none;
		font-family: 'OpenSansRegular', Arial, sans-serif;
		font-size: 12px;
		line-height: 20px;
		display: block;
		width: 100%;
		min-height: 200px;
	}
	.nox_textarea.small {
		min-height: 100px;
	}
	.nox_textarea.mini {
		min-height: 70px;
	}
	.nox_datetime {
		position: relative;
	}
	.nox_datetime .vdatetime-input {
		float: left;
		margin: 2px 0;
		padding: 0 10px 2px 10px;
		border: none;
		border-radius: 13px;
		caret-color: #FFFFFF;
		background: #FFFFFF33;
		color: #FFFFFF;
		outline: none;
		text-align: center;
		font-family: 'OpenSansRegular', Arial, sans-serif;
		font-size: 12px;
		line-height: 26px;
		width: 100%;
		height: 26px;
	}
	.nox_datetime .vdatetime-popup {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		font-family: 'OpenSansRegular', Arial, sans-serif;
		width: 260px;
	}
	.nox_datetime .vdatetime-popup__header {
		padding: 15px 20px;
		background: #333333;
		color: #FFFFFF;
		display: table;
		width: 100%;
	}
	.nox_datetime .vdatetime-popup__year {
		float: left;
		font-size: 16px;
		font-weight: normal;
		line-height: 15px;
		opacity: 1;
	}
	.nox_datetime .vdatetime-popup__date {
		float: right;
		font-size: 14px;
		font-weight: normal;
		line-height: 15px;
	}
	.nox_datetime .vdatetime-calendar__month__day--selected > span > span,
	.nox_datetime .vdatetime-calendar__month__day--selected:hover > span > span {
		background: #333333;
	}
	.nox_datetime .vdatetime-year-picker__item--selected,
	.nox_datetime .vdatetime-time-picker__item--selected {
		color: #333333;
	}
	.nox_datetime .vdatetime-calendar__month__weekday {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-weight: normal;
	}
	.nox_datetime .vdatetime-calendar__month__weekday,
	.nox_datetime .vdatetime-calendar__month__day {
		font-size: 13px;
		line-height: 31px;
	}
	.nox_datetime .vdatetime-time-picker__list {
		height: 250px;
	}
	.nox_datetime .vdatetime-time-picker__item {
		padding: 5px 0;
		font-size: 13px;
	}
	.nox_datetime .vdatetime-time-picker__item--selected {
		font-size: 20px;
	}
	.nox_datetime .vdatetime-popup__actions {
		padding: 0 20px 10px 20px;
	}
	.nox_datetime .vdatetime-popup__actions__button {
		padding: 20px 0 10px 5px;
		color: #333333;
		cursor: pointer;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
	}
	.nox_datetime .vdatetime-popup__actions__button--confirm {
		color: #008000;
	}
	.nox_datetime_clear {
		position: absolute;
		top: 7px;
		right: 7px;
		border: 1px solid #FFFFFF;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		font-size: 8px;
		line-height: 14px;
		width: 16px;
		height: 16px;
	}
	.nox_datetime_clear:hover {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_dropdown {
		position: relative;
		margin: 5px 0 0 5px;
		border-radius: 5px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		outline: none;
		text-align: center;
		display: inline-block;
		height: 30px;
	}
	.nox_dropdown:first-child {
		margin: 5px 0 0 0;
	}
	.nox_dropdown.open .nox_dropdown_toggle:hover {
		opacity: 1;
	}
	.nox_dropdown.open .nox_dropdown_options {
		display: block;
	}
	.nox_dropdown_toggle {
		position: relative;
		padding: 0 30px 0 12px;
		border: 2px solid #999999;
		border-radius: 5px;
		background: #999999;
		color: #FFFFFF;
		cursor: pointer;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 26px;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.nox_dropdown_toggle:hover {
		opacity: 0.8;
	}
	.nox_dropdown_toggle.green {
		border-color: #48B849;
		background: #48B849;
		color: #FFFFFF;
	}
	.nox_dropdown_toggle.transparent_white {
		border-color: #FFFFFF;
		background: transparent;
		color: #FFFFFF;
	}
	.nox_dropdown_toggle_value svg {
		margin: 0 8px 1px 0;
		font-size: 10px;
	}
	.nox_dropdown_toggle_button {
		position: absolute;
		top: 0;
		right: 5px;
		font-size: 10px;
		width: 20px;
		height: 100%;
	}
	.nox_dropdown_options {
		position: absolute;
		top: 100%;
		left: 5px;
		right: 5px;
		padding: 5px 0;
		border: 1px solid #3C3C3C42;
		border-top-style: none;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 3px 6px 0 #00000026;
		background: #FFFFFF;
		display: none;
		z-index: 2;
	}
	.nox_dropdown_options .nox_dropdown_options_item {
		padding: 0 5px;
		color: #333333;
		overflow: hidden;
		white-space: nowrap;
		text-decoration: none;
		text-overflow: ellipsis;
		cursor: pointer;
		font-size: 12px;
		line-height: 25px;
		display: block;
		width: 100%;
		height: 25px;
	}
	.nox_dropdown_options .nox_dropdown_options_item:hover {
		background: #5897FB;
		color: #FFFFFF;
	}
	.nox_select {
		float: left;
		margin: 2px 0;
		display: table;
		width: 100%;
	}
	.nox_select .vs__dropdown-toggle {
		padding: 0 10px 2px 10px;
		border: none;
		border-radius: 13px;
		background: #FFFFFF33;
		color: #FFFFFF;
		line-height: 26px;
		height: 26px;
	}
	.nox_select.vs--open .vs__dropdown-toggle {
		border-radius: 4px 4px 0 0;
	}
	.nox_select.vs--searchable .vs__dropdown-toggle {
		cursor: pointer;
	}
	.nox_select .vs__selected-options {
		padding: 0;
	}
	.nox_select .vs__selected {
		margin: 0;
		padding: 0;
		border: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: transparent;
		color: #FFFFFF;
		font-size: 12px;
		line-height: 12px;
		width: calc(100% - 1px);
		height: 100%;
	}
	.nox_select .vs__search,
	.nox_select .vs__search:focus {
		margin: 0;
		padding: 0;
		color: #CCCCCC;
		cursor: pointer;
		font-family: 'OpenSansRegular', Arial, sans-serif;
		font-size: 12px;
		line-height: 16px;
	}
	.nox_select .vs__actions {
		padding: 2px 0 0 5px;
		cursor: pointer;
	}
	.nox_select .vs__actions .vs__clear {
		display: none;
	}
	.nox_select .vs__actions .vs__clear,
	.nox_select .vs__actions .vs__open-indicator {
		fill: #CCCCCC;
	}
	.nox_select .vs__dropdown-menu {
		margin: 1px 0 0 0 !important;
		padding: 5px 0;
		min-width: 100%;
	}
	.nox_select .vs__dropdown-option {
		padding: 3px 20px;
		color: #333333;
		font-size: 12px;
	}
	.nox_select .vs__dropdown-option--highlight {
		background: #5897FB;
		color: #FFFFFF;
	}
	.nox_cselect {
		float: left;
		position: relative;
		margin: 2px 0;
		border-radius: 13px;
		background: #FFFFFF33;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		outline: none;
		font-size: 12px;
		display: table;
		width: 100%;
		height: 26px;
	}
	.nox_cselect.open {
		border-radius: 4px 4px 0 0;
	}
	.nox_cselect.open .nox_cselect_options {
		display: block;
	}
	.nox_cselect .sticker_beta {
		margin: 0 8px 0 0;
		padding: 0 5px;
		border-radius: 8px;
		background: #FF4040;
		color: #FFFFFF;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 10px;
		line-height: 14px;
		display: inline-block;
	}
	.nox_cselect_toggle {
		padding: 0 30px 0 10px;
		cursor: pointer;
		line-height: 24px;
	}
	.nox_cselect_toggle_value {
		padding: 4px 0;
		line-height: 16px;
	}
	.nox_cselect_toggle_placeholder {
		color: #CCCCCC;
	}
	.nox_cselect_toggle_button {
		position: absolute;
		top: 0;
		right: 7px;
		color: #CCCCCC;
		text-align: center;
		font-size: 17px;
		width: 20px;
		height: 100%;
	}
	.nox_cselect_options {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		padding: 5px 0;
		border: 1px solid #3C3C3C42;
		border-top-style: none;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 3px 6px 0 #00000026;
		background: #FFFFFF;
		display: none;
		z-index: 1000;
	}
	.nox_cselect_options_item_content {
		position: relative;
		color: #333333;
		cursor: pointer;
	}
	.nox_cselect_options_item_content:hover {
		background: #5897FB;
		color: #FFFFFF;
	}
	.nox_cselect_options_item_content_value {
		padding: 5px 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;
		line-height: 16px;
	}
	.nox_cselect_options_item_content:hover .nox_cselect_options_item_content_value {
		color: #FFFFFF !important;
	}
	.nox_cselect_options_item_content.buttons_1 .nox_cselect_options_item_content_value {
		padding: 5px 35px 5px 20px;
	}
	.nox_cselect_options_item_content.buttons_2 .nox_cselect_options_item_content_value {
		padding: 5px 55px 5px 20px;
	}
	.nox_cselect_options_item_content_icon,
	.nox_cselect_options_item_content_button {
		position: absolute;
		top: 4px;
		right: 14px;
		border: 1px solid transparent;
		border-radius: 50%;
		text-align: center;
		font-size: 10px;
		line-height: 16px;
		width: 18px;
		height: 18px;
	}
	.nox_cselect_options_item_content_icon {
		color: #595959;
		font-size: 10px;
	}
	.nox_cselect_options_item_content .nox_cselect_options_item_content_icon.red {
		color: #FF4040;
	}
	.nox_cselect_options_item_content:hover .nox_cselect_options_item_content_icon {
		color: #FFFFFF;
	}
	.nox_cselect_options_item_content_button:hover {
		border-color: #FFFFFF;
		color: #FFFFFF;
		opacity: 0.8;
	}
	.nox_cselect_options_item_content_button:nth-child(2) {
		right: 34px;
		font-size: 8px;
	}
	.nox_cselect_options_item_divline {
		margin: 2px 20px;
		border-bottom: 1px solid #DDDDDD;
	}
	.nox_cselect_options_item:last-child .nox_cselect_options_item_divline {
		display: none;
	}
	.nox_radios_title {
		margin: 0 0 10px 0;
	}
	.nox_radio,
	.nox_checkbox {
		position: relative;
		margin: 0 0 5px 0;
		padding: 0 0 0 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: left;
		cursor: pointer;
		font-size: 12px;
		line-height: 16px;
		display: block;
	}
	.nox_radio.disabled,
	.nox_checkbox.disabled {
		opacity: 0.5;
	}
	.nox_checkbox.tooltips {
		cursor: default;
	}
	.nox_radio input,
	.nox_checkbox input {
		position: absolute;
		cursor: pointer;
		width: 0;
		height: 0;
		opacity: 0;
	}
	.nox_radio_mark,
	.nox_checkbox_mark {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 2px;
		background: #FFFFFF33;
		cursor: pointer;
		width: 16px;
		height: 16px;
	}
	.nox_radio_mark {
		border-radius: 50%;
	}
	.nox_checkbox_mark:after {
		content: '';
		position: absolute;
		left: 6px;
		top: 2px;
		border: solid #FFFFFF;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		display: none;
		width: 3px;
		height: 7px;
	}
	.nox_radio_mark:after {
		content: '';
		position: absolute;
		top: 5px;
		left: 5px;
		border-radius: 50%;
		background: #FFFFFF;
		display: none;
		width: 6px;
		height: 6px;
	}
	.nox_radio input:checked ~ .nox_radio_mark,
	.nox_checkbox input:checked ~ .nox_checkbox_mark,
	.nox_radio.disabled:hover input:checked ~ .nox_radio_mark,
	.nox_checkbox.disabled:hover input:checked ~ .nox_checkbox_mark {
		background: #00FF0080;
	}
	.nox_radio input:checked ~ .nox_radio_mark:after,
	.nox_checkbox input:checked ~ .nox_checkbox_mark:after {
		display: block;
	}
	.nox_p_radios,
	.nox_p_checkboxes {
		margin: 15px 0 10px 0;
	}
	.nox_button {
		padding: 0;
		border: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: transparent;
		color: #FFFFFF;
		outline: none;
		cursor: pointer;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
	}
	.nox_button.big,
	.nox_button.small,
	.nox_button.micro {
		margin: 5px 0 0 5px;
		padding: 0 12px;
		border: 2px solid #999999;
		border-radius: 5px;
		background: #999999;
		color: #FFFFFF;
		overflow: hidden;
		text-align: center;
		text-decoration: none;
		font-size: 12px;
		line-height: 26px;
		height: 30px;
	}
	.nox_button.small {
		margin: 3px 0 0 5px;
		padding: 0 8px;
		font-size: 9px;
		line-height: 21px;
		height: 25px;
	}
	.nox_button.micro {
		margin: 0 0 0 5px;
		padding: 0 5px;
		border-radius: 9px;
		font-size: 8px;
		line-height: 14px;
		height: 18px;
	}
	.nox_button.common:before,
	.nox_button.nox_is_desktop:before {
		content: '\[';
		padding: 0 1px 0 0;
	}
	.nox_button.common:after,
	.nox_button.nox_is_desktop:after {
		content: '\]';
		padding: 0 0 0 1px;
	}
	.nox_button.common:before,
	.nox_button.common:after,
	.nox_button.nox_is_desktop:before,
	.nox_button.nox_is_desktop:after {
		position: relative;
		top: -1px;
	}
	.nox_button.big:hover,
	.nox_button.small:hover,
	.nox_button.micro:hover,
	.nox_button.common:hover,
	.nox_button.nox_is_icon:hover,
	.nox_button.nox_is_mobile:hover,
	.nox_button.nox_is_desktop:hover {
		opacity: 0.8;
	}
	.nox_button.big:first-child {
		margin: 5px 0 0 0;
	}
	.nox_button.small:first-child {
		margin: 3px 0 0 0;
	}
	.nox_button.micro:first-child {
		margin: 0;
	}
	.nox_button.big.full {
		margin: 5px 0 0 0;
		width: 100%;
	}
	.nox_button.big.blue,
	.nox_button.small.blue,
	.nox_button.micro.blue {
		border-color: #5093CB;
		background: #5093CB;
		color: #FFFFFF;
	}
	.nox_button.big.green,
	.nox_button.small.green,
	.nox_button.micro.green {
		border-color: #48B849;
		background: #48B849;
		color: #FFFFFF;
	}
	.nox_button.big.orange,
	.nox_button.small.orange,
	.nox_button.micro.orange {
		border-color: #E1B673;
		background: #E1B673;
		color: #FFFFFF;
	}
	.nox_button.big.red,
	.nox_button.small.red,
	.nox_button.micro.red {
		border-color: #E26868;
		background: #E26868;
		color: #FFFFFF;
	}
	.nox_button.big.violet,
	.nox_button.small.violet,
	.nox_button.micro.violet {
		border-color: #9F5D9F;
		background: #9F5D9F;
		color: #FFFFFF;
	}
	.nox_button.big.white,
	.nox_button.small.white,
	.nox_button.micro.white {
		border-color: #FFFFFF;
		background: #FFFFFF;
		color: #333333B3;
	}
	.nox_button.big.white_red,
	.nox_button.small.white_red,
	.nox_button.micro.white_red {
		border-color: #FFFFFF;
		background: #FFFFFF;
		color: #FF5555;
	}
	.nox_button.big.white_violet,
	.nox_button.small.white_violet,
	.nox_button.micro.white_violet {
		border-color: #FFFFFF;
		background: #FFFFFF;
		color: #AC70AC;
	}
	.nox_button.big.light_blue,
	.nox_button.small.light_blue,
	.nox_button.micro.light_blue {
		border-color: #03A9F4;
		background: #03A9F4;
		color: #FFFFFF;
	}
	.nox_button.big.light_red,
	.nox_button.small.light_red,
	.nox_button.micro.light_red {
		border-color: #FA8072;
		background: #FA8072;
		color: #FFFFFF;
	}
	.nox_button.big.transparent,
	.nox_button.small.transparent,
	.nox_button.micro.transparent {
		background: transparent;
	}
	.nox_button.big.transparent_white,
	.nox_button.small.transparent_white,
	.nox_button.micro.transparent_white {
		border-color: #FFFFFF;
		background: transparent;
		color: #FFFFFF;
	}
	.nox_button.big svg {
		margin: 0 8px 1px 0;
		font-size: 10px;
	}
	.nox_button.small svg {
		margin: 0 5px 0 0;
		font-size: 9px;
	}
	.nox_button.micro svg {
		margin: 0 5px 0 0;
		font-size: 8px;
	}
	.nox_button.nox_is_icon,
	.nox_button.nox_is_mobile {
		border: 1px solid #FFFFFF;
		border-radius: 5px;
		background: transparent;
		color: #FFFFFF;
		font-size: 9px;
		line-height: 16px;
		width: 18px;
		height: 18px;
	}
	.nox_button.blue.nox_is_icon,
	.nox_button.blue.nox_is_mobile {
		border-color: #A5E5FF;
		color: #A5E5FF;
	}
	.nox_button.green.nox_is_icon,
	.nox_button.green.nox_is_mobile {
		border-color: #00FF00;
		color: #00FF00;
	}
	.nox_button.orange.nox_is_icon,
	.nox_button.orange.nox_is_mobile {
		border-color: #FFC660;
		color: #FFC660;
	}
	.nox_button.red.nox_is_icon,
	.nox_button.red.nox_is_mobile {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_link.icon,
	.nox_button.icon {
		line-height: 20px;
		display: inline-block;
		width: 20px;
		height: 20px;
	}
	.nox_button.icon:hover {
		opacity: 0.8;
	}
	.nox_button.icon img {
		margin: -4px 0 0 0;
	}
	.nox_link.blue,
	.nox_button.blue {
		color: #A5E5FF;
	}
	.nox_link.green,
	.nox_button.green {
		color: #00FF00;
	}
	.nox_link.grey,
	.nox_button.grey {
		color: #CCCCCC;
	}
	.nox_link.orange,
	.nox_button.orange {
		color: #FFC660;
	}
	.nox_link.red,
	.nox_button.red {
		color: #FFBBBB;
	}
	.nox_link.dark_red,
	.nox_button.dark_red {
		color: #FF6F6F;
	}
	.nox_button.disabled {
		color: #CCCCCC;
		cursor: default;
		text-decoration: line-through;
	}
	a.nox_button {
		align-items: baseline;
		justify-content: center;
		display: inline-flex;
	}
	.nox_range_slider .vue-slider-dot-handle {
		border: none;
	}
	.nox_range_slider .vue-slider-dot-tooltip-inner {
		padding: 0 5px 1px 5px;
		border-radius: 5px;
		color: #FFFFFF;
		text-align: center;
		font-size: 10px;
		line-height: 18px;
		min-width: 30px;
	}
	.nox_toggle_button {
		float: left;
		margin: 2px 0;
	}
	.nox_mtoggle {
		position: relative;
		border: 1px solid #CCCCCC;
		border-radius: 11px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		width: 76px;
		height: 22px;
	}
	.nox_mtoggle.mtoggle4 {
		width: 169px;
	}
	.nox_mtoggle_radio {
		display: none;
	}
	.nox_mtoggle_label {
		float: left;
		position: relative;
		border-radius: 11px;
		color: #CCCCCC;
		cursor: pointer;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 20px;
		display: block;
		width: 50%;
		height: 20px;
		z-index: 1;
	}
	.nox_mtoggle.mtoggle4 .nox_mtoggle_label {
		width: 25%;
	}
	.nox_mtoggle_label:hover {
		color: #FFFFFF;
	}
	.nox_mtoggle_active {
		position: absolute;
		top: 0;
		left: 0;
		margin: -3px -1px;
		border: 1px solid #FFFFFF;
		border-radius: 13px;
		background: #3CAD3D;
		color: #FFFFFF;
		transition: all 0.3s;
		line-height: 24px;
		width: 44px;
		height: 26px;
	}
	.nox_mtoggle_radio.active + .nox_mtoggle_label {
		color: #FFFFFF;
	}
	.nox_mtoggle.mtoggle4 .nox_mtoggle_radio:nth-child(3).active ~ .nox_mtoggle_active { left: 25%; }
	.nox_mtoggle.mtoggle4 .nox_mtoggle_radio:nth-child(5).active ~ .nox_mtoggle_active { left: 50%; }
	.nox_mtoggle.mtoggle4 .nox_mtoggle_radio:nth-child(7).active ~ .nox_mtoggle_active { left: 75%; }
	.nox_tab_menu_input {
		display: none;
	}
	.nox_tab_menu_label {
		padding: 0 20px;
		border-right: 1px solid #70707033;
		border-radius: 10px 10px 0 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #33333380;
		color: #FFFFFF;
		cursor: pointer;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 30px;
		display: inline-block;
		min-width: 65px;
	}
	.nox_tab_menu_label.red {
		border-color: #FF848B80;
		background: #FF000066;
		text-shadow: 0 0 1px #777777;
	}
	.nox_tab_menu_input:checked + .nox_tab_menu_label {
		border-color: transparent;
		background: #333333CC;
	}
	.nox_tab_menu_input:checked + .nox_tab_menu_label.red {
		background: #FF000099;
	}
	.nox_tab_menu_label_text {
		margin: 0 0 0 10px;
	}
	.nox_tab_block {
		display: none;
	}
	#nox_tab_menu_1:checked ~ #nox_tab_block_1,
	#nox_tab_menu_2:checked ~ #nox_tab_block_2,
	#nox_tab_menu_3:checked ~ #nox_tab_block_3,
	#nox_tab_menu_4:checked ~ #nox_tab_block_4,
	#nox_tab_menu_5:checked ~ #nox_tab_block_5 {
		display: block;
	}
	.nox_mtabs_titles {
		padding: 0 30px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		display: block;
		width: 100%;
		height: 30px;
	}
	.nox_mtabs_titles_arrow {
		float: left;
		color: #CCCCCC;
		cursor: pointer;
		text-align: center;
		font-size: 17px;
		line-height: 30px;
		display: inline-block;
		width: 30px;
		height: 30px;
	}
	.nox_mtabs_titles_arrow:hover {
		opacity: 0.8;
	}
	.nox_mtabs_titles_arrow:first-child {
		margin: 0 0 0 -30px;
	}
	.nox_mtabs_titles_arrow:last-child {
		margin: 0 -30px 0 0;
	}
	.nox_mtabs_titles_buttons {
		float: left;
		position: relative;
		overflow: hidden;
		display: inline-block;
		width: 100%;
		height: 30px;
	}
	.nox_mtabs_titles_buttons_content {
		position: relative;
		transition: all 0.3s;
		display: flex;
	}
	.nox_mtabs_titles_buttons_content_button {
		padding: 0 20px;
		border-right: 1px solid #707070;
		border-radius: 10px 10px 0 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #FFFFFF33;
		color: #FFFFFF;
		cursor: pointer;
		overflow: hidden;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
		line-height: 30px;
		display: inline-block;
		min-width: 100px;
		height: 30px;
	}
	.nox_mtabs_titles_buttons_content_button:hover {
		opacity: 0.8;
	}
	.nox_mtabs_titles_buttons_content_button.active {
		background: #33333366;
	}
	.nox_mtabs_titles_buttons_content_button.active:hover {
		opacity: 1;
	}
	.nox_mtabs_titles_buttons_content_button.new {
		padding: 0;
		background: #00FF0080;
		min-width: 40px;
	}
	.nox_mtabs_blocks {
		border: 1px solid #707070;
		border-top: none;
		border-radius: 15px;
		background: #33333366;
	}
	.nox_mtabs_blocks.single {
		border: 1px solid #707070;
	}
	.nox_mtabs_block {
		padding: 25px 30px;
		display: none;
	}
	.nox_mtabs_block.active {
		display: block;
	}
	.nox_table {
		margin: 5px 0 0 0;
	}
	.nox_table tr th,
	.nox_table tr td {
		padding: 8px 12px;
		border: 1px solid #999999;
		text-align: center;
		font-size: 12px;
	}
	.nox_table tr th {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
	}
	.nox_table tr th svg {
		font-size: 11px;
		width: 20px !important;
	}
	.nox_table tr td.bg_green,
	.nox_table tr td.available {
		background: #00800080;
	}
	.nox_table tr td.bg_red,
	.nox_table tr td.unavailable {
		background: #FF000080;
	}
	.nox_table tr td.button,
	.nox_table tr td.buttons {
		position: relative;
		padding: 8px 43px 8px 8px;
	}
	.nox_table tr td.buttons {
		padding: 8px 78px 8px 8px;
	}
	.nox_table tr td.button div,
	.nox_table tr td.buttons div {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		border-left: 1px solid #999999;
		width: 35px;
	}
	.nox_table tr td.buttons div:last-child {
		right: 35px;
	}
	.nox_table tr td.button div button,
	.nox_table tr td.buttons div button {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -15px 0 0 -15px;
		width: 30px;
		height: 30px;
	}
	.nox_table tr td a {
		color: #FFFFFF;
	}
	.nox_table tr td a:hover {
		opacity: 0.8;
	}
	.nox_table input,
	.nox_table button {
		margin: -8px 0;
		border: none;
	}
	.nox_table input {
		padding: 0;
		border-radius: 0;
		background: transparent;
		line-height: 30px;
		height: 30px;
	}
	.nox_table .nox_toggle_button {
		float: none;
		margin: -4px 0;
	}
	.nox_table tr .blue {
		color: #A5E5FF;
	}
	.nox_table tr .green {
		color: #ABFFAB;
	}
	.nox_table tr .grey {
		color: #CCCCCC;
	}
	.nox_table tr .orange {
		color: #FFC660;
	}
	.nox_table tr .red {
		color: #FFBBBB;
	}
	.nox_table.mini tr {
		margin: 0;
		border-bottom: none;
	}
	.nox_table.mini tr:last-child {
		border-bottom: 1px solid #999999;
	}
	.nox_table.mini tr td {
		padding: 7px 12px;
		border-bottom: 0;
		word-break: break-all;
		word-break: break-word;
		text-align: right;
		font-size: 11px;
		display: block;
		width: auto !important;
		min-height: 32px;
	}
	.nox_table.mini tr td:before {
		content: attr(data-label);
		float: left;
		padding: 0 5px 0 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_chart {
		margin: 5px 0 15px 0;
		padding: 10px;
		border: 3px solid #E6E6E6;
		border-radius: 5px;
		background: #FFFFFF;
		color: #333333;
	}
	.nox_chart:last-child {
		margin: 5px 0 0 0;
	}
	.nox_chart .highcharts-title {
		text-align: center;
		font-size: 13px !important;
	}
	.nox_chart .highcharts-title a {
		color: #333333;
		text-decoration: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_chart .highcharts-credits {
		display: none;
	}
	.nox_chart .highcharts-tooltip > span {
		top: 1px !important;
		left: 1px !important;
		padding: 8px;
		background: #FFFFFF;
	}
	.nox_chart .nox_chart_buttons {
		margin: 0 0 10px 0;
		padding: 0 0 15px 0;
		border-bottom: 1px dashed #E6E6E6;
		text-align: right;
		display: table;
		width: 100%;
	}
	.nox_chart .nox_chart_buttons div {
		margin: 5px 0 0 5px;
		padding: 0 15px;
		border-radius: 12px;
		background: #E6E6E6;
		color: #333333;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		text-align: center;
		font-size: 12px;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 22px;
		display: inline-block;
		height: 24px;
	}
	.nox_chart .nox_chart_buttons div:hover,
	.nox_chart .nox_chart_buttons div.active {
		background: #5093CB;
		color: #FFFFFF;
	}
	.nox_chart .nox_chart_description {
		padding: 5px 10px 10px 10px;
		text-align: center;
		font-size: 13px;
		line-height: 20px;
	}
	.nox_chart.columns {
		display: table;
	}
	.nox_chart.columns .nox_chart_highcharts {
		float: left;
		width: 50%;
	}
	.nox_chart.columns .nox_chart_description {
		float: right;
		padding: 0;
		width: 50%;
	}
	.nox_chart.columns .nox_chart_description div {
		vertical-align: middle;
		display: table-cell;
		height: 200px;
	}
	.nox_lightbox .vel-img {
		border: 5px solid #F1F1F1;
		border-radius: 5px;
	}
	.nox_cropper .nox_cropper_content_crop {
		border: 5px solid #FFFFFF;
	}
	.nox_cropper .nox_cropper_content_alert .alert {
		margin: 8px 0 0 0;
	}
	.nox_tooltip_icon {
		margin: 0 0 0 10px;
		cursor: pointer;
	}
	.nox_tooltip_icon.orange {
		color: #FFC660;
	}
	.nox_tooltip_icon.red {
		color: #FFBBBB;
	}
	.nox_countdown {
		text-align: center;
	}
	.nox_countdown .title {
		margin: 0 0 10px 0;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
		line-height: 16px;
	}
	.nox_countdown .title.blue {
		color: #A5E5FF;
	}
	.nox_countdown .title.green {
		color: #00FF00;
	}
	.nox_countdown .title.red {
		color: #FFBBBB;
	}
	.nox_countdown .values span {
		position: relative;
		text-align: center;
		font-size: 25px;
		line-height: 30px;
		display: inline-block;
		width: 80px;
		height: 30px;
	}
	.nox_countdown .values span:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 20px 0 0 -20px;
		border-radius: 100%;
		background: #FFFFFF;
		width: 40px;
		height: 2px;
	}
	.nox_countdown .labels span {
		margin: 10px 0 0 0;
		font-size: 12px;
		display: inline-block;
		width: 80px;
	}
	.nox_notification.vue-notification-group {
		z-index: 10000;
	}
	.nox_notification .vue-notification,
	.nox_notification.quest .nox_notification_quest {
		position: relative;
		margin: 0 0 5px 0;
		padding: 10px;
		font-size: 12px;
		line-height: 18px;
	}
	.nox_notification.quest .nox_notification_quest {
		padding: 15px;
		border-left: 10px solid #339333;
		border-radius: 0 15px 15px 0;
		background: #56A558;
		color: #FFFFFF;
		line-height: 15px;
	}
	.nox_notification.quest .nox_notification_quest_title {
		margin: 0 0 15px 0;
		padding: 0 25px 15px 0;
		border-bottom: 1px solid #FFFFFF;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_notification.quest .nox_notification_quest_close {
		position: absolute;
		top: 15px;
		right: 15px;
		border: 1px solid #FFFFFF;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		font-size: 9px;
		line-height: 16px;
		width: 18px;
		height: 18px;
	}
	.nox_notification.quest .nox_notification_quest_close:hover {
		opacity: 0.8;
	}
	.nox_notification.quest .nox_notification_quest_content {
		margin: 0 0 15px 0;
	}
	.nox_notification.quest .nox_notification_quest_buttons {
		text-align: right;
	}
	.nox_notification.quest .nox_notification_quest_buttons a {
		border-radius: 3px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #FFFFFF;
		color: #555555;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 10px;
		line-height: 26px;
		display: inline-block;
		width: 170px;
		height: 26px;
	}
	.nox_notification.quest .nox_notification_quest_buttons a:hover {
		opacity: 0.8;
	}
	.nox_notification.quest .nox_notification_quest_button_close {
		display: inline-block;
	}
	.nox_notification.quest {
		width: 291px !important;
	}
	.nox_notification.clipboard {
		top: 10px !important;
		width: 300px !important;
	}
	.nox_notification.clipboard .vue-notification {
		border: 1px solid #71A4D3;
		border-radius: 2px;
		background: #5695CE;
		color: #FFFFFF;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_loading {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -53px 0 0 -53px;
		color: #555555;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 20px;
		line-height: 106px;
		width: 106px;
		height: 106px;
	}
	.nox_loading:before {
		content: 'NOX';
	}
	.nox_loading:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		border: 3px solid transparent;
		border-top: 3px solid #555555;
		border-radius: 100%;
		animation: loading .7s infinite linear;
		background: transparent;
		width: 100px;
		height: 100px;
	}
	.nox_bg {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: -1;
	}
	.nox_bg img {
		position: absolute;
		top: -5%;
		left: -5%;
		max-width: 110%;
		width: 110%;
		height: 110%;
	}
	.tooltip {
		display: block !important;
		z-index: 10000;
	}
	.tooltip .tooltip-inner {
		padding: 10px 12px 12px 12px;
		border-radius: 10px;
		background: #222222;
		color: #FFFFFF;
		text-align: center;
		font-size: 11px;
		max-width: 290px;
	}
	.tooltip .tooltip-arrow {
		position: absolute;
		margin: 5px;
		border-style: solid;
		border-color: #222222;
		width: 0;
		height: 0;
		z-index: 1;
	}
	.tooltip[x-placement^='top'] {
		margin-bottom: 8px;
	}
	.tooltip[x-placement^='top'] .tooltip-arrow {
		left: calc(50% - 5px);
		bottom: -5px;
		margin-top: 0;
		margin-bottom: 0;
		border-width: 5px 5px 0 5px;
		border-left-color: transparent !important;
		border-right-color: transparent !important;
		border-bottom-color: transparent !important;
	}
	.tooltip[x-placement^='bottom'] {
		margin-top: 8px;
	}
	.tooltip[x-placement^='bottom'] .tooltip-arrow {
		top: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
		border-width: 0 5px 5px 5px;
		border-left-color: transparent !important;
		border-right-color: transparent !important;
		border-top-color: transparent !important;
	}
	.tooltip[x-placement^='right'] {
		margin-left: 8px;
	}
	.tooltip[x-placement^='right'] .tooltip-arrow {
		top: calc(50% - 5px);
		left: -5px;
		margin-left: 0;
		margin-right: 0;
		border-width: 5px 5px 5px 0;
		border-left-color: transparent !important;
		border-top-color: transparent !important;
		border-bottom-color: transparent !important;
	}
	.tooltip[x-placement^='left'] {
		margin-right: 8px;
	}
	.tooltip[x-placement^='left'] .tooltip-arrow {
		top: calc(50% - 5px);
		right: -5px;
		margin-left: 0;
		margin-right: 0;
		border-width: 5px 0 5px 5px;
		border-top-color: transparent !important;
		border-right-color: transparent !important;
		border-bottom-color: transparent !important;
	}
	.tooltip.popover .popover-inner {
		padding: 24px;
		border-radius: 5px;
		box-shadow: 0 5px 30px rgba(black, .1);
		background: #F9F9F9;
		color: #000000;
	}
	.tooltip.popover .popover-arrow {
		border-color: #F9F9F9;
	}
	.tooltip[aria-hidden='true'] {
		transition: opacity .15s, visibility .15s;
		visibility: hidden;
		opacity: 0;
	}
	.tooltip[aria-hidden='false'] {
		transition: opacity .15s;
		visibility: visible;
		opacity: 1;
	}
	.nox .v--modal {
		padding: 15px;
		box-shadow: none;
		background: transparent;
		overflow: visible !important;
	}
	.nox .v--modal-overlay {
		background: #FFFFFFE6;
	}
	.nox .NoxModalErrors.v--modal-overlay,
	.nox .NoxModalRecaptcha.v--modal-overlay {
		z-index: 1000;
	}
	.v--modal-block-scroll {
		width: auto !important;
	}
	.nox_modal {
		position: relative;
		padding: 30px;
		border-radius: 15px;
		background: #333333CC;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 20px;
		width: 100%;
		height: 100%;
	}
	.nox_modal_open_loading {
		height: 120px;
	}
	.nox_modal_open_loading .nox_loading:before {
		color: #FFFFFF;
	}
	.nox_modal_open_loading .nox_loading:after {
		border-top-color: #FFFFFF;
	}
	.nox_modal_head {
		margin: -30px -30px 0 -30px;
		border-bottom: 1px solid #707070;
		border-radius: 15px 15px 0 0;
		background: #33333366;
	}
	.nox_modal_close {
		position: absolute;
		top: 20px;
		right: 30px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		font-size: 10px;
		line-height: 18px;
		width: 22px;
		height: 22px;
	}
	.nox_modal_close:hover {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_modal_title {
		padding: 20px 60px;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
		min-height: 62px;
	}
	.nox_modal_body .alert {
		position: relative;
		margin: 8px 0 5px 0;
		padding: 6px 10px 8px 10px;
		border: 1px solid #FFFFFF;
		border-radius: 15px;
		background: #FFFFFF;
		color: #333333;
		text-align: center;
		font-size: 11px;
		line-height: 16px;
		display: inline-block;
		width: 100%;
	}
	.nox_modal_body .alert a {
		color: #333333;
		word-break: break-all;
		word-break: break-word;
		text-decoration: underline;
	}
	.nox_modal_info {
		padding: 25px 0 0 0;
	}
	.nox_modal_info a {
		color: #A5E5FF;
		word-break: break-all;
		word-break: break-word;
	}
	.nox_modal_info a:hover {
		opacity: 0.8;
	}
	.nox_modal_info input,
	.nox_modal_info .nox_datetime .vdatetime-input {
		text-align: left;
	}
	.nox_modal_info .blue {
		color: #A5E5FF;
	}
	.nox_modal_info .green {
		color: #00FF00;
	}
	.nox_modal_info .orange {
		color: #FFC660;
	}
	.nox_modal_info .red {
		color: #FFBBBB;
	}
	.nox_modal_info .dark_blue {
		color: #0060AD;
	}
	.nox_modal_info .alert:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 100%;
		margin-left: -8px;
		border: 8px solid transparent;
		border-bottom-color: #FFFFFF;
		pointer-events: none;
		width: 0;
		height: 0;
	}
	.nox_modal_info_text,
	.nox_modal_info_chart {
		margin: 20px 0;
	}
	.nox_modal_info_text:first-child,
	.nox_modal_info_chart:first-child {
		margin-top: 0;
	}
	.nox_modal_info_text:last-child,
	.nox_modal_info_chart:last-child {
		margin-bottom: 0;
	}
	.nox_modal_info_img {
		float: left;
		margin: 0 25px 10px 0;
		display: block;
		width: 150px;
	}
	.nox_modal_info_box_title {
		margin: 20px 0 5px 0;
		color: #A5E5FF;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
	}
	.nox_modal_info_box_content {
		padding: 15px;
		border: 1px dashed #888888;
		border-radius: 3px;
	}
	.nox_modal_info_box_content_buttons {
		margin: 15px 0 0 0;
		text-align: right;
		line-height: 17px;
		min-height: 17px;
	}
	.nox_modal_info_box_content_buttons button {
		margin: 0 0 0 10px;
		font-size: 12px;
	}
	.nox_modal_info_box_content_buttons button:first-child {
		margin: 0;
	}
	.nox_modal_info_rows.rows_bg {
		margin: 10px -20px;
		padding: 10px 7px 10px 0;
		border: 1px solid #707070;
		border-radius: 15px;
		background: #33333366;
	}
	.nox_modal_info_rows.last.rows_bg {
		margin: 10px -20px 0 -20px;
	}
	.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg {
		padding: 0 7px 0 0;
		background: #33333399;
		width: calc(100% + 7px);
	}
	.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg.row_bg_start {
		margin: 5px 0 0 0;
		padding: 5px 7px 0 0;
	}
	.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg.row_bg_finish {
		margin: 0 0 5px 0;
		padding: 0 7px 5px 0;
	}
	.nox_modal_info_row {
		display: table;
		width: 100%;
	}
	.nox_modal_info_row.last .alert {
		margin: 8px 0 0 0;
	}
	.nox_modal_info_row.single {
		display: flex;
	}
	.nox_modal_info_row.single .nox_modal_info_row_label {
		float: none;
		overflow: visible;
		white-space: nowrap;
		width: auto;
	}
	.nox_modal_info_row.single .nox_modal_info_row_value {
		float: none;
		padding: 0 0 0 10px;
		flex: auto;
		width: auto;
	}
	.nox_modal_info_row_label {
		float: left;
		overflow: hidden;
		line-height: 28px;
		width: 32%;
		height: 30px;
	}
	.nox_modal_info_row_label.blue {
		color: #A5E5FF;
	}
	.nox_modal_info_row_label.green {
		color: #00FF00;
	}
	.nox_modal_info_row_value {
		float: right;
		position: relative;
		width: 68%;
	}
	.nox_modal_info_row_value .nox_radios,
	.nox_modal_info_row_value .nox_checkboxes {
		margin: 8px 0 0 0;
	}
	.nox_modal_info_row_value .nox_range_slider {
		position: absolute;
		top: 0;
		margin: 6px 0 0 45px;
	}
	.nox_modal_info_row_value .nox_range_slider.vue-slider-disabled {
		opacity: 0.6;
	}
	.nox_modal_info_row_value .nox_range_slider .vue-slider-rail {
		background: rgb(255,0,0);
		background: linear-gradient(90deg, rgba(0,128,0,1) 0%, rgba(255,255,255,1) 50%, rgba(255,104,104,1) 100%);
	}
	.nox_modal_info_row_value .nox_range_slider.reverse_color .vue-slider-rail {
		background: rgb(255,0,0);
		background: linear-gradient(90deg, rgba(255,104,104,1) 0%, rgba(255,255,255,1) 50%, rgba(0,128,0,1) 100%);
	}
	.nox_modal_info_row_value .nox_range_slider.default .vue-slider-rail {
		background: #A5E5FF;
	}
	.nox_modal_info_row_value .nox_range_slider .vue-slider-process,
	.nox_modal_info_row_value .nox_range_slider:hover .vue-slider-process {
		background: none;
	}
	.nox_modal_info_row_value .nox_range_slider_value .forever {
		font-family: system-ui;
		font-size: 14px;
		line-height: 0;
	}
	.nox_modal_info_row_value_text {
		padding: 4px 0;
		word-break: break-all;
		word-break: break-word;
	}
	.nox_modal_info_row_value_tooltip {
		margin: 0 0 0 10px;
		padding: 4px 0;
		color: #FFFFFF !important;
		word-break: break-all;
		word-break: break-word;
		display: inline-block;
	}
	.nox_modal_info_row_value_tooltip span {
		margin: 0 0 0 5px;
	}
	.nox_modal_info_row_recaptcha {
		float: right;
		position: relative;
		margin: 25px 0 10px 0;
		display: table;
		width: 100%;
		height: 60px;
	}
	.nox_modal_info_row_recaptcha.single {
		margin: 0;
	}
	.nox_modal_info_row_recaptcha .nox_recaptcha {
		position: absolute;
		top: 0;
		left: 50%;
		margin: 0 0 0 -120px;
		transform: scale(0.795);
		transform-origin: 0 0;
	}
	.nox_modal_info_row_recaptcha .alert {
		margin: 70px 0 0 0 !important;
	}
	.nox_modal_info_attention {
		margin: 15px 0;
		padding: 15px 30px 18px 30px;
		border-radius: 10px;
		background: #E26868;
		text-align: center;
		font-size: 12px;
		line-height: 18px;
	}
	.nox_modal_info_attention.first {
		margin-top: 0;
	}
	.nox_modal_info_attention.last {
		margin-bottom: 0;
	}
	.nox_modal_info_attention.bg_blue {
		background: #5695CE;
	}
	.nox_modal_info_attention.bg_green {
		background: #48B849;
	}
	.nox_modal_info_payment_cards,
	.nox_modal_info_payment_methods {
		margin: 0 -5px;
		text-align: center;
	}
	.nox_modal_info_payment_card,
	.nox_modal_info_payment_method {
		position: relative;
		padding: 5px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		display: inline-block;
		width: 33.33333%;
	}
	.nox_modal_info_payment_card_delete,
	.nox_modal_info_payment_method_content_help {
		position: absolute;
		top: 0;
		right: 0;
		border: 2px solid #FFFFFF99;
		border-radius: 50%;
		background: #5B5B5B;
		color: #FFFFFF99;
		text-align: center;
		cursor: pointer;
		font-size: 11px;
		line-height: 18px;
		width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_modal_info_payment_card_content,
	.nox_modal_info_payment_method_content_image img {
		padding: 15px 0;
		border: 2px solid #FFFFFF99;
		border-radius: 15px;
		cursor: pointer;
		width: 100%;
		opacity: 0.7;
	}
	.nox_modal_info_payment_card_content:hover,
	.nox_modal_info_payment_method_content_image img:hover {
		border-color: #FFFFFFCC;
		opacity: 0.8;
	}
	.nox_modal_info_payment_card_delete:hover,
	.nox_modal_info_payment_card_content.active,
	.nox_modal_info_payment_method_content_help:hover,
	.nox_modal_info_payment_method.active img {
		border-color: #FFFFFF;
		color: #FFFFFF;
		opacity: 1;
	}
	.nox_modal_info_payment_card_delete:hover {
		border-color: #FFBBBB;
		color: #FFBBBB;
	}
	.nox_modal_info_payment_card_content_type {
		margin: 0 0 10px 0;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 16px;
		line-height: 15px;
		height: 15px;
	}
	.nox_modal_info_payment_card_content_type svg {
		font-size: 30px;
	}
	.nox_modal_info_payment_card_content_holder {
		overflow: hidden;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 9px;
		height: 9px;
	}
	.nox_modal_info_payment_card_content_loading,
	.nox_modal_info_payment_card_content_caption {
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 20px;
		height: 20px;
	}
	.nox_modal_info_payment_label {
		margin: 0 10px 0 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_modal_info_payment_row.grey .nox_modal_info_payment_label span,
	.nox_modal_info_payment_row.grey .nox_modal_info_payment_value {
		opacity: 0.6;
	}
	.nox_modal_info_payment_actual_address_company {
		margin: 15px 0 0 0;
		color: #DADADA;
		text-align: center;
		font-size: 10px;
		line-height: 16px;
	}
	.nox_modal_info_payment_crypto_refresh {
		margin: 0 0 0 8px;
	}
	.nox_modal_info_payment_crypto_refresh button {
		font-size: 12px;
	}
	.nox_modal_info_payment_crypto_address {
		margin: 0 0 15px 0;
	}
	.nox_modal_info_payment_crypto_address_value_text {
		padding: 0 8px;
		color: #00FF00;
		cursor: text;
		word-break: break-all;
	}
	.nox_modal_info_payment_crypto_address_value_copy button {
		font-size: 12px;
	}
	.nox_modal_info_payment_crypto_address_value_copy button:hover {
		opacity: 0.8;
	}
	.nox_modal_info_payment_crypto_info {
		text-align: center;
	}
	.nox_modal_info_payment_crypto_info_qrcode {
		float: left;
		margin: 5px 15px 15px 0;
		padding: 10px;
		background: #FFFFFF;
		display: inline-block;
		width: 115px;
		height: 115px;
	}
	.nox_modal_info_payment_crypto_info_content {
		text-align: left;
		font-size: 12px;
		line-height: 18px;
	}
	.nox_modal_info_tree_node a {
		word-break: break-all;
	}
	.nox_modal_info_tree_gift_details_image {
		float: left;
		margin: 0 30px 0 0;
		text-align: center;
		width: 200px;
		height: 200px;
	}
	.nox_modal_info_portrait_result_progress {
		text-align: center;
	}
	.nox_modal_info_portrait_result_progress_block {
		margin: 5px 0 25px 0;
		border-radius: 9px;
		box-shadow: inset 0 0 5px #333333CC;
		background: #ECECEC;
		color: #FFFFFF;
		overflow: hidden;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 17px;
		width: 100%;
		height: 17px;
	}
	.nox_modal_info_portrait_result_progress_value {
		box-shadow: inset 0 0 3px #777777CC;
		text-shadow: 1px 1px 1px #777777;
		line-height: 15px;
		height: 100%;
	}
	.nox_modal_info_phoenix_preset_settings .nox_modal_info_row_label {
		width: 35%;
	}
	.nox_modal_info_phoenix_preset_settings .nox_modal_info_row_value {
		width: 65%;
	}
	.nox_modal_info_phoenix_settings_presets_text {
		line-height: 22px;
		display: table;
		width: 100%;
	}
	.nox_modal_info_phoenix_settings_presets_template {
		margin: 25px -30px;
		padding: 25px 30px;
		border-top: 1px solid #707070;
		border-bottom: 1px solid #707070;
		background: #33333366;
	}
	.nox_modal_info_phoenix_settings_presets_template_title {
		margin: 0 0 15px 0;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
	}
	.nox_modal_info_phoenix_settings_presets_template_cselect {
		display: table;
		width: 100%;
	}
	.nox_modal_info_phoenix_settings_presets_template_description {
		margin: 15px 0 0 0;
	}
	.nox_modal_info_phoenix_settings_content .nox_modal_info_row_label {
		text-align: right;
		width: 62%;
	}
	.nox_modal_info_phoenix_settings_content .nox_modal_info_row_label .nox_tooltip_icon {
		min-width: 16px;
	}
	.nox_modal_info_phoenix_settings_content .nox_modal_info_row_value {
		padding: 0 0 0 20px;
		width: 38%;
	}
	.nox_modal_info_phoenix_settings_content .nox_modal_info_row_value .nox_cselect {
		width: 169px;
	}
	.nox_modal_info_phoenix_settings_content .nox_modal_info_row_value .nox_mtoggle {
		margin: 4px 0 0 0;
	}
	.nox_modal_info_phoenix_settings_content .nox_modal_info_row_value .nox_mtoggle .nox_mtoggle_radio:nth-child(7).active + .nox_mtoggle_label {
		color: #5C5C5C;
	}
	.nox_modal_info_phoenix_settings_content .nox_modal_info_row_value .nox_mtoggle .nox_mtoggle_radio:nth-child(7).active ~ .nox_mtoggle_active {
		background: #BFCBD9;
	}
	.nox_modal_info_phoenix_settings_content_buttons {
		margin: 20px 0 0 0;
		text-align: right;
	}
	.nox_modal_info_tickets_team_item {
		position: relative;
		margin: 10px 0 10px 25px;
		padding: 12px 15px 12px 38px;
		border: 1px solid #999999;
		border-radius: 10px;
		background: #505050B3;
		color: #FFFFFF;
		align-items: center;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		min-height: 46px;
	}
	.nox_modal_info_tickets_team_item_avatar {
		position: absolute;
		top: 50%;
		left: 0;
		margin: -25px 0 0 -25px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #6F6F6F;
		overflow: hidden;
		width: 50px;
		height: 50px;
	}
	.nox_modal_info_tickets_team_item_content span {
		margin: 0 8px 0 0;
	}
	.nox_modal_info_tickets_team_item_content span:last-child {
		margin: 0;
	}
	.nox_modal_info_tickets_accordion_item {
		margin: 0 0 5px 0;
		border: 1px solid #999999;
		border-radius: 10px;
		background: #505050B3;
	}
	.nox_modal_info_tickets_accordion_item:last-child {
		margin: 0;
	}
	.nox_modal_info_tickets_accordion_item_head {
		position: relative;
		padding: 10px 20px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		font-size: 12px;
		line-height: 20px;
		min-height: 40px;
	}
	.nox_modal_info_tickets_accordion_item_head svg {
		position: absolute;
		top: 20px;
		right: 20px;
		margin: -10px 0 0 0;
		font-size: 20px;
	}
	.nox_modal_info_tickets_accordion_item_head_title {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_modal_info_tickets_accordion_item_head_comment {
		color: #DADADA;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-size: 11px;
	}
	.nox_modal_info_tickets_accordion_item_body {
		position: relative;
		margin: 0 20px 20px 20px;
		padding: 15px 0 0 0;
		border-top: 1px solid #999999;
		text-align: center;
	}
	.nox_modal_info_tickets_accordion_item_body_qrcode {
		position: absolute;
		top: 20px;
		left: 0;
		padding: 10px;
		background: #FFFFFF;
		display: inline-block;
		width: 110px;
		height: 110px;
	}
	.nox_modal_info_tickets_accordion_item_body_qrcode a {
		position: absolute;
		left: 0;
		bottom: -34px;
		width: 100%;
	}
	.nox_modal_info_tickets_accordion_item_body_qrcode a .nox_button.small {
		margin: 0;
		width: 100%;
	}
	.nox_modal_info_tickets_accordion_item_body_comment {
		padding: 0 0 0 135px;
		text-align: left;
	}
	.nox_modal_info_tickets_accordion_item_body_comment_label {
		font-size: 12px;
	}
	.nox_modal_info_tickets_accordion_item_body_comment_loading,
	.nox_modal_info_tickets_accordion_item_body_comment_buttons {
		margin: 5px 0 0 0;
		text-align: right;
		line-height: 20px;
		min-height: 20px;
	}
	.nox_modal_info_tickets_accordion_item_body_comment_buttons button {
		margin: 0 0 0 8px;
		font-size: 12px;
	}
	.nox_modal_info_tickets_accordion_item_body_comment_buttons button:first-child {
		margin: 0;
	}
	.nox_modal_info_splash .splash_type_1,
	.nox_modal_info_splash .splash_type_2 {
		display: table;
		width: 100%;
	}
	.nox_modal_info_splash .splash_type_1 img {
		float: left;
		margin: 0 10px 10px 0;
		width: 200px;
	}
	.nox_modal_info_splash .splash_type_2 {
		text-align: center;
	}
	.nox_modal_info_splash .splash_type_2 img {
		width: 200px;
	}
	.nox_modal_info_bot_logs_item {
		font-size: 12px;
	}
	.nox_modal_info_bot_logs_item span:first-child {
		margin: 0 5px 0 0;
		color: #CECECE;
		font-size: 10px;
	}
	.nox_modal_info_bot_logs_item forever {
		position: relative;
		top: 1px;
		font-family: system-ui;
		font-size: 15px;
		line-height: 0;
	}
	.nox_modal_info_user_card {
		position: relative;
		min-height: 170px;
	}
	.nox_modal_info_user_card_avatar {
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		width: 130px;
		height: 170px;
	}
	.nox_modal_info_user_card_avatar_img {
		margin: 5px 0 0 0;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #5C5C5C;
		overflow: hidden;
		width: 130px;
		height: 130px;
	}
	.nox_modal_info_user_card_avatar_img img {
		width: 130px;
		height: 130px;
	}
	.nox_modal_info_user_card_avatar_rating {
		position: relative;
		margin: 5px 0 0 0;
		display: inline-block;
		width: 125px;
		height: 30px;
	}
	.nox_modal_info_user_card_avatar_rating div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url('../assets/images/stars.png');
		width: 100%;
		height: 100%;
	}
	.nox_modal_info_user_card_avatar_rating div span {
		background: inherit;
		background-position: 0 -30px;
		display: block;
		width: 0;
		height: 100%;
	}
	.nox_modal_info_user_card_avatar_status {
		position: absolute;
		top: 5px;
		right: 0;
		padding: 0 7px;
		border: 2px solid #FFFFFF;
		border-radius: 15px;
		background: #B8C3CA;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 26px;
		min-width: 30px;
		height: 30px;
		z-index: 1;
	}
	.nox_modal_info_user_card_content {
		padding: 5px 0 0 160px;
	}
	.nox_modal_info_user_card_content_row {
		font-size: 12px;
		line-height: 22px;
		display: table;
		width: 100%;
	}
	.nox_modal_info_user_card_content_label {
		float: left;
		margin: 0 10px 0 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_modal_info_user_card_content_value {
		float: left;
	}
	.nox_modal_info_user_card_content_socs {
		margin: 20px 0 0 0;
	}
	.nox_modal_info_user_card_content_socs a {
		margin: 0 4px;
		font-size: 20px;
	}
	.nox_modal_info_user_card_content_socs a:first-child {
		margin: 0 4px 0 0;
	}
	.nox_modal_info_user_card_content_socs a:last-child {
		margin: 0 0 0 4px;
	}
	.nox_modal_info_mailings .vs__dropdown-menu {
		max-height: 226px;
		min-height: 100px;
	}
	.nox_modal_info_mailings_trecipients {
		margin: 20px 0 50px 0;
		padding: 0 10px 10px 10px;
		border: 1px dashed #CCCCCC;
		border-radius: 13px 13px 0 13px;
	}
	.nox_modal_info_mailings_trecipients_data_block.hidden {
		overflow: hidden;
		max-height: 320px;
	}
	.nox_modal_info_mailings_trecipients_data_button {
		margin: 5px 0;
		border: 1px dashed #CCCCCC;
		border-radius: 5px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 24px;
		width: 100%;
		height: 26px;
	}
	.nox_modal_info_mailings_trecipients_data_button:hover {
		opacity: 0.8;
	}
	.nox_modal_info_mailings_trecipients textarea {
		float: none;
		margin: 10px 0 0 0;
		border-radius: 5px;
	}
	.nox_modal_info_mailings_trecipient {
		position: relative;
		margin: 5px 0 0 0;
		padding: 0 34px 0 0;
		border: 1px solid #777777;
		border-radius: 5px;
		overflow: hidden;
		font-size: 11px;
		line-height: 24px;
		width: 100%;
		height: 26px;
	}
	.nox_modal_info_mailings_trecipient:first-child {
		margin: 10px 0 0 0;
	}
	.nox_modal_info_mailings_trecipient div:first-child {
		word-break: break-all;
	}
	.nox_modal_info_mailings_trecipient div:first-child span {
		margin: 0 0 0 8px;
	}
	.nox_modal_info_mailings_trecipient div:last-child {
		position: absolute;
		top: 0;
		right: 0;
		border-left: 1px solid #777777;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		cursor: pointer;
		width: 26px;
		height: 100%;
	}
	.nox_modal_info_mailings_trecipient div:last-child:hover {
		color: #FFBBBB;
	}
	.nox_modal_info_mailings_trecipients_count {
		float: right;
		margin: 11px -11px 0 0;
		border: 1px dashed #CCCCCC;
		border-top: none;
		border-radius: 0 0 5px 5px;
		font-size: 11px;
		line-height: 26px;
		height: 27px;
	}
	.nox_modal_info_mailings_trecipients_count span:nth-child(1) {
		padding: 0 12px;
	}
	.nox_modal_info_mailings_trecipients_count span:nth-child(1) b {
		margin: 0 0 0 8px;
		font-size: 12px;
	}
	.nox_modal_info_mailings_trecipients_count span:nth-child(2) {
		border-left: 1px dashed #CCCCCC;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		cursor: pointer;
		display: inline-block;
		width: 26px;
		height: 100%;
	}
	.nox_modal_info_mailings_trecipients_count span:nth-child(2):hover {
		color: #FFBBBB;
	}
	.nox_modal_info_mailings_mrecipients tr th,
	.nox_modal_info_mailings_mrecipients tr td {
		padding: 5px;
		font-size: 10px;
	}
	.nox_modal_info_mailings_mrecipients tr th {
		font-size: 11px;
	}
	.nox_modal_info_mailings_mrecipients tr th:nth-child(1),
	.nox_modal_info_mailings_mrecipients tr td:nth-child(1) {
		min-width: 45px;
		width: 1%;
	}
	.nox_modal_info_events .nox_modal_info_row_label {
		width: 39%;
	}
	.nox_modal_info_events .nox_modal_info_row_value {
		width: 61%;
	}
	.nox_modal_info_tb .vs__dropdown-menu {
		max-height: 195px;
	}
	.nox_modal_info_nox_bot_demo_intro_progressbar {
		text-align: center;
	}
	.nox_modal_info_nox_bot_demo_intro_progressbar_caption {
		margin: 0 0 20px 0;
	}
	.nox_modal_info_nox_bot_demo_intro_progressbar_loading {
		position: relative;
		margin: 0 0 15px 0;
		padding: 3px;
		border-radius: 10px;
		background: #FFFFFF;
		box-shadow: inset 0 -1px 1px #FFFFFF4D;
		display: inline-block;
		width: 75%;
		height: 20px;
	}
	.nox_modal_info_nox_bot_demo_intro_progressbar_loading span {
		position: relative;
		border-radius: 10px;
		background-color: rgb(43,194,83);
		background-image: linear-gradient(center bottom, rgb(43,194,83) 37%, rgb(84,240,84) 69%);
		box-shadow: inset 0 2px 9px  rgba(255,255,255,0.3), inset 0 -2px 6px rgba(0,0,0,0.4);
		transition: all 0.3s linear 0s;
		overflow: hidden;
		display: block;
		height: 100%;
	}
	.nox_modal_info_nox_bot_demo_intro_progressbar_percent {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 15px;
	}
	.nox_modal_info_nox_bot_demo_intro_result_status {
		text-align: center;
	}
	.nox_modal_info_nox_bot_demo_intro_result_status div {
		margin: 15px 0 25px 0;
		border: 2px solid #FFBBBB;
		border-radius: 50%;
		color: #FFBBBB;
		font-size: 50px;
		line-height: 96px;
		display: inline-block;
		width: 100px;
		height: 100px;
	}
	.nox_modal_info_nox_bot_demo_intro_result_status.success div {
		border-color: #00FF00;
		color: #00FF00;
	}
	.nox_modal_info_nox_bot_demo_intro_result_content_row {
		display: table;
		width: 100%;
	}
	.nox_modal_info_nox_bot_demo_intro_result_content_row p:last-child {
		margin: 0;
	}
	.nox_modal_info_nox_bot_demo_intro_result_content_row_label {
		float: left;
	}
	.nox_modal_info_nox_bot_demo_intro_result_content_row_value {
		float: left;
		margin: 0 0 0 10px;
	}
	.nox_modal_info_nox_bot_demo_intro_result_content_row_value .nox_button {
		margin: 0 5px 0 0;
	}
	.nox_modal_info_nox_bot_demo_intro_result_content_row_value .nox_button:last-child {
		margin: 0;
	}
	.nox_modal_info_quests_task .nox_modal_info_row_label {
		width: 38%;
	}
	.nox_modal_info_quests_task .nox_modal_info_row_value {
		width: 62%;
	}
	.nox_modal_info_quests_task_button,
	.nox_modal_info_quests_task_button_content_item {
		position: relative;
		margin: 15px 0 0 0;
		padding: 15px;
		border: 1px solid #888888;
		border-radius: 5px;
		background: #707070;
	}
	.nox_modal_info_quests_task_button:first-child,
	.nox_modal_info_quests_task_button_content_item:first-child {
		margin: 0;
	}
	.nox_modal_info_quests_task_button_title,
	.nox_modal_info_quests_task_button_content_item_title {
		margin: 0 0 10px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_modal_info_quests_task_button_delete,
	.nox_modal_info_quests_task_button_content_item_delete {
		position: absolute;
		top: 15px;
		right: 15px;
	}
	.nox_modal_info_quests_task_button_delete button,
	.nox_modal_info_quests_task_button_content_item_delete button {
		font-size: 11px;
	}
	.nox_modal_info_quests_task_button_content_items {
		margin: 15px 0 0 0;
	}
	.nox_modal_info_quests_task_button_content_item {
		background: #666666;
	}
	.nox_modal_info_quests_task_button_buttons {
		margin: 15px 0 0 0;
		text-align: right;
		line-height: 17px;
		min-height: 17px;
	}
	.nox_modal_info_quests_task_button_buttons button {
		margin: 0 0 0 10px;
		font-size: 12px;
	}
	.nox_modal_info_quests_task_button_buttons button:first-child {
		margin: 0;
	}
	.nox_modal_info_nox_bot_demo .nox_modal_info_row_label,
	.nox_modal_info_nox_bot_intro .nox_modal_info_row_label {
		width: 35%;
	}
	.nox_modal_info_nox_bot_demo .nox_modal_info_row_value,
	.nox_modal_info_nox_bot_intro .nox_modal_info_row_value {
		width: 65%;
	}
	.nox_modal_info_bot_allowed_pairs .nox_modal_info_row_label {
		width: 46%;
	}
	.nox_modal_info_bot_allowed_pairs .nox_modal_info_row_value {
		width: 54%;
	}
	.nox_modal_info_nox_bot_demo .nox_modal_info_row_value .nox_checkbox_text,
	.nox_modal_info_nox_bot_intro .nox_modal_info_row_value .nox_checkbox_text,
	.nox_modal_info_bot_allowed_pairs .nox_modal_info_row_value .nox_checkbox_text {
		display: table;
		width: 100%;
	}
	.nox_modal_info_nox_bot_demo .nox_modal_info_row_value .nox_checkbox_text .name,
	.nox_modal_info_nox_bot_intro .nox_modal_info_row_value .nox_checkbox_text .name,
	.nox_modal_info_bot_allowed_pairs .nox_modal_info_row_value .nox_checkbox_text .name {
		float: left;
		margin: 0 0 0 10px;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		display: inline-block;
		width: 50px;
	}
	.nox_modal_info_nox_bot_demo .nox_modal_info_row_value .nox_checkbox_text .icon,
	.nox_modal_info_nox_bot_intro .nox_modal_info_row_value .nox_checkbox_text .icon,
	.nox_modal_info_bot_allowed_pairs .nox_modal_info_row_value .nox_checkbox_text .icon {
		float: left;
		position: relative;
		margin: 0 0 0 10px;
		text-align: center;
		display: inline-block;
		width: 20px;
	}
	.nox_modal_info_nox_bot_demo .nox_modal_info_row_value .nox_checkbox_text .icon.green,
	.nox_modal_info_nox_bot_intro .nox_modal_info_row_value .nox_checkbox_text .icon.green,
	.nox_modal_info_bot_allowed_pairs .nox_modal_info_row_value .nox_checkbox_text .icon.green {
		font-size: 8px;
	}
	.nox_modal_info_bot_orders_csv {
		margin: 0 0 15px 0;
		text-align: right;
		display: table;
		width: 100%;
	}
	.nox_modal_info_bot_orders_csv .nox_button.big {
		margin: 0;
	}
	.nox_modal_info_team_invited_users_block {
		padding: 5px 0 0 50px;
		display: table;
		width: 100%;
	}
	.nox_modal_info_team_invited_users_block:first-child {
		padding: 0 0 0 50px;
	}
	.nox_modal_info_team_invited_users_block_item {
		position: relative;
		padding: 10px 10px 10px 60px;
		border-radius: 15px;
		background: #6F6F6F;
		color: #FFFFFF;
	}
	.nox_modal_info_team_invited_users_block_item.item_grey {
		background: #E1E1E1;
		color: #555555;
	}
	.nox_modal_info_team_invited_users_block_item.item_orange {
		background: #E1B673;
		color: #FFFFFF;
	}
	.nox_modal_info_team_invited_users_block_item.item_red {
		background: #FF848B;
		color: #FFFFFF;
	}
	.nox_modal_info_team_invited_users_block_item_number {
		position: absolute;
		top: 50%;
		left: -30px;
		margin: -30px 0 0 0;
		border: 1px solid #FFFFFF;
		border-radius: 50%;
		background: #6F6F6F;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 20px;
		width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_modal_info_team_invited_users_block_item.item_grey .nox_modal_info_team_invited_users_block_item_number {
		background: #E1E1E1;
	}
	.nox_modal_info_team_invited_users_block_item.item_orange .nox_modal_info_team_invited_users_block_item_number {
		background: #E1B673;
	}
	.nox_modal_info_team_invited_users_block_item.item_red .nox_modal_info_team_invited_users_block_item_number {
		background: #FF848B;
	}
	.nox_modal_info_team_invited_users_block_item_avatar_img {
		position: absolute;
		top: 50%;
		left: -30px;
		margin: -30px 0 0 0;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #6F6F6F;
		overflow: hidden;
		width: 60px;
		height: 60px;
	}
	.nox_modal_info_team_invited_users_block_item.item_grey .nox_modal_info_team_invited_users_block_item_avatar_img {
		box-shadow: 0 0 2px #E1E1E1;
		background: #E1E1E1;
	}
	.nox_modal_info_team_invited_users_block_item.item_orange .nox_modal_info_team_invited_users_block_item_avatar_img {
		box-shadow: 0 0 2px #E1B673;
		background: #E1B673;
	}
	.nox_modal_info_team_invited_users_block_item.item_red .nox_modal_info_team_invited_users_block_item_avatar_img {
		box-shadow: 0 0 2px #FF848B;
		background: #FF848B;
	}
	.nox_modal_info_team_invited_users_block_item_avatar_status {
		position: absolute;
		top: 50%;
		left: 0;
		margin: -30px 0 0 8px;
		padding: 0 5px;
		border: 1px solid #FFFFFF;
		border-radius: 11px;
		background: #B8C3CA;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 20px;
		min-width: 22px;
		height: 22px;
		z-index: 1;
	}
	.nox_modal_info_team_invited_users_block_item_status {
		position: absolute;
		top: 50%;
		left: 0;
		margin: 18px 0 0 -50px;
		border: 1px solid #FFFFFF;
		border-radius: 18px;
		background: #6F6F6F;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 8px;
		line-height: 16px;
		width: 100px;
		height: 20px;
		z-index: 1;
	}
	.nox_modal_info_team_invited_users_block_item.item_grey .nox_modal_info_team_invited_users_block_item_status {
		background: #E1E1E1;
	}
	.nox_modal_info_team_invited_users_block_item.item_orange .nox_modal_info_team_invited_users_block_item_status {
		background: #E1B673;
	}
	.nox_modal_info_team_invited_users_block_item.item_red .nox_modal_info_team_invited_users_block_item_status {
		background: #FF848B;
	}
	.nox_modal_info_team_invited_users_block_item_table .nox_table {
		margin: 0;
	}
	.nox_modal_info_team_invited_users_block_item_table .nox_table tr {
		margin: 0;
		border: 0;
		display: block;
	}
	.nox_modal_info_team_invited_users_block_item_table .nox_table tr td {
		padding: 0 5px;
		border: 0;
		border-color: transparent;
		text-align: right;
		font-size: 10px;
		line-height: 18px;
		display: block;
		width: auto !important;
		min-width: 100px;
		min-height: 18px;
	}
	.nox_modal_info_team_invited_users_block_item_table .nox_table tr td:before {
		content: attr(data-label);
		float: left;
		padding: 0 5px 0 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_modal_info_team_invited_users_block_item_table .nox_table tr td .sticker {
		padding: 0 10px;
		border-radius: 10px;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 10px;
		line-height: 16px;
		display: inline-block;
		height: 16px;
	}
	.nox_modal_info_team_invited_users_block_item_table .nox_table tr td .sticker.sticker_r_w {
		background: #FF4040;
		color: #FFFFFF;
	}
	.nox_modal_info_team_invited_users_block_item_table .nox_table tr td .sticker.sticker_w_r {
		background: #FFFFFF;
		color: #FF6666;
	}
	.nox_modal_info_team_invited_users_block_item.item_grey .nox_modal_info_team_invited_users_block_item_table .nox_table tr th,
	.nox_modal_info_team_invited_users_block_item.item_grey .nox_modal_info_team_invited_users_block_item_table .nox_table tr td,
	.nox_modal_info_team_invited_users_block_item.item_grey .nox_modal_info_team_invited_users_block_item_table .nox_table tr td a {
		color: #555555;
	}
	.nox_modal_info_editor_landing_elements_tools {
		padding: 0 0 5px 0;
		overflow-y: auto;
		overflow-anchor: auto;
		max-height: 500px;
	}
	.nox_modal_info_editor_landing_elements_tools_row {
		margin: 5px 0 0 0;
	}
	.nox_modal_info_editor_landing_elements_tools_data {
		border: 5px solid #F1F1F1;
		border-radius: 5px;
		background: #FFFFFF;
		cursor: pointer;
		width: 100%;
	}
	.nox_modal_alert .alert {
		margin: 15px 0 0 0;
		padding: 8px 10px 10px 10px;
	}
	.nox_modal_countdown {
		position: relative;
		margin: 25px 0 0 0;
		text-align: center;
		display: table;
		width: 100%;
		height: 100px;
	}
	.nox_modal_countdown_block {
		position: absolute;
		top: 0;
		left: 50%;
		margin: 0 0 0 -50px;
		width: 100px;
		height: 100px;
	}
	.nox_modal_countdown_block svg {
		transform: scaleX(-1);
	}
	.nox_modal_countdown_block svg g {
		stroke: none;
		fill: none;
	}
	.nox_modal_countdown_block svg g circle {
		stroke-width: 8px;
		stroke: #FFFFFF;
	}
	.nox_modal_countdown_block svg g path {
		stroke-width: 4px;
		stroke-linecap: round;
		transform: rotate(90deg);
		transform-origin: center;
		transition: 1s linear all;
		stroke: #48B849;
	}
	.nox_modal_countdown_block span {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -10px 0 0 -30px;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
		line-height: 18px;
		display: block;
		width: 60px;
		height: 20px;
	}
	.nox_modal_loading,
	.nox_modal_buttons {
		margin: 25px -30px -30px -30px;
		padding: 20px 30px;
		border-top: 1px solid #707070;
		border-radius: 0 0 15px 15px;
		background: #33333366;
		text-align: right;
		line-height: 20px;
		min-height: 20px;
	}
	.nox_modal_buttons button {
		margin: 0 0 0 10px;
	}
	.nox_modal_buttons button:first-child {
		margin: 0;
	}
	.nox_modal_buttons button:hover {
		opacity: 0.8;
	}
	.nox_is_mobile {
		display: none;
	}

	::-webkit-input-placeholder { color: #CCCCCC; }
	::-moz-placeholder          { color: #CCCCCC; }
	:-moz-placeholder           { color: #CCCCCC; }
	:-ms-input-placeholder      { color: #CCCCCC; }
	input[placeholder]          { text-overflow: ellipsis; }
	input::-moz-placeholder     { text-overflow: ellipsis; }
	input:-moz-placeholder      { text-overflow: ellipsis; }
	input:-ms-input-placeholder { text-overflow: ellipsis; }

	jdiv {
		z-index: 10 !important;
	}

	@keyframes loading {
		from { transform: rotate(0); }
		to	 { transform: rotate(359deg); }
	}
	@media screen and (orientation:landscape) {
		jdiv .__jivoMobileButton {
			display: none;
		}
	}
	@media (max-width: 1200px) {
		.nox_table thead {
			display: none;
		}
		.nox_table tr {
			margin: 0 0 10px 0;
			border-bottom: 1px solid #999999;
			display: block;
		}
		.nox_table tr:last-child {
			margin: 0;
		}
		.nox_table tr td {
			padding: 7px 12px;
			border-bottom: 0;
			text-align: right;
			font-size: 11px;
			display: block;
			width: auto !important;
			min-height: 32px;
		}
		.nox_table tr td:before {
			content: attr(data-label);
			float: left;
			padding: 0 5px 0 0;
			font-family: 'OpenSansSemiBold', Arial, sans-serif;
		}
		.nox_table tr td.button {
			padding: 7px 47px 7px 12px;
		}
		.nox_table tr td.buttons {
			padding: 7px 82px 7px 12px;
		}
		.nox_table tr td .nox_input {
			float: none;
			text-align: right;
			width: 60%;
		}
		.nox_table tr td .nox_toggle_button {
			float: right;
			margin: -4px 0 -8px 0;
			vertical-align: baseline;
		}
	}
	@media (max-width: 1000px) {
		.nox_button.big {
			font-size: 11px;
		}
		.nox_tab_menu_label_text {
			display: none;
		}
		.nox_table {
			margin: 0;
		}
		.nox_chart {
			margin: 0 0 15px 0;
		}
		.nox_chart:last-child {
			margin: 0;
		}
		.nox_chart .highcharts-title {
			font-size: 12px !important;
		}
		.nox_chart .nox_chart_description {
			font-size: 12px;
			line-height: 18px;
		}
		.nox_notification.clipboard {
			top: 50px !important;
		}
		.nox_modal_info_tickets_team_item_content span {
			margin: 0 5px 0 0;
		}
		.nox .v--modal {
			padding: 55px 15px;
		}
		jdiv #jvlabelWrap {
			margin: 0 -20px 0 0;
		}
	}
	@media (max-width: 760px) {
		.nox_chart .nox_chart_description {
			font-size: 11px;
			line-height: 16px;
		}
		.nox_chart.columns {
			display: block;
		}
		.nox_chart.columns .nox_chart_highcharts {
			float: none;
			width: auto;
		}
		.nox_chart.columns .nox_chart_description {
			float: none;
			padding: 5px 10px 10px 10px;
			width: auto;
		}
		.nox_chart.columns .nox_chart_description div {
			display: block;
			height: auto;
		}
		.nox_modal_info_text,
		.nox_modal_info_chart {
			margin: 30px 0;
		}
		.nox_modal_info_rows.rows_bg,
		.nox_modal_info_rows.last.rows_bg {
			margin: 0 -20px 25px -20px;
			padding: 25px 19px;
			border-radius: 15px;
		}
		.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg,
		.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg.row_bg_start,
		.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg.row_bg_finish {
			margin: 0 -19px;
			padding: 0 19px;
			width: calc(100% + 38px);
		}
		.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg.row_bg_start {
			padding: 25px 19px 0 19px;
		}
		.nox_modal_info_rows.rows_bg .nox_modal_info_row.row_bg.row_bg_finish {
			margin: 0 -19px 25px -19px;
		}
		.nox_modal_info_rows.rows_bg .nox_modal_info_row:last-child .nox_modal_info_row_value {
			margin: 0;
		}
		.nox_modal_info_row.single {
			display: table;
		}
		.nox_modal_info_row .nox_modal_info_row_label,
		.nox_modal_info_row.single .nox_modal_info_row_label {
			float: none;
			margin: 0 0 3px 0;
			text-align: left;
			font-size: 12px;
			line-height: 16px;
			width: 100%;
			height: auto;
		}
		.nox_modal_info_row .nox_modal_info_row_label .nox_tooltip_icon,
		.nox_modal_info_row.single .nox_modal_info_row_label .nox_tooltip_icon {
			margin: 0 0 0 5px;
		}
		.nox_modal_info_row .nox_modal_info_row_value,
		.nox_modal_info_row.single .nox_modal_info_row_value {
			float: none;
			margin: 0 0 25px 0;
			padding: 0;
			flex: none;
			display: table;
			width: 100%;
		}
		.nox_modal_info_row.margin_top_20,
		.nox_modal_info_row.last .nox_modal_info_row_value,
		.nox_modal_info_row.single.last .nox_modal_info_row_value {
			margin: 0;
		}
		.nox_modal_info_attention {
			padding: 13px 20px 15px 20px;
		}
		.nox_modal_info_tree_gift_details_image {
			margin: 0 0 10px 0;
			width: 100%;
		}
		.nox_modal_info_phoenix_settings_content .nox_modal_info_row_value .nox_cselect {
			width: 100%;
		}
		.nox_modal_info_phoenix_settings_content_data {
			margin: 0 0 -25px 0;
		}
		.nox_modal_info_user_card_avatar {
			position: relative;
			margin: 30px 0 15px -50px;
			left: 50%;
			width: 100px;
			height: 135px;
		}
		.nox_modal_info_user_card_avatar_img,
		.nox_modal_info_user_card_avatar_img img {
			width: 100px;
			height: 100px;
		}
		.nox_modal_info_user_card_avatar_rating {
			margin: 5px 0 0 -12.5px;
		}
		.nox_modal_info_user_card_avatar_status {
			top: 0;
		}
		.nox_modal_info_user_card_content {
			padding: 0;
		}
		.nox_modal_info_user_card_content_row {
			padding: 3px 0;
			font-size: 11px;
			line-height: 15px;
			display: flex;
		}
		.nox_modal_info_user_card_content_label {
			margin: 0;
			padding: 0 5px 0 0;
			text-align: right;
			width: 50%;
		}
		.nox_modal_info_user_card_content_value {
			padding: 0 0 0 5px;
			align-items: flex-end;
			display: flex;
			width: 50%;
		}
		.nox_modal_info_user_card_content_socs {
			text-align: center;
		}
	}
	@media (max-width: 550px) {
		.nox_button.big {
			padding: 0 8px;
			font-size: 10px;
		}
		.nox_button.big svg {
			margin: 0 5px 0 0;
			font-size: 9px;
		}
		.nox_is_desktop {
			display: none;
		}
		.nox_is_mobile {
			display: inline;
		}
	}
	@media (max-width: 500px) {
		.br_500 {
			display: block;
		}
		.nox_modal_info_img {
			float: none;
			position: relative;
			left: 50%;
			margin: 0 0 20px -75px;
		}
		.nox_modal_info_payment_card,
		.nox_modal_info_payment_method {
			padding: 3px;
			width: 50%;
		}
		.nox_modal_info_payment_card_content_holder {
			font-size: 8px;
		}
		.nox_modal_info_payment_card_content_loading,
		.nox_modal_info_payment_card_content_caption {
			font-size: 9px;
			line-height: 18px;
			height: 18px;
		}
		.nox_modal_info_tickets_accordion_item_body_qrcode {
			position: relative;
			top: 0;
			left: 0;
			margin: 5px 0 45px 0;
		}
		.nox_modal_info_tickets_accordion_item_body_comment {
			padding: 0;
		}
		.nox_modal_info_splash .splash_type_1 img {
			position: relative;
			float: none;
			left: 50%;
			margin: 15px 0 15px -100px;
			display: block;
			width: 200px;
		}
	}
	@media (max-width: 490px) {
		.br_490 {
			display: block;
		}
	}
	@media (max-width: 480px) {
		.br_480 {
			display: block;
		}
	}
	@media (max-width: 470px) {
		.br_470 {
			display: block;
		}
	}
	@media (max-width: 460px) {
		.br_460 {
			display: block;
		}
	}
	@media (max-width: 450px) {
		.br_450 {
			display: block;
		}
		.nox_modal_info_team_invited_users_block,
		.nox_modal_info_team_invited_users_block:first-child {
			padding: 5px 0 0 0;
		}
		.nox_modal_info_team_invited_users_block:first-child .nox_modal_info_team_invited_users_block_item {
			margin: 50px 0 0 0;
		}
		.nox_modal_info_team_invited_users_block_item {
			margin: 70px 0 0 0;
			padding: 10px;
		}
		.nox_modal_info_team_invited_users_block_item_number,
		.nox_modal_info_team_invited_users_block_item_avatar_img,
		.nox_modal_info_team_invited_users_block_item_avatar_status {
			top: 0;
			left: 50%;
			margin: -56px 0 0 -30px;
		}
		.nox_modal_info_team_invited_users_block_item_avatar_status {
			margin: -56px 0 0 8px;
		}
		.nox_modal_info_team_invited_users_block_item_status {
			top: -10px;
			left: 50%;
			margin: 0 0 0 -50px;
		}
	}
	@media (max-width: 440px) {
		.br_440 {
			display: block;
		}
	}
	@media (max-width: 430px) {
		.br_430 {
			display: block;
		}
	}
	@media (max-width: 425px) {
		.nox_notification.quest {
			left: 20px !important;
			right: 20px !important;
			bottom: 5px !important;
			width: auto !important;
		}
		.nox_notification.quest .nox_notification_quest {
			border-left: 5px solid #339333;
			border-right: 5px solid #339333;
			border-radius: 15px;
		}
		.nox_notification.clipboard {
			left: 20px !important;
			right: 20px !important;
			width: auto !important;
		}
	}
	@media (max-width: 420px) {
		.br_420 {
			display: block;
		}
	}
	@media (max-width: 410px) {
		.br_410 {
			display: block;
		}
	}
	@media (max-width: 400px) {
		.br_400 {
			display: block;
		}
		.nox_tab_menu_label {
			padding: 0 10px;
			min-width: 55px;
		}
		.nox_modal_info_payment_card_delete,
		.nox_modal_info_payment_method_content_help {
			top: -2px;
			right: -2px;
			border-width: 1px;
			line-height: 16px;
			width: 18px;
			height: 18px;
		}
		.nox_modal_info_payment_card_content,
		.nox_modal_info_payment_method_content_image img {
			padding: 10px 0;
		}
		.nox_modal_info_payment_card_content_type {
			margin: 0 0 5px 0;
			font-size: 13px;
		}
		.nox_modal_info_payment_card_content_type svg {
			font-size: 25px;
		}
		.nox_modal_info_payment_crypto_info_qrcode {
			float: none;
			margin: 5px 0 15px 0;
		}
	}
	@media (max-width: 390px) {
		.br_390 {
			display: block;
		}
	}
	@media (max-width: 380px) {
		.br_380 {
			display: block;
		}
	}
	@media (max-width: 370px) {
		.br_370 {
			display: block;
		}
	}
	@media (max-width: 360px) {
		.br_360 {
			display: block;
		}
	}
	@media (max-width: 350px) {
		.br_350 {
			display: block;
		}
		.tooltip .tooltip-inner {
			max-width: 270px;
		}
		.nox_modal_info_payment_crypto_address_value_text {
			font-size: 12px;
		}
		.nox_modal_buttons button {
			font-size: 12px;
		}
	}
	@media (max-width: 340px) {
		.br_340 {
			display: block;
		}
	}
	@media (max-width: 330px) {
		.br_330 {
			display: block;
		}
	}
	@media (max-width: 320px) {
		.br_320 {
			display: block;
		}
	}
	@media (max-width: 310px) {
		.br_310 {
			display: block;
		}
	}
	@media (max-width: 300px) {
		.br_300 {
			display: block;
		}
	}
</style>
