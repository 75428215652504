<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 0)"></div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_portlet_content_intro">
						<div class="nox_account_desktop_portlet_content_img_200">
							<img src="@/assets/images/bot_img_9.png">
							<div v-html="$store.getters.getLanguageText('3.8', 1)"></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_portlet_buttons">
					<button type="button" class="nox_button big" @click="$router.push({ path: '/account/alert_settings' })"><font-awesome-icon :icon="['fas', 'tools']" /><span v-html="$store.getters.getLanguageText('1.1', 44)"></span></button>
				</div>
			</div>
			<div class="nox_tabs">
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_1" name="nox_tab_menu" :checked="noxIsTabMenu1Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_1" @click="selectTab(1)" v-html="$store.getters.getLanguageText('3.8', 2)"></label>
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_2" name="nox_tab_menu" :checked="noxIsTabMenu2Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_2" @click="selectTab(2)"><font-awesome-icon :icon="['fab', 'first-order']" /></label>
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_3" name="nox_tab_menu" :checked="noxIsTabMenu3Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_3" @click="selectTab(3)"><font-awesome-icon :icon="['fas', 'users']" /></label>
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_4" name="nox_tab_menu" :checked="noxIsTabMenu4Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_4" @click="selectTab(4)"><font-awesome-icon :icon="['fas', 'coins']" /></label>
				<input type="radio" class="nox_tab_menu_input" id="nox_tab_menu_5" name="nox_tab_menu" :checked="noxIsTabMenu5Checked">
				<label class="nox_tab_menu_label" for="nox_tab_menu_5" @click="selectTab(5)"><font-awesome-icon :icon="['fas', 'bullhorn']" /></label>
				<div class="nox_tab_block" id="nox_tab_block_1">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 3)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_alerts">
								<table class="nox_table" v-if="noxAlertsDataForAll.length">
									<thead>
										<tr>
											<th v-html="$store.getters.getLanguageText('3.8', 8)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 9)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 10)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 11)"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value, index) in noxAlertsDataForAll" :key="index">
											<td :data-label="$store.getters.getLanguageText('3.8', 8) + ':'">{{ value.id }}</td>
											<td :data-label="$store.getters.getLanguageText('3.8', 9) + ':'"><font-awesome-icon :icon="[(value.type == 1 ? 'fab' : 'fas'), (value.type == 1 ? 'first-order' : (value.type == 2 ? 'users' : (value.type == 3 ? 'coins' : 'bullhorn')))]" /></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 10) + ':'" v-html="getTransliteration(value.details)"></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 11) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
										</tr>
									</tbody>
								</table>
								<div v-else v-html="$store.getters.getLanguageText('3.8', 12)"></div>
							</div>
						</div>
						<div v-if="noxAlertsLimitOffsetForAll">
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoadingForAll }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoadingForAll }">
								<button type="button" class="nox_button big" @click="axios('getAlertsData', 'all')"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_tab_block" id="nox_tab_block_2">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 4)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_alerts">
								<table class="nox_table" v-if="noxAlertsDataForType1.length">
									<thead>
										<tr>
											<th v-html="$store.getters.getLanguageText('3.8', 8)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 9)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 10)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 11)"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value, index) in noxAlertsDataForType1" :key="index">
											<td :data-label="$store.getters.getLanguageText('3.8', 8) + ':'">{{ value.id }}</td>
											<td :data-label="$store.getters.getLanguageText('3.8', 9) + ':'"><font-awesome-icon :icon="['fab', 'first-order']" /></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 10) + ':'" v-html="getTransliteration(value.details)"></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 11) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
										</tr>
									</tbody>
								</table>
								<div v-else v-html="$store.getters.getLanguageText('3.8', 12)"></div>
							</div>
						</div>
						<div v-if="noxAlertsLimitOffsetForType1">
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoadingForType1 }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoadingForType1 }">
								<button type="button" class="nox_button big" @click="axios('getAlertsData', 1)"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_tab_block" id="nox_tab_block_3">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 5)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_alerts">
								<table class="nox_table" v-if="noxAlertsDataForType2.length">
									<thead>
										<tr>
											<th v-html="$store.getters.getLanguageText('3.8', 8)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 9)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 10)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 11)"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value, index) in noxAlertsDataForType2" :key="index">
											<td :data-label="$store.getters.getLanguageText('3.8', 8) + ':'">{{ value.id }}</td>
											<td :data-label="$store.getters.getLanguageText('3.8', 9) + ':'"><font-awesome-icon :icon="['fas', 'users']" /></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 10) + ':'" v-html="getTransliteration(value.details)"></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 11) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
										</tr>
									</tbody>
								</table>
								<div v-else v-html="$store.getters.getLanguageText('3.8', 12)"></div>
							</div>
						</div>
						<div v-if="noxAlertsLimitOffsetForType2">
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoadingForType2 }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoadingForType2 }">
								<button type="button" class="nox_button big" @click="axios('getAlertsData', 2)"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_tab_block" id="nox_tab_block_4">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 6)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_alerts">
								<table class="nox_table" v-if="noxAlertsDataForType3.length">
									<thead>
										<tr>
											<th v-html="$store.getters.getLanguageText('3.8', 8)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 9)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 10)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 11)"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value, index) in noxAlertsDataForType3" :key="index">
											<td :data-label="$store.getters.getLanguageText('3.8', 8) + ':'">{{ value.id }}</td>
											<td :data-label="$store.getters.getLanguageText('3.8', 9) + ':'"><font-awesome-icon :icon="['fas', 'coins']" /></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 10) + ':'" v-html="getTransliteration(value.details)"></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 11) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
										</tr>
									</tbody>
								</table>
								<div v-else v-html="$store.getters.getLanguageText('3.8', 12)"></div>
							</div>
						</div>
						<div v-if="noxAlertsLimitOffsetForType3">
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoadingForType3 }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoadingForType3 }">
								<button type="button" class="nox_button big" @click="axios('getAlertsData', 3)"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_tab_block" id="nox_tab_block_5">
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.8', 7)"></div>
						<div class="nox_account_desktop_portlet_content">
							<div class="nox_account_desktop_alerts">
								<table class="nox_table" v-if="noxAlertsDataForType4.length">
									<thead>
										<tr>
											<th v-html="$store.getters.getLanguageText('3.8', 8)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 9)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 10)"></th>
											<th v-html="$store.getters.getLanguageText('3.8', 11)"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value, index) in noxAlertsDataForType4" :key="index">
											<td :data-label="$store.getters.getLanguageText('3.8', 8) + ':'">{{ value.id }}</td>
											<td :data-label="$store.getters.getLanguageText('3.8', 9) + ':'"><font-awesome-icon :icon="['fas', 'bullhorn']" /></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 10) + ':'" v-html="getTransliteration(value.details)"></td>
											<td :data-label="$store.getters.getLanguageText('3.8', 11) + ':'">{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</td>
										</tr>
									</tbody>
								</table>
								<div v-else v-html="$store.getters.getLanguageText('3.8', 12)"></div>
							</div>
						</div>
						<div v-if="noxAlertsLimitOffsetForType4">
							<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoadingForType4 }"><img src="@/assets/images/loading.gif"></div>
							<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoadingForType4 }">
								<button type="button" class="nox_button big" @click="axios('getAlertsData', 4)"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 20)"></span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxAlertsDataForAll: [],
			noxAlertsDataForType1: [],
			noxAlertsDataForType2: [],
			noxAlertsDataForType3: [],
			noxAlertsDataForType4: [],
			noxAlertsTransliteration: {},
			noxAlertsFilterType: 0,
			noxAlertsFilterSort: 0,
			noxAlertsLimitOffsetForAll: 0,
			noxAlertsLimitOffsetForType1: 0,
			noxAlertsLimitOffsetForType2: 0,
			noxAlertsLimitOffsetForType3: 0,
			noxAlertsLimitOffsetForType4: 0,
			noxAlertsLimitOffset: 0,
			noxAlertsLimitCount: 10,
			noxIsTabMenu1Checked: false,
			noxIsTabMenu2Checked: false,
			noxIsTabMenu3Checked: false,
			noxIsTabMenu4Checked: false,
			noxIsTabMenu5Checked: false,
			noxIsPortletLoadingForAll: false,
			noxIsPortletLoadingForType1: false,
			noxIsPortletLoadingForType2: false,
			noxIsPortletLoadingForType3: false,
			noxIsPortletLoadingForType4: false,
			noxIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getAlertsData');
			},
			initTabs: function(tab) {
				this.noxIsTabMenu1Checked = false;
				this.noxIsTabMenu2Checked = false;
				this.noxIsTabMenu3Checked = false;
				this.noxIsTabMenu4Checked = false;
				this.noxIsTabMenu5Checked = false;
				if      (tab == 2) { this.noxIsTabMenu2Checked = true; }
				else if (tab == 3) { this.noxIsTabMenu3Checked = true; }
				else if (tab == 4) { this.noxIsTabMenu4Checked = true; }
				else if (tab == 5) { this.noxIsTabMenu5Checked = true; }
				else               { this.noxIsTabMenu1Checked = true; }
			},
			getTransliteration: function(data) {
				var text = '';
				if (data !== undefined && Object.keys(data).length) {
					text = this.noxAlertsTransliteration[this.$store.state.noxLanguage][data.index];
					if (data.fvar1 !== undefined) { data.fvar1 = parseFloat(data.fvar1).toFixed(2); }
					if (data.fvar2 !== undefined) { data.fvar2 = parseFloat(data.fvar2).toFixed(2); }
					if (data.fvar3 !== undefined) { data.fvar3 = parseFloat(data.fvar3).toFixed(2); }
					if (data.fvar4 !== undefined) { data.fvar4 = parseFloat(data.fvar4).toFixed([40,110].includes(data.index) ? 0 : 2); }
					if (data.fvar5 !== undefined) { data.fvar5 = parseFloat(data.fvar5).toFixed(2); }
					if (data.fvar6 !== undefined) { data.fvar6 = parseFloat(data.fvar6).toFixed(2); }
					if (data.fvar7 !== undefined) { data.fvar7 = parseFloat(data.fvar7).toFixed(2); }
					if (data.fvar8 !== undefined) { data.fvar8 = parseFloat(data.fvar8).toFixed(data.index == 40 ? 1 : 2); }
					if (data.conditions !== undefined) { text = this.$store.getters.getLanguageTextForDetailsConditions(text, data.conditions); }
					if (data.data_arrays !== undefined) { text = this.$store.getters.getLanguageTextForDetailsDataArrays(text, data.data_arrays); }
					if (data.data_lists !== undefined) { text = this.$store.getters.getLanguageTextForDetailsDataLists(text, data.data_lists); }
					if (data.options !== undefined) { text = this.$store.getters.getLanguageTextForDetailsOptions(text, data.options); }
					text = this.$store.getters.getLanguageTextForDetailsVars(text, data);
				}
				return text;
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getAlertsData') {
						if (var1 !== undefined) {
							if (var1 == 1) {
								_this.noxAlertsFilterType = 1;
								_this.noxAlertsLimitOffset = _this.noxAlertsLimitOffsetForType1;
								_this.noxIsPortletLoadingForType1 = true;
							}
							else if (var1 == 2) {
								_this.noxAlertsFilterType = 2;
								_this.noxAlertsLimitOffset = _this.noxAlertsLimitOffsetForType2;
								_this.noxIsPortletLoadingForType2 = true;
							}
							else if (var1 == 3) {
								_this.noxAlertsFilterType = 3;
								_this.noxAlertsLimitOffset = _this.noxAlertsLimitOffsetForType3;
								_this.noxIsPortletLoadingForType3 = true;
							}
							else if (var1 == 4) {
								_this.noxAlertsFilterType = 4;
								_this.noxAlertsLimitOffset = _this.noxAlertsLimitOffsetForType4;
								_this.noxIsPortletLoadingForType4 = true;
							}
							else {
								_this.noxAlertsFilterType = 0;
								_this.noxAlertsLimitOffset = _this.noxAlertsLimitOffsetForAll;
								_this.noxIsPortletLoadingForAll = true;
							}
						}
						else {
							_this.noxAlertsFilterType = 0;
							_this.noxAlertsLimitOffset = 0;
							_this.noxIsLoading = true;
							_this.$store.state.noxIsAccount = true;
						}
						config.url = '/v2/account/alerts';
						config.params = { limitOffset: _this.noxAlertsLimitOffset, limitCount: _this.noxAlertsLimitCount, filterType: _this.noxAlertsFilterType, filterSort: _this.noxAlertsFilterSort };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getAlertsData') {
								_this.$parent.$parent.goToTop(!_this.noxAlertsLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.alerts_data_for_all !== undefined) {
									if (data.data.alerts_data_for_all.data.length) {
										for (var i0 in data.data.alerts_data_for_all.data) {
											_this.noxAlertsDataForAll.push(data.data.alerts_data_for_all.data[i0]);
										}
									}
									_this.noxAlertsLimitOffsetForAll = data.data.alerts_data_for_all.count > _this.noxAlertsLimitOffsetForAll + _this.noxAlertsLimitCount ? _this.noxAlertsLimitOffsetForAll + _this.noxAlertsLimitCount : 0;
								}
								if (data.data.alerts_data_for_type_1 !== undefined) {
									if (data.data.alerts_data_for_type_1.data.length) {
										for (var i1 in data.data.alerts_data_for_type_1.data) {
											_this.noxAlertsDataForType1.push(data.data.alerts_data_for_type_1.data[i1]);
										}
									}
									_this.noxAlertsLimitOffsetForType1 = data.data.alerts_data_for_type_1.count > _this.noxAlertsLimitOffsetForType1 + _this.noxAlertsLimitCount ? _this.noxAlertsLimitOffsetForType1 + _this.noxAlertsLimitCount : 0;
								}
								if (data.data.alerts_data_for_type_2 !== undefined) {
									if (data.data.alerts_data_for_type_2.data.length) {
										for (var i2 in data.data.alerts_data_for_type_2.data) {
											_this.noxAlertsDataForType2.push(data.data.alerts_data_for_type_2.data[i2]);
										}
									}
									_this.noxAlertsLimitOffsetForType2 = data.data.alerts_data_for_type_2.count > _this.noxAlertsLimitOffsetForType2 + _this.noxAlertsLimitCount ? _this.noxAlertsLimitOffsetForType2 + _this.noxAlertsLimitCount : 0;
								}
								if (data.data.alerts_data_for_type_3 !== undefined) {
									if (data.data.alerts_data_for_type_3.data.length) {
										for (var i3 in data.data.alerts_data_for_type_3.data) {
											_this.noxAlertsDataForType3.push(data.data.alerts_data_for_type_3.data[i3]);
										}
									}
									_this.noxAlertsLimitOffsetForType3 = data.data.alerts_data_for_type_3.count > _this.noxAlertsLimitOffsetForType3 + _this.noxAlertsLimitCount ? _this.noxAlertsLimitOffsetForType3 + _this.noxAlertsLimitCount : 0;
								}
								if (data.data.alerts_data_for_type_4 !== undefined) {
									if (data.data.alerts_data_for_type_4.data.length) {
										for (var i4 in data.data.alerts_data_for_type_4.data) {
											_this.noxAlertsDataForType4.push(data.data.alerts_data_for_type_4.data[i4]);
										}
									}
									_this.noxAlertsLimitOffsetForType4 = data.data.alerts_data_for_type_4.count > _this.noxAlertsLimitOffsetForType4 + _this.noxAlertsLimitCount ? _this.noxAlertsLimitOffsetForType4 + _this.noxAlertsLimitCount : 0;
								}
								if (data.data.alerts_transliteration !== undefined) {
									_this.noxAlertsTransliteration = data.data.alerts_transliteration;
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoadingForAll = false;
						_this.noxIsPortletLoadingForType1 = false;
						_this.noxIsPortletLoadingForType2 = false;
						_this.noxIsPortletLoadingForType3 = false;
						_this.noxIsPortletLoadingForType4 = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			selectTab: function(tab) {
				if (Number(this.$route.query.tab) !== tab) {
					this.$router.push({ path: '/account/alerts', query: { tab: tab }});
				}
			}
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_alerts tr th:nth-child(1),
	.nox_account_desktop_alerts tr td:nth-child(1),
	.nox_account_desktop_alerts tr th:nth-child(2),
	.nox_account_desktop_alerts tr td:nth-child(2) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_alerts tr td:nth-child(2) svg {
		font-size: 14px;
	}
	.nox_account_desktop_alerts tr td:nth-child(3) {
		text-align: left;
	}
	.nox_account_desktop_alerts tr th:last-child,
	.nox_account_desktop_alerts tr td:last-child {
		width: 170px;
	}
</style>
