<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_portlet">
				<div class="nox_account_desktop_portlet_title">Настройки феникса</div>
				<div class="nox_account_desktop_portlet_content">
					<div class="nox_account_desktop_phoenix_settings">
						<div class="nox_account_desktop_portlet_content_accordion" v-if="Object.keys(noxPhoenixSettingsForGroups).length">
							<div class="nox_account_desktop_portlet_content_accordion_block" :class="{ active: (noxToggleAccordionIndex == index) }" :id="'nox_account_desktop_portlet_content_accordion_block_' + index" v-for="(value, index) in Object.keys(noxPhoenixSettingsForGroups).length" :key="index">
								<div class="nox_account_desktop_portlet_content_accordion_block_item">
									<div class="nox_account_desktop_portlet_content_accordion_block_item_head" @click="toggleAccordion(index)">
										<div class="nox_account_desktop_portlet_content_accordion_block_item_head_title">{{ ['basic_configuration', 'trading_modification', 'trading_configuration', 'clv_configuration', 'news_trading', 'zz_trading_modification', 'zz_trading_configuration'][index] }}</div>
										<div class="nox_account_desktop_portlet_content_accordion_block_item_head_toggle">
											<font-awesome-icon :icon="['fas', 'angle-up']" v-if="noxIsToggleAccordion && noxToggleAccordionIndex == index" />
											<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
										</div>
									</div>
									<div class="nox_account_desktop_portlet_content_accordion_block_item_body" :class="{ hide: (!noxIsToggleAccordion || (noxIsToggleAccordion && noxToggleAccordionIndex !== index)) }">
										<div class="nox_account_desktop_portlet_content_accordion_block_item_body_value">
											<table class="nox_table" v-if="noxPhoenixSettingsForGroups[index].length">
												<thead>
													<tr>
														<th>№</th>
														<th>Имя</th>
														<th>Ключ</th>
														<th>Значение</th>
														<th>Тип</th>
														<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(value2, index2) in noxPhoenixSettingsForGroups[index]" :key="index2">
														<td data-label="№:">{{ value2.sort }}</td>
														<td data-label="Имя:">{{ value2.name }}</td>
														<td data-label="Ключ:">{{ value2.key }}</td>
														<td data-label="Значение:">{{ ([37,38,39,41,42,43,51,52,53,54,55,56,60,61,62,63,64,65,69,70,71,93,94,96,98,107,108,109,110,111,112,119,121,122,123,124,125,126,168,169,192,193,194,195,196,197,198,199,210,211,212,213,214,215,219,231,232,233,300,301,302,303,304,305,306,307,308,309,310,311,312,313,314,315,316,317,318,323,327,328,329,330,331,332,333,334,335,336,337,338,339,340,341,346,347,348,349,350,351,352,353,354,355,356,358,368,383,385,395,415,416,417,418,419,427,428,429,430,431,432,433,436,437,438].includes(value2.id) && $parent.$parent.isInteger(value2.value) ? value2.value.toFixed(1) : value2.value) }}</td>
														<td data-label="Тип:" :class="[(value2.type == 1 ? 'green' : (value2.type == 2 ? 'blue' : 'white'))]">{{ noxPhoenixSettingsTypes[value2.type].label }}</td>
														<td data-label="Изменить:"><button type="button" class="nox_button icon blue" @click="$modal.show('NoxModalPhoenixSettings', { type: 'editSettings', group: index, index: index2, id: value2.id, key: value2.key })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalPhoenixSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxPhoenixSettingsForGroups: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
			noxPhoenixSettingsTypes: [
				{ code: 0, label: 'global' },
				{ code: 1, label: 'custom' },
				{ code: 2, label: 'default' }
			],
			noxToggleAccordionIndex: -1,
			noxIsToggleAccordion: false,
			noxIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group == 1) {
					this.axios();
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/admin/account/phoenix/settings',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.noxIsLoading = true;
					_this.$store.state.noxIsAccount = true;

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.$parent.goToTop(true);
							_this.$parent.$parent.checkAppVersion(data.headers);
							if (data.data.length) {
								for (var i in data.data) {
									_this.noxPhoenixSettingsForGroups[data.data[i].group].push(data.data[i]);
								}
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleAccordion: function(index) {
				this.noxIsToggleAccordion = (this.noxToggleAccordionIndex == index ? !this.noxIsToggleAccordion : true);
				this.noxToggleAccordionIndex = (this.noxIsToggleAccordion ? index : -1);
				this.$parent.$parent.goToAccordionBlock(index);
			}
		},
		components: {
			NoxModalPhoenixSettings: () => import('@/views/modal_components/NoxModalPhoenixSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_phoenix_settings tr th:nth-child(1),
	.nox_account_desktop_phoenix_settings tr td:nth-child(1),
	.nox_account_desktop_phoenix_settings tr th:nth-child(6),
	.nox_account_desktop_phoenix_settings tr td:nth-child(6) {
		min-width: 45px;
		width: 1%;
	}
	.nox_account_desktop_phoenix_settings tr th:nth-child(5),
	.nox_account_desktop_phoenix_settings tr td:nth-child(5) {
		min-width: 65px;
		width: 1%;
	}
	.nox_account_desktop_phoenix_settings tr th:nth-child(2),
	.nox_account_desktop_phoenix_settings tr td:nth-child(2),
	.nox_account_desktop_phoenix_settings tr th:nth-child(3),
	.nox_account_desktop_phoenix_settings tr td:nth-child(3),
	.nox_account_desktop_phoenix_settings tr th:nth-child(4),
	.nox_account_desktop_phoenix_settings tr td:nth-child(4) {
		word-break: break-all;
		word-break: break-word;
		width: 28%;
	}
</style>
