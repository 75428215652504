<template>
	<div class="nox_account_desktop_page">
		<div class="nox_account_desktop_page_loading" v-if="noxIsLoading"><div class="nox_loading"></div></div>
		<div class="nox_account_desktop_page_content" v-else>
			<div class="nox_account_desktop_client_program">
				<div class="nox_account_desktop_client_program_content" v-if="$store.state.noxAccountData.is_client_program">
					<div class="nox_account_desktop_client_program_tree_stats" :class="'stats' + noxTreeStatsCols">
						<div class="nox_account_desktop_page_content_row">
							<div class="nox_account_desktop_page_content_col" v-if="noxTreeTopCurators.length">
								<div class="nox_account_desktop_portlet green">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5.2', 0, { count: noxTreeTopCurators.length })"></div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_client_program_tree_top_curators">
											<div class="nox_account_desktop_client_program_tree_top_curators_item" v-for="(value, index) in noxTreeTopCurators" :key="index"><b>{{ (index + 1) }}. </b><b v-if="value.username">{{ value.username }}</b><b v-else>NOX ID: {{ value.user_id }}</b><span v-if="$store.state.noxAccountData.group == 1"> ({{ value.points }})</span></div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_page_content_col" v-if="noxIsTreeBonus4Countdown">
								<div class="nox_account_desktop_portlet" :class="[ noxIsTreeBonus4CountdownExpires ? 'red' : 'blue' ]">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5.8', 0)"></div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_client_program_tree_booster_bonus">
											<div class="nox_countdown">
												<div class="nox_countdown_content">
													<div class="title" v-html="$store.getters.getLanguageText('3.5.8', 1)"></div>
													<div class="values" v-html="noxTreeBonus4CountdownValues"></div>
													<div class="labels" v-html="noxTreeBonus4CountdownLabels"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_page_content_col" v-if="noxTreeCurators.length">
								<div class="nox_account_desktop_portlet">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5.3', 0)"></div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_client_program_tree_curators">
											<div class="nox_account_desktop_client_program_tree_curators_content">
												<div class="nox_account_desktop_client_program_tree_curators_content_block" :class="'items' + noxTreeCurators.length">
													<div class="nox_account_desktop_client_program_tree_curators_content_block_item" v-for="(value, index) in noxTreeCurators" :key="index">
														<div class="nox_account_desktop_client_program_tree_curators_content_block_item_img"><img :src="$parent.$parent.getAvatarByLink(value[2])" @click="$modal.show('NoxModalUserCard', { type: 'showUserCard', id: value[0] })"></div>
														<div class="nox_account_desktop_client_program_tree_curators_content_block_item_name"><span v-if="value[1]">{{ value[1] }}</span><span v-else>NOX ID: {{ value[0] }}</span></div>
														<div class="nox_account_desktop_client_program_tree_curators_content_block_item_status" :style="{ background: '#' + $store.state.noxSystemSettings['user_status_color_' + value[3]] }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 117, { status: $store.getters.getLanguageText('4.3.1', value[3]), count: $store.state.noxSystemSettings['user_status_cycles_' + value[3]] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value[3]">{{ $store.state.noxSystemSettings['user_status_name_' + value[3]] }}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nox_account_desktop_page_content_col" v-if="noxTreeAchievements.length">
								<div class="nox_account_desktop_portlet">
									<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5.4', 0)"></div>
									<div class="nox_account_desktop_portlet_content">
										<div class="nox_account_desktop_client_program_tree_achievements">
											<div class="nox_account_desktop_client_program_tree_achievements_row"><b v-html="$store.getters.getLanguageText('3.5.4', 1)"></b><span>{{ noxTreeAchievements[0] }}</span></div>
											<div class="nox_account_desktop_client_program_tree_achievements_row"><b v-html="$store.getters.getLanguageText('3.5.4', 2)"></b><span>{{ noxTreeAchievements[1] }}</span></div>
											<div class="nox_account_desktop_client_program_tree_achievements_row"><b v-html="$store.getters.getLanguageText('3.5.4', 3)"></b><span>{{ noxTreeAchievements[2] }}</span></div>
											<div class="nox_account_desktop_client_program_tree_achievements_row"><b v-html="$store.getters.getLanguageText('3.5.4', 4)"></b><span>{{ noxTreeAchievements[3] }}</span></div>
											<div class="nox_account_desktop_client_program_tree_achievements_row"><b v-html="$store.getters.getLanguageText('3.5.4', 5)"></b><span>{{ noxTreeAchievements[4] }}</span></div>
											<div class="nox_account_desktop_client_program_tree_achievements_row" v-if="noxTreeAchievements[5] >= 0"><b v-html="$store.getters.getLanguageText('3.5.4', 6)"></b><span>{{ noxTreeAchievements[5] }}</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_client_program_tree_gifts" v-if="Object.keys(noxTreeBonuses).length && (noxTreeBonuses.bonus_6_finish || Object.keys(noxTreeBonuses.bonus_6_details).length)">
						<div class="nox_account_desktop_page_content_row">
							<div class="nox_account_desktop_page_content_col" v-for="(value, index) in Number($store.state.noxSystemSettings.count_gifts_for_bonus_6)" :key="index">
								<div class="nox_account_desktop_portlet" :class="{ grey: noxTreeBonuses.bonus_6_id < value || (noxTreeBonuses.bonus_6_id == value && !noxTreeBonuses.bonus_6_finish) }">
									<div class="nox_account_desktop_client_program_tree_gift">
										<div class="nox_account_desktop_client_program_tree_gift_content">
											<div class="nox_account_desktop_client_program_tree_gift_content_image" :class="{ active: noxTreeBonuses.bonus_6_id > value, progress: (noxTreeBonuses.bonus_6_id == value && noxTreeBonuses.bonus_6_finish) }">
												<div class="nox_account_desktop_client_program_tree_gift_content_image_type"><span v-if="((noxTreeBonuses.bonus_6_details[value] && noxTreeBonuses.bonus_6_details[value].type == 1) || (!noxTreeBonuses.bonus_6_details[value] && getTreeGiftAmount(value)))">${{ (noxTreeBonuses.bonus_6_details[value] && noxTreeBonuses.bonus_6_details[value].type == 1 ? noxTreeBonuses.bonus_6_details[value].amount : getTreeGiftAmount(value)) }}</span><img :src="require(`@/assets/images/gift_${value}.png`)" v-else></div>
												<div class="nox_account_desktop_client_program_tree_gift_content_image_progress" v-if="noxTreeBonuses.bonus_6_id == value && noxTreeBonuses.bonus_6_finish">
													<div class="nox_account_desktop_client_program_tree_gift_content_image_progress_slice" :class="{ s50: getTreeGiftProgress(value) > 50 }">
														<div class="pie" :style="{ transform: 'rotate(' + (360 / 100 * getTreeGiftProgress(value)) + 'deg)' }"></div>
														<div class="pie" :style="{ transform: 'rotate(' + (360 / 100 * getTreeGiftProgress(value)) + 'deg)' }"></div>
													</div>
												</div>
											</div>
											<div class="nox_account_desktop_client_program_tree_gift_content_title" v-html="$store.getters.getLanguageText('3.5.5', index)"></div>
											<div class="nox_account_desktop_client_program_tree_gift_content_block">
												<div class="nox_account_desktop_client_program_tree_gift_content_block_row">
													<span class="nox_account_desktop_client_program_tree_gift_content_block_label" v-html="$store.getters.getLanguageText('3.5.7', 0)"></span>
													<span class="nox_account_desktop_client_program_tree_gift_content_block_value">
														<span v-if="noxTreeBonuses.bonus_6_id >= value && noxTreeBonuses.bonus_6_finish">{{ (noxTreeBonuses.bonus_6_id > value ? $store.state.noxSystemSettings['count_cycles_for_receive_gift' + value] : noxTreeBonuses.bonus_6_points) }} / </span>
														<span>{{ $store.state.noxSystemSettings['count_cycles_for_receive_gift' + value] }}</span>
													</span>
												</div>
												<div class="nox_account_desktop_client_program_tree_gift_content_block_row" v-if="noxTreeBonuses.bonus_6_id == value && noxTreeBonuses.bonus_6_finish">
													<span class="nox_account_desktop_client_program_tree_gift_content_block_label" v-html="$store.getters.getLanguageText('3.5.7', 1)"></span>
													<span class="nox_account_desktop_client_program_tree_gift_content_block_value" v-html="noxTreeBonus6CountdownValue"></span>
												</div>
												<div class="nox_account_desktop_client_program_tree_gift_content_block_row" v-else-if="noxTreeBonuses.bonus_6_id > value">
													<span class="nox_account_desktop_client_program_tree_gift_content_block_status" :class="{ green: noxTreeBonuses.bonus_6_details[value].status == 4, orange: [1,2,3].includes(noxTreeBonuses.bonus_6_details[value].status) }" v-html="$store.getters.getLanguageText('3.5.6', 6)"></span>
												</div>
												<div class="nox_account_desktop_client_program_tree_gift_content_block_row" v-else-if="noxTreeBonuses.bonus_6_id < value || !noxTreeBonuses.bonus_6_finish">
													<span class="nox_account_desktop_client_program_tree_gift_content_block_status" v-html="$store.getters.getLanguageText('3.5.6', 1)"></span>
												</div>
											</div>
										</div>
										<div class="nox_account_desktop_client_program_tree_gift_buttons">
											<button type="button" class="nox_button small" @click="$modal.show('NoxModalTreeGifts', { type: 'detailsGift', id: value, index: index })"><font-awesome-icon :icon="['fas', 'info-circle']" /><span v-html="$store.getters.getLanguageText('1.1', 21)"></span></button>
											<button type="button" class="nox_button small" :class="{ green: noxTreeBonuses.bonus_6_details[value].status == 4, orange: [1,2,3].includes(noxTreeBonuses.bonus_6_details[value].status) }" @click="$modal.show('NoxModalTreeGifts', { type: (noxTreeBonuses.bonus_6_details[value].status == 1 ? 'receiveGift' : 'deliveryGift'), id: value, index: index })" v-if="noxTreeBonuses.bonus_6_details[value] && noxTreeBonuses.bonus_6_details[value].type == 0"><font-awesome-icon :icon="['fas', (noxTreeBonuses.bonus_6_details[value].status == 1 ? 'gift' : (noxTreeBonuses.bonus_6_details[value].status == 4 ? 'check' : 'hourglass-half'))]" /><span v-html="$store.getters.getLanguageText('1.1', (70 + Number(noxTreeBonuses.bonus_6_details[value].status)))"></span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_account_desktop_client_program_tree" v-if="$store.state.noxAccountData.group == 4">
						<div class="nox_account_desktop_portlet red">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5', 0)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.5', 4)"></div>
						</div>
					</div>
					<div class="nox_account_desktop_client_program_tree" id="nox_account_desktop_client_program_tree" v-else-if="noxTree.depth0 && ([1,2].includes($store.state.noxAccountData.group) || $store.state.noxAccountData.id !== noxTree.depth0[0].user_id || noxTree.depth0[0].lcount || noxTree.depth0[0].rcount)">
						<div class="nox_account_desktop_page_content_navigation">
							<div class="nox_account_desktop_page_content_navigation_title" v-html="$store.getters.getLanguageText('3.5', 0)"></div>
							<div class="nox_account_desktop_page_content_navigation_search">
								<input type="text" class="nox_input" v-model.number="noxTreeSearch" @keyup.13="search" :placeholder="$store.getters.getLanguageText('1.8', 1)" autocomplete="off">
								<div class="nox_account_desktop_page_content_navigation_search_button" @click="search"><font-awesome-icon :icon="['fas', 'search']" /></div>
								<div class="nox_account_desktop_page_content_navigation_search_result" v-show="noxTreeSearchResult.length || noxIsTreeSearchLoading">
									<div class="nox_account_desktop_page_content_navigation_search_result_block">
										<div class="nox_account_desktop_page_content_navigation_search_result_loading" :class="{ hide: !noxIsTreeSearchLoading }"><span v-html="$store.getters.getLanguageText('1.8', 0)"></span>&#160;<img src="@/assets/images/loading.gif"></div>
										<div class="nox_account_desktop_page_content_navigation_search_result_content" :class="{ hide: noxIsTreeSearchLoading }">
											<span class="nox_account_desktop_page_content_navigation_search_result_content_link" v-if="noxTreeSearchResult[0] && Object.keys(noxTreeSearchResult[0]).length" @click="getTree(1, noxTreeSearchResult[0].user_key)">
												<span v-html="$store.getters.getLanguageText('1.8', 3, { id: noxTreeSearchResult[0].user_id })"></span>
												<span v-if="noxTreeSearchResult[0].username"> ({{ noxTreeSearchResult[0].username }})</span>
											</span>
											<span v-else v-html="$store.getters.getLanguageText('1.8', 2)"></span>
										</div>
									</div>
								</div>
								<div class="nox_account_desktop_page_content_navigation_search_background" :class="{ hide: !noxTreeSearchResult.length && !noxIsTreeSearchLoading }" @click="searchClose"></div>
							</div>
						</div>
						<div class="nox_account_desktop_client_program_tree_buttons top" v-if="$store.state.noxAccountData.id !== noxTree.depth0[0].user_id && noxTree.depth0[0].user_id !== 100">
							<button type="button" class="nox_button" @click="getTree(1, noxTree.depth0[0].sponsor_key)"><font-awesome-icon :icon="['fas', 'arrow-up']" /><span v-html="$store.getters.getLanguageText('1.1', 26)"></span></button>
							<button type="button" class="nox_button" @click="getTree(1, noxTree.depth0[0].parent_key)"><font-awesome-icon :icon="['fas', 'level-up-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 27)"></span></button>
						</div>
						<div class="nox_account_desktop_client_program_tree_content">
							<div class="nox_account_desktop_client_program_tree_depth" v-for="(depth, index) in noxTree" :key="index">
								<div class="nox_account_desktop_client_program_tree_depth_node" v-for="(node, index2) in depth" :key="index2">
									<div class="nox_account_desktop_client_program_tree_depth_node_block" v-if="node">
										<div class="nox_account_desktop_client_program_tree_depth_node_content open" @click="$modal.show('NoxModalTreeNode', { type: 1, invitation: node.invitation })" v-if="node.status == 'open'"><font-awesome-icon :icon="['fas', 'plus']" /></div>
										<div class="nox_account_desktop_client_program_tree_depth_node_content lock" @click="$modal.show('NoxModalTreeNode', { type: 2, invitation: node.invitation })" v-else-if="node.status == 'lock'"><font-awesome-icon :icon="['fas', 'lock']" /></div>
										<div class="nox_account_desktop_client_program_tree_depth_node_content secret" @click="$modal.show('NoxModalTreeNode', { type: 3, invitation: node.invitation })" v-else-if="node.status == 'secret'"><font-awesome-icon :icon="['fas', 'user-secret']" /></div>
										<div class="nox_account_desktop_client_program_tree_depth_node_wrapper" :class="[node.group == 4 ? 'grey' : (!node.is_subscription ? 'red' : (!node.is_qualification ? 'orange' : ''))]" v-else>
											<div class="nox_account_desktop_client_program_tree_depth_node_avatar">
												<div class="nox_account_desktop_client_program_tree_depth_node_avatar_img" @click="$modal.show('NoxModalUserCard', { type: 'showUserCard', id: node.user_id })"><img :src="$parent.$parent.getAvatarByLink(node.user_data.avatar)"></div>
												<div class="nox_account_desktop_client_program_tree_depth_node_avatar_edit" @click="$parent.$parent.chooseCropperImage('cropImageForAvatar')" v-if="(index == 'depth0' && $store.state.noxAccountData.id == node.user_id)"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></div>
												<div class="nox_account_desktop_client_program_tree_depth_node_avatar_status" :style="{ background: '#' + $store.state.noxSystemSettings['user_status_color_' + node.user_data.status] }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 117, { status: $store.getters.getLanguageText('4.3.1', node.user_data.status), count: $store.state.noxSystemSettings['user_status_cycles_' + node.user_data.status] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="node.user_data.status">{{ $store.state.noxSystemSettings['user_status_name_' + node.user_data.status] }}</div>
											</div>
											<div class="nox_account_desktop_client_program_tree_depth_node_content data" @click="(index !== 'depth0' ? getTree(1, node.user_key) : '')">
												<div class="nox_account_desktop_client_program_tree_depth_node_content_lcount" v-if="index == 'depth0'"><span v-html="$store.getters.getLanguageText('3.5.1', 0)"></span><br><span>{{ node.lcount }}</span></div>
												<div class="nox_account_desktop_client_program_tree_depth_node_content_rcount" v-if="index == 'depth0'"><span v-html="$store.getters.getLanguageText('3.5.1', 0)"></span><br><span>{{ node.rcount }}</span></div>
												<div class="nox_account_desktop_client_program_tree_depth_node_content_title">
													<div class="status" v-if="node.group == 4" v-html="$store.getters.getLanguageText('3.5.1', 4)"></div>
													<div class="status" v-else-if="!node.is_subscription" v-html="$store.getters.getLanguageText('3.5.1', 3)"></div>
													<div class="status" v-else-if="node.is_qualification" v-html="$store.getters.getLanguageText('3.5.1', 1)"></div>
													<div class="status" v-else v-html="$store.getters.getLanguageText('3.5.1', 2)"></div>
													<div class="user_id">id: {{ node.user_id }}, {{ $store.state.noxSystemSettings['package_name_x' + node.package] }}</div>
													<div class="user_data" v-html="$store.getters.getLanguageText('3.5.1', 9, { count: (node.user_data.count_of_completed_quests > $store.state.noxSystemSettings.count_of_available_quests ? $store.state.noxSystemSettings.count_of_available_quests : node.user_data.count_of_completed_quests) })"></div>
													<div class="user_data" v-if="node.user_data.username">{{ node.user_data.username }}</div>
												</div>
												<div class="nox_account_desktop_client_program_tree_depth_node_content_points">
													<div>{{ node.lpoint }}<span v-html="$store.getters.getLanguageText('3.5.1', 5)"></span></div>
													<div></div>
													<div>{{ node.rpoint }}<span v-html="$store.getters.getLanguageText('3.5.1', 5)"></span></div>
												</div>
											</div>
											<div class="nox_account_desktop_client_program_tree_depth_node_potential" v-if="(index == 'depth0' && (node.lpoint || node.rpoint))"><div v-tooltip.bottom="{ content: $store.getters.getLanguageText('1.2', 183), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-html="$store.getters.getLanguageText('3.5.1', 7, { amount: ((node.lpoint ? node.lpoint : node.rpoint) * $store.state.noxSystemSettings.point_cost) })"></div></div>
											<!--div class="nox_account_desktop_client_program_tree_depth_node_content_link"><span @click="$modal.show('NoxModalTreeNode', { type: (node.is_subscription ? ($store.state.noxAccountData.id == node.user_id ? 4 : 5) : 6), invitation: node.invitation })" v-html="$store.getters.getLanguageText('3.5.1', 6)"></span></div-->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_client_program_tree_buttons bottom" v-if="noxTree.depth2 && ((noxTree.depth2[0] && noxTree.depth2[0].id) || (noxTree.depth2[3] && noxTree.depth2[3].id))">
							<button type="button" class="nox_button left" @click="getTree(2, noxTree.depth0[0].user_key)" v-if="noxTree.depth2[0] && noxTree.depth2[0].id"><font-awesome-icon class="rotate_45" :icon="['fas', 'arrow-down']" /><span class="short" v-html="$store.getters.getLanguageText('1.1', 79)"></span><span class="plain" v-html="$store.getters.getLanguageText('1.1', 81, { id: noxTree.depth0[0].user_id })"></span></button>
							<button type="button" class="nox_button right" @click="getTree(3, noxTree.depth0[0].user_key)" v-if="noxTree.depth2[3] && noxTree.depth2[3].id"><span class="short" v-html="$store.getters.getLanguageText('1.1', 80)"></span><span class="plain" v-html="$store.getters.getLanguageText('1.1', 82, { id: noxTree.depth0[0].user_id })"></span><font-awesome-icon class="rotate_135" :icon="['fas', 'arrow-up']" /></button>
						</div>
					</div>
					<div class="nox_account_desktop_client_program_tree" v-else>
						<div class="nox_account_desktop_portlet">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5', 0)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.5', 1)"></div>
							<div class="nox_account_desktop_portlet_buttons">
								<div v-if="noxTree.depth1">
									<div v-for="(node, index) in noxTree.depth1" :key="index">
										<div v-if="node.status == 'open'">
											<button type="button" class="nox_button big green" @click="$modal.show('NoxModalTreeNode', { type: 1, invitation: node.invitation })"><font-awesome-icon :icon="['fas', 'user-plus']" /><span v-html="$store.getters.getLanguageText('1.1', 28)"></span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="nox_account_desktop_portlet green">
							<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5', 2)"></div>
							<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.5', 3, { name_x3: $store.state.noxSystemSettings.package_name_x3, name_x4: $store.state.noxSystemSettings.package_name_x4, name_x5: $store.state.noxSystemSettings.package_name_x5, name_x6: $store.state.noxSystemSettings.package_name_x6, bonus_x3: ($store.state.noxSystemSettings.package_cost_x3 * $store.state.noxSystemSettings.percent_for_bonus_2_x3), bonus_x4: ($store.state.noxSystemSettings.package_cost_x4 * $store.state.noxSystemSettings.percent_for_bonus_2_x4), bonus_x5: ($store.state.noxSystemSettings.package_cost_x5 * $store.state.noxSystemSettings.percent_for_bonus_2_x5), bonus_x6: ($store.state.noxSystemSettings.package_cost_x6 * $store.state.noxSystemSettings.percent_for_bonus_2_x6) })"></div>
						</div>
					</div>
				</div>
				<div class="nox_account_desktop_client_program_content" v-else>
					<div class="nox_account_desktop_portlet">
						<div class="nox_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.5', 0)"></div>
						<div class="nox_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.5', 5)"></div>
						<div class="nox_account_desktop_portlet_loading" :class="{ hide: !noxIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_account_desktop_portlet_buttons" :class="{ hide: noxIsPortletLoading }">
							<button type="button" class="nox_button big green" @click="axios('openAccessToTree')"><font-awesome-icon :icon="['fas', 'lock-open']" /><span v-html="$store.getters.getLanguageText('1.1', 143)"></span></button>
						</div>
					</div>
				</div>
			</div>
			<div class="nox_account_desktop_modals">
				<div is="NoxModalCropper"></div>
				<div is="NoxModalUserCard"></div>
				<div is="NoxModalTreeNode"></div>
				<div is="NoxModalTreeGifts"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTree: {},
			noxTreeType: 0,
			noxTreeBonuses: {},
			noxTreeBonus4CountdownTime: 0,
			noxTreeBonus4CountdownValues: '',
			noxTreeBonus4CountdownLabels: '',
			noxTreeBonus4CountdownTimerId: 0,
			noxTreeBonus6CountdownTime: 0,
			noxTreeBonus6CountdownValue: '',
			noxTreeBonus6CountdownTimerId: 0,
			noxTreeCurators: [],
			noxTreeTopCurators: [],
			noxTreeAchievements: [],
			noxTreeStatsCols: 0,
			noxTreeSearch: '',
			noxTreeSearchResult: [],
			noxIsTreeBonus4Countdown: false,
			noxIsTreeBonus4CountdownExpires: false,
			noxIsTreeBonus6Countdown: false,
			noxIsTreeSearchLoading: false,
			noxIsPortletLoading: false,
			noxIsLoading: true
		}),
		watch: {
			$route() {
				if (this.noxTreeType == 1) {
					this.getTree(1, this.$route.params.key);
				}
				this.noxTreeType = 1;
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.noxIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.noxAccountData.group !== 4 && this.$store.state.noxAccountData.package) {
					this.getTree(1, this.$route.params.key);
				}
				else {
					this.$router.push({ path: '/account/tariffs' });
				}
			},
			getTree: function(type, key) {
				this.$parent.$parent.goToTop(true);
				this.searchClose();
				if (this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeBonuses') &&
						this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeCurators') &&
						this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeTopCurators') &&
						this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeAchievements')) {
						this.noxTreeBonuses = this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeBonuses');
						this.noxTreeCurators = this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeCurators');
						this.noxTreeTopCurators = this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeTopCurators');
						this.noxTreeAchievements = this.$cookies.get('nox' + this.$store.state.noxAccountData.id + 'TreeAchievements');
						this.axios('getTreeData', type, key, 0);
				}
				else {
					this.axios('getTreeData', type, key, 1);
				}
			},
			getTreeGiftAmount: function(id) {
				var countries = String(this.$store.state.noxSystemSettings.bonus_6_countries).split(',');
				return (!countries.includes(this.$store.state.noxAccountData.country) ? this.$store.state.noxSystemSettings['gift_cost_' + id] : 0);
			},
			getTreeGiftProgress: function(id) {
				var percent = this.noxTreeBonuses.bonus_6_id > id ? 100 : (this.noxTreeBonuses.bonus_6_points * 100 / this.$store.state.noxSystemSettings['count_cycles_for_receive_gift' + id]);
				return (percent > 100 ? 100 : percent);
			},
			getTreeBonus4Countdown: function() {

				var seconds = ((this.noxTreeBonus4CountdownTime - (new Date().getTime())) / 1000); seconds = (seconds > 0 ? seconds : 0);
				var days = parseInt(seconds / 86400); seconds = seconds % 86400;
				var hours = parseInt(seconds / 3600); seconds = seconds % 3600;
				var minutes = parseInt(seconds / 60);
				var values = '<span>' + this.$parent.$parent.getNumberPad(days) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(hours) + '</span>';
						values += '<span>' + this.$parent.$parent.getNumberPad(minutes) + '</span>';
				var labels = '<span>' + this.$store.getters.getLanguageText('1.4', 0) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 1) + '</span>';
						labels += '<span>' + this.$store.getters.getLanguageText('1.4', 2) + '</span>';

				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 60) {
					values = '<span>' + Math.floor(seconds) + '</span>';
					labels = '<span>' + this.$store.getters.getLanguageText('1.4', 3) + '</span>';
				}

				this.noxTreeBonus4CountdownValues = values;
				this.noxTreeBonus4CountdownLabels = labels;

				if (Number(days) <= 0) { this.noxIsTreeBonus4CountdownExpires = true; }
				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
					this.noxIsTreeBonus4Countdown = false; clearInterval(this.noxTreeBonus4CountdownTimerId);
				}
			},
			getTreeBonus6Countdown: function() {

				var seconds = ((this.noxTreeBonus6CountdownTime - (new Date().getTime())) / 1000); seconds = (seconds > 0 ? seconds : 0);
				var days = parseInt(seconds / 86400); seconds = seconds % 86400;
				var hours = parseInt(seconds / 3600); seconds = seconds % 3600;
				var minutes = parseInt(seconds / 60);

				this.noxTreeBonus6CountdownValue = this.$store.getters.getLanguageText('3.5.7', 2, { days: days, hours: hours });

				if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) < 60) {
					this.noxTreeBonus6CountdownValue = this.$store.getters.getLanguageText('3.5.7', 3, { options: [this.$parent.$parent.getDeclension(Math.floor(minutes))], minutes: Math.floor(minutes) });
					if (Number(days) <= 0 && Number(hours) <= 0 && Number(minutes) <= 0 && Number(seconds) < 1) {
						this.noxIsTreeBonus6Countdown = false; clearInterval(this.noxTreeBonus6CountdownTimerId);
					}
				}
			},
			search: function() {
				this.noxTreeSearchResult.splice(0, 1);
				if (this.$store.state.noxRegex_d.test(this.noxTreeSearch) && Number(this.noxTreeSearch) >= 100) {
					this.axios('getTreeBySearchUserId');
				}
			},
			searchClose: function() {
				this.noxTreeSearch = '';
				this.noxTreeSearchResult.splice(0, 1);
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					if (type == 'getTreeData') {
						_this.noxTreeType = var1;
						_this.noxIsLoading = true;
						_this.$store.state.noxIsAccount = true;
						config.url = '/v2' + (_this.$store.state.noxAccountData.group == 1 ? '/admin' : '') + '/account/tree';
						config.params = { isAllData: var3, depth: 2, type: var1, key: var2 };
					}
					else if (type == 'getTreeBySearchUserId') {
						_this.noxIsTreeSearchLoading = true;
						config.url = '/v2' + (_this.$store.state.noxAccountData.group == 1 ? '/admin' : '') + '/account/tree/search/' + _this.noxTreeSearch;
					}
					else if (type == 'openAccessToTree') {
						_this.noxIsPortletLoading = true;
						config.url = '/v2/account/users/is_client_program';
						config.data = { is_client_program: 1 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getTreeData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.tree !== undefined) {
									_this.noxTree = data.data.tree;
								}
								if (data.data.tree_bonuses !== undefined) {
									_this.noxTreeBonuses = data.data.tree_bonuses;
									_this.$cookies.set(('nox' + _this.$store.state.noxAccountData.id + 'TreeBonuses'), _this.noxTreeBonuses, '15min');
								}
								if (data.data.tree_curators !== undefined) {
									_this.noxTreeCurators = [];
									for (var i in data.data.tree_curators) {
										var array = data.data.tree_curators[i];
										_this.noxTreeCurators.push([ array.user_id, String(array.username).split(' ')[0], array.avatar, array.status ]);
									}
									_this.$cookies.set(('nox' + _this.$store.state.noxAccountData.id + 'TreeCurators'), _this.noxTreeCurators, '15min');
								}
								if (data.data.tree_top_curators !== undefined) {
									_this.noxTreeTopCurators = data.data.tree_top_curators;
									_this.$cookies.set(('nox' + _this.$store.state.noxAccountData.id + 'TreeTopCurators'), _this.noxTreeTopCurators, '15min');
								}
								if (data.data.tree_achievements !== undefined) {
									_this.noxTreeAchievements = [];
									_this.noxTreeAchievements.push(data.data.tree_achievements.total_points_for_1_day);
									_this.noxTreeAchievements.push(data.data.tree_achievements.total_points_for_1_week);
									_this.noxTreeAchievements.push(data.data.tree_achievements.total_points_for_1_month);
									_this.noxTreeAchievements.push(data.data.tree_achievements.total_points_for_6_months);
									_this.noxTreeAchievements.push(data.data.tree_achievements.total_points_for_all_time);
									_this.noxTreeAchievements.push(data.data.tree_achievements.total_points_for_sprint);
									_this.$cookies.set(('nox' + _this.$store.state.noxAccountData.id + 'TreeAchievements'), _this.noxTreeAchievements, '15min');
								}
								if (Object.keys(_this.noxTreeBonuses).length) {
									if (_this.noxTreeBonuses.bonus_4_finish > 0) {
										_this.noxTreeBonus4CountdownTime = _this.noxTreeBonuses.bonus_4_finish * 1000;
										if (_this.noxTreeBonus4CountdownTime > new Date().getTime()) {
											_this.noxIsTreeBonus4Countdown = true; _this.getTreeBonus4Countdown();
											_this.noxTreeBonus4CountdownTimerId = setInterval(function() { _this.getTreeBonus4Countdown(); }, 1000);
										}
									}
									if (_this.noxTreeBonuses.bonus_6_finish > 0) {
										_this.noxTreeBonus6CountdownTime = _this.noxTreeBonuses.bonus_6_finish * 1000;
										if (_this.noxTreeBonus6CountdownTime > new Date().getTime()) {
											_this.noxIsTreeBonus6Countdown = true; _this.getTreeBonus6Countdown();
											_this.noxTreeBonus6CountdownTimerId = setInterval(function() { _this.getTreeBonus6Countdown(); }, 1000);
										}
									}
								}
								_this.noxTreeStatsCols = 0;
								if (_this.noxTreeCurators.length) { _this.noxTreeStatsCols++; }
								if (_this.noxTreeTopCurators.length) { _this.noxTreeStatsCols++; }
								if (_this.noxTreeAchievements.length) { _this.noxTreeStatsCols++; }
								if (_this.noxIsTreeBonus4Countdown) { _this.noxTreeStatsCols++; }
								if (var2 !== undefined) {
									if ([2,3].includes(var1)) {
										if (_this.noxTree.depth0 && _this.noxTree.depth0[0].user_key) {
											var2 = _this.noxTree.depth0[0].user_key;
										}
									}
									if (_this.$route.path !== '/account/client_program/' + var2) {
										_this.noxTreeType = 0;
										_this.$router.push({ path: '/account/client_program/' + var2 });
									}
									setTimeout(function() {
										var width = document.body.clientWidth;
										var element = document.getElementById('nox_account_desktop_client_program_tree');
										var elementOffsetTop = element.offsetTop - (width > 1250 ? 15 : (width > 1000 ? 10 : 50));
										window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' });
									}, 0);
								}
								else {
									if (_this.$route.path !== '/account/client_program') {
										_this.$router.push({ path: '/account/client_program' });
									}
								}
							}
							else if (type == 'getTreeBySearchUserId') {
								_this.noxTreeSearchResult.splice(0, 1, data.data);
							}
							else if (type == 'openAccessToTree') {
								_this.$store.state.noxAccountData.is_client_program = 1;
							}
						}
					}).catch(function() {
						if (type == 'getTreeBySearchUserId') {
							_this.noxTreeSearchResult.splice(0, 1);
						}
						else {
							_this.$parent.$parent.exit();
						}
					}).finally(function() {
						_this.noxIsLoading = false;
						_this.noxIsPortletLoading = false;
						_this.noxIsTreeSearchLoading = false;
						_this.$store.state.noxIsAccount = false;
						_this.$store.state.noxIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			NoxModalCropper: () => import('@/views/modal_components/NoxModalCropper.vue'),
			NoxModalUserCard: () => import('@/views/modal_components/NoxModalUserCard.vue'),
			NoxModalTreeNode: () => import('@/views/modal_components/NoxModalTreeNode.vue'),
			NoxModalTreeGifts: () => import('@/views/modal_components/NoxModalTreeGifts.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.nox_account_desktop_client_program_tree_stats .nox_account_desktop_page_content_col.col4 {
		width: 25%;
	}
	.nox_account_desktop_client_program_tree_stats .nox_account_desktop_portlet {
		height: 240px;
	}
	.nox_account_desktop_client_program_tree_top_curators {
		text-transform: uppercase;
		font-size: 11px;
		height: 132px;
	}
	.nox_account_desktop_client_program_tree_top_curators_item {
		word-break: break-all;
		overflow: hidden;
		max-height: 22px;
	}
	.nox_account_desktop_client_program_tree_booster_bonus {
		margin: 0 -5px;
	}
	.nox_account_desktop_client_program_tree_booster_bonus .nox_countdown {
		padding: 10px 0 0 0;
	}
	.nox_account_desktop_client_program_tree_booster_bonus .nox_countdown .title {
		margin: 0 0 15px 0;
		text-transform: lowercase;
		font-size: 12px;
		line-height: 15px;
	}
	.nox_account_desktop_client_program_tree_booster_bonus .nox_countdown .values span,
	.nox_account_desktop_client_program_tree_booster_bonus .nox_countdown .labels span {
		width: 70px;
	}
	.nox_account_desktop_client_program_tree_curators {
		text-align: center;
		height: 132px;
	}
	.nox_account_desktop_client_program_tree_curators_content {
		vertical-align: middle;
		display: table-cell;
		width: 100vw;
		height: 132px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block {
		margin: 5px 0 0 0;
		display: inline-block;
		width: 200px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block_item {
		position: relative;
		display: inline-block;
		width: 33.33333%;
		height: 60px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block_item_img img {
		border: 1px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #6F6F6F;
		overflow: hidden;
		cursor: pointer;
		width: 45px;
		height: 45px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block_item_name {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 10px;
		line-height: 18px;
		height: 15px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block_item_status {
		position: absolute;
		top: 0;
		left: 50%;
		margin: 0 0 0 12px;
		padding: 0 5px;
		border: 1px solid #FFFFFF;
		border-radius: 10px;
		background: #B8C3CA;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 8px;
		line-height: 18px;
		min-width: 20px;
		height: 20px;
		z-index: 1;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items1,
	.nox_account_desktop_client_program_tree_curators_content_block.items2,
	.nox_account_desktop_client_program_tree_curators_content_block.items3 {
		margin: 0;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item {
		width: 100%;
		height: 110px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items2 .nox_account_desktop_client_program_tree_curators_content_block_item {
		width: 50%;
		height: 100px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item {
		height: 75px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item_img img,
	.nox_account_desktop_client_program_tree_curators_content_block.items2 .nox_account_desktop_client_program_tree_curators_content_block_item_img img {
		border-width: 2px;
		width: 90px;
		height: 90px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items2 .nox_account_desktop_client_program_tree_curators_content_block_item_img img {
		width: 80px;
		height: 80px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item_img img {
		width: 55px;
		height: 55px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item_name,
	.nox_account_desktop_client_program_tree_curators_content_block.items2 .nox_account_desktop_client_program_tree_curators_content_block_item_name,
	.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item_name {
		margin: 5px 0 -6px 0;
		font-size: 12px;
		line-height: 20px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item_name {
		margin: 3px 0 -6px 0;
		font-size: 10px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item_status {
		margin: 0 0 0 19px;
		padding: 0 6px;
		border-radius: 13px;
		font-size: 11px;
		line-height: 24px;
		min-width: 26px;
		height: 26px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items2 .nox_account_desktop_client_program_tree_curators_content_block_item_status {
		margin: 0 0 0 16px;
		padding: 0 6px;
		border-radius: 12px;
		font-size: 10px;
		line-height: 22px;
		min-width: 24px;
		height: 24px;
	}
	.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item_status {
		margin: 0 0 0 14px;
		border-radius: 11px;
		font-size: 9px;
		line-height: 20px;
		min-width: 22px;
		height: 22px;
	}
	.nox_account_desktop_client_program_tree_achievements {
		font-size: 12px;
		height: 132px;
	}
	.nox_account_desktop_client_program_tree_achievements_row span {
		margin: 0 0 0 8px;
	}
	.nox_account_desktop_client_program_tree_gifts {
		text-align: center;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_portlet {
		padding: 25px 30px 30px 30px;
		border-radius: 15px;
		background: #333333B3;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 22px;
	}
	.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_portlet.grey {
		background: #CCCCCC99;
		color: #555555;
	}
	.nox_account_desktop_client_program_tree_gift {
		display: inline-block;
		width: 202px;
	}
	.nox_account_desktop_client_program_tree_gift_content {
		position: relative;
		padding: 0 0 0 80px;
		text-align: center;
		height: 80px;
	}
	.nox_account_desktop_client_program_tree_gift_content_image {
		position: absolute;
		top: 50%;
		left: -5px;
		margin: -50px 0 0 0;
		filter: grayscale(100%);
		width: 90px;
		height: 90px;
		opacity: 0.5;
	}
	.nox_account_desktop_client_program_tree_gift_content_image.active {
		filter: grayscale(0%);
		opacity: 1;
	}
	.nox_account_desktop_client_program_tree_gift_content_image.progress {
		margin: -45px 0 0 0;
		padding: 5px;
		filter: grayscale(0%);
		width: 80px;
		height: 80px;
		opacity: 1;
	}
	.nox_account_desktop_client_program_tree_gift_content_image.active .nox_account_desktop_client_program_tree_gift_content_image_type span {
		background: #48B849;
	}
	.nox_account_desktop_client_program_tree_gift_content_image.progress .nox_account_desktop_client_program_tree_gift_content_image_type span {
		margin: 0;
		background: transparent;
		line-height: 70px;
		width: 70px;
		height: 70px;
	}
	.nox_account_desktop_client_program_tree_gift_content_image_type span {
		margin: 5px 0 0 0;
		border-radius: 50%;
		background: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		font-size: 18px;
		line-height: 80px;
		display: block;
		width: 80px;
		height: 80px;
	}
	.nox_account_desktop_client_program_tree_gift_content_image_progress {
		position: absolute;
		top: 1px;
		left: 1px;
		border: 1px solid #FFFFFF80;
		border-radius: 50%;
		width: 78px;
		height: 78px;
	}
	.nox_account_desktop_client_program_tree_gift_content_image_progress_slice {
		position: absolute;
		top: -2px;
		left: -2px;
		clip: rect(0, 80px, 80px, 40px);
		width: 80px;
		height: 80px;
	}
	.nox_account_desktop_client_program_tree_gift_content_image_progress_slice.s50 {
		clip: rect(auto, auto, auto, auto);
	}
	.nox_account_desktop_client_program_tree_gift_content_image_progress_slice .pie {
		position: absolute;
		border: 3px solid #FFFFFF;
		border-radius: 50px;
		clip: rect(0, 40px, 80px, 0);
		width: 80px;
		height: 80px;
	}
	.nox_account_desktop_client_program_tree_gift_content_image_progress_slice .pie:nth-child(2) {
		transform: rotate(180deg) !important;
		display: none;
	}
	.nox_account_desktop_client_program_tree_gift_content_image_progress_slice.s50 .pie:nth-child(2) {
		display: block;
	}
	.nox_account_desktop_client_program_tree_gift_content_title {
		margin: 0 0 10px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
	}
	.nox_account_desktop_client_program_tree_gift_content_block_row {
		font-size: 11px;
		line-height: 18px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_client_program_tree_gift_content_block_label {
		margin: 0 5px 0 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_client_program_tree_gift_content_block_status {
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.nox_account_desktop_client_program_tree_gift_content_block_status.green {
		color: #00FF00;
	}
	.nox_account_desktop_client_program_tree_gift_content_block_status.orange {
		color: #FFC660;
	}
	.nox_account_desktop_client_program_tree_gift_buttons {
		margin: 14px 0 0 0;
		text-align: center;
	}
	.nox_account_desktop_client_program_tree_gift_buttons button {
		min-width: 98px;
	}
	.nox_account_desktop_client_program_tree {
		display: table;
		width: 100%;
	}
	.nox_account_desktop_client_program_tree_content {
		margin: 30px 0 0 0;
	}
	.nox_account_desktop_client_program_tree_buttons button {
		padding: 5px 15px 7px 15px;
		border-radius: 15px;
		background: #33333380;
		text-transform: lowercase;
		font-size: 12px;
	}
	.nox_account_desktop_client_program_tree_buttons button:hover {
		background: #333333B3;
	}
	.nox_account_desktop_client_program_tree_buttons button svg {
		margin: 0 5px 0 0;
		font-size: 9px;
	}
	.nox_account_desktop_client_program_tree_buttons.top {
		margin: 30px 0 25px 0;
		text-align: center;
	}
	.nox_account_desktop_client_program_tree_buttons.top button:first-child {
		margin: 0 5px 0 0;
	}
	.nox_account_desktop_client_program_tree_buttons.top button:last-child {
		margin: 0 0 0 5px;
	}
	.nox_account_desktop_client_program_tree_buttons.bottom {
		padding: 26px 5px 0 5px;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_client_program_tree_buttons.bottom button.right {
		float: right;
	}
	.nox_account_desktop_client_program_tree_buttons.bottom button.right svg {
		margin: 0 0 0 5px;
	}
	.nox_account_desktop_client_program_tree_buttons.bottom button span.short {
		display: none;
	}
	.nox_account_desktop_client_program_tree_depth {
		text-align: center;
		display: table;
		width: 100%;
	}
	.nox_account_desktop_client_program_tree_depth_node {
		float: left;
		padding: 20px 5px;
		font-size: 14px;
		display: inline-block;
		width: 100%;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(2) .nox_account_desktop_client_program_tree_depth_node {
		width: 50%;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node {
		padding: 20px 5px 0 5px;
		width: 25%;
	}
	.nox_account_desktop_client_program_tree_depth_node_block {
		position: relative;
		display: inline-block;
		width: 35%;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_block {
		margin: 20px 0 0 0;
		min-width: 150px;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(2) .nox_account_desktop_client_program_tree_depth_node_block {
		width: 55%;
		min-width: 135px;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_block {
		width: 80%;
	}
	.nox_account_desktop_client_program_tree_depth_node_avatar {
		position: relative;
		z-index: 1;
	}
	.nox_account_desktop_client_program_tree_depth_node_avatar_img {
		position: absolute;
		top: 0;
		left: 50%;
		margin: -30px 0 0 -30px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		box-shadow: 0 0 2px #6F6F6F;
		background: #6F6F6F;
		overflow: hidden;
		cursor: pointer;
		width: 60px;
		height: 60px;
	}
	.nox_account_desktop_client_program_tree_depth_node_avatar_img img:hover {
		opacity: 0.9;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_avatar_img {
		margin: -40px 0 0 -40px;
		width: 80px;
		height: 80px;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.grey .nox_account_desktop_client_program_tree_depth_node_avatar_img {
		box-shadow: 0 0 2px #E1E1E1;
		background: #E1E1E1;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.orange .nox_account_desktop_client_program_tree_depth_node_avatar_img {
		box-shadow: 0 0 2px #E1B673;
		background: #E1B673;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.red .nox_account_desktop_client_program_tree_depth_node_avatar_img {
		box-shadow: 0 0 2px #FF848B;
		background: #FF848B;
	}
	.nox_account_desktop_client_program_tree_depth_node_avatar_edit {
		position: absolute;
		top: 0;
		left: 50%;
		margin: 14px 0 0 -40px;
		border: 1px solid #FFFFFF;
		border-radius: 50%;
		background: #48B849;
		color: #FFFFFF;
		text-align: center;
		cursor: pointer;
		font-size: 10px;
		line-height: 24px;
		width: 26px;
		height: 26px;
	}
	.nox_account_desktop_client_program_tree_depth_node_avatar_edit:hover {
		background: #5695CE;
	}
	.nox_account_desktop_client_program_tree_depth_node_avatar_status {
		position: absolute;
		top: 0;
		left: 50%;
		margin: -30px -30px 0 8px;
		padding: 0 5px;
		border: 1px solid #FFFFFF;
		border-radius: 11px;
		background: #B8C3CA;
		color: #FFFFFF;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 9px;
		line-height: 20px;
		min-width: 22px;
		height: 22px;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_avatar_status {
		margin: -40px -40px 0 14px;
		padding: 0 7px;
		border-radius: 13px;
		font-size: 11px;
		line-height: 24px;
		min-width: 26px;
		height: 26px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content {
		border-radius: 15px;
		background: #333333B3;
		color: #FFFFFF;
		cursor: pointer;
		width: 100%;
		height: 100%;
	}
	.nox_account_desktop_client_program_tree_depth_node_content:hover {
		opacity: 0.9;
	}
	.nox_account_desktop_client_program_tree_depth_node_content.open {
		background: #00800080;
		height: 140px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content.lock,
	.nox_account_desktop_client_program_tree_depth_node_content.secret {
		background: #55555580;
		height: 140px;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_content.data {
		cursor: auto;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_content.data:hover {
		opacity: 1;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.grey .nox_account_desktop_client_program_tree_depth_node_content.data {
		background: #CCCCCC99;
		color: #555555;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.orange .nox_account_desktop_client_program_tree_depth_node_content.data {
		background: #CC840099;
		color: #FFFFFF;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.red .nox_account_desktop_client_program_tree_depth_node_content.data {
		background: #FF000080;
		color: #FFFFFF;
	}
	.nox_account_desktop_client_program_tree_depth_node_content.open svg,
	.nox_account_desktop_client_program_tree_depth_node_content.lock svg,
	.nox_account_desktop_client_program_tree_depth_node_content.secret svg {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -20px 0 0 -20px;
		width: 40px;
		height: 40px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_lcount,
	.nox_account_desktop_client_program_tree_depth_node_content_rcount {
		position: absolute;
		top: 0;
		margin: 25px 0 0 -90px;
		padding: 22px 0 22px 5px;
		border-radius: 50% 0 0 50%;
		background: #11111180;
		color: #CCCCCC;
		font-size: 11px;
		line-height: 20px;
		width: 90px;
		height: 90px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_rcount {
		right: 0;
		margin: 25px -90px 0 0;
		padding: 22px 5px 22px 0;
		border-radius: 0 50% 50% 0;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_lcount span:last-child,
	.nox_account_desktop_client_program_tree_depth_node_content_rcount span:last-child {
		color: #FFFFFF;
		font-size: 18px;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_content_lcount {
		margin: 37.5px 0 0 -90px;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_content_rcount {
		margin: 37.5px -90px 0 0;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.grey .nox_account_desktop_client_program_tree_depth_node_content_lcount,
	.nox_account_desktop_client_program_tree_depth_node_wrapper.grey .nox_account_desktop_client_program_tree_depth_node_content_rcount {
		background: #BBBBBB99;
		color: #555555;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.grey .nox_account_desktop_client_program_tree_depth_node_content_lcount span:last-child,
	.nox_account_desktop_client_program_tree_depth_node_wrapper.grey .nox_account_desktop_client_program_tree_depth_node_content_rcount span:last-child {
		color: #555555;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.orange .nox_account_desktop_client_program_tree_depth_node_content_lcount,
	.nox_account_desktop_client_program_tree_depth_node_wrapper.orange .nox_account_desktop_client_program_tree_depth_node_content_rcount {
		background: #BB790099;
		color: #FFFFFF;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.red .nox_account_desktop_client_program_tree_depth_node_content_lcount,
	.nox_account_desktop_client_program_tree_depth_node_wrapper.red .nox_account_desktop_client_program_tree_depth_node_content_rcount {
		background: #E0000080;
		color: #FFFFFF;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_title {
		padding: 35px 0 5px 0;
		overflow: hidden;
		vertical-align: middle;
		display: table-cell;
		width: 100vw;
		height: 100px;
	}
	.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_content_title {
		padding: 45px 0 5px 0;
		height: 110px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_title .status {
		font-size: 10px;
		line-height: 14px;
		height: 14px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_title .user_id {
		margin: 2px 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_title .user_data {
		padding: 0 5px;
		font-size: 11px;
		line-height: 12px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_points {
		position: relative;
		height: 55px;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_points div {
		position: relative;
		font-size: 24px;
		line-height: 36px;
		display: inline-block;
		width: 50%;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_points div:nth-child(2) {
		position: absolute;
		top: 5px;
		left: 50%;
		bottom: 18px;
		padding: 0;
		margin: 0 0 0 -1px;
		border-radius: 100%;
		background: #FFFFFF;
		width: 2px;
	}
	.nox_account_desktop_client_program_tree_depth_node_wrapper.grey .nox_account_desktop_client_program_tree_depth_node_content_points div:nth-child(2) {
		background: #555555;
	}
	.nox_account_desktop_client_program_tree_depth_node_content_points div span {
		position: absolute;
		left: 50%;
		bottom: -5px;
		margin: 0 0 0 -20px;
		font-size: 8px;
		line-height: 8px;
		width: 40px;
	}
	.nox_account_desktop_client_program_tree_depth_node_potential {
		padding: 0 20px;
	}
	.nox_account_desktop_client_program_tree_depth_node_potential div {
		padding: 7px 10px 10px 10px;
		border-radius: 0 0 15px 15px;
		background: #0066BB80;
		color: #FFFFFF;
		font-size: 12px;
		line-height: 15px;
	}

	@media (max-width: 1439px) {
		.nox_account_desktop_client_program_tree_stats.stats4 .nox_account_desktop_page_content_row,
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_row {
			margin: 0;
			display: block;
		}
		.nox_account_desktop_client_program_tree_stats.stats4 .nox_account_desktop_page_content_col,
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col {
			width: 50%;
		}
		.nox_account_desktop_client_program_tree_stats.stats4 .nox_account_desktop_page_content_col:nth-child(odd),
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col:nth-child(odd) {
			padding: 0 7.5px 15px 0;
		}
		.nox_account_desktop_client_program_tree_stats.stats4 .nox_account_desktop_page_content_col:nth-child(even),
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col:nth-child(even) {
			padding: 0 0 15px 7.5px;
		}
	}
	@media (max-width: 1250px) {
		.nox_account_desktop_client_program_tree_stats.stats1 .nox_account_desktop_page_content_col {
			padding: 0 7.5px 10px 7.5px;
		}
		.nox_account_desktop_client_program_tree_stats.stats2 .nox_account_desktop_page_content_col,
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col {
			padding: 0 5px 10px 5px;
		}
		.nox_account_desktop_client_program_tree_stats.stats2 .nox_account_desktop_page_content_col:first-child,
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:first-child {
			padding: 0 5px 10px 7.5px;
		}
		.nox_account_desktop_client_program_tree_stats.stats2 .nox_account_desktop_page_content_col:last-child,
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:last-child {
			padding: 0 7.5px 10px 5px;
		}
		.nox_account_desktop_client_program_tree_stats.stats4 .nox_account_desktop_page_content_col:nth-child(odd),
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col:nth-child(odd) {
			padding: 0 5px 0 0;
		}
		.nox_account_desktop_client_program_tree_stats.stats4 .nox_account_desktop_page_content_col:nth-child(even),
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col:nth-child(even) {
			padding: 0 0 0 5px;
		}
		.nox_account_desktop_client_program_tree_stats.stats4 .nox_account_desktop_page_content_col .nox_account_desktop_portlet,
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col .nox_account_desktop_portlet {
			margin: 0 0 10px 0;
		}
	}
	@media (max-width: 1200px) {
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_row {
			margin: 0;
			display: block;
		}
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col {
			width: 50%;
		}
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:nth-child(odd) {
			padding: 0 5px 5px 0;
		}
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:nth-child(even) {
			padding: 0 0 5px 5px;
		}
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:last-child {
			padding: 5px 0 10px 0;
			width: 100%;
		}
	}
	@media (max-width: 1000px) {
		.nox_account_desktop_client_program_tree_stats .nox_account_desktop_page_content_col,
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col {
			float: left;
		}
		.nox_account_desktop_client_program_tree_stats.stats1 .nox_account_desktop_page_content_col {
			padding: 0;
		}
		.nox_account_desktop_client_program_tree_stats.stats2 .nox_account_desktop_page_content_col {
			width: 50%;
		}
		.nox_account_desktop_client_program_tree_stats.stats2 .nox_account_desktop_page_content_col:nth-child(odd),
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:nth-child(odd) {
			padding: 0 5px 0 0;
		}
		.nox_account_desktop_client_program_tree_stats.stats2 .nox_account_desktop_page_content_col:nth-child(even),
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:nth-child(even) {
			padding: 0 0 0 5px;
		}
		.nox_account_desktop_client_program_tree_stats.stats3 .nox_account_desktop_page_content_col:last-child {
			padding: 0;
		}
		.nox_account_desktop_client_program_tree_stats .nox_account_desktop_portlet {
			height: 213px;
		}
		.nox_account_desktop_client_program_tree_booster_bonus .nox_countdown {
			padding: 8px 0 0 0;
		}
		.nox_account_desktop_client_program_tree_booster_bonus .nox_countdown .title {
			margin: 0 0 11px 0;
			font-size: 11px;
			line-height: 14px;
		}
		.nox_account_desktop_client_program_tree_top_curators,
		.nox_account_desktop_client_program_tree_curators,
		.nox_account_desktop_client_program_tree_curators_content,
		.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item,
		.nox_account_desktop_client_program_tree_curators_content_block.items2 .nox_account_desktop_client_program_tree_curators_content_block_item,
		.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item,
		.nox_account_desktop_client_program_tree_achievements {
			height: 120px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item,
		.nox_account_desktop_client_program_tree_curators_content_block.items2 .nox_account_desktop_client_program_tree_curators_content_block_item {
			margin: 10px 0 0 0;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items4,
		.nox_account_desktop_client_program_tree_curators_content_block.items5 {
			margin: 2px 0 0 0;
			width: 180px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items4 .nox_account_desktop_client_program_tree_curators_content_block_item,
		.nox_account_desktop_client_program_tree_curators_content_block.items5 .nox_account_desktop_client_program_tree_curators_content_block_item {
			height: 55px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item {
			padding: 24px 0;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item_img img {
			width: 80px;
			height: 80px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items4 .nox_account_desktop_client_program_tree_curators_content_block_item_img img,
		.nox_account_desktop_client_program_tree_curators_content_block.items5 .nox_account_desktop_client_program_tree_curators_content_block_item_img img {
			width: 40px;
			height: 40px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items4 .nox_account_desktop_client_program_tree_curators_content_block_item_name,
		.nox_account_desktop_client_program_tree_curators_content_block.items5 .nox_account_desktop_client_program_tree_curators_content_block_item_name {
			font-size: 9px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items1 .nox_account_desktop_client_program_tree_curators_content_block_item_status {
			margin: 0 0 0 16px;
			border-radius: 12px;
			font-size: 10px;
			line-height: 22px;
			min-width: 24px;
			height: 24px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items3 .nox_account_desktop_client_program_tree_curators_content_block_item_status {
			margin: 24px 0 0 14px;
		}
		.nox_account_desktop_client_program_tree_curators_content_block.items4 .nox_account_desktop_client_program_tree_curators_content_block_item_status,
		.nox_account_desktop_client_program_tree_curators_content_block.items5 .nox_account_desktop_client_program_tree_curators_content_block_item_status {
			margin: 0 0 0 10px;
			border-radius: 9px;
			font-size: 7px;
			line-height: 16px;
			min-width: 18px;
			height: 18px;
		}
		.nox_account_desktop_client_program_tree_gift {
			margin: 10px 0;
		}
		.nox_account_desktop_client_program_tree_content {
			margin: 20px 0 0 0;
		}
		.nox_account_desktop_client_program_tree_buttons.top {
			margin: 20px 0 15px 0;
		}
		.nox_account_desktop_client_program_tree_buttons.bottom {
			padding: 16px 5px 0 5px;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_block {
			width: 60%;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(2) .nox_account_desktop_client_program_tree_depth_node_block {
			width: 80%;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_block {
			width: 100%;
		}
		.nox_account_desktop_client_program_tree_depth_node_content_lcount,
		.nox_account_desktop_client_program_tree_depth_node_content_rcount {
			margin: 25px 0 0 -70px;
			font-size: 10px;
			width: 70px;
		}
		.nox_account_desktop_client_program_tree_depth_node_content_rcount {
			margin: 25px -70px 0 0;
		}
		.nox_account_desktop_client_program_tree_depth_node_content_lcount span:last-child,
		.nox_account_desktop_client_program_tree_depth_node_content_rcount span:last-child {
			font-size: 16px;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_content_lcount {
			margin: 37.5px 0 0 -70px;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(1) .nox_account_desktop_client_program_tree_depth_node_content_rcount {
			margin: 37.5px -70px 0 0;
		}
		.nox_account_desktop_client_program_tree_depth_node_content_title .user_data {
			font-size: 9px;
		}
		.nox_account_desktop_client_program_tree_depth_node_content_points div {
			font-size: 20px;
		}
	}
	@media (max-width: 550px) {
		.nox_account_desktop_client_program_tree_stats .nox_account_desktop_page_content_col,
		.nox_account_desktop_client_program_tree_gifts .nox_account_desktop_page_content_col {
			padding: 0 !important;
			width: 100% !important;
		}
		.nox_account_desktop_client_program_tree_stats .nox_account_desktop_portlet,
		.nox_account_desktop_client_program_tree_top_curators,
		.nox_account_desktop_client_program_tree_achievements {
			height: auto;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_content_title {
			flex-direction: column;
			justify-content: center;
			display: flex;
			width: 100%;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_content_title .status {
			display: none;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_content_title .user_id {
			margin: 5px 0 0 0;
			font-size: 10px;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_content_points div {
			font-size: 14px;
			line-height: 26px;
			width: 100%;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_content_points div:nth-child(2) {
			position: absolute;
			top: 50%;
			margin: 0 0 0 -15px;
			width: 30px;
			height: 2px;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(3) .nox_account_desktop_client_program_tree_depth_node_content_points div span {
			display: none;
		}
	}
	@media (max-width: 450px) {
		.nox_account_desktop_client_program_tree_buttons.bottom button span.short {
			display: inline;
		}
		.nox_account_desktop_client_program_tree_buttons.bottom button span.plain {
			display: none;
		}
		.nox_account_desktop_client_program_tree_depth:nth-child(2) .nox_account_desktop_client_program_tree_depth_node_block {
			width: 90%;
		}
	}
	@media (max-width: 350px) {
		.nox_account_desktop_client_program_tree_depth:nth-child(2) .nox_account_desktop_client_program_tree_depth_node_block {
			width: 100%;
		}
	}
</style>
